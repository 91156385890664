import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = (theme) => ({
  bold: {
    fontWeight: "Bold",
  },
});

class TopContent extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Box p={4}>
        <Box pb={1}>
          <Typography variant={"h1"} align={"center"} className={classes.bold}>
            {this.props.title}
          </Typography>
        </Box>
        <Typography variant={"h5"} align={"center"}>
          {this.props.desc1}
        </Typography>
        <Typography variant={"h5"} align={"center"}>
          {this.props.desc2}
        </Typography>
        {this.props.img}
      </Box>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(TopContent);
