import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Otp from "./otp";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import API from "../../../../utilities/api";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CurvedBox from "../../Login/curvedBox";
import Typography from "@material-ui/core/Typography";

class ResponsiveDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fullscreen: true,
      otp: "",
    };
  }

  healthInsuranceProductIds = [49];

  handleClickOpen = () => {
    this.setState(
      {
        open: true,
      },
      () => {
        this.sendOtp();
      }
    );
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  sendOtp() {
    this.props.onCallShowLoader(true);
    let orderId = this.props.userDetails?.order_id
    API.post(
      `orders/${orderId}/sendOtp`,
      {}
    )
      .then((res) => {
        this.props.onCallShowLoader(false);
      })
      .catch((err) => {
        this.props.onCallShowLoader(false);
        console.log(err);
      });
  }

  isHealthInsuranceJourney (productId) {
    return productId && this.healthInsuranceProductIds.indexOf(productId) > -1;
  }

  render() {
    return (
      <div>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          disabled={this.props.disabled}
          endIcon={this.props.endIcon}
          onClick={(e) => {
            this.handleClickOpen();
          }}
        >
          {this.props.title}
        </Button>
        <Dialog
          fullScreen={this.state.fullscreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <DialogContentText>
              <CurvedBox
                checked="true"
                detailsVerifyPage="true"
                title="Phone Number Verification"
                desc={this.isHealthInsuranceJourney(this.props.userDetails?.product_id) 
                  ? 'By providing this OTP, "I/ We hereby declare, on my behalf and on behalf of all persons proposed to be insured that the information, answers, and/or particulars given by me in the proposal form are true and complete in all respects to the best of my knowledge. I confirm to have understood the insurance coverage details and agree to pay the calculated premium. "' 
                  : 'By providing this OTP, I confirm to have understood the insurance coverage details and agree to pay the calculated premium.'}
                subDesc={
                  "Enter the code sent to" + " " + "+91-" + this.props.mobile
                }
                centerAlign = "true"
              />

              <Otp
                otpCallBack={(otp) => {
                  this.setState({
                    otp: otp,
                  });
                }}
                resendOtp={() => {
                  this.sendOtp();
                }}
              />
            </DialogContentText>

            {this.props.userDetails?.otpError && 
            <Box display={"flex"} justifyContent={"center"} m={3}>
              <Typography color="secondary" >
                {this.props.userDetails?.otpError}
              </Typography>
            </Box>}

            <Box display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                onClick={(e) => {
                  this.props.onButtonClick(this.state.otp);
                  // this.handleClose();
                }}
              >
                VERIFY
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.customer.error,
    userDetails: state.customer.userDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
    getUserDetails: (userId) => dispatch(actions.getUserDetails(userId)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDialog);
