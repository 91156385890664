import React, {Component} from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import HighlightChip from "./highlightChip";

import {useStyles} from './styles/coverageCardStyles';


class CoverageCard extends Component {
    constructor(props, context) {
        super(props, context);

    }

    getAmountInLakhs(amount) {
        let tempAmount;
        let finalAmount
        if (amount > 9999999) {
            tempAmount = amount / 10000000
            finalAmount = tempAmount ? (tempAmount === 1 ? tempAmount + ' Crore' : tempAmount + ' Crores') : '';
        } else {
            tempAmount = (amount * 1) / 100000
            finalAmount = tempAmount ? (tempAmount === 1 ? tempAmount + ' Lakh' : tempAmount + ' Lakhs') : '';
        }
        return finalAmount;
    }

    render() {
        const {classes, img, sumAssured, product, productBy, premiumBreakup} = this.props;
        const {paper, paperInner, iconCS, middleText, h2} = classes;
        return (
            <Paper className={paper}>
                <Box className={paperInner}>
                    <Box className={iconCS}>
                        {img}
                    </Box>
                    <Box className={middleText}>
                        <Typography variant={"h3"} className={h2}>
                            ₹{" "}
                            {this.getAmountInLakhs(
                                sumAssured
                            )}
                        </Typography>
                        <Typography className={classes.product} variant={"h5"}>
                            {product}
                        </Typography>
                    </Box>
                </Box>
                <Box p={1} pt={0.5}>
                    <HighlightChip
                        left={
                            <p style={{margin: '0', padding: '0'}}>
                                {productBy} <span
                                style={{fontWeight: 'bold'}}>{premiumBreakup}</span>
                            </p>
                        }
                    />
                </Box>
            </Paper>
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(CoverageCard);
