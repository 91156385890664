import React, {useEffect, useReducer, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import MaterialAppBar from "../../../components/Headers/MaterialAppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import {Spinner} from "reactstrap";
import ResponsiveContainer from "../../../components/ResponsiveContainer/responsiveContainer";
import queryString from 'query-string';
import {useGetApi, usePostApi} from '../../../hooks/useApi';
import {ReducerUtils} from '../../../constants/reducers';
import QuotationReducer from '../../../reducers/quotation';
import {getInvoiceDaysDifference, parseMessage, buildResumeAuthPayload} from '../../../helpers/quotation';
import {useStyles} from '../../../styles/buyStyles/productStyles'
import {Typography} from "@material-ui/core";
import {buildQuotationPayload, ProductDetails, ReferenceMapping} from "../../../mapping/product";
import {postWebService, getWebService} from "../../../helpers/serverUtils";
import SimpleBackdrop from '../../../components/Loader/simpleBackdrop';

const ProductDescription = (props) => {
    const {classes} = props;
    const history = useHistory();
    const location = useLocation();
    const searchObject = queryString.parse(location.search);
    const existingQuotationDetails = useGetApi(`orders/${searchObject.order_id}`);
    const [state, dispatch] = useReducer(QuotationReducer, {existingQuotationDetails: null});
    const [canBuy, setCanBuy] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        dispatchEvent(ReducerUtils.existingQuotation.details, existingQuotationDetails);
        if (existingQuotationDetails && existingQuotationDetails.response_invoice_date) {
            const diffDays = getInvoiceDaysDifference(existingQuotationDetails.response_invoice_date);
        	setCanBuy(diffDays <= 2);
            diffDays <= 2 ? resumeAuth() : setLoader(false);
        }
    }, [existingQuotationDetails, state.existingQuotationDetails]);

    const dispatchEvent = (type, payload) => {
        dispatch({type, payload});
    };

    const cbSuccessCreateQuotation = ({ order_id }) => {
        history.push({
            pathname: `/products/${props.match.params.product}/buy/${order_id}`
        });
    };

    const cbErrorCreateQuotation = () => {
        setIsFormSubmitted(false);
        setErrors(['It seems that there is some issue in quotation creation']);
        setLoader(false);
    };

    const cbError = ({ data: { error_msg }, data }) => {
        setIsFormSubmitted(false);
        setErrors(parseMessage(error_msg));
        setLoader(false);
    };

    const createQuotation = async () => {
        if (!state.existingQuotationDetails) {
            return false;
        }

        setErrors([]);
        const productId = parseInt(ProductDetails[ReferenceMapping[props.match.params.product]].productId, 10);
        const object = {
            ...state.existingQuotationDetails,
            channel: 'api',
            file_screen_photo: state.existingQuotationDetails.response_screen_photo || null,
            file_invoice_photo: state.existingQuotationDetails.response_invoice_photo || null,
            response_cross_sell_order_id: searchObject.order_id,
            response_product: searchObject.product,
            response_tag: searchObject.tag
        };
        const payload = buildQuotationPayload(ReferenceMapping[props.match.params.product], object);

        try {
            const response = await postWebService(`products/${productId}/create_quotation`, payload);
            const { data, success } = response.data;
            success ? cbSuccessCreateQuotation(data) : cbErrorCreateQuotation();
        } catch (error) {
            cbError(error.response.data);
        }
    };

    const cbResumeAuth = ({ success, data: { access_token }, data }) => {
        if (success) {
            localStorage.setItem("token", access_token);
            createQuotation();
        } else {
            setErrors(['It seems that there is some issue with link']);
            setLoader(false);
        }
    };

    const resumeAuth = async () => {
        if (!state.existingQuotationDetails) {
            return false;
        }
        
        const payload = buildResumeAuthPayload(searchObject);        
        const response = await postWebService('web/auth/resume', payload);

        cbResumeAuth(response.data);
	};

    return (
        <ResponsiveContainer
            flag={"userFlow"}
            child={
                <>
                    <MaterialAppBar/>
                    <Box className={classes.page}>
                        {!state.existingQuotationDetails ?
                            <Box style={{'textAlign': 'center'}}>
                                <Spinner className={classes.spinner}/>
                            </Box> :
                            <Box id="mainBlock" className={classes.main}>
                                {canBuy ?
                                <Box m={2}>
                                    {loader &&
                                    <>
                                        <Typography>Quotation creation is in progress, please wait you will be redirected to payment page...</Typography>
                                        <SimpleBackdrop />
                                    </>}
                                    {errors.length > 0 && errors.map((item, index) => {
                                        return index === 0 && <Typography
                                            key={index}
                                            className="mb-3"
                                            style={{'textAlign': 'center', 'color': 'red'}}
                                        >{item}</Typography>
                                    })}
                                </Box> :
                                <>
                                    <Box className={classes.card}>
                                        <Box className={classes.cardBody}>
                                            <Typography className={classes.error}>
                                                You are only eligible for this offer within 24 hours of the invoice date. For any queries or assistance, please contact us at +91-9389-938-938.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </>
                                }
                            </Box>
                        }
                    </Box>
                </>
            }
        />
    );
};

export default withStyles(useStyles, {withTheme: true})(ProductDescription);
