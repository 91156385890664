import {createStyles} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    paper: {
        boxShadow: '0 0 16px 0 rgba(192, 192, 192, 0.5) !important',
        [theme.breakpoints.down("md")]: {
            width: "280px !important",
            height: "140px !important",
            borderRadius: "12px !important",
        },
        [theme.breakpoints.up("md")]: {
            width: "280px !important",
            height: "148px !important",
            borderRadius: "10px !important",
        },
    },
    paperInner: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "start",
        }
    },
    iconCS: {
        display: "flex",
        justifyContent: "start",
        [theme.breakpoints.down("md")]: {
            paddingLeft: "40px",
            paddingTop: "41px",
            paddingBottom: "5px",
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: "40px",
            paddingTop: "10px",
            paddingBottom: "0",
        },
    },

    middleText: {
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "24px",
            paddingTop: "10px",
            marginTop: "21px"
        },
        [theme.breakpoints.down("md")]: {
            paddingLeft: "24px",
            paddingTop: "10px",
            marginTop: "21px"
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: "21px",
            paddingTop: "0",
            marginTop: "5px"
        },
        [theme.breakpoints.up("lg")]: {
            paddingLeft: "21px",
            paddingTop: "5px",
            marginTop: "0"
        },
    },

    h2: {
        fontWeight: "Bold",
    },
    product: {
        [theme.breakpoints.down("md")]: {
            fontWeight: "400",

        },
        [theme.breakpoints.up("md")]: {
            fontWeight: "400",
            fontSize: "18px",
        },
    },

});