import moment from 'moment';

export const timeSlot = {
  start: '09:30',
  end: '17:30'
};
const today = moment( '', 'dd');
export const dateSlot = {
  start: today,
  end: today.add(15,'day')
};

export const options = {
  timeSlots: [
    {title: "09:30", value: "09:30"},
    {title: "10:30", value: "10:30"},
    {title: "11:30", value: "11:30"},
    {title: "12:30", value: "12:30"},
    {title: "13:30", value: "13:30"},
    {title: "14:30", value: "14:30"},
    {title: "15:30", value: "15:30"},
    {title: "16:30", value: "16:30"},
    {title: "17:30", value: "17:30"},
  ]
};

export const totalSteps = 3;