import {Strings} from "./strings";
import {QuestionType} from "../utilities/questionBuilder/questionTypes";
import {Keys} from "./keys";
import {TextInputType} from "../utilities/questionBuilder/textInputType";
import {InputValidator} from "../utilities/questionBuilder/inputValidator";
import {Products} from "./products";

export const Lifestyle = {
    data: {
        key: Keys.lifestyleInfo,
        body: Strings.lifestyleDetails,
        title: Strings.provideHealthHistory,
        questions: [
            {
                type: QuestionType.toggle,
                key: Keys.tobacco,
                placeholder: Strings.currentlySmoke,
                hasSubQuestions: true,
                readonly: true,
                initial: false,
                products: {
                    48: [true],
                    52: [true],
                    53: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.checkbox,
                key: Keys.tobaccoAs,
                validation: [InputValidator.required],
                placeholder: Strings.tobaccoProducts,
                expand: {
                    [Keys.tobacco]: [true]
                },
                inputs: [
                    {value: Keys.bidi, label: Strings.bidi},
                    {value: Keys.gutka, label: Strings.gutka},
                    {value: Keys.pan, label: Strings.panMasala},
                    {value: Keys.cigarettes, label: Strings.cigarette},
                    {value: Keys.cigar, label: Strings.cigar},
                ],
                products: {
                    48: [true],
                    52: [true],
                    53: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.tobaccoQuantity,
                validation: [InputValidator.required],
                placeholder: Strings.frequencyConsumption,
                expand: {
                    [Keys.tobacco]: [true]
                },
                inputs: [
                    {value: Keys.lessThan5, label: Strings.lessThan5},
                    {value: Keys.between6and10, label: Strings.sixToTen},
                    {value: Keys.moreThan10, label: Strings.moreThan10},
                ],
                products: {
                    48: [true],
                    52: [true],
                    53: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.slider,
                key: Keys.tobaccoYears,
                validation: [InputValidator.required],
                placeholder: Strings.consumingFor,
                expand: {
                    [Keys.tobacco]: [true]
                },
                min: 1,
                max: 50,
                step: 1,
                initial: 5,
                products: {
                    48: [true],
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.alcohol,
                validation: [InputValidator.required],
                placeholder: Strings.consumeAlcohol,
                hasSubQuestions: true,
                initial: false,
                products: {
                    48: [true],
                    52: [true],
                    53: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.checkbox,
                key: Keys.alcoholAs,
                validation: [InputValidator.required],
                placeholder: Strings.whichAlcoholProducts,
                expand: {
                    [Keys.alcohol]: [true]
                },
                inputs: [
                    {value: Keys.beer, label: Strings.beer},
                    {value: Keys.liquor, label: Strings.liquor},
                    {value: Keys.wine, label: Strings.wine},
                ],
                products: {
                    48: [true],
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.alcoholFrequency,
                validation: [InputValidator.required],
                placeholder: Strings.consumptionFrequency,
                expand: {
                    [Keys.alcohol]: [true]
                },
                inputs: [
                    {value: Keys.frequently, label: Strings.frequently},
                    {value: Keys.occasionally, label: Strings.occasionally},
                ],
                products: {
                    48: [true],
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.dailyAlcoholConsumptionFrequency,
                validation: [InputValidator.required],
                placeholder: Strings.dailyAlcoholConsumptionFrequency,
                expand: {
                    [Keys.alcohol]: [true]
                },
                inputs: [
                    {value: Keys.lessThanThirty, label: Strings.lessThanThirty},
                    {value: Keys.thirtyToOneHundredEighty, label: Strings.thirtyToOneHundredEighty},
                    {value: Keys.moreThanOneHundredEighty, label: Strings.moreThanOneHundredEighty},
                ],
                products: {
                    48: [true],
                    52: [true],
                    53: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.slider,
                key: Keys.alcoholYears,
                validation: [InputValidator.required],
                placeholder: Strings.forHowManyYearsConsumingAlcohol,
                expand: {
                    [Keys.alcohol]: [true]
                },
                min: 1,
                max: 50,
                step: 1,
                initial: 5,
                products: {
                    48: [true],
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.narcotics,
                validation: [InputValidator.required],
                placeholder: Strings.consumeNarcotics,
                initial: false,
                products: {
                    48: [true],
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.toggle,
                placeholder: Strings.areYouPregnant,
                key: Keys.areYouPregnant,
                validation: [InputValidator.required],
                expand: {
                    [Keys.title]: "Ms" || "ms",
                },
                products: {
                    58: [true],
                },
            },
            {
                type: QuestionType.toggle,
                placeholder: Strings.coveredPnbLifeSuraksha,
                key: Keys.coveredPnbLifeSuraksha,
                validation: [InputValidator.required],
                products: {
                    58: [true],
                },
            },
            {
                type: QuestionType.toggle,
                placeholder: Strings.postponedPnb,
                key: Keys.postponedPnb,
                validation: [InputValidator.required],
                products: {
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.specifyDetails,
                key: Keys.postponedPnbDetails,
                validation: [InputValidator.required],
                textInputType: TextInputType.text,
                expand: {
                    [Keys.postponedPnb]: [true]
                },
                products: {
                    58: [true],
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.hazardousSport,
                validation: [InputValidator.required],
                placeholder: Strings.takePartHazardousSports,
                hasSubQuestions: true,
                initial: false,
                products: {
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.specifyDetails,
                key: Keys.hazardousSportDetails,
                validation: [InputValidator.required],
                minLength: 3,
                maxLength: 50,
                textInputType: TextInputType.text,
                expand: {
                    [Keys.hazardousSport]: [true]
                },
                products: {
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.dangerousOccupation,
                validation: [InputValidator.required],
                placeholder: Strings.currentOccupationAssociated,
                hasSubQuestions: true,
                initial: false,
                products: {
                    48: [true],
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.specifyDetails,
                key: Keys.dangerousOccupationDetails,
                validation: [InputValidator.required],
                minLength: 3,
                maxLength: 50,
                textInputType: TextInputType.text,
                expand: {
                    [Keys.dangerousOccupation]: [true]
                },
                products: {
                    48: [true],
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.criminalProceedings,
                validation: [InputValidator.required],
                placeholder: Strings.historyOfConviction,
                hasSubQuestions: true,
                initial: false,
                products: {
                    52: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.specifyDetails,
                key: Keys.criminalProceedingsDetails,
                validation: [InputValidator.required],
                minLength: 3,
                maxLength: 50,
                textInputType: TextInputType.text,
                expand: {
                    [Keys.criminalProceedings]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.armedForces,
                validations: [InputValidator.required],
                placeholder: Strings.armForce,
                hasSubQuestions: true,
                products: {
                    48: [true],
                    52: [true],
                },
            },
            {
                type: QuestionType.text,
                key: Keys.rankR,
                placeholder: Strings.rank,
                validations: [InputValidator.required],
                textInputType: TextInputType.text,
                expand: {
                    [Keys.armedForces]: [true]
                },
                products: {
                    48: [true],
                    52: [true],
                },
            },
            {
                type: QuestionType.text,
                key: Keys.departmentDivision,
                placeholder: Strings.departmentDivision,
                validations: [InputValidator.required],
                textInputType: TextInputType.text,
                expand: {
                    [Keys.armedForces]: [true]
                },
                products: {
                    48: [true],
                    52: [true],
                },
            },
            {
                type: QuestionType.date,
                placeholder: Strings.lastMedical,
                key: Keys.lastMedical,
                validations: [InputValidator.required],
                min: -100,
                max: -18,
                expand: {
                    [Keys.armedForces]: [true]
                },
                products: {
                    48: [true],
                    52: [true],
                },
            },


            {
                type: QuestionType.toggle,
                key: Keys.political,
                validation: [InputValidator.required],
                placeholder: Strings.politicallyExposed,
                hasSubQuestions: true,
                initial: false,
                products: {
                    48: [true],
                    52: [true],
                },
            },
            {
                type: QuestionType.text,
                key: Keys.politicalDetails,
                placeholder: Strings.politicallyExposedDetails,
                validation: [InputValidator.required],
                textInputType: TextInputType.text,
                expand: {
                    [Keys.political]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.haveOtherLifeInsuranceR,
                placeholder: Strings.haveOtherInsurance,
                hasSubQuestions: true,
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.otherLifeInsurancePolicyType1,
                validation: [InputValidator.required],
                placeholder: Strings.policyType,
                inputs: [
                    {value: Keys.traditional, label: Strings.traditional},
                    {value: Keys.unitLinked, label: Strings.unitLinked},
                    {value: Keys.pension, label: Strings.pension},
                    {value: Keys.health, label: Strings.health},
                    {value: Keys.termPlan, label: Strings.termPlan},
                ],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.otherLifeInsuranceCommencementYear1,
                validation: [InputValidator.required],
                placeholder: Strings.commencementYear,
                inputs: [
                    {value: Strings.twentySeventeen, label: Strings.twentySeventeen},
                    {value: Strings.twentyEighteen, label: Strings.twentyEighteen},
                    {value: Strings.twentyNineteen, label: Strings.twentyNineteen},
                    {value: Strings.twentyTwenty, label: Strings.twentyTwenty},
                ],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.text,
                key: Keys.otherLifeInsuranceSumInsured1,
                validation: [InputValidator.required, InputValidator.numeric, InputValidator.indiaFirstMinSumInsured],
                placeholder: Strings.sumInsuredExist,
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.otherLifeInsurancePolicyStatus1,
                validation: [InputValidator.required],
                placeholder: Strings.policyStatus,
                inputs: [
                    {value: Keys.standard, label: Strings.standard},
                    {value: Keys.ratedUp, label: Strings.ratedUp},
                    {value: Keys.declined, label: Strings.declined},
                    {value: Keys.lapsed, label: Strings.lapsed},
                    {value: Keys.rejected, label: Strings.rejected},
                    {value: Keys.postponed, label: Strings.postponed},
                ],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                readonly: true,
                type: QuestionType.text,
                key: Keys.otherLifeInsurancePersonName1,
                placeholder: Strings.nameOfLifeAssured,
                validation: [InputValidator.required],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.text,
                key: Keys.otherLifeInsuranceCompanyName1,
                placeholder: Strings.nameOfCompany,
                validation: [InputValidator.required],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.otherLifeInsurancePolicyType2,
                placeholder: Strings.policyType,
                inputs: [
                    {value: Keys.traditional, label: Strings.traditional},
                    {value: Keys.unitLinked, label: Strings.unitLinked},
                    {value: Keys.pension, label: Strings.pension},
                    {value: Keys.health, label: Strings.health},
                    {value: Keys.termPlan, label: Strings.termPlan},
                ],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.otherLifeInsuranceCommencementYear2,
                placeholder: Strings.commencementYear,
                inputs: [
                    {value: Strings.twentySeventeen, label: Strings.twentySeventeen},
                    {value: Strings.twentyEighteen, label: Strings.twentyEighteen},
                    {value: Strings.twentyNineteen, label: Strings.twentyNineteen},
                    {value: Strings.twentyTwenty, label: Strings.twentyTwenty},
                ],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.text,
                key: Keys.otherLifeInsuranceSumInsured2,
                validation: [InputValidator.numeric, InputValidator.indiaFirstMinSumInsured],
                placeholder: Strings.sumInsuredExist,
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.otherLifeInsurancePolicyStatus2,
                placeholder: Strings.policyStatus,
                inputs: [
                    {value: Keys.standard, label: Strings.standard},
                    {value: Keys.ratedUp, label: Strings.ratedUp},
                    {value: Keys.declined, label: Strings.declined},
                    {value: Keys.lapsed, label: Strings.lapsed},
                    {value: Keys.rejected, label: Strings.rejected},
                    {value: Keys.postponed, label: Strings.postponed},
                ],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                readonly: true,
                type: QuestionType.text,
                key: Keys.otherLifeInsurancePersonName2,
                placeholder: Strings.nameOfLifeAssured,
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.text,
                key: Keys.otherLifeInsuranceCompanyName2,
                placeholder: Strings.nameOfCompany,
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            }
        ],
    },
};
