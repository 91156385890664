import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import {Call, Mail} from "@material-ui/icons";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import {withRouter} from "react-router-dom";

const useStyles = (theme) => ({
    OuterBox: {
        marginTop: "80px",
    },
    title: {
        textAlign: "center",
        fontWeight: "bold",
        letterSpacing: "0.5px",
        color: "#22334f",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "30px",
        },
    },
    backedTitle:{
        textAlign: "center",
        fontWeight: "bold",
        letterSpacing: "0.5px",
        color: "#ffffff",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "30px",
        },
    },
    featuredLogos:{
        [theme.breakpoints.down('sm')]: {
            width: "33.33%",
            margin: "0 10px 30px 10px",
        },
    },
    backedLogos:{
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
    },
    logos:{
        [theme.breakpoints.down('sm')]: {
            width: "25%"
        },
    },
    logosInner:{
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
    },
    backedBox: {
        borderTopLeftRadius: "264px",
        borderBottomRightRadius: "264px",
        backgroundColor: "#ff585d",
        zIndex: 1,
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            borderTopLeftRadius: "100px",
            borderBottomRightRadius: "100px",
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(8),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            marginLeft: theme.spacing(8),
            marginRight: theme.spacing(8),
            borderTopLeftRadius: "150px",
            borderBottomRightRadius: "150px",
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(8),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            marginLeft: theme.spacing(8),
            marginRight: theme.spacing(8),
            borderTopLeftRadius: "200px",
            borderBottomRightRadius: "200px",
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(8),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            marginLeft: theme.spacing(8),
            marginRight: theme.spacing(8),
            borderTopLeftRadius: "264px",
            borderBottomRightRadius: "264px",
        },
    },
    featuresBox:{
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingLeft: theme.spacing(20),
            paddingRight: theme.spacing(20),
            paddingBottom: theme.spacing(6),
        },
    },
    footerBox: {
        width: "100%",
        backgroundColor: "#22334f",
        zIndex: -1,
        [theme.breakpoints.down('sm')]: {
            height: "1044px",
            marginTop: "-50px",
            borderTopRightRadius: "100px",
        },
        [theme.breakpoints.up('sm')]: {
            // height: "544px",
            marginTop: "-30px",
            borderTopRightRadius: "150px",
        },
        [theme.breakpoints.up('md')]: {
            height: "544px",
            marginTop: "-200px",
            borderTopRightRadius: "264px",
        },
    },
    footerInner:{
        display: "flex",
        [theme.breakpoints.down('sm')]: {
           flexDirection: "column",
            justifyContent: "justify",
            paddingTop: "80px"
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: "space-around",
        },
        [theme.breakpoints.up('md')]: {
            display: "flex",
            justifyContent: "space-around",
            paddingTop: "240px"
        },
    },
    footerText:{
        color: "#ffffff",
        fontSize: "16px",
        opacity: "0.8",
    },
    footerCorporate:{
        lineHeight: "2.25"
    },
    footerAddress:{
        lineHeight: "1.44"
    },
    footerLink:{
        fontSize: "16px",
        lineHeight: "2.25",
        color: "#ffffff",
        opacity: "0.8",
    },
    links:{
        color: "#ffffff",
        opacity: "0.8",
        cursor: "pointer",
        "&:hover":{
            color: "#ff585d !important",
            textDecoration: "none",
            opacity: "1",
        },
    },
    globalLinks:{
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContent: "justify",
            flexWrap:"wrap",
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('md')]: {
            display: "flex",
            justifyContent: "space-around",
            // flexWrap:"wrap",
            padding: theme.spacing(3),
        },
        [theme.breakpoints.up('md')]: {
            display: "block",

        },
    },
    mobileLinks:{
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    footerCorpOuter:{
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    addressOuter:{
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
            paddingLeft: theme.spacing(5),
        },
        [theme.breakpoints.up('sm')]: {
            margin: "auto",
        },
    },
    footerBottom:{
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
            justifyContent: "justify",
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: "space-around",
            margin: "auto",
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: "space-around",
            margin: "auto",
        },

    },
    privacy:{
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            justifyContent: "justify",
            paddingLeft: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: "space-around",
            width: "50%"
        },
    },
    social:{
        display:"flex",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "space-around",
            width: "100%",
            paddingBottom: theme.spacing(3),
        },
    },
    logoCopyRight:{
        display:"flex",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "justify",
            // paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.up('sm')]: {
            margin: "auto",
        },
    },
    logo:{
        width: "116px",
        height: "79px"
    },
    socialLinks:{
        color: "#ffffff",
        "&:hover":{
            color: "#ff585d",
        }
    }
})

class FeaturedBacked extends Component {
    handleRoute = (route) => {
        this.props.history.push(route)
    };
    render() {
        const {classes} = this.props;
        return (
            <>
                <Box className={classes.OuterBox}>
                    <Box>
                        <Typography className={classes.title}>
                            Featured in
                        </Typography>
                        <Box className={classes.featuresBox}>
                            <Box className={classes.featuredLogos}>
                                <img
                                    src={require("../../../assets/img/forbes.png")}
                                    className={classes.logosInner}
                                    alt=""
                                />
                            </Box>
                            <Box className={classes.featuredLogos}>
                                <img
                                    src={require("../../../assets/img/busineessindia.png")}
                                    className={classes.logosInner}
                                    alt=""
                                />
                            </Box>
                            <Box className={classes.featuredLogos}>
                                <img
                                    src={require("../../../assets/img/inc42.png")}
                                    className={classes.logosInner}
                                    alt=""
                                />
                            </Box>
                            <Box className={classes.featuredLogos}>
                                <img
                                    src={require("../../../assets/img/timesofindia.png")}
                                    className={classes.logosInner}
                                    alt=""
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.backedBox}>
                        <Box>
                        <Typography className={classes.backedTitle} align={"center"}>
                            Backed by
                        </Typography>
                        </Box>
                        <Box className={classes.backedLogos}>
                        <Box className={classes.logos}>
                            <img
                                src={require("../../../assets/img/iciclogo.png")}
                                alt=""
                                className={classes.logosInner}
                            />
                        </Box>
                        <Box className={classes.logos}>
                            <img
                                src={require("../../../assets/img/indiafirstlogo.png")}
                                alt=""
                                className={classes.logosInner}
                            />
                        </Box>
                        <Box className={classes.logos}>
                            <img
                                src={require("../../../assets/img/hdfclogo.png")}
                                alt=""
                                className={classes.logosInner}
                            />
                        </Box>
                        <Box className={classes.logos}>
                            <img
                                src={require("../../../assets/img/religarelogo.png")}
                                alt=""
                                className={classes.logosInner}
                            />
                        </Box>
                        <Box className={classes.logos}>
                            <img
                                src={require("../../../assets/img/tataaiglogo.png")}
                                alt=""
                                className={classes.logosInner}
                            />
                        </Box>
                        <Box className={classes.logos}>
                            <img
                                src={require("../../../assets/img/manipallogo.png")}
                                alt=""
                                className={classes.logosInner}
                            />
                        </Box>
                        <Box className={classes.logos}>
                            <img
                                src={require("../../../assets/img/appollomuniclogo.png")}
                                alt=""
                                className={classes.logosInner}
                            />
                        </Box>
                    </Box>
                    </Box>
                    <Box className={classes.footerBox}>
                        <Box className={classes.footerInner} flexWrap={"wrap"}>
                            <Box m={"auto"}>
                                <img
                                    src={require("../../../assets/img/logo.svg")}
                                    className={classes.logo}
                                    alt=""
                                />
                            </Box>
                            <Box className={classes.footerCorpOuter}>
                                <Typography className={classes.footerText +' '+ classes.footerCorporate}>
                                    Corporate Agent (Composite)
                                </Typography>
                                <Typography className={classes.footerText +' '+ classes.footerCorporate}>
                                    Design For Use Consulting Pvt. Ltd.
                                </Typography>
                                <Typography className={classes.footerText +' '+ classes.footerCorporate}>
                                    IRDAI Registration Number: CA0524
                                </Typography>
                                <Typography className={classes.footerText +' '+ classes.footerCorporate}>
                                    Valid Till: 22OCT2020
                                </Typography>
                                <Typography className={classes.footerText +' '+ classes.footerCorporate}>
                                    CIN: U74140DL2007PTC157638
                                </Typography>
                            </Box>
                            <Hidden smUp>
                                <Divider/>
                            </Hidden>
                            <Box  className={classes.globalLinks}>
                                <Typography className={classes.footerLink}>
                                    <Link onClick={()=>{this.handleRoute("/")}} color="inherit" className={classes.links + ' ' + classes.mobileLinks}>
                                        About
                                    </Link>
                                </Typography>
                                <Typography className={classes.footerLink}>
                                    <Link onClick={()=>{this.handleRoute("/")}} color="inherit" className={classes.links + ' ' + classes.mobileLinks}>
                                        Contact
                                    </Link>
                                </Typography>
                                <Typography className={classes.footerLink}>
                                    <Link onClick={()=>{this.handleRoute("/")}} color="inherit" className={classes.links + ' ' + classes.mobileLinks}>
                                        Careers
                                    </Link>
                                </Typography>
                                <Typography className={classes.footerLink}>
                                    <Link onClick={()=>{this.handleRoute("/")}} color="inherit" className={classes.links + ' ' + classes.mobileLinks}>
                                        Blog
                                    </Link>
                                </Typography>
                                <Typography className={classes.footerLink}>
                                    <Link onClick={()=>{this.handleRoute("/")}} color="inherit" className={classes.links + ' ' + classes.mobileLinks}>
                                        FAQ
                                    </Link>
                                </Typography>
                            </Box>
                            <Hidden smUp>
                                <Divider/>
                            </Hidden>
                            <Box className={classes.addressOuter}>
                                <Typography className={classes.footerText + ' ' + classes.footerAddress}>
                                    308-309 Tower C, Unitech
                                </Typography>
                                <Typography className={classes.footerText + ' ' + classes.footerAddress}>
                                    Business Zone, Sector 50
                                </Typography>
                                <Typography className={classes.footerText + ' ' + classes.footerAddress}>
                                    Golf Course Extension Road,
                                </Typography>
                                <Typography className={classes.footerText + ' ' + classes.footerAddress}>
                                    Gurugram, Haryana 122002
                                </Typography>
                                <Box pt={2} pb={2}>
                                <Typography className={classes.footerText}>
                                    <Link color="inherit" className={classes.links} onClick={(e)=>{e.preventDefault()}}>
                                        <Call/> +91 9389 9389 38
                                    </Link>
                                </Typography>
                                </Box>
                                <Box pb={2}>
                                <Typography className={classes.footerText} >
                                    <Link className={classes.links} onClick={(e)=>{e.preventDefault()}}>
                                        <Mail/> hello@toffeeinsurance.com
                                    </Link>
                                </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider light={true}/>
                        <Box className={classes.footerBottom} pt={4} flexWrap={"wrap"}>
                            <Hidden smDown>
                            <Box className={classes.logoCopyRight}>
                                <Box mr={1}>
                                    <img
                                        src={require("../../../assets/img/grey-logo.png")}
                                        alt=""
                                    />
                                </Box>
                                <Box m={"auto"}>
                                    <Typography className={classes.footerText}>
                                        © 2020
                                    </Typography>
                                </Box>
                            </Box>
                            </Hidden>
                            <Box className={classes.privacy}>
                            <Box>
                                <Typography className={classes.footerText}>
                                    <Link onClick={()=>{this.handleRoute("/")}} color="inherit" className={classes.links}>
                                        Privacy Policy
                                    </Link>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography className={classes.footerText}>
                                    <Link onClick={()=>{this.handleRoute("/")}} color="inherit" className={classes.links}>
                                        Cancellation & Refund
                                    </Link>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography className={classes.footerText}>
                                    <Link onClick={()=>{this.handleRoute("/")}} color="inherit" className={classes.links}>
                                        T&C
                                    </Link>
                                </Typography>
                            </Box>
                            </Box>
                            <Box className={classes.social} >
                                <Box pr={3}>
                                    <Link onClick={()=>{this.handleRoute("/")}} className={classes.links}>
                                        <i className={"fab fa-youtube " + classes.socialLinks}/>
                                    </Link>
                                </Box>
                                <Box pr={3}>
                                    <Link onClick={()=>{this.handleRoute("/")}} className={classes.links}>
                                        <i className={"fab fa-twitter " + classes.socialLinks}/>
                                    </Link>
                                </Box>
                                <Box pr={3}>
                                    <Link onClick={()=>{this.handleRoute("/")}} className={classes.links}>
                                        <i className={"fab fa-linkedin-in " + classes.socialLinks}/>
                                    </Link>
                                </Box>
                                <Box pr={3}>
                                    <Link onClick={()=>{this.handleRoute("/")}} className={classes.links}>
                                        <i className={"fab fa-instagram " + classes.socialLinks}/>
                                    </Link>
                                </Box>
                                <Box pr={3}>
                                    <Link onClick={()=>{this.handleRoute("/")}} className={classes.links}>
                                        <i className={"fab fa-facebook-f " + classes.socialLinks}/>
                                    </Link>
                                </Box>
                            </Box>
                            <Hidden smUp>
                                <Box className={classes.logoCopyRight}>
                                    <Box mr={1}>
                                    <img
                                        src={require("../../../assets/img/grey-logo.png")}
                                        alt=""
                                    />
                                    </Box>
                                    <Box m={"auto"}>
                                    <Typography className={classes.footerText}>
                                        © 2020
                                    </Typography>
                                    </Box>
                                </Box>
                            </Hidden>
                        </Box>
                    </Box>
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.customer.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
    };
};
export default withStyles(useStyles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(withRouter(FeaturedBacked)));
