import React from "react";
import DateTime from "./DateTime";
import InputField from "./InputField";
import Location from "./Location";
import TextArea from "./TextArea";
import MultiSelect from "./MultiSelect";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import API from "../../../utilities/api";

class Inputs extends React.Component {
  addedInputs = [];

  state = {
    addedInputs: [],
    position: 0,
  };
  constructor(props) {
    super(props);
    this.props.requiredInputs.length < 1 &&
      this.props.setState({ currentComponent: "documents" });
  }

  addInput = (data) => {
    // console.log(data);
    this.setState({
      addedInputs: this.state.addedInputs.concat(data),
      position: this.state.position + 1,
    });
    this.addedInputs.push(data);
    // console.log(this.addedInputs);
    // this.setState({
    // })
    if (this.state.position >= this.props.requiredInputs.length - 1) {
      this.props.onCallShowLoader(true);

      API.post("web/claim/inputs/" + this.props.claimId, {
        inputs: this.addedInputs,
      })
        .then((response) => {
          this.props.onCallShowLoader(false);
          this.props.setState({
            currentComponent: "documents",
          });
        })
        .catch((err) => {
          this.props.onCallShowLoader(false);
        });
    }
  };
  render() {
    return (
      <>
        {/* <Location></Location> */}
        {this.props.requiredInputs[this.state.position] &&
          this.props.requiredInputs[this.state.position].type ===
            "location" && (
            <Location
              position={{
                current: this.state.position + 1,
                total: this.props.requiredInputs.length + 1,
              }}
              addInput={(data) => {
                this.addInput(data);
              }}
              input={
                this.props.requiredInputs[this.state.position]
                  ? this.props.requiredInputs[this.state.position]
                  : {}
              }
              setState={(data) => this.props.setState(data)}
            ></Location>
          )}
        {this.props.requiredInputs[this.state.position] &&
          this.props.requiredInputs[this.state.position].type ===
            "calendar" && (
            <DateTime
              position={{
                current: this.state.position + 1,
                total: this.props.requiredInputs.length + 1,
              }}
              addInput={(data) => {
                this.addInput(data);
              }}
              input={
                this.props.requiredInputs[this.state.position]
                  ? this.props.requiredInputs[this.state.position]
                  : {}
              }
              setState={(data) => this.props.setState(data)}
            ></DateTime>
          )}
        {this.props.requiredInputs[this.state.position] &&
          this.props.requiredInputs[this.state.position].type === "text" && (
            <InputField
              position={{
                current: this.state.position + 1,
                total: this.props.requiredInputs.length + 1,
              }}
              addInput={(data) => {
                this.addInput(data);
              }}
              input={
                this.props.requiredInputs[this.state.position]
                  ? this.props.requiredInputs[this.state.position]
                  : {}
              }
              setState={(data) => this.props.setState(data)}
            ></InputField>
          )}
        {this.props.requiredInputs[this.state.position] &&
          this.props.requiredInputs[this.state.position].type ===
            "textarea" && (
            <TextArea
              position={{
                current: this.state.position + 1,
                total: this.props.requiredInputs.length + 1,
              }}
              addInput={(data) => {
                this.addInput(data);
              }}
              input={
                this.props.requiredInputs[this.state.position]
                  ? this.props.requiredInputs[this.state.position]
                  : {}
              }
              setState={(data) => this.props.setState(data)}
            ></TextArea>
          )}
        {this.props.requiredInputs[this.state.position] &&
          this.props.requiredInputs[this.state.position].type ===
            "multiselect" && (
            <MultiSelect
              position={{
                current: this.state.position + 1,
                total: this.props.requiredInputs.length + 1,
              }}
              addInput={(data) => {
                this.addInput(data);
              }}
              input={
                this.props.requiredInputs[this.state.position]
                  ? this.props.requiredInputs[this.state.position]
                  : {}
              }
              setState={(data) => this.props.setState(data)}
            ></MultiSelect>
          )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // onGetClaimArray: () => dispatch(actions.getClaimTypesArray()),
    // onUpdateInputsDocs: (data) => dispatch(actions.updateInputsDocs(data)),
    onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
  };
};
export default connect(null, mapDispatchToProps)(Inputs);
