import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles, Grid, Chip, TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const useStyles = (theme) => ({
  chip: {
    backgroundColor: "rgba(255, 88, 93, 0.1)",
    borderRadius: "19px",
    color: "#22334f",
    padding: "12px 36px",
  },
  text: {
    opacity: "0.7",
    fontSize: "15px",
    fontWeight: "bold",
    letterSpacing: "0.5px",
    color: "#22334f",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
  divider: {
    margin: "0 10px",
  },
});

class BackedChip extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.chip}>
        <Grid container>
          <Grid item>
            <Typography className={classes.text}>{this.props.left}</Typography>
          </Grid>
          <Divider
            orientation="vertical"
            className={classes.divider}
            flexItem
          />
          <Grid item>
            <Typography className={classes.text}>{this.props.right}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(BackedChip);
