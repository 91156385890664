import React, {useEffect} from "react";
import "./App.css";
import "../src/assets/css/responsive.css";
import "../src/assets/css/all.min.css";
import "../src/assets/css/bootstrap-datepicker.min.css";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/bootstrap.min.css.map";
import "../src/assets/css/owl.carousel.min.css";
import "../src/assets/css/styles.css";
import {Route, Switch, useLocation} from "react-router-dom";
import Initiate from "./views/pages/Claims/Initiate";
import Feedback from "./views/pages/Feedback/Feedback";
import Auth from "./views/pages/Auth/Auth";
import Login from "./views/pages/Login/login";
import Home from "./views/pages/Home/home";
import VerifyDetails from "./views/pages/VerifyDetails/VerifyDetails";
import Confirmation from "./views/pages/Confirmation/confirmation";
import TermsAndConditions from "./views/pages/VerifyDetails/CutomerVerification/TermsAndConditions";
import BuyFlow from "./views/pages/Buy/buy";
import ProductPage from "./views/pages/Product/product";
import LeadPage from "./views/pages/Lead/lead";
import MyPolicy from "./views/pages/Policy/policy";
import CalculatePremiumBuyflow from "./views/pages/Calculate/calculate";
import {connect} from "react-redux";
import BankSelect from "./views/pages/VerifyDetails/CutomerVerification/bankSelect";
import * as ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import MedicalSuccess from "./views/pages/VerifyDetails/CutomerVerification/medicalSuccess";
import ProductDescription from './views/pages/Buy/product';
// import ProductBuy from './views/pages/Buy/productBuy';
import ProductBuyPayment from './views/pages/Buy/productBuyPayment';
import ProductBuyConfirmation from './views/pages/Buy/productBuyConfirmation';

function usePageViews(effect, deps) {
    let location = useLocation()
    useEffect(() => {
        ReactGA.initialize('UA-105404823-2', {
            debug: false,
            titleCase: false,
        });

            gtag_report_conversion()
            ReactGA.pageview(window.location.pathname);

            facebook();
            window.fbq('init', '621454878417836');
            window.fbq('track', 'PageView');

            const tagManagerArgs = {
                gtmId: 'GTM-TX69D5R',
            }
            TagManager.initialize(tagManagerArgs)

        }, [location],
    )
}

function gtag() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(arguments);
}

function gtag_report_conversion(url) {
    gtag('config', 'AW-808880541');
    return false;
}

function facebook() {
    return !function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');

}


function App() {
    usePageViews()
    return (
        <div className="App">
            <Switch>
                <Route path="/claims/:id/feedback" exact component={Feedback}/>
                <Route path="/claims" component={Initiate}/>
                <Route path="/auth" component={Auth}/>
                <Route path="/login" component={Login}/>
                <Route path="/confirmation" component={Confirmation}/>
                <Route path="/lead" component={LeadPage}/>
                <Route path="/terms" component={TermsAndConditions}/>
                <Route path="/products/:product" exact={true} component={ProductDescription} />
                {/*<Route path="/products/:product/buy" exact={true} component={ProductBuy} />*/}
                <Route path="/products/:product/buy/:id" exact={true} component={ProductBuyPayment} />
                <Route path="/products/:product/buy/:id/confirmation" component={ProductBuyConfirmation} />
                <Route path="/the-toffee-plan/buy" component={BuyFlow}/>
                <Route path="/the-toffee-plan/calculate-premium" component={CalculatePremiumBuyflow}/>
                <Route path="/the-toffee-plan/quotation/verify-pay-upload/select-bank" component={BankSelect}/>
                <Route path="/the-toffee-plan/quotation/success" component={MedicalSuccess}/>
                <Route path="/the-toffee-plan/quotation/verify-pay-upload" component={VerifyDetails}/>
                <Route path="/the-toffee-plan/quotation" component={Home}/>
                <Route path="/the-toffee-plan" component={ProductPage}/>
                <Route path="/lead" component={LeadPage}/>
                <Route path="/policies" component={MyPolicy}/>

                {/* <Route path="/" component={LandingPage} /> */}
                <Route path="/" component={Login}/>
            </Switch>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        language: state.customer.quotation,
    };
};

export default connect(mapStateToProps, null)(App);