import React, { Component } from "react";
import Resizer from "react-image-file-resizer";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import NotificationAlert from "react-notification-alert";
import API from "../../../utilities/api";

class AddPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
    };
  }

  notify = (place, title, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };
  detectImage = (e) => {
    const type = e.target.files[0].type.split("/")[0];
    if (type !== "image") {
      this.notify("tr", "", "Please select image only", "danger");
      return;
    }
    // console.log(e.target.files[0]);
    Resizer.imageFileResizer(
      e.target.files[0],
      1024,
      1024,
      "PNG",
      90,
      0,
      (uri) => {
        // console.log(uri)
        this.setState({
          images: this.state.images.concat([uri]),
        });
      },
      "blob"
    );

    // this.setState({
    //     images: this.state.images.concat([e.target.files[0]])
    // })
  };
  removeImage = (position) => {
    this.setState({
      images: this.state.images.filter(function (image, index, arr) {
        return index !== position;
      }),
    });
  };
  uploadImages = () => {
    if (this.state.images.length < 1) {
      this.notify("tr", "", "Please select at least one image", "danger");
      return;
    }
    if (
      this.props.document.name === "DOCUMENT_ID" &&
      this.state.images.length < 2
    ) {
      this.notify(
        "tr",
        "",
        "Please select two images for Aadhaar card",
        "danger"
      );
      return;
    }
    const data = new FormData();
    data.append("document_type_id", this.props.document.id);
    this.state.images.forEach((file) => {
      data.append("claimDocuments[]", file);
    });
    this.props.onCallShowLoader(true);

    API.post("web/claim/document/submit/" + this.props.claimId, data, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        this.props.onCallShowLoader(false);

        this.props.addToUploadedDocs(response.data.data);
        this.props.switchTo({
          action: "doc_list",
        });
      })
      .catch((err) => {
        this.props.onCallShowLoader(false);
      });
  };

  render() {
    return (
      <div className="component-container position-relative m-auto">
        <div className="p-4 d-flex align-items-center">
          <img
            className="icon-60 ml-n3"
            src={require("../../../assets/img/icons/image-icon.png")}
            alt=""
          />
          <h2 className=" font-weight-light txt-secondary d-inline m-0">
            {this.props.document.title}
          </h2>
        </div>
        <div className="px-4 pb-4">
          <div className="row m-0 mb-4 d-flex justify-content-around">
            <div className="col-4 position-relative bg-shadow-3 border-75 w-100 py-5 px-0 text-center">
              <label htmlFor="imageInput1" className="cursor-pointer w-100">
                <img
                  className="icon-35 m-0 mb-2"
                  src={require("../../../assets/img/icons/plus-icon.png")}
                  alt=""
                />
                <h5 className="m-0 font-weight-normal txt-secondary">
                  Add Photo
                </h5>
              </label>
              <input
                type="file"
                accept="image/*"
                hidden
                id="imageInput1"
                onChange={this.detectImage}
              />

              {this.state.images[0] && (
                <div className="w-100 h-100  left-0 top-0 position-absolute">
                  <img
                    className="icon-35 cursor-pointer right-0 m-0 mt-n2 mr-n3 position-absolute"
                    src={require("../../../assets/img/icons/close.png")}
                    alt=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.removeImage(0);
                    }}
                  />
                  <img
                    className="w-100  h-100 m-0"
                    src={URL.createObjectURL(this.state.images[0])}
                    alt=""
                  />
                </div>
              )}
            </div>
            <div className="col-4 position-relative bg-shadow-3 border-75 w-100 py-5 px-0 text-center">
              <label htmlFor="imageInput2" className="cursor-pointer w-100">
                <img
                  className="icon-35 m-0 mb-2"
                  src={require("../../../assets/img/icons/plus-icon.png")}
                  alt=""
                />
                <h5 className="m-0 font-weight-normal txt-secondary">
                  Add Photo
                </h5>
              </label>
              <input
                type="file"
                accept="image/*"
                hidden
                id="imageInput2"
                onChange={this.detectImage}
              />

              {this.state.images[1] && (
                <div className="w-100 h-100  left-0 top-0 position-absolute">
                  <img
                    className="icon-35 cursor-pointer right-0 m-0 mt-n2 mr-n3 position-absolute"
                    src={require("../../../assets/img/icons/close.png")}
                    alt=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.removeImage(1);
                    }}
                  />
                  <img
                    className="w-100  h-100 m-0"
                    src={URL.createObjectURL(this.state.images[1])}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row m-0 mb-4 d-flex justify-content-around">
            <div className="col-4 position-relative bg-shadow-3 border-75 w-100 py-5 px-0 text-center">
              <label htmlFor="imageInput3" className="cursor-pointer w-100">
                <img
                  className="icon-35 m-0 mb-2"
                  src={require("../../../assets/img/icons/plus-icon.png")}
                  alt=""
                />
                <h5 className="m-0 font-weight-normal txt-secondary">
                  Add Photo
                </h5>
              </label>
              <input
                type="file"
                accept="image/*"
                hidden
                id="imageInput3"
                onChange={this.detectImage}
              />

              {this.state.images[2] && (
                <div className="w-100 h-100  left-0 top-0 position-absolute">
                  <img
                    className="icon-35 cursor-pointer right-0 m-0 mt-n2 mr-n3 position-absolute"
                    src={require("../../../assets/img/icons/close.png")}
                    alt=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.removeImage(2);
                    }}
                  />
                  <img
                    className="w-100  h-100 m-0"
                    src={URL.createObjectURL(this.state.images[2])}
                    alt=""
                  />
                </div>
              )}
            </div>
            <div className="col-4 position-relative bg-shadow-3 border-75 w-100 py-5 px-0 text-center">
              <label htmlFor="imageInput4" className="cursor-pointer w-100">
                <img
                  className="icon-35 m-0 mb-2"
                  src={require("../../../assets/img/icons/plus-icon.png")}
                  alt=""
                />
                <h5 className="m-0 font-weight-normal txt-secondary">
                  Add Photo
                </h5>
              </label>
              <input
                type="file"
                accept="image/*"
                hidden
                id="imageInput4"
                onChange={this.detectImage}
              />

              {this.state.images[3] && (
                <div className="w-100 h-100  left-0 top-0 position-absolute">
                  <img
                    className="icon-35 cursor-pointer right-0 m-0 mt-n2 mr-n3 position-absolute"
                    src={require("../../../assets/img/icons/close.png")}
                    alt=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.removeImage(3);
                    }}
                  />
                  <img
                    className="w-100  h-100 m-0"
                    src={URL.createObjectURL(this.state.images[3])}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            "btn-primary cursor-pointer bg-shadow-3 px-4  d-flex justify-content-center align-items-center mobile-position-fixed footer-button " +
            (this.state.images.length > 0 ? "" : "opacity-50")
          }
          onClick={(e) => {
            e.preventDefault();
            this.uploadImages();
          }}
        >
          <h2 className="font-weight-normal txt-white">Done</h2>
        </div>

        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
  };
};
export default connect(null, mapDispatchToProps)(AddPhoto);
