import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import NotificationAlert from "react-notification-alert";
import {getDateSlots, getTimeSlots, nextAvialableDateSlot, nextAvialableTimeSlot} from "../../../../utilities/Utils";
import {timeSlot, dateSlot} from "../../../../default-values/medical";
import ChipSelectDateTime from "./chipSelectDateTime";
import ChipSelect from "../../Calculate/ChipSelect";
import API from "../../../../utilities/api";
import moment from "moment";
import {withRouter} from "react-router-dom";


const useStyles = (theme) => ({
    card: {
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.24)',
        maxWidth: '600px',

        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(5, 2),
        },
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(5),
        },
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(5),
        },
    },
    title: {
        color: "#22334f",
        [theme.breakpoints.down("sm")]: {
            fontWeight: "500",
            fontSize: "18px",
        },
        [theme.breakpoints.up("sm")]: {
            fontWeight: "bold",
            fontSize: "30px",
            letterSpacing: "1.5px",
        },
        [theme.breakpoints.up("md")]: {
            fontWeight: "bold",
            fontSize: "30px",
            letterSpacing: "1.5px",
        },
    },
    detailsText: {
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 3)
        },
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(0, 4)
        },
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(0, 4)
        },
    },
    detailsTextHeading: {
        textAlign: "center",
        fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "30px",
        },
        [theme.breakpoints.up("md")]: {
            fontSize: '48px',
        },
    },
    detailsTextHeading2: {
        textAlign: "center",
        fontWeight: '500',
        [theme.breakpoints.down("sm")]: {
            fontSize: "18px",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "26px",
            letterSpacing: "1.5px",
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "26px",
        },
    },
    content: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        margin: theme.spacing(2, 0, 10),

    },
    listItems: {
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '2.04',
        letterSpacing: '0.15px',
        color: '#142550',
    },
    pincodeLabel: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '1.58',
        letterSpacing: '0.2px',
        color: '#142550',
    },
    radioBox: {
        display: "flex",
        justifyContent: "start"
    },
    radioLabel: {
        borderBottom: '1px solid #ccc',
        padding: theme.spacing(2, 0)
    },
    radioTitle: {
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '1.58',
        letterSpacing: '0.15px',
        color: '#142550',
    },
    radioText: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '1.75',
        letterSpacing: '0.5px',
        color: '#142550',
    },
    monthBox: {
        display: "flex",
        flexDirection: 'column'
    },
    date: {
        cursor: 'pointer',
        width: '70px',
        height: '70px',
        border: '1px solid #ccc',
        borderRadius: '11px',
        display: 'flex',
        margin: theme.spacing(0, 2, 0, 0)
    },
    dateSelected: {
        cursor: 'pointer',
        width: '70px',
        height: '70px',
        border: '1px solid #ff585d',
        borderRadius: '11px',
        display: 'flex',
        margin: theme.spacing(0, 2, 0, 0),
        backgroundColor: theme.palette.primary.main
    },
    dateInner: {
        margin: 'auto'
    },
    whiteText: {
        color: '#ffffff'
    },
    successTextBox: {
        display: 'flex',
        margin: 'auto',
        maxWidth: "500px",
        height: '50vh',
        flexDirection: 'column'
    },
    bottomBox: {
        padding: theme.spacing(0, 0, 4, 0)
    }


});

class Medical extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            selectedAddress: '',
            pageDescription: 'Your policy requires you to undergo medical check-up for further processing of your application.',
            selectedDate: '',
            selectedTime: '',
            timeSlots: getTimeSlots(nextAvialableTimeSlot(), timeSlot.end),
            dateSlots: getDateSlots(),
            success: false,
            domReset: true
        };
    }

    availableCenters = [
        {name: 'Center 1', address: 'Lorem Ipsum address centre 1, 121102, Haryana'},
        {name: 'Center 2', address: 'Lorem Ipsum address centre 2, 121102, Haryana'},
        {name: 'Center 3', address: 'Lorem Ipsum address centre 3, 121102, Haryana'},
        {name: 'Center 4', address: 'Lorem Ipsum address centre 4, 121102, Haryana'},
    ]


    notify = (place, title, message, type) => {
        let options = {
            place: place,
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 2,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    scheduleAppointment = (step) => {
        if (step === 0) {
            this.setState({

                // step: this.state.step + 1,
                pageDescription: 'Your policy requires you to undergo medical check-up for further processing of your application.'
            })
            this.schedule({
                preferred_date: moment(this.state.selectedDate).format('yyyy-MM-DD'),
                preferred_time: this.state.selectedTime
            })
            return
        }
        // if (step === 1) {
        //     this.setState({
        //         step: this.state.step + 1,
        //         pageDescription: 'Your policy requires you to undergo medical check-up for further processing of your application.'
        //     })
        //     return
        // }
        // if (step === 1) {
        //     this.schedule({
        //         preferred_date: moment(this.state.selectedDate).format('yyyy-MM-DD'),
        //         preferred_time: this.state.selectedTime
        //     })
        //     return
        // }
    }
    handleRadio = (value) => {
        this.setState({
            selectedAddress: value
        })
    }
    selectedDateFunc = (value) => {
        this.setState({
            selectedDate: value
        })
    }
    // Chipset change handler
    handleChangeChipSelect = (name, value) => {
        this.setFormState(name, value);
    };
    setFormState = function (name, value) {
        if (name === 'time') {
            this.setState({
                selectedTime: value
            })
        }
        if (name === 'date') {
            this.setState({
                selectedDate: value
            }, () => {
                let startSlot = timeSlot.start;
                let date = moment(this.state.selectedDate).format('dd')
                if (date === moment().format('dd')) {
                    startSlot = nextAvialableTimeSlot(this.state.selectedDate)
                }
                this.setState({
                    timeSlots: getTimeSlots(startSlot, timeSlot.end),
                    domReset: false,
                    selectedTime: ''
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            domReset: true
                        })
                    }, 100)
                })
            })

        }
    };

    schedule(payload) {
        this.props.onCallShowLoader(true);
        let orderId = this.props.userDetails?.order_id
        API.post("initiate_medical/" + orderId, payload)
            .then((res) => {
                this.props.onCallShowLoader(false);
                this.setState({
                    success: true
                })
            })
            .catch((err) => {
                this.props.onCallShowLoader(false);
                console.log(err);
            });
    }


    render() {
        const {classes} = this.props;
        return (
            <Box>
                <Box className="rna-wrapper z-index-1" zIndex="tooltip" position="fixed">
                    <NotificationAlert ref="notificationAlert"/>
                </Box>
                <Box style={{backgroundColor: '#f9f9f9'}}>
                    <Box pt={3} pb={1}>
                        <Typography className={classes.title} align={"center"}>
                            {!this.state.success ? "Schedule Medical Appointment" : "Whats Next"}
                        </Typography>
                    </Box>
                    <Box pt={1} pb={3} className={this.state.success && classes.successTextBox}>
                        {this.state.step === 2 &&
                        <Typography className={classes.detailsTextHeading}>
                            {this.state.selectedAddress}
                        </Typography>
                        }
                        <Typography variant={"h6"} className={classes.detailsText}>
                            {!this.state.success ? this.state.pageDescription :
                                "You will receive an email and call to confirm the medical appointment. "
                            }
                        </Typography>
                        {this.state.success &&
                        <Box pt={2}>
                            <Typography variant={"h6"} className={classes.detailsText}>
                                Get the medical tests done as per schedule with no extra cost.
                            </Typography>
                        </Box>
                        }
                        {this.state.success &&
                        <Box pt={2}>
                            <Typography variant={"h6"} className={classes.detailsText}>
                                After studying your medical reports, we will confirm the issuance of the policy within
                                15 days of the medical test through email and call
                            </Typography>
                        </Box>
                        }
                    </Box>
                    {this.state.success &&
                    <Box className={classes.bottomBox}>
                        <Typography variant={"h4"} align={"center"}>
                            <b> For any queries </b> <br/>
                            <b>call us at +91 9389 9389 38</b>
                        </Typography>
                        <Box display="flex" justifyContent="center" mt={4}>
                            <Button
                                size="large"
                                variant="contained"
                                color="secondary"
                                disableElevation
                                onClick={(e) => {
                                    e.preventDefault()
                                    // this.props.history.push("/the-toffee-plan/quotation/success");
                                    this.props.history.push("/confirmation");
                                }}
                            >
                                Done
                            </Button>
                        </Box>
                    </Box>}
                </Box>
                {!this.state.success &&
                <Box style={{backgroundColor: '#f9f9f9'}} py={2}>
                    <Box px={2}>
                        <Typography className={classes.detailsTextHeading2} align={"center"}>
                            You are required to visit a medical centre
                        </Typography>
                    </Box>
                    <Box mt={2} px={2}>
                        <Typography variant={"h6"} className={classes.detailsText} align={"center"}>
                            Select your appointment preference. We will try to match your preference, however we
                            don't
                            guarantee the same
                        </Typography>
                    </Box>
                </Box>
                }
                {!this.state.success &&
                <Box display="flex" justifyContent="center" className={classes.content} pt={3}>
                    <Box className={classes.card}>
                        {/*{this.state.step === 0 &&*/}
                        {/*<>*/}
                        {/*    <ul>*/}
                        {/*        <li>*/}
                        {/*            <Typography className={classes.listItems}>*/}
                        {/*                Complete Blood Count HV1*/}
                        {/*            </Typography>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Typography className={classes.listItems}>*/}
                        {/*                Routine Urine Analysis S12*/}
                        {/*            </Typography>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Typography className={classes.listItems}>*/}
                        {/*                COT Medical Examination Report*/}
                        {/*            </Typography>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Typography className={classes.listItems}>*/}
                        {/*                Vulputate eu pharetra*/}
                        {/*            </Typography>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Typography className={classes.listItems}>*/}
                        {/*                Nec, mattis ac neque*/}
                        {/*            </Typography>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Typography className={classes.listItems}>*/}
                        {/*                Duis vulputate*/}
                        {/*            </Typography>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Typography className={classes.listItems}>*/}
                        {/*                Commodo lectus*/}
                        {/*            </Typography>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}

                        {/*    <Box display="flex" justifyContent="center" mt={4}>*/}
                        {/*        <Button*/}
                        {/*            size="large"*/}
                        {/*            variant="contained"*/}
                        {/*            color="primary"*/}
                        {/*            disableElevation*/}
                        {/*            onClick={(e) => {*/}
                        {/*                e.preventDefault()*/}
                        {/*                this.scheduleAppointment(0)*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            Schedule Appointment*/}
                        {/*        </Button>*/}
                        {/*    </Box>*/}
                        {/*</>*/}
                        {/*}*/}


                        {/*{this.state.step === 1 &&*/}
                        {/*<>*/}
                        {/*    <Box className={classes.address}>*/}
                        {/*        <Box mb={2}>*/}
                        {/*            <Typography className={classes.pincodeLabel}>*/}
                        {/*                Your Pin Code*/}
                        {/*            </Typography>*/}
                        {/*        </Box>*/}
                        {/*        <TextField*/}
                        {/*            id="standard-basic"*/}
                        {/*            variant={"filled"}*/}
                        {/*            // label="Pin code"*/}
                        {/*            defaultValue={this.state.mobile}*/}
                        {/*            helperText={this.state.error}*/}
                        {/*            onChange={this.onChange}*/}
                        {/*            fullWidth*/}
                        {/*        />*/}

                        {/*        <Box className={classes.availableCenters} mt={3}>*/}
                        {/*            {this.availableCenters.map((detail, index) => {*/}
                        {/*                return <Box key={index}>*/}
                        {/*                    <FormControl component="fieldset" className={classes.formControl}>*/}
                        {/*                        <RadioGroup aria-label="quiz" name="quiz"*/}
                        {/*                                    value={this.state.selectedAddress}*/}
                        {/*                                    onChange={(e) => {*/}
                        {/*                                        this.handleRadio(detail.name)*/}
                        {/*                                    }}>*/}
                        {/*                            <Box className={classes.radioBox}>*/}
                        {/*                                <FormControlLabel*/}
                        {/*                                    value={detail.name}*/}
                        {/*                                    id={index}*/}
                        {/*                                    control={<Radio/>}*/}
                        {/*                                    // label={detail.name}*/}
                        {/*                                />*/}
                        {/*                                <Box*/}
                        {/*                                    className={index < addressListLength - 1 && classes.radioLabel}>*/}
                        {/*                                    <Typography className={classes.radioTitle}>*/}
                        {/*                                        {detail.name}*/}
                        {/*                                    </Typography>*/}
                        {/*                                    <Typography className={classes.radioText}>*/}
                        {/*                                        {detail.address}*/}
                        {/*                                    </Typography>*/}
                        {/*                                </Box>*/}
                        {/*                            </Box>*/}
                        {/*                        </RadioGroup>*/}
                        {/*                    </FormControl>*/}
                        {/*                </Box>*/}
                        {/*            })}*/}
                        {/*        </Box>*/}
                        {/*    </Box>*/}

                        {/*    <Box display="flex" justifyContent="center" mt={4}>*/}
                        {/*        <Button*/}
                        {/*            size="large"*/}
                        {/*            variant="contained"*/}
                        {/*            color="primary"*/}
                        {/*            disableElevation*/}
                        {/*            onClick={(e) => {*/}
                        {/*                e.preventDefault()*/}
                        {/*                this.scheduleAppointment(1)*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            Schedule Appointment*/}
                        {/*        </Button>*/}
                        {/*    </Box>*/}
                        {/*</>*/}
                        {/*}*/}

                        {this.state.step === 0 &&
                        <>
                            <Box className={classes.timeSlot}>
                                <Box className={classes.dateBox}>
                                    <Typography className={classes.pincodeLabel}>
                                        Choose a Date
                                    </Typography>
                                    <Box className={classes.monthBox}>
                                        <Box>
                                            <ChipSelectDateTime
                                                options={this.state.dateSlots}
                                                selectedItem={this.state.selectedDate}
                                                onChange={(value) => this.handleChangeChipSelect('date', value)}
                                            />
                                        </Box>
                                        {this.state.selectedDate && <>
                                            <Box mt={2}>
                                                <Typography className={classes.pincodeLabel}>
                                                    Choose a
                                                    Time {this.state.selectedDate && 'for (' + moment(this.state.selectedDate).format("DD MMM, YYYY") + ')'}
                                                </Typography>
                                            </Box>
                                            <Box style={{minHeight: '105px'}}>
                                                {this.state.timeSlots.length > 0 ?
                                                    (this.state.domReset &&
                                                        <ChipSelect
                                                            options={this.state.timeSlots}
                                                            selectedItem={this.state.selectedTime}
                                                            onChange={(value) => this.handleChangeChipSelect('time', value)}
                                                        />) : 'Sorry no slot available for today'}
                                            </Box>
                                        </>
                                        }
                                    </Box>
                                </Box>
                            </Box>

                            <Box display="flex" justifyContent="center" mt={4}>
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    disableElevation
                                    disabled={!this.state.selectedDate || !this.state.selectedTime}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        this.scheduleAppointment(0)
                                    }}
                                >
                                    Schedule Appointment
                                </Button>
                            </Box>
                        </>
                        }
                    </Box>

                </Box>
                }
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.claim.loading,
        userDetails: state.customer.userDetails,
        error: state.customer.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
        getUserDetails: (userId) => dispatch(actions.getUserDetails(userId)),
    };
};
export default withRouter(withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(Medical))
);
