import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import NotificationAlert from "react-notification-alert";

class TextArea extends Component {
  state = {
    input: {
      name: "",
      value: "",
    },
  };
  constructor(props) {
    super(props);
    this.props.onGetInitiatedClaimState();
  }

  notify = (place, title, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    nextProps.input.name &&
      this.setState({
        input: {
          name: nextProps.input.name,
          value: "",
        },
      });
    nextProps.claimState &&
      nextProps.claimState.inputs.hasOwnProperty(nextProps.input.name) &&
      this.setState({
        input: {
          name: nextProps.input.name,
          value: nextProps.claimState.inputs[nextProps.input.name],
        },
      });
  }
  onChnage = (e) => {
    let val = "";
    if (
      this.props.input.validations.split("|")[1] === "float" ||
      this.props.input.validations.split("|")[1] === "digits"
    ) {
      // console.log(e.target.value.split("")[e.target.value.split("").length - 1]);
      // val = e.target.value;
      // console.log(
      //     this.nat(e.target.value)
      // );
      if (this.nat(e.target.value)) {
        val = e.target.value;
      } else {
        return;
      }
    } else {
      if (e.target.value.length > 500) {
        this.notify("tr", "", "500 characters max", "danger");
        // console.log("Name should not be more than 120 characters");
        return;
      }
      val = e.target.value;
    }
    // console.log(e.target.value);
    this.setState({
      input: {
        name: this.props.input.name,
        value: val,
      },
    });
  };
  submit = () => {
    if (this.state.input.value !== "") {
      this.props.addInput(this.state.input);
      this.setState({
        input: {
          name: this.state.name,
          value: "",
        },
      });
    }
  };
  render() {
    return (
      <div className="component-container position-relative m-auto">
        <div className="p-4 d-flex align-items-center">
          <img
            className="icon-60 ml-n3"
            src={require("../../../assets/img/icons/details-icon.png")}
            alt=""
          />
          <h2 className=" font-weight-light txt-secondary d-inline m-0">
            {this.props.input.display_name}{" "}
          </h2>
        </div>
        <div className="px-4 pb-4">
          <textarea
            className="py-2 px-3 w-100 border-50 txt-secondary font-weight-normal h6 mb-0"
            rows="9"
            value={this.state.input.value}
            onChange={this.onChnage}
            placeholder={this.props.input.placeholder}
          ></textarea>
          <div className="my-2 mb-3">
            {/* <img className="icon-17 mr-2" src={require("../../../assets/img/icons/question-mark.png")} alt="" />
                        <span className="h6 font-weight-light opacity-50 info-text">Please enter the location and pincode where the accident happened.</span> */}
            {/* <h6 className="d-inline float-right mt-1 txt-secondary font-weight-normal  opacity-80">Step {this.props.position.current}/{this.props.position.total}</h6> */}
            <h6 className="text-right mt-1 txt-secondary font-weight-normal  opacity-80">
              Step {this.props.position.current}/{this.props.position.total}
            </h6>
          </div>
          <div className="bg-green-10 p-3">
            <h6 className="font-weight-bold txt-green opacity-80 m-1">
              You can choose to write about.
            </h6>

            {this.props.input.infoArray &&
              this.props.input.infoArray.map((info) => {
                return (
                  <h6 className="font-weight-normal txt-green opacity-80 m-0">
                    • {info}
                  </h6>
                );
              })}
            {/* <h6 className="font-weight-normal txt-green opacity-80 m-0">• When did the accident happen?</h6>
                        <h6 className="font-weight-normal txt-green opacity-80 m-0">• How it happen?</h6>
                        <h6 className="font-weight-normal txt-green opacity-80 m-0">• Have you given the cycle to the dealer?</h6> */}
          </div>
        </div>
        <div
          className={
            "btn-primary cursor-pointer bg-shadow-3 px-4  d-flex justify-content-center align-items-center mobile-position-fixed footer-button " +
            (this.state.input.value !== "" ? "" : "opacity-50")
          }
          onClick={(e) => {
            e.preventDefault();
            this.submit();
          }}
        >
          <h2 className="font-weight-normal m-0 txt-white">Next</h2>
        </div>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    claimState: state.claim.claimState,
    error: state.claim.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetInitiatedClaimState: () => dispatch(actions.getInitiatedClaims()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TextArea);
