import React, { Component } from "react";

import { connect } from "react-redux";
import * as actions from "../../../store/actions";

class MultiSelect extends Component {
  state = {
    input: {
      name: "",
      value: [],
    },
  };
  constructor(props) {
    super(props);
    if (!props.parent || props.parent !== "feedback") {
      this.props.onGetInitiatedClaimState();
    }
  }

  notify = (place, title, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.input.options);
    nextProps.input.name &&
      this.setState({
        input: {
          name: nextProps.input.name,
          value: [],
        },
      });
    nextProps.claimState &&
      nextProps.claimState.inputs.hasOwnProperty(nextProps.input.name) &&
      this.setState({
        input: {
          name: nextProps.input.name,
          value: nextProps.claimState.inputs[nextProps.input.name],
        },
      });
  }
  componentWillUpdate(props) {
    // console.log(props.claimState.inputs);
    // console.log(props);
  }
  nat = (n) => {
    // var n1 = Math.abs(n),
    //     n2 = parseInt(n, 10);
    // return !isNaN(n1) && n2 === n1 && n1.toString() === n;
    return /^[1-9]*\d*$/.test(n);
  };

  submit = () => {
    if (this.state.input.value.length > 0) {
      this.props.addInput(this.state.input);
      this.setState({
        input: {
          name: this.state.name,
          value: [],
        },
      });
    }
  };
  toggleSelect = (option) => {
    if (this.state.input.value.includes(option)) {
      // this.setState({
      //     value: [...this.state.input.value, option]
      // })
      this.setState({
        input: {
          name: this.props.input.name,
          value: this.state.input.value.filter(function (value, index, arr) {
            return value !== option;
          }),
        },
      });
    } else {
      // this.setState({
      //     value: [...this.state.input.value, option]
      // })

      this.setState({
        input: {
          name: this.props.input.name,
          value: [...this.state.input.value, option],
        },
      });
    }
  };
  render() {
    // console.log(this.state.input);

    return (
      <div className="component-container position-relative m-auto">
        {(!this.props.parent || this.props.parent !== "feedback") && (
          <div className="p-4 d-flex align-items-center">
            <img
              className="icon-60 ml-n3"
              src={require("../../../assets/img/icons/cycle-icon.png")}
              alt=""
            />
            <h2 className=" font-weight-light txt-secondary d-inline m-0">
              Damage Cycle Parts
            </h2>
          </div>
        )}
        <div className="p-3">
          <h5 className=" pl-2 font-weight-normal txt-secondary">
            {!this.props.parent || this.props.parent !== "feedback"
              ? "Please select the damage parts of the cycle."
              : this.props.input.title}
          </h5>
          <div className="bg-gray-15 py-3 px-2">
            {this.props.input.options &&
              this.props.input.options.map((option) => {
                return (
                  <h5
                    className={
                      "py-2 px-3 m-2 d-inline-block txt-secondary cursor-pointer " +
                      (this.state.input.value.includes(option)
                        ? " btn-positive "
                        : " bg-gray-40  ")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggleSelect(option);
                    }}
                  >
                    {option}{" "}
                    {this.state.input.value.includes(option) ? " x" : " +"}
                  </h5>
                );
              })}
          </div>
          {(!this.props.parent || this.props.parent !== "feedback") && (
            <div className="my-2 ">
              <h6 className=" text-right mt-1 txt-secondary font-weight-normal  opacity-80">
                Step {this.props.position.current}/{this.props.position.total}
              </h6>
            </div>
          )}
        </div>

        <div
          className={
            "btn-primary cursor-pointer bg-shadow-3 px-4  d-flex justify-content-center align-items-center mobile-position-fixed footer-button " +
            (this.state.input.value.length > 0 ? "" : "opacity-50")
          }
          onClick={(e) => {
            e.preventDefault();
            this.submit();
          }}
        >
          <h2 className="font-weight-normal m-0 txt-white">
            {!this.props.parent || this.props.parent !== "feedback"
              ? "Next"
              : "Submit"}
          </h2>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    claimState: state.claim.claimState,
    error: state.claim.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetInitiatedClaimState: () => dispatch(actions.getInitiatedClaims()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect);
