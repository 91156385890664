import React, { Component } from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {Images} from "../../constants/images";
import Logo from "../Common/logo";


export default class MaterialAppBar extends Component {
  render() {
    return (
      <AppBar elevation={0}
              position="relative"
              style={{padding: '0'}} >
        <Toolbar >
          <Box m="auto" >
            <Logo logo={Images.toffeeLogo} alt="Toffee Logo" logoStyle={"py-3"}/>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}
