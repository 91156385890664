import {createStyles} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    bgLightBlue: {
        // backgroundColor: "#ecf0f4",
        minHeight: "100vh",
    },
    title: {
        fontWeight: "Bold",
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "justify",
        },
    },
    container: {
        position: "relative",
        backgroundColor: "#ecf0f4",
        margin: "auto",
        textAlign: "center",
    },
    stepsText: {
        [theme.breakpoints.down("sm")]: {
            fontSize: "18px",
            textAlign: "center",
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "24px",
            textAlign: "justify",
        },
    },
    PlanText: {
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "justify",
        },
    },
    icons: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        [theme.breakpoints.up("md")]: {
            display: "block",
        },
    },
    chevron2: {
        marginLeft: "-20px !important",
        marginTop: "-20px !important",
    },
    chevron1: {
        marginTop: "-20px !important",
    },
    largeIcon: {
        height: "60px",
    },
    rightSection: {
        width: '100%',
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            alignItems: "flex-start",
        },
        [theme.breakpoints.up("sm")]: {
            alignItems: "flex-start",
        },
        [theme.breakpoints.up("md")]: {
            alignItems: "center",
        },
    },
    subTitle: {
        fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "center",
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
        },
    },
    emptyBody: {
        backgroundColor: "#ecf0f4",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    card: {
        backgroundColor: 'transparent',
        padding: "0px !important",
        borderRadius: "12px",
        [theme.breakpoints.down('xs')]: {
            width: "308px !important",
            height: "auto"
        },
        [theme.breakpoints.up('sm')]: {
            width: "328px !important",
            height: "auto"
        },
        [theme.breakpoints.up('md')]: {
            width: "585px !important",
            height: "446px !important"
        },

    },
    cardContent: {
        padding: "0px !important",
    },

});