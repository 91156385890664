import React from "react";
import "./insurance-card.scss";

class InsuranceCard extends React.Component {
  render() {
    return (
      <div className={"smart-card"}>
        <img
          src={require("../../../assets/images/buyFlow/s-bg-logo.png")}
          alt=""
          className="bg-logo smart-bg-logo"
        />
        <img
          src={require("../../../assets/images/buyFlow/toffee-plan-logo.png")}
          alt=""
          className="plan-logo smart-logo"
        />
        <img
          src={require("../../../assets/images/buyFlow/toffee-logo.png")}
          alt=""
          className="toffee-logo"
        />
        <p className="p-0 m-0 card-label toffee-id-label">{"TOFFEE ID"}</p>
        <p className="p-0 m-0 card-number toffee-id">{this.props.toffeeId}</p>

        <p className="p-0 m-0 card-label policy-holder-label">
          {"POLICY HOLDER"}
        </p>

        <p className="p-0 m-0 card-name policy-holder text-uppercase">
          {this.props.name.substr(0, 20)}
        </p>
      </div>
    );
  }
}

export default InsuranceCard;
