import React, {Component} from "react";

import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import TopContent from "../../Login/topContent";
import Box from "@material-ui/core/Box";
import MaterialAppBar from "../../../../components/Headers/MaterialAppBar";
import ResponsiveContainer from "../../../../components/ResponsiveContainer/responsiveContainer";
import Button from "@material-ui/core/Button";
import {Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = (theme) => ({
    card: {
        backgroundColor: '#dfecea',
        border: 'solid 6px #eaf2f0',
        borderRadius: '16px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.24)',
        width: '300px',
        padding: theme.spacing(5, 2),
    },
    titleBox:{
        display: 'flex',
        justifyContent: 'space-around'
    },
    titleText: {
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: ' 1.33',
        letterSpacing: '0.33px',
        color: '#22334f',
    },
    bigText:{
        fontSize: '20px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: ' 1.35',
        letterSpacing: '0.56px',
        color: '#22334f',
    },
    bottomText:{
        display: "flex",
        justifyContent: 'center',
        fontSize: '16px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: ' 1',
        letterSpacing: '0.44px',
        color: '#22334f',
        margin: theme.spacing(3,0,0)
    },
    content:{
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: ' 1.5',
        letterSpacing: '0.15px',
        color: '#22334f',
        maxWidth: '400px',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 3),
        },
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(0, 3),
        },
        [theme.breakpoints.up("md")]: {
            // padding: theme.spacing(0, 0, 0, 0),
        },
    }
})

class MedicalSuccess extends Component {
    constructor(props) {
        super(props);
        this.state={
            orderId: null
        }
        this.props.retrieveQuotations(51);
    }
    firstLetterUpperCase = (str) => {
        let string;
        string = str
            ?.toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))[0];
        return string;
    };
    componentDidUpdate(prevProps) {
        if(this.props.quotation?.order_id !== prevProps.quotation?.order_id){
        this.props.getUserDetails(this.props.quotation?.order_id)
        }
    }

    componentWillMount(){

    }

    render() {
        console.log(this.props.quotation)
        console.log(this.props.quotation?.order_id)
        console.log(this.props.userDetails)
        const {classes} = this.props;
        return (
            <ResponsiveContainer
                flag={"userFlow"}
                child={
                    <>
                        <MaterialAppBar/>
                        <Box>
                            <Box display="flex" justifyContent="center" pt={4}>
                                <Typography variant={'h2'}>
                                    {this.firstLetterUpperCase(this.props.quotation?.name)}
                                </Typography>
                            </Box>

                            <Box display="flex" justifyContent="center" pt={4}>
                                <Box className={classes.card}>
                                    <Box className={classes.titleBox}>
                                        <Box className={classes.leftBox}>
                                            <Typography className={classes.titleText}>
                                                Life Cover
                                            </Typography>
                                            <Typography className={classes.bigText}>
                                                ₹1 Crore
                                            </Typography>
                                        </Box>
                                        <Box className={classes.leftBox}>
                                            <Typography className={classes.titleText}>
                                                Cover upto
                                            </Typography>
                                            <Typography className={classes.bigText}>
                                                {this.props.userDetails?.response_policy_term} years
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.bottomBox}>
                                        <Typography className={classes.bottomText}>
                                            Yearly Premium: ₹{this.props.userDetails?.premium}
                                        </Typography>
                                    </Box>
                                </Box>

                            </Box>

                            <Box display="flex" justifyContent="center" pt={4}>
                                <Typography className={classes.content}>
                                    Your application is still being verified. We will reply to you in 4 working days. We regret any inconvenience caused by the delay.
                                </Typography>
                            </Box>

                        </Box>
                        <Box display="flex" justifyContent="center" pt={20}>
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                onClick={(e) => {
                                    window.location.href = "https://toffeeinsurance.com/insurance-policies";
                                }}
                            >
                                View Another Toffee
                            </Button>
                        </Box>
                    </>
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.customer.userDetails,
        error: state.customer.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveQuotations: (productId) => dispatch(actions.postRetrieveQuotation(productId)),
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
        getUserDetails: (userId) => dispatch(actions.getUserDetails(userId)),
    };
};
export default withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(MedicalSuccess));
