import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";

const ExpansionPanel = withStyles({
  root: {
    marginBottom: "10px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    title: {
      borderRadius: "50px",
      backgroundColor: "#f7f9fb",
      minHeight: "64px",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    marginBottom: -1,
    minHeight: 80,
    borderRadius: "50px",
    "&$expanded": {
      minHeight: 80,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 20px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "16px 80px",
    },
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles((theme) => ({
  indexBox: {
    minWidth: "40px",
    height: "100%",
    borderRadius: "50%",
    backgroundColor: "rgba(255,51,57,0.11)",
    display: "flex",
    alignItems: "center",
  },
  index: {
    margin: "0 auto",
    padding: "6px",
    fontSize: "20px",
    // fontWeight: "bold",
    color: "#ff3339",
    opacity: "1",
    zIndex: 1,
  },
  questions: {
    lineHeight: "1.3",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      letterSpacing: "0.5px",
      fontWeight: "500",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      letterSpacing: "1.5px",
      fontWeight: "bold",
    },
  },
  answers: {
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "1.6",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      lineHeight: "1.3",
    },
  },
}));

const faq = [
  {
    question: "OK, again, what is Toffee Insurance",
    answer:
      "Toffee Insurance is insurance simplified. With just one plan, you get protected for your health, life, household expenses and much more. \nInstead of figuring multiple plans for your health, life and household, you can simply pick one Toffee plan and insure yourself or add as many as 4 family members’ health insurance under the same plan. Easy, monthly payments give you peace of mind and secure you and your family’s future. \nSo when it comes to health - should you or a family member fall sick or needs hospitalisation, day care or home treatment, your expenses will be covered by us for up to 5 Lakhs (for each family member). You can also avail cashless facility in our network of over 7400 hospitals across the country, which is hassle-free, convenient and saves you a ton of time. \nSimilarly, with our household insurance, you get covered for theft, damage or loss of your personal belongings for up to 5 Lakhs and repair for mechanical or electrical home appliances like fridge, AC, oven microwave and air cooler for up to 1.25 Lakhs. \nAnd lastly, you secure your family’s future in safe hands with our life insurance that covers a sum of upto 75 Lakhs in case of permanent disability or death. \nThat’s it. Multiple insurance benefits with one plan. No fuss, no hassle, just Toffee.",
    panel: "panel1",
  },
  {
    question:
      "Sounds good but how is it different from other Insurance providers?",
    answer:
      "Toffee takes the headache out of choosing and using insurance. Toffee is a unique and exclusive combination of insurance products that are selected from the most trusted and top insurance companies. You get a unique combination of insurance benefits across health, life, household and a lot of other important things you had not thought of. \nWe know insurance can be painful so we’ve worked super hard to make it easy to understand, simple to use and quick to process. \nOur process is all digital that means zero paperwork, no complicated forms and no waiting on the claims. It’s just one plan that does it all, which means no hassle of buying multiple plans and no confusion on checking through different insurance policies.",
    panel: "panel2",
  },
  {
    question:
      "What if I already have health Insurance? Or life Insurance from work?",
    answer:
      "Great question! You did what felt right for your health and life needs then. Also, life insurance from work typically doesn’t offer you the kind of coverage that can take care of your loved ones’ and your responsibilities toward them once you’re gone. Lucky for you, things have become simpler, convenient and hassle-free with Toffee Insurance all-in-one Combo plan, which not only pays great cover under life and household (Home Appliances & Theft/damage) but in your worst case scenario, covers COVID-19 hospitalisation expenses under health insurance as well. \nConsidering that this is the first ever comprehensive plan in India, we would always suggest you review your existing benefits and relook at what you have and what you can get. Switching to Toffee is very easy, and you can always connect with us at +91-93-89-93-89-38 for more hands-on help from us in making the switch!",
    panel: "panel3",
  },
  {
    question:
      "Does Toffee Insurance Plan cover pandemics like COVID-19 as well?",
    answer:
      "Glad you asked that. Yes, under its health insurance coverage, the Toffee Insurance Plan covers all your hospitalisation expenses for COVID- 19, if tested positive. This coverage is not limited to just the current coronavirus (COVID-19) outbreak but extends to other diseases as well. For a more detailed list of all the diagnoses covered, check out our plan details here.",
    panel: "panel4",
  },
  {
    question: "Explain Household insurance, please?",
    answer:
      "A household insurance policy basically offers protection for your home and your household items against a variety of risks. These risks could be in the form of loss, theft or damage to the property, valuables as well as the appliances like ACs, fridges, washing machine, etc. covered under it. \nFor more details on the benefits under the plan, just see the coverage inclusions here.",
    panel: "panel5",
  },
  {
    question: "What are my payment options while buying Toffee Insurance?",
    answer:
      "Toffee Insurance is a simple plan that covers all your needs in health, life, household and more. You can make easy monthly payments through typical methods like credit/debit card, Netbanking, UPI and others. Because the security of your transaction details is top-priority for us, we use Razorpay payment gateway that keeps things quick and secure. \nIn case you’d like to make payments simpler and opt for automated payment deductions every month, you’ll be requested to use either a credit card or sign up for eNach (Auto-deduct feature for your monthly payments on a set date).",
    panel: "panel6",
  },
  {
    question:
      "Am I still eligible to buy Toffee Insurance if I have pre-existing conditions such as Blood Pressure, Diabetes, Asthma, etc.?",
    answer:
      "Yes, you are still eligible to buy Toffee Insurance, but not as an online product. To ensure your Toffee Insurance Plan is just as per your needs, simply give Toffee a call on +91-93-89-93-89-38 and we’d walk you through all checks that are needed. \nDepending upon your situation, we may need a medical report or check-up before the Toffee Insurance Plan can be issued to you. However, typically, a special pre-existing condition is never a deterrent in buying our policy, except in some cases which our team would be happy to address over a personalised call.",
    panel: "panel7",
  },
  {
    question: "When are the medical checks required?",
    answer:
      "That’s a great question! The medical checks typically are required when you’re either above 40 or have a pre-existing condition(s). \nFor the rest under-40 with no chronic health condition, there is no medical check-up required, whether you decide to include your family under your Toffee Insurance Plan or just yourself.",
    panel: "panel8",
  },
  {
    question:
      "What happens if I am unable to pay the premium for one month? Will my Toffee Plan be valid?",
    answer:
      "Toffee looks out for you even after you’ve bought the plan. We offer to cover your 1 monthly payment; in case you miss your payment or are unable to do so. The payment Toffee makes on your behalf is easily adjusted in your next monthly cycle, so your payments keep going without causing you any inconvenience.",
    panel: "panel9",
  },
  {
    question:
      "What are the circumstances in which Toffee Insurance can be cancelled after purchase?",
    answer:
      "Typically, none. The Toffee Insurance Plan is for everyone, and mostly works for you and your needs in all scenarios. Having said that, in certain exceptional cases where the customer has a critical pre-existing health condition, there are rare chances of the insurance plan being rejected by the insurance provider. \nIn case you have a pre-condition healthwise, we recommend you get in touch with Toffee at +91-93-89-93-89-38 so we can walk you through the checks needed to ensure you buy a plan that meets your needs.",
    panel: "panel10",
  },
  {
    question:
      "Once I buy the Toffee Insurance Plan, what is the maximum number of claims I can take in a year?",
    answer:
      "As many as you’d need, as long as you don’t exhaust your cover. For all your health and household claims, you get an upper limit of 3 Lakhs and 1.25 Lakhs respectively and you can file any number of claims (as long as you keep renewing your policy) as long as you are still not over your coverage limit. \nIn case of Life Insurance, you get an overall coverage of 25 Lakhs that your family can claim in case of your death or diagnosis for a terminal illness.",
    panel: "panel11",
  },
  {
    question: "Why are you called Toffee?",
    answer:
      "Funny you should ask that. We are called Toffee, because just like one, we offer sweet insurance benefits in smaller, bite-sized insurance plans. We make insurance simpler, faster and accessible for everyone. Sweet! \nWe believe insurance should be a good fit for your current needs and above all, should be easy. With one Toffee Insurance Plan, we attempt the same by bringing one plan that you’ll ever need.",
    panel: "panel12",
  },
  {
    question: "Why should I choose Toffee Insurance?",
    answer:
      "Because Toffee Insurance is the only plan you’ll ever need, the focus is on simplifying insurance and making it accessible for everyone. \nWe take out the unnecessary fluff and focus on convenience, flexibility and great returns for anyone who chooses the Toffee Insurance Plan. \nSo all you do is make tiny monthly payments (did we tell you we also cover your missed installment for you for 1 month) and Toffee takes care of your health, life and household and a lot more, from reimbursements, treatments to repair. \nWorried about your family’s health in the current COVID-19 outbreak? Simply opt for our Toffee Insurance Plan and include their health insurance coverage in your plan instantly. That’s the power of a (good) Toffee!",
    panel: "panel13",
  },
  {
    question: "I mean, do I really need life insurance?",
    answer:
      "Nobody likes to think about death but if it does happen, isn’t it good (and important) to leave something behind for your family? Besides, Life Insurance is an important aspect of financial planning especially when you support a partner, children or ageing parents. In addition to providing monetary support for everyday living expenses after your death, your family needs insurance to cover any outstanding debts, like mortgages, credit cards and car loans. It also enables you to add a blanket of financial security in the lives of your children with aids for their higher education, marriage or independent business ventures. Simply put, life insurance is a good idea for anyone who doesn't want to leave their family burdened financially.",
    panel: "panel14",
  },
  {
    question: "Who should buy Life Insurance? And for how much coverage?",
    answer:
      "You should. Your life matters, for you as well as people in your family who care about you, and whose lives are dependent on you. \nWith life insurance, you can make sure that even when you are not around, your children, your partner/parents are looked after and don’t have to compromise on their future lifestyle and education needs. \nAs a thumb rule, your life insurance coverage should pay out 10 times your annual income (if you’re the single earner in your family) and at least 5 times your annual income (in case your spouse/family members are also working).",
    panel: "panel15",
  },
  {
    question: "What does Claim Settlement rate mean? Is yours good?",
    answer:
      "In easy terms, the Claim Settlement Rate for an insurance provider tells you what’s their success rate when it comes to actually getting the claims settled and the policy holder receiving the reimbursements. Typically, a lot of claims every year are rejected by insurance companies on account of incomplete information or process issues. So the higher the claims settlement rate, the more trusting the insurance plan. \nNow, is ours good? We enjoy a claim settlement rate of 98.1% across 1,15,000 policies sold so far, and we only plan to grow from here. So to answer your question, yes, ours is good (very good actually).",
    panel: "panel16",
  },
];

export default function CommonQuestions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const classes = useStyles();
  return (
    <div>
      {faq.map((faq, index) => {
        return (
          <ExpansionPanel
            square
            expanded={expanded === faq.panel}
            onChange={handleChange(faq.panel)}
          >
            <ExpansionPanelSummary
              aria-controls="panel1d-content"
              id={index + "ef57sgn"}
              expandIcon={<ExpandMoreIcon />}
            >
              <Box className={classes.indexBox}>
                <Typography className={classes.index} align={"center"}>
                  <b>{index + 1}</b>
                </Typography>
              </Box>
              <Box m={"auto"} ml={3}>
                <Typography className={classes.questions}>
                  {faq.question}
                </Typography>
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box>
                {faq.answer.split("\n").map(function (item, key) {
                  return (
                    <>
                      <Typography key={key + "723"} className={classes.answers}>
                        {item}
                      </Typography>
                      <br />
                    </>
                  );
                })}
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
}
