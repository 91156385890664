import { useState, useEffect } from 'react';
import { getWebService, postWebService } from '../helpers/serverUtils';

export const useGetApi = (endpoint, params = {}, defaultValue= null) => {
    const [result, setResult] = useState(defaultValue);
    
	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
    	const response = await getWebService(endpoint, params);
	
		setResult(response.data.data);
	};

	return result;
};

export const usePostApi = (endpoint, payload = {}, defaultValue= null) => {
    const [result, setResult] = useState(defaultValue);
    
	useEffect(() => {
		submitData();
	}, []);

	const submitData = async () => {
    	const response = await postWebService(endpoint, payload);
	
		setResult(response.data.data);
	};

	return result;
};