import axios from 'axios';

// Authorization: 'Bearer ' + token
const getApiUrl = (url) => {
  return (url.indexOf('localhost') > -1 || url.indexOf('calendly.com') > -1) ? `${url}` : `${process.env.REACT_APP_API_EP}${url}`;
};

const getRequestHeaders = () => {
  return { 
    "Authorization" : `Bearer ${localStorage.getItem('token')}`
  };
};

export const deleteWebService = async (url, payload, headers = getRequestHeaders()) => {
  return await axios({
    url: getApiUrl(url),
    data: payload,
    method: 'delete',
    headers: headers
  });
};

export const getWebService = async (url, headers = getRequestHeaders()) => {
  return await axios({
    url: getApiUrl(url),
    method: 'get',
    headers: headers
  });
};

export const patchWebService = async (url, payload, headers = getRequestHeaders()) => {
  return await axios({
    url: getApiUrl(url),
    data: payload,
    method: 'patch',
    headers: headers
  });
};

export const postWebService = async (url, payload, headers = getRequestHeaders()) => {
  return await axios({
    url: getApiUrl(url),
    data: payload,
    method: 'post',
    headers: headers
  });
};

export const putWebService = async (url, payload, headers = getRequestHeaders()) => {
  return await axios({
    url: getApiUrl(url),
    data: payload,
    method: 'put',
    headers: headers
  });
};