import health_logo from '../assets/img/verification/Health_2x.png'
import life_logo from '../assets/img/verification/Life_2x.png'
import house_logo from '../assets/img/verification/house_2x.png'
import pa_logo from '../assets/img/verification/pa.svg'
import chi_logo from '../assets/img/verification/Health_2x.png'

export const Products = {
    48: {
        title:'iciciLife',
        provider: 'ICICI Life Insurance',
        name: 'Life Insurance',
        image: life_logo
    },
    49: {
        title:'careHealth',
        provider: 'Care Health Insurance',
        name: 'Health Insurance',
        image: chi_logo
    },
    51: {
        title: 'toffeePlan',
        provider: 'Toffee Plan'
    },
    52: {
        title: 'indiaFirstLife',
        provider: 'IndiaFirst Life e-Term Plan',
        name: 'Life Insurance',
        image: life_logo
    },
    53: {
        title: 'tataHealth',
        provider: 'TATA AIG Medicare Protect',
        name: 'Health Insurance',
        image: health_logo
    },
    54: {
        title: 'tataHome',
        provider: 'TATA AIG HomeSecure',
        name: 'Home Insurance',
        image: house_logo
    },
    55: {
        title: 'tataHealthTopUp',
        provider: 'Tata Health Top Up',
        name: 'Health Top Up',
        image: health_logo
    },
    56: {
        title: 'tataAccidentalShield',
        provider: 'Tata AIG Accidental Shield',
        name: 'Personal Accident',
        image: pa_logo
    },
    58: {
        title: 'pnbMetLife',
        provider: 'PNB MetLife Complete Care Plus ',
        name: 'Life Insurance',
        image: life_logo
    },
    59: {
        title: 'indiaFirstLifeGpp',
        provider: 'IndiaFirst Life Guaranteed Protection Plan',
        name: 'Life Insurance',
        image: life_logo
    }

}