import React, {Component} from "react";
import Box from "@material-ui/core/Box";
import {withStyles} from "@material-ui/core";

const useStyles = (theme) => ({
    chip: {
        backgroundColor: "rgba(255, 88, 93, 0.1)",
        borderRadius: "19px",
        color: "#22334f",
        padding: "5px 22px",
        fontSize: "12px",
        margin: 'auto',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            fontWeight: "400"
        },
    },
});

class HighlightChip extends Component {
    render() {
        const {classes, left} = this.props;
        const {chip} = classes;
        return (
            <Box className={chip}>
                {left}
            </Box>
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(HighlightChip);
