import * as actionTypes from "./actionTypes";
import API from "../../utilities/api";
import Cookies from "js-cookie";
import queryString from "query-string";

// START
export const postSendOtpStart = (mobile) => {
  return {
    type: actionTypes.POST_SENDOTP_TYPES_START,
    mobile: mobile,
  };
};
export const postVerifyOtpStart = () => {
  return {
    type: actionTypes.POST_VERIFYOTP_TYPES_START,
  };
};

// SUCCESS
export const postSendOtpSuccess = () => {
  return {
    type: actionTypes.POST_SENDOTP_TYPES_SUCCESS,
  };
};
export const postVerifyOtpSuccess = () => {
  return {
    type: actionTypes.POST_VERIFYOTP_TYPES_SUCCESS,
  };
};

export const userLoggedInSuccess = (token) => {
  return {
    type: actionTypes.CHECK_USER_LOGGED_IN_SUCCESS,
    token: token,
  };
};

// FAIL
export const postSendOtpFail = (error) => {
  return {
    type: actionTypes.POST_SENDOTP_TYPES_FAIL,
    error: error,
  };
};
export const postVerifyOtpFail = (error) => {
  return {
    type: actionTypes.POST_VERIFYOTP_TYPES_FAIL,
    error: error,
  };
};

// ACTION
export const postSendOtp = (data) => {
  return (dispatch) => {
    dispatch(postSendOtpStart(data.mobile));
    API.post("user/login", data)
      .then((res) => {
        if (res.data.code === 310)
          dispatch(postSendOtpFail(res.data.data.error_msg));
        else dispatch(postSendOtpSuccess());
      })
      .catch((err) => {
        console.log(err);

        // if (err.response) dispatch(postSendOtpFail(err.response.data.error));
        // else
        //   dispatch(
        //     postSendOtpFail({
        //       code: 502,
        //       message: err.message,
        //     })
        //   );
      });
  };
};

export const postVerifyOtp = (data) => {
  return (dispatch) => {
    dispatch(postVerifyOtpStart());
    API.post("user/verify", data)
      .then((res) => {
        // Cookies.set("token", res.data.data.token);
        localStorage.setItem("token", res.data.data.token);
        dispatch(postVerifyOtpSuccess());
      })
      .catch((err) => {
        console.log(err);
        dispatch(postVerifyOtpFail("Incorrect OTP."));
      });
  };
};

export const checkIfLoggedIn = () => {
  return (dispatch) => {
    let token ;
      if (!localStorage.getItem("token") ) {
        if (Cookies.get("react_token")) {
          localStorage.setItem("token", Cookies.get("react_token"));
          token = localStorage.getItem("token");
        } else {
           token = localStorage.getItem("token");
        }
      }
      else{
        token = localStorage.getItem("token");
      }
    // const token = localStorage.getItem("token");
    token && dispatch(userLoggedInSuccess(token));
  };
};
