import {createStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React from "react";

export const useStyles = (theme) => createStyles({
    main: {
        backgroundColor: '#f9f9f9',
    },
    card: {
        backgroundColor: "#ffffff",
        [theme.breakpoints.up("md")]: {},
    },
    cardTitle: {
        fontWeight: "Bold",
        color: "#22334f",
        lineHeight: "1.67",
        letterSpacing: "2px",
        [theme.breakpoints.down("sm")]: {
            opacity: "0.5",
            fontSize: "16px",
        },
        [theme.breakpoints.up("sm")]: {
            opacity: "1.0",
            fontSize: "20px",
        },
        [theme.breakpoints.up("md")]: {
            opacity: "1.0",
            fontSize: "24px",
        },
    },
    title: {
        color: "#22334f",
        [theme.breakpoints.down("sm")]: {
            fontWeight: "500",
            fontSize: "18px",
        },
        [theme.breakpoints.up("sm")]: {
            fontWeight: "bold",
            fontSize: "30px",
            letterSpacing: "1.5px",
        },
        [theme.breakpoints.up("md")]: {
            fontWeight: "bold",
            fontSize: "30px",
            letterSpacing: "1.5px",
        },
    },
    detailsText: {
        textAlign: "center",
    },

    coverageCard: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: "0px",
        },
        [theme.breakpoints.up("sm")]: {
            paddingTop: "0px",
            padding: "70px",
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: "0px",
            padding: "90px",
        },
    },
    textBox: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "20px",
            paddingRight: "20px",
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "15px",
            paddingRight: "25px",
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: "15px",
            paddingLeft: "25px",
            paddingRight: "25px",
        },
    },
    delText: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "5px",
            marginBottom: "0px",
            paddingBottom: "0px",
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: "10px",
            marginBottom: "0px",
            paddingBottom: "0px",
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "10px",
            marginBottom: "10px",
            paddingBottom: "0px",
        },
    },
    saveText: {
        color: '#ff585d'
    },

    fontLight: {
        fontWeight: "200",
    },
    content: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    paper: {
        backgroundColor: 'transparent'
    },
    topRadiusBackground: {
        backgroundColor: "#ff585d"
    },
    download: {
        // top: "-15px",
    },
    downloadText: {
        fontWeight: "Bold",
        lineHeight: "1.67",
        letterSpacing: "2px",
        color: theme.palette.primary.main,
        [theme.breakpoints.down("sm")]: {
            textAlign: "justify",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "justify",
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "right",
        },
    },
    paperImages: {
        [theme.breakpoints.down("sm")]: {
            width: "30px",
            height: "25px",
            textAlign: "justify",
        },
        [theme.breakpoints.up("sm")]: {
            width: "30px",
            height: "25px",
            textAlign: "justify",
        },
        [theme.breakpoints.up("md")]: {
            width: "30px",
            height: "25px",
            textAlign: "justify",
        },
    },
    overflow: {
        [theme.breakpoints.down("sm")]: {
            overflow: 'auto'
        },
    },
    downloadBrochure: {
        display:"flex",
        alignItems:'center',
        width:"100%" ,
        padding: theme.spacing(0, 1, 0, 2)
    }
});