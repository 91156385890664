import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    loading: false,
    error: null,
    claimTypesLoading: false,
    claimInputsLoading: false,
   


    emptyClaimTypesMessage: null,
    claimTypesArray: [],
    claimRequiredInputs: [],
    claimRequiredDocuments: [],
    claimId: '',
    claimState: null
};

//  START 
const claimTypesStart = (state, action) => {
    return updateObject(state, { error: null, claimTypesLoading: true });
};
const claimInitiatedStart = (state, action) => {
    return updateObject(state, { error: null});
};
const claimInputsStart = (state, action) => {
    return updateObject(state, { error: null, claimInputsLoading: true });
};



//  SUCCESS 
const claimTypesSuccess = (state, action) => {
    return updateObject(state, {
        claimTypesArray: action.data,
        claimTypesLoading: false,
        emptyClaimTypesMessage: action.message
    });
};
const claimInitiatedSuccess = (state, action) => {
    return updateObject(state, {
        claimState: action.data
    });
};
const claimInputsSuccess = (state, action) => {
    return updateObject(state, {
        claimRequiredInputs: action.inputs,
        claimRequiredDocuments: action.documents,
        claimId: action.claimId,
        claimInputsLoading: false
    });
};



//  FAIL 
const claimTypesFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        claimTypesLoading: false
    });
};
const claimInitiatedFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};
const claimInputsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        claimInputsLoading: false
    });
};



//flag

const showLoader = (state, action) => {
    return updateObject(state, {
        loading: action.flag
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CLAIM_TYPES_START: return claimTypesStart(state, action);
        case actionTypes.GET_CLAIM_INPUTS_START: return claimInputsStart(state, action);
        case actionTypes.GET_INITIATED_CLAIM_START: return claimInitiatedStart(state, action);
        

        case actionTypes.GET_CLAIM_TYPES_SUCCESS: return claimTypesSuccess(state, action);
        case actionTypes.GET_CLAIM_INPUTS_SUCCESS: return claimInputsSuccess(state, action);
        case actionTypes.GET_INITIATED_CLAIM_SUCCESS: return claimInitiatedSuccess(state, action);
        

        case actionTypes.GET_CLAIM_TYPES_FAIL: return claimTypesFail(state, action);
        case actionTypes.GET_CLAIM_INPUTS_FAIL: return claimInputsFail(state, action);
        case actionTypes.GET_INITIATED_CLAIM_FAIL: return claimInitiatedFail(state, action);
       



        case actionTypes.SHOW_LOADER: return showLoader(state, action);
        default:
            return state;
    }
};

export default reducer;