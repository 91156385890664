import * as actionTypes from "./actionTypes";
import API from "../../utilities/api";
import firebase from "../../config/firestore";

// START
export const postQuotationStart = () => {
    return {
        type: actionTypes.POST_RETRIEVEQUOTATION_TYPES_START,
    };
};
export const getUserDetailsStart = () => {
    return {
        type: actionTypes.GET_USERDETAILS_TYPES_START,
    };
};
export const getPolicyTransactionsStart = () => {
    return {
        type: actionTypes.GET_POLICY_TRANSACTIONS_START,
    };
};

// SUCCESS
export const postQuotationSuccess = (quotation) => {
    return {
        type: actionTypes.POST_RETRIEVEQUOTATION_TYPES_SUCCESS,
        quotation: quotation,
    };
};
export const getUserDetailsSuccess = (userDetails) => {
    return {
        type: actionTypes.GET_USERDETAILS_TYPES_SUCCESS,
        userDetails: userDetails,
    };
};
export const getPolicyTransactionsSuccess = (policyTransactions) => {
    return {
        type: actionTypes.GET_POLICY_TRANSACTIONS_SUCCESS,
        policyTransactions: policyTransactions,
    };
};

// FAIL
export const postQuotationFail = (error) => {
    return {
        type: actionTypes.POST_RETRIEVEQUOTATION_TYPES_FAIL,
        error: error,
    };
};
export const getUserDetailsFail = (error) => {
    return {
        type: actionTypes.GET_USERDETAILS_TYPES_FAIL,
        error: error,
    };
};
export const getPolicyTransactionsFail = (error) => {
    return {
        type: actionTypes.GET_POLICY_TRANSACTIONS_FAIL,
        error: error,
    };
};

export const enableUpi = (data) => {
    return {
        type: actionTypes.ENABLE_UPI_LINK,
        enableUpi: data,
    };
};
export const updatedPremium = (isUpdated, annual, monthly) => {
    return {
        type: actionTypes.GET_UPDATED_PREMIUM,
        isUpdated: isUpdated,
        annualPremium: annual,
        monthlyPremium: monthly
    };
};
export const isMonthly = (data) => {
    return {
        type: actionTypes.PAYMENT_TYPE_MONTHLY,
        isMonthly: data,
    };
};

// ACTION
export const postRetrieveQuotation = (productId) => {
    return (dispatch) => {
        dispatch(postQuotationStart());

        API.post(
            `web/quotations/${productId}`,
            {}
        )
            .then((res) => {
                // console.log(res);
                dispatch(postQuotationSuccess(res.data.data[0]));
            })
            .catch((err) => {
                console.log(err);
                // if (err.response) dispatch(postSendOtpFail(err.response.data.error));
                // else
                //   dispatch(
                //     postQuotationFail({
                //       code: 502,
                //       message: err.message,
                //     })
                //   );
            });
    };
};

export const getUserDetails = (orderId) => {
    console.log(orderId)
    return (dispatch) => {
        dispatch(getUserDetailsStart());

        API.get(`orders/${orderId}`)
            .then((res) => {
                console.log(res)
                dispatch(getUserDetailsSuccess(res.data.data));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};
export const getPolicyTransactions = (orderId) => {
    return (dispatch) => {
        dispatch(getPolicyTransactionsStart());

        API.get(`orders/${orderId}/transactions`)
            .then((res) => {
                dispatch(getPolicyTransactionsSuccess(res.data.data));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};
export const captureFirebaseLead = (doc, data) => {
    return (dispatch) => {
        const db = firebase.firestore();
        const leadsRef = db.collection('leads').doc(doc);
        leadsRef.set(data, {merge: true})
    };
};

export const deleteFirebaseLead = (doc) => {
    return (dispatch) => {
        if (doc) {
            const db = firebase.firestore();
            db.collection('leads').doc(doc).delete();
        }
    };
};

export const enableUpiLink = (data) => {
    return (dispatch) => {
        dispatch(enableUpi(data));
    };
};
export const getUpdatedPremium = (isUpdated, annual, monthly) => {
    return (dispatch) => {
        dispatch(updatedPremium(isUpdated, annual, monthly));
    };
};
export const isMonthlyCheck = (data) => {
    return (dispatch) => {
        dispatch(isMonthly(data));
    };
};


