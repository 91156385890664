import React, {Component} from "react";

import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import MaterialAppBar from "../../../components/Headers/MaterialAppBar";
import {Box, Fade, Slide} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CustomLoader from "../../../components/Common/CustomLoader";
import ArrowForwardOutlined from "@material-ui/icons/ArrowForwardOutlined";
import Button from "@material-ui/core/Button";
import queryString from "query-string";
import Cookies from "js-cookie";
import SideSection from "../../../components/sideContent/sideSection";
import {useStyles} from './homeStyles'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import MaterialListItem from "../../../components/list/ListItem";
import {Images} from "../../../constants/images";


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enableUpi: new URL(window.location.href).searchParams.get("enable_upi"),
            // enableUpi: new URL(window.location.href).searchParams.get("enable_upi") !== null && new URL(window.location.href).searchParams.get("enable_upi")?.toLowerCase() === "yes"
            orderId: new URL(window.location.href).searchParams.get("order_id"),
        }
    }


    componentDidMount() {
        this.props.checkIfLoggedIn();
        // this.props.retrieveQuotations(51);
        this.props.getUserDetails(this.state.orderId);
        localStorage.setItem('orderId', this.state.orderId);
    }

    listItems = () => {
        const listItems = [
            {
                title: "Verify Policy Details",
                index: 1,
                img: Images.userIcon,
                isRequired: true,
                isDone: this.props.userDetails?.verified_otp
            },
            {
                title: "Pay Policy Premium",
                index: 2,
                img: Images.credCard,
                isRequired: true,
                isDone: this.props.userDetails?.is_already_paid,
            },
            {
                title: "Upload Documents",
                index: 3,
                img: Images.docIcon,
                isRequired: this.props.userDetails?.are_documents_required,
                isDone: (this.props.userDetails?.is_documentation_complete) || false,
            },
            {
                title: "Medical",
                index: 4,
                img: Images.medicalIcon,
                isRequired: this.props.userDetails?.is_medical_required,
                isDone: (this.props.userDetails?.is_medical_initiated) || false,
            }
        ]
        return this.props.userDetails && listItems
    }

    componentWillReceiveProps = (newProps) => {
    };

    areStepsComplete() {
        return (
            ((this.props.userDetails?.verified_otp) || false) &&
            ((this.props.userDetails?.is_already_paid) || false) &&
            ((this.props.userDetails?.is_documentation_complete) || false) &&
            ((this.props.userDetails?.is_medical_initiated) || false)
        );
    }

    checkForToken() {
        const params = queryString.parse(this.props.location.search);
        if (Cookies.get("react_token")) {
            localStorage.setItem("token", Cookies.get("react_token"));
        } else if (!localStorage.getItem("token") && !params.token) {
            (window.location.href =
                window.location.origin + "/login?order_id="+ this.state.orderId);
        } else {
            if (params.token) {
                localStorage.setItem("token", params.token ?? "")
            }
        }

    }

    render() {
        const {classes, userDetails, loadingUserDetails, history, loading} = this.props;
        const {emptyBody, subTitle, bgLightBlue, rightSection, card, cardContent} = classes;
        const {enableUpi} = this.state;
        this.checkForToken();
        this.props.onCallShowLoader(loadingUserDetails);
        const isDocumentsRequired = this.props.userDetails?.are_documents_required;
        const orderId = this.state.orderId
        return (
            <>
                {loadingUserDetails ?
                    <MaterialAppBar/>
                    :
                    !userDetails ?
                        <>
                            <MaterialAppBar/>
                            <Box className={emptyBody}>
                                <Fade direction="right" in={true} timeout={500}>
                                    <Typography variant={"h4"} className={subTitle}>
                                        No Quotation Available !
                                    </Typography>
                                </Fade>
                            </Box>
                        </>
                        :
                        (
                            <SideSection
                                title={""}
                                description={
                                    <Slide direction="right" in={true} timeout={500}>
                                        <Typography variant={"h3"} className={subTitle}>
                                            Here are {" "}
                                            {(this.props.userDetails?.is_medical_required) ? " 4" : isDocumentsRequired ? "3" : "2"}
                                            {" "}steps before we can issue your Toffee policy.
                                        </Typography>
                                    </Slide>
                                }
                                data={
                                    <Box
                                        className={bgLightBlue + ' ' + rightSection}
                                        display={"flex"}
                                        justifyContent={"center"}
                                        flexWrap="wrap"
                                    >
                                        {userDetails && (
                                            <Slide direction="left" in={true} timeout={500}>
                                                <Box>
                                                    <Card className={card}>
                                                        <CardContent className={cardContent}>
                                                            <MaterialListItem listItems={this.listItems()}/>
                                                        </CardContent>
                                                    </Card>
                                                    {!this.areStepsComplete() && (
                                                        <Box display="flex" justifyContent="center" mt={4}>
                                                            <Button
                                                                size="large"
                                                                variant="contained"
                                                                color="secondary"
                                                                endIcon={<ArrowForwardOutlined/>}
                                                                disableElevation
                                                                onClick={(e) => {
                                                                    let url = "/the-toffee-plan/quotation/verify-pay-upload?order_id=" + orderId
                                                                    let paramUrl = "/the-toffee-plan/quotation/verify-pay-upload?enable_upi=yes&order_id=" + orderId
                                                                    userDetails && history.push(
                                                                        (enableUpi !== null && enableUpi.toLowerCase() === "yes" ? paramUrl : url)
                                                                    );
                                                                }}
                                                            >
                                                                NEXT
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Slide>
                                        )}
                                    </Box>
                                }
                            />
                        )}
                <CustomLoader active={loading}/>
            </>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        loadingUserDetails: state.customer.loadingUserDetails,
        token: state.auth.token,
        loading: state.claim.loading,
        error: state.customer.error,
        userDetails: state.customer.userDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
        return {
            onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
            checkIfLoggedIn: () => dispatch(actions.checkIfLoggedIn()),
            retrieveQuotations: (productId) =>
                dispatch(actions.postRetrieveQuotation(productId)),
            enableUpiLink: (data) => dispatch(actions.enableUpiLink(data)),
            getUserDetails: (userId) => dispatch(actions.getUserDetails(userId)),
        };
    }
;
export default withStyles(useStyles, {withTheme: true})
(connect(mapStateToProps, mapDispatchToProps)(Home));
