import { Personal } from "./personal";
import { Qualification } from "./qualification";
import { Lifestyle } from "./lifeStyle";
import { Spouse } from "./spouse";
import { Child1 } from "./child1";
import { Child2 } from "./child2";
import { Nominee } from "./nominee";
import { Address } from "./address";
import { Health } from "./health";

export const Policy = {
  questionnaireList: [
    Personal.data,
    Qualification.data,
    Lifestyle.data,
    Spouse.data,
    Child1.data,
    Child2.data,
    Nominee.data,
    Address.data,
    Health.data,
  ],
};
