import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import MaterialUploadListItem from "./UploadListItem";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {Box, Button} from "@material-ui/core";
import API from "../../../../utilities/api";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import * as actionTypes from "../../../../store/actions/actionTypes";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import ChipSelect from "../../Calculate/ChipSelect";
import moment from "moment";
import {useStyles} from './styles/documentsStyles';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CustomerDocuments extends Component {
    isDocComplete = false;

    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
            documentId: "",
            uploadPercent: "0",
            openDialog: false,
            deleteType: "",
            deleteId: "",
            addressProof: this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"] &&
                this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"]?.length < 2,
            selectValue: this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag ? this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag : "",

            selectedOptionText: this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag === "UtilityBill" ? "Utility Bill" : this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag === "VoterID" ? "Voter ID" :
                this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag === "DrivingLicense" ? "Driving License" : this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag === "Passport" ? "Passport" :
                    this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag === "RentAgreement" ? "Rent Agreement (Current)" : this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag === "EmployerAllotmentLetter" ? "Employer Allotment Letter Bill" :
                        this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag === "PropertyTaxReceipt" ? "Property or Municipal tax receipt" : this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag === "BankPostStatement" ? "Bank statement / Post office account statement (within six months)" : "",

            incomeProof: this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag ? this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag : "",

            selectedIncomeOptionText: this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "SalarySlip" ? "Latest 3 months of Salary Slips" : this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_2" ? "2 years of ITR / Form 16" :
                this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "bankStatement" ? "6 months of Bank statement showing salary credit" : this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "computation" ? "3 years of Computation of Income" :
                    this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_3" ? "3 years of ITR / Form 16" : "",

            numberOfDocs: this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "SalarySlip" ? 3 : this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_2" ? 2 :
                this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "bankStatement" ? 6 : this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "computation" ? 3 :
                    this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_3" ? 3 : null,
            openAlertDialog: false,
            chipOptions: [
                {title: "Yes", value: "yes"},
                {title: "No", value: "no"},
            ],
            selectedChip: this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag ? "no" : "yes",
            incomeButtonText: "",
            bankProof: this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]?.tag ? this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]?.tag : "",
            selectedBankOptionText: this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]?.tag === "cancelledCheque" ? "Cancelled Cheque" : this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]?.tag === "passbook" ? "Passbook Front Page" : this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]?.tag === "statement" ? "Bank Statement" : "",
            imageAsset: "",

            salariedDocumentOptions: [
                {key: "SalarySlip", value: "Latest 3 months of Salary Slips"},
                {key: "ITR_2", value: "2 years of ITR / Form 16"},
                {key: "bankStatement", value: "6 months of Bank statement showing salary credit"},
            ],
            nonSalariedDocumentOptions: [
                {key: "ITR_3", value: "3 years of ITR / Form 16"},
                {key: "computation", value: "3 years of Computation of Income"},
            ],
            addressProofOptions: [
                {key: "UtilityBill", value: "Utility Bill"},
                {key: "VoterID", value: "Voter ID"},
                {key: "DrivingLicense", value: "Driving License"},
                {key: "Passport", value: "Passport"},
                {key: "RentAgreement", value: "Rent Agreement (Current)"},
                {key: "EmployerAllotmentLetter", value: "Employer Allotment Letter"},
                {key: "PropertyTaxReceipt", value: "Property or Municipal tax receipt "},
                {key: "BankPostStatement", value: "Bank statement / Post office account statement (within six months)"},
            ],
        };

        this.handleClick = this.handleClick.bind(this);
        this.isDocumentationComplete();
    }

    handleClick(id) {
        this.setState(
            {
                documentId: id,
            },
            () => {
                this.refs.fileUploader.click();
            }
        );
    }

    handleClickOpenDialog = (type, id) => {
        this.setState({
            openDialog: true,
            deleteType: type,
            deleteId: id,
        });
    };

    handleCloseDialog = () => {
        this.setState({
            openDialog: false,
            deleteType: "",
            deleteId: "",
        });
    };

    detectImage = (e) => {
        this.setState({
            imageAsset: this.detectPdf(e.target.files[0].name) ? require("../../../../assets/img/pdf.png") : URL.createObjectURL(e.target.files[0]),
        })
        e.target.files[0] &&
        this.fileToBase64(e.target.files[0]).then((result) => {
            this.uploadFile(result);
            this.refs.fileUploader.value = "";
        });
    };

    uploadFile(base64) {
        const data = new FormData();
        data.append("data", base64);
        data.append("type", this.state.documentId);
        if (this.state.documentId === "DOCUMENT_ADDRESS_PROOF") {
            data.append("tag", this.state.selectValue);
        }
        if (this.state.documentId === "DOCUMENT_INCOME_PROOF") {
            data.append("tag", this.state.incomeProof);
        }
        if (this.state.documentId === "DOCUMENT_BANK_DETAILS_PROOF") {
            data.append("tag", this.state.bankProof);
        }
        this.setState({
            uploading: true,
        });
        API.post(`uploadDocument/${this.props.userDetails.order_id}`, data, {
            headers: {
                "content-type": "application/x-www-form-urlencoded",
            },
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    uploadPercent: percentCompleted,
                });
            },
        })
            .then((res) => {
                this.setState({
                    uploading: false,
                    uploadPercent: 0,
                });
                this.updateDocumentUrl(
                    res.data.data.document_page_id,
                    // res.data.data.path
                    this.state.imageAsset
                );
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    uploading: false,
                    uploadPercent: 0,
                });
            });
    }

    fileToBase64 = (file) => {
        return new Promise((resolve) => {
            var reader = new FileReader();
            reader.onload = function (event) {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });
    };

    updateDocumentUrl(id, url) {
        let allDocs = {...this.props.userDetails.uploaded_documents};

        let docs = (allDocs[this.state.documentId] && allDocs[this.state.documentId]["pages"]) ? allDocs[this.state.documentId]["pages"]
            : [];

        docs.push({
            id: id,
            url: url,
        });
        allDocs = {...allDocs, [this.state.documentId]: {"pages": docs}};
        let quotation = {
            ...this.props.userDetails,
            uploaded_documents: allDocs,
        };
        this.props.updateQuotation(quotation);
        this.isDocumentationComplete();
    }

    removeDocsFromState(docs, id) {
        return docs.filter((doc) => {
            return doc.id != id;
        });
    }

    deleteDocumentUrl() {
        var allDocs = {...this.props.userDetails.uploaded_documents};
        var docs = this.removeDocsFromState(
            allDocs[this.state.deleteType] && allDocs[this.state.deleteType]["pages"],
            this.state.deleteId
        );
        allDocs = {...allDocs, [this.state.deleteType]: {"pages": docs}};
        var details = {
            ...this.props.userDetails,
            uploaded_documents: allDocs,
        };
        this.props.updateQuotation(details);
        this.isDocumentationComplete();
    }

    deleteDocument() {
        this.props.onCallShowLoader(true);
        let orderId = this.props.userDetails?.order_id;
        API.post(
            `deleteDocument/${orderId}/${this.state.deleteId}`,
            {}
        )
            .then((res) => {
                this.props.onCallShowLoader(false);
                this.deleteDocumentUrl();
                this.handleCloseDialog();
            })
            .catch((err) => {
                this.props.onCallShowLoader(false);
                console.log(err);
                this.handleCloseDialog();
            });
    }

    documentationComplete() {
        this.props.onCallShowLoader(true);
        let orderId = this.props.userDetails?.order_id;
        API.post(
            `orders/${orderId}/documentationComplete`,
            {}
        )
            .then((res) => {
                this.props.onCallShowLoader(false);
                console.log(res);
                this.props.onDoneClicked();
            })
            .catch((err) => {
                this.props.onCallShowLoader(false);
                console.log(err);
            });
    }


    isDocumentationComplete() {
        var complete = true;
        if (!this.props.userDetails) {
            complete = false;
            return;
        }
        if (!this.props.userDetails.uploaded_documents) {
            complete = false;
            return;
        }
        if (
            !this.props.userDetails.uploaded_documents["DOCUMENT_USER_PHOTO"] || !this.props.userDetails.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"] || this.props.userDetails.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"].length < 1
        )
            complete = false;

        if (
            !this.props.userDetails.uploaded_documents["DOCUMENT_PAN"] || !this.props.userDetails.uploaded_documents["DOCUMENT_PAN"]["pages"] || this.props.userDetails.uploaded_documents["DOCUMENT_PAN"]["pages"].length < 1
        )
            complete = false;

        if (
            !this.props.userDetails.uploaded_documents["DOCUMENT_ID"] || !this.props.userDetails.uploaded_documents["DOCUMENT_ID"]["pages"] || this.props.userDetails.uploaded_documents["DOCUMENT_ID"]["pages"].length < 2
        )
            complete = false;


        if (this.props.userDetails?.response_sum_assured_life !== "0") {
            if (
                !!(this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"] && this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"]) ?
                    // !(!this.state.incomeProof || !this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag) ?
                    this.state.incomeProof === "ITR_2" || this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_2" ? this.props.userDetails && this.props.userDetails.uploaded_documents["DOCUMENT_INCOME_PROOF"] && this.props.userDetails.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"]?.length < 2 :
                        (this.state.incomeProof === "SalarySlip" || this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "SalarySlip" ||
                            this.state.incomeProof === "ITR_3" || this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_3" ||
                            this.state.incomeProof === "computation" || this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "computation") ? this.props.userDetails && this.props.userDetails.uploaded_documents["DOCUMENT_INCOME_PROOF"] && this.props.userDetails.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"]?.length < 3 :
                            this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"] && this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"].length < 1
                    : true
            )
                complete = false;

            if (
                !this.props.userDetails.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"] || !this.props.userDetails.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"] || this.props.userDetails.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"].length < 1
            )
                complete = false;
        }

        if (this.state.selectedChip === "no") {

            if (
                !this.props.userDetails.uploaded_documents["DOCUMENT_ADDRESS_PROOF"] || !this.props.userDetails.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"] || this.props.userDetails.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"].length < 1
            )
                complete = false;
        }
        this.isDocComplete = complete;


    }

    componentDidMount() {
        this.isDocumentationComplete();
    }

    handleChange = (e) => {
        this.setState({
            selectValue: e.target.value,
            selectedOptionText: this.state.addressProofOptions.map((option, index) => {
                return e.target.value === option.key ? option.value : ""
            })
        });
    }

    handleIncomeChange = (e) => {
        if (this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"] &&
            this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"] &&
            this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"].length > 0) {
            this.handleOpenAlertDialog()
        } else {
            this.setState({
                incomeProof: e.target.value,
                selectedIncomeOptionText: ((this.props.userDetails?.response_occupation === "salaried") ? this.state.salariedDocumentOptions.map((option, index) => {
                        return e.target.value === option.key ? option.value : ""
                    }) :
                    this.state.nonSalariedDocumentOptions.map((option, index) => {
                        return e.target.value === option.key ? option.value : ""
                    })),
                numberOfDocs: e.target.value === "SalarySlip" ? 3 : e.target.value === "ITR_2" ? 2 :
                    e.target.value === "bankStatement" ? 6 : e.target.value === "computation" ? 3 :
                        e.target.value === "ITR_3" ? 3 : null,
            });
        }
    }

    handleBankChange = (e) => {
        if (this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"] &&
            this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"] &&
            this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"].length > 0) {
            this.handleOpenAlertDialog()
        } else {
            this.setState({
                bankProof: e.target.value,
                selectedBankOptionText: e.target.value === "cancelledCheque" ? "Cancelled Cheque" : e.target.value === "passbook" ? "Passbook Front Page" : e.target.value === "statement" ? "Bank Statement" : "",

                numberOfBankDocs: e.target.value === "cancelledCheque" ? 1 : null,
            });
        }
    }

    handleOpenAlertDialog = (type, id) => {
        this.setState({
            openAlertDialog: true,
        });
    };

    handleCloseAlertDialog = () => {
        this.setState({
            openAlertDialog: false,
        });
    };

    selectTitle(value) {
        this.setState(
            {
                selectedChip: value
            });
        if (value === "yes") {
            this.setState(
                {
                    addressProof: false,
                });
        } else {
            this.setState(
                {
                    addressProof: true,
                });
        }
    }

    detectPdf(url) {
        let fileType = url.split(".").slice(-1)[0];
        return fileType === 'pdf'
    }

    render() {
        const {classes} = this.props;
        this.isDocumentationComplete();
        const incomeProofLength = this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"] &&
            this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"] &&
            this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"].length
        return (
            <>
                <Box
                    // className={classes.topRadiusBackground}
                >
                    <Box style={{backgroundColor: '#fff', borderTopLeftRadius: '24px', borderTopRightRadius: '24px'}}>
                        <Box pt={3}>
                            <Typography className={classes.title} align="center">
                                Upload Documents
                            </Typography>
                        </Box>
                        <Box mt={0} mb={1}>
                            <Typography variant={"h5"} align="center">
                                We need documents to verify your identity
                            </Typography>
                        </Box>
                        <Box className={classes.boxBg} p={3}>
                            <MaterialUploadListItem
                                title="Photograph"
                                frontUploaded={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_USER_PHOTO"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"].length > 0
                                }
                                isDone={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_USER_PHOTO"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"].length > 0
                                }
                                progress={this.state.uploadPercent}
                                selectImage={this.handleClick}
                                isUploading={
                                    this.state.uploading &&
                                    this.state.documentId === "DOCUMENT_USER_PHOTO"
                                }
                                otherUploading={this.state.uploading}
                                id="DOCUMENT_USER_PHOTO"
                                docArray={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_USER_PHOTO"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"]
                                }
                                documents={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_USER_PHOTO"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"]
                                }
                                deleteDocument={(id) => {
                                    this.handleClickOpenDialog("DOCUMENT_USER_PHOTO", id);
                                }}
                                addText={"Take A Selfie"}
                                hintText={"Clear photo of upper body, facing front"}
                                asset={this.state.imageAsset}
                                currentDocId={this.state.documentId}
                            />
                            <MaterialUploadListItem
                                title="PAN Card"
                                // subTitle="PAN Card"
                                frontUploaded={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_PAN"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_PAN"]["pages"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_PAN"]["pages"].length > 0
                                }
                                isDone={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_PAN"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_PAN"]["pages"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_PAN"]["pages"].length > 0
                                }
                                progress={this.state.uploadPercent}
                                selectImage={this.handleClick}
                                isUploading={
                                    this.state.uploading && this.state.documentId === "DOCUMENT_PAN"
                                }
                                otherUploading={this.state.uploading}
                                id="DOCUMENT_PAN"
                                docArray={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_PAN"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_PAN"]["pages"]
                                }

                                documents={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_PAN"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_PAN"]["pages"]
                                }
                                deleteDocument={(id) => {
                                    this.handleClickOpenDialog("DOCUMENT_PAN", id);
                                }}
                                addText={"Add PAN Card"}
                                hintText={"Details on the card should be clearly visible"}
                                asset={this.state.imageAsset}
                                currentDocId={this.state.documentId}
                            />
                            <MaterialUploadListItem
                                title="Aadhaar Card"
                                // subTitle="Aadhaar Card"
                                progress={this.state.uploadPercent}
                                selectImage={this.handleClick}
                                isUploading={
                                    this.state.uploading && this.state.documentId === "DOCUMENT_ID"
                                }
                                otherUploading={this.state.uploading}
                                id="DOCUMENT_ID"
                                docArray={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"] ?
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"] :
                                        localStorage.getItem('imgData')
                                }

                                documents={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"]
                                }
                                deleteDocument={(id) => {
                                    this.handleClickOpenDialog("DOCUMENT_ID", id);
                                }}
                                aadhaar={true}
                                frontUploaded={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"].length > 0
                                }
                                backUploaded={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"].length > 1
                                }
                                isDone={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"].length > 1
                                }
                                hintText={"Details on the card should be clearly visible"}
                                hintText2={"If you do not wish to submit your Aadhaar, kindly upload either of your Passport/Driving Licence/Voters ID"}
                                address={
                                    ((this.props.userDetails?.uploaded_documents["DOCUMENT_ID"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"].length > 0) &&
                                        (this.props.userDetails?.uploaded_documents["DOCUMENT_ID"] &&
                                            this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"] &&
                                            this.props.userDetails?.uploaded_documents["DOCUMENT_ID"]["pages"].length > 1)) &&
                                    <Box display={"flex"} justifyContent={"center"}>
                                        <Box className={classes.addressBox}>
                                            <Box mb={2}>
                                                <Typography variant={"h5"}>
                                                    <b>Is this the same address as on Aadhar card?</b><br/>
                                                </Typography>
                                            </Box>
                                            <Typography variant={"h5"}>
                                                {this.props.userDetails?.address_line_1}{this.props.userDetails?.address_line_1 && ","}<br/>
                                                {this.props.userDetails?.address_line_2}{this.props.userDetails?.address_line_2 && ","}<br/>
                                                {this.props.userDetails?.response_landmark}{this.props.userDetails?.response_landmark && ","}<br/>
                                                {this.props.userDetails?.city}{this.props.userDetails?.city && ","}<br/>
                                                {this.props.userDetails?.state}{this.props.userDetails?.state && ","}<br/>
                                                {this.props.userDetails?.pincode}<br/>
                                            </Typography>
                                            <Box mt={3}>
                                                <ChipSelect
                                                    options={this.state.chipOptions}
                                                    selectedItem={this.state.selectedChip}
                                                    onChange={(value) => {
                                                        this.selectTitle(value);
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>

                                }
                                addressProof={
                                    (this.state.addressProof) &&
                                    <Box display={"flex"} justifyContent={"center"}>
                                        <Box className={classes.addressBox}>
                                            <Typography variant={"h5"}>
                                                <b>Address Proof required for this address</b><br/>
                                            </Typography>
                                            <TextField id="docType" label="Choose document as proof"
                                                       select size={"small"}
                                                       value={this.state.selectValue}
                                                       onChange={this.handleChange}
                                                       fullWidth>
                                                {this.state.addressProofOptions.map((option, index) => {
                                                    return <MenuItem value={option.key}
                                                                     key={index + "goku"}>{option.value}</MenuItem>
                                                })}
                                            </TextField>
                                        </Box>
                                    </Box>


                                }
                                asset={this.state.imageAsset}
                                currentDocId={this.state.documentId}
                            />
                            {(this.state.addressProof && this.state.selectValue !== '') &&
                            <MaterialUploadListItem
                                title={"Address Proof"}
                                frontUploaded={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"]
                                        .length > 0
                                }
                                isDone={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"]
                                        .length > 0
                                }
                                progress={this.state.uploadPercent}
                                selectImage={this.handleClick}
                                isUploading={
                                    this.state.uploading &&
                                    this.state.documentId === "DOCUMENT_ADDRESS_PROOF"
                                }
                                otherUploading={this.state.uploading}
                                id="DOCUMENT_ADDRESS_PROOF"
                                docArray={
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"]
                                }
                                documents={
                                   this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"] &&
                                    this.props.userDetails?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"]
                                }
                                deleteDocument={(id) => {
                                    this.handleClickOpenDialog("DOCUMENT_ADDRESS_PROOF", id);
                                }}
                                addText={"Upload Document"}
                                hintText={this.state.selectedOptionText}
                                asset={this.state.imageAsset}
                                currentDocId={this.state.documentId}
                            />
                            }


                            {(this.props.userDetails?.response_sum_assured_life !== "0") && <>
                                <MaterialUploadListItem
                                    title="Income Proof"
                                    income={true}
                                    // subTitle="Salary Slip / Bank Statement / Form 16"
                                    progress={this.state.uploadPercent}
                                    selectImage={this.handleClick}
                                    isUploading={
                                        this.state.uploading &&
                                        this.state.documentId === "DOCUMENT_INCOME_PROOF"
                                    }
                                    otherUploading={this.state.uploading}
                                    id="DOCUMENT_INCOME_PROOF"
                                    docArray={
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"]
                                    }
                                    numberOfDocs={this.state.numberOfDocs}
                                    frontUploaded={
                                        this.state.numberOfDocs === null ? true :
                                            (this.state.numberOfDocs < 6) ?
                                                (this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"] &&
                                                    this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"] &&
                                                    this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"].length > 0)
                                                : (this.state.numberOfDocs === 6 ? "" : true)
                                    }
                                    itemTwoUploaded={
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"].length > 1
                                    }
                                    itemThreeUploaded={
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"].length > 2
                                    }
                                    isDone={
                                        this.state.incomeProof === "ITR_2" || this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_2" ?
                                            incomeProofLength === 2 : (this.state.incomeProof === "ITR_3" || this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_3" ||
                                            this.state.incomeProof === "SalarySlip" || this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "SalarySlip" ||
                                            this.state.incomeProof === "computation" || this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "computation") ?
                                            incomeProofLength === 3 : incomeProofLength > 0

                                    }
                                    documents={
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"]
                                    }
                                    deleteDocument={(id) => {
                                        this.handleClickOpenDialog("DOCUMENT_INCOME_PROOF", id);
                                    }}
                                    multiple={true}
                                    // addText={this.state.selectedIncomeOptionText ? this.state.selectedIncomeOptionText : "Add income Proof"}
                                    addText={
                                        this.state.incomeProof === "ITR_2" ? "ADD ITR " + moment().subtract(2, "years").format("YYYY") + "-" + moment().subtract(1, "years").format("YYYY").toString().substr(2, 2) : this.state.incomeProof === "ITR_3" ?
                                            "ADD ITR " + moment().subtract(3, "years").format("YYYY") + "-" + moment().subtract(2, "years").format("YYYY").toString().substr(2, 2) : this.state.incomeProof === "computation" ? "ADD COI " + moment().subtract(3, "years").format("YYYY") + "-" + moment().subtract(2, "years").format("YYYY").toString().substr(2, 2) :
                                                this.state.incomeProof === "SalarySlip" ? "ADD " + moment().subtract(3, "months").format("MMMM") + " Salary Slip" : "ADD INCOME PROOF"
                                    }
                                    addText2={
                                        this.state.incomeProof === "ITR_2" ? "ADD ITR " + moment().subtract(1, "years").format("YYYY") + "-" + moment().format("YYYY").toString().substr(2, 2) : this.state.incomeProof === "ITR_3" ?
                                            "ADD ITR " + moment().subtract(2, "years").format("YYYY") + "-" + moment().subtract(1, "years").format("YYYY").toString().substr(2, 2) : this.state.incomeProof === "computation" ? "ADD COI " + moment().subtract(2, "years").format("YYYY") + "-" + moment().subtract(1, "years").format("YYYY").toString().substr(2, 2) :
                                                this.state.incomeProof === "SalarySlip" ? "ADD " + moment().subtract(2, "months").format("MMMM") + " Salary Slip" : ""
                                    }
                                    addText3={
                                        this.state.incomeProof === "ITR_3" ?
                                            "ADD ITR " + moment().subtract(1, "years").format("YYYY") + "-" + moment().format("YYYY").toString().substr(2, 2) : this.state.incomeProof === "computation" ? "ADD COI " + moment().subtract(1, "years").format("YYYY") + "-" + moment().format("YYYY").toString().substr(2, 2) : this.state.incomeProof === "SalarySlip" ? "ADD " + moment().subtract(1, "months").format("MMMM") + " Salary Slip" : ""
                                    }
                                    hintText={this.state.selectedIncomeOptionText}
                                    incomeDropDown={
                                        <Box px={3} pb={3}>
                                            {this.props.userDetails?.response_occupation === "salaried" ?
                                                <TextField id="docTYpe" label="Choose document as proof"
                                                           select size={"small"}
                                                           value={this.state.incomeProof}
                                                           onChange={this.handleIncomeChange}
                                                           fullWidth>
                                                    {this.state.salariedDocumentOptions.map((option, index) => {
                                                        return <MenuItem value={option.key}
                                                                         key={index + "sgfsfd"}>{option.value}</MenuItem>
                                                    })}
                                                </TextField>
                                                :
                                                <TextField id="docTYpe" label="Choose document as proof"
                                                           select size={"small"}
                                                           value={this.state.incomeProof}
                                                           onChange={this.handleIncomeChange}
                                                           fullWidth>
                                                    {this.state.nonSalariedDocumentOptions.map((option, index) => {
                                                        return <MenuItem value={option.key}
                                                                         key={index + "yeti"}>{option.value}</MenuItem>
                                                    })}
                                                </TextField>
                                            }
                                        </Box>
                                    }
                                    asset={this.state.imageAsset}
                                    currentDocId={this.state.documentId}
                                />

                                <MaterialUploadListItem
                                    title="Bank Details"
                                    // subTitle="PAN Card"
                                    frontUploaded={this.state.bankProof !== "" ?
                                        (this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"] &&
                                            this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"] &&
                                            this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"].length > 0)
                                        : true
                                    }
                                    isDone={
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"].length > 0
                                    }
                                    progress={this.state.uploadPercent}
                                    selectImage={this.handleClick}
                                    isUploading={
                                        this.state.uploading && this.state.documentId === "DOCUMENT_BANK_DETAILS_PROOF"
                                    }
                                    otherUploading={this.state.uploading}
                                    id="DOCUMENT_BANK_DETAILS_PROOF"
                                    docArray={
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"]
                                    }

                                    documents={
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"] &&
                                        this.props.userDetails?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"]
                                    }
                                    deleteDocument={(id) => {
                                        this.handleClickOpenDialog("DOCUMENT_BANK_DETAILS_PROOF", id);
                                    }}
                                    addText={"Add Bank Details"}
                                    hintText={"Details should be clearly visible"}
                                    bankDropDown={
                                        <Box px={3} pb={3}>
                                            <TextField id="bankDocTYpe" label="Choose document as proof"
                                                       select size={"small"}
                                                       value={this.state.bankProof}
                                                       onChange={this.handleBankChange}
                                                       fullWidth>
                                                <MenuItem value="cancelledCheque">Cancelled Cheque</MenuItem>
                                                <MenuItem value="passbook">Passbook Front Page </MenuItem>
                                                <MenuItem value="statement">Bank Statement</MenuItem>
                                            </TextField>
                                        </Box>
                                    }
                                    asset={this.state.imageAsset}
                                    currentDocId={this.state.documentId}
                                />
                            </>
                            }
                            <Box display="flex" justifyContent="center" className={classes.submitBtn} pt={2}>
                                {(!this.props.userDetails.is_documentation_complete || !this.isDocComplete) ?
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        onClick={(e) => {
                                            this.documentationComplete();
                                        }}
                                        disabled={!this.isDocComplete}
                                        fullWidth={true}
                                    >
                                        SUBMIT
                                    </Button>
                                    :
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        onClick={(e) => {
                                            this.props.onDoneClicked();
                                        }}
                                        fullWidth={true}
                                    >
                                        Next
                                    </Button>
                                }
                            </Box>
                            <Dialog
                                open={this.state.openDialog}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={(e) => this.handleCloseDialog()}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle id="alert-dialog-slide-title">
                                    {"Delete Document"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        Are you sure you want to delete the document?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={(e) => this.handleCloseDialog()}
                                        color="secondary"
                                    >
                                        Close
                                    </Button>
                                    <Button onClick={(e) => this.deleteDocument()} color="primary">
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={this.state.openAlertDialog}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={(e) => this.handleCloseAlertDialog()}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle id="alert-dialog-slide-title">
                                    {"Delete Document"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        If you want to change the document type, then please delete uploaded documents.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={(e) => this.handleCloseAlertDialog()}
                                        color="secondary"
                                    >
                                        Close
                                    </Button>
                                    {/*<Button onClick={(e) => this.deleteDocument()} color="primary">*/}
                                    {/*    Delete*/}
                                    {/*</Button>*/}
                                </DialogActions>
                            </Dialog>

                            {this.state.documentId === "DOCUMENT_USER_PHOTO" ?
                                <input
                                    type="file"
                                    accept="image/*"
                                    capture="camera"
                                    hidden
                                    id="file"
                                    ref="fileUploader"
                                    onChange={this.detectImage}
                                />
                                :
                                <input
                                    type="file"
                                    accept="image/*,.pdf"
                                    hidden
                                    id="file"
                                    ref="fileUploader"
                                    onChange={this.detectImage}
                                />}
                        </Box>
                    </Box>
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.customer.userDetails,
        error: state.customer.error,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
        retrieveQuotations: (productId) => dispatch(actions.postRetrieveQuotation(productId)),
        updateQuotation: (userDetails) =>
            dispatch({
                type: actionTypes.GET_USERDETAILS_TYPES_SUCCESS,
                userDetails: userDetails,
            }),
        getUserDetails: (userId) => dispatch(actions.getUserDetails(userId)),
    };
};
export default withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(CustomerDocuments)
);
