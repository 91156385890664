import {Strings} from "./strings";
import {QuestionType} from "../utilities/questionBuilder/questionTypes";
import {Keys} from "./keys";
import {TextInputType} from "../utilities/questionBuilder/textInputType";
import moment from "moment";
import React from "react";
import {InputValidator} from "../utilities/questionBuilder/inputValidator";
import {Products} from "./products";

export const Nominee = {

    data: {
        key: Keys.nomineeInfo,
        body: Strings.pickYourNominee,
        subtitle: Strings.nomineeIsRequired,
        title: Strings.pleaseEnsure,
        questions: [
            {
                type: QuestionType.choice,
                key: Keys.nomineeTitle,
                validations: [InputValidator.required],
                initial: Keys.mr,
                inputs: [
                    {value: Keys.mr, label: Strings.male},
                    {value: Keys.ms, label: Strings.female},
                ],
                readonly: true,
                products: {
                    58: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.nomineeSalutation,
                placeholder: Strings.chooseSalutation,
                validations: [InputValidator.required],
                initial: Keys.mr,
                inputs: [
                    {value: Keys.mr, label: Strings.mr},
                    {value: Keys.ms, label: Strings.mrs},
                    {value: Keys.dr, label: Strings.dr},
                    {value: Keys.other, label: Strings.other},
                ],
                products: {
                    58: [true]
                },
            },
            {
                type: QuestionType.choice,
                validation: [InputValidator.required],
                key: Keys.nomineeRelation,
                placeholder: Strings.whichOfYour,
                inputs: [
                    {
                        value: Keys.spouse, label: Strings.spouse, products: {
                            48: [true],
                            49: [true],
                            52: [true],
                            53: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.father, label: Strings.father, products: {
                            48: [true],
                            49: [true],
                            52: [true],
                            53: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.mother, label: Strings.mother, products: {
                            48: [true],
                            49: [true],
                            52: [true],
                            53: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.brother, label: Strings.brother, products: {
                            48: [true],
                            49: [true],
                            52: [true],
                            53: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.sister, label: Strings.sister, products: {
                            48: [true],
                            52: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.son, label: Strings.son, products: {
                            48: [true],
                            52: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.daughter, label: Strings.daughter, products: {
                            48: [true],
                            52: [true],
                            58: [true]
                        },
                    },

                    {
                        value: Keys.grandFather, label: Strings.grandFather, products: {
                            48: [true],
                            52: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.grandMother, label: Strings.grandMother, products: {
                            48: [true],
                            52: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.grandSon, label: Strings.grandSon, products: {
                            48: [true],
                            52: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.grandDaughter, label: Strings.grandDaughter, products: {
                            48: [true],
                            52: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.husband, label: Strings.husband, expand: {
                            [Keys.maritalStatus]: [Keys.married]
                        }, products: {
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.wife, label: Strings.wife, expand: {
                            [Keys.maritalStatus]: [Keys.married]
                        }, products: {
                            48: [true],
                            58: [true]
                        },
                    },
                ],
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    58: [true]
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.nomineeFullName,
                key: Keys.nomineeName,
                validation: [InputValidator.required, InputValidator.name],
                maxLength: 30,
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    58: [true]
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.mobileNumber,
                key: Keys.nomineeMobile,
                validation: [InputValidator.required, InputValidator.numeric],
                minLength: 10,
                maxLength: 10,
                textInputType: TextInputType.phone,
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    58: [true]
                },
            },
            {
                type: QuestionType.date,
                placeholder: Strings.dateOfBirth,
                validation: [InputValidator.required],
                key: Keys.nomineeDob,
                maxDate: moment().subtract(18, "years"),
                minDate: moment()
                    .subtract(100, "years")
                    .add(1, "days"),
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    58: [true]
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.underMarriedWomen,
                readonly: true,
                initial: false,
                placeholder: Strings.underMarriedWomen,
                hasSubQuestions: true,
                expand: {
                    [Keys.title]: [Keys.mr],
                    [Keys.maritalStatus]: [Keys.married]
                },
                products: {
                    48: [true],
                    58: [true]
                }
            },
            {
                type: QuestionType.text,
                key: Keys.addBeneficiary,
                placeholder: Strings.addBeneficiary,
                validations: [
                    InputValidator.required
                ], expand: {
                    [Keys.underMarriedWomen]: [true]
                },
                products: {
                    48: [true],
                    58: [true]
                }
            },
            {
                type: QuestionType.text,
                key: Keys.addTrustee,
                placeholder: Strings.addTrustee, validations: [
                    InputValidator.required
                ],
                expand: {
                    [Keys.underMarriedWomen]: [true]
                },
                products: {
                    48: [true],
                    58: [true]
                }
            },
        ],
    },
};
