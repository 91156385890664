import React, { Component } from "react";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import NotificationAlert from "react-notification-alert";
import API from "../../../utilities/api";

class FileUpload extends Component {
  docIdArray = [];
  valid = false;
  constructor(props) {
    super(props);
    this.state = {
      docIdArray: [],
      valid: false,
    };
    // console.log(props);
  }
  notify = (place, title, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };
  validate = () => {
    this.valid = false;
    var valid = true;
    this.props.requiredDocuments.forEach((document) => {
      if (!this.docIdArray.includes(document.id)) {
        valid = false;
      }
    });
    this.valid = valid;
  };
  submitDocuments = () => {
    var valid = true;
    this.props.requiredDocuments.forEach((document) => {
      if (!this.docIdArray.includes(document.id)) {
        valid = false;
      }
    });
    if (valid) {
      this.props.onCallShowLoader(true);

      API.post(
        "web/claim/submit/" + this.props.claimId,
        {},
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then((response) => {
          this.props.onCallShowLoader(false);

          // console.log("inputs: ", response);

          this.props.setState({
            currentComponent: "success",
            successData: response.data.data,
          });
        })
        .catch((err) => {
          this.props.onCallShowLoader(false);

          // console.log(err);
        });
    } else {
      this.notify("tr", "", "Please upload all documents first", "danger");
    }
  };
  deleteDocument = (docId) => {
    var delDoc = this.props.uploadedDocuments.find(function (e) {
      return e.documentTypeId * 1 === docId;
    });
    this.props.onCallShowLoader(true);

    API.delete("web/claim/document/" + delDoc.documentId, {})
      .then((response) => {
        this.props.onCallShowLoader(false);

        this.props.removeDoc(delDoc);
      })
      .catch((err) => {
        this.props.onCallShowLoader(false);
      });
  };
  render() {
    this.docIdArray = [];
    this.props.uploadedDocuments &&
      this.props.uploadedDocuments.forEach((document) => {
        this.docIdArray.push(document.documentTypeId * 1);
      });
    this.validate();

    return (
      <div className="component-container position-relative m-auto">
        <div className="p-4 d-flex align-items-center">
          <img
            className="icon-60 ml-n3"
            src={require("../../../assets/img/icons/image-icon.png")}
            alt=""
          />
          <h2 className=" font-weight-light txt-secondary d-inline m-0">
            Upload photos/documents
          </h2>
        </div>
        <div className="px-4 pb-4">
          {this.props.requiredDocuments.map((document, index) => {
            return (
              <div className="row bg-shadow-2 border-75 w-100 py-3 px-3 m-0 mb-4">
                <div className="col- ">
                  {this.docIdArray.includes(document.id) ? (
                    <img
                      className="icon-35 mr-3"
                      src={require("../../../assets/img/icons/green-filled-tick.png")}
                      alt=""
                    />
                  ) : (
                    <img
                      className="icon-35 mr-3 cursor-pointer"
                      src={require("../../../assets/img/icons/plus-icon.png")}
                      alt=""
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.switchTo({
                          action: "add_photo",
                          position: index,
                        });
                      }}
                    />
                  )}
                </div>
                <div
                  className="col  p-0 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    !this.docIdArray.includes(document.id) &&
                      this.props.switchTo({
                        action: "add_photo",
                        position: index,
                      });
                  }}
                >
                  <h5 className="m-0 font-weight-normal txt-secondary">
                    {document.title}
                  </h5>
                  {
                    this.docIdArray.includes(document.id) ? (
                      <></>
                    ) : (
                      // <p className="span-10 txt-secondary m-0">{document.desc}</p>
                      <p
                        className="span-10 txt-secondary m-0"
                        dangerouslySetInnerHTML={{ __html: document.desc }}
                      ></p>
                    )

                    // dangerouslySetInnerHTML={{ __html: this.props.emptyArrayMessage }}
                  }
                  {/* {
                                        this.props.uploadedDocuments && this.props.uploadedDocuments.map((uploadedDoc, index) => {
                                            return (uploadedDoc.documentTypeId == document.id) ? <span>uploaded</span> : <span>...</span>
                                        })
                                    } */}
                </div>

                <div
                  className="col- "
                  onClick={(e) => {
                    e.preventDefault();
                    this.deleteDocument(document.id);
                  }}
                >
                  {this.docIdArray.includes(document.id) ? (
                    <img
                      className="icon-17 ml-2 cursor-pointer"
                      src={require("../../../assets/img/icons/delete_icon.png")}
                      alt=""
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          })}

          <div className="my-2 mb-3">
            <h6 className="text-right mt-1 txt-secondary font-weight-normal opacity-80">
              Step {this.props.position.current}/{this.props.position.total}
            </h6>
          </div>
        </div>
        <div
          className={
            "btn-primary cursor-pointer bg-shadow-3 px-4  d-flex justify-content-center align-items-center mobile-position-fixed footer-button  " +
            (this.valid ? "" : "opacity-50")
          }
          onClick={(e) => {
            e.preventDefault();
            this.submitDocuments();
          }}
        >
          <h2 className="font-weight-normal txt-white">Submit</h2>
        </div>

        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
    onGetInitiatedClaimState: () => dispatch(actions.getInitiatedClaims()),
  };
};
export default connect(null, mapDispatchToProps)(FileUpload);
