import moment from 'moment';

export const capitalize = word => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
};
export const extractFirstName = name => {
    if (!name) {
        return name;
    }

    return name.split(' ')[0];
};
export const getTimeSlots = (start, end) => {
    let startTime = moment(start, 'HH:mm');
    let endTime = moment(end, 'HH:mm');
    let timeStops = [];

    if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
    }

    while (startTime <= endTime) {
        let title = new moment(startTime).format('HH:mm');
        if (title > "17:30") {
            break;
        }
        timeStops.push({
            //timestamp: timestamp,
            value: title.toString(), //parseInt(title, 10).toString(),
            title: title
        });

        startTime.add(60, 'minutes');
    }
    return timeStops;
};
export const nextAvialableTimeSlot = (selectedDate) => {
    let date;
    if (selectedDate) {
        date = selectedDate;
    } else {
        date = moment();
    }
    let hours = date.hours();
    const minutes = date.minutes();
    if (minutes >= 30) {
        hours = hours + 3
    } else {
        hours = hours + 2
    }
    return `${hours}:30`;
};
export const parseMessage = message => {
    if (typeof message === "string") {
        return message;
    } else if (Array.isArray(message) && message.length) {
        let array = [];
        const output = message.map(item => [...array, item.msg]);
        return output.join(', ');
    }
};
export const roundTimeSlot = (date, duration, method) => {
    return moment(Math[method]((+date) / (+duration)) * (+duration));
};
export const scrollTo = id => {
    const section = document.querySelector(`#${id}`);
    section.scrollIntoView({behavior: 'smooth', block: 'start'});
};
export const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};

export const getDateSlots = (date = new Date(), days = 7) => {
    let count = 0;
    let startDate = moment(date);
    let dateSlots = [];
    let monthVal = moment(date).format("MM");
    while (count < days) {
        let object = {
            value: moment(startDate).unix(),
            title: moment(startDate).format("DD"),
            day: moment(startDate).format(" ddd"),
            month: moment(startDate).format("MMMM"),
            date: moment(startDate),
        }
        let monthValInner = moment(startDate).format("MM");

        object.isSpaceRequired = count === 0 ? false : (monthVal !== monthValInner);
        if (monthVal !== monthValInner) {
            monthVal = monthValInner
        }
        dateSlots.push(object);
        startDate.add(1, 'days');
        ++count;
    }

    return dateSlots;
};

export const nextAvialableDateSlot = () => {
    const date = moment();
    const nextDateSlot = date.format('dd');

    return nextDateSlot;
};
export const roundDateSlot = (date, duration, method) => {
    return moment(Math[method]((+date) / (+duration)) * (+duration));
};