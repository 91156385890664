import {Keys} from "./keys";
import {Strings} from "./strings";
import {QuestionType} from "../utilities/questionBuilder/questionTypes";
import {Promise as TextInputAction} from "q";
import {TextInputType} from "../utilities/questionBuilder/textInputType";
import {InputValidator} from "../utilities/questionBuilder/inputValidator";
import {Products} from "./products";

export const Address = {

    data: {
        key: Keys.addressInfo,
        body: Strings.addressInfo,
        subtitle: Strings.enterHomeAddress,
        // icon: Icons.location_on,
        title: Strings.homeAddressShouldMatch,
        questions: [
            {
                type: QuestionType.text,
                placeholder: Strings.pin,
                key: Keys.pincode,
                validation: [InputValidator.required, InputValidator.numeric],
                maxLength: 6,
                minLength: 6,
                textInputType: TextInputType.number,
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.state,
                key: Keys.state,
                validation: [InputValidator.required],
                model: "State",
                readonly: true,
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.city,
                key: Keys.city,
                validation: [InputValidator.required],
                readonly: true,
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.addressLineOne,
                key: Keys.addressLine1,
                validation: [InputValidator.required],
                maxLength: 30,
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.addressLineTwo,
                key: Keys.addressLine2,
                maxLength: 30,
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.landmark,
                key: Keys.landmark,
                validation: [InputValidator.required],
                textInputAction: TextInputAction.done,
                products: {
                    48: [true],
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.choice,
                placeholder: Strings.typeOfConstruction,
                key: Keys.constructionType,
                validation: [InputValidator.required],
                inputs: [
                    {value: Keys.bungalow, label: "Bungalow"},
                    {value: Keys.flat, label: "Flat"},
                    {value: Keys.farmhouse, label: "Farmhouse"},
                    {value: Keys.otherR, label: "Farmhouse"},
                ],
                products: {
                    48: [true],
                    52: [true],
                    54: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.yearOfConstruction,
                key: Keys.yearOfConstruction,
                textInputType: TextInputType.number,
                maxLength: 4,
                minLength: 4,
                maxValue: new Date().getFullYear(),
                validation: [InputValidator.required, InputValidator.numeric],
                products: {
                    48: [true],
                    52: [true],
                    54: [true],
                },
            },
            {
                type: QuestionType.toggle,
                validation: [InputValidator.required],
                placeholder: Strings.indianCitizenDeclaration,
                key: Keys.citizen,
                initial: true,
                products: {
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.toggle,
                validation: [InputValidator.required],
                placeholder: Strings.overrideNdnc,
                key: Keys.authorizeIndiaFirst,
                initial: true,
                products: {
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.taxResidentIndia,
                // initial: true,
                readonly: true,
                placeholder: Strings.taxResidentIndia,
                validations: [InputValidator.required],
                products: {
                    48: [true],
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.citizenIcici,
                // initial: true,
                placeholder: Strings.birthCountryIndia,
                validations: [InputValidator.required],
                products: {
                    48: [true],
                },
            },
            {
                type: QuestionType.toggle,
                placeholder: Strings.differentPermanentAddress,
                key: Keys.isPermanentAddressDifferent,
                initial: false,
                hasSubQuestions: true,
                readonly: false,
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.pin,
                key: Keys.permanentPincode,
                validation: [InputValidator.required, InputValidator.numeric],
                maxLength: 6,
                minLength: 6,
                expand: {
                    [Keys.isPermanentAddressDifferent]: [true]
                },
                textInputType: TextInputType.number,
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.state,
                key: Keys.permanentState,
                validation: [InputValidator.required],
                model: "State",
                readonly: true,
                expand: {
                    [Keys.isPermanentAddressDifferent]: [true]
                },
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.city,
                key: Keys.permanentCity,
                validation: [InputValidator.required],
                readonly: true,
                expand: {
                    [Keys.isPermanentAddressDifferent]: [true]
                },
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.addressLineOne,
                key: Keys.permanentAddressLine1,
                validation: [InputValidator.required],
                expand: {
                    [Keys.isPermanentAddressDifferent]: [true]
                },
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.addressLineTwo,
                key: Keys.permanentAddressLine2,
                expand: {
                    [Keys.isPermanentAddressDifferent]: [true]
                },
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.landmark,
                key: Keys.permanentLandmark,
                validation: [InputValidator.required],
                expand: {
                    [Keys.isPermanentAddressDifferent]: [true]
                },
                textInputAction: TextInputAction.done,
                products: {
                    48: [true],
                    52: [true],
                    58: [true],
                },
            },
        ],
    },
};
