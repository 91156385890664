import React, { Component } from "react";

import { connect } from "react-redux";
import * as actions from "../../../store/actions";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

class DateTime extends Component {
  state = {
    openDatePicker: false,
    hideTimePicker: true,
    dateFormat: true,
    timeFormat: false,
    date: null,
    time: null,
  };
  timeList = [
    "12:00 AM",
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  ];

  constructor(props) {
    super(props);
    this.props.onGetInitiatedClaimState();
  }
  onDateChanged = (moment) => {
    const date = new Date(moment._d);
    // console.log(date);
    this.setState({
      openDatePicker: false,
      hideTimePicker: false,
      date: date.toDateString(),
    });
  };
  timeCLicked = (time) => {
    // console.log(time);
    this.setState({
      time: time,
      hideTimePicker: true,
    });
  };

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    nextProps.input.name &&
      this.setState({
        date: null,
        time: null,
      });
    nextProps.claimState &&
      nextProps.claimState.inputs.hasOwnProperty(nextProps.input.name) &&
      this.setState({
        date: this.getDateAndTimeFormatted(
          nextProps.claimState.inputs[nextProps.input.name],
          "date"
        ),
        time: this.getDateAndTimeFormatted(
          nextProps.claimState.inputs[nextProps.input.name],
          "time"
        ),
      });
  }

  componentDidMount() {}
  submit = () => {
    if (this.state.date && this.state.time) {
      this.props.addInput({
        name: this.props.input.name,
        value: this.state.date + " " + this.state.time,
      });
    }
  };
  getDateAndTimeFormatted(input, type) {
    let d = new Date(input);
    switch (type) {
      case "time":
        return (
          d.toLocaleTimeString("en-US", {
            hour12: false,
            hour: "numeric",
            minute: "numeric",
          }) +
          " " +
          (d.getHours() > 12 ? "PM" : "AM")
        );
      case "date":
        return d.toDateString();
      default:
        break;
    }
  }
  render() {
    return (
      <div className="component-container position-relative m-auto">
        <div className="p-4 d-flex align-items-center">
          <img
            className="icon-60 ml-n3"
            src={require("../../../assets/img/icons/calender-icon.png")}
            alt=""
          />
          <h2 className=" font-weight-light txt-secondary d-inline m-0">
            {this.props.input.display_name}
          </h2>
        </div>
        <div className="p-4">
          <div
            className="bg-shadow-2 cursor-pointer border-75 w-100 py-4 px-3"
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                openDatePicker: true,
              });
            }}
          >
            <h4 className="font-weight-light m-0 d-inline txt-secondary opacity-80 ">
              {this.state.date
                ? this.state.date +
                  "  - " +
                  (this.state.time ? this.state.time : "")
                : "Select Date and Time"}
            </h4>
          </div>
          <ReactDatetime
            className="date-picker"
            open={this.state.openDatePicker}
            timeFormat={false}
            dateFormat={this.state.dateFormat}
            onChange={(c) => this.onDateChanged(c)}
          />
          <div className="time-picker" hidden={this.state.hideTimePicker}>
            {
              <>
                {" "}
                {this.timeList.map((time, i) => (
                  <div
                    className={this.state.time === time ? "p-2 active" : "p-2"}
                    key={i}
                    onClick={(e) => {
                      e.preventDefault();
                      this.timeCLicked(time);
                    }}
                  >
                    {" "}
                    {time}{" "}
                  </div>
                ))}{" "}
              </>
            }
          </div>

          <div className="my-2 ">
            <img
              className="icon-17 mr-2"
              src={require("../../../assets/img/icons/question-mark.png")}
              alt=""
            />
            <span className="h6 font-weight-light opacity-50 info-text">
              {this.props.input.helper_text}
            </span>
            <h6 className="d-inline float-right mt-1 txt-secondary font-weight-normal  opacity-80">
              Step {this.props.position.current}/{this.props.position.total}
            </h6>
          </div>
        </div>
        <div
          className={
            "btn-primary cursor-pointer bg-shadow-3 px-4  d-flex justify-content-center align-items-center mobile-position-fixed footer-button  " +
            (this.state.time && this.state.date ? "" : "opacity-50")
          }
          onClick={(e) => {
            e.preventDefault();
            this.submit();
          }}
        >
          <h2 className="font-weight-normal m-0 txt-white">Next</h2>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    claimState: state.claim.claimState,
    error: state.claim.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetInitiatedClaimState: () => dispatch(actions.getInitiatedClaims()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DateTime);
