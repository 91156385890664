import React, { Component } from 'react';

export default class Success extends Component {

    render() {
        // console.log(this.props.data);
        
        return (
            <div className="component-container position-relative m-auto">
                {/* <img className="icon-17 ml-n3 float-right m-3" src={require("../../assets/img/icons/cross-icon.png")} alt="" /> */}

                <div className="p-3 py-5 text-center bg-grayshade-2">
                    <img className="icon-40 " src={require("../../assets/img/icons/green-filled-tick.png")} alt="" />
                    <h1 className=" font-weight-light txt-secondary m-0 mt-3 mb-2">Claim Submitted Successfully</h1>
                    {/* <h4 className=" font-weight-light txt-secondary m-0 opacity-60">The claim is submitted with Toffee Insurance. The repair time will depend upon the degree of damage.</h4> */}
                    <h4 className=" font-weight-light txt-secondary m-0 opacity-60">{this.props.data.message}</h4>
                </div>
                <div className="p-0 py-3 text-center bg-grayshade-1">
                    <h4 className="txt-secondary opacity-60 d-inline font-weight-light font-italic">Claim Intimation ID- </h4>
                    <h3 className="txt-green opaci
                    ty-80 d-inline font-weight-normal">{this.props.data.claimID}</h3>

                </div>

                <div className="p-5 pb-4 text-center">
                    {
                        this.props.data.dealer && <img className="icon-35 "  src={require("../../assets/img/icons/home-icon.png")} alt="" />
                    }
                    
                    <h2 className=" font-weight-normal txt-secondary m-0 mt-3 mb-0">
                    { this.props.data.dealer ? (this.props.data.dealer.dealershipName +", "+ this.props.data.dealer.dealerAddress.city) : ""}</h2>
                    <h5 className=" font-weight-light txt-secondary m-0">{ this.props.data.dealer ?  this.props.data.dealer.dealerMobile : ""}</h5>
                    <h4 className=" font-weight-light txt-secondary m-0 mt-3 opacity-60">{this.props.data.info}</h4>
                </div>
                <hr className="border-75 m-0"/>
                <div className="row footer px-4 py-3 m-0">
                    <div className="col-">
                        <img className="icon-17 mr-2" src={require("../../assets/img/icons/call-red-icon.png")} alt="" />
                    </div>
                    <div className="col p-0">
                        <h5 className=" font-weight-light txt-secondary m-0 d-inline opacity-60">Call to our customer support in case your location/ address has changed.</h5>
                    </div>

                </div>
            </div>
        );
    } 
}