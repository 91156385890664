import React, {Component} from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {AppBar, IconButton, TextField, Toolbar} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import {withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import SearchInput from "./searchInput";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const useStyles = (theme) => ({
    inputBox: {
        backgroundColor: "#f6f6f6",
        // zIndex: "1",
        minHeight: "100vh"
    },
    decoy: {
        height: "150px"
    },
    cantFindBank: {
        padding: "20px",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "0px",
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.11)"
    },
});
const AppBar1 = withStyles(theme => ({
    root: props =>
        props.color === "grey"
            ? {
                color: "#ff585d",
                backgroundColor: theme.palette.primary.main,
            }
            : props.color === "successOutline"
            ? {
                color: "#4bc5b0",
            } : {},

}))(AppBar);


class BankSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBank: '',
            otherBankSelected: false,
            inputValue: "",
        }
        this.showBankList = this.state.bankList
        this.bankFilterList = []
        this.onChange = this.onChange.bind(this);
        this.debitList = [
            {title: " Other Bank"},
            {title: "Andhra Bank"},
            {title: "Axis Bank"},
            {title: "Bank of Baroda - Retail Banking"},
            {title: "Bank of Maharashtra"},
            {title: "Deutsche Bank"},
            {title: "Development Bank of Singapore"},
            {title: "Dhanlaxmi Bank"},
            {title: "Equitas Small Finance Bank"},
            {title: "Federal Bank"},
            {title: "HDFC Bank"},
            {title: "ICICI Bank"},
            {title: "IDFC FIRST Bank"},
            {title: "Indusind Bank"},
            {title: "Karnataka Bank"},
            {title: "Kotak Mahindra Bank"},
            {title: "Paytm Payments Bank"},
            {title: "Punjab National Bank - Retail Banking"},
            {title: "RBL Bank"},
            {title: "South Indian Bank"},
            {title: "State Bank of India"},
            {title: "Tamilnadu Mercantile Bank"},
            {title: "Ujjivan Small Finance Bank"},
            {title: "Yes Bank"},
            {title: "AU Small Finance Bank"},
            {title: "CITI Bank"},
            {title: "DCB Bank"},
        ]
        this.netBankingList = [
            {title: " Other Bank"},
            {title: "Andhra Bank"},
            {title: "Axis Bank"},
            {title: "Bank of Baroda - Retail Banking"},
            {title: "Bank of Maharashtra"},
            {title: "Canara Bank"},
            {title: "Central Bank of India"},
            {title: "City Union Bank"},
            {title: "Cosmos Co-operative Bank"},
            {title: "Deutsche Bank"},
            {title: "Development Bank of Singapore"},
            {title: "Dhanlaxmi Bank"},
            {title: "Equitas Small Finance Bank"},
            {title: "Federal Bank"},
            {title: "HDFC Bank"},
            {title: "Hongkong & Shanghai Banking Corporation"},
            {title: "ICICI Bank"},
            {title: "IDFC FIRST Bank"},
            {title: "Indian Overseas Bank"},
            {title: "Indusind Bank"},
            {title: "Karnataka Bank"},
            {title: "Karur Vysya Bank"},
            {title: "Kotak Mahindra Bank"},
            {title: "PNB (Erstwhile-Oriental Bank of Commerce)"},
            {title: "Paytm Payments Bank"},
            {title: "Punjab & Sind Bank"},
            {title: "Punjab National Bank - Retail Banking"},
            {title: "RBL Bank"},
            {title: "South Indian Bank"},
            {title: "Standard Chartered Bank"},
            {title: "State Bank of India"},
            {title: "Syndicate Bank"},
            {title: "Tamilnadu Mercantile Bank"},
            {title: "Ujjivan Small Finance Bank"},
            {title: "Union Bank of India"},
            {title: "Yes Bank"},
        ]
        this.bankList = this.props.method === 'debitCard' ? this.debitList : this.netBankingList
    }

    onChange = (e) => {
        this.setState({
            bank: e
        })
    }
    onChangeValueKey = (e) => {
        this.setState({
            bank: e
        })
    }


    render() {
        const {classes} = this.props;
        this.props.isMonthlyCheck(true)
        console.log(this.state.otherBankSelected)
        return (<>
                <AppBar1 elevation={0} position="sticky" style={{padding: "0"}} color={"grey"}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={(e) => {
                                this.props.onClickClose()
                            }}
                        >
                            <ArrowBack/>
                        </IconButton>
                    </Toolbar>
                </AppBar1>


                <Box className={classes.inputBox}>
                    <Box p={3} px={4} display="flex" justifyContent="center">
                        <Typography variant={"h4"}>
                            Select your bank and subscribe for auto-debit on your monthly payments.
                        </Typography>
                    </Box>
                    <Box p={2} px={4} display="flex" justifyContent="center">
                        <SearchInput
                            bankList={this.bankList}
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                            otherBank={(state) => {
                                this.setState({
                                    otherBankSelected: state
                                })
                            }}
                            onChangeValueKey={(value) => {
                                this.onChangeValueKey(value)
                            }}
                            otherBankCheck={this.state.otherBankSelected}
                            value={(val) => {
                                this.setState({
                                    inputValue: val
                                })
                            }}
                            inputVal={this.state.inputValue}
                        />
                    </Box>
                    {this.state.otherBankSelected &&
                    <Box pb={2} mt={4} px={4} m={"auto"}>
                        <Box m={"auto"} className={classes.cantFindBank}>
                            <Box py={2} display="flex" justifyContent="center">
                                <Typography variant={"h4"}>
                                    <b>Can’t find your bank?</b>
                                </Typography>
                            </Box>
                            <Box pb={2} display="flex" justifyContent="center">
                                <Typography variant={"h5"} align={"center"}>
                                    Make a one-time payment for this month
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" pb={2} pt={4}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    onClick={(e) => {
                                        this.props.continueToBuy(false, this.props.paymentType, true)
                                    }}
                                >
                                    Continue to Pay
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    }
                    {(this.state.bank && !this.state.otherBankSelected) &&
                    <Box display="flex" justifyContent="center" pb={2} pt={4}>
                        <Button
                            variant="contained"
                            size="large"
                            color="secondary"
                            onClick={(e) => {
                                this.props.continueToBuy(this.props.recurring, this.props.paymentType, false)
                            }}
                        >
                            Continue to Pay
                        </Button>
                    </Box>
                    }
                </Box>

                {/*<Box className={classes.decoy}/>*/}

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.customer.userDetails,
        enableUpi: state.customer.enableUpi,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
        isMonthlyCheck: (data) => dispatch(actions.isMonthlyCheck(data)),


    };
};
export default withRouter(withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(BankSelect)));
