import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
const googleApiKey = "AIzaSyDrRXycUYMU-vM4UMzKHnQSh1gv81nv4Xs";

class Location extends Component {
  state = { pincode: "", stateName: "", city: "" };

  constructor(props) {
    super(props);
    this.props.onGetInitiatedClaimState();
  }
  notify = (place, title, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.claimState.inputs[nextProps.input.name]);
    nextProps.input.name &&
      this.setState({
        input: {
          name: nextProps.input.name,
          value: "",
        },
      });
    nextProps.claimState &&
      nextProps.claimState.inputs.hasOwnProperty(nextProps.input.name) &&
      this.setState({
        name: nextProps.input.name,
        pincode: nextProps.claimState.inputs[nextProps.input.name].pincode,
        stateName: nextProps.claimState.inputs[nextProps.input.name].state,
        city: nextProps.claimState.inputs[nextProps.input.name].city,
      });
  }
  onChangePinInput = (e) => {
    // console.log(e.target.value);

    if (e.target.value.length < 7) {
      this.setState({
        pincode: e.target.value,
      });
    }
    if (e.target.value.length === 6) {
      // console.log("hitting api for: " + e.target.value);
      this.getLocationDetails(e.target.value);
    }
  };
  onChangeStateInput = (e) => {
    this.setState({
      stateName: e.target.value,
    });
  };
  onChangeCityInput = (e) => {
    this.setState({
      city: e.target.value,
    });
  };
  getLocationDetails(pincode) {
    axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          pincode +
          "&key=" +
          googleApiKey
      )
      .then((response) => {
        // console.log(response.data.results[0].address_components);
        const addressArray = response.data.results[0].address_components;
        addressArray.forEach((item) => {
          if (item.types[0] === "administrative_area_level_1") {
            // console.log(item.long_name);
            this.setState({
              stateName: item.long_name,
            });
          }
          if (item.types[0] === "locality") {
            // console.log(item.long_name);
            this.setState({
              city: item.long_name,
            });
          }
        });
      })
      .catch((err) => {
        // console.log(err);
        // if (err.response)
        //     dispatch(getRoleIdFail(err.response.data.error));
        // else
        //     dispatch(getRoleIdFail({ code: 502, message: err.message }));
      });
  }
  detectLocation() {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        this.getAddressFromPosition,
        this.getPositionFromGoogleAPI
      );
    } else {
      this.getPositionFromGoogleAPI();
    }
  }
  getPositionFromGoogleAPI = () => {
    axios
      .post(
        "https://www.googleapis.com/geolocation/v1/geolocate?key=" +
          googleApiKey
      )
      .then((response) => {
        // console.log(response);

        let position = {
          coords: {
            latitude: response.data.location.lat,
            longitude: response.data.location.lng,
          },
        };
        this.getAddressFromPosition(position);
      })
      .catch((err) => {
        console.log(err);

        // console.log(err);
        // if (err.response)
        //     dispatch(getRoleIdFail(err.response.data.error));
        // else
        //     dispatch(getRoleIdFail({ code: 502, message: err.message }));
      });
  };

  getAddressFromPosition = (position) => {
    // console.log(position);

    axios
      .post(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "&key=" +
          googleApiKey
      )
      .then((response) => {
        // console.log(response);

        if (response.data.status === "OK") {
          var addressComponents = response.data.results[0].address_components,
            pincode = "",
            state = "",
            city = "";
          addressComponents.forEach((component) => {
            switch (component.types[0]) {
              //pincode
              case "postal_code":
                pincode = component.long_name;
                break;
              //state
              case "administrative_area_level_1":
                state = component.long_name;
                break;
              //city
              case "locality":
                city = component.long_name;
                break;
              default:
                break;
            }
          });
          this.setState({
            pincode: pincode,
            stateName: state,
            city: city,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        // if (err.response)
        //     dispatch(getRoleIdFail(err.response.data.error));
        // else
        //     dispatch(getRoleIdFail({ code: 502, message: err.message }));
      });
  };
  submit = () => {
    if (this.state.pincode && this.state.city && this.state.stateName) {
      this.props.addInput({
        name: this.props.input.name,
        value: {
          city: this.state.city,
          state: this.state.stateName,
          pincode: this.state.pincode,
        },
      });
    } else {
      this.notify("tr", "", "Please fill all the fields", "danger");
    }
  };
  render() {
    return (
      <div className="component-container position-relative m-auto">
        <div className="p-4 d-flex align-items-center">
          <img
            className="icon-60 ml-n3"
            src={require("../../../assets/img/icons/pin-icon.png")}
            alt=""
          />
          <h2 className=" font-weight-light txt-secondary d-inline m-0">
            {this.props.input.display_name}
          </h2>
        </div>
        <div className="p-4">
          <div className="bg-shadow-2  w-100 ">
            <div className="position-relative align-items-center">
              <input
                className="font-weight-light   py-4 border-75 px-3 m-0 txt-secondary opacity-80 "
                style={{ width: "252px", borderRight: "0" }}
                value={this.state.pincode}
                onChange={this.onChangePinInput}
                type="number"
                onKeyDown={(evt) => {
                  if (
                    evt.key === "e" ||
                    evt.key === "-" ||
                    evt.key === "." ||
                    evt.key === "+"
                  )
                    evt.preventDefault();
                }}
                maxLength="6"
                placeholder="Pin code"
              />

              <span
                className="indicator-text txt-primary position-absolute font-weight-normal cursor-pointer "
                onClick={(e) => {
                  e.preventDefault();
                  this.detectLocation();
                }}
              >
                <img
                  className="mr-1"
                  src={require("../../../assets/img/icons/locate-pin.png")}
                  alt=""
                />
                Locate me
              </span>
            </div>
            <input
              className="font-weight-light w-100 py-4 border-75 px-3 m-0 txt-secondary opacity-80 "
              value={this.state.city}
              onChange={this.onChangeCityInput}
              placeholder="City"
            />
            <input
              className="font-weight-light w-100 py-4 border-75 px-3 m-0 txt-secondary opacity-80 "
              value={this.state.stateName}
              onChange={this.onChangeStateInput}
              placeholder="State"
            />
          </div>

          <div className="my-2 ">
            <img
              className="icon-17 mr-2"
              src={require("../../../assets/img/icons/question-mark.png")}
              alt=""
            />
            <span className="h6 font-weight-light opacity-50 info-text">
              {this.props.input.helper_text}
            </span>
            <h6 className="d-inline float-right mt-1 txt-secondary font-weight-normal  opacity-80">
              Step {this.props.position.current}/{this.props.position.total}
            </h6>
          </div>
        </div>
        {/* <div className="btn-primary bg-shadow-3 px-4  d-flex justify-content-center align-items-center mobile-position-fixed footer-button opacity-50" onClick={e => {
                    e.preventDefault()
                    this.props.switchComponent("multiSelect")
                }}>
                    <h2 className="font-weight-normal m-0 txt-white">
                        Next
                    </h2>
                </div> */}

        <div
          className={
            "btn-primary cursor-pointer bg-shadow-3 px-4  d-flex justify-content-center align-items-center mobile-position-fixed footer-button  " +
            (this.state.city !== "" &&
            this.state.stateName !== "" &&
            this.state.pincode !== ""
              ? ""
              : "opacity-50")
          }
          onClick={(e) => {
            e.preventDefault();
            this.submit();
          }}
        >
          <h2 className="font-weight-normal m-0 txt-white">Next</h2>
        </div>

        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    claimState: state.claim.claimState,
    error: state.claim.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetInitiatedClaimState: () => dispatch(actions.getInitiatedClaims()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Location);
