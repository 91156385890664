import React, {Component} from "react";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {withStyles, Grid, Chip, TextField} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const useStyles = (theme) => ({
    activeChip: {
        backgroundColor: "rgba(255, 88, 93, .2)",
        borderRadius: "4px",
        color: "#ff585d",
    },
    chip: {
        backgroundColor: "rgba(34, 51, 79, .1)",
        borderRadius: "4px",
        color: "#22334f",
    },
});

class MaterialChip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: this.props.initialValue ?? "",
            options: this.props.question.inputs ?? [],
        };
    }

    selectItem(item) {
        this.setState(
            {
                selectedItem: item.value,
            },
            () => {
                this.props.onChange(item.value);
            }
        );
    }

    render() {
        const {classes} = this.props;
        return (
            <>
                <Box pb={2}>
                    <Typography variant={"h4"}><b>{this.props.question.placeholder}</b></Typography>
                </Box>
                <Grid container spacing={2} direction={"row"}>
                    {this.state.options.map((item) => {
                        if (item.expand == null || this.contains(item.expand)) {
                            return (
                                <Grid item>
                                    <Chip
                                        label={item.label}
                                        onClick={(e) => {
                                            this.selectItem(item);
                                        }}
                                        className={
                                            this.props.initialValue === item.value
                                                ? classes.activeChip
                                                : classes.chip
                                        }
                                    />
                                </Grid>
                            );
                        }
                    })}
                </Grid>
            </>
        );
    }

    contains(expand) {
        if (!expand) {
            return false;
        }
        let key = Object.keys(expand)[0];
        let values = Object.values(expand)[0];

        if (!this.props.formData) {
            return false;
        } else {
            if (Object.keys(this.props.formData).includes(key) < 0) {
                return false;
            } else {
                if (values.includes(this.props.formData[key])) {
                    return true;
                }
            }
        }
    }
}

export default withStyles(useStyles, {withTheme: true})(MaterialChip);
