import {createStyles, makeStyles, Typography} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    main: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up("sm")]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: 'row',
            padding: theme.spacing(0,5,0,0),
        },
    },
    sideContent: {
        width: '100%',
    },


})