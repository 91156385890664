import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import Button from "@material-ui/core/Button";
import { postWebService } from '../../helpers/serverUtils';
import { buildInitiateRazorpay } from '../../helpers/quotation';
import SimpleBackdrop from '../Loader/simpleBackdrop';

const RazorpayPayment = (props) => {
    const { 
        entity, 
        entity_id,
        is_monthly,
        is_recurring,
        is_tc_checked,
        take_first_payment, 
        quotationDetails: { 
            name, 
            mobile, 
            email, 
            premium 
        }, 
        quotationDetails, 
        redirectUrl
    } = props; 
    const history = useHistory();

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [paymentInitiated, setPaymentInitiated] = useState(false);
	const [showBackdrop, setShowBackdrop] = useState(false);

	useEffect(() => {
        handlePayClick();
    }, []);
    
	const buildRazorpayOptions = ({ key, rp_order_id }) => {
        return {
            key: key,
            order_id: rp_order_id,
            handler: (response) => {
                updateRazorPay(response);
            },
            prefill: {
                name: name,
                contact: mobile,
                email: email,
                // method: !paymentMode && type,
            },
            notes: {
                order_id: entity_id,
                entity: entity,
            },
            modal: {
                ondismiss: function () {
					setIsFormSubmitted(false);
		            setPaymentInitiated(false);
                },
            },
		};
    };

    const openCheckout = (options) => {
        let rzp = new window.Razorpay(options);
        rzp.open();
    };

    const cbUpdateRazorPay = ({ success }) => {
        if (success) {
			setTimeout(() => {
				setShowBackdrop(false);
			}, 1000);
			history.push({
				pathname: redirectUrl
			});
		} else {
			console.log('need to handle error here...');
		}
    };
    const updateRazorPay = async ({ razorpay_payment_id, razorpay_signature, razorpay_order_id }) => {
        setShowBackdrop(true);
        
		const payload = { entity, entity_id, is_recurring, is_monthly, take_first_payment, razorpay_payment_id, razorpay_signature, razorpay_order_id };
        const response = await postWebService('razorpay/response', payload);

        cbUpdateRazorPay(response.data);
	};

	const initiateRazorpay = async () => {
		const payload = buildInitiateRazorpay(entity, entity_id);
        const response = await postWebService('initiateRazorpay', payload);
        const { data, success } = response.data;
        
		success ? openCheckout(buildRazorpayOptions(data)) : console.log('need to handle error here...');
    };
    
	const handlePayClick = () => {
		setIsFormSubmitted(true);
		setPaymentInitiated(true);
        initiateRazorpay();
	};

    return (
        <>
            <Button
                type="button"
                size="large"
                variant={"contained"}
                color="primary"
                {...((isFormSubmitted || !is_tc_checked) && {disabled: true})}
                onClick={handlePayClick}
            >
                {paymentInitiated 
                    ? 'Processing...'
                    : <>Pay ₹{premium} <small style={{'fontSize': '10px', 'marginLeft': '10px' }}>(includes 18% GST)</small></>}
            </Button>
            {showBackdrop && <SimpleBackdrop />}
        </>
    );
};

export default RazorpayPayment;