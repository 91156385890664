import {createStyles} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    outerCard: {
        width: "100%",
        objectFit: "contain",
        backgroundColor: "#f9f9f9",
        minHeight: "100vh",
        paddingLeft: "14px",
        paddingRight: "14px",
        [theme.breakpoints.down('sm')]: {
            borderTopLeftRadius: "40px",
        },
        [theme.breakpoints.up('sm')]: {
            borderTopLeftRadius: "80px",
        },
        [theme.breakpoints.up('md')]: {
            borderTopLeftRadius: "100px",
        },
    },
    title: {
        color: "#22334f",
        [theme.breakpoints.down('sm')]: {
            fontWeight: "500",
            fontSize: "18px"
        },
        [theme.breakpoints.up('sm')]: {
            fontWeight: "bold",
            fontSize: "30px"
        },
        [theme.breakpoints.up('md')]: {
            fontWeight: "bold",
            fontSize: "30px",
            letterSpacing: "1.5px",
        },
    },
    titleBox: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: "25px",
            paddingBottom: "10px"
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: "25px",
            paddingBottom: "10px"
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: "25px",
            paddingBottom: "25px"
        },
    },
    noteBox: {
        borderRadius: "12px",
        backgroundColor: "#ecf0f4",
        padding: "29px"
    },
    topRadiusBackground: {
        backgroundColor: "#ff585d"
    }
});