import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialListButton from "./MaterialListBotton";
import {ArrowForward, CheckCircleOutline, ChevronRight, ErrorOutline} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import PaymentPlanCard from "./paymentPlanCard";
import Typography from "@material-ui/core/Typography";
import API from "../../../../utilities/api";
import * as actionTypes from "../../../../store/actions/actionTypes";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ArrowForwardOutlined from "@material-ui/icons/ArrowForwardOutlined";
import ReactGA, {ga} from "react-ga";
import {withRouter} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import BankSelect from "./bankSelect";
import {useStyles} from './styles/paymentStyles';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CustomerPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCheckedMonthly:
                (this.props.userDetails && this.props.userDetails?.is_monthly) || false,
            isUpdated: this.props.isUpdated || false,
            openBankSelectDialog: false,
            payType: "",
            paymentData: [],
            isRecurring: (this.props.userDetails && this.props.userDetails?.is_monthly) || false,
            is_already_paid: this.props.userDetails && this.props.userDetails?.is_already_paid || false,
            is_subscribed: false,
            paymentMode: false
        };
    }

    monthlyClick = () => {
        this.setState({
            isCheckedMonthly: true,
            isRecurring: true
        });
    };
    yearlyClick = () => {
        this.setState({
            isCheckedMonthly: false,
            isRecurring: false
        });
    };

    startPayment(isRecurring, type, sub, paymentMode) {
        this.setState({
                isRecurring: isRecurring,
                is_subscribed: sub,
                paymentMode: paymentMode
            }, () => {
                this.handleClose()
                if (isRecurring) {
                    this.createPaymentOrder(isRecurring, type).then((res) => {
                        this.props.onCallShowLoader(false);
                        let options = {
                            key: this.props.userDetails && this.props.userDetails?.rp_key,
                            order_id: this.state.paymentData.data.razorpay_order_id,
                            customer_id: this.state.paymentData.data.razorpay_customer_id,
                            recurring: "1",
                            handler: (response) => {
                                this.updateRazorPay(response);
                            },
                            prefill: {
                                name: this.props.userDetails && this.props.userDetails?.name,
                                contact: this.props.userDetails && this.props.userDetails?.mobile,
                                email: this.props.userDetails && this.props.userDetails?.email,
                            },
                            notes: {
                                order_id: this.props.userDetails && this.props.userDetails?.order_id,
                                entity: "order",
                            },
                            modal: {
                                ondismiss: function () {
                                    console.log("Dismissed");
                                },
                            },
                        };
                        this.openCheckout(options);
                    })
                        .catch((err) => {
                            this.props.onCallShowLoader(false);
                            // console.log(err);
                        });
                } else {
                    this.createPaymentOrder(isRecurring, type).then((res) => {
                        this.props.onCallShowLoader(false);
                        this.props.updateQuotation({
                            ...this.props.userDetails,
                            rp_order_id: this.state.paymentData.data.data.rp_order_id,
                            amount: this.state.paymentData.data.data.amount,
                            rp_key: this.state.paymentData.data.data.key,
                        });
                        this.initiateAnnualPayment(type, this.state.paymentMode);
                    })
                        .catch((err) => {
                            this.props.onCallShowLoader(false);
                            // console.log(err);
                        })
                }
            }
        );
    }

    initiateAnnualPayment(type, paymentMode) {
        let options = {
            key: this.props.userDetails && this.props.userDetails?.rp_key,
            order_id: this.props.userDetails && this.props.userDetails?.rp_order_id,
            handler: (response) => {
                this.updateRazorPay(response);
            },
            prefill: {
                name: this.props.userDetails && this.props.userDetails?.name,
                contact: this.props.userDetails && this.props.userDetails?.mobile,
                email: this.props.userDetails && this.props.userDetails?.email,
                method: !paymentMode && type,
            },
            notes: {
                order_id: this.props.userDetails && this.props.userDetails?.order_id,
                entity: "order",
            },
            modal: {
                ondismiss: function () {
                    console.log("Dismissed");
                },
            },
        };
        this.openCheckout(options);
    }

    openCheckout(options) {
        let rzp = new window.Razorpay(options);
        rzp.open();
    }

    updateRazorPay(response) {
        this.props.onCallShowLoader(true);
        API.post(
            "razorpay/response",
            {
                entity: "order",
                entity_id: this.props.userDetails && this.props.userDetails?.order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                razorpay_order_id: response.razorpay_order_id,
                is_recurring: this.state.isRecurring,
                is_monthly: this.state.isCheckedMonthly,
                take_first_payment: this.state.isRecurring,
            }
        )
            .then((res) => {
                this.props.onCallShowLoader(false);
                this.setState({
                    is_already_paid: true
                })

                this.facebookTag();
                this.googleAds();
                ReactGA.event({
                    category: 'Purchase',
                    action: 'Button Click',
                    label: 'Submit'
                });
                ga('ec:setAction', 'purchase', {
                    'revenue': this.state.isCheckedMonthly ? this.props.userDetails?.response_monthly_premium : this.props.userDetails?.response_annual_premium,
                    'id': this.props.userDetails?.order_id,
                });

                this.props.paymentDone(this.state.isRecurring);
            })
            .catch((err) => {
                this.props.onCallShowLoader(false);
                console.log(err);
            });
    }

    async createPaymentOrder(isRecurring, type) {
        this.props.onCallShowLoader(true);
        if (isRecurring) {
            const response = await API.post(
                `policies/${this.props.userDetails?.order_id}/payments/subscribe`,
                {
                    type: type,
                    take_first_payment: true,
                }
            );
            // const json = await response.json();
            this.setState({paymentData: response});
        } else {
            const response = await API.post(
                "initiateRazorpay",
                {
                    entity: "order",
                    entity_id: this.props.userDetails && this.props.userDetails?.order_id,
                    is_monthly: this.state.isCheckedMonthly,
                }
            );
            // const json = await response.json();
            this.setState({paymentData: response});
        }
    }

    handleClose = () => {
        this.setState({
            openBankSelectDialog: false,
        });
    };
    handleClickOpen = (isRecurring, type, method) => {
        this.setState({
            openBankSelectDialog: true,
            payType: type,
            isRecurring: isRecurring,
            paymentMethod: method
        });
    };

    facebookTag = () => {
        window.fbq('track', 'Purchase', {
            value: this.state.isCheckedMonthly ? this.props.userDetails?.response_monthly_premium : this.props.userDetails?.response_annual_premium,
            currency: 'inr',
        });
    }

    gtag() {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(arguments);
    }

    googleAds = () => {
        return (
            this.gtag('event', 'conversion', {
                'send_to': 'AW-808880541/O1ccCIb5k80BEJ2T2oED',
                'value': this.state.isCheckedMonthly ? this.props.userDetails?.response_monthly_premium : this.props.userDetails?.response_annual_premium,
                'currency': 'INR',
                'transaction_id': this.props.userDetails?.order_id
            })
        )
    }

    render() {
        const {classes} = this.props;
        this.state.isRecurring && this.props.isMonthlyCheck(this.state.isRecurring)
        console.log(this.props.userDetails?.amount)
        console.log(this.props.userDetails?.response_monthly_premium)
        return (
            <Box>
                <Box style={{backgroundColor: '#fff', borderTopLeftRadius: '24px', borderTopRightRadius: '24px'}}>
                    <Box className={classes.titleBox}>
                        <Typography className={classes.title} align="center">
                            {!(this.state.is_already_paid) ? "Pay Policy Premium" : "Premium Paid !"}
                        </Typography>
                    </Box>
                    <Box className={classes.outerCard} p={3}>
                        {!this.state.is_already_paid &&
                        <PaymentPlanCard
                            amount={this.props.userDetails?.amount}
                            monthlyPremium={
                                this.state.isUpdated ?
                                    this.props.monthlyPremium
                                    :
                                    this.props.userDetails?.response_monthly_premium
                            }
                            annualPremium={
                                this.state.isUpdated ?
                                    this.props.annualPremium
                                    :
                                    this.props.userDetails?.response_annual_premium
                            }
                            monthClicked={this.monthlyClick.bind(this)}
                            yearClicked={this.yearlyClick.bind(this)}
                            isMonthly={this.state.isCheckedMonthly}
                            disabled={
                                (this.props.userDetails?.is_already_paid) ||
                                false
                            }
                        />
                        }
                        <Grid item container direction="column">
                            <Grid item container>
                                <Grid item xs={false} md={3}/>
                                <Grid item xs={12} md={6}>
                                    {!(this.state.is_already_paid) && (<>
                                            {this.state.isCheckedMonthly && (<>
                                                    <Box p={4} pb={2} display="flex" justifyContent="center">
                                                        <Typography variant={"h5"} align="center">
                                                            For monthly payments, please subscribe for auto-debit.
                                                        </Typography>
                                                    </Box>
                                                    <Divider/>
                                                </>
                                            )}
                                            <Box p={2} pb={4}>
                                                <Box pt={2}>
                                                    <MaterialListButton
                                                        title="Credit Card"
                                                        subTitle={
                                                            this.state.isCheckedMonthly
                                                                ?
                                                                <Typography component="span" variant={"body2"}>
                                                                    Accepting only <b>Visa</b> and <b>MasterCard</b>
                                                                </Typography>
                                                                : ""
                                                        }
                                                        onListClick={() => {
                                                            this.startPayment(this.state.isCheckedMonthly ? this.state.isRecurring : false, "card");
                                                        }}
                                                        icon={<ArrowForward/>}
                                                    />
                                                </Box>
                                                <Box pt={1}>
                                                    <MaterialListButton
                                                        title="Debit Card"
                                                        onListClick={() => {
                                                            this.state.isCheckedMonthly ?
                                                                this.handleClickOpen(this.state.isRecurring, "netbanking", 'debitCard')
                                                                :
                                                                this.startPayment(false, "card");
                                                        }}
                                                        icon={<ArrowForward/>}
                                                    />
                                                </Box>
                                                <Box pt={1}>
                                                    <MaterialListButton
                                                        title="Net Banking"
                                                        onListClick={() => {
                                                            this.state.isCheckedMonthly ?
                                                                this.handleClickOpen(this.state.isRecurring, "netbanking", 'netBanking')
                                                                :
                                                                this.startPayment(false, "netbanking");
                                                        }}
                                                        icon={<ArrowForward/>}
                                                    />
                                                </Box>
                                                {(!this.state.isCheckedMonthly || (this.props.enableUpi && (this.props.userDetails?.response_monthly_premium && this.props.monthlyPremium <= 2000))) && (
                                                    <>
                                                        <Box pt={1}>
                                                            <MaterialListButton
                                                                title="UPI"
                                                                onListClick={() => {
                                                                    this.startPayment(this.state.isCheckedMonthly ? this.state.isRecurring : false, "upi");
                                                                }}
                                                                icon={<ArrowForward/>}
                                                            />
                                                        </Box>
                                                    </>
                                                )}
                                                {!this.state.isCheckedMonthly && (
                                                    <>
                                                        <Box pt={1}>
                                                            <MaterialListButton
                                                                title="Wallet"
                                                                onListClick={() => {
                                                                    // this.handleClickOpen()
                                                                    this.startPayment(false, "wallet");
                                                                }}
                                                                icon={<ArrowForward/>}
                                                            />
                                                        </Box>
                                                    </>
                                                )}


                                            </Box>
                                            {this.state.isCheckedMonthly && (<>
                                                <Box className={classes.noteBox} mt={4}>
                                                    <Typography variant={"h5"} align="justify">
                                                        <b>Please Note:</b>
                                                    </Typography>
                                                    <Divider/>
                                                    <Box pt={2} pb={2}>
                                                        <Typography variant={"h6"} align="justify">
                                                            ₹1 will be deducted for authentication payment and it will be
                                                            refunded after authentication.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </>)}
                                        </>
                                    )}
                                    {(this.state.is_already_paid && ((this.state.isCheckedMonthly) && !(this.props.userDetails.is_subscribed || this.state.is_subscribed))) && (
                                        <Box display="flex" justifyContent="center" pb={2} pt={4} style={{
                                            backgroundColor: "#dfebe9",
                                        }}>
                                            <Box>
                                                <Box p={3} pt={4}>
                                                    <Box display="flex" justifyContent="center" pb={3}>
                                                        <CheckCircleOutline style={{color: "#0be42d", fontSize: "40px"}}
                                                                            fontSize={"large"}/>
                                                    </Box>
                                                    <Box pb={3}>
                                                        <Typography variant={"h3"} align={"center"}>
                                                            <b>
                                                                Payment Successful
                                                            </b>
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant={"h5"} align={"center"}>
                                                        Your one month payment is successful.
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" justifyContent="center" pb={2} pt={4}>
                                                    <Button
                                                        variant="contained"
                                                        size="large"
                                                        color="secondary"
                                                        endIcon={<ArrowForwardOutlined/>}
                                                        onClick={(e) => {
                                                            this.props.onNext();
                                                        }}
                                                    >
                                                        CONTINUE
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>)
                                    }

                                    {this.state.is_already_paid && (!(this.state.isCheckedMonthly) && !this.props.userDetails.is_subscribed) && (
                                        <Box display="flex" justifyContent="center" pb={2} pt={4} style={{
                                            backgroundColor: "#dfebe9",
                                        }}>
                                            <Box>
                                                <Box p={3} pt={4}>
                                                    <Box display="flex" justifyContent="center" pb={3}>
                                                        <CheckCircleOutline style={{color: "#0be42d", fontSize: "40px"}}
                                                                            fontSize={"large"}/>
                                                    </Box>
                                                    <Box pb={3}>
                                                        <Typography variant={"h3"} align={"center"}>
                                                            <b>
                                                                Payment Successful
                                                            </b>
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant={"h5"} align={"center"}>
                                                        Your annual payment is successful.
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" justifyContent="center" pb={2} pt={4}>
                                                    <Button
                                                        variant="contained"
                                                        size="large"
                                                        color="secondary"
                                                        endIcon={<ArrowForwardOutlined/>}
                                                        onClick={(e) => {
                                                            this.props.onNext();
                                                        }}
                                                    >
                                                        CONTINUE
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>)
                                    }

                                    {(this.state.is_already_paid && ((this.state.isCheckedMonthly) && (this.props.userDetails.is_subscribed || this.state.is_subscribed))) && (
                                        <Box display="flex" justifyContent="center" pb={2} pt={4} style={{
                                            backgroundColor: "#dfebe9",
                                        }}>
                                            <Box>
                                                <Box p={3} pt={4}>
                                                    <Box display="flex" justifyContent="center" pb={3}>
                                                        <CheckCircleOutline style={{color: "#0be42d", fontSize: "40px"}}
                                                                            fontSize={"large"}/>
                                                    </Box>
                                                    <Box pb={3}>
                                                        <Typography variant={"h3"} align={"center"}>
                                                            <b>
                                                                Subscription Successful
                                                            </b>
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant={"h5"} align={"center"}>
                                                        You have successfuly subscribed for monthly auto-debit payments.
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" justifyContent="center" pb={2} pt={4}>
                                                    <Button
                                                        variant="contained"
                                                        size="large"
                                                        color="secondary"
                                                        endIcon={<ArrowForwardOutlined/>}
                                                        onClick={(e) => {
                                                            this.props.onNext();
                                                        }}
                                                    >
                                                        CONTINUE
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>)
                                    }

                                </Grid>
                                <Grid item xs={false} md={3}/>
                            </Grid>
                        </Grid>
                        <Dialog
                            fullScreen
                            open={this.state.openBankSelectDialog}
                            onClose={() => this.handleClose()}
                            TransitionComponent={Transition}
                        >
                            <BankSelect
                                onClickClose={this.handleClose} paymentType={this.state.payType}
                                recurring={this.state.isRecurring}
                                continueToBuy={(isRecurring, type, paymentMode) => this.startPayment(isRecurring, type, isRecurring, paymentMode)}
                                method={this.state.paymentMethod}
                            />
                        </Dialog>
                    </Box>
                </Box>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.customer.userDetails,
        enableUpi: state.customer.enableUpi,
        isUpdated: state.customer.isUpdated,
        annualPremium: state.customer.annualPremium,
        monthlyPremium: state.customer.monthlyPremium,
        isMonthly: state.customer.isMonthly

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
        updateQuotation: (userDetails) =>
            dispatch({
                type: actionTypes.GET_USERDETAILS_TYPES_SUCCESS,
                userDetails: userDetails,
            }),
        isMonthlyCheck: (data) => dispatch(actions.isMonthlyCheck(data)),


    };
};
export default withRouter(withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(CustomerPayment))
);
