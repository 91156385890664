import {Keys} from "./keys";
import {Strings} from "./strings";
import {QuestionType} from "../utilities/questionBuilder/questionTypes";
import moment from "moment";
import {InputValidator} from "../utilities/questionBuilder/inputValidator";
import {Products} from "./products";

export const Child2 = {
    data: {
        key: Keys.child2Info,
        body: Strings.submitChild2Info,
        subtitle: Strings.pleaseFillChild2BasicDetails,
        // icon: Icons.person,
        title: Strings.ensureDetailsAreCorrect,
        questions: [
            {
                type: QuestionType.choice,
                key: Keys.child2Title,
                validation: [InputValidator.required],
                initial: Keys.mr,
                placeholder: Strings.title,
                inputs: [
                    {
                        value: Keys.mr,
                        label: Strings.son,
                    },
                    {
                        value: Keys.ms,
                        label: Strings.daughter,
                    },
                ],
                products: {
                    52: [true],
                    53: [true],
                    49: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.fullName,
                key: Keys.child2Name,
                validation: [InputValidator.required, InputValidator.name],
                maxLength: 30,
                products: {
                    52: [true],
                    53: [true],
                    49: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.date,
                placeholder: Strings.dateOfBirth,
                key: Keys.child2Dob,
                validation: [InputValidator.required],
                min: -26,
                max: 0,
                maxDate: moment().subtract(0, "years"),
                minDate: moment()
                    .subtract(26, "years")
                    .add(1, "days"),
                products: {
                    52: [true],
                    53: [true],
                    49: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.slider,
                validation: [InputValidator.required],
                key: Keys.child2Feet,
                placeholder: Strings.heightFeet,
                min: 1,
                max: 8,
                step: 1,
                initial: 3,
                products: {
                    52: [true],
                    53: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.slider,
                validation: [InputValidator.required],
                key: Keys.child2Inches,
                placeholder: Strings.heightInches,
                min: 0,
                max: 11,
                step: 1,
                initial: 0,
                products: {
                    52: [true],
                    53: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.slider,
                validation: [InputValidator.required],
                key: Keys.child2Weight,
                placeholder: Strings.weightKilograms,
                min: 1,
                max: 150,
                step: 0.5,
                initial: 42,
                products: {
                    52: [true],
                    53: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.child2Tobacco,
                placeholder: Strings.currentlySmoke,
                hasSubQuestions: true,
                readonly: true,
                initial: false,
                products: {
                    53: [true]
                },
            },
            {
                type: QuestionType.checkbox,
                key: Keys.child2TobaccoAs,
                validation: [InputValidator.required],
                placeholder: Strings.tobaccoProducts,
                expand: {
                    [Keys.child2Tobacco]: [true]
                },
                inputs: [
                    {value: Keys.bidi, label: Strings.bidi},
                    {value: Keys.gutka, label: Strings.gutka},
                    {value: Keys.pan, label: Strings.panMasala},
                    {value: Keys.cigarettes, label: Strings.cigarette},
                    {value: Keys.cigar, label: Strings.cigar},
                ],
                products: {
                    53: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.child2TobaccoQuantity,
                validation: [InputValidator.required],
                placeholder: Strings.frequencyConsumption,
                expand: {
                    [Keys.child2Tobacco]: [true]
                },
                inputs: [
                    {value: Keys.lessThan5, label: Strings.lessThan5},
                    {value: Keys.between6and10, label: Strings.sixToTen},
                    {value: Keys.moreThan10, label: Strings.moreThan10},
                ],
                products: {
                    53: [true]
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.child2Alcohol,
                validation: [InputValidator.required],
                placeholder: Strings.consumeAlcohol,
                hasSubQuestions: true,
                initial: false,
                products: {
                    53: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.child2AlcoholFrequency,
                validation: [InputValidator.required],
                placeholder: Strings.consumptionFrequency,
                expand: {
                    [Keys.child2Alcohol]: [true]
                },
                inputs: [
                    {value: Keys.lessThanThirty, label: Strings.lessThanThirty},
                    {value: Keys.thirtyToOneHundredEighty, label: Strings.thirtyToOneHundredEighty},
                    {value: Keys.moreThanOneHundredEighty, label: Strings.moreThanOneHundredEighty},
                ],
                products: {
                    53: [true]
                },
            },
        ],
    },
};
