import React, {Component} from "react";

import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import ResponsiveContainer from "../../../components/ResponsiveContainer/responsiveContainer";
import QuestionBuilder from "../../../utilities/questionBuilder/questionBuilder";
import {Policy} from "../../../data/policy";
import {AppBar, Box, Fab, IconButton, LinearProgress, Toolbar, withStyles,} from "@material-ui/core";
import {ArrowBack, ArrowForward, Home, Call, Done, FiberManualRecord} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import * as actionTypes from "../../../store/actions/actionTypes";
import API from "../../../utilities/api";
import {Keys} from "../../../data/keys";
import CustomLoader from "../../../components/Common/CustomLoader";
import {Spouse} from "../../../data/spouse";
import {Child1} from "../../../data/child1";
import {Child2} from "../../../data/child2";
import {InputValidator} from "../../../utilities/questionBuilder/inputValidator";
import Button from "@material-ui/core/Button";
import {strings} from "../../../languages/localization";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import MuiTimelineDot from "@material-ui/lab/TimelineDot";
import {Nominee} from "../../../data/nominee";

const useStyles = (theme) => ({
    fab: {
        position: "absolute",
        right: theme.spacing(4),
    },
    bottom3: {
        bottom: theme.spacing(4),
    },
    title: {
        flexGrow: 1,
        textAlign: "left",
    },
    outer: {
        height: "calc(100vh - 69px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    bgRed: {
        backgroundColor: theme.palette.primary.main,
    },
    whiteCurvedContainer: {
        backgroundColor: "#ffffff",
        borderRadius: "24px 24px 0 0",
        // height: '100vh',
        overflowY: 'scroll',
        height: "calc(100vh - 69px)",
        boxShadow: "0 24px 50px 0 rgba(192, 192, 192, 0.5)"
    },
    whiteCurvedContainerDesktop: {
        backgroundColor: "#ffffff",
        borderRadius: "24px",
        // height: '100vh',
        overflowY: 'scroll',
        height: "calc(80vh - 69px)",
        boxShadow: "0 24px 50px 0 rgba(192, 192, 192, 0.5)"
    },
    progress: {
        backgroundColor: "#eeeeee",
        height: "5px",
    },
    questionnaireTitle: {
        fontWeight: "bold",
        textAlign: "center"
    },
    questionnaireSubtitle: {
        textAlign: "center",
        padding: "0 32px"
    },
    inner: {
        display: "flex",
        justifyContent: "space-between",
        margin: "auto",
        width: "100%",
        padding: "0 48px"
    },
    timelineBox: {
        minWidth: "50%",
        display: "flex",
        justifyContent: "start",
        alignItems: "center"
    },
    timelineTitleNext: {
        fontSize: "18px"
    },
    timelineTitlePrev: {
        fontSize: "22px"
    },
    timelineTitleCurrent: {
        fontWeight: "bold",
        fontSize: "22px"
    },
    itemPadding: {
        padding: "0 16px !important",
        marginTop: "-5px !important"
    },
    fabBox: {
        marginBottom: "32px",
        padding: "0 16px 80px 0",
        display: "flex",
        justifyContent: "flex-end"
    },
    mainContainerUser: {
        margin: "auto",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "600px",
        },
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: "100%",
            paddingLeft: "0",
            paddingRight: "0",
        },
        [theme.breakpoints.up("lg")]: {
            width: "100%",
            paddingLeft: "0",
            paddingRight: "0",
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: "1290px",
            paddingLeft: "0",
            paddingRight: "0",
        },
        [theme.breakpoints.up(1441)]: {
            maxWidth: "1440px",
            paddingLeft: "0",
            paddingRight: "0",
        },
    },
    grey: {
        backgroundColor: "#e8e8e8",
    },
    green: {
        backgroundColor: "#4bc5b0",
    },
    dotPadding2: {
        paddingTop: "2px"
    },
    dotPaddingLeft: {
        paddingLeft: "5px",
        paddingTop: "6px"
    }
});
let btnDisabled = true;

const TimelineDot = withStyles(theme => ({
    root: props =>
        props.color === "success"
            ? {
                color: "#4bc5b0",
                backgroundColor: "#4bc5b0",
            }
            : props.color === "successOutline"
            ? {
                color: "#4bc5b0",
            } : {},

}))(MuiTimelineDot);

class BuyFlow extends Component {
    state = {
        currentStep: 0,
        questionnaireList: Policy.questionnaireList,
        nomineeQuestionnaire:Nominee.data,
        currentQuestionnaire: Policy.questionnaireList[0],
        formData: {},
        disabledBtn: true,
        healthKeys: [Keys.ped1, Keys.disease, Keys.disease2, Keys.disease3],
        showThankYouPage: false,
        width: window.innerWidth,
        isDesktop: window.innerWidth > 960,
    };
    formData = {};
    showSubmit = false;
    subtitleExits = false;

    constructor(props) {
        super(props);
        this.checkValidation(0);
        window.addEventListener("resize", this.updateWidth);
    }

    componentDidMount() {
        this.updateWidth();
    }

    updateWidth = () => {
        this.setState({
            width: window.innerWidth,
            isDesktop: this.state.width > 960
        });
    };

    mapQuestionInitialValueToState() {
        if (this.props.buyFormData && this.props.buyFormData['response_father_name']) {
            this.checkChildSpouse(this.props.calculateData.formData)
            return;
        }
        this.state.questionnaireList.forEach((questionnaire) => {
            questionnaire.questions.forEach((question) => {
                console.log(question)
                if (question.initial !== undefined) {
                    this.formData = {
                        ...this.formData,
                        [question.key]: question.initial
                    }
                }
            })
        });
        if (this.props.calculateData) {
            this.props.calculateData.formData && this.props.calculateData.formData['gender'] && (this.formData = {
                ...this.formData,
                [Keys.name]: this.props.calculateData.formData['gender'],
                [Keys.title]: (this.props.calculateData.formData['gender'] === 'male') ? 'mr' : 'ms',
                [Keys.spouseTitle]: (this.props.calculateData.formData['gender'] === 'male') ? 'ms' : 'mr'
            });
            this.props.calculateData.formData && this.props.calculateData.formData['name'] && (this.formData = {
                ...this.formData,
                [Keys.name]: this.props.calculateData.formData['name'],
                [Keys.otherLifeInsurancePersonName1]: this.props.calculateData.formData['name'],
                [Keys.otherLifeInsurancePersonName2]: this.props.calculateData.formData['name'],

            });
            this.props.calculateData.formData && this.props.calculateData.formData['email'] && (this.formData = {
                ...this.formData,
                [Keys.email]: this.props.calculateData.formData['email']
            });
            this.props.calculateData.formData && this.props.calculateData.formData['mobile'] && (this.formData = {
                ...this.formData,
                [Keys.mobile]: this.props.calculateData.formData['mobile']
            });
            this.props.calculateData.formData && this.props.calculateData.formData['dob'] && (this.formData = {
                ...this.formData,
                [Keys.dob]: this.props.calculateData.formData['dob']
            });
            this.props.calculateData.formData && this.props.calculateData.formData['sum_assured_life'] && (this.formData = {
                ...this.formData,
                [Keys.sumAssuredLifeR]: this.props.calculateData.formData['sum_assured_life']
            });
            this.props.calculateData.formData && this.props.calculateData.formData['sum_assured_health'] && (this.formData = {
                ...this.formData,
                [Keys.sumAssuredHealthR]: this.props.calculateData.formData['sum_assured_health']
            });
            this.props.calculateData.formData && this.props.calculateData.formData['sum_assured_home'] && (this.formData = {
                ...this.formData,
                [Keys.sumAssuredHomeR]: this.props.calculateData.formData['sum_assured_home']
            });
            this.props.calculateData.formData && this.props.calculateData.formData['is_smoker'] && (this.formData = {
                ...this.formData,
                [Keys.tobacco]: this.props.calculateData.formData['is_smoker']
            });

            this.props.calculateData.premiumData && this.props.calculateData.premiumData['premiums']['annual'] && (this.formData = {
                ...this.formData,
                [Keys.annualPremiumR]: this.props.calculateData.premiumData['premiums']['annual']
            });
            this.props.calculateData.premiumData && this.props.calculateData.premiumData['premiums']['monthly'] && (this.formData = {
                ...this.formData,
                [Keys.monthlyPremiumR]: this.props.calculateData.premiumData['premiums']['monthly']
            });
            this.props.calculateData.premiumData && this.props.calculateData.premiumData['premium'] && (this.formData = {
                ...this.formData,
                [Keys.premium]: this.props.calculateData.premiumData['premium']
            });
            // this.props.calculateData.formData && this.props.calculateData.formData['members[spouse][dob]'] && (this.formData = {
            //     ...this.formData,
            //     [Keys.spouseDob]: this.props.calculateData.formData['members[spouse][dob]']
            // });
            this.props.calculateData.formData && this.props.calculateData.formData['members[spouse][dob]'] && (this.formData = {
                ...this.formData,
                [Keys.spouseDob]: this.props.calculateData.formData['members[spouse][dob]'],
                [Keys.maritalStatus]: 'married'
            });
            this.props.calculateData.formData && this.props.calculateData.formData['members[child1][dob]'] && (this.formData = {
                ...this.formData,
                [Keys.child1Dob]: this.props.calculateData.formData['members[child1][dob]']
            });
            this.props.calculateData.formData && this.props.calculateData.formData['members[child2][dob]'] && (this.formData = {
                ...this.formData,
                [Keys.child2Dob]: this.props.calculateData.formData['members[child2][dob]']
            });

            this.props.calculateData.formData && this.checkChildSpouse(this.props.calculateData.formData);
        } else {
            this.props.history.push('/the-toffee-plan/calculate-premium');
        }
        this.formData = {
            ...this.formData,
            [Keys.sendQuotation]: true
        };
        this.formData = {
            ...this.formData,
            [Keys.orderId]: null
        };
        this.props.updateBuyFormData(this.formData);
    }

    checkChildSpouse(formData) {
        let list = [...this.state.questionnaireList];
        if (!formData['members[spouse][dob]']) {
            const index = list.indexOf(Spouse.data);
            list.splice(index, 1);
            this.setState({
                questionnaireList: list
            })
        }
        if (!formData['members[child1][dob]']) {
            const index = list.indexOf(Child1.data);
            list.splice(index, 1);
            this.setState({
                questionnaireList: list
            })
        }
        if (!formData['members[child2][dob]']) {
            const index = list.indexOf(Child2.data);
            list.splice(index, 1);
            this.setState({
                questionnaireList: list
            })
        }
    }

    componentDidMount() {
        this.mapQuestionInitialValueToState();
    }

    checkValidation(index) {
        console.log(index)
        console.log(this.state.questionnaireList[index].questions)
        let valid = true;
        this.showSubmit = false;
        const questions = this.state.questionnaireList[index].questions;
        if (this.props.buyFormData) {
            questions.forEach(question => {
                console.log(question)
                    if (question.expand == null || this.contains(question.expand)) {
                        if (question.validation && question.validation.includes(InputValidator.required) && Object.keys(this.props.buyFormData).indexOf(question.key) < 0) {
                            valid = false;
                        } else {
                            if (question.validation && question.validation.includes(InputValidator.required) &&
                                (this.props.buyFormData[question.key]?.toString().trim() === "")) {
                                valid = false;
                            }
                        }
                    }
                    if (this.state.healthKeys.includes(question.key) && Object.keys(this.props.buyFormData).indexOf(question.key) > 0 && this.props.buyFormData[question.key]) {
                        this.showSubmit = true;
                    }
                }
            );

            if (this.props.buyFormError && Object.keys(this.props.buyFormError).includes(this.state.currentQuestionnaire.key) &&
                (this.props.buyFormError[this.state.currentQuestionnaire.key].length > 0)) {
                valid = false;
            }

            btnDisabled = !valid
        } else {
            btnDisabled = true
        }
    }

    checkForErrorToRemove(qkey, value) {
        this.state.currentQuestionnaire.questions.forEach((question) => {
            if (question.expand) {
                let key = Object.keys(question.expand)[0];
                let values = Object.values(question.expand)[0];

                if (this.props.buyFormData) {
                    if (key == qkey && !value) {
                        // console.log(this.state.currentQuestionnaire.key, " : ", question.key)
                        this.onRemoveError(this.state.currentQuestionnaire.key, question.key)
                    }
                }
            }

        })
    }

    onRemoveError(questionnaireKey, questionKey) {
        let errorArray = [];
        if (this.props.buyFormError && this.props.buyFormError[questionnaireKey]) {
            errorArray = this.props.buyFormError[questionnaireKey];
        }
        errorArray = errorArray.filter((item) => {
            return item.key !== questionKey;
        });
        this.props.addBuyFlowError({
                [questionnaireKey]: errorArray
            }
        )
    }

    contains(expand) {
        if (!expand) {
            return false;
        }
        let key = Object.keys(expand)[0];
        let values = Object.values(expand)[0];

        if (!this.props.buyFormData) {
            return false;
        } else {
            if (Object.keys(this.props.buyFormData).includes(key) < 0) {
                return false;
            } else {
                if (values.includes(this.props.buyFormData[key])) {
                    return true;
                } else {
                    return false
                }
            }
        }
    }

    nextStep() {
        try {
            console.log()
            this.props.calculateData && this.props.calculateData.formData && this.props.calculateData.formData['mobile'] && this.props.addLeadToFirebase(this.props.calculateData.formData['mobile'] + '-51', {
                buy: this.props.buyFormData ?? {}
            })
        } catch (err) {
            console.log(err)
        }
        if (this.state.currentStep > this.state.questionnaireList.length - 2) {
            this.issuePolicy();
            return;
        }
        this.checkValidation(this.state.currentStep + 1);
        this.setState({
            currentQuestionnaire: this.state.questionnaireList[this.state.currentStep + 1],
            currentStep: this.state.currentStep + 1,
        });
        this.scrollToTop();
        window.scrollTo(0, 0);
        // document.querySelector('body').scrollTo(0,0)
    }

    prevStep() {
        if (this.state.currentStep < 1) {
            this.formData = {};
            this.props.updateBuyFormData(this.formData);
            this.props.history.push('/the-toffee-plan/calculate-premium');
            return;
        }
        this.checkValidation(this.state.currentStep - 1);

        this.setState({
            currentQuestionnaire: this.state.questionnaireList[this.state.currentStep - 1],
            currentStep: this.state.currentStep - 1,
        });
        this.scrollToTop();
        window.scrollTo(0, 0);
        // document.querySelector('body').scrollTo(0,0)
    }

    appendMembers(formData) {
        let response_members = {}
        let other_insurance = []
        let data = {...formData};
        if (formData[Keys.spouseDob]) {
            let spouse = {
                "title": formData[Keys.spouseTitle],
                "name": formData[Keys.spouseName],
                "dob": formData[Keys.spouseDob],
                "height_feet": formData[Keys.spouseFeet].toString(),
                "height_inch": formData[Keys.spouseInches].toString(),
                "weight": formData[Keys.spouseWeight].toString(),
            };
            delete data[Keys.spouseTitle];
            delete data[Keys.spouseName];
            delete data[Keys.spouseDob];
            delete data[Keys.spouseFeet];
            delete data[Keys.spouseInches];
            delete data[Keys.spouseWeight];
            response_members = {...response_members, "spouse": spouse}
            this.formData = {...this.formData, response_members}
        }
        if (formData[Keys.child1Dob]) {
            let child1 = {
                "title": formData[Keys.child1Title],
                "name": formData[Keys.child1Name],
                "dob": formData[Keys.child1Dob],
                "height_feet": formData[Keys.child1Feet].toString(),
                "height_inch": formData[Keys.child1Inches].toString(),
                "weight": formData[Keys.child1Weight].toString(),
            };
            delete data[Keys.child1Title];
            delete data[Keys.child1Name];
            delete data[Keys.child1Dob];
            delete data[Keys.child1Feet];
            delete data[Keys.child1Inches];
            delete data[Keys.child1Weight];
            response_members = {...response_members, "child1": child1}
            this.formData = {...this.formData, response_members}
        }
        if (formData[Keys.child2Dob]) {
            let child2 = {
                "title": formData[Keys.child2Title],
                "name": formData[Keys.child2Name],
                "dob": formData[Keys.child2Dob],
                "height_feet": formData[Keys.child2Feet].toString(),
                "height_inch": formData[Keys.child2Inches].toString(),
                "weight": formData[Keys.child2Weight].toString(),
            };
            delete data[Keys.child2Title];
            delete data[Keys.child2Name];
            delete data[Keys.child2Dob];
            delete data[Keys.child2Feet];
            delete data[Keys.child2Inches];
            delete data[Keys.child2Weight];
            response_members = {...response_members, "child2": child2}
            this.formData = {...this.formData, response_members}
        }

        if (formData[Keys.haveOtherLifeInsuranceR]) {
            other_insurance = [...other_insurance, {
                [Keys.otherLifeInsuranceCommencementYear]: formData[Keys.otherLifeInsuranceCommencementYear1],
                [Keys.otherLifeInsuranceCompanyName]: formData[Keys.otherLifeInsuranceCompanyName1],
                [Keys.otherLifeInsurancePersonName]: formData[Keys.otherLifeInsurancePersonName1],
                [Keys.otherLifeInsurancePolicyStatus]: formData[Keys.otherLifeInsurancePolicyStatus1],
                [Keys.otherLifeInsurancePolicyType]: formData[Keys.otherLifeInsurancePolicyType1],
                [Keys.otherLifeInsuranceSumInsured]: formData[Keys.otherLifeInsuranceSumInsured1],
            }]
            delete data[Keys.otherLifeInsuranceCommencementYear1];
            delete data[Keys.otherLifeInsuranceCompanyName1];
            delete data[Keys.otherLifeInsurancePersonName1];
            delete data[Keys.otherLifeInsurancePolicyStatus1];
            delete data[Keys.otherLifeInsurancePolicyType1];
            delete data[Keys.otherLifeInsuranceSumInsured1];
            this.formData = {...this.formData, other_insurance}
        }
        ;
        if (formData[Keys.otherLifeInsurancePolicyType2]) {
            other_insurance = [...other_insurance, {
                [Keys.otherLifeInsuranceCommencementYear]: formData[Keys.otherLifeInsuranceCommencementYear2],
                [Keys.otherLifeInsuranceCompanyName]: formData[Keys.otherLifeInsuranceCompanyName2],
                [Keys.otherLifeInsurancePersonName]: formData[Keys.otherLifeInsurancePersonName2],
                [Keys.otherLifeInsurancePolicyStatus]: formData[Keys.otherLifeInsurancePolicyStatus2],
                [Keys.otherLifeInsurancePolicyType]: formData[Keys.otherLifeInsurancePolicyType2],
                [Keys.otherLifeInsuranceSumInsured]: formData[Keys.otherLifeInsuranceSumInsured2],
            }]
            delete data[Keys.otherLifeInsuranceCommencementYear2];
            delete data[Keys.otherLifeInsuranceCompanyName2];
            delete data[Keys.otherLifeInsurancePersonName2];
            delete data[Keys.otherLifeInsurancePolicyStatus2];
            delete data[Keys.otherLifeInsurancePolicyType2];
            delete data[Keys.otherLifeInsuranceSumInsured2];
            this.formData = {...this.formData, other_insurance}

        }
        ;

        data = {...data, response_members: response_members, [Keys.otherLifeInsurancesR]: other_insurance};
        return data;
    }

    issuePolicy() {
        if (!this.showSubmit) {
            this.props.onCallShowLoader(true);
            let data = this.appendMembers(this.props.buyFormData)
            if (!data[Keys.addressLine2]) {
                data = {...data, [Keys.addressLine2]: ""}
            }
            if (this.props.calculateData.partner_slug) {
                data = {...data, partner_slug: this.props.calculateData.partner_slug}
            }
            if (this.props.calculateData.partner_reference) {
                data = {...data, response_partner_reference: this.props.calculateData.partner_reference}
            }
            if (this.props.buyFormData.annual_income_salary) {
                data = {...data, [Keys.annualIncome]: this.props.buyFormData.annual_income_salary}
            }
            if (this.props.buyFormData.annual_income_non_salary) {
                data = {...data, [Keys.annualIncome]: this.props.buyFormData.annual_income_non_salary}
            }
            API.post("issuePolicy/51", data)
                .then((response) => {
                    this.props.onCallShowLoader(false);
                    this.props.history.push('/the-toffee-plan/quotation/verify-pay-upload');
                })
                .catch((err) => {
                    this.props.onCallShowLoader(false);
                    console.log(err);
                });
        } else {

        }

    }

    scrollToTop() {
        let elmnt = document.getElementById("formBlock");
        let y = elmnt.scrollTo(0, 0);
    }

    timelineSubtitle = (title) => {
        let subtitle = '';
        if (!this.props.buyFormData || !this.props.buyFormData[title]) return '';
        switch (title) {
            case Keys.name : {
                subtitle = this.props.buyFormData[title];
                break;
            }
            case Keys.email : {
                subtitle = ', ' + this.props.buyFormData[title];
                break;
            }
            case Keys.education : {
                subtitle = this.state.questionnaireList.map((questionnaire) => {
                    return questionnaire.questions.map((question) => {
                        return question.inputs && question.inputs.map((options) => {
                            return this.props.buyFormData[title] === options.value && options.label;
                        })
                    })
                })
                break;
            }
            case Keys.occupation : {
                subtitle = this.props.buyFormData[title] === 'others' ? ', ' + "Others" :
                    this.state.questionnaireList.map((questionnaire) => {
                        return questionnaire.questions.map((question) => {
                            return question.inputs && question.inputs.map((options) => {
                                return this.props.buyFormData[title] === options.value && ', ' + options.label;
                            })
                        })
                    })
                break;
            }
            case Keys.spouseName : {
                subtitle = this.props.buyFormData[title];
                break;
            }
            case Keys.spouseDob : {
                subtitle = ', ' + this.props.buyFormData[title];
                break;
            }
            case Keys.child1Name : {
                subtitle = this.props.buyFormData[title];
                break;
            }
            case Keys.child1Dob : {
                subtitle = ', ' + this.props.buyFormData[title];
                break;
            }
            case Keys.child2Name : {
                subtitle = this.props.buyFormData[title];
                break;
            }
            case Keys.child2Dob : {
                subtitle = ', ' + this.props.buyFormData[title];
                break;
            }
            case Keys.nomineeRelation : {
                subtitle = this.state.nomineeQuestionnaire.questions.map((question) => {
                    return question.inputs && question.inputs.map((options) => {
                        return this.props.buyFormData[title] === options.value && options.label;
                    })
                })
                break;
            }
            case Keys.nomineeName : {
                subtitle = ', ' + this.props.buyFormData[title];
                break;
            }
            case Keys.nomineeDob : {
                subtitle = ', ' + this.props.buyFormData[title];
                break;
            }
            case Keys.city : {
                subtitle = ', ' + this.props.buyFormData[title];
                break;
            }
            case Keys.state : {
                subtitle = ', ' + this.props.buyFormData[title];
                break;
            }
            case Keys.pincode : {
                subtitle = this.props.buyFormData[title];
                break;
            }
            case Keys.tobacco : {
                if (this.props.buyFormData[title] === true && !this.subtitleExits) {
                    this.subtitleExits = true;
                    return "Duly Filled";
                } else if (!this.props.buyFormData[title]) {
                    this.subtitleExits = false;
                    subtitle = 'Non Smoker';
                } else {
                    this.subtitleExits = false;
                }
                break;
            }

            case Keys.alcohol : {
                if (this.props.buyFormData[title] === true && !this.subtitleExits) {
                    return "Duly Filled";
                } else if (!this.props.buyFormData[title]) {
                    this.subtitleExits = false;
                    subtitle = 'Non Drinker';
                } else {
                    this.subtitleExits = false;
                }
                break;
            }
            default : {
            }
        }
        return subtitle;
    }

    render() {
        const {classes} = this.props;
        this.checkValidation(this.state.currentStep);
        return (
            <ResponsiveContainer
                flag={this.state.isDesktop ? "buyFlow" : "calculate"}
                child={
                    <>
                        <Box className={!this.state.isDesktop && classes.bgRed} id={"topLocation"}>
                            <AppBar elevation={0} position="sticky" style={{padding: "0"}}>
                                <LinearProgress
                                    variant="determinate"
                                    value={(100 * (this.state.currentStep + 1)) / this.state.questionnaireList.length}
                                    color={"secondary"}
                                    className={classes.progress}
                                />
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="back"
                                        onClick={() => {
                                            this.prevStep();
                                        }}
                                    >
                                        <ArrowBack/>
                                    </IconButton>
                                    <Typography variant="h2" className={classes.title}>
                                        Buy
                                        Toffee {this.state.currentStep + 1 + "/"}
                                        <small>{this.state.questionnaireList.length}</small>
                                    </Typography>
                                    <IconButton color="inherit" aria-label="contact">
                                        <Call/>
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box className={this.state.isDesktop && classes.mainContainerUser}>
                                <Box className={this.state.isDesktop && classes.outer}>
                                    <Box className={this.state.isDesktop && classes.inner}>
                                        {this.state.isDesktop &&
                                        <Box className={classes.timelineBox}>
                                            <Timeline align={"left"}>
                                                {this.state.questionnaireList.map((questionnaire, index) => {
                                                    return <TimelineItem key={index + "sdhasvg"}>
                                                        <TimelineSeparator
                                                            className={(index > this.state.currentStep) && classes.dotPaddingLeft}>
                                                            <TimelineDot
                                                                variant={(index < this.state.currentStep || index > this.state.currentStep) ? "default" : "outlined"}
                                                                color={questionnaire.body === this.state.currentQuestionnaire.body ? "successOutline" : (index < this.state.currentStep) ? "success" : "grey"}
                                                            >
                                                                {(index < this.state.currentStep) ?
                                                                    <Done style={{color: "#ffffff", fontSize: "10px"}}/>
                                                                    : (index === this.state.currentStep) ?
                                                                        <FiberManualRecord style={{
                                                                            color: "#ffffff",
                                                                            fontSize: "10px"
                                                                        }}/> : ""
                                                                }
                                                            </TimelineDot>
                                                            {
                                                                index !== this.state.questionnaireList.length - 1 &&
                                                                <TimelineConnector
                                                                    className={(index === this.state.currentStep - 1) || (index < this.state.currentStep - 1) ? classes.green : classes.grey}
                                                                />
                                                            }
                                                        </TimelineSeparator>
                                                        <TimelineContent
                                                            className={classes.itemPadding}>
                                                            <Typography
                                                                className={questionnaire.body === this.state.currentQuestionnaire.body ? classes.timelineTitleCurrent : (index < this.state.currentStep) ? classes.timelineTitlePrev : classes.timelineTitleNext}>
                                                                {questionnaire.body}
                                                            </Typography>
                                                            <Typography variant={"h5"}>
                                                                {(index <= this.state.currentStep) &&
                                                                questionnaire.questions.map((question, index) => {
                                                                    return <>{this.timelineSubtitle(question.key, index)}</>
                                                                })}
                                                            </Typography>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                })}
                                            </Timeline>
                                        </Box>
                                        }
                                        <Box>
                                            <Box position={"relative"}
                                                 className={this.state.isDesktop ? classes.whiteCurvedContainerDesktop : classes.whiteCurvedContainer}
                                                 m={"auto"}
                                                 id="formBlock">
                                                {!this.state.showThankYouPage ? <>
                                                    <Box pt={3} pb={1}>
                                                        <Typography variant="h2"
                                                                    className={classes.questionnaireTitle}
                                                                    color={'secondary'}>
                                                            {this.state.currentQuestionnaire.body}
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="h4"
                                                                className={classes.questionnaireSubtitle}
                                                                color={'secondary'}>
                                                        {this.state.currentQuestionnaire.subtitle}
                                                    </Typography>
                                                    <Box p={4}>
                                                        {this.state.currentQuestionnaire.questions.map((question) => {
                                                            return <QuestionBuilder
                                                                questionnaireKey={this.state.currentQuestionnaire.key}
                                                                key={question.key + 'eafrewrjhgu'}
                                                                question={question}
                                                                formData={this.props.buyFormData}
                                                                initialValue={this.props.buyFormData && this.props.buyFormData[question.key]}
                                                                onQuestionChange={(key, value) => {
                                                                    this.formData = {
                                                                        ...this.formData,
                                                                        [key]: value
                                                                    };
                                                                    this.props.updateBuyFormData(this.formData);
                                                                    this.checkForErrorToRemove(key, value);
                                                                }}
                                                                error={this.props.buyFormError && Object.keys(this.props.buyFormError).includes(this.state.currentQuestionnaire.key) ?
                                                                    this.props.buyFormError[this.state.currentQuestionnaire.key].filter((error) => {
                                                                        return error.key === question.key
                                                                    })[0] : null}
                                                            />;
                                                        })}
                                                    </Box>
                                                    <Box className={classes.fabBox}>
                                                        {this.showSubmit ?
                                                            <Button
                                                                className={classes.fab}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showThankYouPage: true
                                                                    });
                                                                }}
                                                            >
                                                                Submit
                                                            </Button>
                                                            : <Fab
                                                                aria-label={"Next"}
                                                                className={classes.fab}
                                                                color={"primary"}

                                                                onClick={() => {
                                                                    this.nextStep();
                                                                }}
                                                                disabled={btnDisabled}
                                                            >
                                                                <ArrowForward/>
                                                            </Fab>}
                                                    </Box>
                                                </> : <Box>
                                                    <br/>
                                                    <Typography variant="h4"
                                                                className={classes.questionnaireSubtitle}
                                                                color={'secondary'}>
                                                        Thank You for submitting your response.
                                                    </Typography>
                                                    <br/>
                                                    <Typography variant="h4"
                                                                className={classes.questionnaireSubtitle}
                                                                color={'secondary'}>
                                                        Our customer care representative will reach out to
                                                        you.
                                                    </Typography>
                                                    <Box display={"flex"} justifyContent={"flex-end"} pb={4}
                                                         pr={2}>

                                                        <Button
                                                            className={classes.fab + " " + classes.bottom3}
                                                            variant="contained"
                                                            color="primary"
                                                            startIcon={<Home/>}
                                                            onClick={() => {
                                                                this.props.history.push('/the-toffee-plan/calculate-premium');
                                                            }}
                                                        >
                                                            {strings.go_to_home}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                }

                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <CustomLoader active={this.props.loading}/>
                    </>
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.customer.error,
        calculateData: state.customer.calculatePremiumData,
        buyFormData: state.customer.buyFormData,
        loading: state.claim.loading,
        buyFormError: state.customer.buyFormError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
        updateBuyFormData: (data) =>
            dispatch({
                type: actionTypes.UPDATE_BUY_FORM_DATA,
                data: data,
            }),
        addBuyFlowError: (error) =>
            dispatch({
                type: actionTypes.SET_BUY_FORM_ERROR,
                error: error,
            }),
        addLeadToFirebase: (doc, data) => dispatch(actions.captureFirebaseLead(doc, data))
    };
};

export default withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(BuyFlow)
);
