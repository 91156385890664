import React, { Component } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

const useStyles = (theme) => ({
  coverContainer: {
    marginBottom: "40px",
  },
  icon: { height: "23px", width: "23px" },
  listItem: {
    padding: "0",
    paddingBottom: "8px",
  },
  listTitle: {
    fontWeight: "bold",
  },
  listAvatar: {
    minWidth: "47px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "46px",
    },
  },
});

class CoveredCardItem extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.coverContainer}>
        <ListItem className={classes.listItem}>
          <ListItemAvatar className={classes.listAvatar}>
            {this.props.isCovered ? (
              <img
                className={classes.icon}
                src={require("../../../assets/img/icons/blue_tick.png")}
                alt=""
              />
            ) : (
              <img
                className={classes.icon}
                src={require("../../../assets/img/icons/red_cross.png")}
                alt=""
              />
            )}
          </ListItemAvatar>

          <ListItemText
            primary={
              <Box m={0} p={0} pr={2}>
                <Typography variant={"h3"} className={classes.listTitle}>
                  {this.props.title}
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <Box m={0} p={0}>
          {this.props.children}
        </Box>
      </Box>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(CoveredCardItem);
