import React, {Component} from "react";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = ((theme) => ({
    smartCard: {
        width: "310px",
        height: "196px",
        borderRadius: "20px",
        boxShadow: "0 20px 40px 0 rgba(0, 0, 0, 0.16), inset 0 1px 0 0 rgba(255, 255, 255, 0.29)",
        backgroundImage: "radial-gradient(circle at 89% 14%, #ff585d, #00c9f3 104%)",
        display: "inline-block",
        padding: "32px",
        position: "relative"
    },
    bgLogo: {
        position: "absolute",
        right: "7px",
        bottom: "10px",
        height: "109px",
        width: "90px",
    },
    planLogo: {
        position: 'absolute',
        left: '20px',
        top: '20px',
        height: '42px'
    },
    toffeeLogo: {
        position: 'absolute',
        right: '20px',
        top: '20px',
        width: '49px',
        height: '30px',
    },
    cardTitle: {
        fontFamily: 'Lato',
        position: 'absolute',
        left: '62px',
        top: '36px',
        color: '#ffffff',
        fontWeight: 'bold',
        letterSpacing: '1.8px',
    },
    toffeeIdLabel: {
        position: 'absolute',
        top: '90px',
        left: '20px',
    },
    cardLabel: {
        fontFamily: 'Lato',
        letterSpacing: '1px',
        color: 'rgba(0, 0, 0, 0.4)',
    },
    toffeeId: {
        position: 'absolute',
        top: '108px',
        left: '20px',
        fontFamily: 'Lato',
        fontWeight: 'Bold',
        letterSpacing: '3px',
        textShadow: '2px 2px 3px #8080806e',
        color: '#fbfbfb'
    },
    policyHolderLabel: {
        position: 'absolute',
        top: '142px',
        left: '20px',
    },
    policyHolder: {
        position: 'absolute',
        top: '160px',
        left: '20px',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        letterSpacing: '1.25px',
        color: '#fbfbfb',
        textShadow: '2px 2px 3px #8080806e',
    },


}));

class PolicyCard extends Component {
    render() {
        const {classes} = this.props;
        return (
            <Box className={classes.smartCard} position="relative">
                <img
                    src={require("../../../assets/img/verification/combined-shape-copy-8.png")}
                    alt=""
                    className={classes.bgLogo}
                />
                <img
                    src={require("../../../assets/img/verification/combined-shape-white.png")}
                    alt=""
                    className={classes.planLogo}
                />
                <Typography variant={"body2"} className={classes.cardTitle}>
                    THE INSURANCE PLAN
                </Typography>
                <img
                    src={require("../../../assets/img/verification/logo.png")}
                    alt=""
                    className={classes.toffeeLogo}
                />
                <Typography variant={"body2"} className={classes.toffeeIdLabel + ' ' + classes.cardLabel} >
                    TOFFEE ID
                </Typography>
                <Typography variant={"subtitle2"} className={classes.toffeeId}>
                    {this.props.cardData?.toffee_id}
                </Typography>
                <Typography variant={"body2"} className={classes.policyHolderLabel + ' ' + classes.cardLabel} >
                    POLICY HOLDER NAME
                </Typography>
                <Typography variant={"subtitle2"} className={classes.policyHolder} >
                    {this.props.cardData?.name.toUpperCase()}
                </Typography>
            </Box>
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(PolicyCard);
