import React, {Component} from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {withStyles, Grid, Chip, TextField} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker,
} from "@material-ui/pickers";
import moment from "moment";

const useStyles = (theme) => ({
    dobBox: {
        cursor: "pointer",
    },
});

class MaterialDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.initialValue ?? null,
            openDatePicker: false,
        };
    }

    handleDateChange(date) {
        this.setState(
            {
                date: moment(date, "DD/MM/YYYY").format('DD/MM/yyyy'),
            },
            () => {
                console.log(moment(date, "DD/MM/YYYY").format('DD/MM/yyyy'))
                this.props.onChange(moment(date, "DD/MM/YYYY").format('DD/MM/yyyy'));
            }
        );
    }

    render() {
        const {classes} = this.props;
        return (
            <>
                <Box
                    className={classes.dobBox}
                    onClick={(e) => {
                        this.setState({
                            openDatePicker: true,
                        });
                    }}
                >
                    <Typography classes={classes.label}>{this.props.title}</Typography>
                    <Box pb={1} style={{"borderBottom": "1px solid rgba(0, 0, 0, 0.42)"}}>
                        <Typography variant={"h4"}>
                            {this.props.initialValue
                                ? moment(this.props.initialValue, "DD/MM/YYYY").format('DD/MM/yyyy')
                                : "DD/MM/YYYY"}
                        </Typography>
                    </Box>
                </Box>
                <Box hidden>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            label="DOB"
                            value={moment(this.props.initialValue, "DD/MM/YYYY").format('MM/DD/yyyy')}
                            open={this.state.openDatePicker}
                            format="dd/MM/yyyy"
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            onChange={(e) => this.handleDateChange(e)}
                            animateYearScrolling
                            onClose={(e) =>
                                this.setState({
                                    openDatePicker: false,
                                })
                            }
                        />
                    </MuiPickersUtilsProvider>
                </Box>
            </>
        )
            ;
    }
}

export default withStyles(useStyles, {withTheme: true})(MaterialDate);
