import React, {Component} from "react";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Divider, withStyles} from "@material-ui/core";
import {useStyles} from './listItemStyles'
import List from "@material-ui/core/List";
import {Images} from "../../constants/images";


class MaterialListItem extends Component {
    render() {
        const {classes, listItems} = this.props;
        const {listG, outerBox, listIndex, fontColor, listIcon, listTitle, list} = classes;
        return (
            <List className={list}>
                {listItems.map((item, index) => {
                    return (item.isRequired && <Box key={index}>
                            <ListItem className={listG}>
                                <Box
                                    display={"flex"}
                                    justifyContent={"center"}
                                    className={outerBox}
                                >
                                    <Box m={"auto"} className={listIndex}>
                                        <ListItemAvatar>
                                            <Avatar className="height-48 width-48 bg-white bg-shadow-2 mr-22">
                                                <Box m={0} p={0}>
                                                    <Typography variant={"body1"} align={"center"}
                                                                className={fontColor}>
                                                        <b>{item.index}</b>
                                                    </Typography>
                                                </Box>
                                            </Avatar>
                                        </ListItemAvatar>
                                    </Box>
                                    <Box m={"auto"} className={listIcon}>
                                        <ListItemAvatar>
                                            <img
                                                className="max-height-48 max-width-48 mr-16"
                                                src={item.img}
                                                alt=""
                                            />
                                        </ListItemAvatar>
                                    </Box>
                                    <Box m={"auto"} flexGrow={1}>
                                        <ListItemText
                                            primary={
                                                <Box m={0} p={0} pr={2}>
                                                    <Typography
                                                        variant={"body2"}
                                                        className={listTitle + ' ' + fontColor}
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    </Box>
                                    {item.isDone && (
                                        <Box m={"auto"}>
                                            <ListItemAvatar className="min-width-40">
                                                <Avatar
                                                    className="height-40 width-40"
                                                    src={Images.checkIcon}
                                                />
                                            </ListItemAvatar>
                                        </Box>
                                    )}
                                </Box>
                            </ListItem>
                            <Divider/>
                        </Box>
                    )
                })}
            </List>
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(MaterialListItem);
