import React, {Component} from "react";
import Box from "@material-ui/core/Box";
import {withStyles, Grid, Chip, TextField} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import {
    MuiPickersUtilsProvider,
    DatePicker,
} from "@material-ui/pickers";
// import InputAdornment from "@material-ui/core/InputAdornment";
import {InputAdornment} from '@material-ui/core';
import {ArrowDropDown} from "@material-ui/icons";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";

const useStyles = (theme) => ({
    activeChip: {
        backgroundColor: "rgba(255, 88, 93, .2)",
        borderRadius: "4px",
        color: "#ff585d",
        minWidth: "72px",
    },
    chip: {
        backgroundColor: "rgba(34, 51, 79, .1)",
        borderRadius: "4px",
        color: "#22334f",
        minWidth: "72px",
    },
    dobBox: {
        cursor: "pointer",
        width: "100%"
    },
});

class ChipDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSelected: this.props.isSelected,
            date: this.props.date ?? null,
            openDatePicker: false,
        };
    }

    chipClicked() {
        if (this.props.label.toLowerCase() !== 'self') {
            this.props.isSelected && this.setState({
                date: ''
            }, () => {
                this.props.onChange("date", '');
            })
            this.props.onChange("chip", !this.props.isSelected);
        }
    }

    handleDateChange(date) {
        this.setState(
            {
                date: moment(date, "DD/MM/YYYY").format('DD/MM/yyyy'),
            },
            () => {
                this.props.onChange("date", moment(date, "DD/MM/YYYY").format('DD/MM/yyyy'));
            }
        );
    }

    render() {
        const {classes} = this.props;
        return (
            <Box py={1}>
                <Box display={"flex"}>
                    <Box pt={2}>
                        <Chip
                            label={this.props.label}
                            onClick={(e) => {
                                this.chipClicked();
                            }}
                            className={
                                this.props.isSelected ? classes.activeChip : classes.chip
                            }
                        />
                    </Box>
                    <Box
                        hidden={!this.props.isSelected}
                        className={classes.dobBox}
                        onClick={(e) => {
                            this.setState({
                                openDatePicker: true,
                            });
                        }}
                        pl={2}
                    >
                        <form
                            className={classes.root}
                            noValidate
                            autoComplete="off"
                        >
                            {/*<InputLabel htmlFor="DOB" style={{fontSize: "12px", marginBottom: "0"}}>Date of*/}
                            {/*    Birth</InputLabel>*/}
                            <TextField
                                id={"DOB"}
                                label={"Date of Birth"}
                                fullWidth
                                type="text"
                                value={this.state.date
                                && moment(this.state.date, "DD/MM/YYYY").format('DD/MM/yyyy')
                                }
                                // : "DD/MM/YYYY"}

                            />
                        </form>
                    </Box>
                </Box>
                <Box hidden>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            label="DOB"
                            value={this.state.date ? moment(this.state.date, "DD/MM/YYYY").format('MM/DD/yyyy') : this.props.maxDate}
                            open={this.state.openDatePicker}
                            format="dd/MM/yyyy"
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            onChange={(e) => this.handleDateChange(e)}
                            animateYearScrolling
                            onClose={(e) =>
                                this.setState({
                                    openDatePicker: false,
                                })
                            }
                        />
                    </MuiPickersUtilsProvider>
                </Box>
            </Box>
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(ChipDate);
