import {Keys} from "./keys";
import {Strings} from "./strings";
import {QuestionType} from "../utilities/questionBuilder/questionTypes";
import {TextInputType} from "../utilities/questionBuilder/textInputType";
import {InputValidator} from "../utilities/questionBuilder/inputValidator";
import moment from "moment";

export const Personal = {

    data: {
        key: Keys.personalInfo,
        body: Strings.personalDetails,
        subtitle: Strings.pleaseFill,
        title: Strings.detailsInsuredAadhar,
        questions: [
            {
                type: QuestionType.choice,
                key: Keys.title,
                validation: [InputValidator.required],
                inputs: [
                    {value: Keys.mr, label: Strings.mr},
                    {value: Keys.ms, label: Strings.ms},
                ],
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.salutation,
                placeholder: Strings.chooseSalutation,
                validations: [InputValidator.required],
                initial: Keys.mr,
                inputs: [
                    {value: Keys.mr, label: Strings.mr},
                    {value: Keys.mrs, label: Strings.mrs},
                    {value: Keys.dr, label: Strings.dr},
                    {value: Keys.ms, label: Strings.ms},
                    {value: Keys.other, label: Strings.other},
                ],
                products: {
                    58: [true],
                }
            },
            {
                type: QuestionType.text,
                key: Keys.name,
                placeholder: Strings.fullName,
                validation: [InputValidator.required, InputValidator.name],
                textInputType: TextInputType.text,
                maxLength: 30,
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.date,
                key: Keys.dob,
                placeholder: Strings.birthday,
                validation: [InputValidator.required],
                maxDate: moment().subtract(18, "years"),
                minDate: moment()
                    .subtract(56, "years")
                    .add(1, "days"),
                readonly: true,
                products: {
                    48: [true],
                    52: [true],
                    58: [true],
                }
            },
            {
                type: QuestionType.text,
                placeholder: Strings.fatherName,
                key: Keys.fatherName,
                validation: [InputValidator.required, InputValidator.name],
                maxLength: 30,
                products: {
                    48: [true],
                    52: [true],
                }
            },
            {
                type: QuestionType.text,
                key: Keys.mobile,
                placeholder: Strings.mobileNumber,
                validation: [InputValidator.required, InputValidator.numeric],
                minLength: 10,
                maxLength: 10,
                textInputType: TextInputType.phone,
                products: {
                    48: [true],
                    52: [true],
                    58: [true],
                }
            },
            {
                type: QuestionType.text,
                placeholder: Strings.email1,
                key: Keys.email,
                validation: [InputValidator.required, InputValidator.email],
                textInputType: TextInputType.email,
                products: {
                    48: [true],
                    49: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.maritalStatus,
                placeholder: Strings.maritalStatus,
                validation: [InputValidator.required],
                inputs: [
                    {
                        value: Keys.single, label: Strings.single, products: {
                            48: [true],
                            52: [true],
                            54: [true],
                            58: [true],
                        },
                    },
                    {
                        value: Keys.married, label: Strings.married, products: {
                            48: [true],
                            52: [true],
                            54: [true],
                            58: [true],
                        },
                    },
                    {
                        value: Keys.divorced, label: Strings.divorced, products: {
                            48: [true],
                            52: [true],
                            54: [true],
                            58: [true],
                        },
                    },
                    {
                        value: Keys.widowed, label: Strings.widowed, products: {
                            48: [true],
                            52: [true],
                            54: [true],
                            58: [true],
                        },
                    },
                ],
                products: {
                    48: [true],
                    52: [true],
                    54: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.slider,
                validation: [InputValidator.required],
                key: Keys.feet,
                placeholder: Strings.heightFeet,
                min: 1,
                max: 8,
                step: 1,
                initial: 5,
                products: {
                    48: [true],
                    52: [true],
                    53: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.slider,
                validation: [InputValidator.required],
                key: Keys.inches,
                placeholder: Strings.heightInches,
                min: 0,
                max: 11,
                step: 1,
                initial: 6,
                products: {
                    48: [true],
                    52: [true],
                    53: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.slider,
                validation: [InputValidator.required],
                key: Keys.weight,
                placeholder: Strings.weightKilograms,
                min: 1,
                max: 150,
                step: 0.5,
                initial: 68,
                products: {
                    48: [true],
                    52: [true],
                    53: [true],
                    58: [true],
                },
            },
            {
                type: QuestionType.toggle,
                validation: [InputValidator.required],
                key: Keys.hasWeightChanged,
                placeholder: Strings.haveFiveKilograms,
                initial: false,
                inputs: [
                    {value: Keys.yes, label: Strings.yes},
                    {value: Keys.no, label: Strings.no},
                ],
                products: {
                    48: [true],
                    52: [true],
                },
            },
            {
                type: QuestionType.slider,
                key: Keys.weightChange,
                validation: [InputValidator.required],
                placeholder: Strings.totalWeightGain,
                min: -25,
                max: 25,
                step: 0.5,
                initial: 5,
                expand: {
                    [Keys.hasWeightChanged]: [true]
                },
                products: {
                    48: [true],
                    52: [true],
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.reasonWeightGain,
                key: Keys.weightChangeReason,
                validation: [InputValidator.required],
                expand: {
                    [Keys.hasWeightChanged]: [true]
                },
                products: {
                    48: [true],
                    52: [true],
                },
            },
        ],
    },
};
