import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import {useStyles} from '../../../styles/policyCardStyles/policyCardStyles'
import {Typography} from "@material-ui/core";
import moment from 'moment';

const PolicyCard = (props) => {
    const {classes, startDate, endDate, proposalNumber, name, email} = props;
    const history = useHistory();
    const [canBuy, setCanBuy] = useState(false);

    useEffect(() => {

    }, []);


    return (
        <>
            <Box className={classes.main}>
                <Box className={classes.card}>
                    <Box className={classes.cardData}>
                        <Box>
                            <Typography className={classes.title}>
                                {props.insuranceType ?? '---' }
                            </Typography>
                            <Typography className={classes.subTitle}>
                                {startDate !== null ? moment(startDate).format('MMM, YYYY') : "---"} - {endDate !== null ? moment(endDate).format('MMM, YYYY'): "---"}
                            </Typography>
                        </Box>
                        <Box>
                            <img
                                alt="Toffee Logo"
                                src={require("../../../assets/img/logo.svg")}
                                className="py-3"
                                style={{height: "60px"}}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.cardData + ' ' + classes.middleData}>
                        <Box>
                            <Typography className={classes.proposal}>
                               Proposal Number
                            </Typography>
                            <Typography className={classes.proposalNumber}>
                                {proposalNumber}
                            </Typography>
                        </Box>
                        <Box>

                        </Box>
                    </Box>
                    <Box className={classes.cardData}>
                        <Box>
                            <Typography className={classes.name}>
                                {name}
                            </Typography>
                            <Typography className={classes.email}>
                                {email}
                            </Typography>
                        </Box>
                        <Box>
                            {/*<img*/}
                            {/*    alt="Toffee Logo"*/}
                            {/*    src={require("../../../assets/img/logo.svg")}*/}
                            {/*    className="py-3"*/}
                            {/*    style={{height: "60px"}}*/}
                            {/*/>*/}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
};

export default withStyles(useStyles, {withTheme: true})(PolicyCard);
