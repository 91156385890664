export const QuestionType = {
  choice: "choice",
  filter: "filter",
  checkbox: "checkbox",
  text: "text",
  toggle: "toggle",
  date: "date",
  touchSpin: "touchSpin",
  slider: "slider",
  dropdown: "dropdown",
};
