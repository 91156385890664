import React, {Component} from "react";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {
    Card,
    CardContent,
    List,
    Box,
    Button,
} from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import {AttachFile, CameraAlt} from "@material-ui/icons";
import ResponsiveContainer from "../../../../components/ResponsiveContainer/responsiveContainer";
import {useStyles} from './styles/uploadListItemsStyles';


class MaterialUploadListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backUploaded: this.props.backUploaded,
            frontUploaded: this.props.frontUploaded,
            pdfUrl: require("../../../../assets/img/pdf.png")
        }
    }

    getDocUrl(docArray, id) {
        let doc = docArray.find((doc) => {
            return doc.id == id;
        });
        let url = doc.url;
        let splitUrl = url.split(".");
        let assetUrl = splitUrl.slice(-1)[0];
        return assetUrl === 'pdf' ? this.state.pdfUrl : doc.url;
    }

    render() {
        const {classes} = this.props;
        let doc = this.props.documents ? this.props.documents : []
        let imageExists = doc.length > 0
        return (
            <ResponsiveContainer
                flag={"buyFlow"}
                child={
                    <>
                        <Card className={this.props.address ? classes.card1 : classes.card}>
                            <CardContent className="p-0">
                                <List className="p-0">
                                    <ListItem>
                                        <ListItemAvatar className="min-width-24">
                                            <Avatar
                                                className="height-24 width-24 mr-8"
                                                src={
                                                    this.props.isDone
                                                        ? require("../../../../assets/img/green-tick.png")
                                                        : require("../../../../assets/img/light-blue-circle.png")
                                                }
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography variant="h5" className={classes.primaryTitle}>
                                                    {this.props.title}
                                                </Typography>
                                            }
                                        />
                                        <ListItemSecondaryAction className={classes.docType}>
                                            <Typography variant="subtitle2">{this.props.subTitle}</Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                                {this.props.incomeDropDown}
                                {this.props.bankDropDown}
                                <Box className={classes.hint}>
                                    <Typography variant="subtitle2" className={classes.hintText}>
                                        {this.props.hintText}
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.hintText2}>
                                        {this.props.hintText2}
                                    </Typography>
                                </Box>

                                <Box className={classes.secondaryPart}>
                                    {(imageExists) ?
                                        this.props.documents && this.props.documents.map((doc, index) => {
                                            return (
                                                <Box display={"flex"} justifyContent={"center"} key={index + "dshgcw"}>
                                                    <Box className={classes.viewOuterBox}>
                                                        <Box className={classes.viewBox}>
                                                            <img src={this.getDocUrl(this.props.docArray, doc.id)}
                                                                 width={"156px"}/>

                                                        </Box>

                                                        <Avatar
                                                            className={classes.deleteIcon}
                                                            onClick={(e) => {
                                                                this.props.deleteDocument(doc.id);
                                                                this.setState({})
                                                            }}
                                                        >
                                                            <Delete className={"txt-white"}/>
                                                        </Avatar>
                                                    </Box>
                                                </Box>
                                            );
                                        })
                                        :
                                        <Box display={"flex"} justifyContent={"center"}>
                                            <Box className={classes.viewOuterBox}>
                                                {this.props.aadhaar &&
                                                <img src={require('../../../../assets/img/Aadhaar_front.png')}
                                                    // height={"195px"}
                                                     width={"156px"}/>
                                                }
                                                {this.props.id === "DOCUMENT_USER_PHOTO" &&
                                                <img src={require('../../../../assets/img/Picture@4x.png')}
                                                    // height={"195px"}
                                                     width={"156px"}/>
                                                }
                                                {this.props.id === "DOCUMENT_PAN" &&
                                                <img src={require('../../../../assets/img/PAN_card.png')}
                                                    // height={"195px"}
                                                     width={"156px"}/>
                                                }
                                                {this.props.id === "DOCUMENT_ADDRESS_PROOF" &&
                                                <Box style={{height: "195px"}}>
                                                    <img src={require('../../../../assets/img/document_upload.svg')}
                                                         height={"165px"}
                                                         width={"156px"}/>
                                                </Box>
                                                }
                                                {this.props.id === "DOCUMENT_INCOME_PROOF" &&
                                                <Box style={{height: "195px"}}>
                                                    <img src={require('../../../../assets/img/document_upload.svg')}
                                                         height={"165px"}
                                                         width={"156px"}/>
                                                </Box>
                                                }
                                                {this.props.id === "DOCUMENT_BANK_DETAILS_PROOF" &&
                                                <Box style={{height: "195px"}}>
                                                    <img src={require('../../../../assets/img/document_upload.svg')}
                                                         height={"165px"}
                                                         width={"156px"}/>
                                                </Box>
                                                }


                                            </Box>
                                        </Box>

                                    }
                                    {(!this.props.frontUploaded &&
                                        !this.props.isUploading &&
                                        !this.props.otherUploading) && (
                                        <Box display={"flex"} justifyContent={"center"}>
                                            <Button
                                                variant="outlined"
                                                className={
                                                    classes.uploadBtn +
                                                    " " +
                                                    (this.props.aadhaar && classes.aadhaarBtn)
                                                }
                                                onClick={(e) => {
                                                    this.props.selectImage(this.props.id);
                                                }}
                                                startIcon={(this.props.title === "Photograph") ? <CameraAlt/> :
                                                    <AttachFile/>}
                                            >
                                                {this.props.addText}{this.props.aadhaar && " ADD AADHAR FRONT"}
                                            </Button>
                                        </Box>
                                    )}

                                    {this.props.aadhaar &&
                                    !this.props.backUploaded &&
                                    !this.props.isUploading &&
                                    !this.props.otherUploading && (<>
                                            <Box display={"flex"} justifyContent={"center"}>
                                                <Box className={classes.viewOuterBox}>
                                                    <img src={require('../../../../assets/img/Aadhaar_back.png')}
                                                        // height={"195px"}
                                                         width={"156px"}/>
                                                </Box>
                                            </Box>
                                            <Box display={"flex"} justifyContent={"center"}>
                                                <Button
                                                    variant="outlined"
                                                    // title="UPLOAD"
                                                    className={classes.uploadBtn + " " + classes.aadhaarBtn}
                                                    onClick={(e) => {
                                                        this.props.selectImage(this.props.id);
                                                    }}
                                                    startIcon={<AttachFile/>}
                                                >
                                                    ADD AADHAR BACK
                                                </Button>
                                            </Box>
                                        </>
                                    )}

                                    {this.props.numberOfDocs > 0 &&
                                    this.props.numberOfDocs !== 6 &&
                                    this.props.income &&
                                    !this.props.itemTwoUploaded &&
                                    !this.props.isUploading &&
                                    !this.props.otherUploading && (<>
                                            <Box display={"flex"} justifyContent={"center"}>
                                                <Box className={classes.viewOuterBox}>
                                                    <Box style={{height: "195px"}}>
                                                        <img src={require('../../../../assets/img/document_upload.svg')}
                                                             height={"165px"}
                                                             width={"156px"}/>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box display={"flex"} justifyContent={"center"}>
                                                <Button
                                                    variant="outlined"
                                                    // title="UPLOAD"
                                                    className={classes.uploadBtn + " " + classes.aadhaarBtn}
                                                    onClick={(e) => {
                                                        this.props.selectImage(this.props.id);
                                                    }}
                                                    startIcon={<AttachFile/>}
                                                >
                                                    {this.props.addText2}
                                                </Button>
                                            </Box>
                                        </>
                                    )}

                                    {this.props.numberOfDocs > 2 &&
                                    this.props.numberOfDocs !== 6 &&
                                    this.props.income &&
                                    !this.props.itemThreeUploaded &&
                                    !this.props.isUploading &&
                                    !this.props.otherUploading && (<>
                                            <Box display={"flex"} justifyContent={"center"}>
                                                <Box className={classes.viewOuterBox}>
                                                    <Box style={{height: "195px"}}>
                                                        <img src={require('../../../../assets/img/document_upload.svg')}
                                                             height={"165px"}
                                                             width={"156px"}/>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box display={"flex"} justifyContent={"center"}>
                                                <Button
                                                    variant="outlined"
                                                    // title="UPLOAD"
                                                    className={classes.uploadBtn + " " + classes.aadhaarBtn}
                                                    onClick={(e) => {
                                                        this.props.selectImage(this.props.id);
                                                    }}
                                                    startIcon={<AttachFile/>}
                                                >
                                                    {this.props.addText3}
                                                </Button>
                                            </Box>
                                        </>
                                    )}

                                </Box>

                                {this.props.isUploading && (
                                    <LinearProgress
                                        variant="determinate"
                                        value={this.props.progress}
                                        color={this.props.progress === "100" ? "primary" : "secondary"}
                                        className={classes.progressBar}
                                    />
                                )}
                            </CardContent>
                        </Card>
                        <Card className={classes.cardGrey}>
                            <CardContent className="p-0">
                                <Box p={0}>
                                    {this.props.address}
                                    {this.props.addressProof}
                                </Box>
                            </CardContent>
                        </Card>
                    </>
                }
            />
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(
    MaterialUploadListItem
);
