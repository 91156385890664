import {createStyles} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    root: {
        flexGrow: 1,
    },
    card: {
        maxWidth: "857px",
        margin: "auto",
        padding: "16px 0px",
        // borderRadius: "14px",
        marginBottom: "8px",
        // boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.11)",
        backgroundColor: '#dfebe9'
    },
    card1: {
        maxWidth: "857px",
        margin: "auto",
        padding: "16px 0px",
        // borderRadius: "14px",
        // borderBottomRightRadius: "0",
        // borderBottomLeftRadius: "0",
        marginBottom: "0px",
        backgroundColor: '#dfebe9',
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.11)",
    },
    cardGrey: {
        maxWidth: "857px",
        margin: "auto",
        padding: "0px",
        // borderRadius: "0",
        // borderBottomRightRadius: "14px",
        // borderBottomLeftRadius: "14px",
        marginBottom: "8px",
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.11)",
        backgroundColor: '#dfebe9'
    },
    progressBar: {
        width: "100%",
    },
    primaryTitle: {
        fontWeight: "500",
    },
    deleteIcon: {
        height: "30px",
        width: "30px",
        backgroundColor: "#ff585d",
        cursor: "pointer",
    },
    viewIcon: {
        height: "48px",
        width: "48px",
        backgroundColor: "#fff",
        cursor: "pointer",
    },
    docType: {
        maxWidth: "38%",
        textAlign: "end",
    },
    uploadBtn: {
        backgroundColor: "#ff585d",
        border: "none",
        color: "#fff",
        height: "40px",
        marginBottom: "8px",
        marginTop: "-35px",
        '&:hover': {
            backgroundColor: "#ce4246",
        }
    },
    secondaryPart: {
        padding: "8px 16px",
    },
    viewBox: {
        alignItems: "center",
        borderRadius: "4px",
        overflow: "hidden",
        margin: "4px 0",
        // height: "195px",
        width: "156px",
        objectFit: "contain"
    },
    rightBox: {
        display: "flex",
        justifyContent: "flex-end",
    },
    hint: {
        backgroundColor: "rgba(236,240,244,0.49)",
        padding: "10px 0",
    },
    hintText: {
        textAlign: "center"
    },
    hintText2: {
        marginTop: "5px",
        textAlign: "center",
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    viewOuterBox: {
        display: "flex",
        justifyContent: "space-between",
        // borderRadius: "12px",
        // height: "222px",
        width: "222px",
        backgroundColor: "#d3e2df",
        boxShadow: "0 1px 0 0 rgba(255, 255, 255, 0.55), inset 0 1px 3px 0 rgba(0, 0, 0, 0.06)",
        padding: "10px 5px 10px 30px",
        margin: "15px 0"
    },
    backAdhar: {
        marginTop: "30px"
    },
});