import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import {useStyles} from './styles/paymentPlanCardStyles';


class PaymentPlanCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCheckedMonthly: this.props.isMonthly,
            isCheckedYearly: !this.props.isMonthly,
            showMonthly :true,
            showYearly: true
        };
    }

    monthlyClick = () => {
        if (this.props.disabled)  return;
        this.props.monthClicked(
            this.setState({
                isCheckedMonthly: true,
                isCheckedYearly: false,
            })
        );
    };
    yearlyClick = () => {
        if (this.props.disabled) return;
        this.props.yearClicked(
            this.setState({
                isCheckedMonthly: false,
                isCheckedYearly: true,
            })
        );
    };

    getPercentage() {
        return this.getTwoFixedPoint(
            ((this.props.monthlyPremium * 12 - this.props.annualPremium) /
                this.props.annualPremium) *
            100
        );
    }

    getDifference() {
        return this.getTwoFixedPoint(this.props.monthlyPremium * 12 - this.props.annualPremium);
    }

    getTwoFixedPoint = (num) => {
        var x = num * 1;
        return x.toFixed(2);
    };
    componentDidMount() {

            if(this.props.disabled && this.props.isMonthly){
                this.setState({
                    showMonthly: true,
                    showYearly: false,
                })
                return;
            }
            if(this.props.disabled && !this.props.isMonthly) {
                this.setState({
                    showMonthly: false,
                    showYearly: true,
                })
            }

    }


    render() {
        console.log(this.props.annualPremium);
        console.log(this.props.amount)
        const {classes} = this.props;
        return (
            <Box display="flex" justifyContent="center" className={classes.content}>
                <Box elevation={3} className={classes.root}>
                    {this.state.showYearly && (
                    <Paper
                        elevation={3}
                        className={
                            this.state.isCheckedYearly
                                ? classes.paymentCardSelected
                                : classes.paymentCard
                        }
                        onClick={this.yearlyClick}
                    >
                        <Box className={classes.rdbtn}>
                            {this.state.isCheckedYearly ? (
                                <img
                                    src={require("../../../../assets/img/verification/radio-green-2x.png")}
                                    alt=""
                                    className={classes.paperImages}
                                />
                            ) : (
                                <img
                                    src={require("../../../../assets/img/verification/radio-black-2x.png")}
                                    alt=""
                                    className={classes.paperImages}
                                />
                            )}
                        </Box>
                        <Box className={classes.textBox}>
                            <Box pb={0} mt={0} mb={0}>
                                <Typography variant="body2">
                                    {this.props.disabled ? <b>Paid Annually</b> : <b>Pay Annually</b>}
                                </Typography>
                            </Box>
                            <Box className={classes.delText}>
                                <Typography variant="body2" className={classes.fontLight}>
                                    <del>₹ {this.getTwoFixedPoint(this.props.monthlyPremium * 12)}</del>
                                </Typography>
                            </Box>
                            <Box pb={0} mt={0} mb={0}>
                                <Typography variant="h5">
                                    <b>₹ {this.props.annualPremium}</b>
                                </Typography>
                            </Box>
                            <Box className={classes.saveText}>
                                <Typography variant="body2">
                                    {this.props.disabled ? "saved" : "Save"} {this.getPercentage()}% <b>₹ {this.getDifference()}</b>
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>)
                    }
                    {this.state.showMonthly && (
                    <Paper
                        elevation={3}
                        className={
                            this.state.isCheckedMonthly
                                ? classes.paymentCardSelected
                                : classes.paymentCard
                        }
                        onClick={this.monthlyClick}
                    >
                        <Box className={classes.rdbtn}>
                            {this.state.isCheckedMonthly ? (
                                <img
                                    src={require("../../../../assets/img/verification/radio-green.png")}
                                    alt=""
                                    className={classes.paperImages}
                                />
                            ) : (
                                <img
                                    src={require("../../../../assets/img/verification/radio-black.png")}
                                    alt=""
                                    className={classes.paperImages}
                                />
                            )}
                        </Box>
                        <Box className={classes.textBox}>
                            <Box pb={0} mt={0} mb={0}>
                                <Typography variant="body2">
                                    {this.props.disabled ? <b>Paid Monthly</b> : <b>Pay Monthly</b>}
                                </Typography>
                            </Box>
                            <Box className={classes.delText}>
                                <Typography variant="body2" className={classes.fontLight}>
                                    ₹ {this.getTwoFixedPoint(this.props.monthlyPremium * 12)}
                                </Typography>
                            </Box>
                            <Box pb={0} mt={0} mb={0}>
                                <Typography variant="h5">
                                    <b>₹ {this.props.monthlyPremium}</b>
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>)
                    }
                </Box>
            </Box>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
    };
};
export default withStyles(useStyles, {withTheme: true})(
    connect(null, mapDispatchToProps)(PaymentPlanCard)
);
