import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import NotificationAlert from "react-notification-alert";
import API from "../../utilities/api";
import { Card, CardBody, CardTitle, CardFooter } from "reactstrap";
class Select extends Component {
  state = {
    checked: false,
    selectedIds: [],
    typesArray: [],
    claimId: "",
  };
  constructor(props) {
    super(props);
    this.props.onGetClaimArray();
    this.props.onGetInitiatedClaimState();
  }
  notify = (place, title, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.claimState) {
      nextProps.claimState.claimTypes.length > 0 &&
        this.setState({ selectedIds: nextProps.claimState.claimTypes });
    }
  }
  checkClicked = (unchecked, claim) => {
    if (unchecked) {
      if (claim.has_full_cover) {
        this.setState({
          selectedIds: [claim.id],
        });
      } else {
        this.setState(
          {
            selectedIds: [...this.state.selectedIds, claim.id],
          },
          () => {
            this.props.claimTypesArray.forEach((claimType) => {
              claimType.has_full_cover &&
                this.setState({
                  selectedIds: this.state.selectedIds.filter(function (
                    value,
                    index,
                    arr
                  ) {
                    return value !== claimType.id;
                  }),
                });
            });
          }
        );
      }
    } else {
      this.setState({
        selectedIds: this.state.selectedIds.filter(function (
          value,
          index,
          arr
        ) {
          return value !== claim.id;
        }),
      });
    }
  };

  initiateClaim = () => {
    if (this.state.selectedIds.length < 1) {
      this.notify("tr", "", "Please select at least one option", "danger");
      return;
    }
    this.props.onCallShowLoader(true);

    const toff_id = window.location.href.split("/")[5];
    // console.log(toff_id);
    API.post("web/claim/initiate/" + toff_id, {
      claim_type_id: this.state.selectedIds,
    })
      .then((response) => {
        this.props.onCallShowLoader(false);

        // console.log("inputs: ", response);
        if (response.data.data) {
          this.props.onUpdateInputsDocs({
            inputs: response.data.data.inputs,
            documents: response.data.data.documents,
            claimId: response.data.data.claimId,
          });
        }
        this.props.switchComponent("inputs");
      })
      .catch((err) => {
        this.props.onCallShowLoader(false);
        // console.log(err);
      });
  };
  render() {
    // console.log(this.props.claimState);

    return (
      <div className="component-container position-relative m-auto">
        {!this.props.emptyArrayMessage && (
          <div className="p-4">
            <h2 className=" font-weight-light txt-secondary">
              This is your first claim
            </h2>
            <h5 className=" font-weight-light txt-secondary">
              Below is the list of Toffee Coverage details
            </h5>
          </div>
        )}

        <div className="p-4">
          {!this.props.emptyArrayMessage && (
            <h2 className=" font-weight-light txt-secondary">
              What do you want to claim for?
            </h2>
          )}
          {this.props.emptyArrayMessage && (
            <h5
              className=" font-weight-light txt-secondary"
              dangerouslySetInnerHTML={{ __html: this.props.emptyArrayMessage }}
            ></h5>
          )}
          {this.props.claimTypesArray &&
            !this.props.emptyArrayMessage &&
            this.props.claimTypesArray.map((claimType, index) => {
              return (
                <Card
                  key={claimType.id}
                  className=" my-3 border-0 bg-shadow-1 bg-white rounded-0"
                >
                  <CardBody className=" p-3">
                    <CardTitle className="" tag="h3">
                      <h1 className="font-weight-bold opacity-80  txt-positive d-inline">
                        {" "}
                        {claimType.currency}
                        {claimType.value}
                      </h1>
                      {this.state.selectedIds.includes(claimType.id) ? (
                        <span
                          className="checkbox-span bg-progress justify-content-center align-items-center  d-flex opacity-80 bg-shadow-2 float-right"
                          onClick={(e) => {
                            e.preventDefault();
                            this.checkClicked(false, claimType);
                          }}
                        >
                          <img
                            className="tick-icon"
                            src={require("../../assets/img/icons/white-tick.png")}
                            alt=""
                          />
                        </span>
                      ) : (
                        <span
                          className="checkbox-span-unchecked justify-content-center align-items-center opacity-30 d-flex   float-right"
                          onClick={(e) => {
                            e.preventDefault();
                            this.checkClicked(true, claimType);
                          }}
                        ></span>
                      )}
                      <h3 className="txt-secondary mt-3 h3-regular">
                        {claimType.title}
                      </h3>
                      {claimType.message &&
                        claimType.message.map((msg) => {
                          return (
                            <p className="span-12 txt-secondary m-0">{msg}</p>
                          );
                        })}
                    </CardTitle>
                  </CardBody>
                  <CardFooter
                    hidden={!this.state.selectedIds.includes(claimType.id)}
                    className=" bg-lightred p-3 border-0"
                  >
                    <h5 className="font-weight-bold m-0 txt-primary">
                      Required documents for {claimType.title}
                    </h5>
                    {claimType.documents &&
                      claimType.documents.map((doc) => {
                        return (
                          <h5 className="my-1  opacity-80  txt-primary">
                            - {doc.title}
                          </h5>
                        );
                      })}
                  </CardFooter>
                </Card>
              );
            })}
        </div>

        {this.props.claimTypesArray && !this.props.emptyArrayMessage && (
          <div
            className="btn-primary cursor-pointer bg-shadow-3 px-4  d-flex justify-content-between align-items-center mobile-position-fixed footer-button"
            onClick={(e) => {
              e.preventDefault();
              this.initiateClaim();
            }}
          >
            <h2 className="font-weight-normal txt-white">Initiate Claim</h2>
            <span className="arrow-span">
              <img
                className="arrow-icon"
                src={require("../../assets/img/icons/white-arrow-right.png")}
                alt=""
              />
            </span>
          </div>
        )}

        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    claimTypesLoading: state.claim.claimTypesLoading,

    claimTypesArray: state.claim.claimTypesArray,
    claimRequiredInputs: state.claim.claimRequiredInputs,
    claimRequiredDocuments: state.claim.claimRequiredDocuments,
    claimId: state.claim.claimId,
    claimState: state.claim.claimState,
    emptyArrayMessage: state.claim.emptyClaimTypesMessage,

    error: state.claim.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetClaimArray: () => dispatch(actions.getClaimTypesArray()),
    onGetInitiatedClaimState: () => dispatch(actions.getInitiatedClaims()),
    onUpdateInputsDocs: (data) => dispatch(actions.updateInputsDocs(data)),
    onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Select);
