export const ReferenceMapping = {
    'bharti-mobile-adld': 'bhartiMobileAdld',
    'bharti-mobile-otsr': 'bhartiMobileOtsr'
};
 
export const ProductDetails = {
    bhartiMobileAdld: {
        slug: 'bharti-mobile-adld',
        provider: 'Bharti',
        productId: 36,
        payloads: ['order_id', 'channel', 'title', 'name', 'email', 'mobile', 'dob',
            'nominee_relation_id', 'nominee_name', 'nominee_mobile', 'pincode', 'state', 'city',
            'address_line_1', 'address_line_2', 'premium', 'file_screen_photo', 'file_invoice_photo',
            'response_landmark', 'response_nominee_dob', 'response_phone_brand', 'response_phone_model', 
            'response_phone_color', 'response_phone_storage', 'response_phone_ram', 'response_manufactured_date', 
            'response_serial_number', 'response_imei_number', 'response_imei_number_2',
            'response_invoice_amount', 'response_invoice_number', 'response_invoice_date',
            'response_cross_sell_order_id', 'response_product', 'response_tag', 'response_discount'
        ],
        insuranceType: 'Mobile/Tablet Comprehensive Damage',
        response_discount: {
            is_flat: false,
            value: 50
        }
    },
    bhartiMobileOtsr: {
        slug: 'bharti-mobile-otsr',
        provider: 'Bharti',
        productId: 41,
        payloads: ['order_id', 'channel', 'title', 'name', 'email', 'mobile', 'dob',
            'nominee_relation_id', 'nominee_name', 'nominee_mobile', 'pincode', 'state', 'city',
            'address_line_1', 'address_line_2', 'premium', 'file_screen_photo', 'file_invoice_photo',
            'response_landmark', 'response_nominee_dob', 'response_phone_brand', 'response_phone_model', 
            'response_phone_color', 'response_phone_storage', 'response_phone_ram', 'response_manufactured_date', 
            'response_serial_number', 'response_imei_number', 'response_imei_number_2',
            'response_invoice_amount', 'response_invoice_number', 'response_invoice_date',
            'response_cross_sell_order_id', 'response_product', 'response_tag'
        ],
        insuranceType: 'Mobile Screen Protect'
    }
};

export const buildQuotationPayload = (productReferenceKey, values = null, ignoreKeys=[]) => {
    let payload = {};

    ProductDetails[productReferenceKey].payloads.map(item => {
        if (ignoreKeys.indexOf(item) === -1) {
            if (item === 'response_discount') {
                if (ProductDetails[productReferenceKey].response_discount) {
                    payload['response_discount'] = {
                        is_flat: ProductDetails[productReferenceKey].response_discount.is_flat,
                        value: ProductDetails[productReferenceKey].response_discount.value
                    };
                }
            } else {
                payload[item] = values[item] || null;
            }
        }
    });

    return payload;
};
