import {createStyles} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    title: {
        color: "#22334f",
        [theme.breakpoints.down("sm")]: {
            fontSize: "18px",
            fontWeight: "500",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "18px",
            fontWeight: "500",
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "30px",
            fontWeight: "bold",
            marginBottom: "10px",
            letterSpacing: "1.5px",
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "30px",
            fontWeight: "bold",
            marginBottom: "10px",
            letterSpacing: "1.5px",
        },
    },
    boxBg: {
        backgroundColor: "#f9f9f9",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            borderTopLeftRadius: "40px",
        },
        [theme.breakpoints.up("sm")]: {
            borderTopLeftRadius: "40px",
        },
        [theme.breakpoints.up("md")]: {
            borderTopLeftRadius: "80px",
        },
        [theme.breakpoints.up("lg")]: {
            borderTopLeftRadius: "100px",
        },
    },
    headTitle: {
        [theme.breakpoints.down("sm")]: {
            marginTop: "0px",
            marginBottom: "0px",
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "0px",
            marginBottom: "0px",
        },
        [theme.breakpoints.up("md")]: {
            marginTop: "30px",
            marginBottom: "40px",
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: "30px",
            marginBottom: "40px",
        },
    },
    topRadiusBackground: {
        backgroundColor: "#ff585d"
    },
    addressBox: {
        backgroundColor: "#f8f9fb",
        padding: "10px 20px 20px",
        width: "326px"

    },
    submitBtn: {
        maxWidth: "700px",
        margin: "auto",
    }
});