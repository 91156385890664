import React, { Component, Fragment } from "react";
import MainHeader from "../../../components/Headers/MainHeader";
import Select from "../../../components/Claim/Select";
// import DateTime from '../../../components/Claim/Inputs/DateTime';
// import Location from '../../../components/Claim/Inputs/Location';
// import FileUpload from '../../../components/Claim/Documents/FileUpload';
// import AddPhoto from '../../../components/Claim/Documents/AddPhoto';
import Success from "../../../components/Claim/Success";
import CustomLoader from "../../../components/Common/CustomLoader";
// import TextArea from '../../../components/Claim/Inputs/TextArea';
// import MultiSelect from '../../../components/Claim/Inputs/MultiSelect';
// import InputField from '../../../components/Claim/Inputs/InputField';
import Cookies from "js-cookie";

import Inputs from "../../../components/Claim/Inputs";
import Documents from "../../../components/Claim/Documents";

import { connect } from "react-redux";

class Initiate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentComponent: "select",
      // currentComponent: "inputField"
      successData: null,
    };
  }
  updateState = (data) => {
    this.setState(data);
  };
  moveToDirection = (component) => {
    this.setState({
      currentComponent: component,
    });
  };
  checkForToken() {
    if (Cookies.get("react_token")) {
      localStorage.setItem("token", Cookies.get("react_token"));
    } else {
      !localStorage.getItem("token") &&
        (window.location.href =
          window.location.origin +
          "/login?redirect_url=" +
          window.location.href);
    }
    // !token && (window.location.href = window.location.origin + "/login");
  }
  render() {
    this.checkForToken();
    return (
      <Fragment>
        <MainHeader
          currentComponent={this.state.currentComponent}
          setState={(data) => {
            this.updateState(data);
          }}
        ></MainHeader>
        <div className="pt-5 pb-5">
          {this.state.currentComponent === "select" && (
            <Select
              switchComponent={(data) => this.moveToDirection(data)}
            ></Select>
          )}
          {this.state.currentComponent === "inputs" && (
            <Inputs
              requiredInputs={this.props.claimRequiredInputs}
              claimId={this.props.claimId}
              setState={(data) => this.updateState(data)}
              switchComponent={(data) => this.moveToDirection(data)}
            ></Inputs>
          )}
          {this.state.currentComponent === "documents" && (
            <Documents
              requiredInputs={this.props.claimRequiredInputs}
              requiredDocuments={this.props.claimRequiredDocuments}
              claimId={this.props.claimId}
              setState={(data) => this.updateState(data)}
              switchComponent={(data) => this.moveToDirection(data)}
            ></Documents>
          )}
          {this.state.currentComponent === "success" && (
            <Success
              data={this.state.successData}
              switchComponent={(data) => this.moveToDirection(data)}
            ></Success>
          )}
        </div>
        <CustomLoader active={this.props.loading}></CustomLoader>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // claimTypesLoading: state.claim.claimTypesLoading,

    // claimTypesArray: state.claim.claimTypesArray,
    claimRequiredInputs: state.claim.claimRequiredInputs,
    claimRequiredDocuments: state.claim.claimRequiredDocuments,
    claimId: state.claim.claimId,
    loading: state.claim.loading,

    error: state.claim.error,
  };
};
// const mapDispatchToProps = dispatch => {
//     return {
//         onGetClaimArray: () => dispatch(actions.getClaimTypesArray()),
//         onUpdateInputsDocs: (data) => dispatch(actions.updateInputsDocs(data))
//     };
// };
export default connect(mapStateToProps, null)(Initiate);
