export const ReducerUtils = {
  existingQuotation: {
    details: 'EXISTING_QUOTATION_DETAILS',
  },
  quotation: {
    details: 'QUOTATION_DETAILS'
  },
  razorpay: {
    initialization: 'RAZORPAY_INITIALIZATION'
  },
  payment: {
    details: 'PAYMENT_DETAILS'
  }
};