import moment from 'moment';

export const isProceedButtonDisabled = ({ email, mobile, name, hasError }) => {
    return (!email || !mobile || !name || hasError);
};

export const buildLoginPayload = ({ mobile }) => {
    return {
        mobile: mobile,
        otp_length: 6,
        type: "user",
    };
};

export const buildResendOtpPayload = ({ email, mobile, name }) => {
    const payload = buildLoginPayload({ mobile });

    return {
        ...payload,
        name,
        email
    };
};

export const buildSubmitOtpPayload = ({ mobile, otp }) => {
    return {
        mobile,
        otp
    };
};

export const buildInitiateRazorpay = (entity, entity_id, is_monthly = false) => {
    return { 
        entity, 
        entity_id, 
        is_monthly 
    };
};

export const parseMessage = message => {
    if (typeof message === "string") {
        return message;
    } else if (Array.isArray(message) && message.length) {
        let errors = [];
    
        const output = message.map(item => [...errors, item.msg] );
    
        return output.join(', ');
    } else {
        let errors = [];
        
        for (const [key, value] of Object.entries(message)) {
            errors.push(value);
        }
        
        return errors;
    }
};

export const getQueryStringParameterValue = (keyName) => {
	return (new URLSearchParams(window.location.search)).get(keyName);
};

export const getInvoiceDaysDifference = (invoiceDate) => {
    const array = invoiceDate.split('/');

    return moment().diff(moment(`${array[2]}-${array[1]}-${array[0]}`), 'days');
};

export const buildResumeAuthPayload = ({ id, token }) => {
    return {
        id,
        token
    };
};
