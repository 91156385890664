import React, {Component} from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Grid, withStyles} from "@material-ui/core";

const useStyles = (theme) => ({
    monthBox1: {
        height: "40px"
    },
    space: {
        margin: theme.spacing(3, 0, 0, 0),
        borderLeft: '2px solid #ff585d',
    },
    monthBox: {
        display: "flex",
        flexDirection: 'column'
    },
    date: {
        cursor: 'pointer',
        width: '70px',
        height: '70px',
        border: '1px solid #ccc',
        borderRadius: '0px',
        display: 'flex',
        margin: theme.spacing(0, 2, 0, 0)
    },
    dateSelected: {
        cursor: 'pointer',
        width: '70px',
        height: '70px',
        border: '1px solid #dfebe9',
        borderRadius: '0px',
        display: 'flex',
        margin: theme.spacing(0, 2, 0, 0),
        backgroundColor: theme.palette.primary.main
    },
    dateInner: {
        margin: 'auto'
    },
    redText: {
        color: theme.palette.secondary.main
    },
    marginAuto: {
        padding: theme.spacing(3, 0, 0, 0),
        [theme.breakpoints.down("sm")]: {
            margin: 'auto'
        },
        [theme.breakpoints.up("sm")]: {
            margin: '0'
        },
        [theme.breakpoints.up("md")]: {},
    }
});

class ChipSelectDateTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: this.props.selectedItem ?? "",
            options: this.props.options ?? [],
        };
    }

    selectItem(date) {
        this.setState(
            {
                selectedItem: date,
            },
            () => {
                this.props.onChange(date);
            }
        );
    }

    render() {
        const {classes} = this.props;
        return (
            <Box py={2} display={'flex'} flexWrap={'wrap'}>
                {this.state.options.map((item, index) => {
                    let month = [];
                    month.push({'title': item.title, 'day': item.day, 'date': item.date});
                    return (
                        <Box px={1} className={(item.isSpaceRequired) ? classes.space : classes.marginAuto}>
                            <Typography>{this.props.title}</Typography>
                            <Box mb={1} className={classes.monthBox1}>
                                {(item.month && (index === 0 || this.state.options[index].month !== this.state.options[index - 1].month)) &&
                                item.month
                                }
                            </Box>
                            <Grid container spacing={1}>
                                {month.map((item1) => {
                                    return (
                                        <Grid item>
                                            <Box
                                                className={this.state.selectedItem === item1.date ? classes.dateSelected : classes.date}
                                                onClick={(e) => {
                                                    this.selectItem(item1.date);
                                                }}>
                                                <Box className={classes.dateInner}>
                                                    <Typography
                                                        className={""}>
                                                        <b>{item1.title}</b>
                                                    </Typography>
                                                    <Typography
                                                        className={""}>
                                                        <b> {item1.day}</b>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    );
                })}
            </Box>
        );

    }
}

export default withStyles(useStyles, {withTheme: true})(ChipSelectDateTime);
