import React from 'react'
import { Spinner } from 'reactstrap'

const CustomLoader = (props) => (
    props.active ?
        <div className="h-100 w-100 position-fixed top-0 left-0 right-0 bottom-0" style={{ backgroundColor: "#a9a1a14f" }}>
            <div className="d-inline position-absolute" style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)"
            }}>
                <Spinner className="txt-primary" />
            </div>
        </div> : ""
)

export default CustomLoader