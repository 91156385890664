import {createStyles} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: theme.spacing(5,0),
        maxWidth: '1000px',
        margin:'auto',
    },
    cardBox:{

    },
    contentTextTitle:{
        textAlign: "center",
        fontSize: '20px',
        fontWeight: "500",
        // color: "#ffffff",
    },
    contentText:{
        textAlign: "center",
        fontSize: '16px',
        fontWeight: "normal",
        // color: "#ffffff",
    },
    terms:{
        textAlign: "center",
        fontSize: '14px',
        fontWeight: "normal",
        // color: "#ffffff",
    },
    spinner:{
        textAlign: "center",
        padding: theme.spacing(5,0),
        color: theme.palette.primary.main
    },
    content:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: theme.spacing(0,4),
    }

});