// CLAIMS ACTION TYPES
export const GET_CLAIM_TYPES_START = "GET_CLAIM_TYPES_START";
export const GET_CLAIM_INPUTS_START = "GET_CLAIM_ADD_INPUTS_START";
export const GET_INITIATED_CLAIM_START = "GET_INITIATED_CLAIM_START";

export const GET_CLAIM_TYPES_SUCCESS = "GET_CLAIM_TYPES_SUCCESS";
export const GET_CLAIM_INPUTS_SUCCESS = "GET_CLAIM_ADD_INPUTS_SUCCESS";
export const GET_INITIATED_CLAIM_SUCCESS = "GET_INITIATED_CLAIM_SUCCESS";

export const GET_CLAIM_TYPES_FAIL = "GET_CLAIM_TYPES_FAIL";
export const GET_CLAIM_INPUTS_FAIL = "GET_CLAIM_ADD_INPUTS_FAIL";
export const GET_INITIATED_CLAIM_FAIL = "GET_INITIATED_CLAIM_FAIL";
// AUTH ACTION TYPES
export const POST_SENDOTP_TYPES_START = "POST_LOGIN_TYPES_START";
export const POST_VERIFYOTP_TYPES_START = "POST_VERIFYOTP_TYPES_START";

export const POST_SENDOTP_TYPES_SUCCESS = "POST_LOGIN_TYPES_SUCCESS";
export const POST_VERIFYOTP_TYPES_SUCCESS = "POST_VERIFYOTP_TYPES_SUCCESS";
export const CHECK_USER_LOGGED_IN_SUCCESS = "CHECK_USER_LOGGED_IN_SUCCESS";

export const POST_SENDOTP_TYPES_FAIL = "POST_LOGIN_TYPES_FAIL";
export const POST_VERIFYOTP_TYPES_FAIL = "POST_VERIFYOTP_TYPES_FAIL";

// CUSTOMER ACTION TYPES
export const POST_RETRIEVEQUOTATION_TYPES_START =
    "POST_RETRIEVEQUOTATION_TYPES_START";
export const GET_USERDETAILS_TYPES_START = "GET_USERDETAILS_TYPES_START";
export const GET_POLICY_TRANSACTIONS_START = "GET_POLICY_TRANSACTIONS_START";

export const POST_RETRIEVEQUOTATION_TYPES_SUCCESS =
    "POST_RETRIEVEQUOTATION_TYPES_SUCCESS";
export const GET_USERDETAILS_TYPES_SUCCESS = "GET_USERDETAILS_TYPES_SUCCESS";
export const GET_POLICY_TRANSACTIONS_SUCCESS = "GET_POLICY_TRANSACTIONS_SUCCESS";

export const POST_RETRIEVEQUOTATION_TYPES_FAIL =
    "POST_RETRIEVEQUOTATION_TYPES_FAIL";
export const GET_USERDETAILS_TYPES_FAIL = "GET_USERDETAILS_TYPES_FAIL";
export const GET_POLICY_TRANSACTIONS_FAIL = "GET_POLICY_TRANSACTIONS_FAIL";

export const UPDATE_CALCULATE_PREMIUM_DATA = 'UPDATE_CALCULATE_PREMIUM_DATA';
export const UPDATE_BUY_FORM_DATA = 'UPDATE_BUY_FORM_DATA';
export const SET_BUY_FORM_ERROR = 'SET_BUY_FORM_ERROR';
export const SHOW_LOADER = "SHOW_LOADER";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const ENABLE_UPI_LINK = 'ENABLE_UPI_LINK';
export const GET_UPDATED_PREMIUM = 'GET_UPDATED_PREMIUM';

export const PAYMENT_TYPE_MONTHLY = 'PAYMENT_TYPE_MONTHLY';

