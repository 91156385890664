import {Strings} from "./strings";
import {QuestionType} from "../utilities/questionBuilder/questionTypes";
import {Keys} from "./keys";
import {InputValidator} from "../utilities/questionBuilder/inputValidator";
import {Products} from "./products";

export const Health = {
    data: {
        key: Keys.healthDeclarationInfo,
        body: Strings.healthDeclaration,
        // subtitle: Strings.andFinally,
        // icon: Icons.accessibility_new,
        title: Strings.andFinally,
        questions: [
            {
                type: QuestionType.toggle,
                validation: [InputValidator.required],
                key: Keys.ped1,
                placeholder: Strings.ped1Placeholder,
                inputs: [{value: Keys.title, label: Strings.ped1Placeholder}],
                initial: false,
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.indiaFirstRelation,
                placeholder: Strings.relation,
                inputs: [
                    {value: Keys.father, label: Strings.father},
                    {value: Keys.mother, label: Strings.mother},
                    {value: Keys.sister, label: Strings.sister},
                    {value: Keys.brother, label: Strings.brother},
                    {value: Keys.son, label: Strings.son},
                    {value: Keys.daughter, label: Strings.daughter},
                    {value: Keys.husband, label: Strings.husband},
                    {value: Keys.self, label: Strings.self},
                ],
                expand: {
                    [Keys.ped1]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.text,
                key: Keys.ageTimeOfDemise,
                placeholder: Strings.ageTimeOfDemise,
                validations: [
                    InputValidator.numeric,
                ],
                minValue: 1,
                maxValue: 999,
                expand: {
                    [Keys.ped1]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.text,
                key: Keys.causeOfDeath,
                placeholder: Strings.causeOfDeath,
                validations: [],
                maxLength: 30,
                expand: {
                    [Keys.ped1]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.text,
                key: Keys.ageIfAlive,
                placeholder: Strings.ageIfAlive,
                validations: [
                    InputValidator.numeric,
                ],
                maxLength: 2,
                expand: {
                    [Keys.ped1]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.text,
                key: Keys.specifiedIllness,
                placeholder: Strings.specifiedIllness,
                validations: [],
                maxLength: 30,
                expand: {
                    [Keys.ped1]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.toggle,
                placeholder: Strings.diseasePlaceholder,
                key: Keys.disease,
                validation: [InputValidator.required],
                hasSubQuestions: true,
                initial: false,
                products: {
                    52: [true],
                    53: [true]
                },
            },
            {
                type: QuestionType.checkbox,
                key: Keys.diseaseAs,
                validation: [InputValidator.required],
                placeholder: Strings.empty,
                expand: {
                    [Keys.disease]: [true]
                },
                inputs: [
                    {value: Keys.hypertension, label: Strings.hypertensionPlaceholder},
                    {value: Keys.chest, label: Strings.chestPlaceholder},
                    {value: Keys.diabetes, label: Strings.diabetesPlaceholder},
                    {value: Keys.asthma, label: Strings.asthmaPlaceholder},
                    {value: Keys.nervous, label: Strings.nervousPlaceholder},
                    {value: Keys.anyGastro, label: Strings.anyGastroPlaceholder},
                    {value: Keys.liver, label: Strings.liverPlaceholder},
                    {
                        value: Keys.genitourinary,
                        label: Strings.genitourinaryPlaceholder,
                    },
                    {value: Keys.cancer, label: Strings.cancerPlaceholder},
                    {value: Keys.hiv, label: Strings.hivPlaceholder},
                    {value: Keys.anyBlood, label: Strings.anyBloodPlaceholder},
                    {value: Keys.psychiatric, label: Strings.psychiatricPlaceholder},
                    {
                        value: Keys.anyGynecological,
                        label: Strings.anyGynecologicalPlaceholder,
                    },
                    {
                        value: Keys.anyCongenital,
                        label: Strings.anyCongenitalPlaceholder,
                    },
                    {value: Keys.anyAilment, label: Strings.anyAilmentPlaceholder},
                    {value: Keys.pregnant, label: Strings.pregnantPlaceholder},
                    {value: Keys.autoImmune, label: Strings.autoImmunePlaceholder},
                    {value: Keys.anyDisorder, label: Strings.anyDisorderPlaceholder},
                    {value: Keys.indiaFirstHealth1, label: Strings.hepatitisBorC},
                    {
                        value: Keys.indiaFirstHealth2,
                        label: Strings.diabetesHighBloodPressure,
                    },
                    {
                        value: Keys.indiaFirstHealth3,
                        label: Strings.currentlyTakingMedication,
                    },
                    {
                        value: Keys.indiaFirstHealth4,
                        label: Strings.congenitalBirthDefect,
                    },
                    {value: Keys.indiaFirstHealth5, label: Strings.advisedHadSurgery},
                    {value: Keys.indiaFirstHealth6, label: Strings.tumorGrowth},
                    {value: Keys.indiaFirstHealth7, label: Strings.drugsNarcotics},
                    {value: Keys.indiaFirstHealth8, label: Strings.lastThreeYears},
                    {value: Keys.indiaFirstHealth9, label: Strings.illnessImpairment},
                ],
                products: {
                    52: [true],
                    53: [true]
                },
            },
            {
                type: QuestionType.toggle,
                placeholder: Strings.disease2Placeholder,
                key: Keys.disease2,
                validation: [InputValidator.required],
                hasSubQuestions: true,
                initial: false,
                products: {
                    52: [true],
                    53: [true]
                },
            },
            {
                type: QuestionType.checkbox,
                key: Keys.disease2As,
                validation: [InputValidator.required],
                placeholder: Strings.empty,
                expand: {
                    [Keys.disease2]: [true]
                },
                inputs: [
                    {
                        value: Keys.surgeryMed,
                        label: Strings.surgeryMedPlaceholder,
                    },
                    {
                        value: Keys.illnessInjury,
                        label: Strings.illnessInjuryPlaceholder,
                    },
                    {value: Keys.prevInsurer, label: Strings.prevInsurerPlaceholder},
                    {value: Keys.highPremium, label: Strings.highPremiumPlaceholder},
                    {
                        value: Keys.anyTermination,
                        label: Strings.anyTerminationPlaceholder,
                    },
                ],
                products: {
                    52: [true],
                    53: [true]
                },
            },
            {
                type: QuestionType.toggle,
                placeholder: Strings.coronaExposure,
                key: Keys.disease3,
                validation: [InputValidator.required],
                hasSubQuestions: true,
                initial: false,
                products: {
                    52: [true]
                }
            },
            {
                type: QuestionType.checkbox,
                key: Keys.disease3As,
                validation: [InputValidator.required],
                placeholder: Strings.empty,
                expand: {
                    [Keys.disease3]: [true]
                },
                inputs: [
                    {value: Keys.closeContact, label: Strings.closeContactCovid},
                    {value: Keys.quarantined, label: Strings.quarantined},
                    {value: Keys.doneDiagnosis, label: Strings.doneDiagnosis},
                    {value: Keys.testedPositive, label: Strings.testedPositive},
                    {
                        value: Keys.experiencedSymptoms,
                        label: Strings.experiencedSymptoms,
                    },
                    {value: Keys.travelHistory, label: Strings.travelHistory},
                    {
                        value: Keys.currentlyNotHealthy,
                        label: Strings.currentlyNotHealthy,
                    },
                    {
                        value: Keys.historyOfConviction,
                        label: Strings.historyOfConviction,
                    },
                    {value: Keys.indiaFirstHealth9, label: Strings.illnessImpairment},
                ],
                products: {
                    52: [true]
                },
            },
        ],
    },
};
