import {createStyles} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: theme.spacing(5, 0),
        maxWidth: '1000px',
        margin: 'auto',
    },
    card: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        flexDirection: "column",
        padding: theme.spacing(2, 0),
        backgroundColor: theme.palette.primary.main,
        borderRadius: '10px',
        boxShadow: '1px 4px 12px 3px #d4d0d0',
        [theme.breakpoints.down('sm')]: {
            width: '360px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '400px'
        },
        [theme.breakpoints.up('md')]: {
            width: '400px'
        },
    },
    cardData: {
        display: "flex",
        justifyContent: "space-between",
        // alignItems: "center",
        width: '100%',
        padding: theme.spacing(1, 2),
    },
    middleData:{
        backgroundColor: "#ffffff5c",
        padding: theme.spacing(1, 0),
    },
    title: {
        fontSize: '20px',
        fontWeight: "bold",
        color: "#ffffff"
    },
    subTitle: {
        fontSize: '16px',
        fontWeight: "normal",
        color: "#ffffff"
    },
    proposal: {
        fontSize: '18px',
        fontWeight: "normal",
        color: "#ffffff",
        // opacity: 0.7,
        padding: theme.spacing(0, 2),
    },
    proposalNumber: {
        fontSize: '18px',
        fontWeight: "bold",
        color: "#ffffff",
        // opacity: 0.7,
        padding: theme.spacing(0, 2),
    },
    name: {
        fontSize: '20px',
        fontWeight: "300",
        color: "#ffffff"
    },
    email: {
        fontSize: '14px',
        fontWeight: "300",
        color: "#ffffff"
    }

});