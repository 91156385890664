import React, { Component } from 'react';
export default class MainHeader extends Component {
    onBackClicked = () => {
        switch (this.props.currentComponent) {
            case 'select':
                window.location.href = `${process.env.REACT_APP_HTTP_SCHEME}://${process.env.REACT_APP_API_URL}/claims/toffees`;
                break;

            case 'inputs':
                this.props.setState({
                    currentComponent: 'select'
                })
                break;
            case 'documents':
                this.props.setState({
                    currentComponent: 'inputs'
                })
                break;
            case 'success':
                window.location.href = `${process.env.REACT_APP_HTTP_SCHEME}://${process.env.REACT_APP_API_URL}/claims/toffees`;
                break;

            default:
                    window.location.href = `${process.env.REACT_APP_HTTP_SCHEME}://${process.env.REACT_APP_API_URL}`;
                break;
        }
    }
    render() {
        return (
            <>
                <div className="header position-fixed w-100 top-0 z-index-1">
                    <span className="cursor-pointer" onClick={e => {
                        e.preventDefault()
                        this.onBackClicked();
                    }}>
                        <img className="header-icon" src={require("../../assets/img/icons/back-arrow.png")} alt="" />
                    </span>
                    <a href={`${process.env.REACT_APP_HTTP_SCHEME}://${process.env.REACT_APP_API_URL}`}>
                        <img className="header-logo" src={require("../../assets/img/icons/logo-toffee.png")} alt="" />
                    </a>

                    <a href={`${process.env.REACT_APP_HTTP_SCHEME}://${process.env.REACT_APP_API_URL}/contact-us`}>
                        <img className="header-icon" src={require("../../assets/img/icons/call-icon.png")} alt="" />
                    </a>
                </div>
            </>
        );
    }
}