import React, {Component} from "react";

import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import Box from "@material-ui/core/Box";
import ResponsiveContainer from "../../../components/ResponsiveContainer/responsiveContainer";
import {
    Grid,
    Typography,
    Button,
    Divider,
    AppBar,
    Tabs,
    Tab,
    IconButton,
    withStyles,
    Toolbar,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    List,
    Card,
    CardContent,
    CardHeader,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from "@material-ui/core";
import {Call, AccountCircle, Favorite} from "@material-ui/icons";
import PolicyCard from "./policyCard";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Keys} from '../../../data/keys';
import {Strings} from '../../../data/strings';
import queryString from "query-string";
import moment from "moment";
import API from "../../../utilities/api";
import {Products} from "../../../data/products";
import {Personal} from "../../../data/personal";
import {Address} from "../../../data/address";
import {Qualification} from "../../../data/qualification";
import Checkbox from "@material-ui/core/Checkbox";
import {Lifestyle} from "../../../data/lifeStyle";

const useStyles = (theme) => ({
    topAppBar: {
        paddingTop: '0'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center'
    },
    noQuotationTitle: {
        fontWeight: "Bold",
        textAlign: "justify",
    },
    headerLogo: {
        height: '26px'
    },
    container: {
        [theme.breakpoints.down("md")]: {
            paddingLeft: "12px",
            paddingRight: "12px",
        },

        [theme.breakpoints.up("md")]: {
            paddingLeft: "100px",
            paddingRight: "100px",
            paddingTop: "16px",
            // paddingBottom: "87px",
        },
        [theme.breakpoints.up("lg")]: {
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "16px",
            maxWidth: "1290px",
            margin: "auto",
        },
        [theme.breakpoints.up("xl")]: {
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "16px",
            maxWidth: "1440px",
            margin: "auto",
        },
    },
    headerGrid: {
        height: "180px",
        [theme.breakpoints.down("md")]: {
            height: "100px",
        },
    },
    headerCard: {
        borderRadius: "40px",
        boxShadow: "0 24px 50px 0 rgba(192, 192, 192, 0.5)",
        // marginBottom: "50px",

        [theme.breakpoints.down("md")]: {
            paddingLeft: "50px",
            paddingRight: "50px",
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "0px",
            paddingRight: "0px",
            borderRadius: "0",
            boxShadow: "none",
            // marginBottom: "30px",
            marginTop: -"24px",
        },
        [theme.breakpoints.up("md")]: {
            overflow: "hidden",
            paddingLeft: "50px",
            paddingRight: "50px",
        },

        [theme.breakpoints.up("lg")]: {
            paddingLeft: "120px",
            paddingRight: "120px",
        },
    },
    appBarContainer: {
        overflowX: "scroll",
        [theme.breakpoints.down("sm")]: {
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.13), -3px 3px 17px 0 rgba(140, 138, 138, 0.3)',
        },
    },
    appBar: {
        backgroundColor: "#ffffff !important",
        paddingTop: "28px",
        height: "105px",
        width: "auto",

        [theme.breakpoints.down("md")]: {
            height: '51px',
            paddingTop: '3px'
        },
    },
    tabLabel: {
        fontWeight: "bold",
        fontSize: "16px",
        textTransform: "capitalize",
        minWidth: "auto",
        paddingBottom: "0",
        padding: "0",
        [theme.breakpoints.up("md")]: {
            margin: "0 26px",
            fontSize: "18px",
            fontWeight: "700",
        },
        [theme.breakpoints.down("md")]: {
            fontWeight: "600",
            margin: "0 15px",
            fontSize: "12px",
        },
        [theme.breakpoints.down("sm")]: {
            fontWeight: "500",
            margin: "0 10px",
        },
    },
    logoImg: {
        maxHeight: "90px",
        margin: "0",
        marginRight: "20px",
        [theme.breakpoints.down("md")]: {
            maxHeight: "70px",
        },
        [theme.breakpoints.down("sm")]: {
            maxHeight: "54px",
        },
    },
    tabPannel: {
        paddingLeft: "32px",
        paddingRight: "32px",
        paddingTop: '48px'
        // overflow: "hidden",
        // textAlign: 'center'
    },
    boldFont: {
        fontWeight: "bold",
    },
    center: {
        textAlign: "center",
    },
    blue: {
        color: "#00c9f3",
    },
    mobileHidden: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    mobileCenter: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },

    verticalCenter: {
        display: "flex",
        alignItems: "center",
    },
    justifyEnd: {
        justifyContent: "flex-end",
    },
    justifyCenter: {
        justifyContent: "center",
    },
    claimCard: {
        boxShadow: '1px 2px 3px 0 rgba(0, 0, 0, 0.11)',
        borderRadius: '36px',
        padding: '40px',
        margin: '32px 0',
        backgroundColor: '#f9f9f9',

        textAlign: 'start',
        [theme.breakpoints.down("md")]: {
            textAlign: 'center',
        },
    },
    cardContent: {
        padding: '0!important',
    },
    listIcon: {
        height: '48px',
        width: '48px',
    },
    coverageCard: {
        borderTop: '1px solid rgb(34 51 79 / 0.1)',
        borderRadius: '0',
        paddingTop: '25px',
        paddingBottom: '40px',
        [theme.breakpoints.up("md")]: {
            borderTop: '0',
            padding: '24px'
        }

    },
    coverageTitle: {
        fontSize: '36px'
    },
    expansionPanel: {
        marginTop: '20px',
        '&.MuiExpansionPanel-root:before': {
            display: 'none !important',
        },
    },
    expansionPanelSummary: {
        borderRadius: "50px",
        backgroundColor: '#f9f9fb',
        minHeight: "64px",
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.11)'
    },
    expansionTitle: {
        fontWeight: '500'
    },
    questionTitle: {
        opacity: '0.5'
    },
    questionValue: {
        fontWeight: '500',

    },
    capitalize: {
        textTransform: 'capitalize'
    },
    transactionListItem: {
        borderBottom: '1px solid rgb(34 51 79 / 0.1)'
    },
    paymentIcon: {
        height: '8px'
    },
    failedBox: {
        // width: '296px',
        height: '174px',
        borderRadius: '17px',
        boxShadow: '1px 2px 3px 0 rgba(0, 0, 0, 0.11)',
        backgroundColor: '#f9f9f9',
        paddingTop: '16px',
        paddingBottom: '25px'
    },
    pl2: {
        paddingLeft: '8px'
    },
    downloadBtn: {
        width: '30px',
        height: '30px',
        [theme.breakpoints.up("md")]: {
            width: '42px',
            height: '42px',
        }
    },
    coverageDivider: {
        height: '1px',
        opacity: '0.3',
        backgroundColor: '#979797',
        marginTop: '32px',
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    checkboxStyle: {
        padding: "5px 0"
    }
});

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Box>
    );
}

class MyPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            calOpenDialog: false,
            calculateState: null,
            monthlyPremium: 0,
            yearlyPremium: 0,
            isCalculating: false,
            isCalculated: false,
            isDesktop: window.screen.availWidth > 960,
            bottomBar: false,
            orderId: queryString.parse(this.props.location.search)['order_id'] ?? null,
            personalQuestionList:
                Personal.data ? Personal.data.questions : [],
            qualificationQuestionList: Qualification.data ? Qualification.data.questions : [],
            nomineeQuestionList: [
                {title: Strings.nomineeFullName, key: Keys.nomineeName},
                {title: Strings.nomineeRelation, key: Keys.nomineeRelation},
                {title: Strings.mobileNumber, key: Keys.nomineeMobile},
                {title: Strings.dateOfBirth, key: Keys.nomineeDob},
            ],
            spouseQuestionList: [
                {title: Strings.spouseFullName, key: 'name'},
                {title: Strings.gender, key: 'title'},
                {title: Strings.weightKilograms, key: 'weight'},
                {title: Strings.heightFeet, key: Keys.spouseFeet},
                {title: Strings.dateOfBirth, key: 'dob'},
            ],
            child1QuestionList: [
                {title: Strings.name, key: 'name'},
                {title: Strings.gender, key: 'title'},
                {title: Strings.weightKilograms, key: 'weight'},
                {title: Strings.heightFeet, key: Keys.child1Feet},
                {title: Strings.dateOfBirth, key: 'dob'},
            ],
            child2QuestionList: [
                {title: Strings.name, key: 'name'},
                {title: Strings.gender, key: 'title'},
                {title: Strings.weightKilograms, key: 'weight'},
                {title: Strings.heightFeet, key: Keys.child2Feet},
                {title: Strings.dateOfBirth, key: 'dob'},
            ],
            houseHoldQuestionList: Address.data ? Address.data.questions : [],
            lifeStyleQuestionList: Lifestyle.data ? Lifestyle.data.questions : []
        };
        if (this.getOrderId()) {
            this.props.getUserDetails(this.getOrderId());
            this.props.getPolicyTransactions(this.getOrderId());
        }
    }

    getClaimCard(title, cost) {
        const {classes} = this.props;
        return <Card elevation={0} className={classes.claimCard}>
            <CardContent className={classes.cardContent}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Box pb={1}>
                            <Typography
                                variant="h1"
                                className={classes.boldFont}
                            >
                                {title}
                            </Typography>
                        </Box>
                        <Box pb={2}>
                            <Typography>
                                Coverage: <b>₹ {cost}</b>
                            </Typography>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
              }}
              className={classes.prmBtn}
              hidden={this.state.isCalculating}
            >
              <b>REQUEST CLAIM</b>
            </Button>
          </Grid> */}
                </Grid>
            </CardContent>
        </Card>;
    }

    getCoverageCard(titleText, subtitleText, img, url) {
        const {classes} = this.props;
        return <Grid item xs={12} md={6}>
            <Card elevation={0} className={classes.coverageCard}>
                <CardHeader
                    avatar={

                        <img src={img} className={classes.listIcon}/>
                    }
                    title={<Typography
                        variant="h4"
                        className={classes.boldFont}
                    >
                        {titleText}
                    </Typography>}
                    subheader={subtitleText}
                />
                {url && <CardContent className={classes.cardContent}>
                    <Box display='flex' justifyContent='space-around' alignItems='center'>
                        <Typography className={classes.center}
                        >
                            Download Policy Document
                        </Typography>
                        <IconButton color='primary' edge="center" aria-label="delete" className={classes.downloadBtn}>
                            <a href={url} target="_blank">
                                <img src={require("../../../assets/img/icons/download.png")}
                                     className={classes.downloadBtn}/>
                            </a>
                        </IconButton>
                    </Box>

                </CardContent>}
            </Card>
        </Grid>;
    }

    contains(expand) {
        if (!expand) {
            return false;
        }
        let key = Object.keys(expand)[0];
        let values = Object.values(expand)[0];

        if (!this.props.userDetails) {
            return false;
        } else {
            if (Object.keys(this.props.userDetails).includes(key) < 0) {
                return false;
            } else {
                if (values.includes(this.props.userDetails[key])) {
                    return true;
                } else {
                    return false
                }
            }
        }
    }

    getQuestionArray(index) {
        if (!this.props.userDetails) return []
        switch (index) {
            case 0:
                return this.state.personalQuestionList.map(question => {
                    return (question.key && this.handleHeightValue(question.key) && (question.expand == null || this.contains(question.expand))) && {
                        title: (question.key === Keys.title) ? Strings.title : question.placeholder,
                        value: this.handleHeightValue(question.key)
                    }
                });
            case 1:
                return this.state.qualificationQuestionList.map(question => {
                    return (question.key && this.handleHeightValue(question.key) && (question.expand == null || this.contains(question.expand))) &&
                        {
                            title: question.placeholder,
                            value: (question.inputs) ? question.inputs.map(option => {
                                    return this.props.userDetails[question.key] === option.value && option.label
                                }) :
                                this.handleHeightValue(question.key)
                        }
                })
            case 2:
                return this.state.nomineeQuestionList.map(question => {
                    return {title: question.title, value: this.props.userDetails[question.key]}
                });
            case 3:
                return this.state.spouseQuestionList.map(question => {
                    return (question.key === Keys.spouseFeet ? {
                        title: question.title,
                        value: this.handleHeightValue(question.key)
                    } : {
                        title: question.title,
                        value: this.props.userDetails.response_members && this.props.userDetails.response_members['spouse'][question.key]
                    })
                });
            case 4:
                return this.state.child1QuestionList.map(question => {
                    return (question.key === Keys.child1Feet ? {
                        title: question.title,
                        value: this.handleHeightValue(question.key)
                    } : {
                        title: question.title,
                        value: this.props.userDetails.response_members && this.props.userDetails.response_members['child1'][question.key]
                    })
                });
            case 5:
                return this.state.child2QuestionList.map(question => {
                    return (question.key === Keys.child2Feet ? {
                        title: question.title,
                        value: this.handleHeightValue(question.key)
                    } : {
                        title: question.title,
                        value: this.props.userDetails.response_members && this.props.userDetails.response_members['child2'][question.key]
                    })
                });
            case 6:
                return this.state.houseHoldQuestionList.map(question => {
                    return ((question.key && this.handleHeightValue(question.key) && (question.expand == null || this.contains(question.expand))) && (question.key !== Keys.citizen && question.key !== Keys.authorizeIndiaFirst)) ? {
                        title: question.placeholder,
                        value: this.props.userDetails[question.key]
                    } : ''
                });
            case 7:
                return this.state.lifeStyleQuestionList.map(question => {
                    return (question.key && this.handleHeightValue(question.key) && (question.expand == null || this.contains(question.expand))) &&
                        {
                            title: question.placeholder,
                            value: (question.type !== "checkbox" ? ((question.inputs) ? question.inputs.map(option => {
                                        return this.props.userDetails[question.key] === option.value && option.label
                                    }) :
                                    this.handleHeightValue(question.key)) :
                                    question.inputs.map(option => {
                                        let values = [];
                                        (this.props.userDetails[question.key].map(arrayItem => {
                                            return option.value === arrayItem && (values.push(arrayItem + ', '))
                                        }))
                                        return values
                                    })
                            ),
                            list: "lifestyle"
                        }
                });
            default:
                return []
                break;
        }
    }

    getTwoFixedPoint = (num) => {
        var x = num * 1;
        return x.toFixed(2);
    };

    getTransactionListItem(transaction) {
        let failed = false;
        const {classes} = this.props;
        return <Box pt={2} pb={2} className={classes.transactionListItem}>
            <Grid container className={failed ? classes.failedBox : ''}>
                <Grid item xs={failed ? 12 : 8}>
                    <Box display='flex' justifyContent={failed ? 'center' : 'flex-start'}>
                        <Typography variant={"h5"}>{moment(transaction.date.date).format('MMM DD, yyyy')}</Typography>
                        {/*<Box ml={2}>*/}
                        {/*    <Typography variant={"h5"}>{moment(transaction.date.date).format('HH:mm')}</Typography>*/}
                        {/*</Box>*/}
                    </Box>
                    {!failed && <Typography variant={"h6"}>Payment ID {transaction.payment_id}</Typography>}
                </Grid>
                <Grid item xs={failed ? 12 : 4}>
                    <Box display={failed ? 'flex' : 'inherit'} alignItems='center' justifyContent='center'>
                        <Typography variant={"h5"}>₹{this.getTwoFixedPoint(transaction.amount)}</Typography>
                        <img src={this.getPaymentMethodIcon(transaction.method)}
                             className={failed ? classes.paymentIcon + ' ' + classes.pl2 : classes.paymentIcon}/>
                    </Box>

                    {failed && <Typography variant={"h6"} color='primary' style={{textAlign: 'center'}}>Payment
                        Failed</Typography>}
                </Grid>
                {failed && <Grid item xs={12}>
                    <Box display={'flex'} justifyContent='center'>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                this.retryPayment(transaction.id)
                            }}
                            className={classes.prmBtn}
                            hidden={this.state.isCalculating}
                        >
                            <b>Retry Payment</b>
                        </Button>
                    </Box>
                </Grid>}
            </Grid>
        </Box>;
    }

    getExpansionPannel(title, questions) {
        const {classes} = this.props;
        return <ExpansionPanel className={classes.expansionPanel}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1c-content"
                id="panel1c-header"
                className={classes.expansionPanelSummary}
            >
                <Box pl={1}>
                    <Typography variant={"h5"} className={classes.expansionTitle}>{title}</Typography>
                </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={1}>
                    {
                        questions.map((question) => {
                            return this.getQuestionField(question);
                        })
                    }
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>;
    }

    getQuestionField(question) {
        const {classes} = this.props;
        return (question ? ((question.title !== 'Email' && question.title !== 'Name of the Organization / Business' && !question.list) ?
            <Grid item xs={6}>
                <Typography variant={"h6"} color='secondary'
                            className={classes.questionTitle}>{question.title}</Typography>
                <Typography variant={"h4"} color='secondary'
                            className={(question.title !== 'Email' && classes.capitalize + ' ') + ' ' + classes.questionValue}>{this.parseQuestionValue(question.title, question.value)}</Typography>
            </Grid> :
            <Grid item xs={12}>
                <Typography variant={"h6"} color='secondary'
                            className={classes.questionTitle}>{question.title}</Typography>
                <Typography variant={"h4"} color='secondary'
                            className={(question.title !== 'Email' && classes.capitalize + ' ') + ' ' + classes.questionValue}>{(this.parseQuestionValue(question.title, question.value))}</Typography>
            </Grid>)
            : '')
    }

    handleHeightValue(key) {
        let value;
        switch (key) {
            case Keys.feet:
                value = this.props.userDetails[key] + "' " + this.props.userDetails[Keys.inches] + '"';
                break;
            case Keys.spouseFeet:
                value = ((this.props.userDetails.response_members && this.props.userDetails.response_members['spouse'].height_feet) + "' " + (this.props.userDetails.response_members && this.props.userDetails.response_members['spouse'].height_inch + '"'));
                break;
            case Keys.child1Feet:
                value = ((this.props.userDetails.response_members && this.props.userDetails.response_members['child1'].height_feet) + "' " + (this.props.userDetails.response_members && this.props.userDetails.response_members['child1'].height_inch + '"'));
                break;
            case Keys.child2Feet:
                value = (this.props.userDetails.response_members && this.props.userDetails.response_members['child2'].height_feet) + "' " + (this.props.userDetails.response_members && this.props.userDetails.response_members['child2'].height_inch + '"');
                break;
            default:
                value = this.props.userDetails[key];
        }
        return value;
    }

    parseQuestionValue(title, value) {
        const {classes} = this.props;
        switch (title) {
            case Strings.gender:
                return value.toLowerCase() === 'mr' ? 'Male' : 'Female';
            case Strings.birthday:
                return moment(value, "DD/MM/YYYY").format('MMM DD, yyyy');
            case Strings.dateOfBirth:
                return moment(value, "DD/MM/YYYY").format('MMM DD, yyyy');
            case Keys.startDate:
                return moment(value, "YYYY/MM/DD").format('MMM DD, yyyy');
            case Keys.endDate:
                return moment(value, "YYYY/MM/DD").format('MMM DD, yyyy');
            default:
                return value === true ? <Checkbox className={classes.checkboxStyle} checked={true}/>
                    : value === false ? <Checkbox className={classes.checkboxStyle} checked={false}/> : value;
        }
        return value;
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue}, () => {
            // window.scrollTo(0, window.screen.width > 960 ? 170 : 155);
        });
    };

    retryPayment(enachTransaction) {

        API.get(`transactions/${enachTransaction}/retry`)
            .then((response) => {
                this.setState({
                    retryingPayment: true,
                });
                this.setState({
                    retryingPayment: false,
                });
                this.props.getPolicyTransactions(this.state.orderId);
            })
            .catch((err) => {
                this.setState({
                    retryingPayment: false,
                });
                console.log(err);
            });
    }

    checkForToken() {
        const params = queryString.parse(this.props.location.search);
        // console.log(params.token)
        if (!localStorage.getItem("token") && !params.token) {
            (window.location.href =
                window.location.origin + "/login?redirect_url=" + window.location.href);
        } else {
            if (params.token) {
                localStorage.setItem("token", params.token ?? "")
            }
        }

    }

    getAmoutInLakhs(amount) {
        let finalAmount = (amount * 1) / 100000
        return finalAmount ? (finalAmount === 1 ? finalAmount + ' Lakh' : finalAmount + ' Lakhs') : '';
    }

    getOrderId() {
        return this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1];
    }

    getSumAssured(productId) {
        let sumAssured = '0';
        switch (productId) {
            case '48':
                sumAssured = this.getAmoutInLakhs(this.props.userDetails?.products[productId]?.sum_insured);
                break;
            case '49':
                sumAssured = this.getAmoutInLakhs(this.props.userDetails?.products[productId]?.sum_insured);
                break;
            case '52':
                sumAssured = this.getAmoutInLakhs(this.props.userDetails?.products[productId]?.sum_insured);
                break;
            case '53':
                sumAssured = this.getAmoutInLakhs(this.props.userDetails?.products[productId]?.sum_insured);
                break;
            case '54':
                sumAssured = this.getAmoutInLakhs(this.props.userDetails?.products[productId]?.sum_insured);
                break;
            case '55':
                sumAssured = this.getAmoutInLakhs(this.props.userDetails?.products[productId]?.sum_insured);
                break;
            case '56':
                sumAssured = this.getAmoutInLakhs(this.props.userDetails?.products[productId]?.sum_insured);
                break;
            case '58':
                sumAssured = this.getAmoutInLakhs(this.props.userDetails?.products[productId]?.sum_insured);
                break;
            case '59':
                sumAssured = this.getAmoutInLakhs(this.props.userDetails?.products[productId]?.sum_insured);
                break;
        }
        return sumAssured;
    }

    getPaymentMethodIcon(method) {
        switch (method) {
            case 'upi':
                return require("../../../assets/img/icons/upi.svg");
            case 'wallet':
                return require("../../../assets/img/icons/wallet.svg");
            case 'netbanking':
                return require("../../../assets/img/icons/netbanking.svg");
            case 'card':
                return require("../../../assets/img/icons/cardPayment.svg");
        }
    }


    render() {
        const {classes} = this.props;
        const isDesktop = window.innerWidth > 960;
        const child1Exists = this.props.userDetails && this.props.userDetails.response_members && this.props.userDetails.response_members['child1'];
        const child2Exists = this.props.userDetails && this.props.userDetails.response_members && this.props.userDetails.response_members['child2'];
        const spouceExists = this.props.userDetails && this.props.userDetails.response_members && this.props.userDetails.response_members['spouse'];
        this.checkForToken();
        const sum_assured = this.props.userDetails ? this.props.userDetails.products : {};
        return (
            <ResponsiveContainer
                child={
                    <>
                        <AppBar position="static" className={classes.topAppBar}>
                            <Toolbar>
                                <IconButton edge="start" className={classes.menuButton} color="inherit"
                                            aria-label="menu">
                                    <AccountCircle/>
                                </IconButton>
                                <Box variant="h6" className={classes.title}>
                                    <img className={classes.headerLogo}
                                         src={require("../../../assets/img/icons/toffee_logo_white_3x.png")}/>
                                </Box>

                                <IconButton
                                    edge="end"
                                    aria-label="Account"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <Call/>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Box className={classes.headerCard}>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                className={classes.headerGrid}
                                // spacing={2}
                            >
                                <Grid item md={6} xs={12}>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Box
                                            display="flex"
                                            flexGrow={1}
                                            justifyContent="center"
                                            className={classes.mobileCenter}
                                        >
                                            <img
                                                src={require("../../../assets/img/icon-toffeeplan.svg")}
                                                alt=""
                                                className={classes.logoImg}
                                            />
                                            <Box display="flex" alignItems="center">
                                                <Box>
                                                    <Typography
                                                        variant="h1"
                                                        className={classes.boldFont}
                                                    >
                                                        The Toffee Plan
                                                    </Typography>
                                                    <Typography variant="h4">
                                                        Toffee
                                                        ID: {this.props.userDetails && this.props.userDetails.toffee_id}
                                                    </Typography>
                                                    <Typography variant="h5">
                                                        {this.props.userDetails && this.props.userDetails.start_date && this.parseQuestionValue(Keys.startDate, this.props.userDetails.start_date)}
                                                        {this.props.userDetails && this.props.userDetails.start_date && ' - '}
                                                        {this.props.userDetails && this.props.userDetails.end_date && this.parseQuestionValue(Keys.endDate, this.props.userDetails.end_date)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* <Divider /> */}
                            <Box
                                container
                                display="flex"
                                justifyContent="center"
                                className={classes.appBarContainer}
                            >
                                <AppBar position="static" className={classes.appBar}>
                                    <Tabs
                                        value={this.state.value}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={this.handleChange}
                                    >
                                        <Tab className={classes.tabLabel} label="COVERAGE"/>
                                        <Tab className={classes.tabLabel} label="DETAILS"/>
                                        <Tab className={classes.tabLabel} label="CLAIMS"/>
                                        <Tab className={classes.tabLabel} label="TRANSACTIONS"/>
                                    </Tabs>
                                </AppBar>
                            </Box>
                        </Box>

                        <TabPanel
                            value={this.state.value}
                            index={0}
                            className={classes.tabPannel}
                        >
                            <Grid container alignItems='center'>
                                <Grid item xs={12} md={5}>
                                    <PolicyCard index={0}/>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    {isDesktop && <Typography className={classes.coverageTitle}>
                                        Your coverage details.
                                    </Typography>}
                                    {this.props.userDetails && this.props.userDetails['covernote'] && <List>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={classes.boldFont}
                                                    >
                                                        Toffee Plan Benefit Summary
                                                    </Typography>

                                                }
                                            />

                                            <ListItemSecondaryAction>
                                                <a href={this.props.userDetails['covernote']} target="_blank">
                                                    <IconButton color='primary' edge="center"
                                                                aria-label="delete"
                                                                className={classes.downloadBtn}>
                                                        <img
                                                            src={require("../../../assets/img/icons/download.png")}
                                                            className={classes.downloadBtn}/>
                                                    </IconButton>
                                                </a>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>}
                                </Grid>
                            </Grid>
                            <Divider className={classes.coverageDivider}/>
                            {this.props.userDetails ?
                                <Grid
                                    container
                                    direction="row"
                                    // justify="center"
                                    alignItems="center"
                                >
                                    {this.props.userDetails && this.props.userDetails['products'] && this.props.userDetails['products']['52'] && this.getCoverageCard('Life Insurance of ₹ ' + this.getAmoutInLakhs(this.props.userDetails['products']['52']['sum_insured']), 'IndiaFirst Life E-Term Plan', require("../../../assets/img/heart-icon.png"), this.props.userDetails['products']['52']['policy_document'])}
                                    {this.props.userDetails && this.props.userDetails['products'] && this.props.userDetails['products']['49'] && this.getCoverageCard('Health Insurance of ₹ ' + this.getAmoutInLakhs(this.props.userDetails['products']['49']['sum_insured']), 'Care Health Insurance', require("../../../assets/img/case-icon.png"), this.props.userDetails['products']['49']['policy_document'])}
                                    {this.props.userDetails && this.props.userDetails['products'] && this.props.userDetails['products']['48'] && this.getCoverageCard('Life Insurance of ₹ ' + this.getAmoutInLakhs(this.props.userDetails['products']['48']['sum_insured']), 'ICICI Life Insurance', require("../../../assets/img/heart-icon.png"), this.props.userDetails['products']['48']['policy_document'])}
                                    {this.props.userDetails && this.props.userDetails['products'] && this.props.userDetails['products']['53'] && this.getCoverageCard('Health Insurance of ₹ ' + this.getAmoutInLakhs(this.props.userDetails['products']['53']['sum_insured']), 'TATA AIG Medicare Protect', require("../../../assets/img/case-icon.png"), this.props.userDetails['products']['53']['policy_document'])}
                                    {this.props.userDetails && this.props.userDetails['products'] && this.props.userDetails['products']['55'] && this.getCoverageCard('Health Insurance Top Up ₹ ' + this.getAmoutInLakhs(this.props.userDetails['products']['55']['sum_insured']), 'TATA AIG Medicare Plus', require("../../../assets/img/case-plus-icon.png"), this.props.userDetails['products']['55']['policy_document'])}
                                    {this.props.userDetails && this.props.userDetails['products'] && this.props.userDetails['products']['54'] && this.getCoverageCard('Household Insurance of ₹ ' + this.getAmoutInLakhs(this.props.userDetails['products']['54']['sum_insured']), 'TATA AIG HOME SECURE', require("../../../assets/img/home-icon.png"), this.props.userDetails['products']['54']['policy_document'])}
                                    {this.props.userDetails && this.props.userDetails['products'] && this.props.userDetails['products']['58'] && this.getCoverageCard('Life Insurance of ₹ ' + this.getAmoutInLakhs(this.props.userDetails['products']['58']['sum_insured']), 'PNB MetLife Complete Care Plus', require("../../../assets/img/heart-icon.png"), this.props.userDetails['products']['58']['policy_document'])}

                                    {this.props.userDetails && this.props.userDetails['products'] && this.props.userDetails['products']['59'] && this.getCoverageCard('Life Insurance of ₹ ' + this.getAmoutInLakhs(this.props.userDetails['products']['59']['sum_insured']), 'IndiaFirst Life Guaranteed Protection Plan', require("../../../assets/img/heart-icon.png"), this.props.userDetails['products']['59']['policy_document'])}

                                </Grid>
                                :
                                <Box display="flex" justifyContent="center">
                                    <Box m={"auto"} pt={10}>
                                        <Box py={2}>
                                            <Favorite color={"primary"}/>
                                        </Box>
                                        <Typography
                                            variant={"h1"}
                                            align="center"
                                            className={classes.noQuotationTitle}
                                        >
                                            Thanks !
                                        </Typography>
                                        <Typography
                                            variant={"h3"}
                                            align="justify"
                                        >
                                            Policy data will be available once you complete the payment.
                                        </Typography>
                                    </Box>
                                </Box>
                            }

                        </TabPanel>
                        <TabPanel
                            value={this.state.value}
                            index={1}
                            className={classes.tabPannel}
                        >

                            <Grid container alignItems='center'>
                                <Grid item xs={12} md={5}>
                                    <PolicyCard index={1}/>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    {isDesktop && <Typography className={classes.coverageTitle}>
                                        Your policy details.
                                    </Typography>}
                                </Grid>
                            </Grid>
                            <Divider className={classes.coverageDivider}/>
                            <Box pb={20} pt={4} id={"detailsSection"}>
                                {this.getExpansionPannel('Personal Information', this.getQuestionArray(0))}
                                {this.getExpansionPannel('Qualifications', this.getQuestionArray(1))}
                                {this.getExpansionPannel('Nominee Details', this.getQuestionArray(2))}
                                {spouceExists && this.getExpansionPannel('Spouse Details', this.getQuestionArray(3))}
                                {child1Exists && this.getExpansionPannel('Child 1 Details', this.getQuestionArray(4))}
                                {child2Exists && this.getExpansionPannel('Child 2 Details', this.getQuestionArray(5))}
                                {this.getExpansionPannel('Household Insurance Details', this.getQuestionArray(6))}
                                {this.getExpansionPannel('Lifestyle Details', this.getQuestionArray(7))}
                            </Box>
                        </TabPanel>
                        <TabPanel
                            value={this.state.value}
                            index={2}
                            className={classes.tabPannel}
                        >
                            <Grid container alignItems='center'>
                                <Grid item xs={12} md={5}>
                                    <PolicyCard index={2}/>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    {isDesktop && <Typography className={classes.coverageTitle}>
                                        What do you want to claim for?
                                    </Typography>}
                                </Grid>
                            </Grid>
                            <Divider className={classes.coverageDivider}/>
                            {sum_assured ?
                                Object.keys(sum_assured).map((product_id, i) => {
                                    return this.getClaimCard(Products[product_id].name, this.getSumAssured(product_id))
                                })
                                :
                                <Box display="flex" justifyContent="center">
                                    <Box m={"auto"} pt={10}>
                                        <Box py={2}>
                                            <Favorite color={"primary"}/>
                                        </Box>
                                        <Typography
                                            variant={"h1"}
                                            align="center"
                                            className={classes.noQuotationTitle}
                                        >
                                            Thanks !
                                        </Typography>
                                        <Typography
                                            variant={"h3"}
                                            align="justify"
                                        >
                                            Claim data will be available once you complete the payment.
                                        </Typography>
                                    </Box>
                                </Box>
                            }


                        </TabPanel>
                        <TabPanel
                            value={this.state.value}
                            index={3}
                            className={classes.tabPannel}
                        >
                            {this.props.policyTransactions ? <>
                                    <Box textAlign='center' mb={3}>
                                        <Typography variant={"h6"}
                                                    color='secondary'>{this.props.policyTransactions && this.props.policyTransactions.is_monthly ? 'Monthly' : 'Annual'} Premium</Typography>
                                        <Typography variant={"h2"}
                                                    color='secondary'>₹{this.props.policyTransactions && this.props.policyTransactions.premium}/-</Typography>
                                    </Box>
                                    {
                                        this.props.policyTransactions && this.props.policyTransactions.transactions && this.props.policyTransactions.transactions.map((transaction) => {
                                            return this.getTransactionListItem(transaction)
                                        })
                                    }
                                </>
                                :
                                <Box display="flex" justifyContent="center">
                                    <Box m={"auto"} pt={10}>
                                        <Box py={2}>
                                            <Favorite color={"primary"}/>
                                        </Box>
                                        <Typography
                                            variant={"h1"}
                                            align="center"
                                            className={classes.noQuotationTitle}
                                        >
                                            Thanks !
                                        </Typography>
                                        <Typography
                                            variant={"h3"}
                                            align="justify"
                                        >
                                            Transactions data will be available once you complete the payment.
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                        </TabPanel>
                    </>
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.customer.error,
        userDetails: state.customer.userDetails,
        policyTransactions: state.customer.policyTransactions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
        getUserDetails: (userId) => dispatch(actions.getUserDetails(userId)),
        getPolicyTransactions: (orderId) => dispatch(actions.getPolicyTransactions(orderId)),
    };
};
export default withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(MyPolicy)
);