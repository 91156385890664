import React, {Component} from "react";
import {QuestionType} from "./questionTypes";
import MaterialDate from "../../components/Buy/date";
import MaterialChip from "../../components/Buy/choice";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import {Box, FormGroup, Grid, Input, TextField, Typography, withStyles} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import Checkbox from "@material-ui/core/Checkbox";
import {Strings} from "../../data/strings";
import {connect} from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import {InputValidator} from "./inputValidator";
import {Keys} from "../../data/keys";
import {IndiaFirstLocation} from "../../location/indiaFirstLocations";


const useStyles = () => ({
    toggle:
        {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
    sliderInput: {
        width: '50px'
    },
    checkboxLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '0',
        marginLeft: '0'
    },
    textMargin: {
        marginLeft: '16px'
    }
});

class QuestionBuilder extends Component {

    onChangeKeyData(key, data) {
        this.onRemoveError();
        if (this.props.question.validation && this.props.question.validation.length > 0 && (this.props.question.type === QuestionType.text)) {
            if (this.props.question.validation.includes(InputValidator.required) && data === '' && (this.props.question.key !== Keys.permanentPincode)) {
                this.onAddError('This field cannot be empty')
            }
            if (this.props.question.validation.includes(InputValidator.numeric) && !this.nat(data)) {
                this.onAddError('This field should be numeric')
                return;
            }
            if (this.props.question.maxLength && (data.length > this.props.question.maxLength)) {
                return;
            }
            if (this.props.question.validation.includes(InputValidator.salaryIncome) && !this.salaryIncome(data)) {
                this.onAddError('Minimum salary should be 300000')
            }
            if (this.props.question.validation.includes(InputValidator.nonSalaryIncome) && !this.nonSalaryIncome(data)) {
                this.onAddError('Minimum salary should be 400000')
            }
            if (this.props.question.validation.includes(InputValidator.indiaFirstMinSumInsured) && !this.sumInsured(data)) {
                this.onAddError('Minimum sum Insured should be 10000')
            }
            if (this.props.question.maxValue && (data > this.props.question.maxValue)) {
                this.onAddError('Max number should be ' + this.props.question.maxValue)
                return;
            }
            if (this.props.question.minLength && (data.length < this.props.question.minLength) && (this.props.question.key !== Keys.permanentPincode)) {
                this.onAddError('Min length is ' + this.props.question.minLength)
            }

            if (this.props.question.validation.includes(InputValidator.name) && !this.isNameValid(data)) {
                this.onAddError("Please enter the full name. If the name has only one word, then repeat it. Eg: Ravi Ravi")
            }
            if (this.props.question.validation.includes(InputValidator.pan) && !this.isPanValid(data)) {
                this.onAddError("Invalid PAN")
            }
            if (this.props.question.validation.includes(InputValidator.orgName) && !this.isOrgNameValid(data)) {
                this.onAddError("Invalid Org Name")
            }
            if (this.props.question.validation.includes(InputValidator.email) && !this.isEmailValid(data) && data !== '') {
                this.onAddError("Invalid Email")
            }
            if (this.props.question.key === Keys.pincode) {
                let cityState = new IndiaFirstLocation().get(data);
                if (cityState) {
                    this.props.onQuestionChange(Keys.city, cityState[0]);
                    this.props.onQuestionChange(Keys.state, cityState[1]);
                } else {
                    this.props.onQuestionChange(Keys.city, '');
                    this.props.onQuestionChange(Keys.state, '');
                }
            }
            if (this.props.question.key === Keys.permanentPincode) {
                let cityState = new IndiaFirstLocation().get(data);
                if (cityState) {
                    this.props.onQuestionChange(Keys.permanentCity, cityState[0]);
                    this.props.onQuestionChange(Keys.permanentState, cityState[1]);
                } else {
                    this.props.onQuestionChange(Keys.permanentCity, '');
                    this.props.onQuestionChange(Keys.permanentState, '');
                }
            }
            this.props.question.validation.forEach((validation) => {
            })
        }

        this.props.onQuestionChange(key, data);
    }

    isEmailValid(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    isNameValid(name) {
        const re = /^([a-zA-Z]{2,}[\s]+[a-zA-Z]{2,})*$/;
        return re.test(name);
    }

    isPanValid(pan) {
        const re = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        return re.test(pan);
    }

    isOrgNameValid(orgName) {
        const re = /^[a-zA-Z0-9]{2,}(?:[\s][a-zA-Z0-9]{2,})*$/;
        return re.test(orgName);
    }


    nat = (n) => {
        return /^[1-9]*\d*$/.test(n);
    };

    salaryIncome = (n) => {
        return n >= 300000;
    }

    nonSalaryIncome = (n) => {
        return n >= 400000;
    }

    sumInsured = (n) => {
        return n >= 10000;
    }

    onAddError(error) {
        let errorArray = [];
        if (this.props.buyFormError && this.props.buyFormError[this.props.questionnaireKey]) {
            errorArray = this.props.buyFormError[this.props.questionnaireKey];
        }
        errorArray = errorArray.filter((item) => {
            return item.key !== this.props.question.key;
        });
        errorArray.push({key: this.props.question.key, error: error});
        this.props.addBuyFlowError({
                [this.props.questionnaireKey]: errorArray
            }
        )
    }

    onRemoveError() {
        let errorArray = [];
        if (this.props.buyFormError && this.props.buyFormError[this.props.questionnaireKey]) {
            errorArray = this.props.buyFormError[this.props.questionnaireKey];
        }
        errorArray = errorArray.filter((item) => {
            return item.key !== this.props.question.key;
        });
        this.props.addBuyFlowError({
                [this.props.questionnaireKey]: errorArray
            }
        )
    }

    text() {
        return (
            <TextField
                label={this.props.question.placeholder}
                fullWidth
                disabled={this.props.question.readonly ?? false}
                type={this.props.question.textInputType ?? 'text'}
                value={this.props.initialValue ?? ''}
                onChange={(e) => {
                    this.onChangeKeyData(this.props.question.key, e.target.value)
                }}
                helperText={this.props.error ? this.props.error.error : ''}
                className={(this.props.question.placeholder === Strings.specifyDetails || this.props.question.placeholder === Strings.politicallyExposedDetails) ? this.props.classes.textMargin : ''}
                autoComplete={(this.props.question.key === Keys.permanentPincode || this.props.question.key === Keys.permanentState || this.props.question.key === Keys.permanentCity || this.props.question.key === Keys.permanentAddressLine1 || this.props.question.key === Keys.permanentAddressLine2 || this.props.question.key === Keys.permanentLandmark) ? 'true' : 'false'}
            />
        );
    }

    date() {
        return (
            <MaterialDate
                title={this.props.question.placeholder}
                onChange={(date) => {
                    this.onChangeKeyData(this.props.question.key, date)
                }}
                question={this.props.question}
                initialValue={this.props.initialValue}

                minDate={this.props.question.minDate}
                maxDate={this.props.question.maxDate}
            />
        );
    }

    toggle() {
        return (
            <FormGroup row>
                <FormControlLabel className={this.props.classes.toggle}
                                  control={<Switch color="primary"
                                                   checked={this.props.initialValue ?? false}
                                                   onChange={(event) => {
                                                       this.onChangeKeyData(this.props.question.key, event.target.checked)
                                                   }}/>}
                                  label={<Typography
                                      variant={"h4"}><b>{this.props.question.placeholder}</b></Typography>}
                                  labelPlacement="start"
                                  style={{"margin": 0}}
                />
            </FormGroup>
        );
    }

    slider() {
        return (<>
                <Typography variant={"h4"} gutterBottom>
                    <b>{this.props.question.placeholder}</b>
                </Typography>
                <Grid container spacing={2} alignItems="center">

                    <Grid item xs>
                        <Slider
                            defaultValue={this.props.question.initial}
                            value={this.props.initialValue}
                            aria-labelledby="input-slider"
                            onChange={(event, newValue) => {
                                this.onChangeKeyData(this.props.question.key, newValue)
                            }}
                            placeholder={this.props.question.placeholder}
                            step={this.props.question.step}
                            min={this.props.question.min}
                            max={this.props.question.max}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            className={this.props.classes.sliderInput}
                            value={this.props.initialValue ?? 0}
                            onChange={(e) => {
                                // if (e.target.value < this.props.question.min) {
                                //     this.onChangeKeyData(this.props.question.key, this.props.question.min)
                                // } else if (e.target.value > this.props.question.max) {
                                //     this.onChangeKeyData(this.props.question.key, this.props.question.max)
                                // } else {
                                //     this.onChangeKeyData(this.props.question.key, e.target.value)
                                // }
                            }}
                            disabled
                            onBlur={(e) => {
                                // console.log(e)
                            }}
                            inputProps={{
                                step: this.props.question.step,

                                min: this.props.question.min,
                                max: this.props.question.max,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        );
    }

    choice() {
        return (
            <MaterialChip
                selectedItem={"2"}
                onChange={(value) => {
                    this.onChangeKeyData(this.props.question.key, value)
                }}
                question={this.props.question}
                initialValue={this.props.initialValue}
                formData={this.props.formData}
            />
        );
    }

    checkbox() {
        let values = this.props.initialValue ?? [];
        return (<>
            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">{this.props.question.placeholder}</FormLabel>
                <FormGroup>
                    {
                        this.props.question.inputs.map(input => {
                            return <FormControlLabel
                                className={this.props.classes.checkboxLabel}
                                control={<Checkbox onChange={(event, value) => {
                                    if (value) {
                                        values.push(input.value)
                                    } else {
                                        values = values.filter(function (value, index, arr) {
                                            return value !== input.value
                                        });
                                    }
                                    this.onChangeKeyData(this.props.question.key, values)
                                }}/>}
                                label={input.label}
                                labelPlacement="start"
                            />;
                        })
                    }
                </FormGroup>
            </FormControl>
        </>);
    }

    contains(expand) {
        if (!expand) {
            return false;
        }
        let key = Object.keys(expand)[0];
        let values = Object.values(expand)[0];

        if (!this.props.formData) {
            return false;
        } else {
            if (Object.keys(this.props.formData).includes(key) < 0) {
                return false;
            } else {
                if (values.includes(this.props.formData[key])) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    getRandomNumber() {
        // Math.floor(Math.random() * 10000)
        return 'weqwr';
    }

    render() {
        const {classes} = this.props;
        if (this.props.question.expand == null || this.contains(this.props.question.expand)) {
            switch (this.props.question.type) {
                case QuestionType.text:
                    return <Box key={this.props.question.key + this.getRandomNumber()} py={2}>{this.text()}</Box>;
                case QuestionType.toggle:
                    return <Box key={this.props.question.key + this.getRandomNumber()} py={2}>{this.toggle()}</Box>;
                case QuestionType.date:
                    return <Box key={this.props.question.key + this.getRandomNumber()} py={2}>{this.date()}</Box>;
                case QuestionType.slider:
                    return <Box key={this.props.question.key + this.getRandomNumber()} py={2}>{this.slider()}</Box>;
                case QuestionType.choice:
                    return <Box key={this.props.question.key + this.getRandomNumber()} py={2}>{this.choice()}</Box>;
                case QuestionType.checkbox:
                    return <Box key={this.props.question.key + this.getRandomNumber()} py={2}>{this.checkbox()}</Box>;
                default:
                    return <Typography>
                        Invalid Input
                    </Typography>;
            }
        } else {
            return "";
        }

    }
}

const mapStateToProps = (state) => {
    return {
        buyFormError: state.customer.buyFormError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addBuyFlowError: (error) =>
            dispatch({
                type: actionTypes.SET_BUY_FORM_ERROR,
                error: error,
            }),
    };
};


export default withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(QuestionBuilder)
);