import React, {Component} from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {AppBar, Button, Fab, Grid, IconButton, Switch, TextField, Toolbar, withStyles,} from "@material-ui/core";
import ChipSelect from "./ChipSelect";
import ChipDate from "./ChipDate";
import moment from "moment";
import ResponsiveContainer from "../../../components/ResponsiveContainer/responsiveContainer";
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import API from "../../../utilities/api";
import NotificationAlert from "react-notification-alert";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Otp from "../VerifyDetails/CutomerVerification/otp";
import {strings} from "../../../languages/localization";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

const useStyles = (theme) => ({
    leftBox: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    formBox: {
        borderRadius: "4px",
    },
    textField: {
        underline: {
            "&&&:before": {
                borderBottom: "none",
            },
            "&&:after": {
                borderBottom: "none",
            },
        },
    },
    fab: {
        right: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textAlign: "center",
    },

    fixedBar: {
        boxShadow: "0 0 50px 0 rgba(192, 192, 192, 0.5)",
        padding: "10px 15px",
        height: "130px",
        backgroundColor: "#ffffff",
        transition: "all 0.5s",

        [theme.breakpoints.up("md")]: {
            paddingLeft: "100px",
            paddingRight: "100px",
            height: "87px",
        },
        [theme.breakpoints.up("lg")]: {
            // paddingLeft: "125px",
            // paddingRight: "125px",
            height: "87px",
        },
    },
    barInnerBox: {
        width: "100%",
        [theme.breakpoints.up("lg")]: {
            paddingLeft: "15px",
            paddingRight: "15px",
            maxWidth: "1290px",
            margin: "auto",
        },
        [theme.breakpoints.up("xl")]: {
            paddingLeft: "15px",
            paddingRight: "15px",
            maxWidth: "1440px",
            margin: "auto",
        },
    },

    toffeePlanLogo: {
        maxWidth: "290px",
        height: "55px",
    },
    boldFont: {
        fontWeight: "bold",
    },
    center: {
        textAlign: "center",
    },

    mobileHidden: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    mobileCenter: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    verticalCenter: {
        display: "flex",
        alignItems: "center",
    },
    justifyEnd: {
        justifyContent: "flex-end",
    },
    justifyCenter: {
        justifyContent: "center",
    },
    bottomBar: {
        height: "87px",
        [theme.breakpoints.down("sm")]: {
            height: "130px",
        },
        [theme.breakpoints.up("md")]: {
            height: "87px",
        },
    },
    textBox: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "20px",
            paddingRight: "20px",
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "15px",
            paddingRight: "25px",
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: "15px",
            paddingLeft: "25px",
            paddingRight: "25px",
        },
    },
    delText: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "5px",
            marginBottom: "0px",
            paddingBottom: "0px",
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: "10px",
            marginBottom: "0px",
            paddingBottom: "0px",
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "10px",
            marginBottom: "10px",
            paddingBottom: "0px",
        },
    },
    saveText: {
        color: '#ff585d'
    },

    fontLight: {
        fontWeight: "200",
    },
    content: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    paper: {
        backgroundColor: 'transparent'
    },
    whiteCurvedContainer: {
        backgroundColor: "#ffffff",
        borderRadius: "24px 24px 0 0",
        // height: '100vh',
        overflowY: 'scroll',
        height: "calc(100vh - 69px)"
    },
    redBg: {
        backgroundColor: theme.palette.primary.main,
    },

});

const tagManagerArgs = {
    gtmId: 'GTM-000001',

    dataLayerName: 'LeadGoogle'
}


class CalculatePremiumBuyflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            title: "mr",
            titleOptions: [
                {title: "Mr", value: "mr"},
                {title: "Ms", value: "ms"},
            ],
            name: "",
            mobile: "",
            email: "",
            self: true,
            dob: "",
            spouse: false,
            spouseDob: "",
            child1: false,
            child1Dob: "",
            child2: false,
            child2Dob: "",
            sumAssuredLife: "5000000",
            sumAssuredLifeOptions: [
                {title: "₹50 Lakhs", value: "5000000"},
                {title: "₹75 Lakhs", value: "7500000"},
                {title: "Not Required", value: "0"},

            ],
            sumAssuredHealth: "300000",
            sumAssuredHealthOptions: [
                {title: "₹3 Lakhs", value: "300000"},
                {title: "₹4 Lakhs", value: "400000"},
                {title: "₹5 Lakhs", value: "500000"},
                {title: "Not Required", value: "0"},
            ],
            sumAssuredHouse: "100000",
            sumAssuredHouseOptions: [
                {title: "₹1 Lakh", value: "100000"},
                {title: "₹2 Lakhs", value: "200000"},
                {title: "₹5 Lakhs", value: "500000"},
                {title: "Not Required", value: "0"},
            ],
            isConsumingTobacco: false,
            calculateState: null,
            monthlyPremium: 0,
            yearlyPremium: 0,
            isCalculating: false,
            isCalculated: false,
            isDesktop: true,
            bottomBar: false,
            open: false,
            otp: "",
            otpSentText: false,
            otpSendingText: false,
            otpFailText: false,
            error: '',
            isBelowMinimum: false,
            partner_slug: new URL(window.location.href).searchParams.get("partner_slug"),
            partner_reference: new URL(window.location.href).searchParams.get("partner_reference"),
            openThankyou: false,
            allZeroDialog: false
        };
    }

    formData = {
        _token: "DDf8fZhdjiaUK3nZZVIiqbqtCEdRuXZy6CERu1fI",
        DOB: "on",
    };

    componentDidMount() {
        this.updateFormProps();
    }

    selectTitle(title) {
        this.setState(
            {
                title: title,
            },
            () => {
                this.updateFormProps();
            }
        );
    }

    changeInput(type, value) {
        if (type === "mobile" && !this.nat(value)) {
            return;
        }
        this.setState(
            {
                [type]: value,
            },
            () => {
                this.updateFormProps();
            }
        );
    }

    updateFormProps(state) {
        // this.props.onFormChange(this.state);
        this.setState({
            calculateState: state,
            isCalculated: false,
        });
    }

    changesumAssuredLife(sum) {
        this.setState(
            {
                sumAssuredLife: sum,
            },
            () => {
                this.updateFormProps();
            }
        );
    }

    changesumAssuredHealth(sum) {
        this.setState(
            {
                sumAssuredHealth: sum,
            },
            () => {
                this.updateFormProps();
            }
        );
    }

    changesumAssuredHouse(sum) {
        this.setState(
            {
                sumAssuredHouse: sum,
            },
            () => {
                this.updateFormProps();
            }
        );
    }

    changeDobChip(member, type, value) {
        if (type === "chip") {
            switch (member) {
                case "dob":
                    this.setState(
                        {
                            self: value,
                            dob: value ? this.state.dob : "",
                        },
                        () => {
                            this.updateFormProps();
                        }
                    );
                    break;
                case "spouseDob":
                    this.setState(
                        {
                            spouse: value,
                            spouseDob: value ? this.state.spouseDob : "",
                        },
                        () => {
                            this.updateFormProps();
                        }
                    );
                    break;
                case "child1Dob":
                    this.setState(
                        {
                            child1: value,
                            child1Dob: value ? this.state.child1Dob : "",
                        },
                        () => {
                            this.updateFormProps();
                        }
                    );
                    break;
                case "child2Dob":
                    this.setState(
                        {
                            child2: value,
                            child2Dob: value ? this.state.child2Dob : "",
                        },
                        () => {
                            this.updateFormProps();
                        }
                    );
                    break;
            }
        } else {
            this.setState(
                {
                    [member]: value,
                },
                () => {
                    this.updateFormProps();
                }
            );
        }
    }

    nat = (n) => {
        return /^[1-9]*\d*$/.test(n);
    };

    nextStep() {
        this.formData["gender"] =
            this.state.title === "mr" ? "male" : "female";
        this.formData["name"] = this.state.name;
        this.formData["mobile"] = this.state.mobile;
        this.formData["email"] = this.state.email;
        this.formData["dob"] = this.state.dob;
        this.formData["members[spouse][dob]"] = this.state.spouseDob;
        this.formData["members[child1][dob]"] = this.state.child1Dob;
        this.formData["members[child2][dob]"] = this.state.child2Dob;
        if (this.formData.name === "") {
            this.notify("tc", "", "Please enter name ", "danger");
            return;
        }
        if (!this.isNameValid(this.formData.name)) {
            this.notify("tc", "", "Please enter full name ", "danger");
            return;
        }
        if (this.formData.mobile === "") {
            this.notify("tc", "", "Please enter mobile number ", "danger");
            return;
        }
        if (this.formData.mobile.length !== 10) {
            this.notify("tc", "", "Please enter 10 digit mobile number ", "danger");
            return;
        }
        if (this.formData.email === "") {
            this.notify("tc", "", "Please enter email id ", "danger");
            return;
        }
        if (!this.isEmailValid(this.formData.email)) {
            this.notify("tc", "", "Please enter valid email id ", "danger");
            return;
        }
        if (this.formData.dob === "") {
            this.notify("tc", "", "Please enter self dob ", "danger");
            return;
        }
        if (
            this.state.spouse &&
            this.state.spouseDob === ""
        ) {
            this.notify("tc", "", "Please enter spouse dob ", "danger");
            return;
        }
        if (
            this.state.child1 &&
            this.state.child1Dob === ""
        ) {
            this.notify("tc", "", "Please enter child1 dob ", "danger");
            return;
        }
        if (
            this.state.child2 &&
            this.state.child2Dob === ""
        ) {
            this.notify("tc", "", "Please enter child2 dob ", "danger");
            return;
        }
        if (this.state.currentStep > 0) return;
        this.setState({
            currentStep: this.state.currentStep + 1,
        });
    }

    prevStep() {
        if (this.state.currentStep < 1) return;
        this.setState({
            currentStep: this.state.currentStep - 1,
        });
    }

    isEmailValid(email) {
        let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    isNameValid(name) {
        const re = /^([a-zA-Z]{2,}[\s]+[a-zA-Z]{2,})*$/;
        return re.test(name);
    }

    calculatePremium() {
        if (this.state.calculateState) return;
        this.formData["gender"] =
            this.state.title === "mr" ? "male" : "female";
        this.formData["name"] = this.state.name;
        this.formData["mobile"] = this.state.mobile;
        this.formData["email"] = this.state.email;
        this.formData["dob"] = this.state.dob;
        this.formData["members[spouse][dob]"] = this.state.spouseDob;
        this.formData["members[child1][dob]"] = this.state.child1Dob;
        this.formData["members[child2][dob]"] = this.state.child2Dob;
        this.formData[
            "sum_assured_life"
            ] = this.state.sumAssuredLife;
        this.formData[
            "sum_assured_health"
            ] = this.state.sumAssuredHealth;
        this.formData[
            "sum_assured_home"
            ] = this.state.sumAssuredHouse;
        this.formData[
            "sum_assured_health_topup"
            ] = "0";
        this.formData["capture_lead"] = "Y";
        this.formData["is_smoker"] = this.state.isConsumingTobacco;
        this.setState({
            isCalculating: true,
        });
        const data = new FormData();
        Object.keys(this.formData).forEach((key) =>
            data.append(key, this.formData[key])
        );

        API.post("premium/51", data)
            .then((response) => {
                this.setState({
                    isCalculating: false,
                    isCalculated: true,
                });
                this.setState({
                    monthlyPremium: response.data.data.premiums.monthly,
                    yearlyPremium: response.data.data.premiums.annual,
                    isBelowMinimum: response.data.data.premiums.is_below_minimum,
                });
                this.props.updateCalculatePremiumData({
                    formData: this.formData,
                    premiumData: response.data.data,
                    partner_slug: this.state.partner_slug,
                    partner_reference: this.state.partner_reference
                });
                this.facebookTag();
                ReactGA.event({
                    category: 'Lead Submission',
                    action: 'Button Click',
                    label: 'Calculate Premium'
                });
                this.gtag_report_conversion()
                try {
                    this.props.addLeadToFirebase(this.formData['mobile'] + '-51', {
                        calculate_premium: this.formData ?? {}
                    })
                } catch (err) {
                    console.log(err)
                }
            })
            .catch((err) => {
                this.setState({
                    isCalculating: false,
                });
                console.log(err);
            });
    }

    notify = (place, title, message, type) => {
        let options = {
            place: place,
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 2,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    facebookTag = () =>{
        window.fbq('track', 'Lead', {
            'mobileNumber': this.state.mobile,
            'yearlyPremium': this.state.yearlyPremium,
            'currency': 'inr',
        });
    }
    gtag() {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(arguments);
    }
    gtag_report_conversion(url) {
            let callback = function () {
            if (typeof(url) != 'undefined') {
            window.location = url;
        }
        };
            this.gtag('event', 'conversion', {
            'send_to': 'AW-808880541/laR8CIKmo80BEJ2T2oED',
            'event_callback': callback
        });
            return false;
        }




    getPercentage() {
        return this.getTwoFixedPoint(
            (((this.state.monthlyPremium) * 12 - (this.state.yearlyPremium)) /
                (this.state.yearlyPremium)) *
            100
        );
    }

    getDifference() {
        return this.getTwoFixedPoint((this.state.monthlyPremium) * 12 - (this.state.yearlyPremium));
    }

    getTwoFixedPoint = (num) => {
        var x = num * 1;
        return x.toFixed(2);
    };


    handleClickOpen = () => {
        if(this.state.sumAssuredLife === "0" && this.state.sumAssuredHealth === "0" && this.state.sumAssuredHouse === "0"){
            this.notify("tc", "", "Please select atleast one sum assured ", "danger");
            return;
        }
        if (localStorage.getItem('token') && localStorage.getItem('mobile') === this.state.mobile) {
            this.calculatePremium();
        } else {
            this.setState(
                {
                    open: true,
                }, () => {
                    this.props.sendOtp({
                        mobile: this.state.mobile,
                        otp_length: 6,
                        type: "user",
                        name: this.state.name,
                        email: this.state.email
                    });
                });
        }

    };

    handleClose = () => {
        this.setState({
            open: false,
        })
    };

    submitOtp = () => {
        API.post("user/verify", {
            otp: this.state.otp,
            type: "user",
            mobile: this.state.mobile,
        })
            .then((res) => {
                localStorage.setItem("token", res.data.data.token);
                localStorage.setItem("mobile", this.state.mobile);
                this.setState({
                    open: false,
                }, () => {
                    this.calculatePremium();
                })
            })
            .catch((err) => {
                console.log(err);
                this.showError("Incorrect OTP.");
            });
    }

    sendOtp() {
        this.props.sendOtp({
            mobile: this.props.mobile,
            otp_length: 6,
            type: "user",
        });

    }

    showError(error) {
        this.notify("bc", "", error, "danger");
        this.props.clearNotification();
    }

    continueToBuy (){
        if(this.state.partner_slug !== null) {
            this.props.history.push('/the-toffee-plan/buy');
        }
        else {
            this.handleThankyouDialog()
        }
    }
    handleThankyouDialog(){
        this.setState({
            openThankyou: true
        })
    }

    closeThankyou(){
        this.setState({
            openThankyou: false
        },()=>{
            window.location.reload()
        });
    }
    render() {
        const {classes} = this.props;
        this.props.error && this.showError(this.props.error);
        this.state.partner_slug= new URL(window.location.href).searchParams.get("partner_slug");
        return (
            <>
                <Box className="rna-wrapper z-index-1" zIndex="tooltip" position="fixed">
                    <NotificationAlert ref="notificationAlert"/>
                </Box>
                <ResponsiveContainer
                    flag={"calculate"}
                    child={
                        <>
                            <Box className={classes.redBg}>
                                {" "}
                                <AppBar elevation={0} position="sticky" style={{padding: "0"}}>
                                    <Toolbar>

                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"
                                            onClick={(e) => {
                                                this.state.currentStep > 0 ? this.prevStep() : window.location.href = process.env.REACT_APP_TOFFEE_INSURANCE_THE_TOFFEE_PLAN;
                                            }}
                                        >
                                            <ArrowBack/>
                                        </IconButton>

                                        <Typography variant="h2" className={classes.title}>
                                            Calculate Premium
                                        </Typography>
                                        {/*<IconButton color="inherit" aria-label="contact" onClick={() => {*/}
                                        {/*    this.props.history.push("/the-toffee-plan");*/}
                                        {/*}}>*/}
                                        {/*    <Close/>*/}
                                        {/*</IconButton>*/}
                                    </Toolbar>
                                </AppBar>
                                <Box position={"relative"} className={classes.whiteCurvedContainer}>
                                    <Box>
                                        {this.state.currentStep === 0 && (
                                            <>
                                                <Box display={"flex"} justifyContent={"center"} pt={3}>
                                                    <Typography variant={"h2"}>Let’s get started!</Typography>
                                                </Box>
                                                <Box p={4} className={classes.formBox}>
                                                    <ChipSelect
                                                        options={this.state.titleOptions}
                                                        selectedItem={this.state.title}
                                                        onChange={(value) => {
                                                            this.selectTitle(value);
                                                        }}
                                                    />
                                                    <form
                                                        className={classes.root}
                                                        noValidate
                                                        autoComplete="off"
                                                    >
                                                        <Box py={2}>
                                                            <TextField
                                                                id="name"
                                                                label={strings.full_name}
                                                                fullWidth
                                                                type="text"
                                                                value={this.state.name}
                                                                inputProps={{maxLength: 30}}
                                                                onChange={(e) => {
                                                                    this.changeInput("name", e.target.value);
                                                                }}
                                                            />
                                                        </Box>
                                                        <Box py={2}>
                                                            <TextField
                                                                id="mobile"
                                                                label={strings.mobile_number}
                                                                fullWidth
                                                                value={this.state.mobile}
                                                                onChange={(e) => {
                                                                    this.changeInput("mobile", e.target.value);
                                                                }}
                                                            />
                                                        </Box>
                                                        <Box py={2}>
                                                            <TextField
                                                                id="email"
                                                                label={strings.email}
                                                                fullWidth
                                                                type="email"
                                                                value={this.state.email}
                                                                onChange={(e) => {
                                                                    this.changeInput("email", e.target.value);
                                                                }}
                                                            />
                                                        </Box>
                                                        <Box py={2}>
                                                            <Typography variant={"h3"}>
                                                                <b>{strings.get_out_of_your_toffee_plan}</b>
                                                            </Typography>
                                                            <Box pt={2} pb={4}>
                                                                <Typography variant={"h4"}>
                                                                    {strings.select_family_members}
                                                                </Typography>
                                                            </Box>

                                                            <Box>
                                                                <ChipDate
                                                                    isSelected={true}
                                                                    date={this.state.dob}
                                                                    label={strings.self}
                                                                    onChange={(type, value) => {
                                                                        this.changeDobChip("dob", type, value);
                                                                    }}
                                                                    maxDate={moment().subtract(18, "years")}
                                                                    minDate={moment()
                                                                        .subtract(56, "years")
                                                                        .add(1, "days")}
                                                                />

                                                                <ChipDate
                                                                    isSelected={this.state.spouse}
                                                                    date={this.state.spouseDob}
                                                                    label={strings.spouse}
                                                                    onChange={(type, value) => {
                                                                        this.changeDobChip(
                                                                            "spouseDob",
                                                                            type,
                                                                            value
                                                                        );
                                                                    }}
                                                                    maxDate={moment().subtract(18, "years")}
                                                                    minDate={moment()
                                                                        .subtract(56, "years")
                                                                        .add(1, "days")}
                                                                />

                                                                <ChipDate
                                                                    isSelected={this.state.child1}
                                                                    date={this.state.child1Dob}
                                                                    label={strings.child_1}
                                                                    onChange={(type, value) => {
                                                                        this.changeDobChip(
                                                                            "child1Dob",
                                                                            type,
                                                                            value
                                                                        );
                                                                    }}
                                                                    maxDate={moment()
                                                                        .subtract(3, "months")}
                                                                    minDate={moment()
                                                                        .subtract(26, "years")
                                                                        .add(1, "days")}
                                                                />

                                                                <ChipDate
                                                                    isSelected={this.state.child2}
                                                                    date={this.state.child2Dob}
                                                                    label={strings.child_2}
                                                                    onChange={(type, value) => {
                                                                        this.changeDobChip(
                                                                            "child2Dob",
                                                                            type,
                                                                            value
                                                                        );
                                                                    }}
                                                                    maxDate={moment()
                                                                        .subtract(3, "months")}
                                                                    minDate={moment()
                                                                        .subtract(26, "years")
                                                                        .add(1, "days")}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </form>
                                                </Box>
                                            </>
                                        )}
                                        {this.state.currentStep === 1 && (
                                            <Box p={4}>
                                                <Box py={2}>
                                                    <Typography variant={"h4"}>
                                                        <b>{strings.coverage_for_health}</b>
                                                    </Typography>
                                                    <ChipSelect
                                                        options={this.state.sumAssuredHealthOptions}
                                                        selectedItem={this.state.sumAssuredHealth}
                                                        onChange={(value) => {
                                                            this.changesumAssuredHealth(value);
                                                        }}
                                                    />
                                                </Box>
                                                <Box py={2}>
                                                    <Typography variant={"h4"}>
                                                        <b>{strings.coverage_for_life}</b>
                                                    </Typography>
                                                    <ChipSelect
                                                        options={this.state.sumAssuredLifeOptions}
                                                        selectedItem={this.state.sumAssuredLife}
                                                        onChange={(value) => {
                                                            this.changesumAssuredLife(value);
                                                        }}
                                                    />
                                                </Box>
                                                <Box py={2}>
                                                    <Typography variant={"h4"}>
                                                        <b>{strings.coverage_for_household}</b>
                                                    </Typography>
                                                    <Box pt={1}>
                                                        <Typography variant={"h4"}>
                                                            {strings.example_repair_of_fridge}
                                                        </Typography>
                                                    </Box>
                                                    <ChipSelect
                                                        options={this.state.sumAssuredHouseOptions}
                                                        selectedItem={this.state.sumAssuredHouse}
                                                        onChange={(value) => {
                                                            this.changesumAssuredHouse(value);
                                                        }}
                                                    />
                                                </Box>
                                                <Grid container direction="row">
                                                    <Grid item md={8} xs={9}>
                                                        <Box py={2}>
                                                            <Typography variant={"h4"}>
                                                                <b>
                                                                    {strings.do_you_currently_smoke}
                                                                </b>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item md={4} xs={3}>
                                                        <Box display="flex" justifyContent="flex-end" p={2}>
                                                            <Switch
                                                                checked={this.state.isConsumingTobacco}
                                                                onChange={(event) => {
                                                                    this.setState(
                                                                        {
                                                                            isConsumingTobacco: event.target.checked,
                                                                        },
                                                                        () => {
                                                                            this.updateFormProps();
                                                                        }
                                                                    );
                                                                }}
                                                                name="tobacco"
                                                                // inputProps={{ "aria-label": "secondary checkbox" }}
                                                                color="primary"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                {this.state.isCalculating && (
                                                    <Box pt={10}>
                                                        <Typography variant="h4" className={classes.center}>
                                                            {strings.calculating_premium}
                                                        </Typography>
                                                        <br/>
                                                        <LinearProgress/>
                                                    </Box>
                                                )}
                                                {!this.state.isCalculating &&
                                                this.state.isCalculated ? (!this.state.isBelowMinimum ? <Box style={{
                                                            backgroundColor: '#fff',
                                                            boxShadow: '0 0 10px 0 rgba(101, 101, 101, 0.3'
                                                        }}>
                                                            <Box display="flex" justifyContent="center" p={4} pb={2}>
                                                                <Typography variant={"h4"} align={"center"}>
                                                                    <b>{strings.your_toffee_payment_is} <span
                                                                        style={{color: '#ff585d'}}>{strings.just} </span></b>
                                                                </Typography>
                                                            </Box>
                                                            <Box display="flex" justifyContent="center"
                                                                 className={classes.content} pb={3}>
                                                                <Paper elevation={3} className={classes.paper}>
                                                                    <Box className={classes.textBox}>
                                                                        <Box pb={0} mt={0} mb={0}>
                                                                            <Typography variant="h6">
                                                                                <b>{strings.annually}</b>
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box className={classes.delText}>
                                                                            <Typography variant="h6"
                                                                                        className={classes.fontLight}>
                                                                                <del>₹ {this.getTwoFixedPoint(this.state.monthlyPremium * 12)}</del>
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box pb={0} mt={0} mb={0}>
                                                                            <Typography variant="h3">
                                                                                <b>₹ {this.state.yearlyPremium}</b>
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box>
                                                                            <Typography variant="h6"
                                                                                        className={classes.saveText}>
                                                                                Save {this.getPercentage()}% <b>₹ {this.getDifference()}</b>
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Paper>
                                                                <Divider orientation={"vertical"} flexItem/>
                                                                <Paper elevation={3} className={classes.paper}>
                                                                    <Box className={classes.textBox}>
                                                                        <Box pb={0} mt={0} mb={0}>
                                                                            <Typography variant="h6">
                                                                                <b>{strings.monthly}</b>
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box className={classes.delText}>
                                                                            <Typography variant="h6"
                                                                                        className={classes.fontLight}>
                                                                                ₹ {this.getTwoFixedPoint(this.state.monthlyPremium * 12)}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box pb={0} mt={0} mb={0}>
                                                                            <Typography variant="h3">
                                                                                <b>₹ {this.state.monthlyPremium}</b>
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Paper>
                                                            </Box>
                                                            <Box display="flex" justifyContent="center" pb={4}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={(e) => {
                                                                        this.setState(
                                                                            {
                                                                                isCalculated: false,
                                                                                calOpenDialog: false,
                                                                            }, () => {
                                                                                this.continueToBuy()
                                                                            });

                                                                    }}
                                                                    className={classes.prmBtn}
                                                                >
                                                                    <b>{strings.continue_to_buy}</b>
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                        :
                                                        <Box py={2}>
                                                            <Box>
                                                                <Typography variant={"h4"} color="primary">
                                                                    <b>{strings.please_select_higher_sum}</b>
                                                                </Typography>
                                                            </Box>
                                                            <Box display="flex" justifyContent="center" py={4}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.prmBtn}
                                                                >
                                                                    <b>{strings.next}</b>
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    )
                                                    : (
                                                        <Box display="flex" justifyContent="center" pb={2} pt={5}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={(e) => {
                                                                    this.handleClickOpen();
                                                                }}
                                                                hidden={this.state.isCalculating}
                                                            >
                                                                {this.state.monthlyPremium ? (
                                                                    <b>{strings.recalculate_premium}</b>
                                                                ) : (
                                                                    <b>{strings.calculate_premium}</b>
                                                                )}
                                                            </Button>
                                                        </Box>)
                                                }
                                            </Box>
                                        )}
                                        {this.state.currentStep < 1 && (
                                            <Box display={"flex"} justifyContent={"flex-end"} pb={4} pr={2}>
                                                <Fab
                                                    aria-label={"Next"}
                                                    className={classes.fab}
                                                    color={"primary"}
                                                    onClick={(e) => {
                                                        this.nextStep();
                                                    }}
                                                >
                                                    <ArrowForward/>
                                                </Fab>
                                            </Box>
                                        )}
                                    </Box>

                                    <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title"
                                            open={this.state.open}>
                                        <DialogTitle id="simple-dialog-title">{strings.verification}</DialogTitle>
                                        <Box m={"auto"}>
                                            <Typography variant={"h4"} align={"center"}>{strings.otp_sent_to}
                                                +91-{this.state.mobile}</Typography>
                                        </Box>
                                        <Box ml={2} mr={2}>
                                            <Otp
                                                otpCallBack={(otp) => {
                                                    this.setState({
                                                        otp: otp,
                                                    });
                                                }}
                                                resendOtp={() => {
                                                    this.sendOtp();
                                                }}
                                            />
                                        </Box>
                                        <Box m={2} mt={0} display={"flex"} justifyContent={"center"}>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                color="primary"
                                                onClick={(e) => {
                                                    this.submitOtp()
                                                }}
                                            >
                                                {strings.proceed}
                                            </Button>
                                        </Box>
                                    </Dialog>

                                    <Dialog onClose={this.closeThankyou} aria-labelledby="simple-dialog-title"
                                            open={this.state.openThankyou}>
                                        <DialogTitle id="simple-dialog-title">Thank you</DialogTitle>
                                        <DialogContent>
                                        <Box m={"auto"} p={4}>
                                            <Typography variant={"h4"} align={"center"}>
                                                Thank you for showing your interest,<br/> our team will reach out to you shortly.
                                            </Typography>
                                        </Box>
                                        </DialogContent>
                                        <DialogActions>
                                            <Box m={2} mt={0} display={"flex"} justifyContent={"center"}>
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        this.closeThankyou()
                                                    }}
                                                >
                                                    Close
                                                </Button>
                                            </Box>
                                        </DialogActions>
                                    </Dialog>
                                </Box>
                            </Box>
                        </>
                    }
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        otpVerified: state.auth.otpVerified,
        verifyingOtp: state.auth.verifyingOtp,
        mobile: state.auth.mobile,
        token: state.auth.token,
        error: state.auth.error,
        otpSent: state.auth.otpSent,
        sendingOtp: state.auth.sendOtpLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
        verifyOtp: (data) => dispatch(actions.postVerifyOtp(data)),
        checkIfLoggedIn: () => dispatch(actions.checkIfLoggedIn()),
        sendOtp: (data) => dispatch(actions.postSendOtp(data)),
        clearNotification: (data) =>
            dispatch({
                type: actionTypes.POST_SENDOTP_TYPES_FAIL,
                error: null,
            }),
        updateCalculatePremiumData: (data) =>
            dispatch({
                type: actionTypes.UPDATE_CALCULATE_PREMIUM_DATA,
                data: data,
            }),
        addLeadToFirebase: (doc, data) => dispatch(actions.captureFirebaseLead(doc, data))
    };
};

export default withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(CalculatePremiumBuyflow)
);
