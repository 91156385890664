import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles, Grid, Chip, TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const useStyles = (theme) => ({
  activeChip: {
    backgroundColor: "rgba(255, 88, 93, .2)",
    borderRadius: "4px",
    color: "#ff585d",
  },
  chip: {
    backgroundColor: "rgba(34, 51, 79, .1)",
    borderRadius: "4px",
    color: "#22334f",
  },
});

class ChipSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: this.props.selectedItem ?? "",
      options: this.props.options ?? [],
    };
  }
  selectItem(item) {
    this.setState(
      {
        selectedItem: item.value,
      },
      () => {
        this.props.onChange(item.value);
      }
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <Box p={2}>
        <Typography>{this.props.title}</Typography>
        <Grid container spacing={1}>
          {this.state.options.map((item) => {
            return (
              <Grid item>
                <Chip
                  label={item.title}
                  onClick={(e) => {
                    this.selectItem(item);
                  }}
                  className={
                    this.state.selectedItem === item.value
                      ? classes.activeChip
                      : classes.chip
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(ChipSelect);
