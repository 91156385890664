import React, { Component } from "react";
import queryString from "query-string";
import Cookies from "js-cookie";
import API from "../../../utilities/api";
import {strings} from "../../../languages/localization";

class Auth extends Component {
  componentDidMount() {
    console.log(this.props.location);
    const params = queryString.parse(this.props.location.search);

    API.post("web/auth/resume", {
      id: params.id,
      token: params.token,
    })
      .then((response) => {
        console.log(response.data.data.access_token);
        // Cookies.set("token", response.data.data.access_token);
        localStorage.setItem("token", response.data.data.access_token);
        window.location.href = params.redirect_url;
      })
      .catch((err) => {
        // this.props.onCallShowLoader(false)
      });
  }

  render() {
    return (
      <div className="d-flex justify-content-center vh-100 align-items-center">
        <h5>{strings.logging_in}</h5>
      </div>
    );
  }
}
// const mapDispatchToProps = dispatch => {
//     return {
//         onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag))
//     };
// };
// export default connect(null, mapDispatchToProps)(Feedback);
export default Auth;
