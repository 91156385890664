import {Keys} from "./keys";
import {Strings} from "./strings";
import {QuestionType} from "../utilities/questionBuilder/questionTypes";
import {TextInputType} from "../utilities/questionBuilder/textInputType";
import {InputValidator} from "../utilities/questionBuilder/inputValidator";

export const PolicyDetails = {
    data: {
        key: Keys.policyDetails,
        body: Strings.personalDetails,
        subtitle: Strings.pleaseFill,
        questions: [
            {
                type: QuestionType.text,
                key: Keys.policyTenure,
                placeholder: Strings.policyTenure,
                validation: [InputValidator.required, InputValidator.numeric],
                textInputType: TextInputType.number,
                initial: 40,
                products: {
                    59:  [true],
                    52:  [true],
                    48:  [true],
                }
            },
            {
                type: QuestionType.text,
                key: Keys.paymentTenure,
                placeholder: Strings.paymentTenure,
                validation: [InputValidator.required],
                textInputType: TextInputType.number,
                products: {
                    52:  [true],
                    48:  [true],
                }
            },
            {
                type: QuestionType.text,
                key: Keys.limitedPayFor,
                placeholder: Strings.limitedPayFor,
                validation: [InputValidator.required],
                textInputType: TextInputType.number,
                products:{
                    // 48:  [true],
                }
            },
            {
                type: QuestionType.text,
                key: Keys.paymentFrequency,
                placeholder: Strings.paymentFrequency,
                validation: [InputValidator.required],
                textInputType: TextInputType.number,
                products: {
                    52:  [true],
                    48:  [true],
                }
            },
            {
                type: QuestionType.text,
                key: Keys.deathBenefitPayout,
                placeholder: Strings.deathBenefitPayout,
                validation: [InputValidator.required],
                textInputType: TextInputType.number,
                products: {
                    52:  [true],
                    48:  [true],
                }
            },
            {
                type: QuestionType.toggle,
                validation: [InputValidator.required],
                key: Keys.lifePlusOption,
                placeholder: Strings.lifePlusOption,
                initial: false,
                products: {
                    59: [true],
                }
            },
            {
                type: QuestionType.toggle,
                validation: [InputValidator.required],
                key: Keys.returnOfPremium,
                hasSubQuestions: true,
                placeholder: Strings.returnOfPremium,
                initial: false,
                products: {
                    59: [true]
                }
            },
            {
                type: QuestionType.choice,
                validation: [InputValidator.required],
                expand: {
                    [Keys.returnOfPremium]: [true]
                },
                key: Keys.percentageOfPremium,
                placeholder: Strings.percentageOfPremium,
                inputs: [
                    {
                        value: Keys.fiftyPercent,
                        label: Strings.fiftyPercent,
                    },
                    {
                        value: Keys.hundredPercent,
                        label: Strings.hundredPercent,
                    }
                ],
                products: {
                    59: [true]
                }
            },
            {
                type: QuestionType.toggle,
                validation: [InputValidator.required],
                key: Keys.criticalIllnessCover,
                hasSubQuestions: true,
                placeholder: Strings.criticalIllnessCover,
                initial: false,
                products: {
                    59: [true]
                }
            },
            {
                type: QuestionType.choice,
                validation: [InputValidator.required],
                expand: {
                    [Keys.criticalIllnessCover]: [true]
                },
                key: Keys.criticalIllnessSumAssured,
                placeholder: Strings.criticalIllnessSumAssured,
                inputs: [
                    {
                        value: Keys.tenPercent,
                        label:Strings.tenPercent,
                    },
                    {
                        value: Keys.twentyPercent,
                        label:Strings.twentyPercent,
                    },
                    {
                        value: Keys.thirtyPercent,
                        label:Strings.thirtyPercent,
                    },
                    {
                        value: Keys.fortyPercent,
                        label:Strings.fortyPercent,
                    },
                    {
                        value: Keys.fiftyPercent,
                        label:Strings.fiftyPercent,
                    }
                ],
                products: {
                    59: [true]
                }
            },
            {
                // type: QuestionType.dropdown,
                type: QuestionType.text,
                key: Keys.policyTenure,
                placeholder: Strings.policyTenure,
                validation: [InputValidator.required],
                inputs: [],
                products: {
                    59: [true]
                }
            },
            {
                type: QuestionType.choice,
                placeholder: Strings.typeOfPremiumPayment,
                key: Keys.typeOfPremiumPayment,
                validation: [InputValidator.required],
                initial: Keys.singlePremium,
                inputs: [
                    {value: Keys.limitedPremium, label: Strings.limitedPremium},
                    {value: Keys.singlePremium, label: Strings.singlePremium},
                ],
                products: {
                    59: [true]
                }
            },
            {
                // type: QuestionType.dropdown,
                type: QuestionType.text,
                key: Keys.premiumPaymentTerm,
                placeholder: Strings.premiumPaymentTerm,
                validation: [InputValidator.required],
                inputs: [],
                products: {
                    59: [true]
                }
            },
            {
                type: QuestionType.choice,
                placeholder: Strings.sumAssuredHealth,
                key: Keys.payoutOption,
                validation: [InputValidator.required],
                initial: Keys.lumpsumAndLevelIncome,
                inputs: [
                    {value: Keys.lumpsumPayout, label: Strings.lumpsumPayout,},
                    {value: Keys.lumpsumAndLevelIncome, label: Strings.lumpsumAndLevelIncome,},
                    {value: Keys.lumpsumAndIncreasingIncome, label: Strings.lumpsumAndIncreasingIncome,},
                ],
                products: {
                    59: [true]
                }
            }
        ],
    },
};
