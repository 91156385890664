import {createStyles} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    root: {
        display: "flex",
        // flexWrap: "wrap",
        "& > *": {

            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(1),
                width: theme.spacing(16),
                height: theme.spacing(16),
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(3),
                marginLeft: theme.spacing(6),
                marginRight: theme.spacing(6),
                width: theme.spacing(16),
                height: theme.spacing(16),
            },
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(4),
                marginLeft: theme.spacing(6),
                marginRight: theme.spacing(6),
                width: theme.spacing(16),
                height: theme.spacing(16),
            },
        },
    },
    paymentCardSelected: {
        backgroundColor: "#ffffff",
        boxShadow: '0 0 16px 0 rgba(192, 192, 192, 0.5)',
        [theme.breakpoints.down('sm')]: {
            width: "150px",
            height: "auto",
            borderRadius: "12px",
        },
        [theme.breakpoints.up('sm')]: {
            width: "200px",
            height: "200px",
            borderRadius: "30px",
        },
        [theme.breakpoints.up('md')]: {
            width: "200px",
            height: "200px",
            borderRadius: "30px",
        },
    },
    paymentCard: {
        backgroundColor: "#ffffff",
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.11)',
        [theme.breakpoints.down('sm')]: {
            width: "150px",
            height: "auto",
            borderRadius: "12px",
        },
        [theme.breakpoints.up('sm')]: {
            width: "200px",
            height: "200px",
            borderRadius: "30px",
        },
        [theme.breakpoints.up('md')]: {
            width: "200px",
            height: "200px",
            borderRadius: "30px",
        },
    },
    rdbtn: {
        display: "flex",
        justifyContent: "start",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "15px",
            paddingTop: "20px",
            paddingBottom: "10px"
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "25px",
            paddingTop: "25px",
            paddingBottom: "5px"
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: "25px",
            paddingTop: "20px",
            paddingBottom: "2px"
        },
    },
    paperImages: {
        [theme.breakpoints.down('sm')]: {
            width: '25px',
            height: '25px',
            textAlign: 'justify'
        },
        [theme.breakpoints.up('sm')]: {
            width: '30px',
            height: '30px',
            textAlign: 'justify'
        },
        [theme.breakpoints.up('md')]: {
            width: '30px',
            height: '30px',
            textAlign: 'justify'
        },
    },
    textBox: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "20px",
            paddingRight: "20px",
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "15px",
            paddingRight: "25px",
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: "15px",
            paddingLeft: "25px",
            paddingRight: "25px",
        },
    },
    delText:{
        [theme.breakpoints.down('sm')]: {
            marginTop: "5px",
            marginBottom: "0px",
            paddingBottom: "0px",
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: "10px",
            marginBottom: "0px",
            paddingBottom: "0px",
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "10px",
            marginBottom: "10px",
            paddingBottom: "0px",
        },
    },
    saveText:{
        [theme.breakpoints.down('sm')]: {
            marginTop: "0px",
            marginBottom: "0px",
            paddingBottom: "0px",
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: "5px",
            marginBottom: "0px",
            paddingBottom: "0px",
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: "5px",
            marginBottom: "0px",
            paddingBottom: "0px",
        },
    },
    fontLight: {
        fontWeight: "200",
    },
    content: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
});