import React, {useEffect, useReducer, useState} from 'react';
import {useHistory} from "react-router";

import MaterialAppBar from "../../../components/Headers/MaterialAppBar";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import {Spinner} from "reactstrap";
import ResponsiveContainer from "../../../components/ResponsiveContainer/responsiveContainer";
import {useGetApi} from '../../../hooks/useApi';
import {ReducerUtils} from '../../../constants/reducers';
import QuotationReducer from '../../../reducers/quotation';
import RazorpayPayment from '../../../components/Payment/razorpay';
import {useStyles} from '../../../styles/buyStyles/productBuyPaymentStyles'
import PolicyCard from "../../../components/Cards/policyCards";
import {Typography} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {ProductDetails, ReferenceMapping} from "../../../mapping/product";

const ProductBuyPayment = (props) => {
    const {classes} = props;
    const quotationDetails = useGetApi(`orders/${props.match.params.id}`);
    const [state, dispatch] = useReducer(QuotationReducer, {quotationDetails: null, razorPayInitialization: null});
    const [isTCChecked, setIsTCChecked] = useState(false);

    useEffect(() => {
        dispatchEvent(ReducerUtils.quotation.details, quotationDetails);
    }, [quotationDetails]);

    const dispatchEvent = (type, payload) => {
        dispatch({type, payload});
    };

    const handleChange = (event) => {
        setIsTCChecked(event.target.checked);
    };

    return (
        <ResponsiveContainer
            flag={"userFlow"}
            child={
                <>
                    <MaterialAppBar/>
                    {state.quotationDetails ?
                        <Box id="mainBlock" className={classes.main}>
                            <Box className={classes.cardBox}>
                                {1 === 2 && <Box className={classes.toffeeCard}>
                                    <PolicyCard
                                        insuranceType={ProductDetails[ReferenceMapping[props.match.params.product]].insuranceType}
                                        startDate={state.quotationDetails.start_date}
                                        endDate={state.quotationDetails.end_date}
                                        proposalNumber={props.match.params.id}
                                        name={state.quotationDetails.name}
                                        email={state.quotationDetails.email}
                                    />
                                </Box>}
                                <Box className={classes.content}>
                                    <Box my={1}>
                                    <Typography className={classes.contentTextTitle}>
                                        Grab
                                        your {ProductDetails[ReferenceMapping[props.match.params.product]].insuranceType}
                                    </Typography>
                                    </Box>
                                    <Box my={1}>
                                    <Typography className={classes.contentText}>
                                        Complete your purchase at our secure payment gateway.
                                    </Typography>
                                    </Box>
                                    <Box my={1}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isTCChecked}
                                                onChange={handleChange}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Typography className={classes.terms}>
                                                I agree to the&nbsp;
                                                <span
                                                    style={{color: 'red', cursor: "pointer"}}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        window.open(`${process.env.REACT_APP_SITE_URL}terms`)
                                                    }}>
                                                    Terms and conditions
                                                </span>
                                                &nbsp;of {ProductDetails[ReferenceMapping[props.match.params.product]].insuranceType}
                                            </Typography>
                                        }
                                    />
                                    </Box>
                                </Box>
                            </Box>
                            <Box m={2}>
                                <RazorpayPayment
                                    entity={'order'}
                                    entity_id={props.match.params.id}
                                    is_monthly={false}
                                    is_recurring={false}
                                    quotationDetails={state.quotationDetails}
                                    redirectUrl={`/products/${props.match.params.product}/buy/${props.match.params.id}/confirmation`}
                                    take_first_payment={false}
                                    is_tc_checked={isTCChecked}
                                />
                            </Box>
                        </Box> :
                        <Box className={classes.spinner}>
                            <Spinner/>
                        </Box>}
                </>
            }
        />
    );
};

export default withStyles(useStyles, {withTheme: true})(ProductBuyPayment);