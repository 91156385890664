import {Strings} from "./strings";
import {QuestionType} from "../utilities/questionBuilder/questionTypes";
import {Keys} from "./keys";
import {InputValidator} from "../utilities/questionBuilder/inputValidator";
import {Products} from "./products";

export const Qualification = {
    data: {
        key: Keys.qualificationInfo,
        body: Strings.provideEducationOccupationDetails,
        title: Strings.provideEducationOccupationDetails,
        questions: [
            {
                type: QuestionType.choice,
                validation: [InputValidator.required],
                key: Keys.education,
                placeholder: Strings.highestEducation,
                inputs: [
                    {value: Keys.postGraduate, label: Strings.postGraduate},
                    {value: Keys.graduate, label: Strings.graduate},
                    {value: Keys.diploma, label: Strings.diploma},
                    {value: Keys.seniorSecondary, label: Strings.seniorSecondary},
                    {value: Keys.highSecondary, label: Strings.highSecondary},
                    {value: Keys.belowHighSecondary, label: Strings.belowHighSecondary},
                ],
                products: {
                    52: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.choice,
                validation: [InputValidator.required],
                key: Keys.occupation,
                placeholder: Strings.currentOccupation,
                hasSubQuestions: true,
                inputs: [
                    {
                        value: Keys.salaried, label: Strings.salaried, products: {
                            48: [true],
                            52: [true],
                            53: [true],
                            54: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.selfEmployed, label: Strings.selfEmployed, products: {
                            48: [true],
                            52: [true],
                            53: [true],
                            54: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.retired, label: Strings.retired, products: {
                            52: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.professional, label: Strings.professional, products: {
                            48: [true],
                            52: [true],
                            53: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.others, label: Strings.others, products: {
                            48: [true],
                            52: [true],
                            53: [true],
                            54: [true],
                            58: [true]
                        },
                    },
                ],
                products: {
                    48: [true],
                    52: [true],
                    53: [true],
                    54: [true],
                    58: [true]
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.occupationDescription,
                key: Keys.occupationDescription,
                validations: [InputValidator.required],
                expand: {
                    [Keys.occupation]: [Keys.others]
                },
                products: {
                    48: [true]
                },
            },
            {
                type: QuestionType.choice,
                validation: [InputValidator.required],
                expand: {
                    [Keys.occupation]: [Keys.selfEmployed]
                },
                key: Keys.selfEmployedR,
                placeholder: Strings.currentlySelfEmployedAs,
                inputs: [
                    {
                        value: Keys.architect, label: Strings.architect, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.businessman, label: Strings.businessman, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.ca, label: Strings.ca, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.consultant, label: Strings.consultant, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.doctor, label: Strings.doctor, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.lawyer, label: Strings.lawyer, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.other, label: Strings.other, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                ],
                products: {
                    52: [true],
                    48: [true],
                    58: [true]
                },
            },
            {
                type: QuestionType.choice,
                validation: [InputValidator.required],
                expand: {
                    [Keys.occupation]: [Keys.professional]
                },
                key: Keys.profession,
                placeholder: Strings.yourProfession,
                inputs: [
                    {
                        value: Keys.doctor, label: Strings.doctor, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.ca, label: Strings.ca, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.lawyer, label: Strings.lawyer, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.other, label: Strings.other, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                ],
                products: {
                    52: [true],
                    48: [true],
                    58: [true]
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.nameOfOrganization,
                key: Keys.organizationName,
                validation: [InputValidator.required, InputValidator.orgName],
                expand: {
                    [Keys.occupation]: [Keys.salaried, Keys.selfEmployed, Keys.others]
                },
                products: {
                    52: [true],
                    48: [true],
                    58: [true]
                },
            },
            {
                type: QuestionType.choice,
                validation: [InputValidator.required],
                key: Keys.organizationType,
                placeholder: Strings.organizationType,
                expand: {
                    [Keys.occupation]: [Keys.salaried, Keys.others]
                },
                inputs: [
                    {
                        value: Keys.govt, label: Strings.govt, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.pvtLtd, label: Strings.pvtLtd, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.publicLtd, label: Strings.publicLtd, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.proprietor, label: Strings.proprietor, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.partnership, label: Strings.partnership, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.society, label: Strings.society, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.trust, label: Strings.trust, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.others, label: Strings.others, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                ],
                products: {
                    52: [true],
                    48: [true],
                    58: [true]
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.organizationTypeDetails,
                key: Keys.organizationTypeDetails,
                validations: [InputValidator.required],
                expand: {
                    [Keys.organizationType]: [Keys.others]
                },
                products: {
                    48: [true]
                }
            },
            {
                type: QuestionType.choice,
                validation: [InputValidator.required],
                key: Keys.organizationTypeSelf,
                placeholder: Strings.organizationType,
                expand: {
                    [Keys.occupation]: [Keys.selfEmployed]
                },
                inputs: [
                    {
                        value: Keys.proprietor, label: Strings.proprietor, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.partnership, label: Strings.partnership, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                    {
                        value: Keys.others, label: Strings.others, products: {
                            52: [true],
                            48: [true],
                            58: [true]
                        },
                    },
                ],
                products: {
                    52: [true],
                    48: [true],
                    58: [true]
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.organizationTypeDetails,
                key: Keys.organizationTypeSelfDetails,
                validations: [InputValidator.required],
                expand: {
                    [Keys.organizationTypeSelf]: [Keys.others]
                },
                products: {
                    48: [true]
                }
            },
            {
                type: QuestionType.toggle,
                key: Keys.engagedIn,
                validations: [InputValidator.required],
                placeholder: Strings.engaged,
                hasSubQuestions: true,
                expand: {
                    [Keys.occupation]: [Keys.salaried, Keys.others, Keys.selfEmployed]
                },
                products: {
                    48: [true],
                },
            },
            {
                type: QuestionType.choice,
                validations: [InputValidator.required],
                key: Keys.industryType,
                placeholder: Strings.industryType,
                expand: {
                    [Keys.engagedIn]: [true]
                },
                inputs: [
                    {value: Keys.jewl, label: Strings.jewellery},
                    {value: Keys.impexp, label: Strings.importExport},
                    {value: Keys.shipping, label: Strings.shipping},
                    {value: Keys.scrp, label: Strings.scrapDealing},
                    {value: Keys.realEstate, label: Strings.realEstate},
                    {value: Keys.stock, label: Strings.stockBroking},
                ],
                products: {
                    48: [true],
                },
            },
            {
                type: QuestionType.text,
                validation: [InputValidator.required],
                key: Keys.natureOfDuties,
                placeholder: Strings.natureOfDuties,
                products: {
                    58: [true]
                }
            },
            {
                type: QuestionType.text,
                placeholder: Strings.panNumber,
                key: Keys.panR,
                validation: [InputValidator.required, InputValidator.pan],
                expand: {
                    [Keys.occupation]: [Keys.salaried, Keys.selfEmployed, Keys.retired, Keys.professional, Keys.others]
                },
                products: {
                    52: [true],
                    48: [true],
                    58: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.sourceFund,
                validation: [InputValidator.required],
                placeholder: Strings.sourceIncome,
                hasSubQuestions: true,
                inputs: [
                    {
                        value: Keys.salary, label: Strings.salary, products: {
                            52: [true],
                            54: [true],
                            48: [true]
                        },
                    },
                    {
                        value: Keys.businessIncome, label: Strings.businessIncome, products: {
                            52: [true],
                            54: [true],
                            48: [true]
                        },
                    },
                    {
                        value: Keys.saleOfAssets, label: Strings.saleOfAssets, products: {
                            52: [true],
                            48: [true]
                        },
                    },
                    {
                        value: Keys.inheritance, label: Strings.inheritance, products: {
                            52: [true],
                            48: [true]
                        },
                    },
                    {
                        value: Keys.others, label: Strings.others, products: {
                            52: [true],
                            48: [true]
                        },
                    },
                ],
                products: {
                    52: [true],
                    54: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.text,
                validation:
                    [
                        InputValidator.required, InputValidator.numeric, InputValidator.salaryIncome,
                    ],
                key: Keys.annualIncomeSalary,
                placeholder: Strings.income,
                expand: {
                    [Keys.sourceFund]: [Keys.salary]
                },
                products: {
                    52: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.text,
                validation:
                    [
                        InputValidator.required, InputValidator.numeric, InputValidator.nonSalaryIncome,
                    ],
                key: Keys.annualIncomeNonSalary,
                placeholder: Strings.income,
                expand: {
                    [Keys.sourceFund]: [Keys.businessIncome, Keys.saleOfAssets, Keys.inheritance, Keys.others]
                },
                products: {
                    52: [true],
                    48: [true]
                },
            },

            {
                type: QuestionType.text,
                placeholder: Strings.sourceIncomeDetails,
                key: Keys.sourceFundDetails,
                validations: [InputValidator.required],
                expand: {
                    [Keys.sourceFund]: [Keys.others]
                },
                products: {
                    48: [true]
                }
            },

            {
                type: QuestionType.toggle,
                key: Keys.lifeInsuranceDetail,
                placeholder: Strings.lifeInsuranceDetail,
                hasSubQuestions: true,
                expand: {
                    [Keys.occupation]: [Keys.housewife, Keys.student]
                },
                products: {
                    48: [true],
                }
            },
            {
                type: QuestionType.text,
                placeholder: Strings.lifeInsuranceDetailText,
                key: Keys.lifeInsuranceDetailText,
                validations: [InputValidator.required],
                expand: {
                    [Keys.lifeInsuranceDetail]: [true]
                },
                products: {
                    48: [true],
                }
            },
            {
                type: QuestionType.toggle,
                key: Keys.lifeInsuranceOtherThanIpru,
                placeholder: Strings.lifeInsuranceOtherThanIpru,
                validations: [InputValidator.required],
                hasSubQuestions: true,
                products: {
                    48: [true],
                }
            },
            {
                type: QuestionType.dropdown,
                key: Keys.insuranceCompany,
                placeholder: Strings.insuranceCompany,
                validations: [
                    InputValidator.required
                ],
                inputs: [
                    {value: Keys.aegonReligare, label: Strings.aegonReligare},
                    {value: Keys.avivaLife, label: Strings.avivaLife},
                    {value: Keys.bajajAllianz, label: Strings.bajajAllianz},
                    {value: Keys.bhartiAxa, label: Strings.bhartiAxa},
                    {value: Keys.birlaSun, label: Strings.birlaSun},
                    {value: Keys.canaraHsbc, label: Strings.canaraHsbc},
                    {value: Keys.dlfPramerica, label: Strings.dlfPramerica},
                    {value: Keys.edelwiessTokio, label: Strings.edelwiessTokio},
                    {value: Keys.futureGenerali, label: Strings.futureGenerali},
                    {value: Keys.hdfcStandard, label: Strings.hdfcStandard},
                    {value: Keys.idbiFortis, label: Strings.idbiFortis},
                    {value: Keys.indiaFirst, label: Strings.indiaFirst},
                    {value: Keys.ingVysya, label: Strings.ingVysya},
                    {value: Keys.kotakLife, label: Strings.kotakLife},
                    {value: Keys.lifeInsurance, label: Strings.lifeInsurance},
                    {value: Keys.maxNew, label: Strings.maxNew},
                    {value: Keys.metLifeIndia, label: Strings.metLifeIndia},
                    {value: Keys.postalLife, label: Strings.postalLife},
                    {value: Keys.relianceLife, label: Strings.relianceLife},
                    {value: Keys.saharaIndia, label: Strings.saharaIndia},
                    {value: Keys.sbiLife, label: Strings.sbiLife},
                    {value: Keys.shriramLife, label: Strings.shriramLife},
                    {value: Keys.starUnion, label: Strings.starUnion},
                    {value: Keys.tataAig, label: Strings.tataAig},
                    {value: Keys.unitTrust, label: Strings.unitTrust},
                    {value: Keys.tataAia, label: Strings.tataAia},
                ],
                expand: {
                    [Keys.lifeInsuranceOtherThanIpru]: [true]
                },
                products: {
                    48: [true],
                }
            },
            {
                type: QuestionType.text,
                key: Keys.entireLifeCoverOrSumAssured,
                placeholder: Strings.entireLifeCoverOrSumAssured,
                validations: [
                    InputValidator.required,
                    InputValidator.numeric,
                ],
                expand: {
                    [Keys.lifeInsuranceOtherThanIpru]: [true]
                },
                products: {
                    48: [true],
                }
            },
            {
                type: QuestionType.dropdown,
                key: Keys.insuranceCompany2,
                placeholder: Strings.insuranceCompany,
                validations: [],
                inputs: [
                    {value: Keys.aegonReligare, label: Strings.aegonReligare},
                    {value: Keys.avivaLife, label: Strings.avivaLife},
                    {value: Keys.bajajAllianz, label: Strings.bajajAllianz},
                    {value: Keys.bhartiAxa, label: Strings.bhartiAxa},
                    {value: Keys.birlaSun, label: Strings.birlaSun},
                    {value: Keys.canaraHsbc, label: Strings.canaraHsbc},
                    {value: Keys.dlfPramerica, label: Strings.dlfPramerica},
                    {value: Keys.edelwiessTokio, label: Strings.edelwiessTokio},
                    {value: Keys.futureGenerali, label: Strings.futureGenerali},
                    {value: Keys.hdfcStandard, label: Strings.hdfcStandard},
                    {value: Keys.idbiFortis, label: Strings.idbiFortis},
                    {value: Keys.indiaFirst, label: Strings.indiaFirst},
                    {value: Keys.ingVysya, label: Strings.ingVysya},
                    {value: Keys.kotakLife, label: Strings.kotakLife},
                    {value: Keys.lifeInsurance, label: Strings.lifeInsurance},
                    {value: Keys.maxNew, label: Strings.maxNew},
                    {value: Keys.metLifeIndia, label: Strings.metLifeIndia},
                    {value: Keys.postalLife, label: Strings.postalLife},
                    {value: Keys.relianceLife, label: Strings.relianceLife},
                    {value: Keys.saharaIndia, label: Strings.saharaIndia},
                    {value: Keys.sbiLife, label: Strings.sbiLife},
                    {value: Keys.shriramLife, label: Strings.shriramLife},
                    {value: Keys.starUnion, label: Strings.starUnion},
                    {value: Keys.tataAig, label: Strings.tataAig},
                    {value: Keys.unitTrust, label: Strings.unitTrust},
                    {value: Keys.tataAia, label: Strings.tataAia},
                ],
                expand: {
                    [Keys.lifeInsuranceOtherThanIpru]: [true]
                },
                products: {
                    48: [true],
                }
            },
            {
                type: QuestionType.text,
                key: Keys.entireLifeCoverOrSumAssured2,
                placeholder: Strings.entireLifeCoverOrSumAssured,
                validations: [InputValidator.numeric],
                expand: {
                    [Keys.lifeInsuranceOtherThanIpru]: [true]
                },
                products: {
                    48: [true],
                }
            },
            {
                type: QuestionType.toggle,
                key: Keys.electronicInsuranceR,
                placeholder: Strings.electronicInsurance,
                validations: [InputValidator.required],
                hasSubQuestions: true,
                initial: false,
                products: {
                    48: [true],
                }
            },
            {
                type: QuestionType.dropdown,
                key: Keys.eiaRepositoryR,
                placeholder: Strings.eiaRepository,
                validations: [InputValidator.required],
                inputs: [
                    {value: Keys.ndml, label: Strings.ndml},
                    {value: Keys.cirl, label: Strings.cirl},
                    {value: Keys.karvy, label: Strings.karvy},
                    {value: Keys.cams, label: Strings.cams}
                ],
                expand: {
                    [Keys.electronicInsuranceR]: [false]
                },
                products: {
                    48: [true],
                }
            },
            {
                type: QuestionType.text,
                key: Keys.eiaAccountNumberR,
                placeholder: Strings.eiaAccountNumber,
                validations: [
                    InputValidator.required,
                    InputValidator.numeric

                ],
                expand: {
                    [Keys.electronicInsuranceR]: [true]
                },
                products: {
                    48: [true],
                }
            },
        ],
    },
};
