import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {Typography, withStyles} from "@material-ui/core";
import CommonQuestions from "../../views/pages/Landing/commonQuestions";

const useStyles = (theme) => ({
    mainContainer: {
        maxWidth: "600px",
        margin: "auto",

        [theme.breakpoints.up("sm")]: {
            maxWidth: "960px",
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: "1140px",
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "1290px",
            paddingLeft: "0",
            paddingRight: "0",
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: "1290px",
            paddingLeft: "0",
            paddingRight: "0",
        },
        [theme.breakpoints.up(1441)]: {
            maxWidth: "1440px",
            paddingLeft: "0",
            paddingRight: "0",
        },
    },
    mainContainerUser: {
        margin: "auto",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "600px",
        },
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: "100%",
            paddingLeft: "0",
            paddingRight: "0",
        },
        [theme.breakpoints.up("lg")]: {
            width: "100%",
            paddingLeft: "0",
            paddingRight: "0",
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: "1290px",
            paddingLeft: "0",
            paddingRight: "0",
        },
        [theme.breakpoints.up(1441)]: {
            maxWidth: "1440px",
            paddingLeft: "0",
            paddingRight: "0",
        },
    },
    mainContainerFullWidth: {
        margin: "auto",
    }
});

class ResponsiveContainer extends Component {
    render() {
        const {classes} = this.props;
        return (
            <>
                {(this.props.flag === 'landing') && (
                    <Grid item container direction="column">
                        <Grid item container>
                            <Grid item xs={12}>
                                <Box
                                    className={classes.mainContainer}>{this.props.child}</Box>
                            </Grid>
                        </Grid>
                    </Grid>)}
                    {(!this.props.flag) && (
                    <Grid item container direction="column">
                        <Grid item container>
                            <Grid item xs={12}>
                                <Box
                                    className={classes.mainContainer}>{this.props.child}</Box>
                            </Grid>
                        </Grid>
                    </Grid>)}
                {(this.props.flag === 'userFlow') && (
                    <Grid item container direction="column">
                        <Grid item container>
                            {/*<Grid item xs={false} md={3}/>*/}
                            <Grid item xs={12} >
                                <Box
                                    className={classes.mainContainerUser}>{this.props.child}</Box>
                            </Grid>
                            {/*<Grid item xs={false} md={3}/>*/}
                        </Grid>
                    </Grid>)}
                {(this.props.flag === 'calculate') && (
                    <Grid item container direction="column">
                        <Grid item container>
                            <Grid item xs={false} md={3}/>
                            <Grid item xs={12} md={6}>
                                <Box className={classes.mainContainer}>{this.props.child}</Box>
                            </Grid>
                            <Grid item xs={false} md={3}/>
                        </Grid>
                    </Grid>)}
                {(this.props.flag === 'buyFlow') && (
                    <Grid item container direction="column">
                        <Grid item container>
                            <Grid item xs={12}>
                                <Box
                                    className={classes.mainContainerFullWidth}>{this.props.child}</Box>
                            </Grid>
                        </Grid>
                    </Grid>)}
            </>
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(ResponsiveContainer);
