import React, {Component} from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";

const useStyles = (theme) => ({
    root: {
        height: 180,
    },
    card: {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 100,
        backgroundColor: theme.palette.primary.main,
        color: '#142550',
        textAlign: "center",
        minHeight: 412,

    },
    cardMobile: {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 100,
        backgroundColor: theme.palette.primary.main,
        color: '#142550',
        textAlign: "center",
        height: 400,

    },
    title: {
        fontWeight: "bold",
        [theme.breakpoints.down('sm')]: {
            fontSize: "24px"
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "24px"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "36px",
            textAlign: "center"
        },
    },
    topContent: {
        [theme.breakpoints.down('sm')]: {
            width: "280px"
        },
        [theme.breakpoints.up('sm')]: {
            width: "280px"
        },
        [theme.breakpoints.up('md')]: {
            // width: "460px",
            width: "548px",
            margin: "auto"
        },
    },
    subDescBox:{
        [theme.breakpoints.down('sm')]: {
            paddingTop: "30px",
            paddingBottom: "30px"
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: "30px",
            paddingBottom: "30px"
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: "30px",
            paddingBottom: "60px"
        },
    },
    subDesc:{
        [theme.breakpoints.down('sm')]: {
            textAlign: "center"
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: "center"
        },
        [theme.breakpoints.up('md')]: {
            textAlign: "justify"
        },
    },
    subDescCenterAlign:{
        textAlign: "center"
    }
});

class CurvedBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDesktop: window.innerWidth > 600,
        }
    }
    render() {
        const {classes} = this.props;
        const isDesktop = this.state.isDesktop;
        return (
            <Box
                // className={isDesktop ? classes.card :(this.props.checked ? classes.cardMobile : classes.card)}
                className={classes.card}
                p={5}
            >
                <Box display={"flex"} justifyContent={"center"} flexWrap="wrap">
                    <Box pb={6} className={classes.topContent}>
                        <Typography className={classes.title} gutterBottom>
                            {this.props.title}
                        </Typography>
                        <Typography>{this.props.desc}</Typography>
                        {this.props.subDesc &&
                        <Box className={classes.subDescBox}>
                            <Typography variant={"h5"} className={this.props.centerAlign ? classes.subDescCenterAlign : classes.subDesc}>{this.props.subDesc}</Typography>
                        </Box>
                        }
                    </Box>
                    <Box>
                        {isDesktop ? this.props.img :(this.props.checked ? null : this.props.img)}
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(CurvedBox);
