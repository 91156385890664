export class IndiaFirstLocation {
    get(pincode) {
        return this.code[pincode];
    }

    code = {
        '110001': ['Delhi', 'Delhi'],
        '110002': ['Delhi', 'Delhi'],
        '110003': ['Delhi', 'Delhi'],
        '110004': ['Delhi', 'Delhi'],
        '110005': ['Delhi', 'Delhi'],
        '110006': ['Delhi', 'Delhi'],
        '110007': ['Delhi', 'Delhi'],
        '110008': ['Delhi', 'Delhi'],
        '110009': ['Delhi', 'Delhi'],
        '110010': ['Delhi', 'Delhi'],
        '110011': ['Delhi', 'Delhi'],
        '110012': ['Delhi', 'Delhi'],
        '110013': ['Delhi', 'Delhi'],
        '110014': ['Delhi', 'Delhi'],
        '110015': ['Delhi', 'Delhi'],
        '110016': ['Delhi', 'Delhi'],
        '110017': ['Delhi', 'Delhi'],
        '110018': ['Delhi', 'Delhi'],
        '110019': ['Delhi', 'Delhi'],
        '110020': ['Delhi', 'Delhi'],
        '110021': ['Delhi', 'Delhi'],
        '110022': ['Delhi', 'Delhi'],
        '110023': ['Delhi', 'Delhi'],
        '110024': ['Delhi', 'Delhi'],
        '110025': ['Delhi', 'Delhi'],
        '110026': ['Delhi', 'Delhi'],
        '110027': ['Delhi', 'Delhi'],
        '110028': ['Delhi', 'Delhi'],
        '110029': ['Delhi', 'Delhi'],
        '110030': ['Delhi', 'Delhi'],
        '110031': ['Delhi', 'Delhi'],
        '110032': ['Delhi', 'Delhi'],
        '110033': ['Delhi', 'Delhi'],
        '110034': ['Delhi', 'Delhi'],
        '110035': ['Delhi', 'Delhi'],
        '110036': ['Delhi', 'Delhi'],
        '110037': ['Delhi', 'Delhi'],
        '110038': ['Delhi', 'Delhi'],
        '110039': ['Delhi', 'Delhi'],
        '110040': ['Delhi', 'Delhi'],
        '110041': ['Delhi', 'Delhi'],
        '110042': ['Delhi', 'Delhi'],
        '110043': ['Delhi', 'Delhi'],
        '110044': ['Delhi', 'Delhi'],
        '110045': ['Delhi', 'Delhi'],
        '110046': ['Delhi', 'Delhi'],
        '110047': ['Delhi', 'Delhi'],
        '110048': ['Delhi', 'Delhi'],
        '110049': ['Delhi', 'Delhi'],
        '110050': ['Delhi', 'Delhi'],
        '110051': ['Delhi', 'Delhi'],
        '110052': ['Delhi', 'Delhi'],
        '110053': ['Delhi', 'Delhi'],
        '110054': ['Delhi', 'Delhi'],
        '110055': ['Delhi', 'Delhi'],
        '110056': ['Delhi', 'Delhi'],
        '110057': ['Delhi', 'Delhi'],
        '110058': ['Delhi', 'Delhi'],
        '110059': ['Delhi', 'Delhi'],
        '110060': ['Delhi', 'Delhi'],
        '110061': ['Delhi', 'Delhi'],
        '110062': ['Delhi', 'Delhi'],
        '110063': ['Delhi', 'Delhi'],
        '110064': ['Delhi', 'Delhi'],
        '110065': ['Delhi', 'Delhi'],
        '110066': ['Delhi', 'Delhi'],
        '110067': ['Delhi', 'Delhi'],
        '110068': ['Delhi', 'Delhi'],
        '110069': ['Delhi', 'Delhi'],
        '110070': ['Delhi', 'Delhi'],
        '110071': ['Delhi', 'Delhi'],
        '110072': ['Delhi', 'Delhi'],
        '110073': ['Delhi', 'Delhi'],
        '110074': ['Delhi', 'Delhi'],
        '110075': ['Delhi', 'Delhi'],
        '110076': ['Delhi', 'Delhi'],
        '110077': ['Delhi', 'Delhi'],
        '110078': ['Delhi', 'Delhi'],
        '110080': ['Delhi', 'Delhi'],
        '110081': ['Delhi', 'Delhi'],
        '110082': ['Delhi', 'Delhi'],
        '110083': ['Delhi', 'Delhi'],
        '110084': ['Delhi', 'Delhi'],
        '110085': ['Delhi', 'Delhi'],
        '110086': ['Delhi', 'Delhi'],
        '110087': ['Delhi', 'Delhi'],
        '110088': ['Delhi', 'Delhi'],
        '110089': ['Delhi', 'Delhi'],
        '110090': ['Delhi', 'Delhi'],
        '110091': ['Delhi', 'Delhi'],
        '110092': ['Delhi', 'Delhi'],
        '110093': ['Delhi', 'Delhi'],
        '110094': ['Delhi', 'Delhi'],
        '110095': ['Delhi', 'Delhi'],
        '110096': ['Delhi', 'Delhi'],
        '110097': ['Delhi', 'Delhi'],
        '110098': ['Delhi', 'Delhi'],
        '110100': ['Delhi', 'Delhi'],
        '121001': ['Faridabad', 'Haryana'],
        '121002': ['Faridabad', 'Haryana'],
        '121003': ['Faridabad', 'Haryana'],
        '121004': ['Faridabad', 'Haryana'],
        '121005': ['Faridabad', 'Haryana'],
        '121006': ['Faridabad', 'Haryana'],
        '121007': ['Faridabad', 'Haryana'],
        '121008': ['Faridabad', 'Haryana'],
        '121009': ['Faridabad', 'Haryana'],
        '121010': ['Faridabad', 'Haryana'],
        '121012': ['Faridabad', 'Haryana'],
        '121013': ['Faridabad', 'Haryana'],
        '121014': ['Ambala', 'Haryana'],
        '121015': ['Ambala', 'Haryana'],
        '121101': ['Faridabad', 'Haryana'],
        '121102': ['Faridabad', 'Haryana'],
        '121103': ['Faridabad', 'Haryana'],
        '121104': ['Faridabad', 'Haryana'],
        '121105': ['Faridabad', 'Haryana'],
        '121106': ['Faridabad', 'Haryana'],
        '121107': ['Faridabad', 'Haryana'],
        '122001': ['Gurgaon', 'Haryana'],
        '122002': ['Gurgaon', 'Haryana'],
        '122003': ['Gurgaon', 'Haryana'],
        '122004': ['Gurgaon', 'Haryana'],
        '122005': ['Gurgaon', 'Haryana'],
        '122006': ['Gurgaon', 'Haryana'],
        '122007': ['Gurgaon', 'Haryana'],
        '122008': ['Gurgaon', 'Haryana'],
        '122009': ['Gurgaon', 'Haryana'],
        '122010': ['Gurgaon', 'Haryana'],
        '122011': ['Gurgaon', 'Haryana'],
        '122012': ['Ambala', 'Haryana'],
        '122015': ['Gurgaon', 'Haryana'],
        '122016': ['Gurgaon', 'Haryana'],
        '122017': ['Gurgaon', 'Haryana'],
        '122018': ['Gurgaon', 'Haryana'],
        '122051': ['Gurgaon', 'Haryana'],
        '122052': ['Gurgaon', 'Haryana'],
        '122098': ['Ambala', 'Haryana'],
        '122101': ['Gurgaon', 'Haryana'],
        '122102': ['Gurgaon', 'Haryana'],
        '122103': ['Gurgaon', 'Haryana'],
        '122104': ['Gurgaon', 'Haryana'],
        '122105': ['Gurgaon', 'Haryana'],
        '122107': ['Gurgaon', 'Haryana'],
        '122108': ['Gurgaon', 'Haryana'],
        '122413': ['Gurgaon', 'Haryana'],
        '122414': ['Gurgaon', 'Haryana'],
        '122502': ['Gurgaon', 'Haryana'],
        '122503': ['Gurgaon', 'Haryana'],
        '122504': ['Gurgaon', 'Haryana'],
        '122505': ['Gurgaon', 'Haryana'],
        '122506': ['Gurgaon', 'Haryana'],
        '122508': ['Gurgaon', 'Haryana'],
        '123031': ['Ambala', 'Haryana'],
        '123106': ['Gurgaon', 'Haryana'],
        '123413': ['Gurgaon', 'Haryana'],
        '123414': ['Gurgaon', 'Haryana'],
        '123502': ['Gurgaon', 'Haryana'],
        '123503': ['Gurgaon', 'Haryana'],
        '123504': ['Gurgaon', 'Haryana'],
        '123505': ['Gurgaon', 'Haryana'],
        '123506': ['Gurgaon', 'Haryana'],
        '127311': ['Ambala', 'Haryana'],
        '127312': ['Ambala', 'Haryana'],
        '131001': ['Sonipat', 'Haryana'],
        '131021': ['Sonipat', 'Haryana'],
        '131022': ['Sonipat', 'Haryana'],
        '131023': ['Sonipat', 'Haryana'],
        '131024': ['Sonipat', 'Haryana'],
        '131027': ['Sonipat', 'Haryana'],
        '131028': ['Sonipat', 'Haryana'],
        '131029': ['Sonipat', 'Haryana'],
        '131039': ['Sonipat', 'Haryana'],
        '131101': ['Sonipat', 'Haryana'],
        '131102': ['Sonipat', 'Haryana'],
        '131103': ['Sonipat', 'Haryana'],
        '131301': ['Sonipat', 'Haryana'],
        '131302': ['Sonipat', 'Haryana'],
        '131304': ['Sonipat', 'Haryana'],
        '131305': ['Sonipat', 'Haryana'],
        '131306': ['Sonipat', 'Haryana'],
        '131402': ['Sonipat', 'Haryana'],
        '131403': ['Sonipat', 'Haryana'],
        '131408': ['Sonipat', 'Haryana'],
        '131409': ['Sonipat', 'Haryana'],
        '132001': ['Karnal', 'Haryana'],
        '132020': ['Kurukshetra', 'Haryana'],
        '132021': ['Kurukshetra', 'Haryana'],
        '132022': ['Karnal', 'Haryana'],
        '132023': ['Karnal', 'Haryana'],
        '132024': ['Karnal', 'Haryana'],
        '132025': ['Karnal', 'Haryana'],
        '132026': ['Kurukshetra', 'Haryana'],
        '132027': ['Kurukshetra', 'Haryana'],
        '132034': ['Kurukshetra', 'Haryana'],
        '132035': ['Kurukshetra', 'Haryana'],
        '132036': ['Karnal', 'Haryana'],
        '132037': ['Karnal', 'Haryana'],
        '132038': ['Karnal', 'Haryana'],
        '132039': ['Karnal', 'Haryana'],
        '132040': ['Karnal', 'Haryana'],
        '132041': ['Karnal', 'Haryana'],
        '132042': ['Kurukshetra', 'Haryana'],
        '132043': ['Kurukshetra', 'Haryana'],
        '132046': ['Karnal', 'Haryana'],
        '132054': ['Karnal', 'Haryana'],
        '132101': ['Karnal', 'Haryana'],
        '132103': ['Karnal', 'Haryana'],
        '132104': ['Karnal', 'Haryana'],
        '132105': ['Karnal', 'Haryana'],
        '132108': ['Karnal', 'Haryana'],
        '132113': ['Karnal', 'Haryana'],
        '132114': ['Karnal', 'Haryana'],
        '132115': ['Karnal', 'Haryana'],
        '132116': ['Karnal', 'Haryana'],
        '132117': ['Karnal', 'Haryana'],
        '132118': ['Kurukshetra', 'Haryana'],
        '132119': ['Kurukshetra', 'Haryana'],
        '132124': ['Karnal', 'Haryana'],
        '132128': ['Karnal', 'Haryana'],
        '132129': ['Kurukshetra', 'Haryana'],
        '132130': ['Kurukshetra', 'Haryana'],
        '132131': ['Kurukshetra', 'Haryana'],
        '132132': ['Kurukshetra', 'Haryana'],
        '132133': ['Kurukshetra', 'Haryana'],
        '132135': ['Kurukshetra', 'Haryana'],
        '132136': ['Kurukshetra', 'Haryana'],
        '132137': ['Kurukshetra', 'Haryana'],
        '132140': ['Karnal', 'Haryana'],
        '132145': ['Karnal', 'Haryana'],
        '132153': ['Karnal', 'Haryana'],
        '132154': ['Karnal', 'Haryana'],
        '132157': ['Karnal', 'Haryana'],
        '133001': ['Ambala', 'Haryana'],
        '133004': ['Ambala', 'Haryana'],
        '133005': ['Ambala', 'Haryana'],
        '133006': ['Ambala', 'Haryana'],
        '133008': ['Ambala', 'Haryana'],
        '133010': ['Ambala', 'Haryana'],
        '133021': ['Ambala', 'Haryana'],
        '133101': ['Ambala', 'Haryana'],
        '133102': ['Ambala', 'Haryana'],
        '133104': ['Ambala', 'Haryana'],
        '133105': ['Ambala', 'Haryana'],
        '133201': ['Ambala', 'Haryana'],
        '133202': ['Ambala', 'Haryana'],
        '133203': ['Ambala', 'Haryana'],
        '133204': ['Ambala', 'Haryana'],
        '133205': ['Ambala', 'Haryana'],
        '133206': ['Ambala', 'Haryana'],
        '133207': ['Ambala', 'Haryana'],
        '133301': ['Panchkula', 'Haryana'],
        '133302': ['Panchkula', 'Haryana'],
        '134002': ['Ambala', 'Haryana'],
        '134003': ['Ambala', 'Haryana'],
        '134005': ['Ambala', 'Haryana'],
        '134007': ['Ambala', 'Haryana'],
        '134008': ['Ambala', 'Haryana'],
        '134011': ['Ambala', 'Haryana'],
        '134012': ['Ambala', 'Haryana'],
        '134101': ['Panchkula', 'Haryana'],
        '134102': ['Panchkula', 'Haryana'],
        '134103': ['Panchkula', 'Haryana'],
        '134104': ['Panchkula', 'Haryana'],
        '134105': ['Ambala', 'Haryana'],
        '134107': ['Panchkula', 'Haryana'],
        '134108': ['Panchkula', 'Haryana'],
        '134109': ['Panchkula', 'Haryana'],
        '134112': ['Panchkula', 'Haryana'],
        '134113': ['Panchkula', 'Haryana'],
        '134114': ['Panchkula', 'Haryana'],
        '134116': ['Panchkula', 'Haryana'],
        '134117': ['Panchkula', 'Haryana'],
        '134118': ['Panchkula', 'Haryana'],
        '134201': ['Ambala', 'Haryana'],
        '134202': ['Panchkula', 'Haryana'],
        '134203': ['Ambala', 'Haryana'],
        '134204': ['Panchkula', 'Haryana'],
        '134205': ['Panchkula', 'Haryana'],
        '135001': ['Ambala', 'Haryana'],
        '135002': ['Ambala', 'Haryana'],
        '135003': ['Ambala', 'Haryana'],
        '135021': ['Ambala', 'Haryana'],
        '135101': ['Ambala', 'Haryana'],
        '135102': ['Ambala', 'Haryana'],
        '135103': ['Ambala', 'Haryana'],
        '135105': ['Ambala', 'Haryana'],
        '136030': ['Kurukshetra', 'Haryana'],
        '136038': ['Kurukshetra', 'Haryana'],
        '136118': ['Kurukshetra', 'Haryana'],
        '136119': ['Kurukshetra', 'Haryana'],
        '136128': ['Kurukshetra', 'Haryana'],
        '136129': ['Kurukshetra', 'Haryana'],
        '136130': ['Kurukshetra', 'Haryana'],
        '136131': ['Kurukshetra', 'Haryana'],
        '136132': ['Kurukshetra', 'Haryana'],
        '136135': ['Kurukshetra', 'Haryana'],
        '136136': ['Kurukshetra', 'Haryana'],
        '136156': ['Kurukshetra', 'Haryana'],
        '140103': ['Mohali', 'Punjab'],
        '140109': ['Mohali', 'Punjab'],
        '140110': ['Mohali', 'Punjab'],
        '140112': ['Mohali', 'Punjab'],
        '140119': ['Chandigarh', 'Chandigarh'],
        '140125': ['Chandigarh', 'Chandigarh'],
        '140201': ['Patiala', 'Punjab'],
        '140307': ['Mohali', 'Punjab'],
        '140401': ['Patiala', 'Punjab'],
        '140402': ['Patiala', 'Punjab'],
        '140405': ['Patiala', 'Punjab'],
        '140406': ['Patiala', 'Punjab'],
        '140407': ['Patiala', 'Punjab'],
        '140408': ['Chandigarh', 'Chandigarh'],
        '140412': ['Patiala', 'Punjab'],
        '140413': ['Mohali', 'Punjab'],
        '140417': ['Patiala', 'Punjab'],
        '140501': ['Mohali', 'Punjab'],
        '140506': ['Patiala', 'Punjab'],
        '140507': ['Mohali', 'Punjab'],
        '140601': ['Patiala', 'Punjab'],
        '140602': ['Patiala', 'Punjab'],
        '140603': ['Patiala', 'Punjab'],
        '140604': ['Mohali', 'Punjab'],
        '140701': ['Patiala', 'Punjab'],
        '140702': ['Patiala', 'Punjab'],
        '140801': ['Ludhiana', 'Punjab'],
        '140802': ['Patiala', 'Punjab'],
        '140901': ['Mohali', 'Punjab'],
        '141001': ['Ludhiana', 'Punjab'],
        '141002': ['Ludhiana', 'Punjab'],
        '141003': ['Ludhiana', 'Punjab'],
        '141004': ['Ludhiana', 'Punjab'],
        '141005': ['Ludhiana', 'Punjab'],
        '141006': ['Ludhiana', 'Punjab'],
        '141007': ['Ludhiana', 'Punjab'],
        '141008': ['Ludhiana', 'Punjab'],
        '141010': ['Ludhiana', 'Punjab'],
        '141012': ['Ludhiana', 'Punjab'],
        '141013': ['Ludhiana', 'Punjab'],
        '141014': ['Ludhiana', 'Punjab'],
        '141015': ['Ludhiana', 'Punjab'],
        '141016': ['Ludhiana', 'Punjab'],
        '141017': ['Ludhiana', 'Punjab'],
        '141101': ['Ludhiana', 'Punjab'],
        '141102': ['Ludhiana', 'Punjab'],
        '141103': ['Ludhiana', 'Punjab'],
        '141104': ['Ludhiana', 'Punjab'],
        '141105': ['Ludhiana', 'Punjab'],
        '141106': ['Ludhiana', 'Punjab'],
        '141107': ['Ludhiana', 'Punjab'],
        '141108': ['Ludhiana', 'Punjab'],
        '141109': ['Ludhiana', 'Punjab'],
        '141110': ['Ludhiana', 'Punjab'],
        '141112': ['Ludhiana', 'Punjab'],
        '141113': ['Ludhiana', 'Punjab'],
        '141114': ['Ludhiana', 'Punjab'],
        '141115': ['Ludhiana', 'Punjab'],
        '141116': ['Ludhiana', 'Punjab'],
        '141117': ['Ludhiana', 'Punjab'],
        '141118': ['Ludhiana', 'Punjab'],
        '141119': ['Ludhiana', 'Punjab'],
        '141120': ['Ludhiana', 'Punjab'],
        '141121': ['Ludhiana', 'Punjab'],
        '141122': ['Ludhiana', 'Punjab'],
        '141123': ['Ludhiana', 'Punjab'],
        '141124': ['Ludhiana', 'Punjab'],
        '141125': ['Ludhiana', 'Punjab'],
        '141126': ['Ludhiana', 'Punjab'],
        '141127': ['Ludhiana', 'Punjab'],
        '141201': ['Ludhiana', 'Punjab'],
        '141202': ['Ludhiana', 'Punjab'],
        '141203': ['Ludhiana', 'Punjab'],
        '141204': ['Ludhiana', 'Punjab'],
        '141205': ['Ludhiana', 'Punjab'],
        '141206': ['Ludhiana', 'Punjab'],
        '141401': ['Ludhiana', 'Punjab'],
        '141402': ['Ludhiana', 'Punjab'],
        '141411': ['Patiala', 'Punjab'],
        '141412': ['Ludhiana', 'Punjab'],
        '141413': ['Ludhiana', 'Punjab'],
        '141414': ['Ludhiana', 'Punjab'],
        '141415': ['Ludhiana', 'Punjab'],
        '141416': ['Ludhiana', 'Punjab'],
        '141417': ['Ludhiana', 'Punjab'],
        '141418': ['Ludhiana', 'Punjab'],
        '141419': ['Ludhiana', 'Punjab'],
        '141421': ['Ludhiana', 'Punjab'],
        '141422': ['Ludhiana', 'Punjab'],
        '142021': ['Ludhiana', 'Punjab'],
        '142022': ['Ludhiana', 'Punjab'],
        '142023': ['Ludhiana', 'Punjab'],
        '142024': ['Ludhiana', 'Punjab'],
        '142025': ['Ludhiana', 'Punjab'],
        '142026': ['Ludhiana', 'Punjab'],
        '142027': ['Ludhiana', 'Punjab'],
        '142028': ['Ludhiana', 'Punjab'],
        '142029': ['Ludhiana', 'Punjab'],
        '142030': ['Ludhiana', 'Punjab'],
        '142031': ['Ludhiana', 'Punjab'],
        '142032': ['Ludhiana', 'Punjab'],
        '142033': ['Ludhiana', 'Punjab'],
        '142034': ['Ludhiana', 'Punjab'],
        '142035': ['Ludhiana', 'Punjab'],
        '142036': ['Ludhiana', 'Punjab'],
        '143001': ['Amritsar', 'Punjab'],
        '143002': ['Amritsar', 'Punjab'],
        '143003': ['Amritsar', 'Punjab'],
        '143004': ['Amritsar', 'Punjab'],
        '143005': ['Amritsar', 'Punjab'],
        '143006': ['Amritsar', 'Punjab'],
        '143008': ['Amritsar', 'Punjab'],
        '143009': ['Amritsar', 'Punjab'],
        '143021': ['Amritsar', 'Punjab'],
        '143022': ['Amritsar', 'Punjab'],
        '143036': ['Amritsar', 'Punjab'],
        '143101': ['Amritsar', 'Punjab'],
        '143102': ['Amritsar', 'Punjab'],
        '143103': ['Amritsar', 'Punjab'],
        '143104': ['Amritsar', 'Punjab'],
        '143105': ['Amritsar', 'Punjab'],
        '143106': ['Amritsar', 'Punjab'],
        '143107': ['Amritsar', 'Punjab'],
        '143108': ['Amritsar', 'Punjab'],
        '143109': ['Amritsar', 'Punjab'],
        '143110': ['Amritsar', 'Punjab'],
        '143111': ['Amritsar', 'Punjab'],
        '143112': ['Amritsar', 'Punjab'],
        '143113': ['Amritsar', 'Punjab'],
        '143114': ['Amritsar', 'Punjab'],
        '143115': ['Amritsar', 'Punjab'],
        '143116': ['Amritsar', 'Punjab'],
        '143117': ['Amritsar', 'Punjab'],
        '143118': ['Amritsar', 'Punjab'],
        '143119': ['Amritsar', 'Punjab'],
        '143149': ['Amritsar', 'Punjab'],
        '143201': ['Amritsar', 'Punjab'],
        '143202': ['Amritsar', 'Punjab'],
        '143203': ['Amritsar', 'Punjab'],
        '143204': ['Amritsar', 'Punjab'],
        '143205': ['Amritsar', 'Punjab'],
        '143301': ['Amritsar', 'Punjab'],
        '143302': ['Amritsar', 'Punjab'],
        '143303': ['Amritsar', 'Punjab'],
        '143304': ['Amritsar', 'Punjab'],
        '143305': ['Amritsar', 'Punjab'],
        '143401': ['Amritsar', 'Punjab'],
        '143402': ['Amritsar', 'Punjab'],
        '143406': ['Amritsar', 'Punjab'],
        '143407': ['Amritsar', 'Punjab'],
        '143408': ['Amritsar', 'Punjab'],
        '143409': ['Amritsar', 'Punjab'],
        '143410': ['Amritsar', 'Punjab'],
        '143411': ['Amritsar', 'Punjab'],
        '143412': ['Amritsar', 'Punjab'],
        '143413': ['Amritsar', 'Punjab'],
        '143414': ['Amritsar', 'Punjab'],
        '143415': ['Amritsar', 'Punjab'],
        '143416': ['Amritsar', 'Punjab'],
        '143417': ['Amritsar', 'Punjab'],
        '143418': ['Amritsar', 'Punjab'],
        '143419': ['Amritsar', 'Punjab'],
        '143422': ['Amritsar', 'Punjab'],
        '143501': ['Amritsar', 'Punjab'],
        '143502': ['Amritsar', 'Punjab'],
        '143504': ['Amritsar', 'Punjab'],
        '143601': ['Amritsar', 'Punjab'],
        '143603': ['Amritsar', 'Punjab'],
        '143606': ['Amritsar', 'Punjab'],
        '144001': ['Jalandhar', 'Punjab'],
        '144002': ['Jalandhar', 'Punjab'],
        '144003': ['Jalandhar', 'Punjab'],
        '144004': ['Jalandhar', 'Punjab'],
        '144005': ['Jalandhar', 'Punjab'],
        '144006': ['Jalandhar', 'Punjab'],
        '144007': ['Jalandhar', 'Punjab'],
        '144008': ['Jalandhar', 'Punjab'],
        '144009': ['Jalandhar', 'Punjab'],
        '144010': ['Jalandhar', 'Punjab'],
        '144011': ['Jalandhar', 'Punjab'],
        '144012': ['Jalandhar', 'Punjab'],
        '144013': ['Jalandhar', 'Punjab'],
        '144014': ['Jalandhar', 'Punjab'],
        '144020': ['Jalandhar', 'Punjab'],
        '144021': ['Jalandhar', 'Punjab'],
        '144022': ['Jalandhar', 'Punjab'],
        '144023': ['Jalandhar', 'Punjab'],
        '144024': ['Jalandhar', 'Punjab'],
        '144025': ['Jalandhar', 'Punjab'],
        '144026': ['Jalandhar', 'Punjab'],
        '144027': ['Jalandhar', 'Punjab'],
        '144028': ['Jalandhar', 'Punjab'],
        '144029': ['Jalandhar', 'Punjab'],
        '144030': ['Jalandhar', 'Punjab'],
        '144031': ['Jalandhar', 'Punjab'],
        '144032': ['Jalandhar', 'Punjab'],
        '144033': ['Jalandhar', 'Punjab'],
        '144034': ['Jalandhar', 'Punjab'],
        '144035': ['Jalandhar', 'Punjab'],
        '144036': ['Jalandhar', 'Punjab'],
        '144037': ['Jalandhar', 'Punjab'],
        '144039': ['Jalandhar', 'Punjab'],
        '144040': ['Jalandhar', 'Punjab'],
        '144041': ['Jalandhar', 'Punjab'],
        '144042': ['Jalandhar', 'Punjab'],
        '144043': ['Jalandhar', 'Punjab'],
        '144044': ['Jalandhar', 'Punjab'],
        '144101': ['Jalandhar', 'Punjab'],
        '144102': ['Jalandhar', 'Punjab'],
        '144103': ['Jalandhar', 'Punjab'],
        '144104': ['Jalandhar', 'Punjab'],
        '144106': ['Jalandhar', 'Punjab'],
        '144201': ['Jalandhar', 'Punjab'],
        '144301': ['Jalandhar', 'Punjab'],
        '144302': ['Jalandhar', 'Punjab'],
        '144303': ['Jalandhar', 'Punjab'],
        '144311': ['Jalandhar', 'Punjab'],
        '144403': ['Jalandhar', 'Punjab'],
        '144405': ['Jalandhar', 'Punjab'],
        '144407': ['Jalandhar', 'Punjab'],
        '144409': ['Jalandhar', 'Punjab'],
        '144410': ['Jalandhar', 'Punjab'],
        '144415': ['Jalandhar', 'Punjab'],
        '144416': ['Jalandhar', 'Punjab'],
        '144417': ['Jalandhar', 'Punjab'],
        '144418': ['Jalandhar', 'Punjab'],
        '144419': ['Jalandhar', 'Punjab'],
        '144422': ['Jalandhar', 'Punjab'],
        '144502': ['Jalandhar', 'Punjab'],
        '144503': ['Jalandhar', 'Punjab'],
        '144504': ['Jalandhar', 'Punjab'],
        '144505': ['Jalandhar', 'Punjab'],
        '144506': ['Jalandhar', 'Punjab'],
        '144508': ['Jalandhar', 'Punjab'],
        '144509': ['Jalandhar', 'Punjab'],
        '144510': ['Jalandhar', 'Punjab'],
        '144511': ['Jalandhar', 'Punjab'],
        '144512': ['Jalandhar', 'Punjab'],
        '144513': ['Jalandhar', 'Punjab'],
        '144514': ['Jalandhar', 'Punjab'],
        '144515': ['Jalandhar', 'Punjab'],
        '144516': ['Jalandhar', 'Punjab'],
        '144517': ['Jalandhar', 'Punjab'],
        '144603': ['Jalandhar', 'Punjab'],
        '144623': ['Jalandhar', 'Punjab'],
        '144629': ['Jalandhar', 'Punjab'],
        '144630': ['Jalandhar', 'Punjab'],
        '144633': ['Jalandhar', 'Punjab'],
        '144701': ['Jalandhar', 'Punjab'],
        '144702': ['Jalandhar', 'Punjab'],
        '144703': ['Jalandhar', 'Punjab'],
        '144801': ['Jalandhar', 'Punjab'],
        '144805': ['Jalandhar', 'Punjab'],
        '144806': ['Jalandhar', 'Punjab'],
        '147001': ['Patiala', 'Punjab'],
        '147002': ['Patiala', 'Punjab'],
        '147003': ['Patiala', 'Punjab'],
        '147004': ['Patiala', 'Punjab'],
        '147005': ['Patiala', 'Punjab'],
        '147006': ['Patiala', 'Punjab'],
        '147007': ['Patiala', 'Punjab'],
        '147021': ['Patiala', 'Punjab'],
        '147101': ['Patiala', 'Punjab'],
        '147102': ['Patiala', 'Punjab'],
        '147103': ['Patiala', 'Punjab'],
        '147104': ['Patiala', 'Punjab'],
        '147105': ['Patiala', 'Punjab'],
        '147111': ['Patiala', 'Punjab'],
        '147201': ['Patiala', 'Punjab'],
        '147202': ['Patiala', 'Punjab'],
        '147203': ['Patiala', 'Punjab'],
        '147301': ['Patiala', 'Punjab'],
        '152020': ['Chandigarh', 'Chandigarh'],
        '160001': ['Chandigarh', 'Chandigarh'],
        '160002': ['Chandigarh', 'Chandigarh'],
        '160003': ['Chandigarh', 'Chandigarh'],
        '160004': ['Chandigarh', 'Chandigarh'],
        '160005': ['Chandigarh', 'Chandigarh'],
        '160008': ['Chandigarh', 'Chandigarh'],
        '160009': ['Chandigarh', 'Chandigarh'],
        '160010': ['Chandigarh', 'Chandigarh'],
        '160011': ['Chandigarh', 'Chandigarh'],
        '160012': ['Chandigarh', 'Chandigarh'],
        '160014': ['Chandigarh', 'Chandigarh'],
        '160015': ['Chandigarh', 'Chandigarh'],
        '160016': ['Chandigarh', 'Chandigarh'],
        '160017': ['Chandigarh', 'Chandigarh'],
        '160018': ['Chandigarh', 'Chandigarh'],
        '160019': ['Chandigarh', 'Chandigarh'],
        '160020': ['Chandigarh', 'Chandigarh'],
        '160022': ['Chandigarh', 'Chandigarh'],
        '160023': ['Chandigarh', 'Chandigarh'],
        '160025': ['Chandigarh', 'Chandigarh'],
        '160026': ['Chandigarh', 'Chandigarh'],
        '160030': ['Chandigarh', 'Chandigarh'],
        '160031': ['Chandigarh', 'Chandigarh'],
        '160036': ['Chandigarh', 'Chandigarh'],
        '160043': ['Chandigarh', 'Chandigarh'],
        '160047': ['Chandigarh', 'Chandigarh'],
        '160055': ['Chandigarh', 'Chandigarh'],
        '160059': ['Chandigarh', 'Chandigarh'],
        '160061': ['Chandigarh', 'Chandigarh'],
        '160101': ['Chandigarh', 'Chandigarh'],
        '160102': ['Chandigarh', 'Chandigarh'],
        '160103': ['Mohali', 'Punjab'],
        '160104': ['Mohali', 'Punjab'],
        '160106': ['Chandigarh', 'Chandigarh'],
        '171001': ['Shimla', 'Himachal Pradesh'],
        '171002': ['Shimla', 'Himachal Pradesh'],
        '171003': ['Shimla', 'Himachal Pradesh'],
        '171004': ['Shimla', 'Himachal Pradesh'],
        '171005': ['Shimla', 'Himachal Pradesh'],
        '171006': ['Shimla', 'Himachal Pradesh'],
        '171007': ['Shimla', 'Himachal Pradesh'],
        '171008': ['Shimla', 'Himachal Pradesh'],
        '171009': ['Shimla', 'Himachal Pradesh'],
        '171010': ['Shimla', 'Himachal Pradesh'],
        '171011': ['Shimla', 'Himachal Pradesh'],
        '171012': ['Shimla', 'Himachal Pradesh'],
        '171013': ['Shimla', 'Himachal Pradesh'],
        '171014': ['Shimla', 'Himachal Pradesh'],
        '171015': ['Shimla', 'Himachal Pradesh'],
        '171018': ['Shimla', 'Himachal Pradesh'],
        '171019': ['Shimla', 'Himachal Pradesh'],
        '171103': ['Shimla', 'Himachal Pradesh'],
        '171108': ['Shimla', 'Himachal Pradesh'],
        '171201': ['Shimla', 'Himachal Pradesh'],
        '171202': ['Shimla', 'Himachal Pradesh'],
        '171203': ['Shimla', 'Himachal Pradesh'],
        '171204': ['Shimla', 'Himachal Pradesh'],
        '171205': ['Shimla', 'Himachal Pradesh'],
        '171206': ['Shimla', 'Himachal Pradesh'],
        '171207': ['Shimla', 'Himachal Pradesh'],
        '171208': ['Shimla', 'Himachal Pradesh'],
        '171209': ['Shimla', 'Himachal Pradesh'],
        '171210': ['Shimla', 'Himachal Pradesh'],
        '171211': ['Shimla', 'Himachal Pradesh'],
        '171212': ['Shimla', 'Himachal Pradesh'],
        '171213': ['Shimla', 'Himachal Pradesh'],
        '171214': ['Shimla', 'Himachal Pradesh'],
        '171215': ['Shimla', 'Himachal Pradesh'],
        '171216': ['Shimla', 'Himachal Pradesh'],
        '171217': ['Shimla', 'Himachal Pradesh'],
        '171218': ['Shimla', 'Himachal Pradesh'],
        '171219': ['Shimla', 'Himachal Pradesh'],
        '171220': ['Shimla', 'Himachal Pradesh'],
        '171221': ['Shimla', 'Himachal Pradesh'],
        '171222': ['Shimla', 'Himachal Pradesh'],
        '171223': ['Shimla', 'Himachal Pradesh'],
        '171224': ['Shimla', 'Himachal Pradesh'],
        '171225': ['Shimla', 'Himachal Pradesh'],
        '171226': ['Shimla', 'Himachal Pradesh'],
        '171301': ['Shimla', 'Himachal Pradesh'],
        '171303': ['Shimla', 'Himachal Pradesh'],
        '171304': ['Shimla', 'Himachal Pradesh'],
        '172001': ['Shimla', 'Himachal Pradesh'],
        '172021': ['Shimla', 'Himachal Pradesh'],
        '172022': ['Shimla', 'Himachal Pradesh'],
        '172024': ['Shimla', 'Himachal Pradesh'],
        '172027': ['Shimla', 'Himachal Pradesh'],
        '172028': ['Shimla', 'Himachal Pradesh'],
        '172029': ['Shimla', 'Himachal Pradesh'],
        '172030': ['Shimla', 'Himachal Pradesh'],
        '172031': ['Shimla', 'Himachal Pradesh'],
        '172034': ['Shimla', 'Himachal Pradesh'],
        '172102': ['Shimla', 'Himachal Pradesh'],
        '172201': ['Shimla', 'Himachal Pradesh'],
        '173219': ['Shimla', 'Himachal Pradesh'],
        '173236': ['Shimla', 'Himachal Pradesh'],
        '176326': ['Shimla', 'Himachal Pradesh'],
        '180001': ['Jammu', 'Jammu and Kashmir'],
        '180002': ['Jammu', 'Jammu and Kashmir'],
        '180003': ['Jammu', 'Jammu and Kashmir'],
        '180004': ['Jammu', 'Jammu and Kashmir'],
        '180005': ['Jammu', 'Jammu and Kashmir'],
        '180006': ['Jammu', 'Jammu and Kashmir'],
        '180007': ['Jammu', 'Jammu and Kashmir'],
        '180008': ['Jammu', 'Jammu and Kashmir'],
        '180009': ['Jammu', 'Jammu and Kashmir'],
        '180010': ['Jammu', 'Jammu and Kashmir'],
        '180011': ['Jammu', 'Jammu and Kashmir'],
        '180012': ['Jammu', 'Jammu and Kashmir'],
        '180013': ['Jammu', 'Jammu and Kashmir'],
        '180015': ['Jammu', 'Jammu and Kashmir'],
        '180016': ['Jammu', 'Jammu and Kashmir'],
        '180017': ['Jammu', 'Jammu and Kashmir'],
        '180018': ['Jammu', 'Jammu and Kashmir'],
        '180019': ['Jammu', 'Jammu and Kashmir'],
        '180020': ['Jammu', 'Jammu and Kashmir'],
        '181101': ['Jammu', 'Jammu and Kashmir'],
        '181102': ['Jammu', 'Jammu and Kashmir'],
        '181103': ['Jammu', 'Jammu and Kashmir'],
        '181104': ['Jammu', 'Jammu and Kashmir'],
        '181105': ['Jammu', 'Jammu and Kashmir'],
        '181111': ['Jammu', 'Jammu and Kashmir'],
        '181114': ['Jammu', 'Jammu and Kashmir'],
        '181121': ['Jammu', 'Jammu and Kashmir'],
        '181122': ['Jammu', 'Jammu and Kashmir'],
        '181123': ['Jammu', 'Jammu and Kashmir'],
        '181124': ['Jammu', 'Jammu and Kashmir'],
        '181131': ['Jammu', 'Jammu and Kashmir'],
        '181132': ['Jammu', 'Jammu and Kashmir'],
        '181133': ['Jammu', 'Jammu and Kashmir'],
        '181134': ['Jammu', 'Jammu and Kashmir'],
        '181141': ['Jammu', 'Jammu and Kashmir'],
        '181142': ['Jammu', 'Jammu and Kashmir'],
        '181143': ['Jammu', 'Jammu and Kashmir'],
        '181144': ['Jammu', 'Jammu and Kashmir'],
        '181145': ['Jammu', 'Jammu and Kashmir'],
        '181151': ['Jammu', 'Jammu and Kashmir'],
        '181152': ['Jammu', 'Jammu and Kashmir'],
        '181201': ['Jammu', 'Jammu and Kashmir'],
        '181202': ['Jammu', 'Jammu and Kashmir'],
        '181203': ['Jammu', 'Jammu and Kashmir'],
        '181204': ['Jammu', 'Jammu and Kashmir'],
        '181205': ['Jammu', 'Jammu and Kashmir'],
        '181206': ['Jammu', 'Jammu and Kashmir'],
        '181207': ['Jammu', 'Jammu and Kashmir'],
        '181208': ['Jammu', 'Jammu and Kashmir'],
        '181209': ['Jammu', 'Jammu and Kashmir'],
        '181221': ['Jammu', 'Jammu and Kashmir'],
        '181224': ['Jammu', 'Jammu and Kashmir'],
        '184120': ['Jammu', 'Jammu and Kashmir'],
        '184121': ['Jammu', 'Jammu and Kashmir'],
        '185134': ['Jammu', 'Jammu and Kashmir'],
        '185154': ['Jammu', 'Jammu and Kashmir'],
        '201001': ['Ghaziabad', 'Uttar Pradesh'],
        '201002': ['Ghaziabad', 'Uttar Pradesh'],
        '201003': ['Ghaziabad', 'Uttar Pradesh'],
        '201004': ['Ghaziabad', 'Uttar Pradesh'],
        '201005': ['Ghaziabad', 'Uttar Pradesh'],
        '201006': ['Ghaziabad', 'Uttar Pradesh'],
        '201007': ['Ghaziabad', 'Uttar Pradesh'],
        '201008': ['Noida', 'Uttar Pradesh'],
        '201009': ['Ghaziabad', 'Uttar Pradesh'],
        '201010': ['Ghaziabad', 'Uttar Pradesh'],
        '201011': ['Ghaziabad', 'Uttar Pradesh'],
        '201012': ['Ghaziabad', 'Uttar Pradesh'],
        '201013': ['Ghaziabad', 'Uttar Pradesh'],
        '201014': ['Ghaziabad', 'Uttar Pradesh'],
        '201015': ['Ghaziabad', 'Uttar Pradesh'],
        '201016': ['Ghaziabad', 'Uttar Pradesh'],
        '201017': ['Ghaziabad', 'Uttar Pradesh'],
        '201018': ['Lucknow', 'Uttar Pradesh'],
        '201019': ['Ghaziabad', 'Uttar Pradesh'],
        '201020': ['Lucknow', 'Uttar Pradesh'],
        '201102': ['Ghaziabad', 'Uttar Pradesh'],
        '201103': ['Ghaziabad', 'Uttar Pradesh'],
        '201201': ['Ghaziabad', 'Uttar Pradesh'],
        '201204': ['Ghaziabad', 'Uttar Pradesh'],
        '201206': ['Ghaziabad', 'Uttar Pradesh'],
        '201301': ['Ghaziabad', 'Uttar Pradesh'],
        '201302': ['Ghaziabad', 'Uttar Pradesh'],
        '201303': ['Ghaziabad', 'Uttar Pradesh'],
        '201304': ['Ghaziabad', 'Uttar Pradesh'],
        '201305': ['Noida', 'Uttar Pradesh'],
        '201306': ['Noida', 'Uttar Pradesh'],
        '201307': ['Noida', 'Uttar Pradesh'],
        '201309': ['Noida', 'Uttar Pradesh'],
        '201310': ['Noida', 'Uttar Pradesh'],
        '201315': ['Lucknow', 'Uttar Pradesh'],
        '201316': ['Lucknow', 'Uttar Pradesh'],
        '201317': ['Lucknow', 'Uttar Pradesh'],
        '201318': ['Lucknow', 'Uttar Pradesh'],
        '203207': ['Ghaziabad', 'Uttar Pradesh'],
        '208001': ['Kanpur', 'Uttar Pradesh'],
        '208002': ['Kanpur', 'Uttar Pradesh'],
        '208003': ['Kanpur', 'Uttar Pradesh'],
        '208004': ['Kanpur', 'Uttar Pradesh'],
        '208005': ['Kanpur', 'Uttar Pradesh'],
        '208006': ['Kanpur', 'Uttar Pradesh'],
        '208007': ['Kanpur', 'Uttar Pradesh'],
        '208008': ['Kanpur', 'Uttar Pradesh'],
        '208009': ['Kanpur', 'Uttar Pradesh'],
        '208010': ['Kanpur', 'Uttar Pradesh'],
        '208011': ['Kanpur', 'Uttar Pradesh'],
        '208012': ['Kanpur', 'Uttar Pradesh'],
        '208013': ['Kanpur', 'Uttar Pradesh'],
        '208014': ['Kanpur', 'Uttar Pradesh'],
        '208015': ['Kanpur', 'Uttar Pradesh'],
        '208016': ['Kanpur', 'Uttar Pradesh'],
        '208017': ['Kanpur', 'Uttar Pradesh'],
        '208018': ['Kanpur', 'Uttar Pradesh'],
        '208019': ['Kanpur', 'Uttar Pradesh'],
        '208020': ['Kanpur', 'Uttar Pradesh'],
        '208021': ['Kanpur', 'Uttar Pradesh'],
        '208022': ['Kanpur', 'Uttar Pradesh'],
        '208023': ['Kanpur', 'Uttar Pradesh'],
        '208024': ['Kanpur', 'Uttar Pradesh'],
        '208025': ['Kanpur', 'Uttar Pradesh'],
        '208026': ['Kanpur', 'Uttar Pradesh'],
        '208027': ['Kanpur', 'Uttar Pradesh'],
        '209101': ['Kanpur', 'Uttar Pradesh'],
        '209111': ['Kanpur', 'Uttar Pradesh'],
        '209112': ['Kanpur', 'Uttar Pradesh'],
        '209115': ['Kanpur', 'Uttar Pradesh'],
        '209121': ['Kanpur', 'Uttar Pradesh'],
        '209125': ['Kanpur', 'Uttar Pradesh'],
        '209201': ['Kanpur', 'Uttar Pradesh'],
        '209202': ['Kanpur', 'Uttar Pradesh'],
        '209203': ['Kanpur', 'Uttar Pradesh'],
        '209204': ['Kanpur', 'Uttar Pradesh'],
        '209205': ['Kanpur', 'Uttar Pradesh'],
        '209206': ['Kanpur', 'Uttar Pradesh'],
        '209207': ['Kanpur', 'Uttar Pradesh'],
        '209208': ['Kanpur', 'Uttar Pradesh'],
        '209209': ['Kanpur', 'Uttar Pradesh'],
        '209210': ['Kanpur', 'Uttar Pradesh'],
        '209214': ['Kanpur', 'Uttar Pradesh'],
        '209217': ['Kanpur', 'Uttar Pradesh'],
        '209301': ['Kanpur', 'Uttar Pradesh'],
        '209302': ['Kanpur', 'Uttar Pradesh'],
        '209303': ['Kanpur', 'Uttar Pradesh'],
        '209304': ['Kanpur', 'Uttar Pradesh'],
        '209305': ['Kanpur', 'Uttar Pradesh'],
        '209306': ['Kanpur', 'Uttar Pradesh'],
        '209307': ['Kanpur', 'Uttar Pradesh'],
        '209308': ['Kanpur', 'Uttar Pradesh'],
        '209310': ['Kanpur', 'Uttar Pradesh'],
        '209311': ['Kanpur', 'Uttar Pradesh'],
        '209312': ['Kanpur', 'Uttar Pradesh'],
        '209401': ['Kanpur', 'Uttar Pradesh'],
        '209402': ['Kanpur', 'Uttar Pradesh'],
        '209860': ['Kanpur', 'Uttar Pradesh'],
        '211001': ['Allahabad', 'Uttar Pradesh'],
        '211002': ['Allahabad', 'Uttar Pradesh'],
        '211003': ['Allahabad', 'Uttar Pradesh'],
        '211004': ['Allahabad', 'Uttar Pradesh'],
        '211005': ['Allahabad', 'Uttar Pradesh'],
        '211006': ['Allahabad', 'Uttar Pradesh'],
        '211007': ['Allahabad', 'Uttar Pradesh'],
        '211008': ['Allahabad', 'Uttar Pradesh'],
        '211009': ['Allahabad', 'Uttar Pradesh'],
        '211010': ['Allahabad', 'Uttar Pradesh'],
        '211011': ['Allahabad', 'Uttar Pradesh'],
        '211012': ['Allahabad', 'Uttar Pradesh'],
        '211013': ['Allahabad', 'Uttar Pradesh'],
        '211014': ['Allahabad', 'Uttar Pradesh'],
        '211015': ['Allahabad', 'Uttar Pradesh'],
        '211016': ['Allahabad', 'Uttar Pradesh'],
        '211017': ['Allahabad', 'Uttar Pradesh'],
        '211018': ['Allahabad', 'Uttar Pradesh'],
        '211019': ['Allahabad', 'Uttar Pradesh'],
        '211021': ['Allahabad', 'Uttar Pradesh'],
        '211022': ['Allahabad', 'Uttar Pradesh'],
        '212104': ['Allahabad', 'Uttar Pradesh'],
        '212105': ['Allahabad', 'Uttar Pradesh'],
        '212106': ['Allahabad', 'Uttar Pradesh'],
        '212107': ['Allahabad', 'Uttar Pradesh'],
        '212108': ['Allahabad', 'Uttar Pradesh'],
        '212109': ['Allahabad', 'Uttar Pradesh'],
        '212111': ['Allahabad', 'Uttar Pradesh'],
        '212202': ['Allahabad', 'Uttar Pradesh'],
        '212203': ['Allahabad', 'Uttar Pradesh'],
        '212204': ['Allahabad', 'Uttar Pradesh'],
        '212205': ['Allahabad', 'Uttar Pradesh'],
        '212206': ['Allahabad', 'Uttar Pradesh'],
        '212207': ['Allahabad', 'Uttar Pradesh'],
        '212208': ['Allahabad', 'Uttar Pradesh'],
        '212212': ['Allahabad', 'Uttar Pradesh'],
        '212213': ['Allahabad', 'Uttar Pradesh'],
        '212214': ['Allahabad', 'Uttar Pradesh'],
        '212216': ['Allahabad', 'Uttar Pradesh'],
        '212217': ['Allahabad', 'Uttar Pradesh'],
        '212301': ['Allahabad', 'Uttar Pradesh'],
        '212302': ['Allahabad', 'Uttar Pradesh'],
        '212303': ['Allahabad', 'Uttar Pradesh'],
        '212305': ['Allahabad', 'Uttar Pradesh'],
        '212306': ['Allahabad', 'Uttar Pradesh'],
        '212307': ['Allahabad', 'Uttar Pradesh'],
        '212308': ['Allahabad', 'Uttar Pradesh'],
        '212401': ['Allahabad', 'Uttar Pradesh'],
        '212402': ['Allahabad', 'Uttar Pradesh'],
        '212404': ['Allahabad', 'Uttar Pradesh'],
        '212405': ['Allahabad', 'Uttar Pradesh'],
        '212502': ['Allahabad', 'Uttar Pradesh'],
        '212503': ['Allahabad', 'Uttar Pradesh'],
        '212507': ['Allahabad', 'Uttar Pradesh'],
        '221001': ['Varanasi', 'Uttar Pradesh'],
        '221002': ['Varanasi', 'Uttar Pradesh'],
        '221003': ['Varanasi', 'Uttar Pradesh'],
        '221004': ['Varanasi', 'Uttar Pradesh'],
        '221005': ['Varanasi', 'Uttar Pradesh'],
        '221006': ['Varanasi', 'Uttar Pradesh'],
        '221007': ['Varanasi', 'Uttar Pradesh'],
        '221008': ['Varanasi', 'Uttar Pradesh'],
        '221009': ['Varanasi', 'Uttar Pradesh'],
        '221010': ['Varanasi', 'Uttar Pradesh'],
        '221011': ['Varanasi', 'Uttar Pradesh'],
        '221012': ['Varanasi', 'Uttar Pradesh'],
        '221101': ['Varanasi', 'Uttar Pradesh'],
        '221103': ['Varanasi', 'Uttar Pradesh'],
        '221104': ['Varanasi', 'Uttar Pradesh'],
        '221105': ['Varanasi', 'Uttar Pradesh'],
        '221106': ['Varanasi', 'Uttar Pradesh'],
        '221107': ['Varanasi', 'Uttar Pradesh'],
        '221108': ['Varanasi', 'Uttar Pradesh'],
        '221109': ['Varanasi', 'Uttar Pradesh'],
        '221110': ['Varanasi', 'Uttar Pradesh'],
        '221111': ['Varanasi', 'Uttar Pradesh'],
        '221112': ['Varanasi', 'Uttar Pradesh'],
        '221113': ['Varanasi', 'Uttar Pradesh'],
        '221115': ['Varanasi', 'Uttar Pradesh'],
        '221116': ['Varanasi', 'Uttar Pradesh'],
        '221201': ['Varanasi', 'Uttar Pradesh'],
        '221202': ['Varanasi', 'Uttar Pradesh'],
        '221204': ['Varanasi', 'Uttar Pradesh'],
        '221205': ['Varanasi', 'Uttar Pradesh'],
        '221206': ['Varanasi', 'Uttar Pradesh'],
        '221207': ['Varanasi', 'Uttar Pradesh'],
        '221208': ['Varanasi', 'Uttar Pradesh'],
        '221209': ['Varanasi', 'Uttar Pradesh'],
        '221210': ['Varanasi', 'Uttar Pradesh'],
        '221301': ['Varanasi', 'Uttar Pradesh'],
        '221302': ['Varanasi', 'Uttar Pradesh'],
        '221303': ['Varanasi', 'Uttar Pradesh'],
        '221304': ['Varanasi', 'Uttar Pradesh'],
        '221305': ['Varanasi', 'Uttar Pradesh'],
        '221306': ['Varanasi', 'Uttar Pradesh'],
        '221307': ['Varanasi', 'Uttar Pradesh'],
        '221308': ['Varanasi', 'Uttar Pradesh'],
        '221309': ['Varanasi', 'Uttar Pradesh'],
        '221310': ['Varanasi', 'Uttar Pradesh'],
        '221311': ['Varanasi', 'Uttar Pradesh'],
        '221313': ['Varanasi', 'Uttar Pradesh'],
        '221314': ['Varanasi', 'Uttar Pradesh'],
        '221401': ['Varanasi', 'Uttar Pradesh'],
        '221402': ['Varanasi', 'Uttar Pradesh'],
        '221403': ['Varanasi', 'Uttar Pradesh'],
        '221404': ['Varanasi', 'Uttar Pradesh'],
        '221405': ['Varanasi', 'Uttar Pradesh'],
        '221406': ['Varanasi', 'Uttar Pradesh'],
        '221407': ['Varanasi', 'Uttar Pradesh'],
        '221409': ['Varanasi', 'Uttar Pradesh'],
        '221502': ['Allahabad', 'Uttar Pradesh'],
        '221503': ['Allahabad', 'Uttar Pradesh'],
        '221504': ['Allahabad', 'Uttar Pradesh'],
        '221505': ['Allahabad', 'Uttar Pradesh'],
        '221506': ['Allahabad', 'Uttar Pradesh'],
        '221507': ['Allahabad', 'Uttar Pradesh'],
        '221508': ['Allahabad', 'Uttar Pradesh'],
        '226001': ['Lucknow', 'Uttar Pradesh'],
        '226002': ['Lucknow', 'Uttar Pradesh'],
        '226003': ['Lucknow', 'Uttar Pradesh'],
        '226004': ['Lucknow', 'Uttar Pradesh'],
        '226005': ['Lucknow', 'Uttar Pradesh'],
        '226006': ['Lucknow', 'Uttar Pradesh'],
        '226007': ['Lucknow', 'Uttar Pradesh'],
        '226008': ['Lucknow', 'Uttar Pradesh'],
        '226009': ['Lucknow', 'Uttar Pradesh'],
        '226010': ['Lucknow', 'Uttar Pradesh'],
        '226011': ['Lucknow', 'Uttar Pradesh'],
        '226012': ['Lucknow', 'Uttar Pradesh'],
        '226013': ['Lucknow', 'Uttar Pradesh'],
        '226014': ['Lucknow', 'Uttar Pradesh'],
        '226015': ['Lucknow', 'Uttar Pradesh'],
        '226016': ['Lucknow', 'Uttar Pradesh'],
        '226017': ['Lucknow', 'Uttar Pradesh'],
        '226018': ['Lucknow', 'Uttar Pradesh'],
        '226019': ['Lucknow', 'Uttar Pradesh'],
        '226020': ['Lucknow', 'Uttar Pradesh'],
        '226021': ['Lucknow', 'Uttar Pradesh'],
        '226022': ['Lucknow', 'Uttar Pradesh'],
        '226023': ['Lucknow', 'Uttar Pradesh'],
        '226024': ['Lucknow', 'Uttar Pradesh'],
        '226025': ['Lucknow', 'Uttar Pradesh'],
        '226026': ['Lucknow', 'Uttar Pradesh'],
        '226027': ['Lucknow', 'Uttar Pradesh'],
        '226028': ['Lucknow', 'Uttar Pradesh'],
        '226029': ['Lucknow', 'Uttar Pradesh'],
        '226030': ['Lucknow', 'Uttar Pradesh'],
        '226031': ['Lucknow', 'Uttar Pradesh'],
        '226101': ['Lucknow', 'Uttar Pradesh'],
        '226102': ['Lucknow', 'Uttar Pradesh'],
        '226103': ['Lucknow', 'Uttar Pradesh'],
        '226104': ['Lucknow', 'Uttar Pradesh'],
        '226201': ['Lucknow', 'Uttar Pradesh'],
        '226202': ['Lucknow', 'Uttar Pradesh'],
        '226203': ['Lucknow', 'Uttar Pradesh'],
        '226301': ['Lucknow', 'Uttar Pradesh'],
        '226302': ['Lucknow', 'Uttar Pradesh'],
        '226303': ['Lucknow', 'Uttar Pradesh'],
        '226401': ['Lucknow', 'Uttar Pradesh'],
        '226501': ['Lucknow', 'Uttar Pradesh'],
        '227101': ['Lucknow', 'Uttar Pradesh'],
        '227105': ['Lucknow', 'Uttar Pradesh'],
        '227111': ['Lucknow', 'Uttar Pradesh'],
        '227115': ['Lucknow', 'Uttar Pradesh'],
        '227116': ['Lucknow', 'Uttar Pradesh'],
        '227120': ['Lucknow', 'Uttar Pradesh'],
        '227125': ['Lucknow', 'Uttar Pradesh'],
        '227132': ['Lucknow', 'Uttar Pradesh'],
        '227202': ['Lucknow', 'Uttar Pradesh'],
        '227305': ['Lucknow', 'Uttar Pradesh'],
        '227308': ['Lucknow', 'Uttar Pradesh'],
        '227309': ['Lucknow', 'Uttar Pradesh'],
        '228178': ['Allahabad', 'Uttar Pradesh'],
        '229411': ['Allahabad', 'Uttar Pradesh'],
        '229412': ['Allahabad', 'Uttar Pradesh'],
        '229413': ['Allahabad', 'Uttar Pradesh'],
        '232101': ['Varanasi', 'Uttar Pradesh'],
        '232102': ['Varanasi', 'Uttar Pradesh'],
        '232103': ['Varanasi', 'Uttar Pradesh'],
        '232104': ['Varanasi', 'Uttar Pradesh'],
        '232105': ['Varanasi', 'Uttar Pradesh'],
        '232106': ['Varanasi', 'Uttar Pradesh'],
        '232107': ['Varanasi', 'Uttar Pradesh'],
        '232108': ['Varanasi', 'Uttar Pradesh'],
        '232109': ['Varanasi', 'Uttar Pradesh'],
        '232110': ['Varanasi', 'Uttar Pradesh'],
        '232111': ['Varanasi', 'Uttar Pradesh'],
        '232114': ['Varanasi', 'Uttar Pradesh'],
        '232115': ['Varanasi', 'Uttar Pradesh'],
        '232116': ['Varanasi', 'Uttar Pradesh'],
        '232118': ['Varanasi', 'Uttar Pradesh'],
        '232119': ['Varanasi', 'Uttar Pradesh'],
        '232120': ['Varanasi', 'Uttar Pradesh'],
        '244225': ['Varanasi', 'Uttar Pradesh'],
        '244712': ['Dehradun', 'Uttarakhand'],
        '244713': ['Dehradun', 'Uttarakhand'],
        '244715': ['Dehradun', 'Uttarakhand'],
        '244716': ['Dehradun', 'Uttarakhand'],
        '244717': ['Dehradun', 'Uttarakhand'],
        '245101': ['Ghaziabad', 'Uttar Pradesh'],
        '245201': ['Ghaziabad', 'Uttar Pradesh'],
        '245205': ['Ghaziabad', 'Uttar Pradesh'],
        '245207': ['Ghaziabad', 'Uttar Pradesh'],
        '245208': ['Ghaziabad', 'Uttar Pradesh'],
        '245301': ['Ghaziabad', 'Uttar Pradesh'],
        '245304': ['Ghaziabad', 'Uttar Pradesh'],
        '246001': ['Dehradun', 'Uttarakhand'],
        '246113': ['Dehradun', 'Uttarakhand'],
        '246121': ['Dehradun', 'Uttarakhand'],
        '246123': ['Dehradun', 'Uttarakhand'],
        '246124': ['Dehradun', 'Uttarakhand'],
        '246125': ['Dehradun', 'Uttarakhand'],
        '246127': ['Dehradun', 'Uttarakhand'],
        '246128': ['Dehradun', 'Uttarakhand'],
        '246129': ['Dehradun', 'Uttarakhand'],
        '246130': ['Dehradun', 'Uttarakhand'],
        '246131': ['Dehradun', 'Uttarakhand'],
        '246141': ['Dehradun', 'Uttarakhand'],
        '246142': ['Dehradun', 'Uttarakhand'],
        '246144': ['Dehradun', 'Uttarakhand'],
        '246146': ['Dehradun', 'Uttarakhand'],
        '246147': ['Dehradun', 'Uttarakhand'],
        '246148': ['Dehradun', 'Uttarakhand'],
        '246149': ['Dehradun', 'Uttarakhand'],
        '246155': ['Dehradun', 'Uttarakhand'],
        '246159': ['Dehradun', 'Uttarakhand'],
        '246161': ['Dehradun', 'Uttarakhand'],
        '246162': ['Dehradun', 'Uttarakhand'],
        '246163': ['Dehradun', 'Uttarakhand'],
        '246164': ['Dehradun', 'Uttarakhand'],
        '246165': ['Dehradun', 'Uttarakhand'],
        '246166': ['Dehradun', 'Uttarakhand'],
        '246167': ['Dehradun', 'Uttarakhand'],
        '246169': ['Dehradun', 'Uttarakhand'],
        '246171': ['Dehradun', 'Uttarakhand'],
        '246172': ['Dehradun', 'Uttarakhand'],
        '246173': ['Dehradun', 'Uttarakhand'],
        '246174': ['Dehradun', 'Uttarakhand'],
        '246175': ['Dehradun', 'Uttarakhand'],
        '246176': ['Dehradun', 'Uttarakhand'],
        '246177': ['Dehradun', 'Uttarakhand'],
        '246179': ['Dehradun', 'Uttarakhand'],
        '246193': ['Dehradun', 'Uttarakhand'],
        '246194': ['Dehradun', 'Uttarakhand'],
        '246275': ['Dehradun', 'Uttarakhand'],
        '246276': ['Dehradun', 'Uttarakhand'],
        '246277': ['Dehradun', 'Uttarakhand'],
        '246278': ['Dehradun', 'Uttarakhand'],
        '246279': ['Dehradun', 'Uttarakhand'],
        '246285': ['Dehradun', 'Uttarakhand'],
        '246401': ['Dehradun', 'Uttarakhand'],
        '246419': ['Dehradun', 'Uttarakhand'],
        '246421': ['Dehradun', 'Uttarakhand'],
        '246422': ['Dehradun', 'Uttarakhand'],
        '246424': ['Dehradun', 'Uttarakhand'],
        '246425': ['Dehradun', 'Uttarakhand'],
        '246426': ['Dehradun', 'Uttarakhand'],
        '246427': ['Dehradun', 'Uttarakhand'],
        '246428': ['Dehradun', 'Uttarakhand'],
        '246429': ['Dehradun', 'Uttarakhand'],
        '246431': ['Dehradun', 'Uttarakhand'],
        '246435': ['Dehradun', 'Uttarakhand'],
        '246439': ['Dehradun', 'Uttarakhand'],
        '246440': ['Dehradun', 'Uttarakhand'],
        '246441': ['Dehradun', 'Uttarakhand'],
        '246442': ['Dehradun', 'Uttarakhand'],
        '246443': ['Dehradun', 'Uttarakhand'],
        '246444': ['Dehradun', 'Uttarakhand'],
        '246445': ['Dehradun', 'Uttarakhand'],
        '246446': ['Dehradun', 'Uttarakhand'],
        '246448': ['Dehradun', 'Uttarakhand'],
        '246449': ['Dehradun', 'Uttarakhand'],
        '246453': ['Dehradun', 'Uttarakhand'],
        '246455': ['Dehradun', 'Uttarakhand'],
        '246469': ['Dehradun', 'Uttarakhand'],
        '246471': ['Dehradun', 'Uttarakhand'],
        '246472': ['Dehradun', 'Uttarakhand'],
        '246473': ['Dehradun', 'Uttarakhand'],
        '246474': ['Dehradun', 'Uttarakhand'],
        '246475': ['Dehradun', 'Uttarakhand'],
        '246481': ['Dehradun', 'Uttarakhand'],
        '246482': ['Dehradun', 'Uttarakhand'],
        '246483': ['Dehradun', 'Uttarakhand'],
        '246486': ['Dehradun', 'Uttarakhand'],
        '246487': ['Dehradun', 'Uttarakhand'],
        '246488': ['Dehradun', 'Uttarakhand'],
        '246495': ['Dehradun', 'Uttarakhand'],
        '247656': ['Dehradun', 'Uttarakhand'],
        '247661': ['Haridwar', 'Uttarakhand'],
        '247663': ['Haridwar', 'Uttarakhand'],
        '247664': ['Haridwar', 'Uttarakhand'],
        '247665': ['Haridwar', 'Uttarakhand'],
        '247666': ['Dehradun', 'Uttarakhand'],
        '247667': ['Haridwar', 'Uttarakhand'],
        '247668': ['Haridwar', 'Uttarakhand'],
        '247670': ['Dehradun', 'Uttarakhand'],
        '247671': ['Haridwar', 'Uttarakhand'],
        '248001': ['Dehradun', 'Uttarakhand'],
        '248002': ['Dehradun', 'Uttarakhand'],
        '248003': ['Dehradun', 'Uttarakhand'],
        '248005': ['Dehradun', 'Uttarakhand'],
        '248006': ['Dehradun', 'Uttarakhand'],
        '248007': ['Dehradun', 'Uttarakhand'],
        '248008': ['Dehradun', 'Uttarakhand'],
        '248009': ['Dehradun', 'Uttarakhand'],
        '248011': ['Dehradun', 'Uttarakhand'],
        '248012': ['Dehradun', 'Uttarakhand'],
        '248013': ['Dehradun', 'Uttarakhand'],
        '248014': ['Dehradun', 'Uttarakhand'],
        '248015': ['Dehradun', 'Uttarakhand'],
        '248102': ['Dehradun', 'Uttar Pradesh'],
        '248110': ['Dehradun', 'Uttar Pradesh'],
        '248115': ['Dehradun', 'Uttar Pradesh'],
        '248116': ['Dehradun', 'Uttar Pradesh'],
        '248119': ['Dehradun', 'Uttar Pradesh'],
        '248121': ['Dehradun', 'Uttarakhand'],
        '248122': ['Dehradun', 'Uttarakhand'],
        '248123': ['Dehradun', 'Uttarakhand'],
        '248124': ['Dehradun', 'Uttarakhand'],
        '248125': ['Dehradun', 'Uttarakhand'],
        '248140': ['Dehradun', 'Uttarakhand'],
        '248141': ['Dehradun', 'Uttarakhand'],
        '248142': ['Dehradun', 'Uttarakhand'],
        '248143': ['Dehradun', 'Uttarakhand'],
        '248144': ['Dehradun', 'Uttar Pradesh'],
        '248145': ['Dehradun', 'Uttarakhand'],
        '248146': ['Dehradun', 'Uttarakhand'],
        '248147': ['Dehradun', 'Uttar Pradesh'],
        '248148': ['Dehradun', 'Uttar Pradesh'],
        '248152': ['Dehradun', 'Uttar Pradesh'],
        '248158': ['Dehradun', 'Uttarakhand'],
        '248159': ['Dehradun', 'Uttarakhand'],
        '248160': ['Dehradun', 'Uttar Pradesh'],
        '248161': ['Dehradun', 'Uttar Pradesh'],
        '248164': ['Dehradun', 'Uttar Pradesh'],
        '248165': ['Dehradun', 'Uttarakhand'],
        '248171': ['Dehradun', 'Uttarakhand'],
        '248179': ['Dehradun', 'Uttarakhand'],
        '248195': ['Dehradun', 'Uttarakhand'],
        '248196': ['Dehradun', 'Uttarakhand'],
        '248197': ['Dehradun', 'Uttarakhand'],
        '248198': ['Dehradun', 'Uttarakhand'],
        '248199': ['Dehradun', 'Uttarakhand'],
        '249001': ['Dehradun', 'Uttarakhand'],
        '249121': ['Dehradun', 'Uttarakhand'],
        '249122': ['Dehradun', 'Uttarakhand'],
        '249123': ['Dehradun', 'Uttarakhand'],
        '249124': ['Dehradun', 'Uttarakhand'],
        '249125': ['Dehradun', 'Uttarakhand'],
        '249126': ['Dehradun', 'Uttarakhand'],
        '249128': ['Dehradun', 'Uttarakhand'],
        '249130': ['Dehradun', 'Uttarakhand'],
        '249131': ['Dehradun', 'Uttarakhand'],
        '249132': ['Dehradun', 'Uttarakhand'],
        '249135': ['Dehradun', 'Uttarakhand'],
        '249137': ['Dehradun', 'Uttarakhand'],
        '249141': ['Dehradun', 'Uttarakhand'],
        '249145': ['Dehradun', 'Uttarakhand'],
        '249146': ['Dehradun', 'Uttarakhand'],
        '249151': ['Dehradun', 'Uttarakhand'],
        '249152': ['Dehradun', 'Uttarakhand'],
        '249155': ['Dehradun', 'Uttarakhand'],
        '249161': ['Dehradun', 'Uttarakhand'],
        '249165': ['Dehradun', 'Uttarakhand'],
        '249171': ['Dehradun', 'Uttarakhand'],
        '249175': ['Dehradun', 'Uttarakhand'],
        '249180': ['Dehradun', 'Uttarakhand'],
        '249181': ['Dehradun', 'Uttarakhand'],
        '249185': ['Dehradun', 'Uttarakhand'],
        '249186': ['Dehradun', 'Uttarakhand'],
        '249192': ['Dehradun', 'Uttarakhand'],
        '249193': ['Dehradun', 'Uttarakhand'],
        '249194': ['Dehradun', 'Uttarakhand'],
        '249195': ['Dehradun', 'Uttarakhand'],
        '249196': ['Dehradun', 'Uttarakhand'],
        '249199': ['Dehradun', 'Uttarakhand'],
        '249201': ['Dehradun', 'Uttarakhand'],
        '249202': ['Dehradun', 'Uttarakhand'],
        '249203': ['Dehradun', 'Uttarakhand'],
        '249204': ['Dehradun', 'Uttarakhand'],
        '249205': ['Dehradun', 'Uttarakhand'],
        '249301': ['Dehradun', 'Uttarakhand'],
        '249302': ['Dehradun', 'Uttarakhand'],
        '249304': ['Dehradun', 'Uttarakhand'],
        '249306': ['Dehradun', 'Uttarakhand'],
        '249401': ['Haridwar', 'Uttarakhand'],
        '249402': ['Haridwar', 'Uttarakhand'],
        '249403': ['Haridwar', 'Uttarakhand'],
        '249404': ['Haridwar', 'Uttarakhand'],
        '249405': ['Haridwar', 'Uttarakhand'],
        '249407': ['Haridwar', 'Uttarakhand'],
        '249408': ['Haridwar', 'Uttarakhand'],
        '249410': ['Haridwar', 'Uttarakhand'],
        '249411': ['Dehradun', 'Uttarakhand'],
        '262308': ['Dehradun', 'Uttarakhand'],
        '262309': ['Dehradun', 'Uttarakhand'],
        '262310': ['Dehradun', 'Uttarakhand'],
        '262311': ['Dehradun', 'Uttarakhand'],
        '262401': ['Dehradun', 'Uttarakhand'],
        '262402': ['Dehradun', 'Uttarakhand'],
        '262405': ['Dehradun', 'Uttarakhand'],
        '262501': ['Dehradun', 'Uttarakhand'],
        '262502': ['Dehradun', 'Uttarakhand'],
        '262520': ['Dehradun', 'Uttarakhand'],
        '262521': ['Dehradun', 'Uttarakhand'],
        '262522': ['Dehradun', 'Uttarakhand'],
        '262523': ['Dehradun', 'Uttarakhand'],
        '262524': ['Dehradun', 'Uttarakhand'],
        '262525': ['Dehradun', 'Uttarakhand'],
        '262526': ['Dehradun', 'Uttarakhand'],
        '262527': ['Dehradun', 'Uttarakhand'],
        '262528': ['Dehradun', 'Uttarakhand'],
        '262529': ['Dehradun', 'Uttarakhand'],
        '262530': ['Dehradun', 'Uttarakhand'],
        '262531': ['Dehradun', 'Uttarakhand'],
        '262532': ['Dehradun', 'Uttarakhand'],
        '262533': ['Dehradun', 'Uttarakhand'],
        '262534': ['Dehradun', 'Uttarakhand'],
        '262540': ['Dehradun', 'Uttarakhand'],
        '262541': ['Dehradun', 'Uttarakhand'],
        '262542': ['Dehradun', 'Uttarakhand'],
        '262543': ['Dehradun', 'Uttarakhand'],
        '262544': ['Dehradun', 'Uttarakhand'],
        '262545': ['Dehradun', 'Uttarakhand'],
        '262546': ['Dehradun', 'Uttarakhand'],
        '262547': ['Dehradun', 'Uttarakhand'],
        '262550': ['Dehradun', 'Uttarakhand'],
        '262551': ['Dehradun', 'Uttarakhand'],
        '262552': ['Dehradun', 'Uttarakhand'],
        '262553': ['Dehradun', 'Uttarakhand'],
        '262554': ['Dehradun', 'Uttarakhand'],
        '262555': ['Dehradun', 'Uttarakhand'],
        '262561': ['Dehradun', 'Uttarakhand'],
        '262576': ['Dehradun', 'Uttarakhand'],
        '262580': ['Dehradun', 'Uttarakhand'],
        '263001': ['Dehradun', 'Uttarakhand'],
        '263126': ['Dehradun', 'Uttarakhand'],
        '263127': ['Dehradun', 'Uttarakhand'],
        '263128': ['Dehradun', 'Uttarakhand'],
        '263132': ['Dehradun', 'Uttarakhand'],
        '263134': ['Dehradun', 'Uttarakhand'],
        '263135': ['Dehradun', 'Uttarakhand'],
        '263136': ['Dehradun', 'Uttarakhand'],
        '263137': ['Dehradun', 'Uttarakhand'],
        '263138': ['Dehradun', 'Uttarakhand'],
        '263139': ['Dehradun', 'Uttarakhand'],
        '263140': ['Dehradun', 'Uttarakhand'],
        '263145': ['Dehradun', 'Uttarakhand'],
        '263148': ['Dehradun', 'Uttarakhand'],
        '263149': ['Dehradun', 'Uttarakhand'],
        '263150': ['Dehradun', 'Uttarakhand'],
        '263151': ['Dehradun', 'Uttarakhand'],
        '263152': ['Dehradun', 'Uttarakhand'],
        '263153': ['Rudrapur', 'Uttarakhand'],
        '263154': ['Rudrapur', 'Uttarakhand'],
        '263156': ['Dehradun', 'Uttarakhand'],
        '263157': ['Dehradun', 'Uttarakhand'],
        '263158': ['Dehradun', 'Uttarakhand'],
        '263159': ['Dehradun', 'Uttarakhand'],
        '263160': ['Dehradun', 'Uttarakhand'],
        '263601': ['Dehradun', 'Uttarakhand'],
        '263619': ['Dehradun', 'Uttarakhand'],
        '263620': ['Dehradun', 'Uttarakhand'],
        '263621': ['Dehradun', 'Uttarakhand'],
        '263622': ['Dehradun', 'Uttarakhand'],
        '263623': ['Dehradun', 'Uttarakhand'],
        '263624': ['Dehradun', 'Uttarakhand'],
        '263625': ['Dehradun', 'Uttarakhand'],
        '263626': ['Dehradun', 'Uttarakhand'],
        '263628': ['Dehradun', 'Uttarakhand'],
        '263629': ['Dehradun', 'Uttarakhand'],
        '263630': ['Dehradun', 'Uttarakhand'],
        '263631': ['Dehradun', 'Uttarakhand'],
        '263632': ['Dehradun', 'Uttarakhand'],
        '263633': ['Dehradun', 'Uttarakhand'],
        '263634': ['Dehradun', 'Uttarakhand'],
        '263635': ['Dehradun', 'Uttarakhand'],
        '263636': ['Dehradun', 'Uttarakhand'],
        '263637': ['Dehradun', 'Uttarakhand'],
        '263638': ['Dehradun', 'Uttarakhand'],
        '263639': ['Dehradun', 'Uttarakhand'],
        '263640': ['Dehradun', 'Uttarakhand'],
        '263641': ['Dehradun', 'Uttarakhand'],
        '263642': ['Dehradun', 'Uttarakhand'],
        '263643': ['Dehradun', 'Uttarakhand'],
        '263645': ['Dehradun', 'Uttarakhand'],
        '263646': ['Dehradun', 'Uttarakhand'],
        '263651': ['Dehradun', 'Uttarakhand'],
        '263652': ['Dehradun', 'Uttarakhand'],
        '263653': ['Dehradun', 'Uttarakhand'],
        '263655': ['Dehradun', 'Uttarakhand'],
        '263656': ['Dehradun', 'Uttarakhand'],
        '263658': ['Dehradun', 'Uttarakhand'],
        '263659': ['Dehradun', 'Uttarakhand'],
        '263660': ['Dehradun', 'Uttarakhand'],
        '263661': ['Dehradun', 'Uttarakhand'],
        '263663': ['Dehradun', 'Uttarakhand'],
        '263664': ['Dehradun', 'Uttarakhand'],
        '263665': ['Dehradun', 'Uttarakhand'],
        '263667': ['Dehradun', 'Uttarakhand'],
        '263676': ['Dehradun', 'Uttarakhand'],
        '263678': ['Dehradun', 'Uttarakhand'],
        '263679': ['Dehradun', 'Uttarakhand'],
        '263680': ['Dehradun', 'Uttarakhand'],
        '274204': ['Rudrapur', 'Uttarakhand'],
        '281001': ['Mathura', 'Uttar Pradesh'],
        '281003': ['Mathura', 'Uttar Pradesh'],
        '281004': ['Mathura', 'Uttar Pradesh'],
        '281005': ['Mathura', 'Uttar Pradesh'],
        '281006': ['Mathura', 'Uttar Pradesh'],
        '281112': ['Mathura', 'Uttar Pradesh'],
        '281121': ['Mathura', 'Uttar Pradesh'],
        '281122': ['Mathura', 'Uttar Pradesh'],
        '281123': ['Mathura', 'Uttar Pradesh'],
        '281201': ['Mathura', 'Uttar Pradesh'],
        '281202': ['Mathura', 'Uttar Pradesh'],
        '281203': ['Mathura', 'Uttar Pradesh'],
        '281204': ['Mathura', 'Uttar Pradesh'],
        '281205': ['Mathura', 'Uttar Pradesh'],
        '281206': ['Mathura', 'Uttar Pradesh'],
        '281301': ['Mathura', 'Uttar Pradesh'],
        '281302': ['Mathura', 'Uttar Pradesh'],
        '281303': ['Mathura', 'Uttar Pradesh'],
        '281304': ['Mathura', 'Uttar Pradesh'],
        '281305': ['Mathura', 'Uttar Pradesh'],
        '281306': ['Mathura', 'Uttar Pradesh'],
        '281307': ['Mathura', 'Uttar Pradesh'],
        '281308': ['Mathura', 'Uttar Pradesh'],
        '281401': ['Mathura', 'Uttar Pradesh'],
        '281403': ['Mathura', 'Uttar Pradesh'],
        '281404': ['Mathura', 'Uttar Pradesh'],
        '281405': ['Mathura', 'Uttar Pradesh'],
        '281406': ['Mathura', 'Uttar Pradesh'],
        '281501': ['Mathura', 'Uttar Pradesh'],
        '281502': ['Mathura', 'Uttar Pradesh'],
        '281504': ['Mathura', 'Uttar Pradesh'],
        '282001': ['Agra', 'Uttar Pradesh'],
        '282002': ['Agra', 'Uttar Pradesh'],
        '282003': ['Agra', 'Uttar Pradesh'],
        '282004': ['Agra', 'Uttar Pradesh'],
        '282005': ['Agra', 'Uttar Pradesh'],
        '282006': ['Agra', 'Uttar Pradesh'],
        '282007': ['Agra', 'Uttar Pradesh'],
        '282008': ['Agra', 'Uttar Pradesh'],
        '282009': ['Agra', 'Uttar Pradesh'],
        '282010': ['Agra', 'Uttar Pradesh'],
        '283101': ['Agra', 'Uttar Pradesh'],
        '283102': ['Agra', 'Uttar Pradesh'],
        '283103': ['Agra', 'Uttar Pradesh'],
        '283104': ['Agra', 'Uttar Pradesh'],
        '283105': ['Agra', 'Uttar Pradesh'],
        '283110': ['Agra', 'Uttar Pradesh'],
        '283111': ['Agra', 'Uttar Pradesh'],
        '283112': ['Agra', 'Uttar Pradesh'],
        '283113': ['Agra', 'Uttar Pradesh'],
        '283114': ['Agra', 'Uttar Pradesh'],
        '283115': ['Agra', 'Uttar Pradesh'],
        '283119': ['Agra', 'Uttar Pradesh'],
        '283121': ['Agra', 'Uttar Pradesh'],
        '283122': ['Agra', 'Uttar Pradesh'],
        '283123': ['Agra', 'Uttar Pradesh'],
        '283124': ['Agra', 'Uttar Pradesh'],
        '283125': ['Agra', 'Uttar Pradesh'],
        '283126': ['Agra', 'Uttar Pradesh'],
        '283201': ['Agra', 'Uttar Pradesh'],
        '283202': ['Agra', 'Uttar Pradesh'],
        '283203': ['Agra', 'Uttar Pradesh'],
        '283204': ['Agra', 'Uttar Pradesh'],
        '302001': ['Jaipur', 'Rajasthan'],
        '302002': ['Jaipur', 'Rajasthan'],
        '302003': ['Jaipur', 'Rajasthan'],
        '302004': ['Jaipur', 'Rajasthan'],
        '302005': ['Jaipur', 'Rajasthan'],
        '302006': ['Jaipur', 'Rajasthan'],
        '302010': ['Jaipur', 'Rajasthan'],
        '302011': ['Jaipur', 'Rajasthan'],
        '302012': ['Jaipur', 'Rajasthan'],
        '302013': ['Jaipur', 'Rajasthan'],
        '302014': ['Jaipur', 'Rajasthan'],
        '302015': ['Jaipur', 'Rajasthan'],
        '302016': ['Jaipur', 'Rajasthan'],
        '302017': ['Jaipur', 'Rajasthan'],
        '302018': ['Jaipur', 'Rajasthan'],
        '302019': ['Jaipur', 'Rajasthan'],
        '302020': ['Jaipur', 'Rajasthan'],
        '302021': ['Jaipur', 'Rajasthan'],
        '302022': ['Jaipur', 'Rajasthan'],
        '302026': ['Jaipur', 'Rajasthan'],
        '302027': ['Jaipur', 'Rajasthan'],
        '302028': ['Jaipur', 'Rajasthan'],
        '302029': ['Jaipur', 'Rajasthan'],
        '302031': ['Jaipur', 'Rajasthan'],
        '302033': ['Jaipur', 'Rajasthan'],
        '302034': ['Jaipur', 'Rajasthan'],
        '302036': ['Jaipur', 'Rajasthan'],
        '302037': ['Jaipur', 'Rajasthan'],
        '302038': ['Jaipur', 'Rajasthan'],
        '302039': ['Jaipur', 'Rajasthan'],
        '302040': ['Jaipur', 'Rajasthan'],
        '302041': ['Jaipur', 'Rajasthan'],
        '302042': ['Jaipur', 'Rajasthan'],
        '302043': ['Jaipur', 'Rajasthan'],
        '303001': ['Jaipur', 'Rajasthan'],
        '303002': ['Jaipur', 'Rajasthan'],
        '303003': ['Jaipur', 'Rajasthan'],
        '303004': ['Jaipur', 'Rajasthan'],
        '303005': ['Jaipur', 'Rajasthan'],
        '303006': ['Jaipur', 'Rajasthan'],
        '303007': ['Jaipur', 'Rajasthan'],
        '303008': ['Jaipur', 'Rajasthan'],
        '303009': ['Jaipur', 'Rajasthan'],
        '303010': ['Jaipur', 'Rajasthan'],
        '303011': ['Jaipur', 'Rajasthan'],
        '303012': ['Jaipur', 'Rajasthan'],
        '303101': ['Jaipur', 'Rajasthan'],
        '303102': ['Jaipur', 'Rajasthan'],
        '303103': ['Jaipur', 'Rajasthan'],
        '303104': ['Jaipur', 'Rajasthan'],
        '303105': ['Jaipur', 'Rajasthan'],
        '303106': ['Jaipur', 'Rajasthan'],
        '303107': ['Jaipur', 'Rajasthan'],
        '303108': ['Jaipur', 'Rajasthan'],
        '303109': ['Jaipur', 'Rajasthan'],
        '303110': ['Jaipur', 'Rajasthan'],
        '303119': ['Jaipur', 'Rajasthan'],
        '303120': ['Jaipur', 'Rajasthan'],
        '303121': ['Jaipur', 'Rajasthan'],
        '303122': ['Jaipur', 'Rajasthan'],
        '303123': ['Jaipur', 'Rajasthan'],
        '303301': ['Jaipur', 'Rajasthan'],
        '303302': ['Jaipur', 'Rajasthan'],
        '303303': ['Jaipur', 'Rajasthan'],
        '303304': ['Jaipur', 'Rajasthan'],
        '303305': ['Jaipur', 'Rajasthan'],
        '303313': ['Jaipur', 'Rajasthan'],
        '303315': ['Jaipur', 'Rajasthan'],
        '303323': ['Jaipur', 'Rajasthan'],
        '303325': ['Jaipur', 'Rajasthan'],
        '303326': ['Jaipur', 'Rajasthan'],
        '303327': ['Jaipur', 'Rajasthan'],
        '303328': ['Jaipur', 'Rajasthan'],
        '303329': ['Jaipur', 'Rajasthan'],
        '303331': ['Jaipur', 'Rajasthan'],
        '303338': ['Jaipur', 'Rajasthan'],
        '303339': ['Jaipur', 'Rajasthan'],
        '303348': ['Jaipur', 'Rajasthan'],
        '303501': ['Jaipur', 'Rajasthan'],
        '303504': ['Jaipur', 'Rajasthan'],
        '303505': ['Jaipur', 'Rajasthan'],
        '303506': ['Jaipur', 'Rajasthan'],
        '303507': ['Jaipur', 'Rajasthan'],
        '303508': ['Jaipur', 'Rajasthan'],
        '303509': ['Jaipur', 'Rajasthan'],
        '303510': ['Jaipur', 'Rajasthan'],
        '303601': ['Jaipur', 'Rajasthan'],
        '303602': ['Jaipur', 'Rajasthan'],
        '303603': ['Jaipur', 'Rajasthan'],
        '303604': ['Jaipur', 'Rajasthan'],
        '303701': ['Jaipur', 'Rajasthan'],
        '303702': ['Jaipur', 'Rajasthan'],
        '303704': ['Jaipur', 'Rajasthan'],
        '303706': ['Jaipur', 'Rajasthan'],
        '303712': ['Jaipur', 'Rajasthan'],
        '303801': ['Jaipur', 'Rajasthan'],
        '303803': ['Jaipur', 'Rajasthan'],
        '303804': ['Jaipur', 'Rajasthan'],
        '303805': ['Jaipur', 'Rajasthan'],
        '303806': ['Jaipur', 'Rajasthan'],
        '303807': ['Jaipur', 'Rajasthan'],
        '303901': ['Jaipur', 'Rajasthan'],
        '303902': ['Jaipur', 'Rajasthan'],
        '303903': ['Jaipur', 'Rajasthan'],
        '303904': ['Jaipur', 'Rajasthan'],
        '303905': ['Jaipur', 'Rajasthan'],
        '303908': ['Jaipur', 'Rajasthan'],
        '305921': ['Udaipur', 'Rajasthan'],
        '307025': ['Udaipur', 'Rajasthan'],
        '313001': ['Udaipur', 'Rajasthan'],
        '313002': ['Udaipur', 'Rajasthan'],
        '313003': ['Udaipur', 'Rajasthan'],
        '313004': ['Udaipur', 'Rajasthan'],
        '313011': ['Udaipur', 'Rajasthan'],
        '313015': ['Udaipur', 'Rajasthan'],
        '313021': ['Udaipur', 'Rajasthan'],
        '313022': ['Udaipur', 'Rajasthan'],
        '313023': ['Udaipur', 'Rajasthan'],
        '313024': ['Udaipur', 'Rajasthan'],
        '313025': ['Udaipur', 'Rajasthan'],
        '313026': ['Udaipur', 'Rajasthan'],
        '313027': ['Udaipur', 'Rajasthan'],
        '313031': ['Udaipur', 'Rajasthan'],
        '313038': ['Udaipur', 'Rajasthan'],
        '313201': ['Udaipur', 'Rajasthan'],
        '313202': ['Udaipur', 'Rajasthan'],
        '313203': ['Udaipur', 'Rajasthan'],
        '313204': ['Udaipur', 'Rajasthan'],
        '313205': ['Udaipur', 'Rajasthan'],
        '313206': ['Udaipur', 'Rajasthan'],
        '313207': ['Udaipur', 'Rajasthan'],
        '313211': ['Udaipur', 'Rajasthan'],
        '313301': ['Udaipur', 'Rajasthan'],
        '313321': ['Udaipur', 'Rajasthan'],
        '313322': ['Udaipur', 'Rajasthan'],
        '313323': ['Udaipur', 'Rajasthan'],
        '313324': ['Udaipur', 'Rajasthan'],
        '313325': ['Udaipur', 'Rajasthan'],
        '313326': ['Udaipur', 'Rajasthan'],
        '313327': ['Udaipur', 'Rajasthan'],
        '313328': ['Udaipur', 'Rajasthan'],
        '313329': ['Udaipur', 'Rajasthan'],
        '313330': ['Udaipur', 'Rajasthan'],
        '313331': ['Udaipur', 'Rajasthan'],
        '313332': ['Udaipur', 'Rajasthan'],
        '313333': ['Udaipur', 'Rajasthan'],
        '313337': ['Udaipur', 'Rajasthan'],
        '313338': ['Udaipur', 'Rajasthan'],
        '313341': ['Udaipur', 'Rajasthan'],
        '313342': ['Udaipur', 'Rajasthan'],
        '313601': ['Udaipur', 'Rajasthan'],
        '313602': ['Udaipur', 'Rajasthan'],
        '313603': ['Udaipur', 'Rajasthan'],
        '313604': ['Udaipur', 'Rajasthan'],
        '313605': ['Udaipur', 'Rajasthan'],
        '313611': ['Udaipur', 'Rajasthan'],
        '313701': ['Udaipur', 'Rajasthan'],
        '313702': ['Udaipur', 'Rajasthan'],
        '313703': ['Udaipur', 'Rajasthan'],
        '313704': ['Udaipur', 'Rajasthan'],
        '313705': ['Udaipur', 'Rajasthan'],
        '313706': ['Udaipur', 'Rajasthan'],
        '313708': ['Udaipur', 'Rajasthan'],
        '313801': ['Udaipur', 'Rajasthan'],
        '313802': ['Udaipur', 'Rajasthan'],
        '313803': ['Udaipur', 'Rajasthan'],
        '313804': ['Udaipur', 'Rajasthan'],
        '313901': ['Udaipur', 'Rajasthan'],
        '313902': ['Udaipur', 'Rajasthan'],
        '313903': ['Udaipur', 'Rajasthan'],
        '313904': ['Udaipur', 'Rajasthan'],
        '313905': ['Udaipur', 'Rajasthan'],
        '313906': ['Udaipur', 'Rajasthan'],
        '313907': ['Udaipur', 'Rajasthan'],
        '324001': ['Kota', 'Rajasthan'],
        '324002': ['Kota', 'Rajasthan'],
        '324003': ['Kota', 'Rajasthan'],
        '324004': ['Kota', 'Rajasthan'],
        '324005': ['Kota', 'Rajasthan'],
        '324006': ['Kota', 'Rajasthan'],
        '324007': ['Kota', 'Rajasthan'],
        '324008': ['Kota', 'Rajasthan'],
        '324009': ['Kota', 'Rajasthan'],
        '324010': ['Kota', 'Rajasthan'],
        '325001': ['Kota', 'Rajasthan'],
        '325003': ['Kota', 'Rajasthan'],
        '325004': ['Kota', 'Rajasthan'],
        '325009': ['Kota', 'Rajasthan'],
        '325201': ['Kota', 'Rajasthan'],
        '325202': ['Kota', 'Rajasthan'],
        '325203': ['Kota', 'Rajasthan'],
        '325204': ['Kota', 'Rajasthan'],
        '325205': ['Kota', 'Rajasthan'],
        '325207': ['Kota', 'Rajasthan'],
        '325208': ['Kota', 'Rajasthan'],
        '325214': ['Kota', 'Rajasthan'],
        '325215': ['Kota', 'Rajasthan'],
        '325216': ['Kota', 'Rajasthan'],
        '325217': ['Kota', 'Rajasthan'],
        '325218': ['Kota', 'Rajasthan'],
        '325220': ['Kota', 'Rajasthan'],
        '325221': ['Kota', 'Rajasthan'],
        '325222': ['Kota', 'Rajasthan'],
        '325223': ['Kota', 'Rajasthan'],
        '325225': ['Kota', 'Rajasthan'],
        '325601': ['Kota', 'Rajasthan'],
        '325602': ['Kota', 'Rajasthan'],
        '326038': ['Kota', 'Rajasthan'],
        '326517': ['Kota', 'Rajasthan'],
        '326518': ['Kota', 'Rajasthan'],
        '326519': ['Kota', 'Rajasthan'],
        '326520': ['Kota', 'Rajasthan'],
        '326529': ['Kota', 'Rajasthan'],
        '326530': ['Kota', 'Rajasthan'],
        '333502': ['Surat', 'Gujarat'],
        '360001': ['Rajkot', 'Gujarat'],
        '360002': ['Rajkot', 'Gujarat'],
        '360003': ['Rajkot', 'Gujarat'],
        '360004': ['Rajkot', 'Gujarat'],
        '360005': ['Rajkot', 'Gujarat'],
        '360006': ['Rajkot', 'Gujarat'],
        '360007': ['Rajkot', 'Gujarat'],
        '360020': ['Rajkot', 'Gujarat'],
        '360021': ['Rajkot', 'Gujarat'],
        '360022': ['Rajkot', 'Gujarat'],
        '360023': ['Rajkot', 'Gujarat'],
        '360024': ['Rajkot', 'Gujarat'],
        '360025': ['Rajkot', 'Gujarat'],
        '360026': ['Rajkot', 'Gujarat'],
        '360030': ['Rajkot', 'Gujarat'],
        '360035': ['Rajkot', 'Gujarat'],
        '360040': ['Rajkot', 'Gujarat'],
        '360050': ['Rajkot', 'Gujarat'],
        '360055': ['Rajkot', 'Gujarat'],
        '360060': ['Rajkot', 'Gujarat'],
        '360070': ['Rajkot', 'Gujarat'],
        '360080': ['Rajkot', 'Gujarat'],
        '360110': ['Rajkot', 'Gujarat'],
        '360311': ['Rajkot', 'Gujarat'],
        '360320': ['Rajkot', 'Gujarat'],
        '360330': ['Rajkot', 'Gujarat'],
        '360335': ['Rajkot', 'Gujarat'],
        '360360': ['Rajkot', 'Gujarat'],
        '360370': ['Rajkot', 'Gujarat'],
        '360375': ['Rajkot', 'Gujarat'],
        '360380': ['Rajkot', 'Gujarat'],
        '360405': ['Rajkot', 'Gujarat'],
        '360410': ['Rajkot', 'Gujarat'],
        '360411': ['Rajkot', 'Gujarat'],
        '360421': ['Rajkot', 'Gujarat'],
        '360430': ['Rajkot', 'Gujarat'],
        '360440': ['Rajkot', 'Gujarat'],
        '360450': ['Rajkot', 'Gujarat'],
        '360452': ['Rajkot', 'Gujarat'],
        '360460': ['Rajkot', 'Gujarat'],
        '360470': ['Rajkot', 'Gujarat'],
        '360480': ['Jamnagar', 'Gujarat'],
        '360490': ['Rajkot', 'Gujarat'],
        '360510': ['Jamnagar', 'Gujarat'],
        '360515': ['Jamnagar', 'Gujarat'],
        '360520': ['Jamnagar', 'Gujarat'],
        '360530': ['Jamnagar', 'Gujarat'],
        '360531': ['Jamnagar', 'Gujarat'],
        '360540': ['Jamnagar', 'Gujarat'],
        '361001': ['Jamnagar', 'Gujarat'],
        '361002': ['Jamnagar', 'Gujarat'],
        '361003': ['Jamnagar', 'Gujarat'],
        '361004': ['Jamnagar', 'Gujarat'],
        '361005': ['Jamnagar', 'Gujarat'],
        '361006': ['Jamnagar', 'Gujarat'],
        '361007': ['Jamnagar', 'Gujarat'],
        '361008': ['Jamnagar', 'Gujarat'],
        '361009': ['Jamnagar', 'Gujarat'],
        '361010': ['Jamnagar', 'Gujarat'],
        '361011': ['Jamnagar', 'Gujarat'],
        '361012': ['Jamnagar', 'Gujarat'],
        '361013': ['Jamnagar', 'Gujarat'],
        '361110': ['Jamnagar', 'Gujarat'],
        '361120': ['Jamnagar', 'Gujarat'],
        '361130': ['Jamnagar', 'Gujarat'],
        '361140': ['Jamnagar', 'Gujarat'],
        '361141': ['Jamnagar', 'Gujarat'],
        '361142': ['Jamnagar', 'Gujarat'],
        '361150': ['Jamnagar', 'Gujarat'],
        '361160': ['Jamnagar', 'Gujarat'],
        '361162': ['Jamnagar', 'Gujarat'],
        '361170': ['Jamnagar', 'Gujarat'],
        '361210': ['Jamnagar', 'Gujarat'],
        '361220': ['Jamnagar', 'Gujarat'],
        '361230': ['Jamnagar', 'Gujarat'],
        '361240': ['Jamnagar', 'Gujarat'],
        '361250': ['Jamnagar', 'Gujarat'],
        '361280': ['Jamnagar', 'Gujarat'],
        '361305': ['Jamnagar', 'Gujarat'],
        '361306': ['Jamnagar', 'Gujarat'],
        '361310': ['Jamnagar', 'Gujarat'],
        '361315': ['Jamnagar', 'Gujarat'],
        '361320': ['Jamnagar', 'Gujarat'],
        '361325': ['Jamnagar', 'Gujarat'],
        '361330': ['Jamnagar', 'Gujarat'],
        '361335': ['Jamnagar', 'Gujarat'],
        '361336': ['Jamnagar', 'Gujarat'],
        '361345': ['Jamnagar', 'Gujarat'],
        '361347': ['Jamnagar', 'Gujarat'],
        '361350': ['Jamnagar', 'Gujarat'],
        '362530': ['Kota', 'Gujarat'],
        '363331': ['Rajkot', 'Gujarat'],
        '363610': ['Ahmedabad', 'Gujarat'],
        '363621': ['Rajkot', 'Gujarat'],
        '363622': ['Rajkot', 'Gujarat'],
        '363630': ['Rajkot', 'Gujarat'],
        '363635': ['Rajkot', 'Gujarat'],
        '363636': ['Rajkot', 'Gujarat'],
        '363641': ['Rajkot', 'Gujarat'],
        '363642': ['Rajkot', 'Gujarat'],
        '363643': ['Rajkot', 'Gujarat'],
        '363650': ['Rajkot', 'Gujarat'],
        '363655': ['Jamnagar', 'Gujarat'],
        '363660': ['Rajkot', 'Gujarat'],
        '363670': ['Rajkot', 'Gujarat'],
        '364465': ['Rajkot', 'Gujarat'],
        '364470': ['Rajkot', 'Gujarat'],
        '364485': ['Rajkot', 'Gujarat'],
        '364490': ['Rajkot', 'Gujarat'],
        '380001': ['Ahmedabad', 'Gujarat'],
        '380002': ['Ahmedabad', 'Gujarat'],
        '380003': ['Ahmedabad', 'Gujarat'],
        '380004': ['Ahmedabad', 'Gujarat'],
        '380005': ['Ahmedabad', 'Gujarat'],
        '380006': ['Ahmedabad', 'Gujarat'],
        '380007': ['Ahmedabad', 'Gujarat'],
        '380008': ['Ahmedabad', 'Gujarat'],
        '380009': ['Ahmedabad', 'Gujarat'],
        '380010': ['Ahmedabad', 'Gujarat'],
        '380012': ['Ahmedabad', 'Gujarat'],
        '380013': ['Ahmedabad', 'Gujarat'],
        '380014': ['Ahmedabad', 'Gujarat'],
        '380015': ['Ahmedabad', 'Gujarat'],
        '380016': ['Ahmedabad', 'Gujarat'],
        '380018': ['Ahmedabad', 'Gujarat'],
        '380019': ['Ahmedabad', 'Gujarat'],
        '380021': ['Ahmedabad', 'Gujarat'],
        '380022': ['Ahmedabad', 'Gujarat'],
        '380023': ['Ahmedabad', 'Gujarat'],
        '380024': ['Ahmedabad', 'Gujarat'],
        '380025': ['Ahmedabad', 'Gujarat'],
        '380026': ['Ahmedabad', 'Gujarat'],
        '380027': ['Ahmedabad', 'Gujarat'],
        '380028': ['Ahmedabad', 'Gujarat'],
        '380049': ['Ahmedabad', 'Gujarat'],
        '380050': ['Ahmedabad', 'Gujarat'],
        '380051': ['Ahmedabad', 'Gujarat'],
        '380052': ['Ahmedabad', 'Gujarat'],
        '380053': ['Ahmedabad', 'Gujarat'],
        '380054': ['Ahmedabad', 'Gujarat'],
        '380055': ['Ahmedabad', 'Gujarat'],
        '380058': ['Ahmedabad', 'Gujarat'],
        '380059': ['Ahmedabad', 'Gujarat'],
        '380060': ['Ahmedabad', 'Gujarat'],
        '380061': ['Ahmedabad', 'Gujarat'],
        '380063': ['Ahmedabad', 'Gujarat'],
        '382006': ['GandhiNagar', 'Gujarat'],
        '382007': ['GandhiNagar', 'Gujarat'],
        '382009': ['Gandhinagar', 'Gujarat'],
        '382010': ['Gandhinagar', 'Gujarat'],
        '382016': ['GandhiNagar', 'Gujarat'],
        '382017': ['GandhiNagar', 'Gujarat'],
        '382019': ['GandhiNagar', 'Gujarat'],
        '382020': ['GandhiNagar', 'Gujarat'],
        '382021': ['GandhiNagar', 'Gujarat'],
        '382022': ['Gandhinagar', 'Gujarat'],
        '382023': ['Gandhinagar', 'Gujarat'],
        '382024': ['GandhiNagar', 'Gujarat'],
        '382028': ['Gandhinagar', 'Gujarat'],
        '382029': ['Gandhinagar', 'Gujarat'],
        '382030': ['Gandhinagar', 'Gujarat'],
        '382041': ['Gandhinagar', 'Gujarat'],
        '382042': ['Gandhinagar', 'Gujarat'],
        '382043': ['GandhiNagar', 'Gujarat'],
        '382045': ['Gandhinagar', 'Gujarat'],
        '382051': ['GandhiNagar', 'Gujarat'],
        '382110': ['Ahmedabad', 'Gujarat'],
        '382115': ['Ahmedabad', 'Gujarat'],
        '382120': ['Ahmedabad', 'Gujarat'],
        '382130': ['Ahmedabad', 'Gujarat'],
        '382140': ['Ahmedabad', 'Gujarat'],
        '382145': ['Ahmedabad', 'Gujarat'],
        '382150': ['Ahmedabad', 'Gujarat'],
        '382155': ['Ahmedabad', 'Gujarat'],
        '382165': ['Ahmedabad', 'Gujarat'],
        '382170': ['Ahmedabad', 'Gujarat'],
        '382210': ['Ahmedabad', 'Gujarat'],
        '382213': ['Ahmedabad', 'Gujarat'],
        '382220': ['Ahmedabad', 'Gujarat'],
        '382225': ['Ahmedabad', 'Gujarat'],
        '382230': ['Ahmedabad', 'Gujarat'],
        '382240': ['Ahmedabad', 'Gujarat'],
        '382245': ['Ahmedabad', 'Gujarat'],
        '382250': ['Ahmedabad', 'Gujarat'],
        '382255': ['Ahmedabad', 'Gujarat'],
        '382260': ['Ahmedabad', 'Gujarat'],
        '382265': ['Ahmedabad', 'Gujarat'],
        '382270': ['Ahmedabad', 'Gujarat'],
        '382305': ['Gandhinagar', 'Gujarat'],
        '382308': ['GandhiNagar', 'Gujarat'],
        '382315': ['GandhiNagar', 'Gujarat'],
        '382320': ['GandhiNagar', 'Gujarat'],
        '382321': ['GandhiNagar', 'Gujarat'],
        '382325': ['Ahmedabad', 'Gujarat'],
        '382330': ['Ahmedabad', 'Gujarat'],
        '382340': ['Ahmedabad', 'Gujarat'],
        '382345': ['Ahmedabad', 'Gujarat'],
        '382350': ['Ahmedabad', 'Gujarat'],
        '382352': ['Ahmedabad', 'Gujarat'],
        '382355': ['Gandhinagar', 'Gujarat'],
        '382405': ['Ahmedabad', 'Gujarat'],
        '382410': ['Ahmedabad', 'Gujarat'],
        '382415': ['Ahmedabad', 'Gujarat'],
        '382418': ['Ahmedabad', 'Gujarat'],
        '382421': ['Ahmedabad', 'Gujarat'],
        '382422': ['GandhiNagar', 'Gujarat'],
        '382423': ['GandhiNagar', 'Gujarat'],
        '382424': ['Ahmedabad', 'Gujarat'],
        '382425': ['Ahmedabad', 'Gujarat'],
        '382426': ['Ahmedabad', 'Gujarat'],
        '382427': ['Ahmedabad', 'Gujarat'],
        '382428': ['GandhiNagar', 'Gujarat'],
        '382430': ['Ahmedabad', 'Gujarat'],
        '382433': ['Ahmedabad', 'Gujarat'],
        '382435': ['Ahmedabad', 'Gujarat'],
        '382440': ['Ahmedabad', 'Gujarat'],
        '382443': ['Ahmedabad', 'Gujarat'],
        '382445': ['Ahmedabad', 'Gujarat'],
        '382449': ['Ahmedabad', 'Gujarat'],
        '382450': ['Ahmedabad', 'Gujarat'],
        '382451': ['Ahmedabad', 'Gujarat'],
        '382455': ['Ahmedabad', 'Gujarat'],
        '382460': ['Ahmedabad', 'Gujarat'],
        '382463': ['Ahmedabad', 'Gujarat'],
        '382465': ['Ahmedabad', 'Gujarat'],
        '382470': ['Ahmedabad', 'Gujarat'],
        '382475': ['Ahmedabad', 'Gujarat'],
        '382480': ['Ahmedabad', 'Gujarat'],
        '382481': ['Ahmedabad', 'Gujarat'],
        '382610': ['GandhiNagar', 'Gujarat'],
        '382620': ['GandhiNagar', 'Gujarat'],
        '382630': ['GandhiNagar', 'Gujarat'],
        '382640': ['Gandhinagar', 'Gujarat'],
        '382650': ['Gandhinagar', 'Gujarat'],
        '382721': ['GandhiNagar', 'Gujarat'],
        '382725': ['GandhiNagar', 'Gujarat'],
        '382729': ['GandhiNagar', 'Gujarat'],
        '382735': ['GandhiNagar', 'Gujarat'],
        '382740': ['GandhiNagar', 'Gujarat'],
        '382810': ['GandhiNagar', 'Gujarat'],
        '382835': ['GandhiNagar', 'Gujarat'],
        '382845': ['Gandhinagar', 'Gujarat'],
        '382855': ['GandhiNagar', 'Gujarat'],
        '383205': ['Ahmedabad', 'Gujarat'],
        '383345': ['Ahmedabad', 'Gujarat'],
        '387003': ['Vadodara', 'Gujarat'],
        '387210': ['Anand', 'Gujarat'],
        '387220': ['Anand', 'Gujarat'],
        '387240': ['Anand', 'Gujarat'],
        '387310': ['Anand', 'Gujarat'],
        '387345': ['Anand', 'Gujarat'],
        '387710': ['Anand', 'Gujarat'],
        '387810': ['Ahmedabad', 'Gujarat'],
        '388001': ['Anand', 'Gujarat'],
        '388110': ['Anand', 'Gujarat'],
        '388120': ['Anand', 'Gujarat'],
        '388121': ['Anand', 'Gujarat'],
        '388130': ['Anand', 'Gujarat'],
        '388140': ['Anand', 'Gujarat'],
        '388150': ['Anand', 'Gujarat'],
        '388160': ['Anand', 'Gujarat'],
        '388170': ['Anand', 'Gujarat'],
        '388180': ['Anand', 'Gujarat'],
        '388205': ['Anand', 'Gujarat'],
        '388210': ['Anand', 'Gujarat'],
        '388215': ['Anand', 'Gujarat'],
        '388220': ['Anand', 'Gujarat'],
        '388305': ['Anand', 'Gujarat'],
        '388306': ['Anand', 'Gujarat'],
        '388307': ['Anand', 'Gujarat'],
        '388310': ['Anand', 'Gujarat'],
        '388315': ['Anand', 'Gujarat'],
        '388320': ['Anand', 'Gujarat'],
        '388325': ['Anand', 'Gujarat'],
        '388330': ['Anand', 'Gujarat'],
        '388335': ['Anand', 'Gujarat'],
        '388340': ['Anand', 'Gujarat'],
        '388345': ['Anand', 'Gujarat'],
        '388350': ['Anand', 'Gujarat'],
        '388355': ['Anand', 'Gujarat'],
        '388360': ['Anand', 'Gujarat'],
        '388365': ['Anand', 'Gujarat'],
        '388370': ['Anand', 'Gujarat'],
        '388430': ['Anand', 'Gujarat'],
        '388440': ['Anand', 'Gujarat'],
        '388450': ['Anand', 'Gujarat'],
        '388460': ['Anand', 'Gujarat'],
        '388465': ['Anand', 'Gujarat'],
        '388470': ['Anand', 'Gujarat'],
        '388480': ['Anand', 'Gujarat'],
        '388510': ['Anand', 'Gujarat'],
        '388520': ['Anand', 'Gujarat'],
        '388530': ['Anand', 'Gujarat'],
        '388540': ['Anand', 'Gujarat'],
        '388543': ['Anand', 'Gujarat'],
        '388545': ['Anand', 'Gujarat'],
        '388550': ['Anand', 'Gujarat'],
        '388560': ['Anand', 'Gujarat'],
        '388570': ['Anand', 'Gujarat'],
        '388580': ['Anand', 'Gujarat'],
        '388590': ['Anand', 'Gujarat'],
        '388610': ['Anand', 'Gujarat'],
        '388620': ['Anand', 'Gujarat'],
        '388625': ['Anand', 'Gujarat'],
        '388630': ['Anand', 'Gujarat'],
        '388640': ['Anand', 'Gujarat'],
        '390001': ['Vadodara', 'Gujarat'],
        '390002': ['Vadodara', 'Gujarat'],
        '390003': ['Vadodara', 'Gujarat'],
        '390004': ['Vadodara', 'Gujarat'],
        '390005': ['Vadodara', 'Gujarat'],
        '390006': ['Vadodara', 'Gujarat'],
        '390007': ['Vadodara', 'Gujarat'],
        '390008': ['Vadodara', 'Gujarat'],
        '390009': ['Vadodara', 'Gujarat'],
        '390010': ['Vadodara', 'Gujarat'],
        '390011': ['Vadodara', 'Gujarat'],
        '390012': ['Vadodara', 'Gujarat'],
        '390013': ['Vadodara', 'Gujarat'],
        '390014': ['Vadodara', 'Gujarat'],
        '390015': ['Vadodara', 'Gujarat'],
        '390016': ['Vadodara', 'Gujarat'],
        '390017': ['Vadodara', 'Gujarat'],
        '390018': ['Vadodara', 'Gujarat'],
        '390019': ['Vadodara', 'Gujarat'],
        '390020': ['Vadodara', 'Gujarat'],
        '390021': ['Vadodara', 'Gujarat'],
        '390022': ['Vadodara', 'Gujarat'],
        '390023': ['Vadodara', 'Gujarat'],
        '390024': ['Vadodara', 'Gujarat'],
        '390025': ['Vadodara', 'Gujarat'],
        '391101': ['Vadodara', 'Gujarat'],
        '391105': ['Vadodara', 'Gujarat'],
        '391107': ['Vadodara', 'Gujarat'],
        '391110': ['Vadodara', 'Gujarat'],
        '391111': ['Vadodara', 'Gujarat'],
        '391115': ['Vadodara', 'Gujarat'],
        '391120': ['Vadodara', 'Gujarat'],
        '391121': ['Vadodara', 'Gujarat'],
        '391125': ['Vadodara', 'Gujarat'],
        '391126': ['Vadodara', 'Gujarat'],
        '391130': ['Vadodara', 'Gujarat'],
        '391135': ['Vadodara', 'Gujarat'],
        '391136': ['Vadodara', 'Gujarat'],
        '391137': ['Vadodara', 'Gujarat'],
        '391140': ['Vadodara', 'Gujarat'],
        '391142': ['Vadodara', 'Gujarat'],
        '391145': ['Vadodara', 'Gujarat'],
        '391146': ['Vadodara', 'Gujarat'],
        '391150': ['Vadodara', 'Gujarat'],
        '391151': ['Vadodara', 'Gujarat'],
        '391152': ['Vadodara', 'Gujarat'],
        '391155': ['Vadodara', 'Gujarat'],
        '391156': ['Vadodara', 'Gujarat'],
        '391157': ['Vadodara', 'Gujarat'],
        '391160': ['Vadodara', 'Gujarat'],
        '391165': ['Vadodara', 'Gujarat'],
        '391168': ['Vadodara', 'Gujarat'],
        '391169': ['Vadodara', 'Gujarat'],
        '391170': ['Vadodara', 'Gujarat'],
        '391175': ['Vadodara', 'Gujarat'],
        '391210': ['Vadodara', 'Gujarat'],
        '391220': ['Vadodara', 'Gujarat'],
        '391230': ['Vadodara', 'Gujarat'],
        '391240': ['Vadodara', 'Gujarat'],
        '391243': ['Vadodara', 'Gujarat'],
        '391244': ['Vadodara', 'Gujarat'],
        '391250': ['Vadodara', 'Gujarat'],
        '391310': ['Vadodara', 'Gujarat'],
        '391320': ['Vadodara', 'Gujarat'],
        '391330': ['Vadodara', 'Gujarat'],
        '391340': ['Vadodara', 'Gujarat'],
        '391345': ['Vadodara', 'Gujarat'],
        '391346': ['Vadodara', 'Gujarat'],
        '391347': ['Vadodara', 'Gujarat'],
        '391350': ['Vadodara', 'Gujarat'],
        '391410': ['Vadodara', 'Gujarat'],
        '391421': ['Vadodara', 'Gujarat'],
        '391430': ['Vadodara', 'Gujarat'],
        '391440': ['Vadodara', 'Gujarat'],
        '391445': ['Vadodara', 'Gujarat'],
        '391450': ['Vadodara', 'Gujarat'],
        '391460': ['Vadodara', 'Gujarat'],
        '391510': ['Vadodara', 'Gujarat'],
        '391520': ['Vadodara', 'Gujarat'],
        '391530': ['Vadodara', 'Gujarat'],
        '391535': ['Vadodara', 'Gujarat'],
        '391740': ['Vadodara', 'Gujarat'],
        '391745': ['Vadodara', 'Gujarat'],
        '391750': ['Vadodara', 'Gujarat'],
        '391760': ['Vadodara', 'Gujarat'],
        '391761': ['Vadodara', 'Gujarat'],
        '391770': ['Vadodara', 'Gujarat'],
        '391774': ['Vadodara', 'Gujarat'],
        '391775': ['Vadodara', 'Gujarat'],
        '391776': ['Vadodara', 'Gujarat'],
        '391780': ['Vadodara', 'Gujarat'],
        '392030': ['Vadodara', 'Gujarat'],
        '392110': ['Vadodara', 'Gujarat'],
        '392130': ['Vadodara', 'Gujarat'],
        '392150': ['Vadodara', 'Gujarat'],
        '392160': ['Vadodara', 'Gujarat'],
        '392170': ['Vadodara', 'Gujarat'],
        '392220': ['Vadodara', 'Gujarat'],
        '392310': ['Vadodara', 'Gujarat'],
        '393002': ['Vadodara', 'Gujarat'],
        '393010': ['Vadodara', 'Gujarat'],
        '393050': ['Surat', 'Gujarat'],
        '393105': ['Vadodara', 'Gujarat'],
        '393130': ['Surat', 'Gujarat'],
        '394101': ['Surat', 'Gujarat'],
        '394105': ['Surat', 'Gujarat'],
        '394107': ['Surat', 'Gujarat'],
        '394110': ['Surat', 'Gujarat'],
        '394111': ['Surat', 'Gujarat'],
        '394120': ['Surat', 'Gujarat'],
        '394125': ['Surat', 'Gujarat'],
        '394130': ['Surat', 'Gujarat'],
        '394140': ['Surat', 'Gujarat'],
        '394150': ['Surat', 'Gujarat'],
        '394155': ['Surat', 'Gujarat'],
        '394160': ['Surat', 'Gujarat'],
        '394163': ['Surat', 'Gujarat'],
        '394170': ['Surat', 'Gujarat'],
        '394180': ['Surat', 'Gujarat'],
        '394185': ['Surat', 'Gujarat'],
        '394190': ['Surat', 'Gujarat'],
        '394210': ['Surat', 'Gujarat'],
        '394220': ['Surat', 'Gujarat'],
        '394221': ['Surat', 'Gujarat'],
        '394230': ['Surat', 'Gujarat'],
        '394235': ['Surat', 'Gujarat'],
        '394240': ['Surat', 'Gujarat'],
        '394245': ['Surat', 'Gujarat'],
        '394246': ['Surat', 'Gujarat'],
        '394248': ['Surat', 'Gujarat'],
        '394250': ['Surat', 'Gujarat'],
        '394270': ['Surat', 'Gujarat'],
        '394305': ['Surat', 'Gujarat'],
        '394310': ['Surat', 'Gujarat'],
        '394315': ['Surat', 'Gujarat'],
        '394317': ['Surat', 'Gujarat'],
        '394320': ['Surat', 'Gujarat'],
        '394325': ['Surat', 'Gujarat'],
        '394326': ['Surat', 'Gujarat'],
        '394327': ['Surat', 'Gujarat'],
        '394330': ['Surat', 'Gujarat'],
        '394335': ['Surat', 'Gujarat'],
        '394340': ['Surat', 'Gujarat'],
        '394345': ['Surat', 'Gujarat'],
        '394350': ['Surat', 'Gujarat'],
        '394352': ['Surat', 'Gujarat'],
        '394355': ['Surat', 'Gujarat'],
        '394360': ['Surat', 'Gujarat'],
        '394365': ['Surat', 'Gujarat'],
        '394370': ['Surat', 'Gujarat'],
        '394375': ['Surat', 'Gujarat'],
        '394380': ['Surat', 'Gujarat'],
        '394405': ['Surat', 'Gujarat'],
        '394410': ['Surat', 'Gujarat'],
        '394421': ['Surat', 'Gujarat'],
        '394430': ['Surat', 'Gujarat'],
        '394440': ['Surat', 'Gujarat'],
        '394445': ['Surat', 'Gujarat'],
        '394510': ['Surat', 'Gujarat'],
        '394515': ['Surat', 'Gujarat'],
        '394516': ['Surat', 'Gujarat'],
        '394517': ['Surat', 'Gujarat'],
        '394518': ['Surat', 'Gujarat'],
        '394520': ['Surat', 'Gujarat'],
        '394530': ['Surat', 'Gujarat'],
        '394540': ['Surat', 'Gujarat'],
        '394541': ['Vadodara', 'Gujarat'],
        '394550': ['Surat', 'Gujarat'],
        '394601': ['Surat', 'Gujarat'],
        '394620': ['Surat', 'Gujarat'],
        '394630': ['Surat', 'Gujarat'],
        '394633': ['Surat', 'Gujarat'],
        '394635': ['Surat', 'Gujarat'],
        '394640': ['Surat', 'Gujarat'],
        '394641': ['Surat', 'Gujarat'],
        '394650': ['Surat', 'Gujarat'],
        '394651': ['Surat', 'Gujarat'],
        '394652': ['Vadodara', 'Gujarat'],
        '394655': ['Surat', 'Gujarat'],
        '394660': ['Surat', 'Gujarat'],
        '394670': ['Surat', 'Gujarat'],
        '394680': ['Surat', 'Gujarat'],
        '394690': ['Surat', 'Gujarat'],
        '394715': ['Surat', 'Gujarat'],
        '395001': ['Surat', 'Gujarat'],
        '395002': ['Surat', 'Gujarat'],
        '395003': ['Surat', 'Gujarat'],
        '395004': ['Surat', 'Gujarat'],
        '395005': ['Surat', 'Gujarat'],
        '395006': ['Surat', 'Gujarat'],
        '395007': ['Surat', 'Gujarat'],
        '395008': ['Surat', 'Gujarat'],
        '395009': ['Surat', 'Gujarat'],
        '395010': ['Surat', 'Gujarat'],
        '395011': ['Surat', 'Gujarat'],
        '395012': ['Surat', 'Gujarat'],
        '395013': ['Surat', 'Gujarat'],
        '395017': ['Surat', 'Gujarat'],
        '395023': ['Surat', 'Gujarat'],
        '396001': ['Valsad', 'Gujarat'],
        '396002': ['Valsad', 'Gujarat'],
        '396007': ['Valsad', 'Gujarat'],
        '396020': ['Valsad', 'Gujarat'],
        '396030': ['Valsad', 'Gujarat'],
        '396035': ['Valsad', 'Gujarat'],
        '396040': ['Valsad', 'Gujarat'],
        '396045': ['Valsad', 'Gujarat'],
        '396050': ['Valsad', 'Gujarat'],
        '396051': ['Valsad', 'Gujarat'],
        '396055': ['Valsad', 'Gujarat'],
        '396060': ['Valsad', 'Gujarat'],
        '396065': ['Valsad', 'Gujarat'],
        '396067': ['Vadodara', 'Gujarat'],
        '396105': ['Valsad', 'Gujarat'],
        '396110': ['Valsad', 'Gujarat'],
        '396115': ['Valsad', 'Gujarat'],
        '396120': ['Valsad', 'Gujarat'],
        '396125': ['Valsad', 'Gujarat'],
        '396126': ['Valsad', 'Gujarat'],
        '396130': ['Valsad', 'Gujarat'],
        '396135': ['Valsad', 'Gujarat'],
        '396140': ['Valsad', 'Gujarat'],
        '396145': ['Valsad', 'Gujarat'],
        '396150': ['Valsad', 'Gujarat'],
        '396155': ['Valsad', 'Gujarat'],
        '396165': ['Valsad', 'Gujarat'],
        '396170': ['Valsad', 'Gujarat'],
        '396171': ['Valsad', 'Gujarat'],
        '396175': ['Valsad', 'Gujarat'],
        '396180': ['Valsad', 'Gujarat'],
        '396185': ['Valsad', 'Gujarat'],
        '396191': ['Valsad', 'Gujarat'],
        '396193': ['Vapi', 'Gujarat'],
        '396195': ['Valsad', 'Gujarat'],
        '396215': ['Vapi', 'Gujarat'],
        '396220': ['Vapi', 'Gujarat'],
        '396230': ['Valsad', 'Gujarat'],
        '396235': ['Valsad', 'Gujarat'],
        '396310': ['Valsad', 'Gujarat'],
        '396321': ['Valsad', 'Gujarat'],
        '396325': ['Valsad', 'Gujarat'],
        '396340': ['Valsad', 'Gujarat'],
        '396350': ['Valsad', 'Gujarat'],
        '396360': ['Valsad', 'Gujarat'],
        '396370': ['Valsad', 'Gujarat'],
        '396375': ['Valsad', 'Gujarat'],
        '396385': ['Valsad', 'Gujarat'],
        '396403': ['Valsad', 'Gujarat'],
        '396406': ['Valsad', 'Gujarat'],
        '396409': ['Valsad', 'Gujarat'],
        '396412': ['Valsad', 'Gujarat'],
        '396415': ['Valsad', 'Gujarat'],
        '396418': ['Valsad', 'Gujarat'],
        '396421': ['Valsad', 'Gujarat'],
        '396424': ['Valsad', 'Gujarat'],
        '396427': ['Valsad', 'Gujarat'],
        '396430': ['Valsad', 'Gujarat'],
        '396436': ['Valsad', 'Gujarat'],
        '396439': ['Valsad', 'Gujarat'],
        '396440': ['Valsad', 'Gujarat'],
        '396445': ['Valsad', 'Gujarat'],
        '396450': ['Valsad', 'Gujarat'],
        '396460': ['Valsad', 'Gujarat'],
        '396463': ['Valsad', 'Gujarat'],
        '396466': ['Valsad', 'Gujarat'],
        '396469': ['Valsad', 'Gujarat'],
        '396472': ['Valsad', 'Gujarat'],
        '396475': ['Valsad', 'Gujarat'],
        '396510': ['Surat', 'Gujarat'],
        '396521': ['Valsad', 'Gujarat'],
        '396530': ['Valsad', 'Gujarat'],
        '396540': ['Valsad', 'Gujarat'],
        '396560': ['Valsad', 'Gujarat'],
        '396570': ['Valsad', 'Gujarat'],
        '396590': ['Valsad', 'Gujarat'],
        '400001': ['Mumbai', 'Maharashtra'],
        '400002': ['Mumbai', 'Maharashtra'],
        '400003': ['Mumbai', 'Maharashtra'],
        '400004': ['Mumbai', 'Maharashtra'],
        '400005': ['Mumbai', 'Maharashtra'],
        '400006': ['Mumbai', 'Maharashtra'],
        '400007': ['Mumbai', 'Maharashtra'],
        '400008': ['Mumbai', 'Maharashtra'],
        '400009': ['Mumbai', 'Maharashtra'],
        '400010': ['Mumbai', 'Maharashtra'],
        '400011': ['Mumbai', 'Maharashtra'],
        '400012': ['Mumbai', 'Maharashtra'],
        '400013': ['Mumbai', 'Maharashtra'],
        '400014': ['Mumbai', 'Maharashtra'],
        '400015': ['Mumbai', 'Maharashtra'],
        '400016': ['Mumbai', 'Maharashtra'],
        '400017': ['Mumbai', 'Maharashtra'],
        '400018': ['Mumbai', 'Maharashtra'],
        '400019': ['Mumbai', 'Maharashtra'],
        '400020': ['Mumbai', 'Maharashtra'],
        '400021': ['Mumbai', 'Maharashtra'],
        '400022': ['Mumbai', 'Maharashtra'],
        '400024': ['Mumbai', 'Maharashtra'],
        '400025': ['Mumbai', 'Maharashtra'],
        '400026': ['Mumbai', 'Maharashtra'],
        '400027': ['Mumbai', 'Maharashtra'],
        '400028': ['Mumbai', 'Maharashtra'],
        '400029': ['Mumbai', 'Maharashtra'],
        '400030': ['Mumbai', 'Maharashtra'],
        '400031': ['Mumbai', 'Maharashtra'],
        '400032': ['Mumbai', 'Maharashtra'],
        '400033': ['Mumbai', 'Maharashtra'],
        '400034': ['Mumbai', 'Maharashtra'],
        '400035': ['Mumbai', 'Maharashtra'],
        '400036': ['Mumbai', 'Maharashtra'],
        '400037': ['Mumbai', 'Maharashtra'],
        '400039': ['Mumbai', 'Maharashtra'],
        '400042': ['Mumbai', 'Maharashtra'],
        '400043': ['Mumbai', 'Maharashtra'],
        '400048': ['Mumbai', 'Maharashtra'],
        '400049': ['Mumbai', 'Maharashtra'],
        '400050': ['Mumbai', 'Maharashtra'],
        '400051': ['Mumbai', 'Maharashtra'],
        '400052': ['Mumbai', 'Maharashtra'],
        '400053': ['Mumbai', 'Maharashtra'],
        '400054': ['Mumbai', 'Maharashtra'],
        '400055': ['Mumbai', 'Maharashtra'],
        '400056': ['Mumbai', 'Maharashtra'],
        '400057': ['Mumbai', 'Maharashtra'],
        '400058': ['Mumbai', 'Maharashtra'],
        '400059': ['Mumbai', 'Maharashtra'],
        '400060': ['Mumbai', 'Maharashtra'],
        '400061': ['Mumbai', 'Maharashtra'],
        '400062': ['Mumbai', 'Maharashtra'],
        '400063': ['Mumbai', 'Maharashtra'],
        '400064': ['Mumbai', 'Maharashtra'],
        '400065': ['Mumbai', 'Maharashtra'],
        '400066': ['Mumbai', 'Maharashtra'],
        '400067': ['Mumbai', 'Maharashtra'],
        '400068': ['Mumbai', 'Maharashtra'],
        '400069': ['Mumbai', 'Maharashtra'],
        '400070': ['Mumbai', 'Maharashtra'],
        '400071': ['Mumbai', 'Maharashtra'],
        '400072': ['Mumbai', 'Maharashtra'],
        '400074': ['Mumbai', 'Maharashtra'],
        '400075': ['Mumbai', 'Maharashtra'],
        '400076': ['Mumbai', 'Maharashtra'],
        '400077': ['Mumbai', 'Maharashtra'],
        '400078': ['Mumbai', 'Maharashtra'],
        '400079': ['Mumbai', 'Maharashtra'],
        '400080': ['Mumbai', 'Maharashtra'],
        '400081': ['Mumbai', 'Maharashtra'],
        '400082': ['Mumbai', 'Maharashtra'],
        '400083': ['Mumbai', 'Maharashtra'],
        '400084': ['Mumbai', 'Maharashtra'],
        '400085': ['Mumbai', 'Maharashtra'],
        '400086': ['Mumbai', 'Maharashtra'],
        '400087': ['Mumbai', 'Maharashtra'],
        '400088': ['Mumbai', 'Maharashtra'],
        '400089': ['Mumbai', 'Maharashtra'],
        '400090': ['Mumbai', 'Maharashtra'],
        '400091': ['Mumbai', 'Maharashtra'],
        '400092': ['Mumbai', 'Maharashtra'],
        '400093': ['Mumbai', 'Maharashtra'],
        '400094': ['Mumbai', 'Maharashtra'],
        '400095': ['Mumbai', 'Maharashtra'],
        '400096': ['Mumbai', 'Maharashtra'],
        '400097': ['Mumbai', 'Maharashtra'],
        '400098': ['Mumbai', 'Maharashtra'],
        '400099': ['Mumbai', 'Maharashtra'],
        '400101': ['Mumbai', 'Maharashtra'],
        '400102': ['Mumbai', 'Maharashtra'],
        '400103': ['Mumbai', 'Maharashtra'],
        '400104': ['Mumbai', 'Maharashtra'],
        '400601': ['Thane', 'Maharashtra'],
        '400602': ['Thane', 'Maharashtra'],
        '400603': ['Thane', 'Maharashtra'],
        '400604': ['Thane', 'Maharashtra'],
        '400605': ['Navi Mumbai', 'Maharashtra'],
        '400606': ['Thane', 'Maharashtra'],
        '400607': ['Thane', 'Maharashtra'],
        '400608': ['Thane', 'Maharashtra'],
        '400610': ['Thane', 'Maharashtra'],
        '400612': ['Thane', 'Maharashtra'],
        '400613': ['Thane', 'Maharashtra'],
        '400614': ['Navi Mumbai', 'Maharashtra'],
        '400615': ['Thane', 'Maharashtra'],
        '400701': ['Navi Mumbai', 'Maharashtra'],
        '400702': ['Navi Mumbai', 'Maharashtra'],
        '400703': ['Navi Mumbai', 'Maharashtra'],
        '400704': ['Navi Mumbai', 'Maharashtra'],
        '400705': ['Navi Mumbai', 'Maharashtra'],
        '400706': ['Navi Mumbai', 'Maharashtra'],
        '400707': ['Navi Mumbai', 'Maharashtra'],
        '400708': ['Navi Mumbai', 'Maharashtra'],
        '400709': ['Navi Mumbai', 'Maharashtra'],
        '400710': ['Navi Mumbai', 'Maharashtra'],
        '401101': ['Thane', 'Maharashtra'],
        '401102': ['Thane', 'Maharashtra'],
        '401103': ['Thane', 'Maharashtra'],
        '401104': ['Thane', 'Maharashtra'],
        '401105': ['Thane', 'Maharashtra'],
        '401106': ['Thane', 'Maharashtra'],
        '401107': ['Thane', 'Maharashtra'],
        '401201': ['Thane', 'Maharashtra'],
        '401202': ['Thane', 'Maharashtra'],
        '401203': ['Thane', 'Maharashtra'],
        '401204': ['Thane', 'Maharashtra'],
        '401206': ['Thane', 'Maharashtra'],
        '401207': ['Thane', 'Maharashtra'],
        '401208': ['Thane', 'Maharashtra'],
        '401209': ['Thane', 'Maharashtra'],
        '401301': ['Thane', 'Maharashtra'],
        '401302': ['Thane', 'Maharashtra'],
        '401303': ['Thane', 'Maharashtra'],
        '401304': ['Thane', 'Maharashtra'],
        '401305': ['Thane', 'Maharashtra'],
        '401401': ['Thane', 'Maharashtra'],
        '401402': ['Thane', 'Maharashtra'],
        '401403': ['Thane', 'Maharashtra'],
        '401404': ['Thane', 'Maharashtra'],
        '401405': ['Thane', 'Maharashtra'],
        '401501': ['Thane', 'Maharashtra'],
        '401502': ['Thane', 'Maharashtra'],
        '401503': ['Thane', 'Maharashtra'],
        '401504': ['Thane', 'Maharashtra'],
        '401506': ['Thane', 'Maharashtra'],
        '401601': ['Thane', 'Maharashtra'],
        '401602': ['Thane', 'Maharashtra'],
        '401603': ['Thane', 'Maharashtra'],
        '401604': ['Thane', 'Maharashtra'],
        '401605': ['Thane', 'Maharashtra'],
        '401606': ['Thane', 'Maharashtra'],
        '401607': ['Thane', 'Maharashtra'],
        '401608': ['Thane', 'Maharashtra'],
        '401609': ['Thane', 'Maharashtra'],
        '401610': ['Thane', 'Maharashtra'],
        '401701': ['Thane', 'Maharashtra'],
        '401702': ['Thane', 'Maharashtra'],
        '401703': ['Thane', 'Maharashtra'],
        '403001': ['Pananji', 'Goa'],
        '403002': ['Pananji', 'Goa'],
        '403003': ['Pananji', 'Goa'],
        '403004': ['Pananji', 'Goa'],
        '403005': ['Goa', 'Goa'],
        '403006': ['Goa', 'Goa'],
        '403101': ['Goa', 'Goa'],
        '403102': ['Goa', 'Goa'],
        '403103': ['Goa', 'Goa'],
        '403104': ['Goa', 'Goa'],
        '403105': ['Goa', 'Goa'],
        '403106': ['Goa', 'Goa'],
        '403107': ['Goa', 'Goa'],
        '403108': ['Goa', 'Goa'],
        '403109': ['Goa', 'Goa'],
        '403110': ['Goa', 'Goa'],
        '403111': ['Goa', 'Goa'],
        '403114': ['Goa', 'Goa'],
        '403115': ['Goa', 'Goa'],
        '403201': ['Goa', 'Goa'],
        '403202': ['Goa', 'Goa'],
        '403203': ['Goa', 'Goa'],
        '403204': ['Goa', 'Goa'],
        '403206': ['Pananji', 'Goa'],
        '403401': ['Goa', 'Goa'],
        '403402': ['Goa', 'Goa'],
        '403403': ['Goa', 'Goa'],
        '403404': ['Goa', 'Goa'],
        '403406': ['Goa', 'Goa'],
        '403407': ['Goa', 'Goa'],
        '403409': ['Goa', 'Goa'],
        '403410': ['Goa', 'Goa'],
        '403501': ['Goa', 'Goa'],
        '403502': ['Goa', 'Goa'],
        '403503': ['Goa', 'Goa'],
        '403504': ['Goa', 'Goa'],
        '403505': ['Goa', 'Goa'],
        '403506': ['Goa', 'Goa'],
        '403507': ['Goa', 'Goa'],
        '403508': ['Goa', 'Goa'],
        '403509': ['Goa', 'Goa'],
        '403510': ['Goa', 'Goa'],
        '403511': ['Goa', 'Goa'],
        '403512': ['Goa', 'Goa'],
        '403513': ['Goa', 'Goa'],
        '403515': ['Goa', 'Goa'],
        '403516': ['Goa', 'Goa'],
        '403517': ['Goa', 'Goa'],
        '403518': ['Goa', 'Goa'],
        '403519': ['Goa', 'Goa'],
        '403521': ['Pananji', 'Goa'],
        '403523': ['Goa', 'Goa'],
        '403524': ['Goa', 'Goa'],
        '403526': ['Goa', 'Goa'],
        '403527': ['Goa', 'Goa'],
        '403529': ['Goa', 'Goa'],
        '403530': ['Goa', 'Goa'],
        '403601': ['Margao', 'Goa'],
        '403602': ['Margao', 'Goa'],
        '403603': ['Goa', 'Goa'],
        '403604': ['Goa', 'Goa'],
        '403701': ['Goa', 'Goa'],
        '403702': ['Goa', 'Goa'],
        '403703': ['Goa', 'Goa'],
        '403704': ['Goa', 'Goa'],
        '403705': ['Goa', 'Goa'],
        '403706': ['Goa', 'Goa'],
        '403707': ['Margao', 'Goa'],
        '403708': ['Goa', 'Goa'],
        '403709': ['Margao', 'Goa'],
        '403710': ['Goa', 'Goa'],
        '403711': ['Goa', 'Goa'],
        '403712': ['Goa', 'Goa'],
        '403713': ['Goa', 'Goa'],
        '403714': ['Margao', 'Goa'],
        '403715': ['Goa', 'Goa'],
        '403716': ['Goa', 'Goa'],
        '403717': ['Goa', 'Goa'],
        '403718': ['Goa', 'Goa'],
        '403719': ['Goa', 'Goa'],
        '403720': ['Margao', 'Goa'],
        '403721': ['Goa', 'Goa'],
        '403722': ['Goa', 'Goa'],
        '403723': ['Goa', 'Goa'],
        '403724': ['Goa', 'Goa'],
        '403725': ['Goa', 'Goa'],
        '403726': ['Goa', 'Goa'],
        '403727': ['Goa', 'Goa'],
        '403728': ['Goa', 'Goa'],
        '403729': ['Goa', 'Goa'],
        '403730': ['Goa', 'Goa'],
        '403731': ['Goa', 'Goa'],
        '403801': ['Goa', 'Goa'],
        '403802': ['Goa', 'Goa'],
        '403803': ['Goa', 'Goa'],
        '403804': ['Goa', 'Goa'],
        '403806': ['Goa', 'Goa'],
        '410106': ['Navi Mumbai', 'Maharashtra'],
        '410206': ['Navi Mumbai', 'Maharashtra'],
        '410208': ['Navi Mumbai', 'Maharashtra'],
        '410209': ['Navi Mumbai', 'Maharashtra'],
        '410210': ['Navi Mumbai', 'Maharashtra'],
        '410218': ['Navi Mumbai', 'Maharashtra'],
        '410221': ['Navi Mumbai', 'Maharashtra'],
        '410301': ['Pune', 'Maharashtra'],
        '410302': ['Pune', 'Maharashtra'],
        '410401': ['Pune', 'Maharashtra'],
        '410402': ['Pune', 'Maharashtra'],
        '410403': ['Pune', 'Maharashtra'],
        '410405': ['Pune', 'Maharashtra'],
        '410406': ['Pune', 'Maharashtra'],
        '410501': ['Pune', 'Maharashtra'],
        '410502': ['Pune', 'Maharashtra'],
        '410503': ['Pune', 'Maharashtra'],
        '410504': ['Pune', 'Maharashtra'],
        '410505': ['Pune', 'Maharashtra'],
        '410506': ['Pune', 'Maharashtra'],
        '410507': ['Pune', 'Maharashtra'],
        '410508': ['Pune', 'Maharashtra'],
        '410509': ['Pune', 'Maharashtra'],
        '410510': ['Pune', 'Maharashtra'],
        '410511': ['Pune', 'Maharashtra'],
        '410512': ['Pune', 'Maharashtra'],
        '410513': ['Pune', 'Maharashtra'],
        '410515': ['Pune', 'Maharashtra'],
        '410516': ['Pune', 'Maharashtra'],
        '411001': ['Pune', 'Maharashtra'],
        '411002': ['Pune', 'Maharashtra'],
        '411003': ['Pune', 'Maharashtra'],
        '411004': ['Pune', 'Maharashtra'],
        '411005': ['Pune', 'Maharashtra'],
        '411006': ['Pune', 'Maharashtra'],
        '411007': ['Pune', 'Maharashtra'],
        '411008': ['Pune', 'Maharashtra'],
        '411009': ['Pune', 'Maharashtra'],
        '411010': ['Pune', 'Maharashtra'],
        '411011': ['Pune', 'Maharashtra'],
        '411012': ['Pune', 'Maharashtra'],
        '411013': ['Pune', 'Maharashtra'],
        '411014': ['Pune', 'Maharashtra'],
        '411015': ['Pune', 'Maharashtra'],
        '411016': ['Pune', 'Maharashtra'],
        '411017': ['Pune', 'Maharashtra'],
        '411018': ['Pune', 'Maharashtra'],
        '411019': ['Pune', 'Maharashtra'],
        '411020': ['Pune', 'Maharashtra'],
        '411021': ['Pune', 'Maharashtra'],
        '411022': ['Pune', 'Maharashtra'],
        '411023': ['Pune', 'Maharashtra'],
        '411024': ['Pune', 'Maharashtra'],
        '411025': ['Pune', 'Maharashtra'],
        '411026': ['Pune', 'Maharashtra'],
        '411027': ['Pune', 'Maharashtra'],
        '411028': ['Pune', 'Maharashtra'],
        '411029': ['Pune', 'Maharashtra'],
        '411030': ['Pune', 'Maharashtra'],
        '411031': ['Pune', 'Maharashtra'],
        '411032': ['Pune', 'Maharashtra'],
        '411033': ['Pune', 'Maharashtra'],
        '411034': ['Pune', 'Maharashtra'],
        '411035': ['Pune', 'Maharashtra'],
        '411036': ['Pune', 'Maharashtra'],
        '411037': ['Pune', 'Maharashtra'],
        '411038': ['Pune', 'Maharashtra'],
        '411039': ['Pune', 'Maharashtra'],
        '411040': ['Pune', 'Maharashtra'],
        '411041': ['Pune', 'Maharashtra'],
        '411042': ['Pune', 'Maharashtra'],
        '411043': ['Pune', 'Maharashtra'],
        '411044': ['Pune', 'Maharashtra'],
        '411045': ['Pune', 'Maharashtra'],
        '411046': ['Pune', 'Maharashtra'],
        '411047': ['Pune', 'Maharashtra'],
        '411048': ['Pune', 'Maharashtra'],
        '411051': ['Pune', 'Maharashtra'],
        '411052': ['Pune', 'Maharashtra'],
        '411057': ['Pune', 'Maharashtra'],
        '411058': ['Pune', 'Maharashtra'],
        '411060': ['Pune', 'Maharashtra'],
        '411061': ['Pune', 'Maharashtra'],
        '411062': ['Pune', 'Maharashtra'],
        '411067': ['Pune', 'Maharashtra'],
        '411068': ['Pune', 'Maharashtra'],
        '412101': ['Pune', 'Maharashtra'],
        '412102': ['Pune', 'Maharashtra'],
        '412103': ['Pune', 'Maharashtra'],
        '412104': ['Pune', 'Maharashtra'],
        '412105': ['Pune', 'Maharashtra'],
        '412106': ['Pune', 'Maharashtra'],
        '412107': ['Pune', 'Maharashtra'],
        '412108': ['Pune', 'Maharashtra'],
        '412109': ['Pune', 'Maharashtra'],
        '412110': ['Pune', 'Maharashtra'],
        '412112': ['Pune', 'Maharashtra'],
        '412113': ['Pune', 'Maharashtra'],
        '412115': ['Pune', 'Maharashtra'],
        '412201': ['Pune', 'Maharashtra'],
        '412202': ['Pune', 'Maharashtra'],
        '412203': ['Pune', 'Maharashtra'],
        '412204': ['Pune', 'Maharashtra'],
        '412205': ['Pune', 'Maharashtra'],
        '412206': ['Pune', 'Maharashtra'],
        '412207': ['Pune', 'Maharashtra'],
        '412208': ['Pune', 'Maharashtra'],
        '412209': ['Pune', 'Maharashtra'],
        '412210': ['Pune', 'Maharashtra'],
        '412211': ['Pune', 'Maharashtra'],
        '412212': ['Pune', 'Maharashtra'],
        '412213': ['Pune', 'Maharashtra'],
        '412214': ['Pune', 'Maharashtra'],
        '412215': ['Pune', 'Maharashtra'],
        '412216': ['Pune', 'Maharashtra'],
        '412218': ['Pune', 'Maharashtra'],
        '412219': ['Pune', 'Maharashtra'],
        '412220': ['Pune', 'Maharashtra'],
        '412301': ['Pune', 'Maharashtra'],
        '412303': ['Pune', 'Maharashtra'],
        '412304': ['Pune', 'Maharashtra'],
        '412305': ['Pune', 'Maharashtra'],
        '412306': ['Pune', 'Maharashtra'],
        '412307': ['Pune', 'Maharashtra'],
        '412308': ['Pune', 'Maharashtra'],
        '412311': ['Pune', 'Maharashtra'],
        '412312': ['Pune', 'Maharashtra'],
        '412401': ['Pune', 'Maharashtra'],
        '412402': ['Pune', 'Maharashtra'],
        '412403': ['Pune', 'Maharashtra'],
        '412404': ['Pune', 'Maharashtra'],
        '412405': ['Pune', 'Maharashtra'],
        '412406': ['Pune', 'Maharashtra'],
        '412407': ['Pune', 'Maharashtra'],
        '412408': ['Pune', 'Maharashtra'],
        '412409': ['Pune', 'Maharashtra'],
        '412410': ['Pune', 'Maharashtra'],
        '412411': ['Pune', 'Maharashtra'],
        '412412': ['Pune', 'Maharashtra'],
        '412415': ['Pune', 'Maharashtra'],
        '413001': ['Solapur', 'Maharashtra'],
        '413002': ['Solapur', 'Maharashtra'],
        '413003': ['Solapur', 'Maharashtra'],
        '413004': ['Solapur', 'Maharashtra'],
        '413005': ['Solapur', 'Maharashtra'],
        '413006': ['Solapur', 'Maharashtra'],
        '413007': ['Solapur', 'Maharashtra'],
        '413008': ['Solapur', 'Maharashtra'],
        '413101': ['Solapur', 'Maharashtra'],
        '413102': ['Pune', 'Maharashtra'],
        '413103': ['Pune', 'Maharashtra'],
        '413104': ['Pune', 'Maharashtra'],
        '413105': ['Pune', 'Maharashtra'],
        '413106': ['Pune', 'Maharashtra'],
        '413107': ['Solapur', 'Maharashtra'],
        '413108': ['Solapur', 'Maharashtra'],
        '413109': ['Solapur', 'Maharashtra'],
        '413110': ['Pune', 'Maharashtra'],
        '413111': ['Solapur', 'Maharashtra'],
        '413112': ['Solapur', 'Maharashtra'],
        '413113': ['Solapur', 'Maharashtra'],
        '413114': ['Pune', 'Maharashtra'],
        '413115': ['Pune', 'Maharashtra'],
        '413116': ['Pune', 'Maharashtra'],
        '413117': ['Pune', 'Maharashtra'],
        '413118': ['Solapur', 'Maharashtra'],
        '413120': ['Pune', 'Maharashtra'],
        '413124': ['Pune', 'Maharashtra'],
        '413130': ['Pune', 'Maharashtra'],
        '413132': ['Pune', 'Maharashtra'],
        '413133': ['Pune', 'Maharashtra'],
        '413201': ['AhmedNagar', 'Maharashtra'],
        '413202': ['Solapur', 'Maharashtra'],
        '413203': ['Solapur', 'Maharashtra'],
        '413205': ['AhmedNagar', 'Maharashtra'],
        '413206': ['Solapur', 'Maharashtra'],
        '413208': ['Solapur', 'Maharashtra'],
        '413209': ['Solapur', 'Maharashtra'],
        '413210': ['Solapur', 'Maharashtra'],
        '413211': ['Solapur', 'Maharashtra'],
        '413212': ['Solapur', 'Maharashtra'],
        '413213': ['Solapur', 'Maharashtra'],
        '413214': ['Solapur', 'Maharashtra'],
        '413215': ['Solapur', 'Maharashtra'],
        '413216': ['Solapur', 'Maharashtra'],
        '413217': ['Solapur', 'Maharashtra'],
        '413218': ['Solapur', 'Maharashtra'],
        '413219': ['Solapur', 'Maharashtra'],
        '413220': ['Solapur', 'Maharashtra'],
        '413221': ['Solapur', 'Maharashtra'],
        '413222': ['Solapur', 'Maharashtra'],
        '413223': ['Solapur', 'Maharashtra'],
        '413224': ['Solapur', 'Maharashtra'],
        '413226': ['Solapur', 'Maharashtra'],
        '413227': ['Solapur', 'Maharashtra'],
        '413228': ['Solapur', 'Maharashtra'],
        '413248': ['Solapur', 'Maharashtra'],
        '413250': ['Solapur', 'Maharashtra'],
        '413251': ['Solapur', 'Maharashtra'],
        '413252': ['Solapur', 'Maharashtra'],
        '413253': ['Solapur', 'Maharashtra'],
        '413255': ['Solapur', 'Maharashtra'],
        '413301': ['Solapur', 'Maharashtra'],
        '413302': ['Solapur', 'Maharashtra'],
        '413303': ['Solapur', 'Maharashtra'],
        '413304': ['Solapur', 'Maharashtra'],
        '413305': ['Solapur', 'Maharashtra'],
        '413306': ['Solapur', 'Maharashtra'],
        '413307': ['Solapur', 'Maharashtra'],
        '413308': ['Solapur', 'Maharashtra'],
        '413309': ['Solapur', 'Maharashtra'],
        '413310': ['Solapur', 'Maharashtra'],
        '413314': ['Solapur', 'Maharashtra'],
        '413315': ['Solapur', 'Maharashtra'],
        '413317': ['Solapur', 'Maharashtra'],
        '413318': ['Sangli', 'Maharashtra'],
        '413319': ['Solapur', 'Maharashtra'],
        '413322': ['Solapur', 'Maharashtra'],
        '413324': ['Solapur', 'Maharashtra'],
        '413401': ['Solapur', 'Maharashtra'],
        '413402': ['Solapur', 'Maharashtra'],
        '413403': ['Solapur', 'Maharashtra'],
        '413404': ['Solapur', 'Maharashtra'],
        '413406': ['Solapur', 'Maharashtra'],
        '413409': ['Solapur', 'Maharashtra'],
        '413410': ['Solapur', 'Maharashtra'],
        '413411': ['Solapur', 'Maharashtra'],
        '413412': ['Solapur', 'Maharashtra'],
        '413701': ['AhmedNagar', 'Maharashtra'],
        '413702': ['AhmedNagar', 'Maharashtra'],
        '413703': ['Ahmednagar', 'Maharashtra'],
        '413704': ['AhmedNagar', 'Maharashtra'],
        '413706': ['Ahmednagar', 'Maharashtra'],
        '413708': ['AhmedNagar', 'Maharashtra'],
        '413709': ['AhmedNagar', 'Maharashtra'],
        '413710': ['AhmedNagar', 'Maharashtra'],
        '413711': ['AhmedNagar', 'Maharashtra'],
        '413712': ['AhmedNagar', 'Maharashtra'],
        '413713': ['Ahmednagar', 'Maharashtra'],
        '413714': ['AhmedNagar', 'Maharashtra'],
        '413715': ['Ahmednagar', 'Maharashtra'],
        '413716': ['Ahmednagar', 'Maharashtra'],
        '413718': ['Ahmednagar', 'Maharashtra'],
        '413719': ['Ahmednagar', 'Maharashtra'],
        '413720': ['Ahmednagar', 'Maharashtra'],
        '413721': ['AhmedNagar', 'Maharashtra'],
        '413722': ['AhmedNagar', 'Maharashtra'],
        '413723': ['AhmedNagar', 'Maharashtra'],
        '413724': ['AhmedNagar', 'Maharashtra'],
        '413725': ['Ahmednagar', 'Maharashtra'],
        '413728': ['AhmedNagar', 'Maharashtra'],
        '413736': ['Ahmednagar', 'Maharashtra'],
        '413737': ['AhmedNagar', 'Maharashtra'],
        '413739': ['AhmedNagar', 'Maharashtra'],
        '413801': ['Pune', 'Maharashtra'],
        '413802': ['Pune', 'Maharashtra'],
        '414001': ['Ahmednagar', 'Maharashtra'],
        '414002': ['Ahmednagar', 'Maharashtra'],
        '414005': ['AhmedNagar', 'Maharashtra'],
        '414101': ['AhmedNagar', 'Maharashtra'],
        '414102': ['AhmedNagar', 'Maharashtra'],
        '414103': ['Ahmednagar', 'Maharashtra'],
        '414106': ['Ahmednagar', 'Maharashtra'],
        '414201': ['Ahmednagar', 'Maharashtra'],
        '414208': ['Pune', 'Maharashtra'],
        '414301': ['AhmedNagar', 'Maharashtra'],
        '414302': ['Ahmednagar', 'Maharashtra'],
        '414303': ['AhmedNagar', 'Maharashtra'],
        '414304': ['AhmedNagar', 'Maharashtra'],
        '414305': ['AhmedNagar', 'Maharashtra'],
        '414306': ['AhmedNagar', 'Maharashtra'],
        '414402': ['AhmedNagar', 'Maharashtra'],
        '414403': ['AhmedNagar', 'Maharashtra'],
        '414405': ['AhmedNagar', 'Maharashtra'],
        '414501': ['Ahmednagar', 'Maharashtra'],
        '414502': ['AhmedNagar', 'Maharashtra'],
        '414504': ['AhmedNagar', 'Maharashtra'],
        '414505': ['AhmedNagar', 'Maharashtra'],
        '414601': ['AhmedNagar', 'Maharashtra'],
        '414602': ['AhmedNagar', 'Maharashtra'],
        '414603': ['AhmedNagar', 'Maharashtra'],
        '414604': ['Ahmednagar', 'Maharashtra'],
        '414605': ['Ahmednagar', 'Maharashtra'],
        '414606': ['Ahmednagar', 'Maharashtra'],
        '414607': ['AhmedNagar', 'Maharashtra'],
        '414701': ['AhmedNagar', 'Maharashtra'],
        '414702': ['Ahmednagar', 'Maharashtra'],
        '415101': ['Kolhapur', 'Maharashtra'],
        '415301': ['Sangli', 'Maharashtra'],
        '415302': ['Sangli', 'Maharashtra'],
        '415303': ['Sangli', 'Maharashtra'],
        '415304': ['Sangli', 'Maharashtra'],
        '415305': ['Sangli', 'Maharashtra'],
        '415306': ['Sangli', 'Maharashtra'],
        '415307': ['Sangli', 'Maharashtra'],
        '415308': ['Sangli', 'Maharashtra'],
        '415309': ['Sangli', 'Maharashtra'],
        '415310': ['Sangli', 'Maharashtra'],
        '415311': ['Sangli', 'Maharashtra'],
        '415313': ['Sangli', 'Maharashtra'],
        '415315': ['Sangli', 'Maharashtra'],
        '415401': ['Sangli', 'Maharashtra'],
        '415402': ['Sangli', 'Maharashtra'],
        '415403': ['Sangli', 'Maharashtra'],
        '415404': ['Sangli', 'Maharashtra'],
        '415405': ['Sangli', 'Maharashtra'],
        '415406': ['Sangli', 'Maharashtra'],
        '415407': ['Sangli', 'Maharashtra'],
        '415408': ['Sangli', 'Maharashtra'],
        '415409': ['Sangli', 'Maharashtra'],
        '415410': ['Sangli', 'Maharashtra'],
        '415411': ['Sangli', 'Maharashtra'],
        '415412': ['Sangli', 'Maharashtra'],
        '415413': ['Sangli', 'Maharashtra'],
        '415414': ['Sangli', 'Maharashtra'],
        '415415': ['Sangli', 'Maharashtra'],
        '415416': ['Sangli', 'Maharashtra'],
        '415417': ['Sangli', 'Maharashtra'],
        '415418': ['Sangli', 'Maharashtra'],
        '415705': ['AhmedNagar', 'Maharashtra'],
        '416001': ['Kolhapur', 'Maharashtra'],
        '416002': ['Kolhapur', 'Maharashtra'],
        '416003': ['Kolhapur', 'Maharashtra'],
        '416004': ['Kolhapur', 'Maharashtra'],
        '416005': ['Kolhapur', 'Maharashtra'],
        '416006': ['Kolhapur', 'Maharashtra'],
        '416007': ['Kolhapur', 'Maharashtra'],
        '416008': ['Kolhapur', 'Maharashtra'],
        '416010': ['Kolhapur', 'Maharashtra'],
        '416011': ['Kolhapur', 'Maharashtra'],
        '416012': ['Kolhapur', 'Maharashtra'],
        '416013': ['Kolhapur', 'Maharashtra'],
        '416101': ['Kolhapur', 'Maharashtra'],
        '416102': ['Kolhapur', 'Maharashtra'],
        '416103': ['Kolhapur', 'Maharashtra'],
        '416104': ['Kolhapur', 'Maharashtra'],
        '416105': ['Kolhapur', 'Maharashtra'],
        '416106': ['Kolhapur', 'Maharashtra'],
        '416107': ['Kolhapur', 'Maharashtra'],
        '416108': ['Kolhapur', 'Maharashtra'],
        '416109': ['Kolhapur', 'Maharashtra'],
        '416110': ['Kolhapur', 'Maharashtra'],
        '416111': ['Kolhapur', 'Maharashtra'],
        '416112': ['Kolhapur', 'Maharashtra'],
        '416113': ['Kolhapur', 'Maharashtra'],
        '416114': ['Kolhapur', 'Maharashtra'],
        '416115': ['Kolhapur', 'Maharashtra'],
        '416116': ['Kolhapur', 'Maharashtra'],
        '416117': ['Kolhapur', 'Maharashtra'],
        '416118': ['Kolhapur', 'Maharashtra'],
        '416119': ['Kolhapur', 'Maharashtra'],
        '416120': ['Kolhapur', 'Maharashtra'],
        '416121': ['Kolhapur', 'Maharashtra'],
        '416122': ['Kolhapur', 'Maharashtra'],
        '416129': ['Kolhapur', 'Maharashtra'],
        '416130': ['Kolhapur', 'Maharashtra'],
        '416132': ['Kolhapur', 'Maharashtra'],
        '416134': ['Kolhapur', 'Maharashtra'],
        '416137': ['Kolhapur', 'Maharashtra'],
        '416138': ['Goa', 'Goa'],
        '416143': ['Kolhapur', 'Maharashtra'],
        '416144': ['Kolhapur', 'Maharashtra'],
        '416146': ['Kolhapur', 'Maharashtra'],
        '416201': ['Kolhapur', 'Maharashtra'],
        '416202': ['Kolhapur', 'Maharashtra'],
        '416203': ['Kolhapur', 'Maharashtra'],
        '416204': ['Kolhapur', 'Maharashtra'],
        '416205': ['Kolhapur', 'Maharashtra'],
        '416206': ['Kolhapur', 'Maharashtra'],
        '416207': ['Kolhapur', 'Maharashtra'],
        '416208': ['Kolhapur', 'Maharashtra'],
        '416209': ['Kolhapur', 'Maharashtra'],
        '416210': ['Kolhapur', 'Maharashtra'],
        '416211': ['Kolhapur', 'Maharashtra'],
        '416212': ['Kolhapur', 'Maharashtra'],
        '416213': ['Kolhapur', 'Maharashtra'],
        '416214': ['Kolhapur', 'Maharashtra'],
        '416215': ['Kolhapur', 'Maharashtra'],
        '416216': ['Kolhapur', 'Maharashtra'],
        '416217': ['Kolhapur', 'Maharashtra'],
        '416218': ['Kolhapur', 'Maharashtra'],
        '416219': ['Kolhapur', 'Maharashtra'],
        '416220': ['Kolhapur', 'Maharashtra'],
        '416221': ['Kolhapur', 'Maharashtra'],
        '416222': ['Kolhapur', 'Maharashtra'],
        '416223': ['Kolhapur', 'Maharashtra'],
        '416224': ['Kolhapur', 'Maharashtra'],
        '416227': ['Kolhapur', 'Maharashtra'],
        '416229': ['Kolhapur', 'Maharashtra'],
        '416230': ['Kolhapur', 'Maharashtra'],
        '416231': ['Kolhapur', 'Maharashtra'],
        '416232': ['Kolhapur', 'Maharashtra'],
        '416234': ['Kolhapur', 'Maharashtra'],
        '416235': ['Kolhapur', 'Maharashtra'],
        '416236': ['Kolhapur', 'Maharashtra'],
        '416301': ['Sangli', 'Maharashtra'],
        '416302': ['Sangli', 'Maharashtra'],
        '416303': ['Sangli', 'Maharashtra'],
        '416304': ['Sangli', 'Maharashtra'],
        '416305': ['Sangli', 'Maharashtra'],
        '416306': ['Sangli', 'Maharashtra'],
        '416307': ['Sangli', 'Maharashtra'],
        '416308': ['Sangli', 'Maharashtra'],
        '416309': ['Sangli', 'Maharashtra'],
        '416310': ['Sangli', 'Maharashtra'],
        '416311': ['Sangli', 'Maharashtra'],
        '416312': ['Sangli', 'Maharashtra'],
        '416313': ['Sangli', 'Maharashtra'],
        '416314': ['Sangli', 'Maharashtra'],
        '416315': ['Sangli', 'Maharashtra'],
        '416316': ['Sangli', 'Maharashtra'],
        '416317': ['Sangli', 'Maharashtra'],
        '416319': ['Sangli', 'Maharashtra'],
        '416320': ['Sangli', 'Maharashtra'],
        '416322': ['Sangli', 'Maharashtra'],
        '416401': ['Sangli', 'Maharashtra'],
        '416402': ['Sangli', 'Maharashtra'],
        '416403': ['Sangli', 'Maharashtra'],
        '416404': ['Sangli', 'Maharashtra'],
        '416405': ['Sangli', 'Maharashtra'],
        '416406': ['Sangli', 'Maharashtra'],
        '416407': ['Sangli', 'Maharashtra'],
        '416408': ['Sangli', 'Maharashtra'],
        '416409': ['Sangli', 'Maharashtra'],
        '416410': ['Sangli', 'Maharashtra'],
        '416411': ['Sangli', 'Maharashtra'],
        '416412': ['Sangli', 'Maharashtra'],
        '416413': ['Sangli', 'Maharashtra'],
        '416414': ['Sangli', 'Maharashtra'],
        '416415': ['Sangli', 'Maharashtra'],
        '416416': ['Sangli', 'Maharashtra'],
        '416417': ['Sangli', 'Maharashtra'],
        '416418': ['Sangli', 'Maharashtra'],
        '416419': ['Sangli', 'Maharashtra'],
        '416420': ['Sangli', 'Maharashtra'],
        '416422': ['Sangli', 'Maharashtra'],
        '416423': ['Sangli', 'Maharashtra'],
        '416425': ['Sangli', 'Maharashtra'],
        '416426': ['Sangli', 'Maharashtra'],
        '416428': ['Sangli', 'Maharashtra'],
        '416436': ['Sangli', 'Maharashtra'],
        '416437': ['Sangli', 'Maharashtra'],
        '416501': ['Kolhapur', 'Maharashtra'],
        '416502': ['Kolhapur', 'Maharashtra'],
        '416503': ['Kolhapur', 'Maharashtra'],
        '416504': ['Kolhapur', 'Maharashtra'],
        '416505': ['Kolhapur', 'Maharashtra'],
        '416506': ['Kolhapur', 'Maharashtra'],
        '416507': ['Kolhapur', 'Maharashtra'],
        '416508': ['Kolhapur', 'Maharashtra'],
        '416509': ['Kolhapur', 'Maharashtra'],
        '416526': ['Kolhapur', 'Maharashtra'],
        '416527': ['Kolhapur', 'Maharashtra'],
        '416551': ['Kolhapur', 'Maharashtra'],
        '416552': ['Kolhapur', 'Maharashtra'],
        '421001': ['Thane', 'Maharashtra'],
        '421002': ['Thane', 'Maharashtra'],
        '421003': ['Thane', 'Maharashtra'],
        '421004': ['Thane', 'Maharashtra'],
        '421005': ['Thane', 'Maharashtra'],
        '421101': ['Thane', 'Maharashtra'],
        '421102': ['Thane', 'Maharashtra'],
        '421103': ['Thane', 'Maharashtra'],
        '421201': ['Thane', 'Maharashtra'],
        '421202': ['Thane', 'Maharashtra'],
        '421203': ['Thane', 'Maharashtra'],
        '421204': ['Navi Mumbai', 'Maharashtra'],
        '421206': ['Thane', 'Maharashtra'],
        '421301': ['Thane', 'Maharashtra'],
        '421302': ['Thane', 'Maharashtra'],
        '421303': ['Thane', 'Maharashtra'],
        '421305': ['Thane', 'Maharashtra'],
        '421306': ['Thane', 'Maharashtra'],
        '421308': ['Thane', 'Maharashtra'],
        '421311': ['Thane', 'Maharashtra'],
        '421312': ['Thane', 'Maharashtra'],
        '421401': ['Thane', 'Maharashtra'],
        '421402': ['Thane', 'Maharashtra'],
        '421403': ['Thane', 'Maharashtra'],
        '421501': ['Thane', 'Maharashtra'],
        '421502': ['Thane', 'Maharashtra'],
        '421503': ['Thane', 'Maharashtra'],
        '421505': ['Thane', 'Maharashtra'],
        '421506': ['Thane', 'Maharashtra'],
        '421601': ['Thane', 'Maharashtra'],
        '421602': ['Thane', 'Maharashtra'],
        '421603': ['Thane', 'Maharashtra'],
        '421604': ['Thane', 'Maharashtra'],
        '421605': ['Thane', 'Maharashtra'],
        '422001': ['Nashik', 'Maharashtra'],
        '422002': ['Nashik', 'Maharashtra'],
        '422003': ['Nashik', 'Maharashtra'],
        '422004': ['Nashik', 'Maharashtra'],
        '422005': ['Nashik', 'Maharashtra'],
        '422006': ['Nashik', 'Maharashtra'],
        '422007': ['Nashik', 'Maharashtra'],
        '422008': ['Nashik', 'Maharashtra'],
        '422009': ['Nashik', 'Maharashtra'],
        '422010': ['Nashik', 'Maharashtra'],
        '422011': ['Nashik', 'Maharashtra'],
        '422012': ['Nashik', 'Maharashtra'],
        '422013': ['Nashik', 'Maharashtra'],
        '422101': ['Nashik', 'Maharashtra'],
        '422102': ['Nashik', 'Maharashtra'],
        '422103': ['Nashik', 'Maharashtra'],
        '422104': ['Nashik', 'Maharashtra'],
        '422105': ['Nashik', 'Maharashtra'],
        '422112': ['Nashik', 'Maharashtra'],
        '422113': ['Nashik', 'Maharashtra'],
        '422201': ['Nashik', 'Maharashtra'],
        '422202': ['Nashik', 'Maharashtra'],
        '422203': ['Nashik', 'Maharashtra'],
        '422204': ['Nashik', 'Maharashtra'],
        '422205': ['Nashik', 'Maharashtra'],
        '422206': ['Nashik', 'Maharashtra'],
        '422207': ['Nashik', 'Maharashtra'],
        '422208': ['Nashik', 'Maharashtra'],
        '422209': ['Nashik', 'Maharashtra'],
        '422210': ['Nashik', 'Maharashtra'],
        '422211': ['Nashik', 'Maharashtra'],
        '422212': ['Nashik', 'Maharashtra'],
        '422213': ['Nashik', 'Maharashtra'],
        '422214': ['Nashik', 'Maharashtra'],
        '422215': ['Nashik', 'Maharashtra'],
        '422221': ['Nashik', 'Maharashtra'],
        '422222': ['Nashik', 'Maharashtra'],
        '422301': ['Nashik', 'Maharashtra'],
        '422302': ['Nashik', 'Maharashtra'],
        '422303': ['Nashik', 'Maharashtra'],
        '422304': ['Nashik', 'Maharashtra'],
        '422305': ['Nashik', 'Maharashtra'],
        '422306': ['Nashik', 'Maharashtra'],
        '422308': ['Nashik', 'Maharashtra'],
        '422401': ['Nashik', 'Maharashtra'],
        '422402': ['Nashik', 'Maharashtra'],
        '422403': ['Nashik', 'Maharashtra'],
        '422501': ['Nashik', 'Maharashtra'],
        '422502': ['Nashik', 'Maharashtra'],
        '422601': ['AhmedNagar', 'Maharashtra'],
        '422603': ['Ahmednagar', 'Maharashtra'],
        '422605': ['AhmedNagar', 'Maharashtra'],
        '422606': ['Nashik', 'Maharashtra'],
        '422610': ['AhmedNagar', 'Maharashtra'],
        '422611': ['AhmedNagar', 'Maharashtra'],
        '422620': ['AhmedNagar', 'Maharashtra'],
        '423101': ['Nashik', 'Maharashtra'],
        '423102': ['Nashik', 'Maharashtra'],
        '423103': ['Ahmednagar', 'Maharashtra'],
        '423104': ['Nashik', 'Maharashtra'],
        '423105': ['Nashik', 'Maharashtra'],
        '423106': ['Nashik', 'Maharashtra'],
        '423107': ['Ahmednagar', 'Maharashtra'],
        '423108': ['Nashik', 'Maharashtra'],
        '423109': ['AhmedNagar', 'Maharashtra'],
        '423110': ['Nashik', 'Maharashtra'],
        '423111': ['Nashik', 'Maharashtra'],
        '423117': ['Nashik', 'Maharashtra'],
        '423201': ['Nashik', 'Maharashtra'],
        '423202': ['Nashik', 'Maharashtra'],
        '423203': ['Nashik', 'Maharashtra'],
        '423204': ['Nashik', 'Maharashtra'],
        '423205': ['Nashik', 'Maharashtra'],
        '423206': ['Nashik', 'Maharashtra'],
        '423208': ['Nashik', 'Maharashtra'],
        '423212': ['Nashik', 'Maharashtra'],
        '423213': ['Nashik', 'Maharashtra'],
        '423301': ['Nashik', 'Maharashtra'],
        '423302': ['Nashik', 'Maharashtra'],
        '423303': ['Nashik', 'Maharashtra'],
        '423401': ['Nashik', 'Maharashtra'],
        '423402': ['Nashik', 'Maharashtra'],
        '423403': ['Nashik', 'Maharashtra'],
        '423501': ['Nashik', 'Maharashtra'],
        '423502': ['Nashik', 'Maharashtra'],
        '423601': ['Ahmednagar', 'Maharashtra'],
        '423602': ['AhmedNagar', 'Maharashtra'],
        '423603': ['Ahmednagar', 'Maharashtra'],
        '423605': ['AhmedNagar', 'Maharashtra'],
        '423607': ['AhmedNagar', 'Maharashtra'],
        '423701': ['Aurangabad', 'Maharashtra'],
        '423702': ['Aurangabad', 'Maharashtra'],
        '423703': ['Aurangabad', 'Maharashtra'],
        '424109': ['Nashik', 'Maharashtra'],
        '431001': ['Aurangabad', 'Maharashtra'],
        '431002': ['Aurangabad', 'Maharashtra'],
        '431003': ['Aurangabad', 'Maharashtra'],
        '431004': ['Aurangabad', 'Maharashtra'],
        '431005': ['Aurangabad', 'Maharashtra'],
        '431006': ['Aurangabad', 'Maharashtra'],
        '431007': ['Aurangabad', 'Maharashtra'],
        '431008': ['Aurangabad', 'Maharashtra'],
        '431009': ['Aurangabad', 'Maharashtra'],
        '431010': ['Aurangabad', 'Maharashtra'],
        '431011': ['Aurangabad', 'Maharashtra'],
        '431101': ['Aurangabad', 'Maharashtra'],
        '431102': ['Aurangabad', 'Maharashtra'],
        '431103': ['Aurangabad', 'Maharashtra'],
        '431104': ['Aurangabad', 'Maharashtra'],
        '431105': ['Aurangabad', 'Maharashtra'],
        '431106': ['Aurangabad', 'Maharashtra'],
        '431107': ['Aurangabad', 'Maharashtra'],
        '431108': ['Aurangabad', 'Maharashtra'],
        '431109': ['Aurangabad', 'Maharashtra'],
        '431110': ['Aurangabad', 'Maharashtra'],
        '431111': ['Aurangabad', 'Maharashtra'],
        '431112': ['Aurangabad', 'Maharashtra'],
        '431113': ['Aurangabad', 'Maharashtra'],
        '431115': ['Aurangabad', 'Maharashtra'],
        '431116': ['Aurangabad', 'Maharashtra'],
        '431117': ['Aurangabad', 'Maharashtra'],
        '431118': ['Aurangabad', 'Maharashtra'],
        '431119': ['Aurangabad', 'Maharashtra'],
        '431120': ['Aurangabad', 'Maharashtra'],
        '431121': ['Aurangabad', 'Maharashtra'],
        '431132': ['Aurangabad', 'Maharashtra'],
        '431133': ['Aurangabad', 'Maharashtra'],
        '431134': ['Aurangabad', 'Maharashtra'],
        '431135': ['Aurangabad', 'Maharashtra'],
        '431136': ['Aurangabad', 'Maharashtra'],
        '431137': ['Aurangabad', 'Maharashtra'],
        '431140': ['Aurangabad', 'Maharashtra'],
        '431147': ['Aurangabad', 'Maharashtra'],
        '431148': ['Aurangabad', 'Maharashtra'],
        '431150': ['Aurangabad', 'Maharashtra'],
        '431151': ['Aurangabad', 'Maharashtra'],
        '431152': ['Aurangabad', 'Maharashtra'],
        '431154': ['Aurangabad', 'Maharashtra'],
        '431201': ['Aurangabad', 'Maharashtra'],
        '431210': ['Aurangabad', 'Maharashtra'],
        '431212': ['Aurangabad', 'Maharashtra'],
        '431213': ['Aurangabad', 'Maharashtra'],
        '431501': ['Aurangabad', 'Maharashtra'],
        '440001': ['Nagpur', 'Maharashtra'],
        '440002': ['Nagpur', 'Maharashtra'],
        '440003': ['Nagpur', 'Maharashtra'],
        '440004': ['Nagpur', 'Maharashtra'],
        '440005': ['Nagpur', 'Maharashtra'],
        '440006': ['Nagpur', 'Maharashtra'],
        '440007': ['Nagpur', 'Maharashtra'],
        '440008': ['Nagpur', 'Maharashtra'],
        '440009': ['Nagpur', 'Maharashtra'],
        '440010': ['Nagpur', 'Maharashtra'],
        '440011': ['Nagpur', 'Maharashtra'],
        '440012': ['Nagpur', 'Maharashtra'],
        '440013': ['Nagpur', 'Maharashtra'],
        '440014': ['Nagpur', 'Maharashtra'],
        '440015': ['Nagpur', 'Maharashtra'],
        '440016': ['Nagpur', 'Maharashtra'],
        '440017': ['Nagpur', 'Maharashtra'],
        '440018': ['Nagpur', 'Maharashtra'],
        '440019': ['Nagpur', 'Maharashtra'],
        '440020': ['Nagpur', 'Maharashtra'],
        '440021': ['Nagpur', 'Maharashtra'],
        '440022': ['Nagpur', 'Maharashtra'],
        '440023': ['Nagpur', 'Maharashtra'],
        '440024': ['Nagpur', 'Maharashtra'],
        '440025': ['Nagpur', 'Maharashtra'],
        '440026': ['Nagpur', 'Maharashtra'],
        '440027': ['Nagpur', 'Maharashtra'],
        '440029': ['Nagpur', 'Maharashtra'],
        '440030': ['Nagpur', 'Maharashtra'],
        '440032': ['Nagpur', 'Maharashtra'],
        '440033': ['Nagpur', 'Maharashtra'],
        '440034': ['Nagpur', 'Maharashtra'],
        '440035': ['Nagpur', 'Maharashtra'],
        '440036': ['Nagpur', 'Maharashtra'],
        '440037': ['Nagpur', 'Maharashtra'],
        '441001': ['Nagpur', 'Maharashtra'],
        '441101': ['Nagpur', 'Maharashtra'],
        '441102': ['Nagpur', 'Maharashtra'],
        '441103': ['Nagpur', 'Maharashtra'],
        '441104': ['Nagpur', 'Maharashtra'],
        '441105': ['Nagpur', 'Maharashtra'],
        '441106': ['Nagpur', 'Maharashtra'],
        '441107': ['Nagpur', 'Maharashtra'],
        '441108': ['Nagpur', 'Maharashtra'],
        '441109': ['Nagpur', 'Maharashtra'],
        '441110': ['Nagpur', 'Maharashtra'],
        '441111': ['Nagpur', 'Maharashtra'],
        '441112': ['Nagpur', 'Maharashtra'],
        '441113': ['Nagpur', 'Maharashtra'],
        '441114': ['Nagpur', 'Maharashtra'],
        '441115': ['Nagpur', 'Maharashtra'],
        '441116': ['Nagpur', 'Maharashtra'],
        '441117': ['Nagpur', 'Maharashtra'],
        '441122': ['Nagpur', 'Maharashtra'],
        '441123': ['Nagpur', 'Maharashtra'],
        '441201': ['Nagpur', 'Maharashtra'],
        '441202': ['Nagpur', 'Maharashtra'],
        '441203': ['Nagpur', 'Maharashtra'],
        '441204': ['Nagpur', 'Maharashtra'],
        '441205': ['Chandrapur', 'Maharashtra'],
        '441206': ['Chandrapur', 'Maharashtra'],
        '441207': ['Chandrapur', 'Maharashtra'],
        '441210': ['Nagpur', 'Maharashtra'],
        '441212': ['Chandrapur', 'Maharashtra'],
        '441214': ['Nagpur', 'Maharashtra'],
        '441215': ['Chandrapur', 'Maharashtra'],
        '441216': ['Nagpur', 'Maharashtra'],
        '441221': ['Chandrapur', 'Maharashtra'],
        '441222': ['Chandrapur', 'Maharashtra'],
        '441223': ['Chandrapur', 'Maharashtra'],
        '441224': ['Chandrapur', 'Maharashtra'],
        '441225': ['Chandrapur', 'Maharashtra'],
        '441226': ['Chandrapur', 'Maharashtra'],
        '441228': ['Chandrapur', 'Maharashtra'],
        '441301': ['Nagpur', 'Maharashtra'],
        '441302': ['Nagpur', 'Maharashtra'],
        '441303': ['Nagpur', 'Maharashtra'],
        '441304': ['Nagpur', 'Maharashtra'],
        '441305': ['Nagpur', 'Maharashtra'],
        '441306': ['Nagpur', 'Maharashtra'],
        '441401': ['Nagpur', 'Maharashtra'],
        '441402': ['Nagpur', 'Maharashtra'],
        '441403': ['Nagpur', 'Maharashtra'],
        '441404': ['Nagpur', 'Maharashtra'],
        '441406': ['Nagpur', 'Maharashtra'],
        '441408': ['Nagpur', 'Maharashtra'],
        '441409': ['Nagpur', 'Maharashtra'],
        '441501': ['Nagpur', 'Maharashtra'],
        '441502': ['Nagpur', 'Maharashtra'],
        '441503': ['Nagpur', 'Maharashtra'],
        '441806': ['Nagpur', 'Maharashtra'],
        '441807': ['Nagpur', 'Maharashtra'],
        '441911': ['Nagpur', 'Maharashtra'],
        '441912': ['Nagpur', 'Maharashtra'],
        '441913': ['Nagpur', 'Maharashtra'],
        '441915': ['Chandrapur', 'Maharashtra'],
        '442107': ['Nagpur', 'Maharashtra'],
        '442202': ['Nagpur', 'Maharashtra'],
        '442302': ['Amravati', 'Maharashtra'],
        '442401': ['Chandrapur', 'Maharashtra'],
        '442402': ['Chandrapur', 'Maharashtra'],
        '442403': ['Chandrapur', 'Maharashtra'],
        '442404': ['Chandrapur', 'Maharashtra'],
        '442406': ['Chandrapur', 'Maharashtra'],
        '442501': ['Chandrapur', 'Maharashtra'],
        '442502': ['Chandrapur', 'Maharashtra'],
        '442503': ['Chandrapur', 'Maharashtra'],
        '442504': ['Chandrapur', 'Maharashtra'],
        '442505': ['Chandrapur', 'Maharashtra'],
        '442506': ['Chandrapur', 'Maharashtra'],
        '442507': ['Chandrapur', 'Maharashtra'],
        '442701': ['Chandrapur', 'Maharashtra'],
        '442702': ['Chandrapur', 'Maharashtra'],
        '442706': ['Chandrapur', 'Maharashtra'],
        '442901': ['Chandrapur', 'Maharashtra'],
        '442902': ['Chandrapur', 'Maharashtra'],
        '442903': ['Chandrapur', 'Maharashtra'],
        '442904': ['Chandrapur', 'Maharashtra'],
        '442905': ['Chandrapur', 'Maharashtra'],
        '442906': ['Chandrapur', 'Maharashtra'],
        '442907': ['Chandrapur', 'Maharashtra'],
        '442908': ['Chandrapur', 'Maharashtra'],
        '442910': ['Chandrapur', 'Maharashtra'],
        '442912': ['Chandrapur', 'Maharashtra'],
        '442913': ['Chandrapur', 'Maharashtra'],
        '442914': ['Chandrapur', 'Maharashtra'],
        '442916': ['Chandrapur', 'Maharashtra'],
        '442917': ['Chandrapur', 'Maharashtra'],
        '442918': ['Chandrapur', 'Maharashtra'],
        '442919': ['Nagpur', 'Maharashtra'],
        '444110': ['Nagpur', 'Maharashtra'],
        '444123': ['Amravati', 'Maharashtra'],
        '444402': ['Chandrapur', 'Maharashtra'],
        '444601': ['Amravati', 'Maharashtra'],
        '444602': ['Amravati', 'Maharashtra'],
        '444603': ['Amravati', 'Maharashtra'],
        '444604': ['Amravati', 'Maharashtra'],
        '444605': ['Amravati', 'Maharashtra'],
        '444606': ['Amravati', 'Maharashtra'],
        '444607': ['Amravati', 'Maharashtra'],
        '444701': ['Amravati', 'Maharashtra'],
        '444702': ['Amravati', 'Maharashtra'],
        '444704': ['Amravati', 'Maharashtra'],
        '444705': ['Amravati', 'Maharashtra'],
        '444706': ['Amravati', 'Maharashtra'],
        '444707': ['Amravati', 'Maharashtra'],
        '444708': ['Amravati', 'Maharashtra'],
        '444709': ['Amravati', 'Maharashtra'],
        '444710': ['Amravati', 'Maharashtra'],
        '444711': ['Amravati', 'Maharashtra'],
        '444713': ['Amravati', 'Maharashtra'],
        '444716': ['Amravati', 'Maharashtra'],
        '444717': ['Amravati', 'Maharashtra'],
        '444718': ['Amravati', 'Maharashtra'],
        '444719': ['Amravati', 'Maharashtra'],
        '444720': ['Amravati', 'Maharashtra'],
        '444723': ['Amravati', 'Maharashtra'],
        '444801': ['Amravati', 'Maharashtra'],
        '444802': ['Amravati', 'Maharashtra'],
        '444803': ['Amravati', 'Maharashtra'],
        '444804': ['Amravati', 'Maharashtra'],
        '444805': ['Amravati', 'Maharashtra'],
        '444806': ['Amravati', 'Maharashtra'],
        '444807': ['Amravati', 'Maharashtra'],
        '444808': ['Amravati', 'Maharashtra'],
        '444809': ['Amravati', 'Maharashtra'],
        '444810': ['Amravati', 'Maharashtra'],
        '444811': ['Amravati', 'Maharashtra'],
        '444812': ['Amravati', 'Maharashtra'],
        '444813': ['Amravati', 'Maharashtra'],
        '444814': ['Amravati', 'Maharashtra'],
        '444815': ['Amravati', 'Maharashtra'],
        '444816': ['Amravati', 'Maharashtra'],
        '444818': ['Amravati', 'Maharashtra'],
        '444819': ['Amravati', 'Maharashtra'],
        '444821': ['Amravati', 'Maharashtra'],
        '444826': ['Amravati', 'Maharashtra'],
        '444827': ['Amravati', 'Maharashtra'],
        '444901': ['Amravati', 'Maharashtra'],
        '444902': ['Amravati', 'Maharashtra'],
        '444903': ['Amravati', 'Maharashtra'],
        '444904': ['Amravati', 'Maharashtra'],
        '444905': ['Amravati', 'Maharashtra'],
        '444906': ['Amravati', 'Maharashtra'],
        '444907': ['Amravati', 'Maharashtra'],
        '444908': ['Amravati', 'Maharashtra'],
        '444909': ['Amravati', 'Maharashtra'],
        '444914': ['Amravati', 'Maharashtra'],
        '444915': ['Amravati', 'Maharashtra'],
        '445324': ['Nagpur', 'Maharashtra'],
        '452001': ['Indore', 'Madhya Pradesh'],
        '452002': ['Indore', 'Madhya Pradesh'],
        '452003': ['Indore', 'Madhya Pradesh'],
        '452004': ['Indore', 'Madhya Pradesh'],
        '452005': ['Indore', 'Madhya Pradesh'],
        '452006': ['Indore', 'Madhya Pradesh'],
        '452007': ['Indore', 'Madhya Pradesh'],
        '452008': ['Indore', 'Madhya Pradesh'],
        '452009': ['Indore', 'Madhya Pradesh'],
        '452010': ['Indore', 'Madhya Pradesh'],
        '452011': ['Indore', 'Madhya Pradesh'],
        '452012': ['Indore', 'Madhya Pradesh'],
        '452013': ['Indore', 'Madhya Pradesh'],
        '452014': ['Indore', 'Madhya Pradesh'],
        '452015': ['Indore', 'Madhya Pradesh'],
        '452016': ['Indore', 'Madhya Pradesh'],
        '452018': ['Indore', 'Madhya Pradesh'],
        '452020': ['Indore', 'Madhya Pradesh'],
        '453001': ['Indore', 'Madhya Pradesh'],
        '453111': ['Indore', 'Madhya Pradesh'],
        '453112': ['Indore', 'Madhya Pradesh'],
        '453115': ['Indore', 'Madhya Pradesh'],
        '453220': ['Indore', 'Madhya Pradesh'],
        '453235': ['Indore', 'Madhya Pradesh'],
        '453331': ['Indore', 'Madhya Pradesh'],
        '453332': ['Indore', 'Madhya Pradesh'],
        '453441': ['Indore', 'Madhya Pradesh'],
        '453446': ['Indore', 'Madhya Pradesh'],
        '453463': ['Indore', 'Madhya Pradesh'],
        '453551': ['Indore', 'Madhya Pradesh'],
        '453552': ['Indore', 'Madhya Pradesh'],
        '453555': ['Indore', 'Madhya Pradesh'],
        '453556': ['Indore', 'Madhya Pradesh'],
        '453661': ['Indore', 'Madhya Pradesh'],
        '453771': ['Indore', 'Madhya Pradesh'],
        '455115': ['Indore', 'Madhya Pradesh'],
        '456001': ['Ujjain', 'Madhya Pradesh'],
        '456003': ['Ujjain', 'Madhya Pradesh'],
        '456006': ['Ujjain', 'Madhya Pradesh'],
        '456010': ['Ujjain', 'Madhya Pradesh'],
        '456221': ['Ujjain', 'Madhya Pradesh'],
        '456222': ['Ujjain', 'Madhya Pradesh'],
        '456224': ['Ujjain', 'Madhya Pradesh'],
        '456313': ['Ujjain', 'Madhya Pradesh'],
        '456331': ['Ujjain', 'Madhya Pradesh'],
        '456335': ['Ujjain', 'Madhya Pradesh'],
        '456337': ['Ujjain', 'Madhya Pradesh'],
        '456440': ['Ujjain', 'Madhya Pradesh'],
        '456441': ['Ujjain', 'Madhya Pradesh'],
        '456443': ['Ujjain', 'Madhya Pradesh'],
        '456457': ['Ujjain', 'Madhya Pradesh'],
        '456550': ['Ujjain', 'Madhya Pradesh'],
        '456661': ['Ujjain', 'Madhya Pradesh'],
        '456664': ['Ujjain', 'Madhya Pradesh'],
        '456665': ['Ujjain', 'Madhya Pradesh'],
        '456668': ['Ujjain', 'Madhya Pradesh'],
        '456770': ['Ujjain', 'Madhya Pradesh'],
        '456771': ['Ujjain', 'Madhya Pradesh'],
        '456776': ['Ujjain', 'Madhya Pradesh'],
        '462001': ['Bhopal', 'Madhya Pradesh'],
        '462002': ['Bhopal', 'Madhya Pradesh'],
        '462003': ['Bhopal', 'Madhya Pradesh'],
        '462004': ['Bhopal', 'Madhya Pradesh'],
        '462007': ['Bhopal', 'Madhya Pradesh'],
        '462008': ['Bhopal', 'Madhya Pradesh'],
        '462010': ['Bhopal', 'Madhya Pradesh'],
        '462011': ['Bhopal', 'Madhya Pradesh'],
        '462012': ['Bhopal', 'Madhya Pradesh'],
        '462013': ['Bhopal', 'Madhya Pradesh'],
        '462016': ['Bhopal', 'Madhya Pradesh'],
        '462018': ['Bhopal', 'Madhya Pradesh'],
        '462020': ['Bhopal', 'Madhya Pradesh'],
        '462021': ['Bhopal', 'Madhya Pradesh'],
        '462022': ['Bhopal', 'Madhya Pradesh'],
        '462023': ['Bhopal', 'Madhya Pradesh'],
        '462024': ['Bhopal', 'Madhya Pradesh'],
        '462026': ['Bhopal', 'Madhya Pradesh'],
        '462027': ['Bhopal', 'Madhya Pradesh'],
        '462030': ['Bhopal', 'Madhya Pradesh'],
        '462031': ['Bhopal', 'Madhya Pradesh'],
        '462032': ['Bhopal', 'Madhya Pradesh'],
        '462033': ['Bhopal', 'Madhya Pradesh'],
        '462036': ['Bhopal', 'Madhya Pradesh'],
        '462037': ['Bhopal', 'Madhya Pradesh'],
        '462038': ['Bhopal', 'Madhya Pradesh'],
        '462039': ['Bhopal', 'Madhya Pradesh'],
        '462040': ['Bhopal', 'Madhya Pradesh'],
        '462041': ['Bhopal', 'Madhya Pradesh'],
        '462042': ['Bhopal', 'Madhya Pradesh'],
        '462043': ['Bhopal', 'Madhya Pradesh'],
        '462044': ['Bhopal', 'Madhya Pradesh'],
        '462045': ['Bhopal', 'Madhya Pradesh'],
        '462046': ['Bhopal', 'Madhya Pradesh'],
        '462047': ['Bhopal', 'Madhya Pradesh'],
        '462066': ['Bhopal', 'Madhya Pradesh'],
        '462100': ['Bhopal', 'Madhya Pradesh'],
        '462101': ['Bhopal', 'Madhya Pradesh'],
        '462120': ['Bhopal', 'Madhya Pradesh'],
        '463106': ['Bhopal', 'Madhya Pradesh'],
        '463111': ['Bhopal', 'Madhya Pradesh'],
        '463120': ['Bhopal', 'Madhya Pradesh'],
        '464334': ['Bhopal', 'Madhya Pradesh'],
        '464570': ['Bhopal', 'Madhya Pradesh'],
        '473445': ['Indore', 'Madhya Pradesh'],
        '481883': ['Bhopal', 'Madhya Pradesh'],
        '483225': ['Raipur', 'Madhya Pradesh'],
        '484447': ['Bhopal', 'Madhya Pradesh'],
        '486004': ['Bhopal', 'Madhya Pradesh'],
        '490011': ['Bhilai', 'Madhya Pradesh'],
        '490022': ['Bhilai', 'Madhya Pradesh'],
        '490023': ['Bhilai', 'Madhya Pradesh'],
        '490036': ['Bhilai', 'Madhya Pradesh'],
        '490042': ['Bhilai', 'Madhya Pradesh'],
        '492001': ['Raipur', 'Chhattisgarh'],
        '492002': ['Raipur', 'Chhattisgarh'],
        '492003': ['Raipur', 'Chhattisgarh'],
        '492004': ['Raipur', 'Chhattisgarh'],
        '492005': ['Raipur', 'Chhattisgarh'],
        '492006': ['Raipur', 'Madhya Pradesh'],
        '492007': ['Raipur', 'Madhya Pradesh'],
        '492008': ['Raipur', 'Chhattisgarh'],
        '492009': ['Raipur', 'Chhattisgarh'],
        '492010': ['Raipur', 'Chhattisgarh'],
        '492012': ['Raipur', 'Chhattisgarh'],
        '492013': ['Raipur', 'Chhattisgarh'],
        '492014': ['Raipur', 'Chhattisgarh'],
        '492015': ['Raipur', 'Chhattisgarh'],
        '492099': ['Raipur', 'Chhattisgarh'],
        '492101': ['Raipur', 'Chhattisgarh'],
        '492109': ['Raipur', 'Chhattisgarh'],
        '492112': ['Raipur', 'Madhya Pradesh'],
        '493101': ['Raipur', 'Chhattisgarh'],
        '493111': ['Raipur', 'Chhattisgarh'],
        '493113': ['Raipur', 'Chhattisgarh'],
        '493114': ['Raipur', 'Chhattisgarh'],
        '493116': ['Raipur', 'Chhattisgarh'],
        '493118': ['Raipur', 'Chhattisgarh'],
        '493195': ['Raipur', 'Chhattisgarh'],
        '493196': ['Raipur', 'Chhattisgarh'],
        '493221': ['Raipur', 'Chhattisgarh'],
        '493222': ['Raipur', 'Chhattisgarh'],
        '493225': ['Raipur', 'Chhattisgarh'],
        '493228': ['Raipur', 'Chhattisgarh'],
        '493229': ['Raipur', 'Chhattisgarh'],
        '493330': ['Raipur', 'Madhya Pradesh'],
        '493331': ['Raipur', 'Chhattisgarh'],
        '493332': ['Raipur', 'Chhattisgarh'],
        '493335': ['Raipur', 'Chhattisgarh'],
        '493338': ['Raipur', 'Chhattisgarh'],
        '493344': ['Raipur', 'Chhattisgarh'],
        '493441': ['Raipur', 'Chhattisgarh'],
        '493445': ['Raipur', 'Madhya Pradesh'],
        '493449': ['Raipur', 'Madhya Pradesh'],
        '493526': ['Raipur', 'Chhattisgarh'],
        '493551': ['Raipur', 'Madhya Pradesh'],
        '493555': ['Raipur', 'Madhya Pradesh'],
        '493558': ['Raipur', 'Madhya Pradesh'],
        '493559': ['Raipur', 'Chhattisgarh'],
        '493562': ['Raipur', 'Madhya Pradesh'],
        '493661': ['Raipur', 'Chhattisgarh'],
        '493663': ['Raipur', 'Madhya Pradesh'],
        '493773': ['Raipur', 'Madhya Pradesh'],
        '493778': ['Raipur', 'Madhya Pradesh'],
        '493881': ['Raipur', 'Chhattisgarh'],
        '493885': ['Raipur', 'Chhattisgarh'],
        '493888': ['Raipur', 'Chhattisgarh'],
        '493889': ['Raipur', 'Chhattisgarh'],
        '493890': ['Raipur', 'Chhattisgarh'],
        '493895': ['Raipur', 'Madhya Pradesh'],
        '493992': ['Raipur', 'Chhattisgarh'],
        '493996': ['Raipur', 'Chhattisgarh'],
        '500001': ['Hyderabad', 'Telangana'],
        '500002': ['Hyderabad', 'Telangana'],
        '500003': ['Hyderabad', 'Telangana'],
        '500004': ['Hyderabad', 'Telangana'],
        '500005': ['Hyderabad', 'Telangana'],
        '500006': ['Hyderabad', 'Telangana'],
        '500007': ['Hyderabad', 'Telangana'],
        '500008': ['Hyderabad', 'Telangana'],
        '500009': ['Hyderabad', 'Telangana'],
        '500010': ['Hyderabad', 'Telangana'],
        '500011': ['Hyderabad', 'Telangana'],
        '500012': ['Hyderabad', 'Telangana'],
        '500013': ['Hyderabad', 'Telangana'],
        '500014': ['Hyderabad', 'Telangana'],
        '500015': ['Hyderabad', 'Telangana'],
        '500016': ['Hyderabad', 'Telangana'],
        '500017': ['Hyderabad', 'Telangana'],
        '500018': ['Hyderabad', 'Telangana'],
        '500020': ['Hyderabad', 'Telangana'],
        '500021': ['Hyderabad', 'Telangana'],
        '500022': ['Hyderabad', 'Telangana'],
        '500023': ['Hyderabad', 'Telangana'],
        '500024': ['Hyderabad', 'Telangana'],
        '500025': ['Hyderabad', 'Telangana'],
        '500026': ['Hyderabad', 'Telangana'],
        '500027': ['Hyderabad', 'Telangana'],
        '500028': ['Hyderabad', 'Telangana'],
        '500029': ['Hyderabad', 'Telangana'],
        '500031': ['Hyderabad', 'Telangana'],
        '500033': ['Hyderabad', 'Telangana'],
        '500034': ['Hyderabad', 'Telangana'],
        '500036': ['Hyderabad', 'Telangana'],
        '500037': ['Hyderabad', 'Telangana'],
        '500038': ['Hyderabad', 'Telangana'],
        '500039': ['Hyderabad', 'Telangana'],
        '500040': ['Hyderabad', 'Telangana'],
        '500041': ['Hyderabad', 'Telangana'],
        '500042': ['Hyderabad', 'Telangana'],
        '500043': ['Hyderabad', 'Telangana'],
        '500044': ['Hyderabad', 'Telangana'],
        '500045': ['Hyderabad', 'Telangana'],
        '500046': ['Hyderabad', 'Telangana'],
        '500047': ['Hyderabad', 'Telangana'],
        '500048': ['Hyderabad', 'Telangana'],
        '500049': ['Hyderabad', 'Telangana'],
        '500050': ['Hyderabad', 'Telangana'],
        '500051': ['Hyderabad', 'Telangana'],
        '500053': ['Hyderabad', 'Telangana'],
        '500054': ['Hyderabad', 'Telangana'],
        '500055': ['Hyderabad', 'Telangana'],
        '500056': ['Hyderabad', 'Telangana'],
        '500057': ['Hyderabad', 'Telangana'],
        '500058': ['Hyderabad', 'Telangana'],
        '500059': ['Hyderabad', 'Telangana'],
        '500061': ['Hyderabad', 'Telangana'],
        '500062': ['Hyderabad', 'Telangana'],
        '500063': ['Hyderabad', 'Telangana'],
        '500064': ['Hyderabad', 'Telangana'],
        '500065': ['Hyderabad', 'Telangana'],
        '500066': ['Hyderabad', 'Telangana'],
        '500067': ['Hyderabad', 'Telangana'],
        '500068': ['Hyderabad', 'Telangana'],
        '500069': ['Hyderabad', 'Telangana'],
        '500072': ['Hyderabad', 'Telangana'],
        '500073': ['Hyderabad', 'Telangana'],
        '500074': ['Hyderabad', 'Telangana'],
        '500076': ['Hyderabad', 'Telangana'],
        '500077': ['Hyderabad', 'Telangana'],
        '500078': ['Hyderabad', 'Telangana'],
        '500080': ['Hyderabad', 'Telangana'],
        '500081': ['Hyderabad', 'Telangana'],
        '500082': ['Hyderabad', 'Telangana'],
        '500083': ['Hyderabad', 'Telangana'],
        '500085': ['Hyderabad', 'Telangana'],
        '500086': ['Hyderabad', 'Telangana'],
        '500087': ['Hyderabad', 'Telangana'],
        '500088': ['Hyderabad', 'Telangana'],
        '500089': ['Hyderabad', 'Telangana'],
        '500091': ['Hyderabad', 'Telangana'],
        '500092': ['Hyderabad', 'Telangana'],
        '500093': ['Hyderabad', 'Telangana'],
        '500094': ['Hyderabad', 'Telangana'],
        '500095': ['Hyderabad', 'Telangana'],
        '500096': ['Hyderabad', 'Telangana'],
        '500097': ['Hyderabad', 'Telangana'],
        '500098': ['Hyderabad', 'Telangana'],
        '500100': ['Secunderabad', 'Telangana'],
        '500101': ['Hyderabad', 'Telangana'],
        '500103': ['Hyderabad', 'Telangana'],
        '500104': ['Hyderabad', 'Telangana'],
        '500105': ['Hyderabad', 'Telangana'],
        '500107': ['Hyderabad', 'Telangana'],
        '500108': ['Hyderabad', 'Telangana'],
        '500110': ['Hyderabad', 'Telangana'],
        '500111': ['Hyderabad', 'Telangana'],
        '500133': ['Hyderabad', 'Telangana'],
        '500253': ['Hyderabad', 'Telangana'],
        '500258': ['Hyderabad', 'Telangana'],
        '500264': ['Hyderabad', 'Telangana'],
        '500265': ['Hyderabad', 'Telangana'],
        '500266': ['Hyderabad', 'Telangana'],
        '500267': ['Hyderabad', 'Telangana'],
        '500361': ['Hyderabad', 'Telangana'],
        '500380': ['Hyderabad', 'Telangana'],
        '500457': ['Hyderabad', 'Telangana'],
        '500463': ['Hyderabad', 'Telangana'],
        '500482': ['Hyderabad', 'Telangana'],
        '500486': ['Hyderabad', 'Telangana'],
        '500556': ['Hyderabad', 'Telangana'],
        '500587': ['Hyderabad', 'Telangana'],
        '500593': ['Hyderabad', 'Telangana'],
        '500594': ['Hyderabad', 'Telangana'],
        '500660': ['Hyderabad', 'Telangana'],
        '500762': ['Hyderabad', 'Telangana'],
        '500855': ['Hyderabad', 'Telangana'],
        '500872': ['Hyderabad', 'Telangana'],
        '500873': ['Hyderabad', 'Telangana'],
        '500890': ['Hyderabad', 'Telangana'],
        '501101': ['Hyderabad', 'Telangana'],
        '501102': ['Hyderabad', 'Telangana'],
        '501301': ['Hyderabad', 'Telangana'],
        '501401': ['Secunderabad', 'Telangana'],
        '501505': ['Hyderabad', 'Telangana'],
        '506103': ['Hyderabad', 'Telangana'],
        '506146': ['Hyderabad', 'Telangana'],
        '509133': ['Hyderabad', 'Telangana'],
        '516228': ['Belgaum', 'Karnataka'],
        '524347': ['Vijayawada', 'Andhra Pradesh'],
        '530001': ['Visakhapatnam', 'Andhra Pradesh'],
        '530002': ['Visakhapatnam', 'Andhra Pradesh'],
        '530003': ['Visakhapatnam', 'Andhra Pradesh'],
        '530004': ['Visakhapatnam', 'Andhra Pradesh'],
        '530005': ['Visakhapatnam', 'Andhra Pradesh'],
        '530006': ['Visakhapatnam', 'Andhra Pradesh'],
        '530007': ['Visakhapatnam', 'Andhra Pradesh'],
        '530008': ['Visakhapatnam', 'Andhra Pradesh'],
        '530009': ['Visakhapatnam', 'Andhra Pradesh'],
        '530010': ['Visakhapatnam', 'Andhra Pradesh'],
        '530011': ['Visakhapatnam', 'Andhra Pradesh'],
        '530012': ['Visakhapatnam', 'Andhra Pradesh'],
        '530013': ['Visakhapatnam', 'Andhra Pradesh'],
        '530014': ['Visakhapatnam', 'Andhra Pradesh'],
        '530015': ['Visakhapatnam', 'Andhra Pradesh'],
        '530016': ['Visakhapatnam', 'Andhra Pradesh'],
        '530017': ['Visakhapatnam', 'Andhra Pradesh'],
        '530018': ['Visakhapatnam', 'Andhra Pradesh'],
        '530020': ['Visakhapatnam', 'Andhra Pradesh'],
        '530021': ['Visakhapatnam', 'Andhra Pradesh'],
        '530022': ['Visakhapatnam', 'Andhra Pradesh'],
        '530023': ['Visakhapatnam', 'Andhra Pradesh'],
        '530024': ['Visakhapatnam', 'Andhra Pradesh'],
        '530026': ['Visakhapatnam', 'Andhra Pradesh'],
        '530027': ['Visakhapatnam', 'Andhra Pradesh'],
        '530028': ['Visakhapatnam', 'Andhra Pradesh'],
        '530029': ['Visakhapatnam', 'Andhra Pradesh'],
        '530031': ['Visakhapatnam', 'Andhra Pradesh'],
        '530032': ['Visakhapatnam', 'Andhra Pradesh'],
        '530035': ['Visakhapatnam', 'Andhra Pradesh'],
        '530040': ['Visakhapatnam', 'Andhra Pradesh'],
        '530041': ['Visakhapatnam', 'Andhra Pradesh'],
        '530042': ['Visakhapatnam', 'Andhra Pradesh'],
        '530043': ['Visakhapatnam', 'Andhra Pradesh'],
        '530044': ['Visakhapatnam', 'Andhra Pradesh'],
        '530045': ['Visakhapatnam', 'Andhra Pradesh'],
        '530046': ['Visakhapatnam', 'Andhra Pradesh'],
        '530047': ['Visakhapatnam', 'Andhra Pradesh'],
        '530048': ['Visakhapatnam', 'Andhra Pradesh'],
        '530049': ['Visakhapatnam', 'Andhra Pradesh'],
        '530051': ['Visakhapatnam', 'Andhra Pradesh'],
        '530052': ['Visakhapatnam', 'Andhra Pradesh'],
        '530053': ['Visakhapatnam', 'Andhra Pradesh'],
        '531001': ['Visakhapatnam', 'Andhra Pradesh'],
        '531002': ['Visakhapatnam', 'Andhra Pradesh'],
        '531011': ['Visakhapatnam', 'Andhra Pradesh'],
        '531019': ['Visakhapatnam', 'Andhra Pradesh'],
        '531020': ['Visakhapatnam', 'Andhra Pradesh'],
        '531021': ['Visakhapatnam', 'Andhra Pradesh'],
        '531022': ['Visakhapatnam', 'Andhra Pradesh'],
        '531023': ['Visakhapatnam', 'Andhra Pradesh'],
        '531024': ['Visakhapatnam', 'Andhra Pradesh'],
        '531025': ['Visakhapatnam', 'Andhra Pradesh'],
        '531026': ['Visakhapatnam', 'Andhra Pradesh'],
        '531027': ['Visakhapatnam', 'Andhra Pradesh'],
        '531028': ['Visakhapatnam', 'Andhra Pradesh'],
        '531029': ['Visakhapatnam', 'Andhra Pradesh'],
        '531030': ['Visakhapatnam', 'Andhra Pradesh'],
        '531031': ['Visakhapatnam', 'Andhra Pradesh'],
        '531032': ['Visakhapatnam', 'Andhra Pradesh'],
        '531033': ['Visakhapatnam', 'Andhra Pradesh'],
        '531034': ['Visakhapatnam', 'Andhra Pradesh'],
        '531035': ['Visakhapatnam', 'Andhra Pradesh'],
        '531036': ['Visakhapatnam', 'Andhra Pradesh'],
        '531040': ['Visakhapatnam', 'Andhra Pradesh'],
        '531055': ['Visakhapatnam', 'Andhra Pradesh'],
        '531060': ['Visakhapatnam', 'Andhra Pradesh'],
        '531061': ['Visakhapatnam', 'Andhra Pradesh'],
        '531075': ['Visakhapatnam', 'Andhra Pradesh'],
        '531077': ['Visakhapatnam', 'Andhra Pradesh'],
        '531081': ['Visakhapatnam', 'Andhra Pradesh'],
        '531082': ['Visakhapatnam', 'Andhra Pradesh'],
        '531083': ['Visakhapatnam', 'Andhra Pradesh'],
        '531084': ['Visakhapatnam', 'Andhra Pradesh'],
        '531085': ['Visakhapatnam', 'Andhra Pradesh'],
        '531087': ['Visakhapatnam', 'Andhra Pradesh'],
        '531105': ['Visakhapatnam', 'Andhra Pradesh'],
        '531110': ['Visakhapatnam', 'Andhra Pradesh'],
        '531111': ['Visakhapatnam', 'Andhra Pradesh'],
        '531113': ['Visakhapatnam', 'Andhra Pradesh'],
        '531114': ['Visakhapatnam', 'Andhra Pradesh'],
        '531115': ['Visakhapatnam', 'Andhra Pradesh'],
        '531116': ['Visakhapatnam', 'Andhra Pradesh'],
        '531117': ['Visakhapatnam', 'Andhra Pradesh'],
        '531118': ['Visakhapatnam', 'Andhra Pradesh'],
        '531126': ['Visakhapatnam', 'Andhra Pradesh'],
        '531127': ['Visakhapatnam', 'Andhra Pradesh'],
        '531133': ['Visakhapatnam', 'Andhra Pradesh'],
        '531145': ['Visakhapatnam', 'Andhra Pradesh'],
        '531148': ['Visakhapatnam', 'Andhra Pradesh'],
        '531149': ['Visakhapatnam', 'Andhra Pradesh'],
        '531151': ['Visakhapatnam', 'Andhra Pradesh'],
        '531152': ['Visakhapatnam', 'Andhra Pradesh'],
        '531160': ['Visakhapatnam', 'Andhra Pradesh'],
        '531161': ['Visakhapatnam', 'Andhra Pradesh'],
        '531162': ['Visakhapatnam', 'Andhra Pradesh'],
        '531163': ['Visakhapatnam', 'Andhra Pradesh'],
        '531172': ['Visakhapatnam', 'Andhra Pradesh'],
        '531173': ['Visakhapatnam', 'Andhra Pradesh'],
        '531219': ['Visakhapatnam', 'Andhra Pradesh'],
        '531240': ['Visakhapatnam', 'Andhra Pradesh'],
        '531281': ['Visakhapatnam', 'Andhra Pradesh'],
        '533347': ['Visakhapatnam', 'Andhra Pradesh'],
        '533348': ['Visakhapatnam', 'Andhra Pradesh'],
        '533349': ['Visakhapatnam', 'Andhra Pradesh'],
        '533350': ['Visakhapatnam', 'Andhra Pradesh'],
        '533351': ['Visakhapatnam', 'Andhra Pradesh'],
        '533352': ['Visakhapatnam', 'Andhra Pradesh'],
        '534435': ['Vijayawada', 'Andhra Pradesh'],
        '534444': ['Vijayawada', 'Andhra Pradesh'],
        '535005': ['Visakhapatnam', 'Andhra Pradesh'],
        '535145': ['Visakhapatnam', 'Andhra Pradesh'],
        '560001': ['Bangalore', 'Karnataka'],
        '560002': ['Bangalore', 'Karnataka'],
        '560003': ['Bangalore', 'Karnataka'],
        '560004': ['Bangalore', 'Karnataka'],
        '560005': ['Bangalore', 'Karnataka'],
        '560006': ['Bangalore', 'Karnataka'],
        '560007': ['Bangalore', 'Karnataka'],
        '560008': ['Bangalore', 'Karnataka'],
        '560009': ['Bangalore', 'Karnataka'],
        '560010': ['Bangalore', 'Karnataka'],
        '560011': ['Bangalore', 'Karnataka'],
        '560012': ['Bangalore', 'Karnataka'],
        '560013': ['Bangalore', 'Karnataka'],
        '560014': ['Bangalore', 'Karnataka'],
        '560015': ['Bangalore', 'Karnataka'],
        '560016': ['Bangalore', 'Karnataka'],
        '560017': ['Bangalore', 'Karnataka'],
        '560018': ['Bangalore', 'Karnataka'],
        '560019': ['Bangalore', 'Karnataka'],
        '560020': ['Bangalore', 'Karnataka'],
        '560021': ['Bangalore', 'Karnataka'],
        '560022': ['Bangalore', 'Karnataka'],
        '560023': ['Bangalore', 'Karnataka'],
        '560024': ['Bangalore', 'Karnataka'],
        '560025': ['Bangalore', 'Karnataka'],
        '560026': ['Bangalore', 'Karnataka'],
        '560027': ['Bangalore', 'Karnataka'],
        '560028': ['Bangalore', 'Karnataka'],
        '560029': ['Bangalore', 'Karnataka'],
        '560030': ['Bangalore', 'Karnataka'],
        '560031': ['Bangalore', 'Karnataka'],
        '560032': ['Bangalore', 'Karnataka'],
        '560033': ['Bangalore', 'Karnataka'],
        '560034': ['Bangalore', 'Karnataka'],
        '560035': ['Bangalore', 'Karnataka'],
        '560036': ['Bangalore', 'Karnataka'],
        '560037': ['Bangalore', 'Karnataka'],
        '560038': ['Bangalore', 'Karnataka'],
        '560039': ['Bangalore', 'Karnataka'],
        '560040': ['Bangalore', 'Karnataka'],
        '560041': ['Bangalore', 'Karnataka'],
        '560042': ['Bangalore', 'Karnataka'],
        '560043': ['Bangalore', 'Karnataka'],
        '560044': ['Bangalore', 'Karnataka'],
        '560045': ['Bangalore', 'Karnataka'],
        '560046': ['Bangalore', 'Karnataka'],
        '560047': ['Bangalore', 'Karnataka'],
        '560048': ['Bangalore', 'Karnataka'],
        '560049': ['Bangalore', 'Karnataka'],
        '560050': ['Bangalore', 'Karnataka'],
        '560051': ['Bangalore', 'Karnataka'],
        '560052': ['Bangalore', 'Karnataka'],
        '560053': ['Bangalore', 'Karnataka'],
        '560054': ['Bangalore', 'Karnataka'],
        '560055': ['Bangalore', 'Karnataka'],
        '560056': ['Bangalore', 'Karnataka'],
        '560057': ['Bangalore', 'Karnataka'],
        '560058': ['Bangalore', 'Karnataka'],
        '560059': ['Bangalore', 'Karnataka'],
        '560060': ['Bangalore', 'Karnataka'],
        '560061': ['Bangalore', 'Karnataka'],
        '560062': ['Bangalore', 'Karnataka'],
        '560063': ['Bangalore', 'Karnataka'],
        '560064': ['Bangalore', 'Karnataka'],
        '560065': ['Bangalore', 'Karnataka'],
        '560066': ['Bangalore', 'Karnataka'],
        '560067': ['Bangalore', 'Karnataka'],
        '560068': ['Bangalore', 'Karnataka'],
        '560069': ['Bangalore', 'Karnataka'],
        '560070': ['Bangalore', 'Karnataka'],
        '560071': ['Bangalore', 'Karnataka'],
        '560072': ['Bangalore', 'Karnataka'],
        '560073': ['Bangalore', 'Karnataka'],
        '560074': ['Bangalore', 'Karnataka'],
        '560075': ['Bangalore', 'Karnataka'],
        '560076': ['Bangalore', 'Karnataka'],
        '560077': ['Bangalore', 'Karnataka'],
        '560078': ['Bangalore', 'Karnataka'],
        '560079': ['Bangalore', 'Karnataka'],
        '560080': ['Bangalore', 'Karnataka'],
        '560081': ['Bangalore', 'Karnataka'],
        '560082': ['Bangalore', 'Karnataka'],
        '560083': ['Bangalore', 'Karnataka'],
        '560084': ['Bangalore', 'Karnataka'],
        '560085': ['Bangalore', 'Karnataka'],
        '560086': ['Bangalore', 'Karnataka'],
        '560087': ['Bangalore', 'Karnataka'],
        '560088': ['Bangalore', 'Karnataka'],
        '560089': ['Bangalore', 'Karnataka'],
        '560090': ['Bangalore', 'Karnataka'],
        '560091': ['Bangalore', 'Karnataka'],
        '560092': ['Bangalore', 'Karnataka'],
        '560093': ['Bangalore', 'Karnataka'],
        '560094': ['Bangalore', 'Karnataka'],
        '560095': ['Bangalore', 'Karnataka'],
        '560096': ['Bangalore', 'Karnataka'],
        '560097': ['Bangalore', 'Karnataka'],
        '560098': ['Bangalore', 'Karnataka'],
        '560099': ['Bangalore', 'Karnataka'],
        '560100': ['Bangalore', 'Karnataka'],
        '560102': ['Bangalore', 'Karnataka'],
        '560103': ['Bangalore', 'Karnataka'],
        '560104': ['Bangalore', 'Karnataka'],
        '560105': ['Bangalore', 'Karnataka'],
        '560107': ['Bangalore', 'Karnataka'],
        '560108': ['Bangalore', 'Karnataka'],
        '560109': ['Bangalore', 'Karnataka'],
        '560110': ['Bangalore', 'Karnataka'],
        '560111': ['Bangalore', 'Karnataka'],
        '560112': ['Bangalore', 'Karnataka'],
        '560113': ['Bangalore', 'Karnataka'],
        '560114': ['Bangalore', 'Karnataka'],
        '560115': ['Bangalore', 'Karnataka'],
        '560116': ['Bangalore', 'Karnataka'],
        '560117': ['Bangalore', 'Karnataka'],
        '560300': ['Bangalore', 'Karnataka'],
        '560500': ['Bangalore', 'Karnataka'],
        '561101': ['Bangalore', 'Karnataka'],
        '561201': ['Bangalore', 'Karnataka'],
        '561203': ['Bangalore', 'Karnataka'],
        '561204': ['Bangalore', 'Karnataka'],
        '561205': ['Bangalore', 'Karnataka'],
        '561214': ['Bangalore', 'Karnataka'],
        '561215': ['Bangalore', 'Karnataka'],
        '561216': ['Bangalore', 'Karnataka'],
        '561226': ['Bangalore', 'Karnataka'],
        '561227': ['Bangalore', 'Karnataka'],
        '562101': ['Bangalore', 'Karnataka'],
        '562106': ['Bangalore', 'Karnataka'],
        '562107': ['Bangalore', 'Karnataka'],
        '562108': ['Bangalore', 'Karnataka'],
        '562109': ['Bangalore', 'Karnataka'],
        '562110': ['Bangalore', 'Karnataka'],
        '562111': ['Bangalore', 'Karnataka'],
        '562112': ['Bangalore', 'Karnataka'],
        '562114': ['Bangalore', 'Karnataka'],
        '562115': ['Bangalore', 'Karnataka'],
        '562117': ['Bangalore', 'Karnataka'],
        '562119': ['Bangalore', 'Karnataka'],
        '562120': ['Bangalore', 'Karnataka'],
        '562121': ['Bangalore', 'Karnataka'],
        '562122': ['Bangalore', 'Karnataka'],
        '562123': ['Bangalore', 'Karnataka'],
        '562124': ['Bangalore', 'Karnataka'],
        '562125': ['Bangalore', 'Karnataka'],
        '562126': ['Bangalore', 'Karnataka'],
        '562127': ['Bangalore', 'Karnataka'],
        '562128': ['Bangalore', 'Karnataka'],
        '562129': ['Bangalore', 'Karnataka'],
        '562130': ['Bangalore', 'Karnataka'],
        '562131': ['Bangalore', 'Karnataka'],
        '562132': ['Bangalore', 'Karnataka'],
        '562134': ['Bangalore', 'Karnataka'],
        '562135': ['Bangalore', 'Karnataka'],
        '562136': ['Bangalore', 'Karnataka'],
        '562137': ['Bangalore', 'Karnataka'],
        '562138': ['Bangalore', 'Karnataka'],
        '562139': ['Bangalore', 'Karnataka'],
        '562140': ['Bangalore', 'Karnataka'],
        '562142': ['Bangalore', 'Karnataka'],
        '562143': ['Bangalore', 'Karnataka'],
        '562144': ['Bangalore', 'Karnataka'],
        '562145': ['Bangalore', 'Karnataka'],
        '562147': ['Bangalore', 'Karnataka'],
        '562148': ['Bangalore', 'Karnataka'],
        '562149': ['Bangalore', 'Karnataka'],
        '562150': ['Bangalore', 'Karnataka'],
        '562151': ['Bangalore', 'Karnataka'],
        '562152': ['Bangalore', 'Karnataka'],
        '562153': ['Bangalore', 'Karnataka'],
        '562154': ['Bangalore', 'Karnataka'],
        '562157': ['Bangalore', 'Karnataka'],
        '562162': ['Bangalore', 'Karnataka'],
        '562163': ['Bangalore', 'Karnataka'],
        '562164': ['Bangalore', 'Karnataka'],
        '570001': ['Mysore', 'Karnataka'],
        '570002': ['Mysore', 'Karnataka'],
        '570003': ['Mysore', 'Karnataka'],
        '570004': ['Mysore', 'Karnataka'],
        '570005': ['Mysore', 'Karnataka'],
        '570006': ['Mysore', 'Karnataka'],
        '570007': ['Mysore', 'Karnataka'],
        '570008': ['Mysore', 'Karnataka'],
        '570009': ['Mysore', 'Karnataka'],
        '570010': ['Mysore', 'Karnataka'],
        '570011': ['Mysore', 'Karnataka'],
        '570012': ['Mysore', 'Karnataka'],
        '570014': ['Mysore', 'Karnataka'],
        '570015': ['Mysore', 'Karnataka'],
        '570016': ['Mysore', 'Karnataka'],
        '570017': ['Mysore', 'Karnataka'],
        '570018': ['Mysore', 'Karnataka'],
        '570019': ['Mysore', 'Karnataka'],
        '570020': ['Mysore', 'Karnataka'],
        '570021': ['Mysore', 'Karnataka'],
        '570022': ['Mysore', 'Karnataka'],
        '570023': ['Mysore', 'Karnataka'],
        '570024': ['Mysore', 'Karnataka'],
        '570025': ['Mysore', 'Karnataka'],
        '570026': ['Mysore', 'Karnataka'],
        '570027': ['Mysore', 'Karnataka'],
        '570028': ['Mysore', 'Karnataka'],
        '570029': ['Mysore', 'Karnataka'],
        '570030': ['Mysore', 'Karnataka'],
        '570031': ['Mysore', 'Karnataka'],
        '571101': ['Mysore', 'Karnataka'],
        '571102': ['Mysore', 'Karnataka'],
        '571103': ['Mysore', 'Karnataka'],
        '571104': ['Mysore', 'Karnataka'],
        '571105': ['Mysore', 'Karnataka'],
        '571106': ['Mysore', 'Karnataka'],
        '571107': ['Mysore', 'Karnataka'],
        '571108': ['Mysore', 'Karnataka'],
        '571109': ['Mysore', 'Karnataka'],
        '571110': ['Mysore', 'Karnataka'],
        '571111': ['Mysore', 'Karnataka'],
        '571114': ['Mysore', 'Karnataka'],
        '571115': ['Mysore', 'Karnataka'],
        '571116': ['Mysore', 'Karnataka'],
        '571117': ['Mysore', 'Karnataka'],
        '571118': ['Mysore', 'Karnataka'],
        '571119': ['Mysore', 'Karnataka'],
        '571120': ['Mysore', 'Karnataka'],
        '571121': ['Mysore', 'Karnataka'],
        '571122': ['Mysore', 'Karnataka'],
        '571123': ['Mysore', 'Karnataka'],
        '571124': ['Mysore', 'Karnataka'],
        '571125': ['Mysore', 'Karnataka'],
        '571126': ['Mysore', 'Karnataka'],
        '571127': ['Mysore', 'Karnataka'],
        '571128': ['Mysore', 'Karnataka'],
        '571129': ['Mysore', 'Karnataka'],
        '571130': ['Mysore', 'Karnataka'],
        '571131': ['Mysore', 'Karnataka'],
        '571132': ['Mysore', 'Karnataka'],
        '571134': ['Mysore', 'Karnataka'],
        '571135': ['Mysore', 'Karnataka'],
        '571136': ['Mysore', 'Karnataka'],
        '571137': ['Mysore', 'Karnataka'],
        '571138': ['Mysore', 'Karnataka'],
        '571139': ['Mysore', 'Karnataka'],
        '571140': ['Mysore', 'Karnataka'],
        '571141': ['Mysore', 'Karnataka'],
        '571142': ['Mysore', 'Karnataka'],
        '571143': ['Mysore', 'Karnataka'],
        '571145': ['Mysore', 'Karnataka'],
        '571146': ['Mysore', 'Karnataka'],
        '571147': ['Mysore', 'Karnataka'],
        '571148': ['Mysore', 'Karnataka'],
        '571149': ['Mysore', 'Karnataka'],
        '571150': ['Mysore', 'Karnataka'],
        '571151': ['Mysore', 'Karnataka'],
        '571152': ['Mysore', 'Karnataka'],
        '571153': ['Mysore', 'Karnataka'],
        '571154': ['Mysore', 'Karnataka'],
        '571155': ['Mysore', 'Karnataka'],
        '571156': ['Mysore', 'Karnataka'],
        '571158': ['Mysore', 'Karnataka'],
        '571159': ['Mysore', 'Karnataka'],
        '571162': ['Mysore', 'Karnataka'],
        '571163': ['Mysore', 'Karnataka'],
        '571164': ['Mysore', 'Karnataka'],
        '571165': ['Mysore', 'Karnataka'],
        '571166': ['Mysore', 'Karnataka'],
        '571167': ['Mysore', 'Karnataka'],
        '571168': ['Mysore', 'Karnataka'],
        '571169': ['Mysore', 'Karnataka'],
        '571170': ['Mysore', 'Karnataka'],
        '571171': ['Mysore', 'Karnataka'],
        '571172': ['Mysore', 'Karnataka'],
        '571173': ['Mysore', 'Karnataka'],
        '571174': ['Mysore', 'Karnataka'],
        '571175': ['Mysore', 'Karnataka'],
        '571176': ['Mysore', 'Karnataka'],
        '571177': ['Mysore', 'Karnataka'],
        '571178': ['Mysore', 'Karnataka'],
        '571180': ['Mysore', 'Karnataka'],
        '571181': ['Mysore', 'Karnataka'],
        '571182': ['Mysore', 'Karnataka'],
        '571183': ['Mysore', 'Karnataka'],
        '571184': ['Mysore', 'Karnataka'],
        '571185': ['Mysore', 'Karnataka'],
        '571187': ['Mysore', 'Karnataka'],
        '571189': ['Mysore', 'Karnataka'],
        '571246': ['Mysore', 'Karnataka'],
        '571264': ['Mysore', 'Karnataka'],
        '571301': ['Mysore', 'Karnataka'],
        '571302': ['Mysore', 'Karnataka'],
        '571311': ['Mysore', 'Karnataka'],
        '571312': ['Mysore', 'Karnataka'],
        '571313': ['Mysore', 'Karnataka'],
        '571314': ['Mysore', 'Karnataka'],
        '571315': ['Mysore', 'Karnataka'],
        '571316': ['Mysore', 'Karnataka'],
        '571317': ['Mysore', 'Karnataka'],
        '571318': ['Mysore', 'Karnataka'],
        '571319': ['Mysore', 'Karnataka'],
        '571321': ['Mysore', 'Karnataka'],
        '571322': ['Mysore', 'Karnataka'],
        '571323': ['Mysore', 'Karnataka'],
        '571324': ['Mysore', 'Karnataka'],
        '571325': ['Mysore', 'Karnataka'],
        '571326': ['Mysore', 'Karnataka'],
        '571327': ['Mysore', 'Karnataka'],
        '571328': ['Mysore', 'Karnataka'],
        '571331': ['Mysore', 'Karnataka'],
        '571332': ['Mysore', 'Karnataka'],
        '571333': ['Mysore', 'Karnataka'],
        '571334': ['Mysore', 'Karnataka'],
        '571335': ['Mysore', 'Karnataka'],
        '571336': ['Mysore', 'Karnataka'],
        '571337': ['Mysore', 'Karnataka'],
        '571339': ['Mysore', 'Karnataka'],
        '571340': ['Mysore', 'Karnataka'],
        '571341': ['Mysore', 'Karnataka'],
        '571415': ['Mysore', 'Karnataka'],
        '571417': ['Mysore', 'Karnataka'],
        '571426': ['Mysore', 'Karnataka'],
        '571436': ['Mysore', 'Karnataka'],
        '571439': ['Mysore', 'Karnataka'],
        '571440': ['Mysore', 'Karnataka'],
        '571441': ['Mysore', 'Karnataka'],
        '571442': ['Mysore', 'Karnataka'],
        '571444': ['Mysore', 'Karnataka'],
        '571457': ['Mysore', 'Karnataka'],
        '571460': ['Mysore', 'Karnataka'],
        '571467': ['Mysore', 'Karnataka'],
        '571476': ['Mysore', 'Karnataka'],
        '571477': ['Mysore', 'Karnataka'],
        '571478': ['Mysore', 'Karnataka'],
        '571479': ['Mysore', 'Karnataka'],
        '571480': ['Mysore', 'Karnataka'],
        '571484': ['Mysore', 'Karnataka'],
        '571488': ['Mysore', 'Karnataka'],
        '571489': ['Mysore', 'Karnataka'],
        '571490': ['Mysore', 'Karnataka'],
        '571491': ['Mysore', 'Karnataka'],
        '571492': ['Mysore', 'Karnataka'],
        '571493': ['Mysore', 'Karnataka'],
        '571494': ['Mysore', 'Karnataka'],
        '571495': ['Mysore', 'Karnataka'],
        '571498': ['Mysore', 'Karnataka'],
        '571499': ['Mysore', 'Karnataka'],
        '571501': ['Bangalore', 'Karnataka'],
        '571502': ['Bangalore', 'Karnataka'],
        '571511': ['Bangalore', 'Karnataka'],
        '571512': ['Bangalore', 'Karnataka'],
        '571601': ['Mysore', 'Karnataka'],
        '571602': ['Mysore', 'Karnataka'],
        '571603': ['Mysore', 'Karnataka'],
        '571604': ['Mysore', 'Karnataka'],
        '571605': ['Mysore', 'Karnataka'],
        '571607': ['Mysore', 'Karnataka'],
        '571608': ['Mysore', 'Karnataka'],
        '571609': ['Mysore', 'Karnataka'],
        '571610': ['Mysore', 'Karnataka'],
        '571611': ['Mysore', 'Karnataka'],
        '571613': ['Mysore', 'Karnataka'],
        '571614': ['Mysore', 'Karnataka'],
        '571615': ['Mysore', 'Karnataka'],
        '571616': ['Mysore', 'Karnataka'],
        '571617': ['Mysore', 'Karnataka'],
        '571801': ['Mysore', 'Karnataka'],
        '571803': ['Mysore', 'Karnataka'],
        '571804': ['Mysore', 'Karnataka'],
        '571805': ['Mysore', 'Karnataka'],
        '571808': ['Mysore', 'Karnataka'],
        '571809': ['Mysore', 'Karnataka'],
        '571811': ['Mysore', 'Karnataka'],
        '571812': ['Mysore', 'Karnataka'],
        '572157': ['Mysore', 'Karnataka'],
        '574101': ['Udupi', 'Karnataka'],
        '574102': ['Udupi', 'Karnataka'],
        '574103': ['Udupi', 'Karnataka'],
        '574104': ['Udupi', 'Karnataka'],
        '574105': ['Udupi', 'Karnataka'],
        '574106': ['Udupi', 'Karnataka'],
        '574107': ['Udupi', 'Karnataka'],
        '574108': ['Udupi', 'Karnataka'],
        '574109': ['Udupi', 'Karnataka'],
        '574110': ['Udupi', 'Karnataka'],
        '574111': ['Udupi', 'Karnataka'],
        '574112': ['Udupi', 'Karnataka'],
        '574113': ['Udupi', 'Karnataka'],
        '574114': ['Udupi', 'Karnataka'],
        '574115': ['Udupi', 'Karnataka'],
        '574116': ['Udupi', 'Karnataka'],
        '574117': ['Udupi', 'Karnataka'],
        '574118': ['Udupi', 'Karnataka'],
        '574119': ['Udupi', 'Karnataka'],
        '574122': ['Udupi', 'Karnataka'],
        '574129': ['Udupi', 'Karnataka'],
        '574142': ['Mangalore', 'Karnataka'],
        '574144': ['Udupi', 'Karnataka'],
        '574173': ['Mangalore', 'Karnataka'],
        '574244': ['Udupi', 'Karnataka'],
        '575001': ['Mangalore', 'Karnataka'],
        '575002': ['Mangalore', 'Karnataka'],
        '575003': ['Mangalore', 'Karnataka'],
        '575004': ['Mangalore', 'Karnataka'],
        '575005': ['Mangalore', 'Karnataka'],
        '575006': ['Mangalore', 'Karnataka'],
        '575008': ['Mangalore', 'Karnataka'],
        '575010': ['Mangalore', 'Karnataka'],
        '575011': ['Mangalore', 'Karnataka'],
        '575013': ['Mangalore', 'Karnataka'],
        '575014': ['Mangalore', 'Karnataka'],
        '575015': ['Mangalore', 'Karnataka'],
        '575016': ['Mangalore', 'Karnataka'],
        '575019': ['Mangalore', 'Karnataka'],
        '575028': ['Mangalore', 'Karnataka'],
        '575029': ['Mangalore', 'Karnataka'],
        '575030': ['Mangalore', 'Karnataka'],
        '576101': ['Udupi', 'Karnataka'],
        '576102': ['Udupi', 'Karnataka'],
        '576103': ['Udupi', 'Karnataka'],
        '576104': ['Udupi', 'Karnataka'],
        '576105': ['Udupi', 'Karnataka'],
        '576106': ['Udupi', 'Karnataka'],
        '576107': ['Udupi', 'Karnataka'],
        '576108': ['Udupi', 'Karnataka'],
        '576111': ['Udupi', 'Karnataka'],
        '576112': ['Udupi', 'Karnataka'],
        '576113': ['Udupi', 'Karnataka'],
        '576114': ['Udupi', 'Karnataka'],
        '576115': ['Udupi', 'Karnataka'],
        '576117': ['Udupi', 'Karnataka'],
        '576120': ['Udupi', 'Karnataka'],
        '576121': ['Udupi', 'Karnataka'],
        '576122': ['Udupi', 'Karnataka'],
        '576124': ['Udupi', 'Karnataka'],
        '576201': ['Udupi', 'Karnataka'],
        '576210': ['Udupi', 'Karnataka'],
        '576211': ['Udupi', 'Karnataka'],
        '576212': ['Udupi', 'Karnataka'],
        '576213': ['Udupi', 'Karnataka'],
        '576214': ['Udupi', 'Karnataka'],
        '576215': ['Udupi', 'Karnataka'],
        '576216': ['Udupi', 'Karnataka'],
        '576217': ['Udupi', 'Karnataka'],
        '576218': ['Udupi', 'Karnataka'],
        '576219': ['Udupi', 'Karnataka'],
        '576220': ['Udupi', 'Karnataka'],
        '576221': ['Udupi', 'Karnataka'],
        '576222': ['Udupi', 'Karnataka'],
        '576223': ['Udupi', 'Karnataka'],
        '576224': ['Udupi', 'Karnataka'],
        '576225': ['Udupi', 'Karnataka'],
        '576226': ['Udupi', 'Karnataka'],
        '576227': ['Udupi', 'Karnataka'],
        '576228': ['Udupi', 'Karnataka'],
        '576229': ['Udupi', 'Karnataka'],
        '576230': ['Udupi', 'Karnataka'],
        '576231': ['Udupi', 'Karnataka'],
        '576232': ['Udupi', 'Karnataka'],
        '576233': ['Udupi', 'Karnataka'],
        '576234': ['Udupi', 'Karnataka'],
        '576235': ['Udupi', 'Karnataka'],
        '576247': ['Udupi', 'Karnataka'],
        '576257': ['Udupi', 'Karnataka'],
        '576282': ['Udupi', 'Karnataka'],
        '576283': ['Udupi', 'Karnataka'],
        '577001': ['DAVANGERE', 'Karnataka'],
        '577002': ['DAVANGERE', 'Karnataka'],
        '577003': ['DAVANGERE', 'Karnataka'],
        '577154': ['Mysore', 'Karnataka'],
        '577173': ['Mysore', 'Karnataka'],
        '580003': ['Belgaum', 'Karnataka'],
        '581229': ['Belgaum', 'Karnataka'],
        '586154': ['Belgaum', 'Karnataka'],
        '586226': ['Raipur', 'Karnataka'],
        '587124': ['Belgaum', 'Karnataka'],
        '590001': ['Belgaum', 'Karnataka'],
        '590003': ['Belgaum', 'Karnataka'],
        '590004': ['Belgaum', 'Karnataka'],
        '590005': ['Belgaum', 'Karnataka'],
        '590006': ['Belgaum', 'Karnataka'],
        '590007': ['Belgaum', 'Karnataka'],
        '590008': ['Belgaum', 'Karnataka'],
        '590009': ['Belgaum', 'Karnataka'],
        '590010': ['Belgaum', 'Karnataka'],
        '590011': ['Belgaum', 'Karnataka'],
        '590012': ['Belgaum', 'Karnataka'],
        '590013': ['Belgaum', 'Karnataka'],
        '590014': ['Belgaum', 'Karnataka'],
        '590015': ['Belgaum', 'Karnataka'],
        '590016': ['Belgaum', 'Karnataka'],
        '590017': ['Belgaum', 'Karnataka'],
        '590018': ['Belgaum', 'Karnataka'],
        '590019': ['Belgaum', 'Karnataka'],
        '590020': ['Belgaum', 'Karnataka'],
        '591101': ['Belgaum', 'Karnataka'],
        '591102': ['Belgaum', 'Karnataka'],
        '591103': ['Belgaum', 'Karnataka'],
        '591104': ['Belgaum', 'Karnataka'],
        '591106': ['Belgaum', 'Karnataka'],
        '591107': ['Belgaum', 'Karnataka'],
        '591108': ['Belgaum', 'Karnataka'],
        '591109': ['Belgaum', 'Karnataka'],
        '591110': ['Belgaum', 'Karnataka'],
        '591111': ['Belgaum', 'Karnataka'],
        '591112': ['Belgaum', 'Karnataka'],
        '591113': ['Belgaum', 'Karnataka'],
        '591114': ['Belgaum', 'Karnataka'],
        '591115': ['Belgaum', 'Karnataka'],
        '591116': ['Belgaum', 'Karnataka'],
        '591117': ['Belgaum', 'Karnataka'],
        '591118': ['Belgaum', 'Karnataka'],
        '591119': ['Belgaum', 'Karnataka'],
        '591120': ['Belgaum', 'Karnataka'],
        '591121': ['Belgaum', 'Karnataka'],
        '591122': ['Belgaum', 'Karnataka'],
        '591123': ['Belgaum', 'Karnataka'],
        '591124': ['Belgaum', 'Karnataka'],
        '591125': ['Belgaum', 'Karnataka'],
        '591126': ['Belgaum', 'Karnataka'],
        '591127': ['Belgaum', 'Karnataka'],
        '591128': ['Belgaum', 'Karnataka'],
        '591129': ['Belgaum', 'Karnataka'],
        '591130': ['Belgaum', 'Karnataka'],
        '591131': ['Belgaum', 'Karnataka'],
        '591132': ['Belgaum', 'Karnataka'],
        '591134': ['Belgaum', 'Karnataka'],
        '591135': ['Belgaum', 'Karnataka'],
        '591136': ['Belgaum', 'Karnataka'],
        '591137': ['Belgaum', 'Karnataka'],
        '591138': ['Belgaum', 'Karnataka'],
        '591139': ['Belgaum', 'Karnataka'],
        '591140': ['Belgaum', 'Karnataka'],
        '591141': ['Belgaum', 'Karnataka'],
        '591142': ['Belgaum', 'Karnataka'],
        '591143': ['Belgaum', 'Karnataka'],
        '591144': ['Belgaum', 'Karnataka'],
        '591145': ['Belgaum', 'Karnataka'],
        '591146': ['Belgaum', 'Karnataka'],
        '591147': ['Belgaum', 'Karnataka'],
        '591148': ['Belgaum', 'Karnataka'],
        '591149': ['Belgaum', 'Karnataka'],
        '591150': ['Belgaum', 'Karnataka'],
        '591152': ['Belgaum', 'Karnataka'],
        '591153': ['Belgaum', 'Karnataka'],
        '591154': ['Belgaum', 'Karnataka'],
        '591155': ['Belgaum', 'Karnataka'],
        '591156': ['Belgaum', 'Karnataka'],
        '591157': ['Belgaum', 'Karnataka'],
        '591158': ['Belgaum', 'Karnataka'],
        '591159': ['Belgaum', 'Karnataka'],
        '591160': ['Belgaum', 'Karnataka'],
        '591161': ['Belgaum', 'Karnataka'],
        '591162': ['Belgaum', 'Karnataka'],
        '591163': ['Belgaum', 'Karnataka'],
        '591164': ['Belgaum', 'Karnataka'],
        '591167': ['Belgaum', 'Karnataka'],
        '591168': ['Belgaum', 'Karnataka'],
        '591171': ['Belgaum', 'Karnataka'],
        '591173': ['Belgaum', 'Karnataka'],
        '591175': ['Belgaum', 'Karnataka'],
        '591176': ['Belgaum', 'Karnataka'],
        '591177': ['Belgaum', 'Karnataka'],
        '591178': ['Belgaum', 'Karnataka'],
        '591180': ['Belgaum', 'Karnataka'],
        '591181': ['Belgaum', 'Karnataka'],
        '591182': ['Belgaum', 'Karnataka'],
        '591183': ['Belgaum', 'Karnataka'],
        '591184': ['Belgaum', 'Karnataka'],
        '591185': ['Belgaum', 'Karnataka'],
        '591186': ['Belgaum', 'Karnataka'],
        '591187': ['Belgaum', 'Karnataka'],
        '591188': ['Belgaum', 'Karnataka'],
        '591189': ['Belgaum', 'Karnataka'],
        '591190': ['Belgaum', 'Karnataka'],
        '591191': ['Belgaum', 'Karnataka'],
        '591192': ['Belgaum', 'Karnataka'],
        '591193': ['Belgaum', 'Karnataka'],
        '591194': ['Belgaum', 'Karnataka'],
        '591195': ['Belgaum', 'Karnataka'],
        '591196': ['Belgaum', 'Karnataka'],
        '591197': ['Belgaum', 'Karnataka'],
        '591198': ['Belgaum', 'Karnataka'],
        '591201': ['Belgaum', 'Karnataka'],
        '591211': ['Belgaum', 'Karnataka'],
        '591212': ['Belgaum', 'Karnataka'],
        '591213': ['Belgaum', 'Karnataka'],
        '591214': ['Belgaum', 'Karnataka'],
        '591215': ['Belgaum', 'Karnataka'],
        '591216': ['Belgaum', 'Karnataka'],
        '591217': ['Belgaum', 'Karnataka'],
        '591218': ['Belgaum', 'Karnataka'],
        '591219': ['Belgaum', 'Karnataka'],
        '591220': ['Belgaum', 'Karnataka'],
        '591221': ['Belgaum', 'Karnataka'],
        '591222': ['Belgaum', 'Karnataka'],
        '591223': ['Belgaum', 'Karnataka'],
        '591224': ['Belgaum', 'Karnataka'],
        '591225': ['Belgaum', 'Karnataka'],
        '591226': ['Belgaum', 'Karnataka'],
        '591227': ['Belgaum', 'Karnataka'],
        '591228': ['Belgaum', 'Karnataka'],
        '591229': ['Belgaum', 'Karnataka'],
        '591230': ['Belgaum', 'Karnataka'],
        '591231': ['Belgaum', 'Karnataka'],
        '591232': ['Belgaum', 'Karnataka'],
        '591233': ['Belgaum', 'Karnataka'],
        '591234': ['Belgaum', 'Karnataka'],
        '591235': ['Belgaum', 'Karnataka'],
        '591236': ['Belgaum', 'Karnataka'],
        '591237': ['Belgaum', 'Karnataka'],
        '591238': ['Belgaum', 'Karnataka'],
        '591239': ['Belgaum', 'Karnataka'],
        '591240': ['Belgaum', 'Karnataka'],
        '591241': ['Belgaum', 'Karnataka'],
        '591242': ['Belgaum', 'Karnataka'],
        '591243': ['Belgaum', 'Karnataka'],
        '591244': ['Belgaum', 'Karnataka'],
        '591245': ['Belgaum', 'Karnataka'],
        '591246': ['Belgaum', 'Karnataka'],
        '591247': ['Belgaum', 'Karnataka'],
        '591248': ['Belgaum', 'Karnataka'],
        '591249': ['Belgaum', 'Karnataka'],
        '591250': ['Belgaum', 'Karnataka'],
        '591251': ['Belgaum', 'Karnataka'],
        '591252': ['Belgaum', 'Karnataka'],
        '591253': ['Belgaum', 'Karnataka'],
        '591254': ['Belgaum', 'Karnataka'],
        '591255': ['Belgaum', 'Karnataka'],
        '591256': ['Belgaum', 'Karnataka'],
        '591257': ['Belgaum', 'Karnataka'],
        '591258': ['Belgaum', 'Karnataka'],
        '591259': ['Belgaum', 'Karnataka'],
        '591260': ['Belgaum', 'Karnataka'],
        '591261': ['Belgaum', 'Karnataka'],
        '591262': ['Belgaum', 'Karnataka'],
        '591263': ['Belgaum', 'Karnataka'],
        '591264': ['Belgaum', 'Karnataka'],
        '591265': ['Belgaum', 'Karnataka'],
        '591266': ['Belgaum', 'Karnataka'],
        '591267': ['Belgaum', 'Karnataka'],
        '591268': ['Belgaum', 'Karnataka'],
        '591269': ['Belgaum', 'Karnataka'],
        '591270': ['Belgaum', 'Karnataka'],
        '591271': ['Belgaum', 'Karnataka'],
        '591272': ['Belgaum', 'Karnataka'],
        '591273': ['Belgaum', 'Karnataka'],
        '591274': ['Belgaum', 'Karnataka'],
        '591275': ['Belgaum', 'Karnataka'],
        '591276': ['Belgaum', 'Karnataka'],
        '591278': ['Belgaum', 'Karnataka'],
        '591279': ['Belgaum', 'Karnataka'],
        '591280': ['Belgaum', 'Karnataka'],
        '591281': ['Belgaum', 'Karnataka'],
        '591282': ['Belgaum', 'Karnataka'],
        '591283': ['Belgaum', 'Karnataka'],
        '591284': ['Belgaum', 'Karnataka'],
        '591285': ['Belgaum', 'Karnataka'],
        '591287': ['Belgaum', 'Karnataka'],
        '591288': ['Belgaum', 'Karnataka'],
        '591289': ['Belgaum', 'Karnataka'],
        '591290': ['Belgaum', 'Karnataka'],
        '591291': ['Belgaum', 'Karnataka'],
        '591292': ['Belgaum', 'Karnataka'],
        '591293': ['Belgaum', 'Karnataka'],
        '591295': ['Belgaum', 'Karnataka'],
        '591296': ['Belgaum', 'Karnataka'],
        '591297': ['Belgaum', 'Karnataka'],
        '591298': ['Belgaum', 'Karnataka'],
        '591299': ['Belgaum', 'Karnataka'],
        '591301': ['Belgaum', 'Karnataka'],
        '591302': ['Belgaum', 'Karnataka'],
        '591303': ['Belgaum', 'Karnataka'],
        '591304': ['Belgaum', 'Karnataka'],
        '591305': ['Belgaum', 'Karnataka'],
        '591306': ['Belgaum', 'Karnataka'],
        '591307': ['Belgaum', 'Karnataka'],
        '591308': ['Belgaum', 'Karnataka'],
        '591309': ['Belgaum', 'Karnataka'],
        '591310': ['Belgaum', 'Karnataka'],
        '591311': ['Belgaum', 'Karnataka'],
        '591312': ['Belgaum', 'Karnataka'],
        '591313': ['Belgaum', 'Karnataka'],
        '591314': ['Belgaum', 'Karnataka'],
        '591315': ['Belgaum', 'Karnataka'],
        '591316': ['Belgaum', 'Karnataka'],
        '591317': ['Belgaum', 'Karnataka'],
        '591318': ['Belgaum', 'Karnataka'],
        '591320': ['Belgaum', 'Karnataka'],
        '591321': ['Belgaum', 'Karnataka'],
        '591322': ['Belgaum', 'Karnataka'],
        '591323': ['Belgaum', 'Karnataka'],
        '591324': ['Belgaum', 'Karnataka'],
        '591327': ['Belgaum', 'Karnataka'],
        '591328': ['Belgaum', 'Karnataka'],
        '591329': ['Belgaum', 'Karnataka'],
        '591330': ['Belgaum', 'Karnataka'],
        '591331': ['Belgaum', 'Karnataka'],
        '591333': ['Belgaum', 'Karnataka'],
        '591334': ['Belgaum', 'Karnataka'],
        '591337': ['Belgaum', 'Karnataka'],
        '591339': ['Belgaum', 'Karnataka'],
        '591340': ['Belgaum', 'Karnataka'],
        '591341': ['Belgaum', 'Karnataka'],
        '591342': ['Belgaum', 'Karnataka'],
        '591343': ['Belgaum', 'Karnataka'],
        '591344': ['Belgaum', 'Karnataka'],
        '591345': ['Belgaum', 'Karnataka'],
        '591346': ['Belgaum', 'Karnataka'],
        '591501': ['Belgaum', 'Karnataka'],
        '591502': ['Belgaum', 'Karnataka'],
        '591503': ['Belgaum', 'Karnataka'],
        '591504': ['Belgaum', 'Karnataka'],
        '591505': ['Belgaum', 'Karnataka'],
        '591506': ['Belgaum', 'Karnataka'],
        '591507': ['Belgaum', 'Karnataka'],
        '591508': ['Belgaum', 'Karnataka'],
        '591509': ['Belgaum', 'Karnataka'],
        '591510': ['Belgaum', 'Karnataka'],
        '591512': ['Belgaum', 'Karnataka'],
        '591515': ['Belgaum', 'Karnataka'],
        '591516': ['Belgaum', 'Karnataka'],
        '591517': ['Belgaum', 'Karnataka'],
        '591701': ['Belgaum', 'Karnataka'],
        '591702': ['Belgaum', 'Karnataka'],
        '591703': ['Belgaum', 'Karnataka'],
        '591802': ['Belgaum', 'Karnataka'],
        '591812': ['Belgaum', 'Karnataka'],
        '600001': ['Chennai', 'Tamil Nadu'],
        '600002': ['Chennai', 'Tamil Nadu'],
        '600003': ['Chennai', 'Tamil Nadu'],
        '600004': ['Chennai', 'Tamil Nadu'],
        '600005': ['Chennai', 'Tamil Nadu'],
        '600006': ['Chennai', 'Tamil Nadu'],
        '600007': ['Chennai', 'Tamil Nadu'],
        '600008': ['Chennai', 'Tamil Nadu'],
        '600009': ['Chennai', 'Tamil Nadu'],
        '600010': ['Chennai', 'Tamil Nadu'],
        '600011': ['Chennai', 'Tamil Nadu'],
        '600012': ['Chennai', 'Tamil Nadu'],
        '600013': ['Chennai', 'Tamil Nadu'],
        '600014': ['Chennai', 'Tamil Nadu'],
        '600015': ['Chennai', 'Tamil Nadu'],
        '600017': ['Chennai', 'Tamil Nadu'],
        '600018': ['Chennai', 'Tamil Nadu'],
        '600020': ['Chennai', 'Tamil Nadu'],
        '600021': ['Chennai', 'Tamil Nadu'],
        '600022': ['Chennai', 'Tamil Nadu'],
        '600023': ['Chennai', 'Tamil Nadu'],
        '600024': ['Chennai', 'Tamil Nadu'],
        '600025': ['Chennai', 'Tamil Nadu'],
        '600026': ['Chennai', 'Tamil Nadu'],
        '600028': ['Chennai', 'Tamil Nadu'],
        '600029': ['Chennai', 'Tamil Nadu'],
        '600030': ['Chennai', 'Tamil Nadu'],
        '600031': ['Chennai', 'Tamil Nadu'],
        '600032': ['Chennai', 'Tamil Nadu'],
        '600033': ['Chennai', 'Tamil Nadu'],
        '600034': ['Chennai', 'Tamil Nadu'],
        '600035': ['Chennai', 'Tamil Nadu'],
        '600036': ['Chennai', 'Tamil Nadu'],
        '600037': ['Chennai', 'Tamil Nadu'],
        '600038': ['Chennai', 'Tamil Nadu'],
        '600039': ['Chennai', 'Tamil Nadu'],
        '600040': ['Chennai', 'Tamil Nadu'],
        '600041': ['Chennai', 'Tamil Nadu'],
        '600042': ['Chennai', 'Tamil Nadu'],
        '600057': ['Chennai', 'Tamil Nadu'],
        '600078': ['Chennai', 'Tamil Nadu'],
        '600079': ['Chennai', 'Tamil Nadu'],
        '600081': ['Chennai', 'Tamil Nadu'],
        '600082': ['Chennai', 'Tamil Nadu'],
        '600083': ['Chennai', 'Tamil Nadu'],
        '600084': ['Chennai', 'Tamil Nadu'],
        '600085': ['Chennai', 'Tamil Nadu'],
        '600086': ['Chennai', 'Tamil Nadu'],
        '600090': ['Chennai', 'Tamil Nadu'],
        '600092': ['Chennai', 'Tamil Nadu'],
        '600093': ['Chennai', 'Tamil Nadu'],
        '600094': ['Chennai', 'Tamil Nadu'],
        '600095': ['Chennai', 'Tamil Nadu'],
        '600096': ['Chennai', 'Tamil Nadu'],
        '600097': ['Chennai', 'Tamil Nadu'],
        '600101': ['Chennai', 'Tamil Nadu'],
        '600102': ['Chennai', 'Tamil Nadu'],
        '600104': ['Chennai', 'Tamil Nadu'],
        '600105': ['Chennai', 'Tamil Nadu'],
        '600106': ['Chennai', 'Tamil Nadu'],
        '600107': ['Chennai', 'Tamil Nadu'],
        '600108': ['Chennai', 'Tamil Nadu'],
        '600110': ['Chennai', 'Tamil Nadu'],
        '600111': ['Chennai', 'Tamil Nadu'],
        '600112': ['Chennai', 'Tamil Nadu'],
        '600113': ['Chennai', 'Tamil Nadu'],
        '600118': ['Chennai', 'Tamil Nadu'],
        '600131': ['Chennai', 'Tamil Nadu'],
        '600132': ['Chennai', 'Tamil Nadu'],
        '603004': ['Chennai', 'Tamil Nadu'],
        '605001': ['Puducherry', 'Puducherry'],
        '605002': ['Puducherry', 'Puducherry'],
        '605003': ['Puducherry', 'Puducherry'],
        '605004': ['Puducherry', 'Puducherry'],
        '605005': ['Puducherry', 'Puducherry'],
        '605006': ['Puducherry', 'Puducherry'],
        '605007': ['Puducherry', 'Puducherry'],
        '605008': ['Puducherry', 'Puducherry'],
        '605009': ['Puducherry', 'Puducherry'],
        '605010': ['Puducherry', 'Puducherry'],
        '605011': ['Puducherry', 'Puducherry'],
        '605012': ['Puducherry', 'Tamil Nadu'],
        '605013': ['Puducherry', 'Puducherry'],
        '605014': ['Puducherry', 'Puducherry'],
        '605101': ['Puducherry', 'Puducherry'],
        '605102': ['Puducherry', 'Puducherry'],
        '605104': ['Puducherry', 'Puducherry'],
        '605105': ['Puducherry', 'Puducherry'],
        '605106': ['Puducherry', 'Tamil Nadu'],
        '605110': ['Puducherry', 'Puducherry'],
        '605501': ['Puducherry', 'Puducherry'],
        '605502': ['Puducherry', 'Puducherry'],
        '607402': ['Puducherry', 'Puducherry'],
        '607403': ['Chennai', 'Puducherry'],
        '614201': ['Coimbatore', 'Tamil Nadu'],
        '620001': ['Trichy', 'Tamil Nadu'],
        '620002': ['Trichy', 'Tamil Nadu'],
        '620003': ['Trichy', 'Tamil Nadu'],
        '620004': ['Trichy', 'Tamil Nadu'],
        '620005': ['Trichy', 'Tamil Nadu'],
        '620006': ['Trichy', 'Tamil Nadu'],
        '620007': ['Trichy', 'Tamil Nadu'],
        '620008': ['Trichy', 'Tamil Nadu'],
        '620009': ['Trichy', 'Tamil Nadu'],
        '620010': ['Trichy', 'Tamil Nadu'],
        '620011': ['Trichy', 'Tamil Nadu'],
        '620012': ['Trichy', 'Tamil Nadu'],
        '620013': ['Trichy', 'Tamil Nadu'],
        '620014': ['Trichy', 'Tamil Nadu'],
        '620015': ['Trichy', 'Tamil Nadu'],
        '620016': ['Trichy', 'Tamil Nadu'],
        '620017': ['Trichy', 'Tamil Nadu'],
        '620018': ['Trichy', 'Tamil Nadu'],
        '620019': ['Trichy', 'Tamil Nadu'],
        '620020': ['Trichy', 'Tamil Nadu'],
        '620021': ['Trichy', 'Tamil Nadu'],
        '620022': ['Trichy', 'Tamil Nadu'],
        '620051': ['Trichy', 'Tamil Nadu'],
        '620101': ['Trichy', 'Tamil Nadu'],
        '620102': ['Trichy', 'Tamil Nadu'],
        '621001': ['Trichy', 'Tamil Nadu'],
        '621002': ['Trichy', 'Tamil Nadu'],
        '621003': ['Trichy', 'Tamil Nadu'],
        '621004': ['Trichy', 'Tamil Nadu'],
        '621005': ['Trichy', 'Tamil Nadu'],
        '621006': ['Trichy', 'Tamil Nadu'],
        '621007': ['Trichy', 'Tamil Nadu'],
        '621008': ['Trichy', 'Tamil Nadu'],
        '621009': ['Trichy', 'Tamil Nadu'],
        '621010': ['Trichy', 'Tamil Nadu'],
        '621012': ['Trichy', 'Tamil Nadu'],
        '621014': ['Trichy', 'Tamil Nadu'],
        '621015': ['Trichy', 'Tamil Nadu'],
        '621016': ['Trichy', 'Tamil Nadu'],
        '621017': ['Trichy', 'Tamil Nadu'],
        '621018': ['Trichy', 'Tamil Nadu'],
        '621021': ['Trichy', 'Tamil Nadu'],
        '621022': ['Trichy', 'Tamil Nadu'],
        '621101': ['Trichy', 'Tamil Nadu'],
        '621103': ['Trichy', 'Tamil Nadu'],
        '621104': ['Trichy', 'Tamil Nadu'],
        '621105': ['Trichy', 'Tamil Nadu'],
        '621106': ['Trichy', 'Tamil Nadu'],
        '621107': ['Trichy', 'Tamil Nadu'],
        '621108': ['Trichy', 'Tamil Nadu'],
        '621109': ['Trichy', 'Tamil Nadu'],
        '621110': ['Trichy', 'Tamil Nadu'],
        '621112': ['Trichy', 'Tamil Nadu'],
        '621113': ['Trichy', 'Tamil Nadu'],
        '621114': ['Trichy', 'Tamil Nadu'],
        '621115': ['Trichy', 'Tamil Nadu'],
        '621116': ['Trichy', 'Tamil Nadu'],
        '621117': ['Trichy', 'Tamil Nadu'],
        '621118': ['Trichy', 'Tamil Nadu'],
        '621121': ['Trichy', 'Tamil Nadu'],
        '621126': ['Trichy', 'Tamil Nadu'],
        '621127': ['Trichy', 'Tamil Nadu'],
        '621129': ['Trichy', 'Tamil Nadu'],
        '621132': ['Trichy', 'Tamil Nadu'],
        '621201': ['Trichy', 'Tamil Nadu'],
        '621202': ['Trichy', 'Tamil Nadu'],
        '621203': ['Trichy', 'Tamil Nadu'],
        '621205': ['Trichy', 'Tamil Nadu'],
        '621206': ['Trichy', 'Tamil Nadu'],
        '621208': ['Trichy', 'Tamil Nadu'],
        '621209': ['Trichy', 'Tamil Nadu'],
        '621210': ['Trichy', 'Tamil Nadu'],
        '621211': ['Trichy', 'Tamil Nadu'],
        '621212': ['Trichy', 'Tamil Nadu'],
        '621213': ['Trichy', 'Tamil Nadu'],
        '621214': ['Trichy', 'Tamil Nadu'],
        '621215': ['Trichy', 'Tamil Nadu'],
        '621216': ['Trichy', 'Tamil Nadu'],
        '621217': ['Trichy', 'Tamil Nadu'],
        '621218': ['Trichy', 'Tamil Nadu'],
        '621219': ['Trichy', 'Tamil Nadu'],
        '621220': ['Trichy', 'Tamil Nadu'],
        '621221': ['Trichy', 'Tamil Nadu'],
        '621224': ['Trichy', 'Tamil Nadu'],
        '621229': ['Trichy', 'Tamil Nadu'],
        '621302': ['Trichy', 'Tamil Nadu'],
        '621303': ['Trichy', 'Tamil Nadu'],
        '621304': ['Trichy', 'Tamil Nadu'],
        '621305': ['Trichy', 'Tamil Nadu'],
        '621306': ['Trichy', 'Tamil Nadu'],
        '621307': ['Trichy', 'Tamil Nadu'],
        '621309': ['Trichy', 'Tamil Nadu'],
        '621310': ['Trichy', 'Tamil Nadu'],
        '621311': ['Trichy', 'Tamil Nadu'],
        '621312': ['Trichy', 'Tamil Nadu'],
        '621313': ['Trichy', 'Tamil Nadu'],
        '621314': ['Trichy', 'Tamil Nadu'],
        '621315': ['Trichy', 'Tamil Nadu'],
        '621317': ['Trichy', 'Tamil Nadu'],
        '621601': ['Trichy', 'Tamil Nadu'],
        '621651': ['Trichy', 'Tamil Nadu'],
        '621652': ['Trichy', 'Tamil Nadu'],
        '621653': ['Trichy', 'Tamil Nadu'],
        '621701': ['Trichy', 'Tamil Nadu'],
        '621702': ['Trichy', 'Tamil Nadu'],
        '621703': ['Trichy', 'Tamil Nadu'],
        '621704': ['Trichy', 'Tamil Nadu'],
        '621705': ['Trichy', 'Tamil Nadu'],
        '621707': ['Trichy', 'Tamil Nadu'],
        '621708': ['Trichy', 'Tamil Nadu'],
        '621709': ['Trichy', 'Tamil Nadu'],
        '621710': ['Trichy', 'Tamil Nadu'],
        '621711': ['Trichy', 'Tamil Nadu'],
        '621712': ['Trichy', 'Tamil Nadu'],
        '621713': ['Trichy', 'Tamil Nadu'],
        '621714': ['Trichy', 'Tamil Nadu'],
        '621715': ['Trichy', 'Tamil Nadu'],
        '621716': ['Trichy', 'Tamil Nadu'],
        '621717': ['Trichy', 'Tamil Nadu'],
        '621718': ['Trichy', 'Tamil Nadu'],
        '621719': ['Trichy', 'Tamil Nadu'],
        '621722': ['Trichy', 'Tamil Nadu'],
        '621729': ['Trichy', 'Tamil Nadu'],
        '621801': ['Trichy', 'Tamil Nadu'],
        '621802': ['Trichy', 'Tamil Nadu'],
        '621803': ['Trichy', 'Tamil Nadu'],
        '621805': ['Trichy', 'Tamil Nadu'],
        '621806': ['Trichy', 'Tamil Nadu'],
        '621851': ['Trichy', 'Tamil Nadu'],
        '621853': ['Trichy', 'Tamil Nadu'],
        '624215': ['Madurai', 'Tamil Nadu'],
        '624401': ['Madurai', 'Tamil Nadu'],
        '624402': ['Madurai', 'Tamil Nadu'],
        '624403': ['Madurai', 'Tamil Nadu'],
        '625001': ['Madurai', 'Tamil Nadu'],
        '625002': ['Madurai', 'Tamil Nadu'],
        '625003': ['Madurai', 'Tamil Nadu'],
        '625004': ['Madurai', 'Tamil Nadu'],
        '625005': ['Madurai', 'Tamil Nadu'],
        '625006': ['Madurai', 'Tamil Nadu'],
        '625007': ['Madurai', 'Tamil Nadu'],
        '625008': ['Madurai', 'Tamil Nadu'],
        '625009': ['Madurai', 'Tamil Nadu'],
        '625010': ['Madurai', 'Tamil Nadu'],
        '625011': ['Madurai', 'Tamil Nadu'],
        '625012': ['Madurai', 'Tamil Nadu'],
        '625014': ['Madurai', 'Tamil Nadu'],
        '625015': ['Madurai', 'Tamil Nadu'],
        '625016': ['Madurai', 'Tamil Nadu'],
        '625017': ['Madurai', 'Tamil Nadu'],
        '625018': ['Madurai', 'Tamil Nadu'],
        '625019': ['Madurai', 'Tamil Nadu'],
        '625020': ['Madurai', 'Tamil Nadu'],
        '625021': ['Madurai', 'Tamil Nadu'],
        '625022': ['Madurai', 'Tamil Nadu'],
        '625023': ['Madurai', 'Tamil Nadu'],
        '625051': ['Madurai', 'Tamil Nadu'],
        '625052': ['Madurai', 'Tamil Nadu'],
        '625101': ['Madurai', 'Tamil Nadu'],
        '625102': ['Madurai', 'Tamil Nadu'],
        '625103': ['Madurai', 'Tamil Nadu'],
        '625104': ['Madurai', 'Tamil Nadu'],
        '625105': ['Madurai', 'Tamil Nadu'],
        '625106': ['Madurai', 'Tamil Nadu'],
        '625107': ['Madurai', 'Tamil Nadu'],
        '625108': ['Madurai', 'Tamil Nadu'],
        '625109': ['Madurai', 'Tamil Nadu'],
        '625110': ['Madurai', 'Tamil Nadu'],
        '625111': ['Madurai', 'Tamil Nadu'],
        '625115': ['Madurai', 'Tamil Nadu'],
        '625119': ['Madurai', 'Tamil Nadu'],
        '625121': ['Madurai', 'Tamil Nadu'],
        '625122': ['Madurai', 'Tamil Nadu'],
        '625125': ['Madurai', 'Tamil Nadu'],
        '625201': ['Madurai', 'Tamil Nadu'],
        '625205': ['Madurai', 'Tamil Nadu'],
        '625207': ['Madurai', 'Tamil Nadu'],
        '625214': ['Madurai', 'Tamil Nadu'],
        '625218': ['Madurai', 'Tamil Nadu'],
        '625221': ['Madurai', 'Tamil Nadu'],
        '625234': ['Madurai', 'Tamil Nadu'],
        '625301': ['Madurai', 'Tamil Nadu'],
        '625303': ['Madurai', 'Tamil Nadu'],
        '625304': ['Madurai', 'Tamil Nadu'],
        '625402': ['Madurai', 'Tamil Nadu'],
        '625403': ['Madurai', 'Tamil Nadu'],
        '625501': ['Madurai', 'Tamil Nadu'],
        '625503': ['Madurai', 'Tamil Nadu'],
        '625504': ['Madurai', 'Tamil Nadu'],
        '625514': ['Madurai', 'Tamil Nadu'],
        '625527': ['Madurai', 'Tamil Nadu'],
        '625529': ['Madurai', 'Tamil Nadu'],
        '625532': ['Madurai', 'Tamil Nadu'],
        '625535': ['Madurai', 'Tamil Nadu'],
        '625537': ['Madurai', 'Tamil Nadu'],
        '625577': ['Madurai', 'Tamil Nadu'],
        '625701': ['Madurai', 'Tamil Nadu'],
        '625702': ['Madurai', 'Tamil Nadu'],
        '625703': ['Madurai', 'Tamil Nadu'],
        '625704': ['Madurai', 'Tamil Nadu'],
        '625705': ['Madurai', 'Tamil Nadu'],
        '625706': ['Madurai', 'Tamil Nadu'],
        '625707': ['Madurai', 'Tamil Nadu'],
        '625708': ['Madurai', 'Tamil Nadu'],
        '626161': ['Madurai', 'Tamil Nadu'],
        '626501': ['Madurai', 'Tamil Nadu'],
        '626512': ['Madurai', 'Tamil Nadu'],
        '626513': ['Madurai', 'Tamil Nadu'],
        '626514': ['Madurai', 'Tamil Nadu'],
        '626515': ['Madurai', 'Tamil Nadu'],
        '626516': ['Madurai', 'Tamil Nadu'],
        '626517': ['Madurai', 'Tamil Nadu'],
        '626518': ['Madurai', 'Tamil Nadu'],
        '626519': ['Madurai', 'Tamil Nadu'],
        '626520': ['Madurai', 'Tamil Nadu'],
        '626521': ['Madurai', 'Tamil Nadu'],
        '626522': ['Madurai', 'Tamil Nadu'],
        '626523': ['Madurai', 'Tamil Nadu'],
        '626524': ['Madurai', 'Tamil Nadu'],
        '626526': ['Madurai', 'Tamil Nadu'],
        '626527': ['Madurai', 'Tamil Nadu'],
        '626528': ['Madurai', 'Tamil Nadu'],
        '626529': ['Madurai', 'Tamil Nadu'],
        '626530': ['Madurai', 'Tamil Nadu'],
        '626531': ['Madurai', 'Tamil Nadu'],
        '626532': ['Madurai', 'Tamil Nadu'],
        '626533': ['Madurai', 'Tamil Nadu'],
        '626534': ['Madurai', 'Tamil Nadu'],
        '626535': ['Madurai', 'Tamil Nadu'],
        '626536': ['Madurai', 'Tamil Nadu'],
        '626537': ['Madurai', 'Tamil Nadu'],
        '626538': ['Madurai', 'Tamil Nadu'],
        '626539': ['Madurai', 'Tamil Nadu'],
        '626540': ['Madurai', 'Tamil Nadu'],
        '626541': ['Madurai', 'Tamil Nadu'],
        '626544': ['Madurai', 'Tamil Nadu'],
        '626547': ['Madurai', 'Tamil Nadu'],
        '626553': ['Madurai', 'Tamil Nadu'],
        '626555': ['Madurai', 'Tamil Nadu'],
        '626556': ['Madurai', 'Tamil Nadu'],
        '626562': ['Madurai', 'Tamil Nadu'],
        '626565': ['Madurai', 'Tamil Nadu'],
        '626566': ['Madurai', 'Tamil Nadu'],
        '626569': ['Madurai', 'Tamil Nadu'],
        '626571': ['Madurai', 'Tamil Nadu'],
        '626572': ['Madurai', 'Tamil Nadu'],
        '626579': ['Madurai', 'Tamil Nadu'],
        '626582': ['Madurai', 'Tamil Nadu'],
        '626601': ['Madurai', 'Tamil Nadu'],
        '626701': ['Madurai', 'Tamil Nadu'],
        '626702': ['Madurai', 'Tamil Nadu'],
        '626704': ['Madurai', 'Tamil Nadu'],
        '626705': ['Madurai', 'Tamil Nadu'],
        '626706': ['Madurai', 'Tamil Nadu'],
        '626709': ['Madurai', 'Tamil Nadu'],
        '626712': ['Madurai', 'Tamil Nadu'],
        '626717': ['Madurai', 'Tamil Nadu'],
        '626719': ['Madurai', 'Tamil Nadu'],
        '628207': ['Trichy', 'Tamil Nadu'],
        '631001': ['Vellore', 'Tamil Nadu'],
        '631002': ['Vellore', 'Tamil Nadu'],
        '631003': ['Vellore', 'Tamil Nadu'],
        '631004': ['Vellore', 'Tamil Nadu'],
        '631005': ['Vellore', 'Tamil Nadu'],
        '631006': ['Vellore', 'Tamil Nadu'],
        '631051': ['Vellore', 'Tamil Nadu'],
        '631052': ['Vellore', 'Tamil Nadu'],
        '631101': ['Vellore', 'Tamil Nadu'],
        '631102': ['Vellore', 'Tamil Nadu'],
        '631151': ['Vellore', 'Tamil Nadu'],
        '631152': ['Vellore', 'Tamil Nadu'],
        '632001': ['Vellore', 'Tamil Nadu'],
        '632002': ['Vellore', 'Tamil Nadu'],
        '632004': ['Vellore', 'Tamil Nadu'],
        '632006': ['Vellore', 'Tamil Nadu'],
        '632007': ['Vellore', 'Tamil Nadu'],
        '632008': ['Vellore', 'Tamil Nadu'],
        '632009': ['Vellore', 'Tamil Nadu'],
        '632010': ['Vellore', 'Tamil Nadu'],
        '632011': ['Vellore', 'Tamil Nadu'],
        '632012': ['Vellore', 'Tamil Nadu'],
        '632013': ['Vellore', 'Tamil Nadu'],
        '632014': ['Vellore', 'Tamil Nadu'],
        '632055': ['Vellore', 'Tamil Nadu'],
        '632057': ['Vellore', 'Tamil Nadu'],
        '632058': ['Vellore', 'Tamil Nadu'],
        '632059': ['Vellore', 'Tamil Nadu'],
        '632101': ['Vellore', 'Tamil Nadu'],
        '632102': ['Vellore', 'Tamil Nadu'],
        '632103': ['Vellore', 'Tamil Nadu'],
        '632104': ['Vellore', 'Tamil Nadu'],
        '632105': ['Vellore', 'Tamil Nadu'],
        '632106': ['Vellore', 'Tamil Nadu'],
        '632107': ['Vellore', 'Tamil Nadu'],
        '632113': ['Vellore', 'Tamil Nadu'],
        '632114': ['Vellore', 'Tamil Nadu'],
        '632115': ['Vellore', 'Tamil Nadu'],
        '632201': ['Vellore', 'Tamil Nadu'],
        '632202': ['Vellore', 'Tamil Nadu'],
        '632203': ['Vellore', 'Tamil Nadu'],
        '632204': ['Vellore', 'Tamil Nadu'],
        '632209': ['Vellore', 'Tamil Nadu'],
        '632312': ['Vellore', 'Tamil Nadu'],
        '632318': ['Vellore', 'Tamil Nadu'],
        '632319': ['Vellore', 'Tamil Nadu'],
        '632401': ['Vellore', 'Tamil Nadu'],
        '632403': ['Vellore', 'Tamil Nadu'],
        '632404': ['Vellore', 'Tamil Nadu'],
        '632405': ['Vellore', 'Tamil Nadu'],
        '632406': ['Vellore', 'Tamil Nadu'],
        '632501': ['Vellore', 'Tamil Nadu'],
        '632502': ['Vellore', 'Tamil Nadu'],
        '632503': ['Vellore', 'Tamil Nadu'],
        '632504': ['Vellore', 'Tamil Nadu'],
        '632505': ['Vellore', 'Tamil Nadu'],
        '632506': ['Vellore', 'Tamil Nadu'],
        '632507': ['Vellore', 'Tamil Nadu'],
        '632508': ['Vellore', 'Tamil Nadu'],
        '632509': ['Vellore', 'Tamil Nadu'],
        '632510': ['Vellore', 'Tamil Nadu'],
        '632511': ['Vellore', 'Tamil Nadu'],
        '632512': ['Vellore', 'Tamil Nadu'],
        '632513': ['Vellore', 'Tamil Nadu'],
        '632514': ['Vellore', 'Tamil Nadu'],
        '632515': ['Vellore', 'Tamil Nadu'],
        '632516': ['Vellore', 'Tamil Nadu'],
        '632517': ['Vellore', 'Tamil Nadu'],
        '632518': ['Vellore', 'Tamil Nadu'],
        '632519': ['Vellore', 'Tamil Nadu'],
        '632520': ['Vellore', 'Tamil Nadu'],
        '632521': ['Vellore', 'Tamil Nadu'],
        '632531': ['Vellore', 'Tamil Nadu'],
        '632601': ['Vellore', 'Tamil Nadu'],
        '632602': ['Vellore', 'Tamil Nadu'],
        '632603': ['Vellore', 'Tamil Nadu'],
        '632604': ['Vellore', 'Tamil Nadu'],
        '635130': ['Coimbatore', 'Tamil Nadu'],
        '635601': ['Vellore', 'Tamil Nadu'],
        '635602': ['Vellore', 'Tamil Nadu'],
        '635651': ['Vellore', 'Tamil Nadu'],
        '635652': ['Vellore', 'Tamil Nadu'],
        '635653': ['Vellore', 'Tamil Nadu'],
        '635654': ['Vellore', 'Tamil Nadu'],
        '635655': ['Vellore', 'Tamil Nadu'],
        '635701': ['Vellore', 'Tamil Nadu'],
        '635702': ['Vellore', 'Tamil Nadu'],
        '635703': ['Vellore', 'Tamil Nadu'],
        '635710': ['Vellore', 'Tamil Nadu'],
        '635751': ['Vellore', 'Tamil Nadu'],
        '635752': ['Vellore', 'Tamil Nadu'],
        '635754': ['Vellore', 'Tamil Nadu'],
        '635801': ['Vellore', 'Tamil Nadu'],
        '635802': ['Vellore', 'Tamil Nadu'],
        '635803': ['Vellore', 'Tamil Nadu'],
        '635804': ['Vellore', 'Tamil Nadu'],
        '635805': ['Vellore', 'Tamil Nadu'],
        '635806': ['Vellore', 'Tamil Nadu'],
        '635807': ['Vellore', 'Tamil Nadu'],
        '635808': ['Vellore', 'Tamil Nadu'],
        '635809': ['Vellore', 'Tamil Nadu'],
        '635810': ['Vellore', 'Tamil Nadu'],
        '635811': ['Vellore', 'Tamil Nadu'],
        '635812': ['Vellore', 'Tamil Nadu'],
        '635813': ['Vellore', 'Tamil Nadu'],
        '635814': ['Vellore', 'Tamil Nadu'],
        '635815': ['Vellore', 'Tamil Nadu'],
        '635851': ['Vellore', 'Tamil Nadu'],
        '635852': ['Vellore', 'Tamil Nadu'],
        '635853': ['Vellore', 'Tamil Nadu'],
        '635854': ['Vellore', 'Tamil Nadu'],
        '635901': ['Vellore', 'Tamil Nadu'],
        '636017': ['Coimbatore', 'Tamil Nadu'],
        '638001': ['Erode', 'Tamil Nadu'],
        '638002': ['Erode', 'Tamil Nadu'],
        '638003': ['Erode', 'Tamil Nadu'],
        '638004': ['Erode', 'Tamil Nadu'],
        '638005': ['Erode', 'Tamil Nadu'],
        '638007': ['Erode', 'Tamil Nadu'],
        '638009': ['Erode', 'Tamil Nadu'],
        '638010': ['Erode', 'Tamil Nadu'],
        '638011': ['Erode', 'Tamil Nadu'],
        '638012': ['Erode', 'Tamil Nadu'],
        '638051': ['Erode', 'Tamil Nadu'],
        '638052': ['Erode', 'Tamil Nadu'],
        '638053': ['Erode', 'Tamil Nadu'],
        '638054': ['Erode', 'Tamil Nadu'],
        '638055': ['Erode', 'Tamil Nadu'],
        '638056': ['Erode', 'Tamil Nadu'],
        '638057': ['Erode', 'Tamil Nadu'],
        '638060': ['Coimbatore', 'Tamil Nadu'],
        '638101': ['Erode', 'Tamil Nadu'],
        '638102': ['Erode', 'Tamil Nadu'],
        '638103': ['Coimbatore', 'Tamil Nadu'],
        '638104': ['Erode', 'Tamil Nadu'],
        '638105': ['Erode', 'Tamil Nadu'],
        '638106': ['Erode', 'Tamil Nadu'],
        '638107': ['Erode', 'Tamil Nadu'],
        '638108': ['Erode', 'Tamil Nadu'],
        '638109': ['Erode', 'Tamil Nadu'],
        '638110': ['Erode', 'Tamil Nadu'],
        '638111': ['Erode', 'Tamil Nadu'],
        '638112': ['Erode', 'Tamil Nadu'],
        '638115': ['Erode', 'Tamil Nadu'],
        '638116': ['Erode', 'Tamil Nadu'],
        '638151': ['Erode', 'Tamil Nadu'],
        '638152': ['Erode', 'Tamil Nadu'],
        '638153': ['Erode', 'Tamil Nadu'],
        '638154': ['Erode', 'Tamil Nadu'],
        '638201': ['Coimbatore', 'Tamil Nadu'],
        '638203': ['Coimbatore', 'Tamil Nadu'],
        '638211': ['Coimbatore', 'Tamil Nadu'],
        '638301': ['Erode', 'Tamil Nadu'],
        '638311': ['Erode', 'Tamil Nadu'],
        '638312': ['Erode', 'Tamil Nadu'],
        '638313': ['Erode', 'Tamil Nadu'],
        '638314': ['Erode', 'Tamil Nadu'],
        '638315': ['Erode', 'Tamil Nadu'],
        '638316': ['Erode', 'Tamil Nadu'],
        '638401': ['Erode', 'Tamil Nadu'],
        '638402': ['Erode', 'Tamil Nadu'],
        '638406': ['Coimbatore', 'Tamil Nadu'],
        '638451': ['Erode', 'Tamil Nadu'],
        '638452': ['Erode', 'Tamil Nadu'],
        '638453': ['Erode', 'Tamil Nadu'],
        '638454': ['Erode', 'Tamil Nadu'],
        '638455': ['Erode', 'Tamil Nadu'],
        '638456': ['Erode', 'Tamil Nadu'],
        '638457': ['Erode', 'Tamil Nadu'],
        '638458': ['Erode', 'Tamil Nadu'],
        '638459': ['Erode', 'Tamil Nadu'],
        '638460': ['Erode', 'Tamil Nadu'],
        '638461': ['Erode', 'Tamil Nadu'],
        '638462': ['Erode', 'Tamil Nadu'],
        '638476': ['Erode', 'Tamil Nadu'],
        '638501': ['Erode', 'Tamil Nadu'],
        '638502': ['Erode', 'Tamil Nadu'],
        '638503': ['Erode', 'Tamil Nadu'],
        '638504': ['Erode', 'Tamil Nadu'],
        '638505': ['Erode', 'Tamil Nadu'],
        '638506': ['Erode', 'Tamil Nadu'],
        '638656': ['Erode', 'Tamil Nadu'],
        '638657': ['Erode', 'Tamil Nadu'],
        '638660': ['Erode', 'Tamil Nadu'],
        '638661': ['Erode', 'Tamil Nadu'],
        '638672': ['Erode', 'Tamil Nadu'],
        '638673': ['Erode', 'Tamil Nadu'],
        '638701': ['Erode', 'Tamil Nadu'],
        '638702': ['Erode', 'Tamil Nadu'],
        '638703': ['Erode', 'Tamil Nadu'],
        '638706': ['Erode', 'Tamil Nadu'],
        '638751': ['Erode', 'Tamil Nadu'],
        '638752': ['Erode', 'Tamil Nadu'],
        '638812': ['Erode', 'Tamil Nadu'],
        '639005': ['Trichy', 'Tamil Nadu'],
        '639102': ['Trichy', 'Tamil Nadu'],
        '639103': ['Trichy', 'Tamil Nadu'],
        '639104': ['Trichy', 'Tamil Nadu'],
        '639106': ['Trichy', 'Tamil Nadu'],
        '639111': ['Trichy', 'Tamil Nadu'],
        '639112': ['Trichy', 'Tamil Nadu'],
        '639115': ['Trichy', 'Tamil Nadu'],
        '639116': ['Trichy', 'Tamil Nadu'],
        '639117': ['Trichy', 'Tamil Nadu'],
        '639121': ['Trichy', 'Tamil Nadu'],
        '639202': ['Erode', 'Tamil Nadu'],
        '641001': ['Coimbatore', 'Tamil Nadu'],
        '641002': ['Coimbatore', 'Tamil Nadu'],
        '641003': ['Coimbatore', 'Tamil Nadu'],
        '641004': ['Coimbatore', 'Tamil Nadu'],
        '641005': ['Coimbatore', 'Tamil Nadu'],
        '641006': ['Coimbatore', 'Tamil Nadu'],
        '641007': ['Coimbatore', 'Tamil Nadu'],
        '641008': ['Coimbatore', 'Tamil Nadu'],
        '641009': ['Coimbatore', 'Tamil Nadu'],
        '641010': ['Coimbatore', 'Tamil Nadu'],
        '641011': ['Coimbatore', 'Tamil Nadu'],
        '641012': ['Coimbatore', 'Tamil Nadu'],
        '641013': ['Coimbatore', 'Tamil Nadu'],
        '641014': ['Coimbatore', 'Tamil Nadu'],
        '641015': ['Coimbatore', 'Tamil Nadu'],
        '641016': ['Coimbatore', 'Tamil Nadu'],
        '641017': ['Coimbatore', 'Tamil Nadu'],
        '641018': ['Coimbatore', 'Tamil Nadu'],
        '641019': ['Coimbatore', 'Tamil Nadu'],
        '641020': ['Coimbatore', 'Tamil Nadu'],
        '641021': ['Coimbatore', 'Tamil Nadu'],
        '641022': ['Coimbatore', 'Tamil Nadu'],
        '641023': ['Coimbatore', 'Tamil Nadu'],
        '641024': ['Coimbatore', 'Tamil Nadu'],
        '641025': ['Coimbatore', 'Tamil Nadu'],
        '641026': ['Coimbatore', 'Tamil Nadu'],
        '641027': ['Coimbatore', 'Tamil Nadu'],
        '641028': ['Coimbatore', 'Tamil Nadu'],
        '641029': ['Coimbatore', 'Tamil Nadu'],
        '641030': ['Coimbatore', 'Tamil Nadu'],
        '641031': ['Coimbatore', 'Tamil Nadu'],
        '641032': ['Coimbatore', 'Tamil Nadu'],
        '641033': ['Coimbatore', 'Tamil Nadu'],
        '641034': ['Coimbatore', 'Tamil Nadu'],
        '641035': ['Coimbatore', 'Tamil Nadu'],
        '641036': ['Coimbatore', 'Tamil Nadu'],
        '641037': ['Coimbatore', 'Tamil Nadu'],
        '641038': ['Coimbatore', 'Tamil Nadu'],
        '641039': ['Coimbatore', 'Tamil Nadu'],
        '641040': ['Coimbatore', 'Tamil Nadu'],
        '641041': ['Coimbatore', 'Tamil Nadu'],
        '641042': ['Coimbatore', 'Tamil Nadu'],
        '641043': ['Coimbatore', 'Tamil Nadu'],
        '641044': ['Coimbatore', 'Tamil Nadu'],
        '641045': ['Coimbatore', 'Tamil Nadu'],
        '641046': ['Coimbatore', 'Tamil Nadu'],
        '641047': ['Coimbatore', 'Tamil Nadu'],
        '641048': ['Coimbatore', 'Tamil Nadu'],
        '641049': ['Coimbatore', 'Tamil Nadu'],
        '641050': ['Coimbatore', 'Tamil Nadu'],
        '641062': ['Coimbatore', 'Tamil Nadu'],
        '641101': ['Coimbatore', 'Tamil Nadu'],
        '641103': ['Coimbatore', 'Tamil Nadu'],
        '641104': ['Coimbatore', 'Tamil Nadu'],
        '641105': ['Coimbatore', 'Tamil Nadu'],
        '641106': ['Coimbatore', 'Tamil Nadu'],
        '641107': ['Coimbatore', 'Tamil Nadu'],
        '641108': ['Coimbatore', 'Tamil Nadu'],
        '641109': ['Coimbatore', 'Tamil Nadu'],
        '641110': ['Coimbatore', 'Tamil Nadu'],
        '641111': ['Coimbatore', 'Tamil Nadu'],
        '641112': ['Coimbatore', 'Tamil Nadu'],
        '641113': ['Coimbatore', 'Tamil Nadu'],
        '641114': ['Coimbatore', 'Tamil Nadu'],
        '641115': ['Coimbatore', 'Tamil Nadu'],
        '641118': ['Coimbatore', 'Tamil Nadu'],
        '641120': ['Coimbatore', 'Tamil Nadu'],
        '641201': ['Coimbatore', 'Tamil Nadu'],
        '641202': ['Coimbatore', 'Tamil Nadu'],
        '641301': ['Coimbatore', 'Tamil Nadu'],
        '641302': ['Coimbatore', 'Tamil Nadu'],
        '641305': ['Coimbatore', 'Tamil Nadu'],
        '641401': ['Coimbatore', 'Tamil Nadu'],
        '641402': ['Coimbatore', 'Tamil Nadu'],
        '641407': ['Coimbatore', 'Tamil Nadu'],
        '641601': ['Coimbatore', 'Tamil Nadu'],
        '641602': ['Coimbatore', 'Tamil Nadu'],
        '641603': ['Coimbatore', 'Tamil Nadu'],
        '641604': ['Coimbatore', 'Tamil Nadu'],
        '641605': ['Erode', 'Tamil Nadu'],
        '641606': ['Coimbatore', 'Tamil Nadu'],
        '641607': ['Coimbatore', 'Tamil Nadu'],
        '641652': ['Coimbatore', 'Tamil Nadu'],
        '641653': ['Coimbatore', 'Tamil Nadu'],
        '641654': ['Coimbatore', 'Tamil Nadu'],
        '641655': ['Coimbatore', 'Tamil Nadu'],
        '641658': ['Coimbatore', 'Tamil Nadu'],
        '641659': ['Coimbatore', 'Tamil Nadu'],
        '641662': ['Coimbatore', 'Tamil Nadu'],
        '641663': ['Coimbatore', 'Tamil Nadu'],
        '641664': ['Coimbatore', 'Tamil Nadu'],
        '641665': ['Coimbatore', 'Tamil Nadu'],
        '641666': ['Coimbatore', 'Tamil Nadu'],
        '641667': ['Coimbatore', 'Tamil Nadu'],
        '641668': ['Coimbatore', 'Tamil Nadu'],
        '641669': ['Coimbatore', 'Tamil Nadu'],
        '641670': ['Coimbatore', 'Tamil Nadu'],
        '641671': ['Coimbatore', 'Tamil Nadu'],
        '641687': ['Coimbatore', 'Tamil Nadu'],
        '641697': ['Coimbatore', 'Tamil Nadu'],
        '642001': ['Coimbatore', 'Tamil Nadu'],
        '642002': ['Coimbatore', 'Tamil Nadu'],
        '642003': ['Coimbatore', 'Tamil Nadu'],
        '642004': ['Coimbatore', 'Tamil Nadu'],
        '642005': ['Coimbatore', 'Tamil Nadu'],
        '642006': ['Coimbatore', 'Tamil Nadu'],
        '642007': ['Coimbatore', 'Tamil Nadu'],
        '642051': ['Coimbatore', 'Tamil Nadu'],
        '642101': ['Coimbatore', 'Tamil Nadu'],
        '642102': ['Coimbatore', 'Tamil Nadu'],
        '642103': ['Coimbatore', 'Tamil Nadu'],
        '642104': ['Coimbatore', 'Tamil Nadu'],
        '642105': ['Coimbatore', 'Tamil Nadu'],
        '642106': ['Coimbatore', 'Tamil Nadu'],
        '642107': ['Coimbatore', 'Tamil Nadu'],
        '642108': ['Coimbatore', 'Tamil Nadu'],
        '642109': ['Coimbatore', 'Tamil Nadu'],
        '642110': ['Coimbatore', 'Tamil Nadu'],
        '642111': ['Coimbatore', 'Tamil Nadu'],
        '642112': ['Coimbatore', 'Tamil Nadu'],
        '642113': ['Coimbatore', 'Tamil Nadu'],
        '642114': ['Coimbatore', 'Tamil Nadu'],
        '642115': ['Coimbatore', 'Tamil Nadu'],
        '642116': ['Coimbatore', 'Tamil Nadu'],
        '642117': ['Coimbatore', 'Tamil Nadu'],
        '642118': ['Coimbatore', 'Tamil Nadu'],
        '642119': ['Coimbatore', 'Tamil Nadu'],
        '642120': ['Coimbatore', 'Tamil Nadu'],
        '642122': ['Coimbatore', 'Tamil Nadu'],
        '642123': ['Coimbatore', 'Tamil Nadu'],
        '642124': ['Coimbatore', 'Tamil Nadu'],
        '642125': ['Coimbatore', 'Tamil Nadu'],
        '642126': ['Coimbatore', 'Tamil Nadu'],
        '642127': ['Coimbatore', 'Tamil Nadu'],
        '642128': ['Coimbatore', 'Tamil Nadu'],
        '642129': ['Coimbatore', 'Tamil Nadu'],
        '642130': ['Coimbatore', 'Tamil Nadu'],
        '642131': ['Coimbatore', 'Tamil Nadu'],
        '642132': ['Coimbatore', 'Tamil Nadu'],
        '642133': ['Coimbatore', 'Tamil Nadu'],
        '642134': ['Coimbatore', 'Tamil Nadu'],
        '642135': ['Coimbatore', 'Tamil Nadu'],
        '642136': ['Coimbatore', 'Tamil Nadu'],
        '642137': ['Coimbatore', 'Tamil Nadu'],
        '642139': ['Coimbatore', 'Tamil Nadu'],
        '642152': ['Coimbatore', 'Tamil Nadu'],
        '642153': ['Coimbatore', 'Tamil Nadu'],
        '642154': ['Coimbatore', 'Tamil Nadu'],
        '642201': ['Coimbatore', 'Tamil Nadu'],
        '642202': ['Coimbatore', 'Tamil Nadu'],
        '642203': ['Coimbatore', 'Tamil Nadu'],
        '642204': ['Coimbatore', 'Tamil Nadu'],
        '642205': ['Coimbatore', 'Tamil Nadu'],
        '642206': ['Coimbatore', 'Tamil Nadu'],
        '642207': ['Coimbatore', 'Tamil Nadu'],
        '670001': ['Kannur', 'Kerala'],
        '670002': ['Kannur', 'Kerala'],
        '670003': ['Kannur', 'Kerala'],
        '670004': ['Kannur', 'Kerala'],
        '670005': ['Kannur', 'Kerala'],
        '670006': ['Kannur', 'Kerala'],
        '670007': ['Kannur', 'Kerala'],
        '670008': ['Kannur', 'Kerala'],
        '670009': ['Kannur', 'Kerala'],
        '670010': ['Kannur', 'Kerala'],
        '670011': ['Kannur', 'Kerala'],
        '670012': ['Kannur', 'Kerala'],
        '670013': ['Kannur', 'Kerala'],
        '670014': ['Kannur', 'Kerala'],
        '670017': ['Kannur', 'Kerala'],
        '670018': ['Kannur', 'Kerala'],
        '670101': ['Kannur', 'Kerala'],
        '670102': ['Kannur', 'Kerala'],
        '670103': ['Kannur', 'Kerala'],
        '670104': ['Kannur', 'Kerala'],
        '670105': ['Kannur', 'Kerala'],
        '670106': ['Kannur', 'Kerala'],
        '670107': ['Kannur', 'Kerala'],
        '670141': ['Kannur', 'Kerala'],
        '670142': ['Kannur', 'Kerala'],
        '670143': ['Kannur', 'Kerala'],
        '670301': ['Kannur', 'Kerala'],
        '670302': ['Kannur', 'Kerala'],
        '670303': ['Kannur', 'Kerala'],
        '670304': ['Kannur', 'Kerala'],
        '670305': ['Kannur', 'Kerala'],
        '670306': ['Kannur', 'Kerala'],
        '670307': ['Kannur', 'Kerala'],
        '670308': ['Kannur', 'Kerala'],
        '670309': ['Kannur', 'Kerala'],
        '670310': ['Kannur', 'Kerala'],
        '670325': ['Kannur', 'Kerala'],
        '670327': ['Kannur', 'Kerala'],
        '670330': ['Calicut', 'Kerala'],
        '670331': ['Kannur', 'Kerala'],
        '670334': ['Kannur', 'Kerala'],
        '670353': ['Kannur', 'Kerala'],
        '670358': ['Kannur', 'Kerala'],
        '670501': ['Kannur', 'Kerala'],
        '670502': ['Kannur', 'Kerala'],
        '670503': ['Kannur', 'Kerala'],
        '670504': ['Kannur', 'Kerala'],
        '670511': ['Kannur', 'Kerala'],
        '670521': ['Kannur', 'Kerala'],
        '670561': ['Kannur', 'Kerala'],
        '670562': ['Kannur', 'Kerala'],
        '670563': ['Kannur', 'Kerala'],
        '670567': ['Kannur', 'Kerala'],
        '670571': ['Kannur', 'Kerala'],
        '670581': ['Kannur', 'Kerala'],
        '670582': ['Kannur', 'Kerala'],
        '670591': ['Kannur', 'Kerala'],
        '670592': ['Kannur', 'Kerala'],
        '670593': ['Kannur', 'Kerala'],
        '670594': ['Kannur', 'Kerala'],
        '670595': ['Kannur', 'Kerala'],
        '670601': ['Kannur', 'Kerala'],
        '670602': ['Kannur', 'Kerala'],
        '670604': ['Kannur', 'Kerala'],
        '670611': ['Kannur', 'Kerala'],
        '670612': ['Kannur', 'Kerala'],
        '670613': ['Kannur', 'Kerala'],
        '670621': ['Kannur', 'Kerala'],
        '670622': ['Kannur', 'Kerala'],
        '670631': ['Kannur', 'Kerala'],
        '670632': ['Kannur', 'Kerala'],
        '670633': ['Kannur', 'Kerala'],
        '670641': ['Kannur', 'Kerala'],
        '670642': ['Kannur', 'Kerala'],
        '670643': ['Kannur', 'Kerala'],
        '670649': ['Kannur', 'Kerala'],
        '670650': ['Kannur', 'Kerala'],
        '670651': ['Kannur', 'Kerala'],
        '670661': ['Kannur', 'Kerala'],
        '670662': ['Kannur', 'Kerala'],
        '670663': ['Kannur', 'Kerala'],
        '670671': ['Kannur', 'Kerala'],
        '670672': ['Kannur', 'Kerala'],
        '670673': ['Kannur', 'Kerala'],
        '670674': ['Kannur', 'Kerala'],
        '670675': ['Kannur', 'Kerala'],
        '670676': ['Kannur', 'Kerala'],
        '670691': ['Kannur', 'Kerala'],
        '670692': ['Kannur', 'Kerala'],
        '670693': ['Kannur', 'Kerala'],
        '670694': ['Kannur', 'Kerala'],
        '670701': ['Kannur', 'Kerala'],
        '670702': ['Kannur', 'Kerala'],
        '670703': ['Kannur', 'Kerala'],
        '670704': ['Kannur', 'Kerala'],
        '670705': ['Kannur', 'Kerala'],
        '670706': ['Kannur', 'Kerala'],
        '670741': ['Kannur', 'Kerala'],
        '671534': ['Calicut', 'Kerala'],
        '673001': ['Calicut', 'Kerala'],
        '673002': ['Calicut', 'Kerala'],
        '673003': ['Calicut', 'Kerala'],
        '673004': ['Calicut', 'Kerala'],
        '673005': ['Calicut', 'Kerala'],
        '673006': ['Calicut', 'Kerala'],
        '673007': ['Calicut', 'Kerala'],
        '673008': ['Calicut', 'Kerala'],
        '673009': ['Calicut', 'Kerala'],
        '673010': ['Calicut', 'Kerala'],
        '673011': ['Calicut', 'Kerala'],
        '673012': ['Calicut', 'Kerala'],
        '673013': ['Calicut', 'Kerala'],
        '673014': ['Calicut', 'Kerala'],
        '673015': ['Calicut', 'Kerala'],
        '673016': ['Calicut', 'Kerala'],
        '673017': ['Calicut', 'Kerala'],
        '673018': ['Calicut', 'Kerala'],
        '673019': ['Calicut', 'Kerala'],
        '673020': ['Calicut', 'Kerala'],
        '673021': ['Calicut', 'Kerala'],
        '673023': ['Calicut', 'Kerala'],
        '673025': ['Calicut', 'Kerala'],
        '673026': ['Calicut', 'Kerala'],
        '673027': ['Calicut', 'Kerala'],
        '673028': ['Calicut', 'Kerala'],
        '673029': ['Calicut', 'Kerala'],
        '673032': ['Calicut', 'Kerala'],
        '673051': ['Calicut', 'Kerala'],
        '673101': ['Calicut', 'Kerala'],
        '673102': ['Calicut', 'Kerala'],
        '673103': ['Calicut', 'Kerala'],
        '673104': ['Calicut', 'Kerala'],
        '673105': ['Calicut', 'Kerala'],
        '673106': ['Calicut', 'Kerala'],
        '673107': ['Calicut', 'Kerala'],
        '673108': ['Calicut', 'Kerala'],
        '673109': ['Calicut', 'Kerala'],
        '673110': ['Calicut', 'Kerala'],
        '673112': ['Calicut', 'Kerala'],
        '673301': ['Calicut', 'Kerala'],
        '673302': ['Calicut', 'Kerala'],
        '673303': ['Calicut', 'Kerala'],
        '673304': ['Calicut', 'Kerala'],
        '673305': ['Calicut', 'Kerala'],
        '673306': ['Calicut', 'Kerala'],
        '673307': ['Calicut', 'Kerala'],
        '673308': ['Calicut', 'Kerala'],
        '673309': ['Calicut', 'Kerala'],
        '673310': ['Calicut', 'Kerala'],
        '673311': ['Kannur', 'Kerala'],
        '673312': ['Kannur', 'Kerala'],
        '673313': ['Kannur', 'Kerala'],
        '673315': ['Calicut', 'Kerala'],
        '673316': ['Kannur', 'Kerala'],
        '673317': ['Calicut', 'Kerala'],
        '673318': ['Calicut', 'Kerala'],
        '673319': ['Calicut', 'Kerala'],
        '673320': ['Calicut', 'Kerala'],
        '673321': ['Calicut', 'Kerala'],
        '673322': ['Calicut', 'Kerala'],
        '673323': ['Calicut', 'Kerala'],
        '673324': ['Calicut', 'Kerala'],
        '673326': ['Calicut', 'Kerala'],
        '673327': ['Calicut', 'Kerala'],
        '673328': ['Calicut', 'Kerala'],
        '673333': ['Calicut', 'Kerala'],
        '673501': ['Calicut', 'Kerala'],
        '673502': ['Calicut', 'Kerala'],
        '673503': ['Calicut', 'Kerala'],
        '673504': ['Calicut', 'Kerala'],
        '673505': ['Calicut', 'Kerala'],
        '673506': ['Calicut', 'Kerala'],
        '673508': ['Calicut', 'Kerala'],
        '673509': ['Calicut', 'Kerala'],
        '673510': ['Calicut', 'Kerala'],
        '673511': ['Calicut', 'Kerala'],
        '673512': ['Calicut', 'Kerala'],
        '673513': ['Calicut', 'Kerala'],
        '673514': ['Calicut', 'Kerala'],
        '673515': ['Calicut', 'Kerala'],
        '673517': ['Calicut', 'Kerala'],
        '673518': ['Calicut', 'Kerala'],
        '673519': ['Calicut', 'Kerala'],
        '673520': ['Calicut', 'Kerala'],
        '673521': ['Calicut', 'Kerala'],
        '673522': ['Calicut', 'Kerala'],
        '673523': ['Calicut', 'Kerala'],
        '673524': ['Calicut', 'Kerala'],
        '673525': ['Calicut', 'Kerala'],
        '673526': ['Calicut', 'Kerala'],
        '673527': ['Calicut', 'Kerala'],
        '673528': ['Calicut', 'Kerala'],
        '673529': ['Calicut', 'Kerala'],
        '673530': ['Calicut', 'Kerala'],
        '673531': ['Calicut', 'Kerala'],
        '673532': ['Calicut', 'Kerala'],
        '673534': ['Calicut', 'Kerala'],
        '673541': ['Calicut', 'Kerala'],
        '673542': ['Calicut', 'Kerala'],
        '673544': ['Calicut', 'Kerala'],
        '673571': ['Calicut', 'Kerala'],
        '673572': ['Calicut', 'Kerala'],
        '673574': ['Calicut', 'Kerala'],
        '673580': ['Calicut', 'Kerala'],
        '673583': ['Calicut', 'Kerala'],
        '673586': ['Calicut', 'Kerala'],
        '673587': ['Calicut', 'Kerala'],
        '673591': ['Calicut', 'Kerala'],
        '673593': ['Calicut', 'Kerala'],
        '673602': ['Calicut', 'Kerala'],
        '673604': ['Calicut', 'Kerala'],
        '673611': ['Calicut', 'Kerala'],
        '673612': ['Calicut', 'Kerala'],
        '673613': ['Calicut', 'Kerala'],
        '673614': ['Calicut', 'Kerala'],
        '673615': ['Calicut', 'Kerala'],
        '673616': ['Calicut', 'Kerala'],
        '673617': ['Calicut', 'Kerala'],
        '673618': ['Calicut', 'Kerala'],
        '673619': ['Calicut', 'Kerala'],
        '673620': ['Calicut', 'Kerala'],
        '673621': ['Calicut', 'Kerala'],
        '673624': ['Calicut', 'Kerala'],
        '673631': ['Calicut', 'Kerala'],
        '673632': ['Calicut', 'Kerala'],
        '673633': ['Calicut', 'Kerala'],
        '673635': ['Calicut', 'Kerala'],
        '673643': ['Calicut', 'Kerala'],
        '673654': ['Calicut', 'Kerala'],
        '673655': ['Calicut', 'Kerala'],
        '673661': ['Calicut', 'Kerala'],
        '678001': ['Palakkad', 'Kerala'],
        '678002': ['Palakkad', 'Kerala'],
        '678003': ['Palakkad', 'Kerala'],
        '678004': ['Palakkad', 'Kerala'],
        '678005': ['Palakkad', 'Kerala'],
        '678006': ['Palakkad', 'Kerala'],
        '678007': ['Palakkad', 'Kerala'],
        '678008': ['Palakkad', 'Kerala'],
        '678009': ['Palakkad', 'Kerala'],
        '678010': ['Palakkad', 'Kerala'],
        '678011': ['Palakkad', 'Kerala'],
        '678012': ['Palakkad', 'Kerala'],
        '678013': ['Palakkad', 'Kerala'],
        '678014': ['Palakkad', 'Kerala'],
        '678101': ['Palakkad', 'Kerala'],
        '678102': ['Palakkad', 'Kerala'],
        '678103': ['Palakkad', 'Kerala'],
        '678104': ['Palakkad', 'Kerala'],
        '678501': ['Palakkad', 'Kerala'],
        '678502': ['Palakkad', 'Kerala'],
        '678503': ['Palakkad', 'Kerala'],
        '678504': ['Palakkad', 'Kerala'],
        '678505': ['Palakkad', 'Kerala'],
        '678506': ['Palakkad', 'Kerala'],
        '678507': ['Palakkad', 'Kerala'],
        '678508': ['Palakkad', 'Kerala'],
        '678510': ['Palakkad', 'Kerala'],
        '678512': ['Palakkad', 'Kerala'],
        '678531': ['Palakkad', 'Kerala'],
        '678532': ['Palakkad', 'Kerala'],
        '678533': ['Palakkad', 'Kerala'],
        '678534': ['Palakkad', 'Kerala'],
        '678541': ['Palakkad', 'Kerala'],
        '678542': ['Palakkad', 'Kerala'],
        '678543': ['Palakkad', 'Kerala'],
        '678544': ['Palakkad', 'Kerala'],
        '678545': ['Palakkad', 'Kerala'],
        '678546': ['Palakkad', 'Kerala'],
        '678551': ['Palakkad', 'Kerala'],
        '678552': ['Palakkad', 'Kerala'],
        '678553': ['Palakkad', 'Kerala'],
        '678554': ['Palakkad', 'Kerala'],
        '678555': ['Palakkad', 'Kerala'],
        '678556': ['Palakkad', 'Kerala'],
        '678557': ['Palakkad', 'Kerala'],
        '678571': ['Palakkad', 'Kerala'],
        '678572': ['Palakkad', 'Kerala'],
        '678573': ['Palakkad', 'Kerala'],
        '678574': ['Palakkad', 'Kerala'],
        '678581': ['Palakkad', 'Kerala'],
        '678582': ['Palakkad', 'Kerala'],
        '678583': ['Palakkad', 'Kerala'],
        '678591': ['Palakkad', 'Kerala'],
        '678592': ['Palakkad', 'Kerala'],
        '678593': ['Palakkad', 'Kerala'],
        '678594': ['Palakkad', 'Kerala'],
        '678595': ['Palakkad', 'Kerala'],
        '678596': ['Palakkad', 'Kerala'],
        '678597': ['Palakkad', 'Kerala'],
        '678598': ['Palakkad', 'Kerala'],
        '678601': ['Palakkad', 'Kerala'],
        '678611': ['Palakkad', 'Kerala'],
        '678612': ['Palakkad', 'Kerala'],
        '678613': ['Palakkad', 'Kerala'],
        '678621': ['Palakkad', 'Kerala'],
        '678622': ['Palakkad', 'Kerala'],
        '678623': ['Palakkad', 'Kerala'],
        '678624': ['Palakkad', 'Kerala'],
        '678631': ['Palakkad', 'Kerala'],
        '678632': ['Palakkad', 'Kerala'],
        '678633': ['Palakkad', 'Kerala'],
        '678641': ['Palakkad', 'Kerala'],
        '678642': ['Palakkad', 'Kerala'],
        '678651': ['Palakkad', 'Kerala'],
        '678661': ['Palakkad', 'Kerala'],
        '678671': ['Palakkad', 'Kerala'],
        '678681': ['Palakkad', 'Kerala'],
        '678682': ['Palakkad', 'Kerala'],
        '678683': ['Palakkad', 'Kerala'],
        '678684': ['Palakkad', 'Kerala'],
        '678685': ['Palakkad', 'Kerala'],
        '678686': ['Palakkad', 'Kerala'],
        '678687': ['Palakkad', 'Kerala'],
        '678688': ['Palakkad', 'Kerala'],
        '678701': ['Palakkad', 'Kerala'],
        '678702': ['Palakkad', 'Kerala'],
        '678703': ['Palakkad', 'Kerala'],
        '678704': ['Palakkad', 'Kerala'],
        '678705': ['Palakkad', 'Kerala'],
        '678706': ['Palakkad', 'Kerala'],
        '678721': ['Palakkad', 'Kerala'],
        '678722': ['Palakkad', 'Kerala'],
        '678731': ['Palakkad', 'Kerala'],
        '678732': ['Palakkad', 'Kerala'],
        '678762': ['Palakkad', 'Kerala'],
        '679101': ['Palakkad', 'Kerala'],
        '679102': ['Palakkad', 'Kerala'],
        '679103': ['Palakkad', 'Kerala'],
        '679104': ['Palakkad', 'Kerala'],
        '679105': ['Thrissur', 'Kerala'],
        '679106': ['Thrissur', 'Kerala'],
        '679121': ['Palakkad', 'Kerala'],
        '679122': ['Palakkad', 'Kerala'],
        '679123': ['Palakkad', 'Kerala'],
        '679301': ['Palakkad', 'Kerala'],
        '679302': ['Palakkad', 'Kerala'],
        '679303': ['Palakkad', 'Kerala'],
        '679304': ['Palakkad', 'Kerala'],
        '679305': ['Palakkad', 'Kerala'],
        '679306': ['Palakkad', 'Kerala'],
        '679307': ['Palakkad', 'Kerala'],
        '679308': ['Palakkad', 'Kerala'],
        '679309': ['Palakkad', 'Kerala'],
        '679313': ['Palakkad', 'Kerala'],
        '679335': ['Palakkad', 'Kerala'],
        '679336': ['Palakkad', 'Kerala'],
        '679337': ['Palakkad', 'Kerala'],
        '679501': ['Palakkad', 'Kerala'],
        '679502': ['Palakkad', 'Kerala'],
        '679503': ['Palakkad', 'Kerala'],
        '679504': ['Palakkad', 'Kerala'],
        '679505': ['Palakkad', 'Kerala'],
        '679506': ['Palakkad', 'Kerala'],
        '679511': ['Palakkad', 'Kerala'],
        '679512': ['Palakkad', 'Kerala'],
        '679513': ['Palakkad', 'Kerala'],
        '679514': ['Palakkad', 'Kerala'],
        '679515': ['Palakkad', 'Kerala'],
        '679516': ['Palakkad', 'Kerala'],
        '679521': ['Palakkad', 'Kerala'],
        '679522': ['Palakkad', 'Kerala'],
        '679523': ['Palakkad', 'Kerala'],
        '679531': ['Thrissur', 'Kerala'],
        '679532': ['Thrissur', 'Kerala'],
        '679533': ['Palakkad', 'Kerala'],
        '679534': ['Palakkad', 'Kerala'],
        '679535': ['Palakkad', 'Kerala'],
        '679536': ['Palakkad', 'Kerala'],
        '679551': ['Palakkad', 'Kerala'],
        '679552': ['Palakkad', 'Kerala'],
        '679553': ['Palakkad', 'Kerala'],
        '679554': ['Palakkad', 'Kerala'],
        '679561': ['Thrissur', 'Kerala'],
        '679562': ['Thrissur', 'Kerala'],
        '679563': ['Thrissur', 'Kerala'],
        '679564': ['Thrissur', 'Kerala'],
        '680001': ['Thrissur', 'Kerala'],
        '680002': ['Thrissur', 'Kerala'],
        '680003': ['Thrissur', 'Kerala'],
        '680004': ['Thrissur', 'Kerala'],
        '680005': ['Thrissur', 'Kerala'],
        '680006': ['Thrissur', 'Kerala'],
        '680007': ['Thrissur', 'Kerala'],
        '680008': ['Thrissur', 'Kerala'],
        '680009': ['Thrissur', 'Kerala'],
        '680010': ['Thrissur', 'Kerala'],
        '680011': ['Thrissur', 'Kerala'],
        '680012': ['Thrissur', 'Kerala'],
        '680013': ['Thrissur', 'Kerala'],
        '680014': ['Thrissur', 'Kerala'],
        '680020': ['Thrissur', 'Kerala'],
        '680021': ['Thrissur', 'Kerala'],
        '680022': ['Thrissur', 'Kerala'],
        '680026': ['Thrissur', 'Kerala'],
        '680027': ['Thrissur', 'Kerala'],
        '680028': ['Thrissur', 'Kerala'],
        '680101': ['Thrissur', 'Kerala'],
        '680102': ['Thrissur', 'Kerala'],
        '680103': ['Thrissur', 'Kerala'],
        '680104': ['Thrissur', 'Kerala'],
        '680121': ['Thrissur', 'Kerala'],
        '680122': ['Thrissur', 'Kerala'],
        '680123': ['Thrissur', 'Kerala'],
        '680125': ['Thrissur', 'Kerala'],
        '680301': ['Thrissur', 'Kerala'],
        '680302': ['Thrissur', 'Kerala'],
        '680303': ['Thrissur', 'Kerala'],
        '680304': ['Thrissur', 'Kerala'],
        '680305': ['Thrissur', 'Kerala'],
        '680306': ['Thrissur', 'Kerala'],
        '680307': ['Thrissur', 'Kerala'],
        '680308': ['Thrissur', 'Kerala'],
        '680309': ['Thrissur', 'Kerala'],
        '680310': ['Thrissur', 'Kerala'],
        '680311': ['Thrissur', 'Kerala'],
        '680312': ['Thrissur', 'Kerala'],
        '680317': ['Thrissur', 'Kerala'],
        '680501': ['Thrissur', 'Kerala'],
        '680502': ['Thrissur', 'Kerala'],
        '680503': ['Thrissur', 'Kerala'],
        '680504': ['Thrissur', 'Kerala'],
        '680505': ['Thrissur', 'Kerala'],
        '680506': ['Thrissur', 'Kerala'],
        '680507': ['Thrissur', 'Kerala'],
        '680508': ['Thrissur', 'Kerala'],
        '680509': ['Thrissur', 'Kerala'],
        '680510': ['Thrissur', 'Kerala'],
        '680511': ['Thrissur', 'Kerala'],
        '680512': ['Thrissur', 'Kerala'],
        '680513': ['Thrissur', 'Kerala'],
        '680514': ['Thrissur', 'Kerala'],
        '680515': ['Thrissur', 'Kerala'],
        '680516': ['Thrissur', 'Kerala'],
        '680517': ['Thrissur', 'Kerala'],
        '680518': ['Thrissur', 'Kerala'],
        '680519': ['Thrissur', 'Kerala'],
        '680520': ['Thrissur', 'Kerala'],
        '680521': ['Thrissur', 'Kerala'],
        '680522': ['Thrissur', 'Kerala'],
        '680523': ['Thrissur', 'Kerala'],
        '680524': ['Thrissur', 'Kerala'],
        '680541': ['Thrissur', 'Kerala'],
        '680542': ['Thrissur', 'Kerala'],
        '680543': ['Thrissur', 'Kerala'],
        '680544': ['Thrissur', 'Kerala'],
        '680545': ['Thrissur', 'Kerala'],
        '680546': ['Thrissur', 'Kerala'],
        '680551': ['Thrissur', 'Kerala'],
        '680552': ['Thrissur', 'Kerala'],
        '680553': ['Thrissur', 'Kerala'],
        '680555': ['Thrissur', 'Kerala'],
        '680561': ['Thrissur', 'Kerala'],
        '680562': ['Thrissur', 'Kerala'],
        '680563': ['Thrissur', 'Kerala'],
        '680564': ['Thrissur', 'Kerala'],
        '680565': ['Thrissur', 'Kerala'],
        '680566': ['Thrissur', 'Kerala'],
        '680567': ['Thrissur', 'Kerala'],
        '680568': ['Thrissur', 'Kerala'],
        '680569': ['Thrissur', 'Kerala'],
        '680570': ['Thrissur', 'Kerala'],
        '680571': ['Thrissur', 'Kerala'],
        '680581': ['Thrissur', 'Kerala'],
        '680582': ['Thrissur', 'Kerala'],
        '680583': ['Thrissur', 'Kerala'],
        '680584': ['Thrissur', 'Kerala'],
        '680585': ['Thrissur', 'Kerala'],
        '680586': ['Thrissur', 'Kerala'],
        '680587': ['Thrissur', 'Kerala'],
        '680588': ['Thrissur', 'Kerala'],
        '680589': ['Thrissur', 'Kerala'],
        '680590': ['Thrissur', 'Kerala'],
        '680591': ['Thrissur', 'Kerala'],
        '680594': ['Thrissur', 'Kerala'],
        '680596': ['Thrissur', 'Kerala'],
        '680601': ['Thrissur', 'Kerala'],
        '680602': ['Thrissur', 'Kerala'],
        '680604': ['Thrissur', 'Kerala'],
        '680611': ['Thrissur', 'Kerala'],
        '680612': ['Thrissur', 'Kerala'],
        '680613': ['Thrissur', 'Kerala'],
        '680614': ['Thrissur', 'Kerala'],
        '680615': ['Thrissur', 'Kerala'],
        '680616': ['Thrissur', 'Kerala'],
        '680617': ['Thrissur', 'Kerala'],
        '680618': ['Thrissur', 'Kerala'],
        '680619': ['Thrissur', 'Kerala'],
        '680620': ['Thrissur', 'Kerala'],
        '680623': ['Thrissur', 'Kerala'],
        '680631': ['Thrissur', 'Kerala'],
        '680641': ['Thrissur', 'Kerala'],
        '680642': ['Thrissur', 'Kerala'],
        '680651': ['Thrissur', 'Kerala'],
        '680652': ['Thrissur', 'Kerala'],
        '680653': ['Thrissur', 'Kerala'],
        '680654': ['Thrissur', 'Kerala'],
        '680655': ['Thrissur', 'Kerala'],
        '680656': ['Thrissur', 'Kerala'],
        '680661': ['Thrissur', 'Kerala'],
        '680662': ['Thrissur', 'Kerala'],
        '680663': ['Thrissur', 'Kerala'],
        '680664': ['Thrissur', 'Kerala'],
        '680665': ['Thrissur', 'Kerala'],
        '680666': ['Thrissur', 'Kerala'],
        '680667': ['Thrissur', 'Kerala'],
        '680668': ['Thrissur', 'Kerala'],
        '680669': ['Thrissur', 'Kerala'],
        '680670': ['Thrissur', 'Kerala'],
        '680671': ['Thrissur', 'Kerala'],
        '680681': ['Thrissur', 'Kerala'],
        '680682': ['Thrissur', 'Kerala'],
        '680683': ['Thrissur', 'Kerala'],
        '680684': ['Thrissur', 'Kerala'],
        '680685': ['Thrissur', 'Kerala'],
        '680686': ['Thrissur', 'Kerala'],
        '680687': ['Thrissur', 'Kerala'],
        '680688': ['Thrissur', 'Kerala'],
        '680689': ['Thrissur', 'Kerala'],
        '680691': ['Thrissur', 'Kerala'],
        '680697': ['Thrissur', 'Kerala'],
        '680699': ['Thrissur', 'Kerala'],
        '680701': ['Thrissur', 'Kerala'],
        '680702': ['Thrissur', 'Kerala'],
        '680703': ['Thrissur', 'Kerala'],
        '680711': ['Thrissur', 'Kerala'],
        '680712': ['Thrissur', 'Kerala'],
        '680721': ['Thrissur', 'Kerala'],
        '680722': ['Thrissur', 'Kerala'],
        '680724': ['Thrissur', 'Kerala'],
        '680731': ['Thrissur', 'Kerala'],
        '680732': ['Thrissur', 'Kerala'],
        '680733': ['Thrissur', 'Kerala'],
        '680734': ['Thrissur', 'Kerala'],
        '680741': ['Thrissur', 'Kerala'],
        '680751': ['Thrissur', 'Kerala'],
        '682050': ['Kochi', 'Kerala'],
        '682051': ['Kochi', 'Kerala'],
        '685509': ['Trivandrum', 'Kerala'],
        '685591': ['Kottayam', 'Kerala'],
        '686001': ['Kottayam', 'Kerala'],
        '686002': ['Kottayam', 'Kerala'],
        '686003': ['Kottayam', 'Kerala'],
        '686004': ['Kottayam', 'Kerala'],
        '686005': ['Kottayam', 'Kerala'],
        '686006': ['Kottayam', 'Kerala'],
        '686007': ['Kottayam', 'Kerala'],
        '686008': ['Kottayam', 'Kerala'],
        '686009': ['Kottayam', 'Kerala'],
        '686010': ['Kottayam', 'Kerala'],
        '686011': ['Kottayam', 'Kerala'],
        '686012': ['Kottayam', 'Kerala'],
        '686013': ['Kottayam', 'Kerala'],
        '686014': ['Kottayam', 'Kerala'],
        '686015': ['Kottayam', 'Kerala'],
        '686016': ['Kottayam', 'Kerala'],
        '686017': ['Kottayam', 'Kerala'],
        '686018': ['Kottayam', 'Kerala'],
        '686019': ['Kottayam', 'Kerala'],
        '686020': ['Kottayam', 'Kerala'],
        '686021': ['Kottayam', 'Kerala'],
        '686022': ['Kottayam', 'Kerala'],
        '686023': ['Kottayam', 'Kerala'],
        '686024': ['Kottayam', 'Kerala'],
        '686025': ['Kottayam', 'Kerala'],
        '686026': ['Kottayam', 'Kerala'],
        '686028': ['Kottayam', 'Kerala'],
        '686029': ['Kottayam', 'Kerala'],
        '686030': ['Kottayam', 'Kerala'],
        '686032': ['Kottayam', 'Kerala'],
        '686033': ['Kottayam', 'Kerala'],
        '686034': ['Kottayam', 'Kerala'],
        '686035': ['Kottayam', 'Kerala'],
        '686036': ['Kottayam', 'Kerala'],
        '686037': ['Kottayam', 'Kerala'],
        '686038': ['Kottayam', 'Kerala'],
        '686041': ['Kottayam', 'Kerala'],
        '686101': ['Kottayam', 'Kerala'],
        '686102': ['Kottayam', 'Kerala'],
        '686103': ['Kottayam', 'Kerala'],
        '686104': ['Kottayam', 'Kerala'],
        '686105': ['Kottayam', 'Kerala'],
        '686106': ['Kottayam', 'Kerala'],
        '686121': ['Kottayam', 'Kerala'],
        '686122': ['Kottayam', 'Kerala'],
        '686123': ['Kottayam', 'Kerala'],
        '686124': ['Kottayam', 'Kerala'],
        '686141': ['Kottayam', 'Kerala'],
        '686142': ['Kottayam', 'Kerala'],
        '686143': ['Kottayam', 'Kerala'],
        '686144': ['Kottayam', 'Kerala'],
        '686145': ['Kottayam', 'Kerala'],
        '686146': ['Kottayam', 'Kerala'],
        '686501': ['Kottayam', 'Kerala'],
        '686502': ['Kottayam', 'Kerala'],
        '686503': ['Kottayam', 'Kerala'],
        '686504': ['Kottayam', 'Kerala'],
        '686505': ['Kottayam', 'Kerala'],
        '686506': ['Kottayam', 'Kerala'],
        '686507': ['Kottayam', 'Kerala'],
        '686508': ['Kottayam', 'Kerala'],
        '686509': ['Kottayam', 'Kerala'],
        '686510': ['Kottayam', 'Kerala'],
        '686511': ['Kottayam', 'Kerala'],
        '686512': ['Kottayam', 'Kerala'],
        '686513': ['Kottayam', 'Kerala'],
        '686514': ['Kottayam', 'Kerala'],
        '686515': ['Kottayam', 'Kerala'],
        '686516': ['Kottayam', 'Kerala'],
        '686517': ['Kottayam', 'Kerala'],
        '686518': ['Kottayam', 'Kerala'],
        '686519': ['Kottayam', 'Kerala'],
        '686520': ['Kottayam', 'Kerala'],
        '686521': ['Kottayam', 'Kerala'],
        '686522': ['Kottayam', 'Kerala'],
        '686524': ['Kottayam', 'Kerala'],
        '686527': ['Kottayam', 'Kerala'],
        '686529': ['Kottayam', 'Kerala'],
        '686531': ['Kottayam', 'Kerala'],
        '686532': ['Kottayam', 'Kerala'],
        '686533': ['Kottayam', 'Kerala'],
        '686534': ['Kottayam', 'Kerala'],
        '686535': ['Kottayam', 'Kerala'],
        '686536': ['Kottayam', 'Kerala'],
        '686537': ['Kottayam', 'Kerala'],
        '686538': ['Kottayam', 'Kerala'],
        '686539': ['Kottayam', 'Kerala'],
        '686540': ['Kottayam', 'Kerala'],
        '686541': ['Kottayam', 'Kerala'],
        '686542': ['Kottayam', 'Kerala'],
        '686543': ['Kottayam', 'Kerala'],
        '686544': ['Kottayam', 'Kerala'],
        '686545': ['Kottayam', 'Kerala'],
        '686546': ['Kottayam', 'Kerala'],
        '686548': ['Kottayam', 'Kerala'],
        '686549': ['Kottayam', 'Kerala'],
        '686550': ['Kottayam', 'Kerala'],
        '686553': ['Kottayam', 'Kerala'],
        '686555': ['Kottayam', 'Kerala'],
        '686560': ['Kottayam', 'Kerala'],
        '686561': ['Kottayam', 'Kerala'],
        '686562': ['Kottayam', 'Kerala'],
        '686563': ['Kottayam', 'Kerala'],
        '686564': ['Kottayam', 'Kerala'],
        '686566': ['Kottayam', 'Kerala'],
        '686568': ['Kottayam', 'Kerala'],
        '686571': ['Kottayam', 'Kerala'],
        '686572': ['Kottayam', 'Kerala'],
        '686573': ['Kottayam', 'Kerala'],
        '686574': ['Kottayam', 'Kerala'],
        '686575': ['Kottayam', 'Kerala'],
        '686576': ['Kottayam', 'Kerala'],
        '686577': ['Kottayam', 'Kerala'],
        '686578': ['Kottayam', 'Kerala'],
        '686579': ['Kottayam', 'Kerala'],
        '686580': ['Kottayam', 'Kerala'],
        '686581': ['Kottayam', 'Kerala'],
        '686582': ['Kottayam', 'Kerala'],
        '686583': ['Kottayam', 'Kerala'],
        '686584': ['Kottayam', 'Kerala'],
        '686585': ['Kottayam', 'Kerala'],
        '686586': ['Kottayam', 'Kerala'],
        '686587': ['Kottayam', 'Kerala'],
        '686588': ['Kottayam', 'Kerala'],
        '686589': ['Kottayam', 'Kerala'],
        '686590': ['Kottayam', 'Kerala'],
        '686592': ['Kottayam', 'Kerala'],
        '686594': ['Kottayam', 'Kerala'],
        '686595': ['Kottayam', 'Kerala'],
        '686596': ['Kottayam', 'Kerala'],
        '686597': ['Kottayam', 'Kerala'],
        '686600': ['Kottayam', 'Kerala'],
        '686601': ['Kottayam', 'Kerala'],
        '686602': ['Kottayam', 'Kerala'],
        '686603': ['Kottayam', 'Kerala'],
        '686604': ['Kottayam', 'Kerala'],
        '686605': ['Kottayam', 'Kerala'],
        '686606': ['Kottayam', 'Kerala'],
        '686607': ['Kottayam', 'Kerala'],
        '686608': ['Kottayam', 'Kerala'],
        '686609': ['Kottayam', 'Kerala'],
        '686610': ['Kottayam', 'Kerala'],
        '686611': ['Kottayam', 'Kerala'],
        '686612': ['Kottayam', 'Kerala'],
        '686613': ['Kottayam', 'Kerala'],
        '686615': ['Kottayam', 'Kerala'],
        '686616': ['Kottayam', 'Kerala'],
        '686617': ['Kottayam', 'Kerala'],
        '686630': ['Kottayam', 'Kerala'],
        '686631': ['Kottayam', 'Kerala'],
        '686632': ['Kottayam', 'Kerala'],
        '686633': ['Kottayam', 'Kerala'],
        '686634': ['Kottayam', 'Kerala'],
        '686635': ['Kottayam', 'Kerala'],
        '686636': ['Kottayam', 'Kerala'],
        '686637': ['Kottayam', 'Kerala'],
        '686638': ['Kottayam', 'Kerala'],
        '686640': ['Kottayam', 'Kerala'],
        '686641': ['Kottayam', 'Kerala'],
        '686642': ['Kottayam', 'Kerala'],
        '686651': ['Kottayam', 'Kerala'],
        '686652': ['Kottayam', 'Kerala'],
        '686653': ['Kottayam', 'Kerala'],
        '686654': ['Kottayam', 'Kerala'],
        '686655': ['Kottayam', 'Kerala'],
        '689695': ['Kollam', 'Kerala'],
        '689696': ['Kollam', 'Kerala'],
        '690518': ['Kollam', 'Kerala'],
        '690519': ['Kollam', 'Kerala'],
        '690520': ['Kollam', 'Kerala'],
        '690521': ['Kollam', 'Kerala'],
        '690522': ['Kollam', 'Kerala'],
        '690523': ['Kollam', 'Kerala'],
        '690524': ['Kollam', 'Kerala'],
        '690525': ['Kollam', 'Kerala'],
        '690526': ['Kollam', 'Kerala'],
        '690528': ['Kollam', 'Kerala'],
        '690536': ['Kollam', 'Kerala'],
        '690538': ['Kollam', 'Kerala'],
        '690539': ['Kollam', 'Kerala'],
        '690540': ['Kollam', 'Kerala'],
        '690542': ['Kollam', 'Kerala'],
        '690544': ['Kollam', 'Kerala'],
        '690547': ['Kollam', 'Kerala'],
        '690561': ['Kollam', 'Kerala'],
        '690573': ['Kollam', 'Kerala'],
        '690574': ['Kollam', 'Kerala'],
        '691001': ['Kollam', 'Kerala'],
        '691002': ['Kollam', 'Kerala'],
        '691003': ['Kollam', 'Kerala'],
        '691004': ['Kollam', 'Kerala'],
        '691005': ['Kollam', 'Kerala'],
        '691006': ['Kollam', 'Kerala'],
        '691007': ['Kollam', 'Kerala'],
        '691008': ['Kollam', 'Kerala'],
        '691009': ['Kollam', 'Kerala'],
        '691010': ['Kollam', 'Kerala'],
        '691011': ['Kollam', 'Kerala'],
        '691012': ['Kollam', 'Kerala'],
        '691013': ['Kollam', 'Kerala'],
        '691014': ['Kollam', 'Kerala'],
        '691015': ['Kollam', 'Kerala'],
        '691016': ['Kollam', 'Kerala'],
        '691019': ['Kollam', 'Kerala'],
        '691020': ['Kollam', 'Kerala'],
        '691021': ['Kollam', 'Kerala'],
        '691301': ['Kollam', 'Kerala'],
        '691302': ['Kollam', 'Kerala'],
        '691303': ['Kollam', 'Kerala'],
        '691304': ['Kollam', 'Kerala'],
        '691305': ['Kollam', 'Kerala'],
        '691306': ['Kollam', 'Kerala'],
        '691307': ['Kollam', 'Kerala'],
        '691308': ['Kollam', 'Kerala'],
        '691309': ['Kollam', 'Kerala'],
        '691310': ['Kollam', 'Kerala'],
        '691311': ['Kollam', 'Kerala'],
        '691312': ['Kollam', 'Kerala'],
        '691319': ['Kollam', 'Kerala'],
        '691322': ['Kollam', 'Kerala'],
        '691331': ['Kollam', 'Kerala'],
        '691332': ['Kollam', 'Kerala'],
        '691333': ['Kollam', 'Kerala'],
        '691334': ['Kollam', 'Kerala'],
        '691500': ['Kollam', 'Kerala'],
        '691501': ['Kollam', 'Kerala'],
        '691502': ['Kollam', 'Kerala'],
        '691503': ['Kollam', 'Kerala'],
        '691504': ['Kollam', 'Kerala'],
        '691505': ['Kollam', 'Kerala'],
        '691506': ['Kollam', 'Kerala'],
        '691507': ['Kollam', 'Kerala'],
        '691508': ['Kollam', 'Kerala'],
        '691509': ['Kollam', 'Kerala'],
        '691510': ['Kollam', 'Kerala'],
        '691511': ['Kollam', 'Kerala'],
        '691512': ['Kollam', 'Kerala'],
        '691515': ['Kollam', 'Kerala'],
        '691516': ['Kollam', 'Kerala'],
        '691520': ['Kollam', 'Kerala'],
        '691521': ['Kollam', 'Kerala'],
        '691522': ['Kollam', 'Kerala'],
        '691526': ['Kollam', 'Kerala'],
        '691531': ['Kollam', 'Kerala'],
        '691532': ['Kollam', 'Kerala'],
        '691533': ['Kollam', 'Kerala'],
        '691534': ['Kollam', 'Kerala'],
        '691535': ['Kollam', 'Kerala'],
        '691536': ['Kollam', 'Kerala'],
        '691537': ['Kollam', 'Kerala'],
        '691538': ['Kollam', 'Kerala'],
        '691540': ['Kollam', 'Kerala'],
        '691541': ['Kollam', 'Kerala'],
        '691543': ['Kollam', 'Kerala'],
        '691552': ['Kollam', 'Kerala'],
        '691553': ['Kollam', 'Kerala'],
        '691557': ['Kollam', 'Kerala'],
        '691559': ['Kollam', 'Kerala'],
        '691560': ['Kollam', 'Kerala'],
        '691566': ['Kollam', 'Kerala'],
        '691571': ['Kollam', 'Kerala'],
        '691572': ['Kollam', 'Kerala'],
        '691573': ['Kollam', 'Kerala'],
        '691574': ['Kollam', 'Kerala'],
        '691576': ['Kollam', 'Kerala'],
        '691577': ['Kollam', 'Kerala'],
        '691578': ['Kollam', 'Kerala'],
        '691579': ['Kollam', 'Kerala'],
        '691581': ['Kollam', 'Kerala'],
        '691582': ['Kollam', 'Kerala'],
        '691583': ['Kollam', 'Kerala'],
        '691584': ['Kollam', 'Kerala'],
        '691585': ['Kollam', 'Kerala'],
        '691589': ['Kollam', 'Kerala'],
        '691590': ['Kollam', 'Kerala'],
        '691601': ['Kollam', 'Kerala'],
        '691602': ['Kollam', 'Kerala'],
        '695001': ['Trivandrum', 'Kerala'],
        '695002': ['Trivandrum', 'Kerala'],
        '695003': ['Trivandrum', 'Kerala'],
        '695004': ['Trivandrum', 'Kerala'],
        '695005': ['Trivandrum', 'Kerala'],
        '695006': ['Trivandrum', 'Kerala'],
        '695007': ['Trivandrum', 'Kerala'],
        '695008': ['Trivandrum', 'Kerala'],
        '695009': ['Trivandrum', 'Kerala'],
        '695010': ['Trivandrum', 'Kerala'],
        '695011': ['Trivandrum', 'Kerala'],
        '695012': ['Trivandrum', 'Kerala'],
        '695013': ['Trivandrum', 'Kerala'],
        '695014': ['Trivandrum', 'Kerala'],
        '695015': ['Trivandrum', 'Kerala'],
        '695016': ['Trivandrum', 'Kerala'],
        '695017': ['Trivandrum', 'Kerala'],
        '695018': ['Trivandrum', 'Kerala'],
        '695019': ['Trivandrum', 'Kerala'],
        '695020': ['Trivandrum', 'Kerala'],
        '695021': ['Trivandrum', 'Kerala'],
        '695022': ['Trivandrum', 'Kerala'],
        '695023': ['Trivandrum', 'Kerala'],
        '695024': ['Trivandrum', 'Kerala'],
        '695025': ['Trivandrum', 'Kerala'],
        '695026': ['Trivandrum', 'Kerala'],
        '695027': ['Trivandrum', 'Kerala'],
        '695028': ['Trivandrum', 'Kerala'],
        '695029': ['Trivandrum', 'Kerala'],
        '695030': ['Trivandrum', 'Kerala'],
        '695031': ['Trivandrum', 'Kerala'],
        '695032': ['Trivandrum', 'Kerala'],
        '695033': ['Trivandrum', 'Kerala'],
        '695034': ['Trivandrum', 'Kerala'],
        '695035': ['Trivandrum', 'Kerala'],
        '695036': ['Trivandrum', 'Kerala'],
        '695037': ['Trivandrum', 'Kerala'],
        '695038': ['Trivandrum', 'Kerala'],
        '695039': ['Trivandrum', 'Kerala'],
        '695040': ['Trivandrum', 'Kerala'],
        '695041': ['Trivandrum', 'Kerala'],
        '695042': ['Trivandrum', 'Kerala'],
        '695043': ['Trivandrum', 'Kerala'],
        '695099': ['Trivandrum', 'Kerala'],
        '695101': ['Trivandrum', 'Kerala'],
        '695102': ['Trivandrum', 'Kerala'],
        '695103': ['Trivandrum', 'Kerala'],
        '695104': ['Trivandrum', 'Kerala'],
        '695121': ['Trivandrum', 'Kerala'],
        '695122': ['Trivandrum', 'Kerala'],
        '695123': ['Trivandrum', 'Kerala'],
        '695124': ['Trivandrum', 'Kerala'],
        '695125': ['Trivandrum', 'Kerala'],
        '695126': ['Trivandrum', 'Kerala'],
        '695127': ['Trivandrum', 'Kerala'],
        '695131': ['Trivandrum', 'Kerala'],
        '695132': ['Trivandrum', 'Kerala'],
        '695133': ['Trivandrum', 'Kerala'],
        '695134': ['Trivandrum', 'Kerala'],
        '695141': ['Trivandrum', 'Kerala'],
        '695142': ['Trivandrum', 'Kerala'],
        '695143': ['Trivandrum', 'Kerala'],
        '695144': ['Trivandrum', 'Kerala'],
        '695145': ['Trivandrum', 'Kerala'],
        '695146': ['Trivandrum', 'Kerala'],
        '695301': ['Trivandrum', 'Kerala'],
        '695302': ['Trivandrum', 'Kerala'],
        '695303': ['Trivandrum', 'Kerala'],
        '695304': ['Trivandrum', 'Kerala'],
        '695305': ['Trivandrum', 'Kerala'],
        '695306': ['Trivandrum', 'Kerala'],
        '695307': ['Trivandrum', 'Kerala'],
        '695308': ['Trivandrum', 'Kerala'],
        '695309': ['Trivandrum', 'Kerala'],
        '695310': ['Trivandrum', 'Kerala'],
        '695311': ['Trivandrum', 'Kerala'],
        '695312': ['Trivandrum', 'Kerala'],
        '695313': ['Trivandrum', 'Kerala'],
        '695314': ['Trivandrum', 'Kerala'],
        '695316': ['Trivandrum', 'Kerala'],
        '695317': ['Trivandrum', 'Kerala'],
        '695318': ['Trivandrum', 'Kerala'],
        '695501': ['Trivandrum', 'Kerala'],
        '695502': ['Trivandrum', 'Kerala'],
        '695503': ['Trivandrum', 'Kerala'],
        '695504': ['Trivandrum', 'Kerala'],
        '695505': ['Trivandrum', 'Kerala'],
        '695506': ['Trivandrum', 'Kerala'],
        '695507': ['Trivandrum', 'Kerala'],
        '695508': ['Trivandrum', 'Kerala'],
        '695510': ['Trivandrum', 'Kerala'],
        '695512': ['Trivandrum', 'Kerala'],
        '695513': ['Trivandrum', 'Kerala'],
        '695521': ['Trivandrum', 'Kerala'],
        '695522': ['Trivandrum', 'Kerala'],
        '695523': ['Trivandrum', 'Kerala'],
        '695524': ['Trivandrum', 'Kerala'],
        '695525': ['Trivandrum', 'Kerala'],
        '695526': ['Trivandrum', 'Kerala'],
        '695527': ['Trivandrum', 'Kerala'],
        '695528': ['Trivandrum', 'Kerala'],
        '695541': ['Trivandrum', 'Kerala'],
        '695542': ['Trivandrum', 'Kerala'],
        '695543': ['Trivandrum', 'Kerala'],
        '695544': ['Trivandrum', 'Kerala'],
        '695547': ['Trivandrum', 'Kerala'],
        '695551': ['Trivandrum', 'Kerala'],
        '695561': ['Trivandrum', 'Kerala'],
        '695562': ['Trivandrum', 'Kerala'],
        '695563': ['Trivandrum', 'Kerala'],
        '695564': ['Trivandrum', 'Kerala'],
        '695568': ['Trivandrum', 'Kerala'],
        '695570': ['Trivandrum', 'Kerala'],
        '695572': ['Trivandrum', 'Kerala'],
        '695573': ['Trivandrum', 'Kerala'],
        '695574': ['Trivandrum', 'Kerala'],
        '695575': ['Trivandrum', 'Kerala'],
        '695581': ['Trivandrum', 'Kerala'],
        '695582': ['Trivandrum', 'Kerala'],
        '695583': ['Trivandrum', 'Kerala'],
        '695584': ['Trivandrum', 'Kerala'],
        '695585': ['Trivandrum', 'Kerala'],
        '695586': ['Trivandrum', 'Kerala'],
        '695587': ['Trivandrum', 'Kerala'],
        '695588': ['Trivandrum', 'Kerala'],
        '695589': ['Trivandrum', 'Kerala'],
        '695601': ['Trivandrum', 'Kerala'],
        '695602': ['Trivandrum', 'Kerala'],
        '695603': ['Trivandrum', 'Kerala'],
        '695604': ['Trivandrum', 'Kerala'],
        '695605': ['Trivandrum', 'Kerala'],
        '695606': ['Trivandrum', 'Kerala'],
        '695607': ['Trivandrum', 'Kerala'],
        '695608': ['Trivandrum', 'Kerala'],
        '695609': ['Trivandrum', 'Kerala'],
        '695610': ['Trivandrum', 'Kerala'],
        '695611': ['Trivandrum', 'Kerala'],
        '695612': ['Trivandrum', 'Kerala'],
        '695614': ['Trivandrum', 'Kerala'],
        '695615': ['Trivandrum', 'Kerala'],
        '695616': ['Trivandrum', 'Kerala'],
        '695618': ['Trivandrum', 'Kerala'],
        '700001': ['Kolkata', 'West Bengal'],
        '700002': ['Kolkata', 'West Bengal'],
        '700003': ['Kolkata', 'West Bengal'],
        '700004': ['Kolkata', 'West Bengal'],
        '700005': ['Kolkata', 'West Bengal'],
        '700006': ['Kolkata', 'West Bengal'],
        '700007': ['Kolkata', 'West Bengal'],
        '700008': ['Kolkata', 'West Bengal'],
        '700009': ['Kolkata', 'West Bengal'],
        '700010': ['Kolkata', 'West Bengal'],
        '700011': ['Kolkata', 'West Bengal'],
        '700012': ['Kolkata', 'West Bengal'],
        '700013': ['Kolkata', 'West Bengal'],
        '700014': ['Kolkata', 'West Bengal'],
        '700015': ['Kolkata', 'West Bengal'],
        '700016': ['Kolkata', 'West Bengal'],
        '700017': ['Kolkata', 'West Bengal'],
        '700018': ['Kolkata', 'West Bengal'],
        '700019': ['Kolkata', 'West Bengal'],
        '700020': ['Kolkata', 'West Bengal'],
        '700021': ['Kolkata', 'West Bengal'],
        '700022': ['Kolkata', 'West Bengal'],
        '700023': ['Kolkata', 'West Bengal'],
        '700024': ['Kolkata', 'West Bengal'],
        '700025': ['Kolkata', 'West Bengal'],
        '700026': ['Kolkata', 'West Bengal'],
        '700027': ['Kolkata', 'West Bengal'],
        '700028': ['Kolkata', 'West Bengal'],
        '700029': ['Kolkata', 'West Bengal'],
        '700030': ['Kolkata', 'West Bengal'],
        '700031': ['Kolkata', 'West Bengal'],
        '700032': ['Kolkata', 'West Bengal'],
        '700033': ['Kolkata', 'West Bengal'],
        '700034': ['Kolkata', 'West Bengal'],
        '700035': ['Kolkata', 'West Bengal'],
        '700036': ['Kolkata', 'West Bengal'],
        '700037': ['Kolkata', 'West Bengal'],
        '700038': ['Kolkata', 'West Bengal'],
        '700040': ['Kolkata', 'West Bengal'],
        '700041': ['Kolkata', 'West Bengal'],
        '700042': ['Kolkata', 'West Bengal'],
        '700043': ['Kolkata', 'West Bengal'],
        '700044': ['Kolkata', 'West Bengal'],
        '700045': ['Kolkata', 'West Bengal'],
        '700046': ['Kolkata', 'West Bengal'],
        '700047': ['Kolkata', 'West Bengal'],
        '700050': ['Kolkata', 'West Bengal'],
        '700052': ['Kolkata', 'West Bengal'],
        '700053': ['Kolkata', 'West Bengal'],
        '700054': ['Kolkata', 'West Bengal'],
        '700060': ['Kolkata', 'West Bengal'],
        '700061': ['Kolkata', 'West Bengal'],
        '700062': ['Kolkata', 'West Bengal'],
        '700063': ['Kolkata', 'West Bengal'],
        '700065': ['Kolkata', 'West Bengal'],
        '700066': ['Kolkata', 'West Bengal'],
        '700067': ['Kolkata', 'West Bengal'],
        '700068': ['Kolkata', 'West Bengal'],
        '700069': ['Kolkata', 'West Bengal'],
        '700071': ['Kolkata', 'West Bengal'],
        '700072': ['Kolkata', 'West Bengal'],
        '700073': ['Kolkata', 'West Bengal'],
        '700074': ['Kolkata', 'West Bengal'],
        '700075': ['Kolkata', 'West Bengal'],
        '700077': ['Kolkata', 'West Bengal'],
        '700078': ['Kolkata', 'West Bengal'],
        '700080': ['Kolkata', 'West Bengal'],
        '700082': ['Kolkata', 'West Bengal'],
        '700085': ['Kolkata', 'West Bengal'],
        '700086': ['Kolkata', 'West Bengal'],
        '700087': ['Kolkata', 'West Bengal'],
        '700088': ['Kolkata', 'West Bengal'],
        '700089': ['Kolkata', 'West Bengal'],
        '700090': ['Kolkata', 'West Bengal'],
        '700092': ['Kolkata', 'West Bengal'],
        '700094': ['Kolkata', 'West Bengal'],
        '700095': ['Kolkata', 'West Bengal'],
        '700099': ['Kolkata', 'West Bengal'],
        '700107': ['Kolkata', 'West Bengal'],
        '700108': ['Kolkata', 'West Bengal'],
        '711101': ['Howrah', 'West Bengal'],
        '711102': ['Howrah', 'West Bengal'],
        '711103': ['Howrah', 'West Bengal'],
        '711104': ['Howrah', 'West Bengal'],
        '711105': ['Howrah', 'West Bengal'],
        '711106': ['Howrah', 'West Bengal'],
        '711107': ['Howrah', 'West Bengal'],
        '711108': ['Howrah', 'West Bengal'],
        '711109': ['Howrah', 'West Bengal'],
        '711110': ['Howrah', 'West Bengal'],
        '711111': ['Howrah', 'West Bengal'],
        '711112': ['Howrah', 'West Bengal'],
        '711113': ['Howrah', 'West Bengal'],
        '711114': ['Howrah', 'West Bengal'],
        '711201': ['Howrah', 'West Bengal'],
        '711202': ['Howrah', 'West Bengal'],
        '711203': ['Howrah', 'West Bengal'],
        '711204': ['Howrah', 'West Bengal'],
        '711205': ['Howrah', 'West Bengal'],
        '711224': ['Howrah', 'West Bengal'],
        '711225': ['Howrah', 'West Bengal'],
        '711226': ['Howrah', 'West Bengal'],
        '711227': ['Howrah', 'West Bengal'],
        '711301': ['Howrah', 'West Bengal'],
        '711302': ['Howrah', 'West Bengal'],
        '711303': ['Howrah', 'West Bengal'],
        '711304': ['Howrah', 'West Bengal'],
        '711305': ['Howrah', 'West Bengal'],
        '711306': ['Howrah', 'West Bengal'],
        '711307': ['Howrah', 'West Bengal'],
        '711308': ['Howrah', 'West Bengal'],
        '711309': ['Howrah', 'West Bengal'],
        '711310': ['Howrah', 'West Bengal'],
        '711311': ['Howrah', 'West Bengal'],
        '711312': ['Howrah', 'West Bengal'],
        '711313': ['Howrah', 'West Bengal'],
        '711314': ['Howrah', 'West Bengal'],
        '711315': ['Howrah', 'West Bengal'],
        '711316': ['Howrah', 'West Bengal'],
        '711317': ['Howrah', 'West Bengal'],
        '711321': ['Howrah', 'West Bengal'],
        '711322': ['Howrah', 'West Bengal'],
        '711323': ['Howrah', 'West Bengal'],
        '711324': ['Howrah', 'West Bengal'],
        '711325': ['Howrah', 'West Bengal'],
        '711326': ['Howrah', 'West Bengal'],
        '711327': ['Howrah', 'West Bengal'],
        '711401': ['Howrah', 'West Bengal'],
        '711402': ['Howrah', 'West Bengal'],
        '711403': ['Howrah', 'West Bengal'],
        '711404': ['Howrah', 'West Bengal'],
        '711405': ['Howrah', 'West Bengal'],
        '711406': ['Howrah', 'West Bengal'],
        '711408': ['Howrah', 'West Bengal'],
        '711409': ['Howrah', 'West Bengal'],
        '711410': ['Howrah', 'West Bengal'],
        '711411': ['Howrah', 'West Bengal'],
        '711412': ['Howrah', 'West Bengal'],
        '711413': ['Howrah', 'West Bengal'],
        '711414': ['Howrah', 'West Bengal'],
        '711415': ['Howrah', 'West Bengal'],
        '711416': ['Howrah', 'West Bengal'],
        '712101': ['Chinsurah', 'West Bengal'],
        '712102': ['Chinsurah', 'West Bengal'],
        '712136': ['BARASAT', 'West Bengal'],
        '712221': ['Durgapur', 'West Bengal'],
        '712408': ['Howrah', 'West Bengal'],
        '713201': ['Durgapur', 'West Bengal'],
        '713202': ['Durgapur', 'West Bengal'],
        '713203': ['Durgapur', 'West Bengal'],
        '713204': ['Durgapur', 'West Bengal'],
        '713205': ['Durgapur', 'West Bengal'],
        '713206': ['Durgapur', 'West Bengal'],
        '713207': ['Durgapur', 'West Bengal'],
        '713209': ['Durgapur', 'West Bengal'],
        '713210': ['Durgapur', 'West Bengal'],
        '713214': ['Durgapur', 'West Bengal'],
        '713215': ['Durgapur', 'West Bengal'],
        '734001': ['Darjeeling', 'West Bengal'],
        '734002': ['Darjeeling', 'West Bengal'],
        '734003': ['Darjeeling', 'West Bengal'],
        '734005': ['Darjeeling', 'West Bengal'],
        '734008': ['Darjeeling', 'West Bengal'],
        '734009': ['Darjeeling', 'West Bengal'],
        '734010': ['Darjeeling', 'West Bengal'],
        '734011': ['Darjeeling', 'West Bengal'],
        '734012': ['Darjeeling', 'West Bengal'],
        '734013': ['Darjeeling', 'West Bengal'],
        '734014': ['Darjeeling', 'West Bengal'],
        '734101': ['Darjeeling', 'West Bengal'],
        '734102': ['Darjeeling', 'West Bengal'],
        '734103': ['Darjeeling', 'West Bengal'],
        '734104': ['Darjeeling', 'West Bengal'],
        '734105': ['Darjeeling', 'West Bengal'],
        '734121': ['Darjeeling', 'West Bengal'],
        '734122': ['Darjeeling', 'West Bengal'],
        '734123': ['Darjeeling', 'West Bengal'],
        '734124': ['Darjeeling', 'West Bengal'],
        '734201': ['Darjeeling', 'West Bengal'],
        '734203': ['Darjeeling', 'West Bengal'],
        '734204': ['Darjeeling', 'West Bengal'],
        '734209': ['Darjeeling', 'West Bengal'],
        '734213': ['Darjeeling', 'West Bengal'],
        '734214': ['Darjeeling', 'West Bengal'],
        '734215': ['Darjeeling', 'West Bengal'],
        '734216': ['Darjeeling', 'West Bengal'],
        '734217': ['Darjeeling', 'West Bengal'],
        '734218': ['Darjeeling', 'West Bengal'],
        '734219': ['Darjeeling', 'West Bengal'],
        '734220': ['Darjeeling', 'West Bengal'],
        '734221': ['Darjeeling', 'West Bengal'],
        '734222': ['Darjeeling', 'West Bengal'],
        '734223': ['Darjeeling', 'West Bengal'],
        '734224': ['Darjeeling', 'West Bengal'],
        '734225': ['Darjeeling', 'West Bengal'],
        '734226': ['Darjeeling', 'West Bengal'],
        '734227': ['Darjeeling', 'West Bengal'],
        '734301': ['Darjeeling', 'West Bengal'],
        '734311': ['Darjeeling', 'West Bengal'],
        '734312': ['Darjeeling', 'West Bengal'],
        '734313': ['Darjeeling', 'West Bengal'],
        '734314': ['Darjeeling', 'West Bengal'],
        '734315': ['Darjeeling', 'West Bengal'],
        '734316': ['Darjeeling', 'West Bengal'],
        '734317': ['Darjeeling', 'West Bengal'],
        '734319': ['Darjeeling', 'West Bengal'],
        '734320': ['Darjeeling', 'West Bengal'],
        '734321': ['Darjeeling', 'West Bengal'],
        '734401': ['Darjeeling', 'West Bengal'],
        '734403': ['Darjeeling', 'West Bengal'],
        '734404': ['Darjeeling', 'West Bengal'],
        '734405': ['Siluguri', 'West Bengal'],
        '734406': ['Darjeeling', 'West Bengal'],
        '734421': ['Darjeeling', 'West Bengal'],
        '734422': ['Darjeeling', 'West Bengal'],
        '734423': ['Darjeeling', 'West Bengal'],
        '734424': ['Darjeeling', 'West Bengal'],
        '734425': ['Darjeeling', 'West Bengal'],
        '734426': ['Darjeeling', 'West Bengal'],
        '734427': ['Darjeeling', 'West Bengal'],
        '734428': ['Darjeeling', 'West Bengal'],
        '734429': ['Darjeeling', 'West Bengal'],
        '734430': ['Darjeeling', 'West Bengal'],
        '734431': ['Darjeeling', 'West Bengal'],
        '734432': ['Darjeeling', 'West Bengal'],
        '734433': ['Darjeeling', 'West Bengal'],
        '734434': ['Darjeeling', 'West Bengal'],
        '734435': ['Darjeeling', 'West Bengal'],
        '734436': ['Darjeeling', 'West Bengal'],
        '734501': ['Darjeeling', 'West Bengal'],
        '734502': ['Darjeeling', 'West Bengal'],
        '734503': ['Darjeeling', 'West Bengal'],
        '735231': ['Darjeeling', 'West Bengal'],
        '742102': ['Kolkata', 'West Bengal'],
        '743201': ['BARASAT', 'West Bengal'],
        '743262': ['BARASAT', 'West Bengal'],
        '743372': ['BARASAT', 'West Bengal'],
        '751001': ['Bhubaneswar', 'Orissa'],
        '751002': ['Bhubaneswar', 'Orissa'],
        '751009': ['Bhubaneswar', 'Orissa'],
        '753001': ['Cuttack', 'Orissa'],
        '753002': ['Cuttack', 'Orissa'],
        '753003': ['Cuttack', 'Orissa'],
        '753004': ['Cuttack', 'Orissa'],
        '753005': ['Cuttack', 'Orissa'],
        '753006': ['Cuttack', 'Orissa'],
        '753007': ['Cuttack', 'Orissa'],
        '753008': ['Cuttack', 'Orissa'],
        '753009': ['Cuttack', 'Orissa'],
        '753010': ['Cuttack', 'Orissa'],
        '753011': ['Cuttack', 'Orissa'],
        '753012': ['Cuttack', 'Orissa'],
        '753013': ['Cuttack', 'Orissa'],
        '753014': ['Cuttack', 'Orissa'],
        '753015': ['Cuttack', 'Orissa'],
        '753051': ['Cuttack', 'Orissa'],
        '753052': ['Cuttack', 'Orissa'],
        '754001': ['Cuttack', 'Orissa'],
        '754002': ['Cuttack', 'Orissa'],
        '754003': ['Cuttack', 'Orissa'],
        '754004': ['Cuttack', 'Orissa'],
        '754005': ['Cuttack', 'Orissa'],
        '754006': ['Cuttack', 'Orissa'],
        '754007': ['Cuttack', 'Orissa'],
        '754008': ['Cuttack', 'Orissa'],
        '754009': ['Cuttack', 'Orissa'],
        '754010': ['Cuttack', 'Orissa'],
        '754011': ['Cuttack', 'Orissa'],
        '754012': ['Cuttack', 'Orissa'],
        '754013': ['Cuttack', 'Orissa'],
        '754014': ['Cuttack', 'Orissa'],
        '754015': ['Cuttack', 'Orissa'],
        '754016': ['Cuttack', 'Orissa'],
        '754017': ['Cuttack', 'Orissa'],
        '754018': ['Cuttack', 'Orissa'],
        '754021': ['Cuttack', 'Orissa'],
        '754022': ['Cuttack', 'Orissa'],
        '754023': ['Cuttack', 'Orissa'],
        '754024': ['Cuttack', 'Orissa'],
        '754025': ['Cuttack', 'Orissa'],
        '754026': ['Cuttack', 'Orissa'],
        '754027': ['Cuttack', 'Orissa'],
        '754028': ['Cuttack', 'Orissa'],
        '754029': ['Cuttack', 'Orissa'],
        '754030': ['Cuttack', 'Orissa'],
        '754031': ['Cuttack', 'Orissa'],
        '754032': ['Cuttack', 'Orissa'],
        '754033': ['Cuttack', 'Orissa'],
        '754034': ['Cuttack', 'Orissa'],
        '754035': ['Cuttack', 'Orissa'],
        '754036': ['Cuttack', 'Orissa'],
        '754037': ['Cuttack', 'Orissa'],
        '754070': ['Cuttack', 'Orissa'],
        '754071': ['Cuttack', 'Orissa'],
        '754080': ['Cuttack', 'Orissa'],
        '754081': ['Cuttack', 'Orissa'],
        '754082': ['Cuttack', 'Orissa'],
        '754100': ['Cuttack', 'Orissa'],
        '754101': ['Cuttack', 'Orissa'],
        '754102': ['Cuttack', 'Orissa'],
        '754103': ['Cuttack', 'Orissa'],
        '754104': ['Cuttack', 'Orissa'],
        '754105': ['Cuttack', 'Orissa'],
        '754106': ['Cuttack', 'Orissa'],
        '754107': ['Cuttack', 'Orissa'],
        '754108': ['Cuttack', 'Orissa'],
        '754109': ['Cuttack', 'Orissa'],
        '754110': ['Cuttack', 'Orissa'],
        '754111': ['Cuttack', 'Orissa'],
        '754112': ['Cuttack', 'Orissa'],
        '754113': ['Cuttack', 'Orissa'],
        '754114': ['Cuttack', 'Orissa'],
        '754115': ['Cuttack', 'Orissa'],
        '754116': ['Cuttack', 'Orissa'],
        '754117': ['Cuttack', 'Orissa'],
        '754118': ['Cuttack', 'Orissa'],
        '754119': ['Cuttack', 'Orissa'],
        '754131': ['Cuttack', 'Orissa'],
        '754132': ['Cuttack', 'Orissa'],
        '754133': ['Cuttack', 'Orissa'],
        '754134': ['Cuttack', 'Orissa'],
        '754135': ['Cuttack', 'Orissa'],
        '754136': ['Cuttack', 'Orissa'],
        '754137': ['Cuttack', 'Orissa'],
        '754139': ['Cuttack', 'Orissa'],
        '754140': ['Cuttack', 'Orissa'],
        '754141': ['Cuttack', 'Orissa'],
        '754142': ['Cuttack', 'Orissa'],
        '754143': ['Cuttack', 'Orissa'],
        '754144': ['Cuttack', 'Orissa'],
        '754153': ['Cuttack', 'Orissa'],
        '754154': ['Cuttack', 'Orissa'],
        '754155': ['Cuttack', 'Orissa'],
        '754156': ['Cuttack', 'Orissa'],
        '754157': ['Cuttack', 'Orissa'],
        '754158': ['Cuttack', 'Orissa'],
        '754159': ['Cuttack', 'Orissa'],
        '754160': ['Cuttack', 'Orissa'],
        '754161': ['Cuttack', 'Orissa'],
        '754162': ['Cuttack', 'Orissa'],
        '754200': ['Cuttack', 'Orissa'],
        '754201': ['Cuttack', 'Orissa'],
        '754202': ['Cuttack', 'Orissa'],
        '754203': ['Cuttack', 'Orissa'],
        '754204': ['Cuttack', 'Orissa'],
        '754205': ['Cuttack', 'Orissa'],
        '754206': ['Cuttack', 'Orissa'],
        '754207': ['Cuttack', 'Orissa'],
        '754208': ['Cuttack', 'Orissa'],
        '754209': ['Cuttack', 'Orissa'],
        '754210': ['Cuttack', 'Orissa'],
        '754211': ['Cuttack', 'Orissa'],
        '754212': ['Cuttack', 'Orissa'],
        '754213': ['Cuttack', 'Orissa'],
        '754214': ['Cuttack', 'Orissa'],
        '754215': ['Cuttack', 'Orissa'],
        '754216': ['Cuttack', 'Orissa'],
        '754217': ['Cuttack', 'Orissa'],
        '754218': ['Cuttack', 'Orissa'],
        '754219': ['Cuttack', 'Orissa'],
        '754220': ['Cuttack', 'Orissa'],
        '754221': ['Cuttack', 'Orissa'],
        '754222': ['Cuttack', 'Orissa'],
        '754223': ['Cuttack', 'Orissa'],
        '754224': ['Cuttack', 'Orissa'],
        '754225': ['Cuttack', 'Orissa'],
        '754226': ['Cuttack', 'Orissa'],
        '754227': ['Cuttack', 'Orissa'],
        '754228': ['Cuttack', 'Orissa'],
        '754229': ['Cuttack', 'Orissa'],
        '754230': ['Cuttack', 'Orissa'],
        '754231': ['Cuttack', 'Orissa'],
        '754232': ['Cuttack', 'Orissa'],
        '754234': ['Cuttack', 'Orissa'],
        '754235': ['Cuttack', 'Orissa'],
        '754236': ['Cuttack', 'Orissa'],
        '754237': ['Cuttack', 'Orissa'],
        '754238': ['Cuttack', 'Orissa'],
        '754239': ['Cuttack', 'Orissa'],
        '754240': ['Cuttack', 'Orissa'],
        '754241': ['Cuttack', 'Orissa'],
        '754242': ['Cuttack', 'Orissa'],
        '754243': ['Cuttack', 'Orissa'],
        '754244': ['Cuttack', 'Orissa'],
        '754246': ['Cuttack', 'Orissa'],
        '754247': ['Cuttack', 'Orissa'],
        '754248': ['Cuttack', 'Orissa'],
        '754249': ['Cuttack', 'Orissa'],
        '754250': ['Cuttack', 'Orissa'],
        '754251': ['Cuttack', 'Orissa'],
        '754252': ['Cuttack', 'Orissa'],
        '754253': ['Cuttack', 'Orissa'],
        '754260': ['Cuttack', 'Orissa'],
        '754280': ['Cuttack', 'Orissa'],
        '754281': ['Cuttack', 'Orissa'],
        '754282': ['Cuttack', 'Orissa'],
        '754284': ['Cuttack', 'Orissa'],
        '754285': ['Cuttack', 'Orissa'],
        '754286': ['Cuttack', 'Orissa'],
        '754287': ['Cuttack', 'Orissa'],
        '754288': ['Cuttack', 'Orissa'],
        '754289': ['Cuttack', 'Orissa'],
        '754290': ['Cuttack', 'Orissa'],
        '754291': ['Cuttack', 'Orissa'],
        '754292': ['Cuttack', 'Orissa'],
        '754293': ['Cuttack', 'Orissa'],
        '754295': ['Cuttack', 'Orissa'],
        '755001': ['Cuttack', 'Orissa'],
        '755003': ['Cuttack', 'Orissa'],
        '755004': ['Cuttack', 'Orissa'],
        '755005': ['Cuttack', 'Orissa'],
        '755006': ['Cuttack', 'Orissa'],
        '755007': ['Cuttack', 'Orissa'],
        '755008': ['Cuttack', 'Orissa'],
        '755009': ['Cuttack', 'Orissa'],
        '755010': ['Cuttack', 'Orissa'],
        '755011': ['Cuttack', 'Orissa'],
        '755012': ['Cuttack', 'Orissa'],
        '755013': ['Cuttack', 'Orissa'],
        '755014': ['Cuttack', 'Orissa'],
        '755015': ['Cuttack', 'Orissa'],
        '755016': ['Cuttack', 'Orissa'],
        '755017': ['Cuttack', 'Orissa'],
        '755018': ['Cuttack', 'Orissa'],
        '755019': ['Cuttack', 'Orissa'],
        '755021': ['Cuttack', 'Orissa'],
        '755022': ['Cuttack', 'Orissa'],
        '755023': ['Cuttack', 'Orissa'],
        '755024': ['Cuttack', 'Orissa'],
        '755025': ['Cuttack', 'Orissa'],
        '755026': ['Cuttack', 'Orissa'],
        '755027': ['Cuttack', 'Orissa'],
        '755028': ['Cuttack', 'Orissa'],
        '755029': ['Cuttack', 'Orissa'],
        '755030': ['Cuttack', 'Orissa'],
        '755031': ['Cuttack', 'Orissa'],
        '755032': ['Cuttack', 'Orissa'],
        '755033': ['Cuttack', 'Orissa'],
        '755034': ['Cuttack', 'Orissa'],
        '755035': ['Cuttack', 'Orissa'],
        '755036': ['Cuttack', 'Orissa'],
        '755037': ['Cuttack', 'Orissa'],
        '755038': ['Cuttack', 'Orissa'],
        '755039': ['Cuttack', 'Orissa'],
        '755041': ['Cuttack', 'Orissa'],
        '755042': ['Cuttack', 'Orissa'],
        '755043': ['Cuttack', 'Orissa'],
        '755044': ['Cuttack', 'Orissa'],
        '755045': ['Cuttack', 'Orissa'],
        '755046': ['Cuttack', 'Orissa'],
        '755047': ['Cuttack', 'Orissa'],
        '755048': ['Cuttack', 'Orissa'],
        '755049': ['Cuttack', 'Orissa'],
        '755061': ['Cuttack', 'Orissa'],
        '764027': ['Cuttack', 'Orissa'],
        '769001': ['Rourkela', 'Orissa'],
        '769002': ['Rourkela', 'Orissa'],
        '769003': ['Rourkela', 'Orissa'],
        '769004': ['Rourkela', 'Orissa'],
        '769005': ['Rourkela', 'Orissa'],
        '769006': ['Rourkela', 'Orissa'],
        '769007': ['Rourkela', 'Orissa'],
        '769008': ['Rourkela', 'Orissa'],
        '769009': ['Rourkela', 'Orissa'],
        '769012': ['Rourkela', 'Orissa'],
        '769014': ['Rourkela', 'Orissa'],
        '769016': ['Rourkela', 'Orissa'],
        '781002': ['Guwahati', 'Assam'],
        '800001': ['Patna', 'Bihar'],
        '800002': ['Patna', 'Bihar'],
        '800003': ['Patna', 'Bihar'],
        '800004': ['Patna', 'Bihar'],
        '800005': ['Patna', 'Bihar'],
        '800006': ['Patna', 'Bihar'],
        '800007': ['Patna', 'Bihar'],
        '800008': ['Patna', 'Bihar'],
        '800009': ['Patna', 'Bihar'],
        '800010': ['Patna', 'Bihar'],
        '800011': ['Patna', 'Bihar'],
        '800012': ['Patna', 'Bihar'],
        '800013': ['Patna', 'Bihar'],
        '800014': ['Patna', 'Bihar'],
        '800015': ['Patna', 'Bihar'],
        '800016': ['Patna', 'Bihar'],
        '800017': ['Patna', 'Bihar'],
        '800018': ['Patna', 'Bihar'],
        '800019': ['Patna', 'Bihar'],
        '800020': ['Patna', 'Bihar'],
        '800021': ['Patna', 'Bihar'],
        '800022': ['Patna', 'Bihar'],
        '800023': ['Patna', 'Bihar'],
        '800024': ['Patna', 'Bihar'],
        '800025': ['Patna', 'Bihar'],
        '800026': ['Patna', 'Bihar'],
        '800027': ['Patna', 'Bihar'],
        '800028': ['Patna', 'Bihar'],
        '800029': ['Patna', 'Bihar'],
        '800030': ['Patna', 'Bihar'],
        '801101': ['Patna', 'Bihar'],
        '801102': ['Patna', 'Bihar'],
        '801103': ['Patna', 'Bihar'],
        '801104': ['Patna', 'Bihar'],
        '801105': ['Patna', 'Bihar'],
        '801106': ['Patna', 'Bihar'],
        '801108': ['Patna', 'Bihar'],
        '801109': ['Patna', 'Bihar'],
        '801110': ['Patna', 'Bihar'],
        '801111': ['Patna', 'Bihar'],
        '801112': ['Patna', 'Bihar'],
        '801113': ['Patna', 'Bihar'],
        '801114': ['Patna', 'Bihar'],
        '801115': ['Patna', 'Bihar'],
        '801117': ['Patna', 'Bihar'],
        '801118': ['Patna', 'Bihar'],
        '801304': ['Patna', 'Bihar'],
        '801501': ['Patna', 'Bihar'],
        '801502': ['Patna', 'Bihar'],
        '801503': ['Patna', 'Bihar'],
        '801505': ['Patna', 'Bihar'],
        '801506': ['Patna', 'Bihar'],
        '801507': ['Patna', 'Bihar'],
        '803201': ['Patna', 'Bihar'],
        '803202': ['Patna', 'Bihar'],
        '803203': ['Patna', 'Bihar'],
        '803204': ['Patna', 'Bihar'],
        '803205': ['Patna', 'Bihar'],
        '803211': ['Patna', 'Bihar'],
        '803212': ['Patna', 'Bihar'],
        '803213': ['Patna', 'Bihar'],
        '803214': ['Patna', 'Bihar'],
        '803221': ['Patna', 'Bihar'],
        '803232': ['Patna', 'Bihar'],
        '803301': ['Patna', 'Bihar'],
        '803302': ['Patna', 'Bihar'],
        '803303': ['Patna', 'Bihar'],
        '803306': ['Patna', 'Bihar'],
        '803307': ['Patna', 'Bihar'],
        '804451': ['Patna', 'Bihar'],
        '804452': ['Patna', 'Bihar'],
        '804453': ['Patna', 'Bihar'],
        '804454': ['Patna', 'Bihar'],
        '804457': ['Patna', 'Bihar'],
        '813202': ['Patna', 'Bihar'],
        '826001': ['Dhanbad', 'Jharkhand'],
        '826003': ['Dhanbad', 'Jharkhand'],
        '826004': ['Dhanbad', 'Jharkhand'],
        '826005': ['Dhanbad', 'Jharkhand'],
        '827302': ['Dhanbad', 'Jharkhand'],
        '828101': ['Dhanbad', 'Jharkhand'],
        '828102': ['Dhanbad', 'Jharkhand'],
        '828103': ['Dhanbad', 'Jharkhand'],
        '828104': ['Dhanbad', 'Jharkhand'],
        '828105': ['Dhanbad', 'Jharkhand'],
        '828106': ['Dhanbad', 'Jharkhand'],
        '828107': ['Dhanbad', 'Jharkhand'],
        '828108': ['Dhanbad', 'Jharkhand'],
        '828109': ['Dhanbad', 'Jharkhand'],
        '828110': ['Dhanbad', 'Jharkhand'],
        '828111': ['Dhanbad', 'Jharkhand'],
        '828112': ['Dhanbad', 'Jharkhand'],
        '828113': ['Dhanbad', 'Jharkhand'],
        '828114': ['Dhanbad', 'Jharkhand'],
        '828115': ['Dhanbad', 'Jharkhand'],
        '828116': ['Dhanbad', 'Jharkhand'],
        '828117': ['Dhanbad', 'Jharkhand'],
        '828119': ['Dhanbad', 'Jharkhand'],
        '828120': ['Dhanbad', 'Jharkhand'],
        '828121': ['Dhanbad', 'Jharkhand'],
        '828122': ['Dhanbad', 'Jharkhand'],
        '828123': ['Dhanbad', 'Jharkhand'],
        '828124': ['Dhanbad', 'Jharkhand'],
        '828125': ['Dhanbad', 'Jharkhand'],
        '828126': ['Dhanbad', 'Jharkhand'],
        '828127': ['Dhanbad', 'Jharkhand'],
        '828128': ['Dhanbad', 'Jharkhand'],
        '828129': ['Dhanbad', 'Jharkhand'],
        '828130': ['Dhanbad', 'Jharkhand'],
        '828131': ['Dhanbad', 'Jharkhand'],
        '828132': ['Dhanbad', 'Jharkhand'],
        '828133': ['Dhanbad', 'Jharkhand'],
        '828135': ['Dhanbad', 'Jharkhand'],
        '828142': ['Dhanbad', 'Jharkhand'],
        '828144': ['Dhanbad', 'Jharkhand'],
        '828201': ['Dhanbad', 'Jharkhand'],
        '828202': ['Dhanbad', 'Jharkhand'],
        '828203': ['Dhanbad', 'Jharkhand'],
        '828204': ['Dhanbad', 'Jharkhand'],
        '828205': ['Dhanbad', 'Jharkhand'],
        '828206': ['Dhanbad', 'Jharkhand'],
        '828207': ['Dhanbad', 'Jharkhand'],
        '828301': ['Dhanbad', 'Jharkhand'],
        '828304': ['Dhanbad', 'Jharkhand'],
        '828305': ['Dhanbad', 'Jharkhand'],
        '828306': ['Dhanbad', 'Jharkhand'],
        '828307': ['Dhanbad', 'Jharkhand'],
        '828308': ['Dhanbad', 'Jharkhand'],
        '828309': ['Dhanbad', 'Jharkhand'],
        '828311': ['Dhanbad', 'Jharkhand'],
        '828401': ['Dhanbad', 'Jharkhand'],
        '828402': ['Dhanbad', 'Jharkhand'],
        '831001': ['Jamshedpur', 'Bihar'],
        '854105': ['Drugapur', 'Bihar'],
    }
}
