import {createStyles} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    page:{
        backgroundColor: '##f7f8fa'
    },
    main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: theme.spacing(5,0),
        maxWidth: '1000px',
        margin:'auto',
    },
    card: {
        borderRadius: '8px',
        boxShadow: '0 1px 10px 2px #e7e7e8',
        width: '100%',
    },
    cardBody:{
        padding: theme.spacing(4, 5),
        width :'100%'
    },
    error:{
        fontSize: '24px',
        fontWeight: "bold",
        textAlign: "center"
    },
    spinner:{
        textAlign: "center",
        margin: theme.spacing(5,0),
        color: theme.palette.primary.main
    },

});