import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    loadingQuotation: false,
    loadingUserDetails: false,
    loadingPolicyTransactions: false,
    quotation: null,
    userDetails: null,
    calculatePremiumData: null,
    buyFormData: null,
    buyFormError: null,
    policyTransactions: null,
    error: null,
    enableUpi: false,
    isUpdated: false,
    annualPremium: null,
    monthlyPremium: null,
    isMonthly: false,
};

//  START
const retrieveQuotationsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loadingQuotation: true,
    });
};
const getUserDetailsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loadingUserDetails: true,
    });
};
const getPolicyTransactionsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loadingPolicyTransactions: true,
    });
};

//  SUCCESS
const retrieveQuotationsSuccess = (state, action) => {
    return updateObject(state, {
        loadingQuotation: false,
        quotation: action.quotation,
    });
};
const getUserDetailsSuccess = (state, action) => {
    return updateObject(state, {
        loadingUserDetails: false,
        userDetails: action.userDetails
    });
};
const getPolicyTransactionsSuccess = (state, action) => {
    return updateObject(state, {
        loadingUserDetails: false,
        policyTransactions: action.policyTransactions,
    });
};

//  FAIL
const retrieveQuotationsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loadingQuotation: false,
    });
};
const getUserDetailsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loadingUserDetails: false,
    });
};
const getPolicyTransactionsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loadingPolicyTransactions: false,
    });
};


const updateCalculatePremiumData = (state, action) => {
    return updateObject(state, {
        calculatePremiumData: action.data,
    });
};
const updateBuyFormData = (state, action) => {
    return updateObject(state, {
        buyFormData: action.data,
    });
};
const setBuyFormError = (state, action) => {
    return updateObject(state, {
        buyFormError: action.error,
    });
};
const enableUpiLink = (state, action) => {
    return updateObject(state, {
        enableUpiData: action.enableUpi,
        enableUpi: true
    });
};
const updatedPremium = (state, action) => {
    return updateObject(state, {
        isUpdated: action.isUpdated,
        annualPremium: action.annualPremium,
        monthlyPremium: action.monthlyPremium,
    });
};
const isMonthlyCheck = (state, action) => {
    return updateObject(state, {
        isMonthly: true
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.POST_RETRIEVEQUOTATION_TYPES_START:
            return retrieveQuotationsStart(state, action);

        case actionTypes.GET_USERDETAILS_TYPES_START:
            return getUserDetailsStart(state, action);

        case actionTypes.GET_POLICY_TRANSACTIONS_START:
            return getPolicyTransactionsStart(state, action);



        case actionTypes.POST_RETRIEVEQUOTATION_TYPES_SUCCESS:
            return retrieveQuotationsSuccess(state, action);

        case actionTypes.GET_USERDETAILS_TYPES_SUCCESS:
            return getUserDetailsSuccess(state, action);

        case actionTypes.GET_POLICY_TRANSACTIONS_SUCCESS:
            return getPolicyTransactionsSuccess(state, action);



        case actionTypes.POST_RETRIEVEQUOTATION_TYPES_FAIL:
            return retrieveQuotationsFail(state, action);

        case actionTypes.GET_USERDETAILS_TYPES_FAIL:
            return getUserDetailsFail(state, action);

        case actionTypes.GET_POLICY_TRANSACTIONS_FAIL:
            return getPolicyTransactionsFail(state, action);



        case actionTypes.UPDATE_CALCULATE_PREMIUM_DATA:
            return updateCalculatePremiumData(state, action);

        case actionTypes.UPDATE_BUY_FORM_DATA:
            return updateBuyFormData(state, action);

        case actionTypes.SET_BUY_FORM_ERROR:
            return setBuyFormError(state, action);

        case actionTypes.ENABLE_UPI_LINK:
            return enableUpiLink(state, action);

        case actionTypes.PAYMENT_TYPE_MONTHLY:
            return isMonthlyCheck(state, action);

        case actionTypes.GET_UPDATED_PREMIUM:
            return updatedPremium(state, action);

        default:
            return state;
    }
};

export default reducer;
