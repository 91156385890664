import React, {useEffect} from 'react';
import Box from "@material-ui/core/Box";
import {Images} from "../../constants/images";

function Logo(props) {
    const {logoStyle, logo} = props;

    return (
        <>
            <img src={logo} className={logoStyle}/>
        </>
    );
}

export default Logo;