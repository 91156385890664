import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  sendOtpLoading: false,
  verifyingOtp: false,
  otpSent: false,
  otpVerified: false,
  token: null,
  error: null,
  mobile: "",
};

//  START
const sendOtpStart = (state, action) => {
  return updateObject(state, {
    error: null,
    sendOtpLoading: true,
    mobile: action.mobile,
  });
};
const verifyOtpStart = (state, action) => {
  return updateObject(state, { error: null, verifyingOtp: true });
};

//  SUCCESS
const sendOtpSuccess = (state, action) => {
  return updateObject(state, {
    otpSent: true,
    sendOtpLoading: false,
  });
};
const verifyOtpSuccess = (state, action) => {
  return updateObject(state, {
    otpVerified: true,
    verifyingOtp: false,
  });
};
const loggedInSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
  });
};

//  FAIL
const sendOtpFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    sendOtpLoading: false,
  });
};
const verifyOtpFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    verifyingOtp: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POST_SENDOTP_TYPES_START:
      return sendOtpStart(state, action);
    case actionTypes.POST_VERIFYOTP_TYPES_START:
      return verifyOtpStart(state, action);

    case actionTypes.POST_SENDOTP_TYPES_SUCCESS:
      return sendOtpSuccess(state, action);
    case actionTypes.POST_VERIFYOTP_TYPES_SUCCESS:
      return verifyOtpSuccess(state, action);
    case actionTypes.CHECK_USER_LOGGED_IN_SUCCESS:
      return loggedInSuccess(state, action);

    case actionTypes.POST_SENDOTP_TYPES_FAIL:
      return sendOtpFail(state, action);
    case actionTypes.POST_VERIFYOTP_TYPES_FAIL:
      return verifyOtpFail(state, action);
    default:
      return state;
  }
};

export default reducer;
