import React, { Component } from "react";

import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import Box from "@material-ui/core/Box";
import ResponsiveContainer from "../../../components/ResponsiveContainer/responsiveContainer";

class LeadPage extends Component {
  render() {
    return (
      <ResponsiveContainer
        child={
          <>
            <Box>Lead Page</Box>
          </>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.customer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadPage);
