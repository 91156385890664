import React, {useEffect, useReducer} from 'react';
import {useHistory, useLocation} from "react-router";
import MaterialAppBar from "../../../components/Headers/MaterialAppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import {Spinner} from "reactstrap";
import ResponsiveContainer from "../../../components/ResponsiveContainer/responsiveContainer";
import {ReducerUtils} from '../../../constants/reducers';
import QuotationReducer from '../../../reducers/quotation';
import {useGetApi} from '../../../hooks/useApi';
import Typography from 'material-ui/styles/typography';
import TopContent from "../Login/topContent";
import PolicyCard from "../Confirmation/policyCard";
import {ProductDetails, ReferenceMapping} from "../../../mapping/product";


const ProductBuyConfirmation = (props) => {
    const {classes} = props;

    const quotationDetails = useGetApi(`orders/${props.match.params.id}`);
    const [state, dispatch] = useReducer(QuotationReducer, {quotationDetails: null, razorPayInitialization: null});

    useEffect(() => {

        dispatchEvent(ReducerUtils.quotation.details, quotationDetails);
    }, [quotationDetails]);

    const dispatchEvent = (type, payload) => {
        dispatch({
            type,
            payload
        });
    };

    const firstLetterUpperCase = (str) => {
        let string;
        string = str
            ?.toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))[0];
        return string;
    };

    return (
        <ResponsiveContainer
            flag={"userFlow"}
            child={
                <>
                    <MaterialAppBar/>
                    {state.quotationDetails ?
                        <Box id="mainBlock" m={2} style={{'textAlign': 'center'}}>
                            <Box>
                                <TopContent
                                    title={
                                        "Congratulations " +
                                        firstLetterUpperCase(state.quotationDetails.name) +
                                        "!"
                                    }
                                    desc1={"You have successfully purchased a  " + ProductDetails[ReferenceMapping[props.match.params.product]].insuranceType + " insurance policy. "}
                                    desc2="The policy documents have been sent to you over email."
                                />
                                {1 === 2 &&
                                <Box display="flex" justifyContent="center" pt={1}>
                                    <PolicyCard cardData={state.quotationDetails}/>
                                </Box>}
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={(e) => {
                                        window.location.href = `${process.env.REACT_APP_SITE_URL}insurance-policies`;
                                    }}
                                >
                                    Done{/*View Another Toffee*/}
                                </Button>
                            </Box>
                        </Box> :
                        <Box m={2} style={{'textAlign': 'center'}}>
                            <Spinner/>
                        </Box>}
                </>
            }
        />
    );
};

export default withStyles({withTheme: true})(ProductBuyConfirmation);