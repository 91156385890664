import { ReducerUtils } from '../constants/reducers';

export default function reducer(state, action) {
  switch (action.type) {
    case ReducerUtils.existingQuotation.details:
      return {
        ...state,
        existingQuotationDetails: action.payload
      };

    case ReducerUtils.quotation.details:
      return {
        ...state,
        quotationDetails: action.payload
      };

    case ReducerUtils.razorpay.initialization:
      return {
        ...state,
        razorPayInitialization: action.payload
      };

    default:
      return state;
  }
}