import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles, Grid, Chip, TextField } from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";

const useStyles = (theme) => ({
  activeChip: {
    backgroundColor: "rgba(255, 88, 93, .2)",
    borderRadius: "4px",
    color: "#ff585d",
    minWidth: "72px",
  },
  chip: {
    backgroundColor: "rgba(34, 51, 79, .1)",
    borderRadius: "4px",
    color: "#22334f",
    minWidth: "72px",
  },
  dobBox: {
    cursor: "pointer",
  },
});

class ChipDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: this.props.isSelected,
      date: null,
      openDatePicker: false,
    };
  }
  chipClicked() {
    this.setState(
      {
        isSelected: !this.state.isSelected,
      },
      () => {
        this.props.onChange("chip", this.state.isSelected);
      }
    );
  }
  handleDateChange(date) {
    this.setState(
      {
        date: date,
      },
      () => {
        this.props.onChange("date", date.toLocaleString().split(",")[0]);
      }
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <Box p={2}>
        <Grid container spacing={1}>
          <Grid item>
            <Chip
              label={this.props.label}
              onClick={(e) => {
                this.chipClicked();
              }}
              className={
                this.state.isSelected ? classes.activeChip : classes.chip
              }
            />
          </Grid>
          <Grid item>
            <Box
              hidden={!this.state.isSelected}
              className={classes.dobBox}
              onClick={(e) => {
                this.setState({
                  openDatePicker: true,
                });
              }}
            >
              <Typography>DOB</Typography>
              <Typography>
                {this.state.date
                  ? new Date(this.state.date).toLocaleString().split(",")[0]
                  : "dd/MM/yyyy"}
              </Typography>
            </Box>
            <Box hidden>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="DOB"
                  value={this.state.date ?? this.props.maxDate}
                  open={this.state.openDatePicker}
                  format="dd/MM/yyyy"
                  minDate={this.props.minDate}
                  maxDate={this.props.maxDate}
                  onChange={(e) => this.handleDateChange(e)}
                  animateYearScrolling
                  onClose={(e) =>
                    this.setState({
                      openDatePicker: false,
                    })
                  }
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(ChipDate);
