import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import CoverageCard from "./CoverageCard";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import ResponsiveDialog from "./otpDialog";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TermsAndConditions from "./TermsAndConditions";
import ArrowForwardOutlined from "@material-ui/icons/ArrowForwardOutlined";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import {Products} from "../../../../data/products";
import AllDetails from "./allDetails";
import Item from "./item";
import Carousel from "react-elastic-carousel";
import NotificationAlert from "react-notification-alert";
import {useStyles} from './styles/detailsStyles';


const breakPoints = [
    {width: 1, itemsToShow: 1},
    {width: 550, itemsToShow: 2},
    {width: 868, itemsToShow: 3},
    // { width: 1200, itemsToShow: 4 }
];


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CustomerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTermsChecked: true,
            isDesktop: window.innerWidth > 600,
            isDesktopCarousel: window.innerWidth > 900,
            tncOpenDialog: false,
            orderId: new URL(window.location.href).searchParams.get("order_id"),
        };
    }

    componentDidMount() {
        let orderId = new URL(window.location.href).searchParams.get("order_id")
        this.props.getUserDetails(orderId)
    }

    onChangeCheckBox = () => {
        this.setState({isTermsChecked: !this.state.isTermsChecked});
    };
    componentWillReceiveProps = (newProps) => {
        this.setState({
            isTermsChecked:
                (newProps.userDetails && newProps.userDetails?.verified_otp) || false,
        });
    };
    handleClickOpen = () => {
        this.setState({
            tncOpenDialog: true,
        });
    };

    handleClose = () => {
        this.setState({
            tncOpenDialog: false,
        });
    };

    getPercentage() {
        return this.getTwoFixedPoint(
            (((this.props.userDetails?.response_monthly_premium) * 12 - (this.props.userDetails?.response_annual_premium)) /
                (this.props.userDetails?.response_annual_premium)) *
            100
        );
    }

    getDifference() {
        return this.getTwoFixedPoint((this.props.userDetails?.monthly_premium) * 12 - (this.props.userDetails?.annual_premium));
    }

    getTwoFixedPoint = (num) => {
        var x = num * 1;
        return x.toFixed(2);
    };

    getSumAssured(productId) {
        const {userDetails} = this.props;
        let sumAssured = '0';
        switch (productId) {
            case '48':
                sumAssured = userDetails?.response_sum_assured_life;
                break;
            case '49':
                sumAssured = userDetails?.response_sum_assured_health;
                break;
            case '52':
                sumAssured = userDetails?.response_sum_assured_life;
                break;
            case '53':
                sumAssured = userDetails?.response_sum_assured_health;
                break;
            case '54':
                sumAssured = userDetails?.response_sum_assured_home;
                break;
            case '55':
                sumAssured = userDetails?.response_sum_assured_health_topup;
                break;
            case '56':
                sumAssured = userDetails?.response_sum_assured_personal_accidental;
                break;
            case '58':
                sumAssured = userDetails?.response_sum_assured_life;
                break;
            case '59':
                sumAssured = userDetails?.response_sum_assured_life;
                break;
        }
        return sumAssured;
    }

    notify = (place, title, message, type) => {
        let options = {
            place: place,
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 2,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };


    render() {
        const {classes, userDetails, onConfirmClicked, onNext} = this.props;
        const {
            main, title, detailsText, coverageCard, card, content, cardTitle,
            overflow, paperImages, downloadText, download, downloadBrochure
        } = classes;
        const isDesktop = this.state.isDesktop;
        const premium_breakup = userDetails?.products ? userDetails?.products : {};
        let productId = this.props.userDetails?.product_id
        return (
            <Box>
                <Box className="rna-wrapper z-index-1" zIndex="tooltip" position="fixed">
                    <NotificationAlert ref="notificationAlert"/>
                </Box>
                <Box className={main}>
                    <Box pt={3} pb={1}>
                        <Typography className={title} align={"center"}>
                            {!(userDetails?.verified_otp) ?
                                "Verify Policy Details" : "Details Verified"}
                        </Typography>
                    </Box>
                    {!(userDetails?.verified_otp) && <>
                        {isDesktop ? (
                            <Box pt={1} pb={3}>
                                <Typography variant={"h4"} className={detailsText}>
                                    Following details will be used to issue your policy. Kindly verify
                                    your details.
                                </Typography>
                            </Box>
                        ) : (
                            ""
                        )}
                    </>
                    }
                    <Box className={coverageCard}>
                        <Card className={card} elevation={0}>
                            <CardContent p={0} className={content}>
                                <Typography gutterBottom className={cardTitle} align="center">
                                    COVERAGE
                                </Typography>
                                <Box className={overflow}>
                                    {productId === 51 &&
                                    <Carousel breakPoints={breakPoints} enableAutoPlay={false} showArrows={false}
                                        // pagination={(!(isDesktopCarousel && Object.keys(premium_breakup).length > 1))}
                                              pagination={true}
                                    >
                                        {Object.keys(premium_breakup).map((product_id, i) => {
                                            return <Item key={i}>
                                                <CoverageCard
                                                    img={<img
                                                        src={Products[product_id] ? Products[product_id].image : ''}
                                                        alt=""
                                                        className={paperImages}
                                                    />}
                                                    sumAssured={this.getSumAssured(product_id)}
                                                    product={Products[product_id] ? Products[product_id].name : ''}
                                                    productBy={Products[product_id] ? Products[product_id].provider : ''}
                                                    premiumBreakup={"₹" + premium_breakup[product_id].premium}
                                                />
                                            </Item>;
                                        })}
                                    </Carousel>}

                                    {productId !== 51 &&
                                    <Item>
                                        <CoverageCard
                                            img={<img
                                                src={Products[productId] ? Products[productId].image : ''}
                                                alt=""
                                                className={paperImages}
                                            />}
                                            sumAssured={productId && this.getSumAssured(productId.toString())}
                                            product={Products[productId] ? Products[productId].name : ''}
                                            productBy={Products[productId] ? Products[productId].provider : ''}
                                            // premiumBreakup={"₹" + premium_breakup[product_id]}
                                        />
                                    </Item>}
                                </Box>
                            </CardContent>
                            <CardActions>
                                {/*<Box className={downloadBrochure}>*/}
                                {/*    <Box flexGrow={1}>*/}
                                {/*        <Typography variant={"h6"} className={downloadText}>*/}
                                {/*            DOWNLOAD BROCHURE*/}
                                {/*        </Typography>*/}
                                {/*    </Box>*/}

                                {/*        <Button*/}
                                {/*            size="small"*/}
                                {/*            pt={-3}*/}
                                {/*            className={download}*/}
                                {/*            onClick={() => {*/}
                                {/*                window.open(*/}
                                {/*                    "https://toffeeinsurance.com/documents/the-toffee-plan.pdf"*/}
                                {/*                );*/}
                                {/*            }}*/}
                                {/*        >*/}
                                {/*            <img*/}
                                {/*                src={require("../../../../assets/img/verification/download.png")}*/}
                                {/*                alt=""*/}
                                {/*            />*/}
                                {/*        </Button>*/}

                                {/*</Box>*/}
                            </CardActions>
                        </Card>
                    </Box>
                    {!isDesktop ? (
                        <Box pt={4} pl={3} pr={3} pb={0}>
                            <Typography variant={"h6"} className={detailsText}>
                                Following details will be used to issue your policy. Kindly verify
                                your details.
                            </Typography>
                        </Box>
                    ) : (
                        ""
                    )}
                    <Grid item container direction="column">
                        <Grid item container>
                            <Grid item xs={false} md={2}/>
                            <Grid item xs={12} md={8}>
                                <AllDetails userData={userDetails}/>
                            </Grid>
                            <Grid item xs={false} md={2}/>
                        </Grid>
                    </Grid>
                    {!(userDetails?.verified_otp) ? (
                        <Box display="flex" justifyContent="center" pb={2}>
                            <ResponsiveDialog
                                // disabled={!this.state.isTermsChecked}
                                title="PAY NOW"
                                endIcon={<ArrowForwardOutlined/>}
                                mobile={userDetails?.mobile}
                                onButtonClick={(otp) => {
                                    onConfirmClicked(otp);
                                }}
                            />
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent="center" pb={2}>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                endIcon={<ArrowForwardOutlined/>}
                                onClick={(e) => {
                                    onNext();
                                }}
                            >
                                Next
                            </Button>
                        </Box>
                    )}
                    <Box display="flex" justifyContent="center" p={3}>
                        <Typography variant="body2" className="font-size-12" align={"center"}>
                            By clicking on the pay button, you agree with our
                            <span
                                className="font-size-12 txt-primary cursor-pointer"
                                onClick={() => {
                                    this.handleClickOpen();
                                }}
                            >
                      {" "}
                                Terms and Conditions{" "}
                    </span>
                        </Typography>
                    </Box>
                    <Dialog
                        fullScreen
                        open={this.state.tncOpenDialog}
                        onClose={() => this.handleClose()}
                        TransitionComponent={Transition}
                    >
                        <AppBar position="sticky" style={{paddingTop: '0'}}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => this.handleClose()}
                                    aria-label="close"
                                >
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="h5">Terms and Conditions</Typography>
                            </Toolbar>
                        </AppBar>
                        <TermsAndConditions/>
                    </Dialog>
                </Box>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.customer.userDetails,
        error: state.customer.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
        getUserDetails: (userId) => dispatch(actions.getUserDetails(userId)),
    };
};
export default withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(CustomerDetails)
);
