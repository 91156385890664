import React, { Component, Fragment } from "react";
import MainHeader from "../../../components/Headers/MainHeader";

import { connect } from "react-redux";
import MultiSelect from "../../../components/Claim/Inputs/MultiSelect";
import NotificationAlert from "react-notification-alert";
import * as actions from "../../../store/actions";
import API from "../../../utilities/api";

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentComponent: "",
      options: [],
      isSubmitted: false,
      changed: null,
      rating: 5,
      label: "",
      feedbackStarArray: [],
      toffeeId: "",
      linkExpired: false,
    };
    this.setState({
      toffeeId: props.match.params.id,
    });
    // console.log(props.match.params.id);
  }
  componentDidMount() {
    this.props.onCallShowLoader(true);
    this.getResumedData();
    API.get("web/feedback/claims/", {})
      .then((response) => {
        // console.log(response.data);
        this.props.onCallShowLoader(false);
        this.setState(
          {
            feedbackStarArray: response.data.data.feedbackStar,
          },
          () => {
            this.updateRating(5);
          }
        );
      })
      .catch((err) => {
        this.props.onCallShowLoader(false);
      });
  }
  getResumedData = () => {
    API.get("web/feedback/claims/" + this.props.match.params.id, {})
      .then((response) => {
        // console.log(response.data);
        if (response.data.data.feedbackStar) {
          this.setState({
            isSubmitted: true,
            linkExpired: true,
            rating: response.data.data.feedbackStar,
            feedbackStarArray: response.data.data.feedbackParams,
          });
        } else {
          console.log("new feedback...");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  stars = {
    gray: require("../../../assets/img/star-gray.png"),
    golden: require("../../../assets/img/star-gold.png"),
  };
  updateState = (data) => {
    this.setState(data);
  };
  moveToDirection = (component) => {
    // console.log(component);
    // this.setState({
    //     currentComponent: component
    // })
  };

  updateRating = (rating) => {
    this.setState({ rating: rating });
    this.state.feedbackStarArray &&
      this.state.feedbackStarArray.forEach((element) => {
        if (element.value * 1 === rating * 1) {
          this.setState({
            label: element.label,
            options: Array.isArray(element.params) ? element.params : [],
          });
        }
      });
  };
  getMultiselectData = (data) => {
    // console.log(data);
    this.props.onCallShowLoader(true);
    API.post("web/feedback/claims/" + this.props.match.params.id, {
      stars: this.state.rating,
      params: data.value,
    })
      .then((response) => {
        // console.log(response);
        this.props.onCallShowLoader(false);
        // this.notify("tr", "", "Please fill all the fields", "success")

        this.setState({
          isSubmitted: true,
        });
      })
      .catch((err) => {
        this.props.onCallShowLoader(false);
      });
  };
  notify = (place, title, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    return (
      <Fragment>
        <MainHeader
          currentComponent={this.state.currentComponent}
          setState={(data) => {
            this.updateState(data);
          }}
        ></MainHeader>
        {!this.state.isSubmitted ? (
          <div className=" component-container position-relative m-auto pt-5">
            <div className="w-100">
              <div className="w-100 pt-5 pb-2 text-center mt-3">
                <div>
                  <img
                    className="icon-40"
                    src={require("../../../assets/img/icons/green-filled-tick.png")}
                    alt=""
                  />
                </div>
                <div>
                  <h5 className="m-auto pt-2 text-center w-75  text-muted h5">
                    Congrats! Your claim has been settled and the money is on
                    the way to your bank account.
                  </h5>
                </div>
              </div>
            </div>
            <div className="p-3 py-4 ml-2 text-left ">
              <h3 className="font-weight-light font-weight-normal m-0 mt-3 mb-2">
                Please do take a minute to rate your experience with Toffee
                Insurance!
              </h3>
            </div>
            <div className="p-0 py-1 ml-3 text-left ">
              <img
                className="icon-30 m-2"
                onClick={(e) => {
                  this.updateRating(1);
                }}
                src={
                  this.state.rating > 0 ? this.stars.golden : this.stars.gray
                }
                alt=""
              />
              <img
                className="icon-30 m-2"
                onClick={(e) => {
                  this.updateRating(2);
                }}
                src={
                  this.state.rating > 1 ? this.stars.golden : this.stars.gray
                }
                alt=""
              />
              <img
                className="icon-30 m-2"
                onClick={(e) => {
                  this.updateRating(3);
                }}
                src={
                  this.state.rating > 2 ? this.stars.golden : this.stars.gray
                }
                alt=""
              />
              <img
                className="icon-30 m-2"
                onClick={(e) => {
                  this.updateRating(4);
                }}
                src={
                  this.state.rating > 3 ? this.stars.golden : this.stars.gray
                }
                alt=""
              />
              <img
                className="icon-30 m-2"
                onClick={(e) => {
                  this.updateRating(5);
                }}
                src={
                  this.state.rating > 4 ? this.stars.golden : this.stars.gray
                }
                alt=""
              />
              <h6 className="d-inline">{this.state.rating + ".0"}</h6>
            </div>
            <h6>
              <label className="p-0 ml-4 text-left text-muted">
                {this.state.label}
              </label>
            </h6>

            <MultiSelect
              parent={"feedback"}
              addInput={(data) => {
                this.getMultiselectData(data);
              }}
              input={{
                name: "Feedback",
                options: this.state.options,
                title:
                  this.state.rating < 5
                    ? "Please let us know where can we improve?"
                    : "Please let us know what you like?",
              }}
              setState={(data) => this.props.setState(data)}
            ></MultiSelect>
          </div>
        ) : (
          <div className=" component-container position-relative m-auto pt-5">
            {!this.state.linkExpired ? (
              <div
                hidden={this.state}
                className=" text-center vh-100 d-flex align-items-center justify-content-center"
              >
                <div>
                  <img
                    className="icon-80 "
                    src={require("../../../assets/img/icons/green-filled-tick.png")}
                    alt=""
                  />
                  <h3 className="mt-2 font-weight-light">
                    Thank you for your time! <br /> Your feedback is submitted
                    successfully.
                  </h3>
                </div>
              </div>
            ) : (
              <div className=" text-center vh-100 d-flex align-items-center justify-content-center">
                <div>
                  <img
                    className="icon-80 "
                    src={require("../../../assets/img/icons/close.png")}
                    alt=""
                  />
                  <h3 className="mt-2 font-weight-light">
                    Link expired! <br /> Feedback for the claim is already
                    submitted.
                  </h3>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
  };
};
export default connect(null, mapDispatchToProps)(Feedback);
