import {createStyles, makeStyles, Typography} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    listG: {
        padding: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            width: "328px !important",
            //   width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: "585px !important",
        },
    },
    listTitle: {
        fontWeight: "600",
        // minWidth: "40px"
    },
    outerBox: {
        width: "100%",

        [theme.breakpoints.down("sm")]: {
            padding: "24px",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(4),
        },
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2),
        },
    },
    listIndex: {
        [theme.breakpoints.up("md")]: {
            minWidth: "100px",
        },
    },
    listIcon: {
        [theme.breakpoints.up("md")]: {
            minWidth: "100px",
        },
    },
    fontColor:{
        color: '#142550'
    },
    list: {
        padding: "0px !important",
    },
});