import {createStyles, makeStyles, Typography} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    main: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up("sm")]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: 'row',
            padding: theme.spacing(0,5,0,0),
        },
    },
    sideContent: {
        width: '100%',
    },
    input: {
        display: "flex",
        justifyContent: "space-between",
        alignItems:"space-between",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
        },
        [theme.breakpoints.up("sm")]: {
            height: 'auto',
        },
        [theme.breakpoints.up("md")]: {
            height: '88vh',
        },
    },
    imgLg: {
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            height: "auto",
            width: "auto",
        },
        [theme.breakpoints.up("md")]: {
            height: "auto",
            width: "100%",
        },
    },
    mobileInput: {
        padding: theme.spacing(2),

        border: 0,
        borderLeft: '1px solid #142550',
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: '350px',
        },
    },
    mobileInputFocus: {
        padding: theme.spacing(2),
        width: '100%',
        border: 0,
        borderLeft: '1px solid #142550',
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginTop: "0px",
        },
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: '350px',
        },
    },
    customBtn: {
        display: "flex",
        marginTop: "40px",
        marginBottom: "80px",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: "justify",
        },
        [theme.breakpoints.up("md")]: {
            position:'relative',
            height: '100%',
            justifyContent: "justify",
        },
    },
    button:{
        [theme.breakpoints.down("sm")]: {
            position: "none",
        },
        [theme.breakpoints.up("sm")]: {
            // justifyContent: "justify",
        },
        [theme.breakpoints.up("md")]: {
            position: "absolute",
            bottom: 100,
            right: 10
        },

    },
    subTitle: {
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "center",
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
        },
    },
    formInput: {
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            paddingLeft: "150px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(10, 0, 0)
        },
    },
    btnLoader: {
        color: "#ffffff",
        height: "26px",
        width: "26px",
        marginLeft: "26px",
        marginRight: "26px"
    },
    formInputCard: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.common.white,
        // minHeight: '100%'
    },
    numberInputBox: {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: 'column',
        // flexDirection: "column"
        [theme.breakpoints.down("sm")]: {
            marginTop: "80px",
            alignItems:"center",
            height: 'auto'
        },
        [theme.breakpoints.up("sm")]: {
            alignItems:"center",
            height: 'auto'
        },
        [theme.breakpoints.up("md")]: {
            alignItems:"flex-start",
            width: '100%',
            height: '100%',
        },


    },
    numberInput: {
        border: '1px solid #142550',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    countryCode: {
        padding: theme.spacing(0, 1.5),
        color: '#22334f',
        opacity: '0.5'
    },
    errorMsg:{
        height: '20px'
    },
    mobileLabel:{
        margin:theme.spacing(0,0,1)
    },
    mobileLabelOtp:{
        width: '100%',
        margin:theme.spacing(0,0,1),
        textAlign: 'left'
    }


})