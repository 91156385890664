import React, { Component } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from 'react-router-dom';

const useStyles = (theme) => ({
  listTitle: {
    fontWeight: "600",
  },
  listTitleBackground: {
    borderRadius: "0px",
    backgroundColor: theme.palette.primary.main,
    minHeight: "64px",
    maxHeight: "64px",
    '&:hover':{
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8
    }
  },
});

class MaterialListButton extends Component {
  render() {
    const { classes } = this.props;
    return (
      <ListItem
        button
        onClick={(e) => {
          this.props.onListClick();
        }}
        className={classes.listTitleBackground}
      >
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                component="span"
                variant={"h6"}
                className={classes.listTitle}
              >
                {this.props.title}
              </Typography>
              {this.props.desc}
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
                {this.props.subTitle}
              {this.props.desc}
            </React.Fragment>
          }
          className="pl-2"
        />
        {this.props.icon}
      </ListItem>
    );
  }
}
export default  withRouter(withStyles(useStyles, { withTheme: true })(MaterialListButton));
