import React, {Component} from "react";

import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import CustomerDetails from "./CutomerVerification/Details";
import CustomerDocuments from "./CutomerVerification/Documents";
import CustomerPayment from "./CutomerVerification/Payment";
import withStyles from "@material-ui/core/styles/withStyles";
import StepConnector from "@material-ui/core/StepConnector";
import {ArrowBack, CheckCircle} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import ResponsiveContainer from "../../../components/ResponsiveContainer/responsiveContainer";
import Typography from "@material-ui/core/Typography";
import CustomLoader from "../../../components/Common/CustomLoader";
import API from "../../../utilities/api";
import * as actionTypes from "../../../store/actions/actionTypes";
import {AppBar, IconButton, Toolbar} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Medical from "./CutomerVerification/medical";
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 5,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    active: {
        "& $line": {
            borderColor: "#0be42d",
        },
    },
    completed: {
        "& $line": {
            borderColor: "#0be42d",
        },
        color: "red",
    },
    line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 1,
        borderRadius: 1,
    },
})(StepConnector);

const useStyles = (theme) => ({
    stepLabel: {
        fontWeight: "500",
        fontSize: "10px",
    },
    label: {
        cursor: "pointer",
    },
    stepLab: {
        padding: "0px",
    },
    checkIcon: {
        height: "10px",
        width: "10px",
    },
    topRadiusBackground: {
        backgroundColor: "#ff585d"
    },
    title: {
        flexGrow: 1,
        textAlign: "center",
    },
    whiteCurvedContainer: {
        backgroundColor: "#ffffff",
        overflowY: 'scroll',
        height: "calc(100vh - 69px)"
    },
    redBg: {
        // backgroundColor: theme.palette.primary.main,
    },
});

const useColorlibStepIconStyles = makeStyles({
    root: {
        // backgroundColor: "rgba(34, 51, 79, 0.2)",
        color: "rgba(34, 51, 79, 0.2)",
        width: 10,
        height: 10,
        padding: 0,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        color: "#eb5757",
        borderWidth: '3px',
        backgroundColor: 'transparent',
        // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
        color: "#0be42d",
    },
    default: {
        backgroundColor: "rgba(34, 51, 79, 0.2)",
    },
    overrides: {
        MuiAvatar: {
            root: {
                width: 10,
                height: 10,
            },
        },
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed, idle} = props;
    const iconsActive = {
        1: <FiberManualRecordOutlinedIcon fontSize={"large"}/>,
        2: <FiberManualRecordOutlinedIcon fontSize={"large"}/>,
        3: <FiberManualRecordOutlinedIcon fontSize={"large"}/>,
        4: <FiberManualRecordOutlinedIcon fontSize={"large"}/>,
    };
    const iconsDone = {
        1: <CheckCircleIcon fontSize={"medium"}/>,
        2: <CheckCircleIcon fontSize={"medium"}/>,
        3: <CheckCircleIcon fontSize={"medium"}/>,
        4: <CheckCircleIcon fontSize={"medium"}/>,
    };
    const iconsDefault = {
        1: <FiberManualRecordIcon fontSize={"large"}/>,
        2: <FiberManualRecordIcon fontSize={"large"}/>,
        3: <FiberManualRecordIcon fontSize={"large"}/>,
        4: <FiberManualRecordIcon fontSize={"large"}/>,
    }
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
                [classes.default]: idle,
            })}
        >
            {active ? iconsActive[String(props.icon)] : (completed ? iconsDone[String(props.icon)] : iconsDefault[String(props.icon)])}
        </div>
    );
}

let param, enableUpi;

class VerifyDetails extends Component {
    state = {
        activeStep: 0,
        value: true,
        enableUpi: new URL(window.location.href).searchParams.get("enable_upi") !== null && new URL(window.location.href).searchParams.get("enable_upi")?.toLowerCase() === "yes",
        premiumUpdated: false,
        annualPremium: null,
        monthlyPremium: null,
        orderId: new URL(window.location.href).searchParams.get("order_id"),
        otpError: null
    };
    isDocumentsRequired = this.props.userDetails?.are_documents_required;

    stepContent = [
        <CustomerDetails
            onConfirmClicked={(otp) => {
                this.VerifyDetailsWithOtp(otp);
            }}
            onNext={() => {
                this.updatePremium()

            }}
        />,
        <CustomerPayment
            paymentDone={(monthly) => {
                if (!this.props.userDetails?.are_documents_required) {
                    this.props.history.push("/confirmation");
                    return;
                }
                this.props.updateQuotation({
                    ...this.props.userDetails,
                    is_monthly: monthly,
                });
                try {
                    this.props.userDetails && this.props.deleteLeadFromFirebase(this.props.userDetails["mobile"] + '-51')
                } catch (err) {

                }
            }}
            onNext={() => {
                this.props.updateQuotation({
                    ...this.props.userDetails,
                    is_already_paid: true,
                });
                this.props.userDetails?.are_documents_required ?
                    this.handleNext() :
                    this.props.history.push("/confirmation");
            }}
        />,
        <CustomerDocuments
            onDoneClicked={(e) => {
                this.props.userDetails?.is_medical_required ?
                    this.handleNext() :
                    this.props.history.push("/confirmation");
            }}
        />,
        <Medical/>

];

    stepItemsWithMedical = ["Verify Details", "Pay Premium", "Upload Documents", "Medical"]
    stepItemsWithoutMedical = ["Verify Details", "Pay Premium", "Upload Documents"]
    stepItemsWithoutDocuments = ["Verify Details", "Pay Premium"]
    // steps = ["Verify Details", "Pay Premium", "Upload Documents", this.props.userDetails?.is_medical_required === false && "Medical"];
    steps = this.props.userDetails?.is_medical_required ? this.stepItemsWithMedical : (!this.isDocumentsRequired ? this.stepItemsWithoutDocuments : this.stepItemsWithoutMedical);

    handleNext = () => {
        this.setState({
            activeStep: this.state.activeStep + 1,
        });
        this.scrollToTop();
    };
    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
        this.scrollToTop();
    };
    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };
    handleStep = (step) => {
        switch (step) {
            case 1:
                this.props.userDetails &&
                this.props.userDetails?.verified_otp &&
                this.setState({
                    activeStep: step,
                });
                break;
            case 2:
                this.props.userDetails &&
                this.props.userDetails?.is_already_paid &&
                this.setState({
                    activeStep: step,
                });
                break;
            case 3:
                this.props.userDetails &&
                this.props.userDetails?.is_documentation_complete &&
                this.setState({
                    activeStep: step,
                });
                break;
            default:
                this.setState({
                    activeStep: step,
                });
                break;
        }
    };

    updatePremium() {
        this.props.onCallShowLoader(true);
        let id = this.props.userDetails?.order_id;
        API.get('orders/' + id + '/update_premium')
            .then((res) => {
                if (res.data.data.is_changed) {
                    this.props.getUpdatedPremium(res.data.data.is_changed, res.data.data.premiums.annual_premium, res.data.data.premiums.monthly_premium)
                }
                this.handleNext();
                this.props.onCallShowLoader(false);
            })
            .catch(err => {

                this.props.onCallShowLoader(false);
                console.log(err);
            });
    }

    componentDidMount() {
        let orderId = new URL(window.location.href).searchParams.get("order_id")
        this.props.getUserDetails(orderId)
        // this.props.userDetails
        //     ? this.props.getUserDetails(orderId)
        //     : this.props.history.push("/the-toffee-plan/quotation?order_id="+orderId);
        !this.props.userDetails && this.props.history.push("/the-toffee-plan/quotation?order_id=" + orderId);
    }

    VerifyDetailsWithOtp(otp) {
        let orderId = this.props.userDetails?.order_id
        console.log(orderId)
        console.log(this.props.userDetails)
        this.props.onCallShowLoader(true);
        API.post(`orders/${orderId}/verifyOtp`, {
            otp: otp,
        })
            .then((res) => {
                this.props.onCallShowLoader(false);
                if (res.data.code === 200) {
                    this.props.updateQuotation({
                        ...this.props.userDetails,
                        verified_otp: true,
                    });
                    this.updatePremium()
                } else {
                    console.log(res.data.data.message);
                    this.props.updateQuotation({
                        ...this.props.userDetails,
                        otpError: res.data.data.message
                    });
                    //this.setState({ otpError: })
                }
            })
            .catch((err) => {
                this.props.onCallShowLoader(false);
                console.log(err);
            });
    }

    // The state needs to be updated when receiving newProps
    // componentWillReceiveProps = (newProps) => {
    //     if (newProps.quotation && newProps.quotation.verified_otp) {
    //       this.setState({
    //         activeStep: 1,
    //       });
    //     }
    //     if (newProps.quotation && newProps.quotation.is_already_paid) {
    //       this.setState({
    //         activeStep: 2,
    //       });
    //     }
    // };

    scrollToTop() {
        let elmnt = document.getElementById("outerBox");
        let y = elmnt.scrollTo(0, 0);
    }

    render() {
        console.log(this.state.orderId)
        const {classes} = this.props;
        this.state.enableUpi && this.props.enableUpiLink(this.state.enableUpi)
        return (
            <ResponsiveContainer
                flag={"userFlow"}
                child={
                    <>
                        <Box className={classes.redBg}>
                            {/*<MaterialAppBar />*/}
                            <AppBar elevation={0} position="sticky" style={{padding: "0"}}>
                                <Toolbar>
                                    {!this.props.userDetails?.is_agent_quotation ?
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"
                                            onClick={(e) => {
                                                this.state.activeStep > 0 ? this.handleBack() : this.props.history.push("/the-toffee-plan/buy");
                                            }}
                                        >
                                            <ArrowBack/>
                                        </IconButton>
                                        :
                                        (this.state.activeStep > 0 &&
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="menu"
                                                onClick={(e) => {
                                                    this.handleBack();
                                                }}
                                            >
                                                <ArrowBack/>
                                            </IconButton>)
                                    }
                                    <Typography variant="h3" className={classes.title}>
                                        <b> {this.state.activeStep === 0 ? "Review" : (this.state.activeStep === 1 ? "Pay" : (this.state.activeStep === 2 ? "Documents" : "Medical"))}</b>
                                    </Typography>
                                    {/*<IconButton color="inherit" aria-label="contact" onClick={() => {*/}
                                    {/*    this.props.history.push("/the-toffee-plan");*/}
                                    {/*}}>*/}
                                    {/*    <Close/>*/}
                                    {/*</IconButton>*/}
                                </Toolbar>
                            </AppBar>
                            <Box position={"relative"} className={classes.whiteCurvedContainer} id={"outerBox"}>
                                <Stepper
                                    activeStep={this.state.activeStep}
                                    alternativeLabel
                                    connector={<ColorlibConnector/>}
                                    className="stepper-bg-color pb-0 px-0"
                                >
                                    {this.steps.map((label, index) => (
                                        <Step key={label} className={classes.stepLab}>
                                            <StepLabel
                                                className={classes.label}
                                                onClick={(e) => this.handleStep(index)}
                                                StepIconComponent={ColorlibStepIcon}
                                            >
                                                <Box pl={0} pr={0} pb={1} m={0}>
                                                    <Typography
                                                        variant={"h6"}
                                                        align={"center"}
                                                        className={classes.stepLabel}
                                                    >
                                                        {label}
                                                    </Typography>
                                                </Box>
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>

                                {this.stepContent[this.state.activeStep]}
                            </Box>
                        </Box>
                        <CustomLoader active={this.props.loading}/>
                    </>
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.customer.userDetails,
        loading: state.claim.loading,
        error: state.customer.error,
        enableUpi: state.customer.enableUpi,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
        getUserDetails: (userId) => dispatch(actions.getUserDetails(userId)),
        updateQuotation: (userDetails) =>
            dispatch({
                type: actionTypes.GET_USERDETAILS_TYPES_SUCCESS,
                userDetails: userDetails,
            }),
        deleteLeadFromFirebase: (doc) => dispatch(actions.deleteFirebaseLead(doc)),
        enableUpiLink: (data) => dispatch(actions.enableUpiLink(data)),
        getUpdatedPremium: (isUpdated, annual, monthly) => dispatch(actions.getUpdatedPremium(isUpdated, annual, monthly)),
    };
};
export default withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(VerifyDetails)
);
