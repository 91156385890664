import {Keys} from "./keys";
import {Text} from "./text";

export const Strings = {
    empty: " ",
    aadhardCard: Text.aadhar_card,
    about: Text.about,
    aboveFifteenLakhs: Text.above_fifteen_lakhs,
    accidentalPhysicalDamage: Text.accidental_physical_damage,
    actionsRequired: Text.actions_required,
    activateYourPolicy: Text.activate_your_policy,
    add: Text.add,
    addBack: Text.add_back,
    addContact: Text.add_contact,
    addEstimate: Text.add_estimate,
    addEstimateAmount: Text.add_estimate_amount,
    addFamilyMembers: Text.add_family_members,
    addFront: Text.add_front,
    addIncome: Text.add_income,
    addInvoice: Text.add_invoice,
    addInvoiceAmount: Text.add_invoice_amount,
    addPan: Text.add_pan,
    addPhotoOfDamageCycle: Text.add_photo_of_damage_cycle,
    addUserPhoto: Text.add_user_photo,
    additionalData: Text.additional_data,
    address: Text.address,
    addressInfo: Text.address_info,
    addressLineOne: Text.address_line_1,
    addressLineTwo: Text.address_line_2,
    advisedHadSurgery: Text.advised_had_surgery,
    ageOfEldestMember: Text.age_of_eldest_member,
    agentKit: Text.agent_kit,
    agentKitTitleText: Text.agent_kit_title_text,
    aids: Text.aids,
    ailmentInjuryAccident: Text.ailment_injury_accident,
    ailmentPresentBirth: Text.ailment_present_birth,
    alcoholDrugUse: Text.alcohol_drug_use,
    allInOneInsurance: Text.all_in_one_insurance,
    allInclusiveCoverage: Text.all_inclusive_coverage,
    allNotifications: Text.all_notifications,
    alreadyCovered: Text.already_covered,
    alreadyHaveAccount: Text.already_have_an_account,
    alreadyHealthInsurance: Text.already_health_insurance,
    alternateTreatments: Text.alternate_treatments,
    amanPandoh: Text.aman_pandoh,
    amanPandohTestimonial: Text.aman_pandoh_testimonial,
    ambulanceCoverage: Text.ambulance_coverage,
    amitRai: Text.amit_rai,
    amitRaiTestimonial: Text.amit_rai_testimonial,
    andFinally: Text.and_finally,
    annualHealthCheckup: Text.annual_health_checkup,
    anyAilmentPlaceholder: Text.any_ailment_placeholder,
    anyBloodPlaceholder: Text.any_blood_placeholder,
    anyCongenitalPlaceholder: Text.any_congenital_placeholder,
    anyDisorderPlaceholder: Text.any_disorder_placeholder,
    anyGastroPlaceholder: Text.any_gastro_placeholder,
    anyGynecologicalPlaceholder: Text.any_gynecological_placeholder,
    anyTerminationPlaceholder: Text.any_termination_placeholder,
    architect: Text.architect,
    asthma: Text.asthma,
    asthmaPlaceholder: Text.asthma_placeholder,
    autoImmunePlaceholder: Text.auto_immune_placeholder,
    baggageCoverage: Text.baggage_coverage,
    beer: Text.beer,
    belongingsWithoutInvoice: Text.belongings_without_invoice,
    bestOfThree: Text.best_of_three,
    bicycleMotorVehicles: Text.bicycle_motor_vehicles,
    bidi: Text.bidi,
    birthday: Text.birthday,
    bloodDisorders: Text.blood_disorders,
    boughtToffeeInsurance: Text.bought_toffee_insurance,
    brochure: Text.brochure,
    brother: Text.brother,
    businessIncome: Text.business_income,
    businessman: Text.businessman,
    buyPlan: Text.buy_plan,
    buyPlanOnline: Text.buy_plan_online,
    buyPolicy: Text.buy_policy,
    ca: Text.ca,
    calculate: Text.calculate,
    calculatePremium: Text.calculate_premium,
    calculateToffeePayment: Text.calculate_toffee_payment,
    call: "Call",
    callBackNumber: Text.call_back_number,
    calledToffeeBecause: Text.called_toffee_because,
    cancerPlaceholder: Text.cancer_placeholder,
    cancerTumor: Text.cancer_tumor,
    caseAccidentillnessCausingPermanent:
    Text.case_accident_illness_causing_permanent,
    caseInjuryDeathAccident: Text.case_injury_death_accident,
    caseOfEmergency: Text.case_of_emergency,
    caseRanOutHealthCover: Text.case_ran_out_health_cover,
    caseSuicide1Year: Text.case_suicide_1_year,
    cashlessFacility: Text.cashless_facility,
    certainDiseasesNonInfective: Text.certain_diseases_non_infective,
    checkAvailability: Text.check_availability,
    checkMail: Text.check_mail,
    checkOutPlan: Text.check_out_plan,
    chestPain: Text.chest_pain,
    chestPlaceholder: Text.chest_placeholder,
    child1: Text.child_1,
    child2: Text.child_2,
    chooseFourFamilyMember: Text.choose_four_family_member,
    chooseIncomeProof: Text.choose_income_proof,
    cigar: Text.cigar,
    cigarette: Text.cigarette,
    citiesCovered: Text.cities_covered,
    city: Text.city,
    claim: Text.claim,
    claimAmount: Text.claim_amount,
    claimCompleted: Text.claim_completed,
    claimSettlementRate: Text.claim_settlement_rate,
    claims: Text.claims,
    claimsDetails: Text.claims_details,
    claimsDetailsFileText1: Text.claims_details_file_text_1,
    claimsDetailsFileText2: Text.claims_details_file_text_2,
    clickPicture: Text.click_picture,
    closeContactCovid: Text.close_contact_covid,
    commission: Text.commission,
    completedClaims: Text.completed_claims,
    congenitalBirthDefect: Text.congenital_birth_defect,
    congenitalDefect: Text.congenital_defect,
    congenitalDisease: Text.congenital_disease,
    congratulationPolicyIssue: Text.congratulation_policy_issue,
    congratulations: Text.congratulations,
    consultant: Text.consultant,
    consultedTakenTreatment: Text.consulted_taken_treatment,
    consumeAlcohol: Text.consume_alcohol,
    consumeNarcotics: Text.consume_narcotics,
    consumingFor: Text.consuming_for,
    consumptionFrequency: Text.consumption_frequency,
    contactUs: Text.contact_us,
    contactUsAt: Text.contact_us_at,
    continueBuy: Text.continue_buy,
    continueText: Text.continue,
    convenienceEasyMonthlyPayment: Text.convenience_easy_monthly_payment,
    coronaExposure: Text.corona_exposure,
    corporateAgentLicence: Text.corporate_agent_licence,
    coversCovid19: Text.covers_covid_19,
    coversUptoFour: Text.covers_upto_four,
    currentOccupation: Text.current_occupation,
    currentOccupationAssociated: Text.current_occupation_associated,
    currentlyNotHealthy: Text.currently_hot_healthy,
    currentlySelfEmployedAs: Text.currently_self_employed_as,
    currentlySmoke: Text.currently_smoke,
    currentlySuffering: Text.currently_suffering,
    currentlyTakingMedication: Text.currently_taking_medication,
    customers: Text.customers,
    customisedMonthlyPayment: Text.customised_monthly_payment,
    cyclePolicy: Text.cycle_policy,
    damageBelongingMotorVehicles: Text.damage_belonging_motor_vehicles,
    damageCausedDepreciation: Text.damage_caused_depreciation,
    damageMobilePhones: Text.damage_mobile_phones,
    dashboard: Text.dashboard,
    dateOfBirth: Text.date_of_birth,
    daughter: Text.daughter,
    day30WaitingPeriod: Text.day_30_waiting_period,
    days30PreHospitalisation: Text.days_30_pre_hospitalisation,
    dealershipDetails: Text.dealership_details,
    declinedChargedHigherPremium: Text.declined_charged_higher_premium,
    deductibleApplicable5Percent: Text.deductible_applicable_5_percent,
    deductibleTheftDisaster: Text.deductible_theft_disaster,
    detailsInsuredAadhar: Text.details_insured_aadhar,
    diabetes: Text.diabetes,
    diabetesHighBloodPressure: Text.diabetes_high_blood_pressure,
    diabetesPlaceholder: Text.diabetes_placeholder,
    diagnosedHospitalized: Text.diagnosed_hospitalized,
    didNotReceiveCode: Text.did_not_receive_code,
    differentPermanentAddress: Text.different_permanent_address,
    diploma: Text.diploma,
    disease2Placeholder: Text.disease2_placeholder,
    diseasePlaceholder: Text.disease_placeholder,
    disordersEndocrine: Text.disorders_endocrine,
    dividerOR: Text.divider_or,
    doctor: Text.doctor,
    done: Text.done,
    doneDiagnosis: Text.done_diagnosis,
    dontHaveAccount: Text.dont_have_account,
    dontHaveAnyThese: Text.dont_have_any_these,
    drugsNarcotics: Text.drugs_narcotics,
    earnings: Text.earnings,
    easyAsABC: Text.easy_as_a_b_c,
    eligible3000RoomRent: Text.eligible_3000_room_rent,
    eligible6000ICU: Text.eligible_6000_ICU,
    email: Text.email,
    email1: Text.email1,
    emailedInstructionsActivatePolicy: Text.emailed_instructions_activate_policy,
    endToEndCoverage: Text.end_to_end_coverage,
    enjoyCashlessFacility: Text.enjoy_cashless_facility,
    ensureDetailsAreCorrect: Text.ensure_details_are_correct,
    enterHomeAddress: Text.enter_home_address,
    enterHomeAddressAvailability: Text.enter_home_address_availability,
    enterMobileNumber: Text.enter_mobile_number,
    enterRegisteredNumber: Text.enter_registered_number,
    enterTheCodeSentTo: Text.enter_the_code_sent_to,
    enterValidPan: Text.enter_valid_pan,
    estimateApproved: Text.estimate_approved,
    everyYearDontClaim: Text.every_year_dont_claim,
    expensesArisingAlcohol: Text.expenses_arising_alcohol,
    expensesAttributable: Text.expenses_attributable,
    expensesContactLenses: Text.expenses_contact_lenses,
    expensesIncurredAIDS: Text.expenses_incurred_aids,
    experiencedSymptoms: Text.experienced_symptoms,
    failed: Text.failed,
    familyFamilyToffee: Text.family__family_toffee,
    faqForToffee: Text.faq_for_toffee,
    faqs: Text.faqs,
    father: Text.father,
    featuredTitle: Text.featured_title,
    feelUncertainDiagnosis: Text.feel_uncertain_diagnosis,
    female: Text.female,
    fiftyLakh: Text.fifty_lakh,
    filedClaim: Text.filed_claim,
    firstChildDOB: Text.first_child_dob,
    fiveLakh: Text.five_lakh,
    flexibleSumInsured: Text.flexible_sum_insured,
    forHowManyYearsConsumingAlcohol: Text.for_how_many_years_consuming_alcohol,
    formSixty: Text.form_16,
    fourLakh: Text.four_lakh,
    fourToTenLakhs: Text.four_to_ten_lakhs,
    freeAnnualHealthCheckup: Text.free_annual_health_checkup,
    freeSecondOpinion: Text.free_second_opinion,
    frequencyConsumption: Text.frequency_consumption,
    frequently: Text.frequently,
    fullName: Text.full_name,
    fullNameLabel: Text.full_name,
    gastroIntestinalDisorders: Text.gastro_intestinal_disorders,
    gender: Text.gender,
    genitourinaryDisorders: Text.genitourinary_disorders,
    genitourinaryPlaceholder: Text.genitourinary_placeholder,
    getBack3Lakhs: Text.get_back_3_lakhs,
    getStarted: Text.get_started,
    getToffee: Text.get_toffee,
    goBack: "Go Back",
    goToHome: Text.go_to_home,
    gotBackSubtitle: Text.got_back_subtitle,
    gotBackTitle: Text.got_back_title,
    govt: Text.govt,
    graduate: Text.graduate,
    greatQuestion: Text.great_question,
    gstDetails: Text.gst_details,
    gutka: Text.gutka,
    gynecologicalComplications: Text.gynecologicalC_complications,
    hassleFreeAccessible: Text.hassle_free_accessible,
    hassleFreeProcess: Text.hassle_free_process,
    havePersonInsuredFacedSituation: Text.have_person_insured_faced_situation,
    havePersonInsuredSufferedDisease: Text.have_person_insured_suffered_disease,
    health: Text.health,
    healthDeclaration: Text.health_declaration,
    healthDetails: Text.kit_three,
    healthInsurance3Lakhs: Text.health_insurance_3_lakhs,
    healthInsuranceLifeInsurance: Text.health_insurance_life_insurance,
    healthLifeHousehold: Text.health_life_household,
    heightFeet: Text.height_feet,
    heightInches: Text.height_inches,
    helloFromToffee: Text.hello_from_toffee,
    help: Text.help,
    hepatitisBorC: Text.hepatitis_b_or_c,
    highPremiumPlaceholder: Text.high_premium_placeholder,
    highestEducation: Text.highest_education,
    historyOfConviction: Text.history_of_conviction,
    hivPlaceholder: Text.hiv_placeholder,
    home: Text.home,
    homeAddressShouldMatch: Text.home_address_should_match,
    homeInsuranceDoesntCoverJewellery: Text.home_insurance_doesnt_cover_jewellery,
    homeUtilityAppliances: Text.home_utility_appliances,
    hospitalisation3Lakhs: Text.hospitalisation_3_lakhs,
    hospitalisationAtHome: Text.hospitalisation_at_home,
    householdInsurance1Lakh: Text.household_insurance_1_lakh,
    howSubtitle: Text.how_subtitle,
    howTitle: Text.how_title,
    howToSellToffee: Text.how_to_sell_toffee,
    hurrayDetailsVerified: Text.hurray_details_verified,
    husband: Text.husband,
    hypertension: Text.hypertension,
    hypertensionPlaceholder: Text.hypertension_placeholder,
    icuCharges: Text.icu_charges,
    illnessImpairment: Text.illness_impairment,
    illnessInjuryPlaceholder: Text.illness_injury_placeholder,
    incentives: Text.incentives,
    includingGST: Text.including_gst,
    income: Text.income,
    incomeInLakhs: Text.income_in_lakhs,
    incomeProof: Text.income_proof,
    increaseDecreaseSumInsured: Text.increase_decrease_sum_insured,
    indianCitizenDeclaration: Text.indian_citizen_declaration,
    inheritance: Text.inheritance,
    insuredTitle: Text.insured_title,
    isPromoter: Text.is_promoter,
    jewelleryCash: Text.jewellery_cash,
    jointPain: Text.joint_pain,
    justLikeOurPlan: Text.just_like_our_plan,
    keepAllOnline: Text.keep_all_online,
    knowMore: Text.know_more,
    knowYouBetter: Text.know_you_better,
    landmark: Text.landmark,
    lastThreeYears: Text.last_three_years,
    lawyer: Text.lawyer,
    lessThan5: Text.less_than_5,
    letsGetStarted: Text.lets_get_started,
    life: Text.life,
    lifeCover25Lakhs: Text.life_cover_25_lakhs,
    lifeInsurance25Lakhs: Text.life_insurance_25_lakhs,
    lifelongRenewability: Text.lifelong_renewability,
    lifestyleDetails: Text.lifestyle_details,
    liquor: Text.liquor,
    liverDisorders: Text.liver_disorders,
    liverPlaceholder: Text.liver_placeholder,
    logInToffee: Text.log_in_toffee,
    loginErrorTitle: Text.login_error_title,
    loginSubTitleFirst: Text.login_subtitle_first,
    loginSubTitleSecond: Text.login_subtitle_second,
    loginSubTitleThird: Text.login_subtitle_third,
    logout: Text.logout,
    lossDamage1Lakhs: Text.loss_damage_1_lakhs,
    lossDamageAccountDisasters: Text.loss_Damage_account_disasters,
    lossDamageMobilePhones: Text.loss_damage_mobile_phones,
    lossDamagePersonalBaggage: Text.loss_damage_personal_baggage,
    lossTheftDuringRiot: Text.loss_theft_during_riot,
    male: Text.male,
    maturitySurvivalBenefit: Text.maturity_survival_benefit,
    medicalExpensesOPD: Text.medical_expenses_OPD,
    message: Text.message,
    mobileNumber: Text.mobile_number,
    mobilePolicy: Text.mobile_policy,
    moneyLive75: Text.money_live_75,
    month970: Text.month_970,
    moreThan10: Text.more_than_10,
    mostImportantInsurance: Text.most_important_insurance,
    mother: Text.mother,
    mr: Text.mr,
    ms: Text.ms,
    name: Text.name,
    nameMoreThanThreeCharacters: Text.name_more_than_three_characters,
    nameOneWord: Text.name_one_word,
    nameOneWord2: Text.name_one_word2,
    nameOfOrganization: Text.name_of_organization,
    neftDetails: Text.neft_details,
    nervousDisorders: Text.nervous_disorders,
    nervousPlaceholder: Text.nervous_placeholder,
    next: Text.next,
    nishitaDhawan: Text.nishita_dhawan,
    nishitaDhawanTestimonial: Text.nishita_dhawan_testimonial,
    no: Text.no,
    noClaimBonus: Text.no_claim_bonus,
    noDetailsAvailable: Text.no_details_available,
    retrievingDetails: Text.retrieving_details,
    noOfFamilyMembers: Text.no_of_family_members,
    nomineeFullName: Text.nominee_full_name,
    nomineeRelation: Text.nominee_relation,
    nomineeIsRequired: Text.nominee_is_required,
    noneAbove: Text.none_above,
    notAvailable: "Not Avialable",
    notJustHospitalisation: Text.not_just_hospitalisation,
    notificationDetails: "Notification Details",
    nowWhat: Text.now_what,
    occasionally: Text.occasionally,
    onToffeeWebsite: Text.on_toffee_website,
    onceBuyPlan: Text.once_buy_plan,
    oneComboPlan: Text.one_combo_plan,
    oneLakh: Text.one_lakh,
    ongoingClaims: Text.ongoing_claims,
    opdExpenses: Text.opd_expenses,
    optForEnach: Text.opt_for_enach,
    opticalDentalCare: Text.optical_dental_care,
    or: Text.or,
    orderMarketingKits: Text.order_marketing_kits,
    orders: Text.orders,
    organDonorCover: Text.organ_donor_cover,
    organizationType: Text.organization_type,
    originalInvoiceApplicances: Text.original_invoice_applicances,
    other: Text.other,
    others: Text.others,
    overrideNdnc: Text.override_ndnc,
    pabCard: Text.pan_card,
    panMasala: Text.pan_masala,
    panNumber: Text.pan_number,
    paralysis: Text.paralysis,
    partnership: Text.partnership,
    passbook: Text.passbook,
    pay: Text.pay,
    payment: Text.payment,
    paymentFailed: Text.payment_failed,
    paymentTransferred: Text.payment_transferred,
    paymentType: Text.payment_type,
    ped1Placeholder: Text.ped_1_placeholder,
    peopleInsured: Text.people_insured,
    peopleLoveToffee: Text.people_love_toffee,
    percent10AnnualDepreciation: Text.percent10_annual_depreciation,
    percent99: Text.percent_99,
    personalAccidentCoverage: Text.personal_accident_coverage,
    phoneNumberVerification: Text.phone_number_verification,
    photograph: Text.photograph,
    pickFromGallery: Text.pick_from_gallery,
    pickYourNominee: Text.pick_your_nominee,
    pin: Text.pincode,
    planDetailSubmitted: Text.plan_detail_submitted,
    pleaseAnswerQuickQuestion: Text.please_answer_quick_question,
    pleaseEnsure: Text.please_ensure,
    pleaseFillChild1BasicDetails: Text.please_fill_child1_basic_details,
    pleaseFillChild2BasicDetails: Text.please_fill_child2_basic_details,
    plus170: Text.plus_170,
    policies: Text.policies,
    policyCoversBelongings: Text.policy_covers_belongings,
    policyHolder: Text.policy_holder,
    policyHolderInfo: Text.policy_holder_info,
    policySoldOnCredit: Text.policy_sold_on_credit,
    politicallyExposed: Text.politically_exposed,
    politicallyExposedDetails: Text.politically_exposed_details,
    postGraduate: Text.post_graduate,
    posters: Text.posters,
    pregnancyRelatedExpenses: Text.pregnancy_related_expenses,
    pregnantPlaceholder: Text.pregnant_placeholder,
    pregnantPresent: Text.pregnant_present,
    prevInsurerPlaceholder: Text.prev_insurer_placeholder,
    premiumFrequency: Text.premium_frequency,
    proceedToPayment: Text.proceed_to_payment,
    productDetail: "Product Detail",
    professional: Text.professional,
    profile: Text.profile,
    proprietor: Text.proprietor,
    provideEducationOccupationDetails: Text.provide_education_occupation_details,
    provideHealthHistory: Text.provide_health_history,
    psychiatric: Text.psychiatric,
    psychiatricPlaceholder: Text.psychiatric_placeholder,
    pvtLtd: Text.pvt_ltd,
    publicLtd: Text.public_ltd,
    qnaSubtitle: Text.qna_subtitle,
    qnaTitle: Text.qna_title,
    quarantined: Text.quarantined,
    quickHealthDeclaration: Text.quick_health_declaration,
    quotation: Text.quotation,
    quotationCreated: Text.quotation_created,
    quotationCreatedAndSent: Text.quotation_created_and_sent,
    rank: Text.rank,
    ranking: Text.ranking,
    register: Text.register,
    reimburseForMedicalExpenses: Text.reimburse_for_medical_expenses,
    reimbursedExpenses: Text.reimbursed_expenses,
    renewInsuranceUntil75Years: Text.renew_insurance_until_75_years,
    renewPolicy: Text.renew_policy,
    renewal75Years: Text.renewal_75_years,
    requestMoreCredit: Text.request_more_credit,
    requestMoreCreditOf: Text.request_more_credit_of,
    requiredCyclePhoto: Text.required_cycle_photo,
    resend: Text.resend,
    respiratoryDisorders: Text.respiratory_disorders,
    retired: Text.retired,
    riotsTerrorismDamage: Text.riots_terrorism_damage,
    roomRent: Text.room_rent,
    ruppee970: Text.rupee_970,
    salaried: Text.salaried,
    salary: Text.salary,
    saleOfAssets: Text.sale_of_assets,
    sauravSharma: Text.saurav_sharma,
    sauravSharmaTestimonial: Text.saurav_sharma_testimonial,
    secondChildDOB: Text.second_child_dob,
    seeAll: Text.see_all,
    seeMoreBenefits: Text.see_more_benefits,
    seePlan: Text.see_plan,
    selectFamilyMembers: Text.select_family_members,
    selectYourPlan: Text.select_your_plan,
    self: Text.self,
    selfEmployed: Text.self_employed,
    selfInflictedInjury: Text.self_inflicted_injury,
    sendOtp: Text.send_otp,
    sendPolicy: Text.send_policy,
    sendingOtp: Text.sending_otp,
    sentCardOnMail: Text.sent_card_on_mail,
    settleClaims: Text.settle_claims,
    settlementRate: Text.settlement_rate,
    seventyFiveLakh: Text.seventy_five_lakh,
    shareSubtitle: Text.share_subtitle,
    shareTitle: Text.share_title,
    signIn: Text.sign_in,
    signUp: Text.sign_up,
    signingUpSuperQuick: Text.signing_up_super_quick,
    simpleFastEffective: Text.simple_fast_effective,
    sinceOptedAddFamilyMembers: Text.since_opted_add_family_members,
    sinceYouHave: "since_you_have",
    sister: Text.sister,
    sixToTen: Text.six_to_ten,
    sixmonthSalary: Text.six_month_salary,
    socialLoginError: Text.social_login_error,
    society: Text.society,
    someThingWentWrong: Text.some_thing_went_wrong,
    someoneYouCanTrust: Text.someone_you_can_trust,
    son: Text.son,
    sorryPolicyNotIssue: Text.sorry_policy_not_issue,
    sourceIncome: Text.source_income,
    specifyDetails: Text.specify_details,
    spouse: Text.spouse,
    spouseFullName: Text.spouse_full_name,
    spouseDOB: Text.spouse_dob,
    standardDepreciation: Text.standard_depreciation,
    startingFrom: Text.starting_from,
    startsFrom: Text.starts_from,
    state: Text.state,
    structureHome: Text.structure_home,
    submitChild1Info: Text.submit_child1_info,
    submitChild2Info: Text.submit_child2_info,
    submitSpouseInfo: Text.submit_spouse_info,
    suicide1Year: Text.suicide_1_year,
    sumAssured: Text.sum_assured,
    sumAssuredHealth: Keys.sumAssuredHealth,
    sumAssuredHouseHold: Text.sum_assured_house_hold,
    sumAssuredLife: Keys.sumAssuredLife,
    superfastSignUp: Text.super_fast_sign_up,
    support: Text.support,
    surgeryMedPlaceholder: Text.surgery_med_placeholder,
    takePartHazardousSports: Text.take_part_hazardous_sports,
    talkToUs: Text.talk_to_us,
    tarachandMaurya: Text.tarachand_maurya,
    tarachandMauryaTestimonial: Text.tarachand_maurya_testimonial,
    tenToFifteenLakhs: Text.ten_to_fifteen_lakhs,
    termLifeInsurancePays: Text.term_life_insurance_pays,
    testedPositive: Text.tested_positive,
    theToffeePlan: Text.the_toffee_plan,
    theft1Lakhs: Text.theft_1_lakhs,
    theftHome1Lakh: Text.theft_home_1_lakh,
    threeDays: Text.three_days,
    threeInOne: Text.three_in_one,
    threeInOnePlan: Text.three_in_one_plan,
    threeLakh: Text.three_lakh,
    threeMonthSalary: Text.three_month_salary,
    threeSimpleSteps: Text.three_simple_steps,
    threeToTenLakhs: Text.three_to_ten_lakhs,
    thriceCoverage: Text.thrice_coverage,
    title: Text.title,
    tobaccoConsumptionDetails: Text.tobacco_consumption_details,
    tobaccoProducts: Text.tobacco_products,
    toffee: Text.toffee,
    toffeeBackedTrustedNames: Text.toffee_backed_trusted_names,
    toffeeID: Text.toffee_ID,
    toffeeInsurance: Text.toffee_insurance,
    toffeeInsuranceCoversPandemic: Text.toffee_insurance_covers_pandemic,
    toffeeInsuranceDifferent: Text.toffee_insurance_different,
    toffeeInsuranceSimplified: Text.tofee_insurance_simplified,
    toffeeLooksOut: Text.toffee_looks_out,
    toffeePartnerProgram: Text.toffee_partner_program,
    toffeePlan: Text.toffee_plan,
    toffeeTakesHeadache: Text.toffee_takes_headache,
    totalEarnings: Text.total_earnings,
    transactionId: Text.transaction_id,
    travelHistory: Text.travel_history,
    treatmentArisingPregnancy: Text.treatment_arising_pregnancy,
    treatmentRelatingInfertility: Text.treatment_relating_infertility,
    treatmentUnderAyurveda: Text.treatment_under_ayurveda,
    tripeshGoel: Text.tripesh_goel,
    tripeshGoelTestimonial: Text.tripesh_goel_testimonial,
    trust: Text.trust,
    tumorGrowth: Text.tumor_growth,
    tutorialVideos: Text.tutorial_videos,
    seniorSecondary: Text.senior_secondary,
    twoLakh: Text.two_lakh,
    twoLakhs: Text.two_lakhs,
    typeOfConstruction: Text.type_of_construction,
    unablePayPremium: Text.unable_pay_premium,
    underHealthInsuranceCoverage: Text.under_health_insurance_coverage,
    unlikeTypicalInsurancePolicies: Text.unlike_typical_insurance_policies,
    update: Text.update,
    uploadAddressProof: Text.upload_address_proof,
    uploadDocuments: Text.upload_documents,
    uploadPanCard: Text.upload_pan_card,
    uploadScannedCopies: Text.upload_scanned_copies,
    uploadThreeMonthsSalarySlip: Text.upload_3_months_salary_slip,
    uploadYourPhoto: Text.upload_your_photo,
    userAlreadyExist: Text.user_already_exist,
    userRegisteredSuccessfully: Text.user_registered_successfully,
    verificationFailed: Text.verification_failed,
    verify: Text.verify,
    verifyDetails: Text.verify_details,
    verifyMobile: Text.verify_mobile,
    versionToffeeInsurance: Text.version_toffee_insurance,
    viewPolicy: Text.view_policy,
    vitroFertilization: Text.vitro_fertilization,
    vivekRaju: Text.vivek_raju,
    vivekRajuTestimonial: Text.vivek_raju_testimonial,
    waitingPeriod30: Text.waiting_period_30,
    waitingPeriod4Years: Text.waiting_period_4_years,
    waiverPremiumPermanentDisability: Text.waiver_premium_permanent_disability,
    wantSomeoneTalk: Text.want_someone_talk,
    warRiotStrike: Text.war_riot_strike,
    warRiotStrikeHospitalisation: Text.war_riot_strike_hospitalisation,
    watchTutorialVideos: Text.watch_tutorial_videos,
    weNeedFewDocuments: Text.we_need_few_documents,
    wearTear: Text.wear_tear,
    weightKilograms: Text.weight_kilograms,
    whatCovered: Text.what_covered,
    whatIsToffee: Text.what_is_toffee,
    whatNotCovered: Text.what_not_covered,
    whatToffeeInsurance: Text.what_toffee_insurance,
    whatsApp: Text.whats_app,
    whichAlcoholProducts: Text.which_alcohol_products,
    whichOfYour: Text.which_of_your,
    whyCalledToffee: Text.why_called_toffee,
    whySellToffee: Text.why_sell_toffee,
    whyToffeeSubtitle: Text.why_toffee_subtitle,
    whyToffeeTitle: Text.why_toffee_title,
    wife: Text.wife,
    wine: Text.wine,
    yay: Text.yay,
    yearOfConstruction: Text.year_of_cunstruction,
    years2WaitingPeriod: Text.years_2_waiting_period,
    years4WaitingPeriod: Text.years_4_waiting_period,
    yes: Text.yes,
    youHitSubmit: Text.you_hit_submit,
    yourProfession: Text.your_profession,
    zeroPaperwork: Text.zero_paperwork,
    single: Text.single,
    married: Text.married,
    divorced: Text.divorced,
    widowed: Text.widowed,
    haveFiveKilograms: Text.have_five_kilograms,
    totalWeightGain: Text.total_weight_gain,
    reasonWeightGain: Text.reason_weight_gain,
    maritalStatus: Text.marital_status,
    fatherName: Text.father_name,
    personalDetails: Text.personal_details,
    pleaseFill: Text.please_fill,
    availability: Text.availability,
    premium: Text.premium,
    toffeePlanQuotation: Text.toffee_plan_quotation,
    toffeePlanSubject: Text.toffee_plan_subject,
    byProvidingOtp: Text.by_providing_otp,

    privacyPolicyPointOneHeading: Text.privacy_policy_point_one_heading,
    privacyPolicyPointTwoHeading: Text.privacy_policy_point_two_heading,
    privacyPolicyPointThreeHeading: Text.privacy_policy_point_three_heading,
    privacyPolicyPointFourHeading: Text.privacy_policy_point_four_heading,
    privacyPolicyPointFiveHeading: Text.privacy_policy_point_five_heading,
    privacyPolicyPointSixHeading: Text.privacy_policy_point_six_heading,
    privacyPolicyPointSevenHeading: Text.privacy_policy_point_seven_heading,
    privacyPolicyPointEightHeading: Text.privacy_policy_point_eight_heading,
    privacyPolicyPointNineHeading: Text.privacy_policy_point_nine_heading,
    privacyPolicyPointTenHeading: Text.privacy_policy_point_ten_heading,
    privacyPolicyPointElevenHeading: Text.privacy_policy_point_eleven_heading,

    privacyPolicyPointOne: Text.privacy_policy_point_one,
    privacyPolicyPointTwo: Text.privacy_policy_point_two,
    privacyPolicyPointThree: Text.privacy_policy_point_three,
    privacyPolicyPointFour: Text.privacy_policy_point_four,
    privacyPolicyPointFive: Text.privacy_policy_point_five,
    privacyPolicyPointSix: Text.privacy_policy_point_six,
    privacyPolicyPointSeven: Text.privacy_policy_point_seven,

    privacyPolicyPointSevenSubOne: Text.privacy_policy_point_seven_sub_one,
    privacyPolicyPointSevenSubTwo: Text.privacy_policy_point_seven_sub_two,
    privacyPolicyPointSevenSubThree: Text.privacy_policy_point_seven_sub_three,
    privacyPolicyPointSevenSubFour: Text.privacy_policy_point_seven_sub_four,
    privacyPolicyPointSevenSubFive: Text.privacy_policy_point_seven_sub_five,
    privacyPolicyPointSevenSubSix: Text.privacy_policy_point_seven_sub_six,
    privacyPolicyPointSevenSubSeven: Text.privacy_policy_point_seven_sub_seven,
    privacyPolicyPointSevenSubEight: Text.privacy_policy_point_seven_sub_eight,
    privacyPolicyPointSevenSubNine: Text.privacy_policy_point_seven_sub_nine,

    privacyPolicyPointEight: Text.privacy_policy_point_eight,
    privacyPolicyPointNine: Text.privacy_policy_point_nine,
    privacyPolicyPointTen: Text.privacy_policy_point_ten,
    privacyPolicyPointEleven: Text.privacy_policy_point_eleven,
    privacyPolicyPointTwelve: Text.privacy_policy_point_twelve,
    privacyPolicyPointThirteen: Text.privacy_policy_point_thirteen,
    privacyPolicyPointThirteenName: Text.privacy_policy_point_thirteen_name,
    privacyPolicyPointThirteenEmail: Text.privacy_policy_point_thirteen_email,

    termsAndConditionsPointOneHeading:
    Text.terms_and_conditions_point_one_heading,
    termsAndConditionsPointTwoHeading:
    Text.terms_and_conditions_point_two_heading,
    termsAndConditionsPointThreeHeading:
    Text.terms_and_conditions_point_three_heading,
    termsAndConditionsPointFourHeading:
    Text.terms_and_conditions_point_four_heading,
    termsAndConditionsPointFiveHeading:
    Text.terms_and_conditions_point_five_heading,
    termsAndConditionsPointSixHeading:
    Text.terms_and_conditions_point_six_heading,
    termsAndConditionsPointSevenHeading:
    Text.terms_and_conditions_point_seven_heading,
    termsAndConditionsPointEightHeading:
    Text.terms_and_conditions_point_eight_heading,
    termsAndConditionsPointNineHeading:
    Text.terms_and_conditions_point_nine_heading,
    termsAndConditionsPointTenHeading:
    Text.terms_and_conditions_point_ten_heading,
    termsAndConditionsPointElevenHeading:
    Text.terms_and_conditions_point_eleven_heading,
    termsAndConditionsPointTwelveHeading:
    Text.terms_and_conditions_point_twelve_heading,
    termsAndConditionsPointThirteenHeading:
    Text.terms_and_conditions_point_thirteen_heading,
    termsAndConditionsPointFourteenHeading:
    Text.terms_and_conditions_point_fourteen_heading,
    termsAndConditionsPointFifteenHeading:
    Text.terms_and_conditions_point_fifteen_heading,
    termsAndConditionsPointSixteenHeading:
    Text.terms_and_conditions_point_sixteen_heading,
    termsAndConditionsTitleAml: Text.terms_and_conditions_title_AML,

    termsAndConditionsPointOne: Text.terms_and_conditions_point_one,
    termsAndConditionsPointOneTwo: Text.terms_and_conditions_point_one_two,
    termsAndConditionsPointOneThree: Text.terms_and_conditions_point_one_three,
    termsAndConditionsPointTwo: Text.terms_and_conditions_point_two,
    termsAndConditionsPointThree: Text.terms_and_conditions_point_three,
    termsAndConditionsPointFour: Text.terms_and_conditions_point_four,
    termsAndConditionsPointFive: Text.terms_and_conditions_point_five,
    termsAndConditionsPointSix: Text.terms_and_conditions_point_six,
    termsAndConditionsPointSixTwo: Text.terms_and_conditions_point_six_two,
    termsAndConditionsPointSixThree: Text.terms_and_conditions_point_six_three,
    termsAndConditionsPointSixFour: Text.terms_and_conditions_point_six_four,
    termsAndConditionsPointSixFive: Text.terms_and_conditions_point_six_five,
    termsAndConditionsPointSeven: Text.terms_and_conditions_point_seven,
    termsAndConditionsPointSevenTwo: Text.terms_and_conditions_point_seven_two,
    termsAndConditionsPointSevenThree:
    Text.terms_and_conditions_point_seven_three,
    termsAndConditionsPointSevenFour: Text.terms_and_conditions_point_seven_four,
    termsAndConditionsPointSevenFive: Text.terms_and_conditions_point_seven_five,
    termsAndConditionsPointSevenSix: Text.terms_and_conditions_point_seven_six,
    termsAndConditionsPointSevenSeven:
    Text.terms_and_conditions_point_seven_seven,
    termsAndConditionsPointSevenEight:
    Text.terms_and_conditions_point_seven_eight,
    termsAndConditionsPointSevenNine: Text.terms_and_conditions_point_seven_nine,
    termsAndConditionsPointSevenTen: Text.terms_and_conditions_point_seven_ten,
    termsAndConditionsPointEight: Text.terms_and_conditions_point_eight,
    termsAndConditionsPointNine: Text.terms_and_conditions_point_nine,
    termsAndConditionsPointTen: Text.terms_and_conditions_point_ten,
    termsAndConditionsPointTenTwo: Text.terms_and_conditions_point_ten_two,
    termsAndConditionsPointTenThree: Text.terms_and_conditions_point_ten_three,
    termsAndConditionsPointTenFour: Text.terms_and_conditions_point_ten_four,
    termsAndConditionsPointTenFive: Text.terms_and_conditions_point_ten_five,
    termsAndConditionsPointTenSix: Text.terms_and_conditions_point_ten_six,
    termsAndConditionsPointTenSeven: Text.terms_and_conditions_point_ten_seven,
    termsAndConditionsPointTenEight: Text.terms_and_conditions_point_ten_eight,
    termsAndConditionsPointTenNine: Text.terms_and_conditions_point_ten_nine,
    termsAndConditionsPointEleven: Text.terms_and_conditions_point_eleven,
    termsAndConditionsPointElevenTwo: Text.terms_and_conditions_point_eleven_two,
    termsAndConditionsPointElevenThree:
    Text.terms_and_conditions_point_eleven_three,
    termsAndConditionsPointElevenFour:
    Text.terms_and_conditions_point_eleven_four,
    termsAndConditionsPointTwelve: Text.terms_and_conditions_point_twelve,
    termsAndConditionsPointThirteen: Text.terms_and_conditions_point_thirteen,
    termsAndConditionsPointFourteen: Text.terms_and_conditions_point_fourteen,
    termsAndConditionsPointFifteen: Text.terms_and_conditions_point_fifteen,
    termsAndConditionsPointSixteen: Text.terms_and_conditions_point_sixteen,
    termsAndConditionsPointSeventeen: Text.terms_and_conditions_point_seventeen,
    termsAndConditionsPointEighteen: Text.terms_and_conditions_point_eightteen,
    termsAndConditionsPointNineteen: Text.terms_and_conditions_point_nineteen,
    termsAndConditionsPointNineteenTwo:
    Text.terms_and_conditions_point_nineteen_two,
    policyTerm: Text.policy_term,
    notRequired: "Not Required",
    selectBank: Text.select_bank,

    lessThanThirty: Text.less_than_thirty,
    thirtyToOneHundredEighty: Text.thirty_to_one_hundred_eighty,
    moreThanOneHundredEighty: Text.more_than_one_hundred_eighty,
    tobaccoOverdoseMessage: Text.tobacco_overdose_message,
    alcoholOverdoseMessage: Text.alcohol_overdose_message,

    relation: Text.relation,
    ageTimeOfDemise: Text.age_time_of_demise,
    causeOfDeath: Text.cause_of_death,
    ageIfAlive: Text.age_if_alive,
    specifiedIllness: Text.specified_illness,
    dailyAlcoholConsumptionFrequency: Text.daily_alcohol_consumption_frequency,

    haveOtherInsurance: Text.have_other_insurance,
    policyType: Text.policy_type,
    traditional: Text.traditional,
    unitLinked: Text.unit_linked,
    pension: Text.pension,
    termPlan: Text.term_plan,
    commencementYear: Text.commencement_year,
    twentySeventeen: Text.twenty_seventeen,
    twentyEighteen: Text.twenty_eighteen,
    twentyNineteen: Text.twenty_nineteen,
    twentyTwenty: Text.twenty_twenty,
    sumInsuredExist: Text.sum_insured_exist,
    policyStatus: Text.policy_status,
    standard: Text.standard,
    ratedUp: Text.rated_up,
    declined: Text.declined,
    postponed: Text.postponed,
    lapsed: Text.lapsed,
    rejected: Text.rejected,
    nameOfLifeAssured: Text.name_of_life_assured,
    nameOfCompany: Text.name_of_company,
    lessThanFourLacs: Text.less_than_four_lacs,
    lessThanThreeLacs: Text.less_than_three_lacs,
    indiaFirstCovid: Text.india_first_covid,
    annualIncome: Text.annual_income,

    grandFather: Text.grand_father,
    grandMother: Text.grand_mother,
    grandSon: Text.grand_son,
    grandDaughter: Text.grand_daughter,

    armForce: Text.arm_force,
    armForceDetails: Text.arm_force_details,
    departmentDivision: Text.department_division,
    lastMedical: Text.last_medical,

    niece: Text.niece,
    uncle: Text.uncle,
    nephew: Text.nephew,
    sisterInLaw: Text.sister_in_law,
    highSecondary: Text.high_secondary,
    belowHighSecondary: Text.below_high_secondary,

    premiumIncludingGst: Text.premium_including_gst,
    year: Text.year,
    save: Text.save,
    annually: Text.annually,
    premiumDetail: Text.premium_detail,
    indiaFirstLifePlan: Text.india_first_life_plan,
    lifePremium: Text.life_premium,
    tataAigMedicare: Text.tata_aig_medicare,
    healthPremium: Text.health_premium,
    tataAigHome: Text.tata_aig_home,
    householdPremium: Text.household_premium,
    month: Text.month,
    electronicInsurance: Text.electronic_insurance,
    eiaAccountNumber: Text.eia_account_number,
    lifeInsuranceOtherThanIpru: Text.life_insurance_other_than_ipru,
    insuranceCompany: Text.insurance_company,
    entireLifeCoverOrSumAssured: Text.entire_life_cover_or_sum_assured,
    lifeInsuranceDetail: Text.life_insurance_detail,
    underMarriedWomen: Text.under_married_women,
    addTrustee: Text.add_trustee,
    addBeneficiary: Text.add_beneficiary,
    taxResidentIndia: Text.tax_resident_india,
    birthCountryIndia: Text.birth_country_india,
    haveTenKilograms: Text.have_ten_kilograms,
    anyOtherDisorder: Text.any_other_disorder,
    selectHigherLifeSumInsured: Text.select_higher_life_sum_insured,
    jewellery: Text.jewellery,
    importExport: Text.import_export,
    shipping: Text.shipping,
    scrapDealing: Text.scrap_dealing,
    realEstate: Text.real_estate,
    stockBroking: Text.stock_broking,
    industryType: Text.industry_type,
    aegonReligare: Text.aegon_religare,
    avivaLife: Text.aviva_life,
    bajajAllianz: Text.bajaj_allianz,
    bhartiAxa: Text.bharti_axa,
    birlaSun: Text.birla_sun,
    canaraHsbc: Text.canara_hsbc,
    dlfPramerica: Text.dlf_pramerica,
    edelwiessTokio: Text.edelwiess_tokio,
    futureGenerali: Text.future_generali,
    hdfcStandard: Text.hdfc_standard,
    idbiFortis: Text.idbi_fortis,
    indiaFirst: Text.india_first,
    ingVysya: Text.ing_vysya,
    kotakLife: Text.kotak_life,
    maxNew: Text.max_new,
    metLifeIndia: Text.metLife_india,
    postalLife: Text.postal_life,
    relianceLife: Text.reliance_life,
    saharaIndia: Text.sahara_india,
    sbiLife: Text.sbi_life,
    shriramLife: Text.shriram_life,
    starUnion: Text.star_union,
    tataAig: Text.tata_aig,
    unitTrust: Text.unit_trust,
    tataAia: Text.tata_aia,
    invalidMessageForNri: Text.invalid_message_for_nri,
    organizationTypeSelf: Text.organization_type_self,
    invalidSumAssured: Text.invalid_sum_assured,
    lifeInsuranceDetailText: Text.life_insurance_detail_text,
    eiaRepository: Text.eia_repository,
    ndml: Text.ndml,
    cirl: Text.cirl,
    karvy: Text.karvy,
    cams: Text.cams,
    iciciSurgery: Text.icici_surgery,
    engaged: Text.engaged,
    sourceIncomeDetails: Text.source_income_details,
    occupationDescription: Text.occupation_description,
    organizationTypeDetails: Text.organization_type_details,

    policyDetails: Text.policy_details,
    paymentTenure: Text.payment_tenure,
    limitedPayFor: Text.limited_pay_for,
    paymentFrequency: Text.payment_frequency,
    deathBenefitPayout: Text.death_benefit_payout,
    chooseSalutation: Text.choose_salutation,
    mrs: Text.mrs,
    dr: Text.dr,
    nomineeGender: Text.nominee_gender,
    natureOfDuties: Text.nature_of_duties,
    coveredPnbLifeSuraksha: Text.covered_pnb_life_suraksha,
    postponedPnb: Text.postponed_pnb,
    areYouPregnant: Text.are_you_pregnant,

    returnOfPremium: Text.return_of_premium,
    percentageOfPremium: Text.percentage_of_premium,
    tenPercent: Text.ten_percent,
    twentyPercent: Text.twenty_percent,
    thirtyPercent: Text.thirty_percent,
    fortyPercent: Text.forty_percent,
    fiftyPercent: Text.fifty_percent,
    hundredPercent: Text.hundred_percent,
    criticalIllnessCover: Text.critical_illness_cover,
    criticalIllnessSumAssured: Text.critical_illness_sum_assured,
    policyTenure: Text.policy_tenure,
    typeOfPremiumPayment: Text.type_of_premium_payment,
    limitedPremium: Text.limited_premium,
    singlePremium: Text.single_premium,
    premiumPaymentTerm: Text.premium_payment_term,
    lumpsumPayout: Text.lumpsum_payout,
    lumpsumAndLevelIncome: Text.lumpsum_and_level_income,
    lumpsumAndIncreasingIncome: Text.lumpsum_and_increasing_income,
    lifePlusOption: Text.life_plus_option,


};
