import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import queryString from "query-string";
import NotificationAlert from "react-notification-alert";
import * as actionTypes from "../../../store/actions/actionTypes";
import {Spinner} from "reactstrap";
import {useStyles} from './loginStyles'
import Otp from "../VerifyDetails/CutomerVerification/otp";
import SideSection from "../../../components/sideContent/sideSection";
import {Images} from "../../../constants/images";

const mobileRegex = /^([0]|\+91)?[6789]\d{9}$/;

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onfocusMobile: false,
            active: true,
            error: "",
            redirectUrl: null,
            step: 0,
            otp: "",
            changeNumber: false,
            otpSent: false,
            orderId: new URL(window.location.href).searchParams.get("order_id"),
        };
    }

    toggleClass = () => {
        this.setState({onfocusMobile: true});
    };
    onChange = (e) => {
        this.setState(
            {
                mobile: e.target.value,
            },
            () => {
                if (this.state.mobile.match(mobileRegex)) {
                    this.setState({
                        active: false,
                        error: "",
                    });
                }
            }
        );
    };
    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.mobile.match(mobileRegex)) {
            this.props.sendOtp({
                mobile: this.state.mobile,
                otp_length: 6,
                type: "user",
            });

        } else {
            this.setState({
                error: "Please enter valid mobile number",
            });
        }
    };

    componentDidMount() {
        this.props.checkIfLoggedIn();
        const params = queryString.parse(this.props.location.search);
        this.setState({
            redirectUrl: params.redirect_url || null,
        });
    }

    checkForToken() {
        if (this.props.token)
            if (this.state.redirectUrl !== null) {
                window.location.href = this.state.redirectUrl;
            } else {
                this.props.history.push("/the-toffee-plan/quotation?order_id=" + this.state.orderId);
            }
    }

    notify = (place, title, message, type) => {
        let options = {
            place: place,
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 2,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    showError(error) {
        this.notify("bc", "", error, "danger");
        this.props.clearNotification();
    }

    componentWillMount() {
        window.scrollTo(0, 0);
    }

    sendOtp() {
        this.props.sendOtp({
            mobile: this.props.mobile,
            otp_length: 6,
            type: "user",
        });
    }

    onOtpSubmit = (e) => {
        e.preventDefault()
        this.props.verifyOtp({
            otp: this.state.otp,
            type: "user",
            mobile: this.props.mobile,
        });
    }
    goToPrevStep = () => {
        window.location.reload();
    }


    render() {
        this.props.error && this.showError(this.props.error);
        const {
            classes, otpSent, otpVerified, history, sendingOtp, verifyingOtp
        } = this.props;
        const {
            subTitle, imgLg, formInput, formInputCard, input, numberInputBox, mobileLabel, numberInput,
            countryCode, mobileInput, mobileInputFocus, errorMsg, customBtn, button, btnLoader, mobileLabelOtp,
        } = classes;
        const {step, onfocusMobile, mobile, error, active, otp} = this.state;
        let loginStep = 0

        this.checkForToken();
        (otpSent) && (loginStep = 1)
        otpVerified &&
        history.push("/the-toffee-plan/quotation?order_id=" + this.state.orderId)

        return (
            <>
                <Box id="mainBlock">
                    <SideSection
                        onFocus={onfocusMobile}
                        title={"Safety Verification"}
                        description={
                            <Typography variant={"body1"} className={subTitle}>
                                For safety concerns, we need to validate your mobile number. We’ll send you a <b>One
                                Time Password</b> on this number.
                            </Typography>}
                        image={
                            <img
                                src={Images.familyIllus}
                                className={imgLg}
                                alt=""
                            />
                        }
                        data={
                            <Box className={formInput}>
                                <Box className={formInputCard}>
                                    {step === 0 && loginStep === 0 &&
                                    <form
                                        noValidate
                                        autoComplete="off"
                                        className={input}
                                        onSubmit={this.onSubmit}
                                    >
                                        <Box className={numberInputBox}>
                                            <Typography variant={'body1'} className={mobileLabel}>
                                                Enter your mobile number
                                            </Typography>
                                            <Box className={numberInput}>
                                                <Box className={countryCode}>
                                                    <Typography variant={'body1'}>
                                                        +91
                                                    </Typography>
                                                </Box>
                                                <input
                                                    id="standard-basic"
                                                    placeholder={'Mobile Number'}
                                                    defaultValue={mobile}
                                                    className={
                                                        !onfocusMobile
                                                            ? mobileInput
                                                            : mobileInputFocus
                                                    }
                                                    onChange={this.onChange}
                                                    onFocus={this.toggleClass}
                                                />
                                            </Box>
                                            <Typography variant={'body1'} color={'secondary'}
                                                        className={errorMsg}>
                                                {error}
                                            </Typography>
                                        </Box>

                                        <Box className={customBtn}>
                                            <Button
                                                type="submit"
                                                size="large"
                                                variant={"contained"}
                                                color="secondary"
                                                disabled={active}
                                                className={button}
                                            >
                                                {!sendingOtp ? <b>Next</b> :
                                                    <Spinner className={btnLoader}/>}
                                            </Button>
                                        </Box>
                                    </form>
                                    }
                                    {(loginStep === 1) &&
                                    <form
                                        noValidate
                                        autoComplete="off"
                                        className={input}
                                        onSubmit={this.onOtpSubmit}
                                    >
                                        <Box className={numberInputBox}>
                                            <Typography variant={'body1'} className={mobileLabelOtp}>
                                                Enter OTP sent to mobile number<br/> +91 {mobile}
                                            </Typography>
                                            <Box>
                                                <Otp
                                                    changeNumber={true}
                                                    onChangeNumberClick={(step) => {
                                                        this.goToPrevStep();
                                                    }}
                                                    otpCallBack={(otp) => {
                                                        this.setState({
                                                            otp: otp,
                                                        });
                                                    }}
                                                    resendOtp={() => {
                                                        this.sendOtp();
                                                    }}
                                                />
                                            </Box>
                                        </Box>

                                        <Box className={customBtn}>
                                            <Button
                                                type="submit"
                                                size="large"
                                                variant={"contained"}
                                                color="secondary"
                                                disabled={otp.length !== 6}
                                                className={button}
                                            >
                                                {!verifyingOtp ? <b>PROCEED</b> :
                                                    <Spinner className={btnLoader}/>}
                                            </Button>
                                        </Box>
                                    </form>
                                    }
                                </Box>
                                <Box className="rna-wrapper">
                                    <NotificationAlert ref="notificationAlert"/>
                                </Box>
                            </Box>
                        }
                    />
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        otpSent: state.auth.otpSent,
        sendingOtp: state.auth.sendOtpLoading,
        token: state.auth.token,
        error: state.auth.error,
        otpVerified: state.auth.otpVerified,
        verifyingOtp: state.auth.verifyingOtp,
        mobile: state.auth.mobile,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
        checkIfLoggedIn: () => dispatch(actions.checkIfLoggedIn()),
        verifyOtp: (data) => dispatch(actions.postVerifyOtp(data)),
        sendOtp: (data) => dispatch(actions.postSendOtp(data)),
        clearNotification: () =>
            dispatch({
                type: actionTypes.POST_SENDOTP_TYPES_FAIL,
                error: null,
            }),
    };
};
export default withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(Login)
);
