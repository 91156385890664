export const Keys = {
    aadharBack: "file_aadhar_back",
    aadharCardBack: "aadhar_back",
    aadharCardFront: "aadhar_front",
    aadharFront: "file_aadhar_front",
    aadharNumber: "response_aadhar_number",
    aboutToExpire: "aboutToExpire",
    aboutToExpired: "about_to_expired",
    aboveFifteen: "above_fifteen",
    accountName: "account_name",
    accountNumber: "account_number",
    additionalNumber1: "additional_number_1",
    additionalNumber2: "additional_number_2",
    additionalNumber3: "additional_number_2",
    additionalNumbers: "additionalNumbersData",
    addressInfo: "address_info",
    addressLine1: "address_line_1",
    addressLine2: "address_line_2",
    admin: "admin",
    agent: "agent",
    alcohol: "response_has_consumed_alcohol",
    alcoholAs: "response_alcohol_consumed_as",
    alcoholFrequency: "response_alcohol_frequency",
    alcoholYears: "response_alcohol_since_years",
    alreadyPaid: "is_already_paid",
    amount: "amount",
    annualIncome: "response_annual_income",
    annualIncomeSalary: "annual_income_salary",
    annualIncomeNonSalary: "annual_income_non_salary",
    anonymous: "anonymous",
    anyAilment: "any_ailment",
    anyBlood: "any_blood",
    anyCongenital: "any_congenital",
    anyDisorder: "any_disorder",
    anyGastro: "any_gastro",
    anyGynecological: "any_gynecological",
    anyTermination: "any_termination",
    architect: "architect",
    armedForces: "response_armed_forces",
    asthma: "asthma",
    authorizeIndiaFirst: "response_authorize_india_first",
    autoImmune: "auto_immune",
    availableAddress: "available_address_info",
    availableCity: "avail_city",
    availableInvoiceLimit: "availableInvoiceLimit",
    availablePincode: "avail_pincode",
    availableState: "avail_state",
    beer: "beer",
    beneficiaryName: "beneficiary_name",
    between6and10: "6_to_10_units",
    bidi: "bidi",
    brand: "brand",
    brother: "brother",
    bungalow: "bungalow",
    businessIncome: "business_income",
    businessman: "businessman",
    ca: "ca",
    cancer: "cancer",
    chequeBookImage: "cheque_book_image",
    chest: "chest",
    child1: 'child1',
    child1Dob: "dob",
    child1Feet: "height_feet",
    child1Gender: "gender",
    child1Inches: "height_inch",
    child1Info: "info",
    child1Name: "name",
    child1Title: "title",
    child1Weight: "weight",
    child2: 'child2',
    child2Dob: "dob",
    child2Feet: "height_feet",
    child2Gender: "gender",
    child2Inches: "height_inch",
    child2Info: "info",
    child2Name: "name",
    child2Title: "title",
    child2Weight: "weight",
    cigar: "cigar",
    cigarettes: "cigarettes",
    citizen: "response_citizen",
    city: "city",
    claimedAmount: "claimedAmount",
    closeContact: "close_contact",
    code: "code",
    color: "color",
    comission: "comission",
    commission: "commission",
    consultant: "consultant",
    constructionType: "response_construction_type",
    covidInfo: "covid_info",
    creditAmount: "creditAmount",
    creditAvailable: "creditAvailable",
    creditConsumed: "creditConsumed",
    criminalProceedings: "response_criminal_proceedings",
    criminalProceedingsDetails: "response_criminal_proceedings_details",
    criticalIllness: "response_critical_illness",
    currentlyNotHealthy: "currently_not_healthy",
    currentlySuffering: "currently_suffering",
    historyOfConviction: "history_of_conviction",
    customer: "customer",
    customerName: "customer_name",
    customers: "customers",
    cycle: "cycle",
    cyst: "response_cyst",
    dangerousOccupation: "response_dangerous_occupation",
    dangerousOccupationDetails: "response_dangerous_occupation_details",
    data: "data",
    dateOfBirth: "dateOfBirth",
    daughter: "daughter",
    dealer: "dealer",
    dealerClaimId: "dealerClaimId",
    dealerData: "dealerData",
    dealerId: "dealer_id",
    dealerIdP: "dealerId",
    dealerName: "dealerName",
    dealerPartner: "dealerPartner",
    dealerUserId: "dealer_user_id",
    dealershipName: "dealership_name",
    diabetes: "diabetes",
    diagnosisHistory: "response_diagnosis_history",
    diploma: "diploma",
    disease: "disease",
    disease2: "diseases2",
    disease3: "diseases3",
    disease2As: "response_diseases2_as",
    disease3As: "response_diseases3_as",
    diseaseAs: "response_disease_as",
    distributor: "distributor",
    divorced: "divorced",
    dob: "dob",
    dobC: "DOB",
    doctor: "doctor",
    doneDiagnosis: "done_diagnosis",
    duration: "duration",
    dynamicPricingEnabled: "is_dynamic_pricing_enabled",
    education: "response_education",
    eia: "eia",
    eiaExists: "eia_exists",
    email: "email",
    emailT: "Email",
    endDate: "end_date",
    entity: "entity",
    entityId: "entityId",
    existingCycle: "is_existing_cycle",
    existingCyclePartners: "existing_cycle_partners",
    experiencedSymptoms: "experienced_symptoms",
    expiryText: "expiryText",
    farmhouse: "farmhouse",
    father: "father",
    fatherName: "response_father_name",
    feet: "response_feet",
    female: "female",
    fiftyLac: "5000000",
    firefox: "firefox",
    fiveLac: "500000",
    flat: "flat",
    format: "dd/MM/yyyy",
    fourLac: "400000",
    fourToTen: "four_to_ten",
    frequently: "frequently",
    gender: "gender",
    genitourinary: "genitourinary",
    govt: "govt",
    graduate: "graduate",
    gst: "gst",
    gutka: "gutka",
    hasWeightChanged: "response_has_weight_changed",
    hazardousSport: "response_hazardous_sport",
    hazardousSportDetails: "response_hazardous_sport_details",
    healthDeclarationInfo: "health_declaration_info",
    healthInfo: "health_info",
    height: "height",
    heightFeet: "height_feet",
    heightInch: "height_inch",
    hero: "hero",
    highPremium: "high_premium",
    hiv: "hiv",
    homeBtnText: "homeBtnText",
    hypertension: "hypertension",
    id: "id",
    idToken: "id_token",
    ifsc: "ifsc_code",
    illnessInjury: "illness_injury",
    incentive: "incentive",
    inches: "response_inches",
    income: "income_proof",
    incomeProof: "file_response_income_proof",
    incomeSalary: "income_salary",
    incomeOthers: "income_others",
    indiaFirstHealth1: "india_first_health1",
    indiaFirstHealth2: "india_first_health2",
    indiaFirstHealth3: "india_first_health3",
    indiaFirstHealth4: "india_first_health4",
    indiaFirstHealth5: "india_first_health5",
    indiaFirstHealth6: "india_first_health6",
    indiaFirstHealth7: "india_first_health7",
    indiaFirstHealth8: "india_first_health8",
    indiaFirstHealth9: "india_first_health9",
    indType: "response_indtype",
    inheritance: "inheritance",
    invoiceLimit: "invoiceLimit",
    invoiceNumber: "invoice_number",
    invoicingModal: "invoicingModal",
    isPermanentAddressDifferent: "response_permanent_address_different",
    isRenewedPolicy: "is_renewed_policy",
    isSmoker: "is_smoker",
    landmark: "response_landmark",
    lawyer: "lawyer",
    lessThan5: "less_than_5_units",
    lifestyleInfo: "lifestyle_info",
    liquor: "liquor",
    liver: "liver",
    locale: "locale",
    male: "male",
    manager: "manager",
    maritalStatus: "response_marital_status",
    married: "married",
    medicalLeave: "response_has_taken_medical_leave",
    members: "response_members",
    message: "message",
    mobile: "mobile",
    model: "model",
    monthlyPremium: "monthly_premium",
    monthlyRank: "monthlyrank",
    moreThan10: "greater_than_10_units",
    mother: "mother",
    mr: "mr",
    ms: "ms",
    msg: "msg",
    name: "name",
    narcotics: "response_has_consumed_narcotics",
    nervous: "nervous",
    no: "no",
    noOfInvoices: "noOfInvoices",
    nomineeDob: "response_nominee_dob",
    nomineeInfo: "nominee_info",
    nomineeMobile: "nominee_mobile",
    nomineeName: "nominee_name",
    nomineeRelation: "nominee_relation_id",
    nonSmoker: "non_smoker",
    notes: "notes",
    occasionally: "occasionally",
    occupation: "response_occupation",
    occupationDescription: "response_occupation_desc",
    oneLac: "100000",
    orderId: "order_id",
    organizationName: "response_organization_name",
    organizationType: "response_organization_type",
    organizationTypeSelf: "response_orgtype_self",
    other: "other",
    others: "others",
    otpLength: "otp-length",
    pan: "pan",
    panCard: "pan_card",
    panR: "response_pan",
    partner: "partner",
    partnerName: "partnerName",
    partnership: "partnership",
    payment: "payment",
    ped1: "ped1",
    percentComplete: "percentComplete",
    permanentAddressLine1: "response_permanent_address_line_1",
    permanentAddressLine2: "response_permanent_address_line_2",
    permanentCity: "response_permanent_city",
    permanentLandmark: "response_permanent_landmark",
    permanentPincode: "response_permanent_pincode",
    permanentState: "response_permanent_state",
    personalInfo: "personal_info",
    pincode: "pincode",
    policy: "policy",
    policyEndDate: "policy_end_date",
    policyNumber: "policy_number",
    policySold: "policySold",
    policyStartDate: "policy_start_date",
    political: "response_politically_exposed",
    politicalDetails: "response_politically_exposed_details",
    postGraduate: "post_graduate",
    pregnant: "pregnant",
    premium: "premium",
    isMonthly: "is_monthly",
    prevInsurer: "prev_insurer",
    profession: "response_profession",
    professional: "professional",
    promoter: "promoter",
    proprietor: "proprietor",
    providerName: "provider_name",
    psychiatric: "psychiatric",
    publicLtd: "public_ltd",
    pvtLtd: "pvt_ltd",
    qualificationInfo: "education_occupation_info",
    quarantined: "quarantined",
    ranking: "ranking",
    razorPayOrderId: "razorpayOrderId",
    razorPayPaymentId: "razorpayPaymentId",
    razorPaySignature: "razorpaySignature",
    razorpaySubscriptionId: "razorpaySubscriptionId",
    redirect: "redirect",
    relationship: "relationship",
    retired: "retired",
    rewardAmount: "reward_amount",
    rewardRuleId: "reward_rule_id",
    rpKey: "rp_key",
    rpOrderId: "rp_order_id",
    rpSubscriptionId: "rp_subscription_id",
    salaried: "salaried",
    salary: "salary",
    saleDate: "sale_date",
    saleFormattedDate: "sale_formatted_date",
    saleId: "sale_id",
    saleOfAssets: "sale_of_assets",
    salesData: "salesData",
    selfEmployed: "self_employed",
    selfEmployedR: "response_self_employed",
    seller: "seller",
    sendQuotation: "send_quotation",
    seniorSecondary: "12th",
    seventyFiveLac: "7500000",
    sharePortfolio: "response_share_portfolio",
    single: "single",
    sister: "sister",
    slabs: "slabs",
    smoker: "smoker",
    society: "society",
    son: "son",
    sourceFund: "response_source_of_fund",
    spouse: "spouse",
    spouseDob: "dob",
    spouseFeet: "height_feet",
    spouseInches: "height_inch",
    spouseInfo: "spouse_info",
    spouseName: "name",
    spouseTitle: "title",
    spouseWeight: "weight",
    startDate: "start_date",
    state: "state",
    status: "status",
    subscriptionData: "subscription_data",
    success: "success",
    sumAssuredHealth: "sum_assured_health",
    sumAssuredHealthR: "response_sum_assured_health",
    sumAssuredHome: "sum_assured_home",
    sumAssuredHomeR: "response_sum_assured_home",
    sumAssuredLife: "sum_assured_life",
    sumAssuredLifeR: "response_sum_assured_life",
    sumInsured: "sum_insured",
    support: "support",
    surgeryMed: "surgery_med",
    takePayment: "take_payment",
    threeLac: "300000",
    threeToTen: "three_to_ten",
    tenToFifteen: "ten_to_fifteen",
    testedPositive: "tested_positive",
    ti: "ti",
    timeDiff: "timeDiff",
    title: "title",
    tobacco: "response_has_consumed_tobacco",
    tobaccoAs: "response_tobacco_consumed_as",
    tobaccoInfo: "tobacco_info",
    tobaccoQuantity: "response_tobacco_quantity",
    tobaccoYears: "response_tobacco_since_years",
    toffeeId: "toffee_id",
    token: "token",
    totalEarning: "totalEarning",
    transferPrice: "transfer_price",
    transferPricingEnabled: "transfer_pricing_enabled",
    travelHistory: "travel_history",
    trust: "trust",
    truth: "true",
    twoLac: "200000",
    type: "type",
    uploadDocuments: "upload_documents",
    user: "user",
    userPhoto: "user_photo",
    verifiedOtp: "verified_otp",
    verifyDetails: "verify_details",
    weight: "response_weight",
    weightChange: "response_weight_change",
    weightChangeReason: "response_weight_change_reason",
    widowed: "widowed",
    wine: "wine",
    yearOfConstruction: "response_year_of_construction",
    yes: "yes",
    production: "production",
    android: "android",
    imageUrl: "image_url",
    policyTerm: "policy_term",
    policyTermR: "response_policy_term",
    zero: "0",
    annualPremiumR: "response_annual_premium",
    monthlyPremiumR: "response_monthly_premium",
    annual: "annual",
    monthly: "monthly",

    spouseTobacco: 'has_consumed_tobacco',
    spouseTobaccoAs: 'tobacco_consumed_as',
    spouseTobaccoQuantity: 'tobacco_quantity',
    spouseTobaccoYears: 'tobacco_since_years',
    spouseAlcohol: 'has_consumed_alcohol',
    spouseAlcoholAs: 'alcohol_consumed_as',
    spouseAlcoholFrequency: 'daily_alcohol_frequency',
    spouseDailyAlcoholConsumptionFrequency: 'daily_alcohol_frequency',
    spouseAlcoholYears: 'alcohol_since_years',

    child1Tobacco: 'has_consumed_tobacco',
    child1TobaccoAs: 'tobacco_consumed_as',
    child1TobaccoQuantity: 'tobacco_quantity',
    child1TobaccoYears: 'tobacco_since_years',
    child1Alcohol: 'has_consumed_alcohol',
    child1AlcoholAs: 'alcohol_consumed_as',
    child1AlcoholFrequency: 'daily_alcohol_frequency',
    child1DailyAlcoholConsumptionFrequency: 'daily_alcohol_frequency',
    child1AlcoholYears: 'alcohol_since_years',

    child2Tobacco: 'has_consumed_tobacco',
    child2TobaccoAs: 'tobacco_consumed_as',
    child2TobaccoQuantity: 'tobacco_quantity',
    child2TobaccoYears: 'tobacco_since_years',
    child2Alcohol: 'has_consumed_alcohol',
    child2AlcoholAs: 'alcohol_consumed_as',
    child2AlcoholFrequency: 'daily_alcohol_frequency',
    child2DailyAlcoholConsumptionFrequency: 'daily_alcohol_frequency',
    child2AlcoholYears: 'alcohol_since_years',

    traditional: 'traditional',
    health: 'health',
    unitLinked: 'unit_linked',
    pension: 'pension',
    termPlan: 'term_plan',
    standard: 'standard',
    ratedUp: 'rated_up',
    declined: 'declined',
    postponed: 'postponed',
    lapsed: 'lapsed',
    rejected: 'rejected',
    self: 'self',
    husband: 'husband',
    indiaFirstCovid: 'response_india_first_covid',

    lessThanThirty: 'less_than_thirty',
    thirtyToOneHundredEighty: 'thirty_to_one_hundred_eighty',
    moreThanOneHundredEighty: 'more_than_one_hundred_eighty',

    indiaFirstRelation: 'response_relation',
    ageTimeOfDemise: 'response_age_time_of_demise',
    causeOfDeath: 'response_cause_of_death',
    ageIfAlive: 'response_age_if_alive',
    specifiedIllness: 'response_specified_illness',
    dailyAlcoholConsumptionFrequency: 'response_daily_alcohol_frequency',

    haveOtherLifeInsuranceR: 'response_have_other_life_insurance',
    otherLifeInsurancesR: 'response_other_life_insurance',
    otherLifeInsurancePolicyType: 'policy_type',
    otherLifeInsuranceCommencementYear: 'commencement_year',
    otherLifeInsuranceSumInsured: 'sum_insured',
    otherLifeInsurancePolicyStatus: 'policy_status',
    otherLifeInsurancePersonName: 'person_name',
    otherLifeInsuranceCompanyName: 'company_name',
    otherLifeInsurances1: 'other_life_insurance1',
    otherLifeInsurances2: 'other_life_insurance2',
    otherLifeInsurancePolicyType1: 'other_life_insurance1_policy_type',
    otherLifeInsurancePolicyType2: 'other_life_insurance2_policy_type',
    otherLifeInsuranceCommencementYear1: 'other_life_insurance1_commencement_year',
    otherLifeInsuranceCommencementYear2: 'other_life_insurance2_commencement_year',
    otherLifeInsuranceSumInsured1: 'other_life_insurance1_sum_insured_exist',
    otherLifeInsuranceSumInsured2: 'other_life_insurance2_sum_insured_exist',
    otherLifeInsurancePolicyStatus1: 'other_life_insurance1_policy_status',
    otherLifeInsurancePolicyStatus2: 'other_life_insurance2_policy_status',
    otherLifeInsurancePersonName1: 'other_life_insurance1_name',
    otherLifeInsurancePersonName2: 'other_life_insurance2_name',
    otherLifeInsuranceCompanyName1: 'other_life_insurance1_company',
    otherLifeInsuranceCompanyName2: 'other_life_insurance2_company',

    diagnosedRemark: 'diagnosed_remark',
    gynecologicalRemark: 'gynecological_remark',
    armedForcesDetails: 'response_armed_forces_details',
    illnessInjuryRemark: 'illness_injury_remark',
    ped1Details: 'ped1_details',
    pregnantRemark: 'response_pregnant_remark',
    byPassSurgery: 'by_pass_surgery',
    disease4: 'diseases4',
    disease4As: 'response_diseases4_as',
    anyHandicapRemark: 'any_handicap_remark',
    healthDeclaration: 'response_health_declaration',
    healthDeclarationDetails: 'response_health_declaration_details',
    medicalConditionName: 'medical_condition_name',
    dateOfDiagnosis: 'date_of_diagnosis',
    treatmentDuration: 'treatment_duration',
    currentMedicalCondition: 'current_medical_condition',
    treatmentDetails: 'treatment_details',
    treatmentTests: 'treatment_tests',
    provideOtherDetails: 'provide_other_details',
    coronaTestedPositive: 'corona_tested_positive',
    selfIsolated: 'self_isolated',
    selfIsolatedDueCorona: 'self_isolated_due_corona',
    confirmedCorona: 'confirmed_corona',
    riskToGetCorona: 'risk_to_get_corona',
    student: 'student',
    agriculturist: 'agriculturist',
    housewife: 'housewife',
    engagedIn: 'response_engaged_in',
    sourceFundDetails: 'response_source_of_fund_details',
    organizationTypeDetails: 'response_organization_type_details',
    aunt: 'aunt',
    brotherInLaw: 'brother_in_law',
    grandFather: 'grand_father',
    grandMother: 'grand_mother',
    grandSon: 'grand_son',
    grandDaughter: 'grand_daughter',
    niece: 'niece',
    uncle: 'uncle',
    nephew: 'nephew',
    sisterInLaw: 'sister_in_law',
    wife: 'wife',
    highSecondary: '10th',
    belowHighSecondary: 'below10th',
    electronicInsuranceR: 'response_electronic_insurance',
    eiaAccountNumberR: 'response_eia_account_number',
    lifeInsuranceOtherThanIpru: 'response_life_insurance_other_than_ipru',
    insuranceCompany: 'response_insurance_company',
    insuranceCompany2: 'response_insurance_company2',
    entireLifeCoverOrSumAssured: 'response_other_sum_insured',
    entireLifeCoverOrSumAssured2: 'response_other_sum_insured2',
    lifeInsuranceDetail: 'response_life_insurance_detail',
    lifeInsuranceDetailText: 'response_life_insurance_detail_text',
    underMarriedWomen: 'response_under_married_women',
    addTrustee: 'response_add_trustee',
    addBeneficiary: 'response_add_beneficiary',
    taxResidentIndia: 'response_tax_resident_india',
    birthCountryIndia: 'response_birth_country_india',
    departmentDivision: 'response_department_division',
    lastMedical: 'response_last_medical',
    industryType: 'response_industry_type',
    jewl: 'jewl',
    impexp: 'impexp',
    shipping: 'shipping',
    scrp: 'scrp',
    realEstate: 'real_estate',
    stock: 'stock',
    aegonReligare: 'aegon_religare',
    avivaLife: 'aviva_life',
    bajajAllianz: 'bajaj_allianz',
    bhartiAxa: 'bharti_axa',
    birlaSun: 'birla_sun',
    canaraHsbc: 'canara_hsbc',
    dlfPramerica: 'dlf_pramerica',
    edelwiessTokio: 'edelwiess_tokio',
    futureGenerali: 'future_generali',
    hdfcStandard: 'hdfc_standard',
    idbiFortis: 'idbi_fortis',
    indiaFirst: 'india_first',
    ingVysya: 'ing_vysya',
    kotakLife: 'kotak_life',
    lifeInsurance: 'life_insurance',
    maxNew: 'max_new',
    metLifeIndia: 'metLife_india',
    postalLife: 'postal_life',
    relianceLife: 'reliance_life',
    saharaIndia: 'sahara_india',
    sbiLife: 'sbi_life',
    shriramLife: 'shriram_life',
    starUnion: 'star_union',
    tataAig: 'tata_aig',
    unitTrust: 'unit_trust',
    tataAia: 'tata_aia',
    eiaRepositoryR: 'response_eia_repository',
    ndml: 'NDML',
    cirl: 'CIRL',
    karvy: 'KARVY',
    cams: 'CAMSRep',
    diseaseDetails: 'Disease details',
    rankR: 'response_rank',

    citizenIcici: 'response_citizen_icici',
    organizationTypeSelfDetails: 'response_organization_type_self_details',
    otherR: "response_other",
    salutation: "response_salutation",
    mrs: "mrs",
    dr: "dr",
    nomineeTitle: "response_nominee_title",
    nomineeSalutation: "response_nominee_salutation",
    natureOfDuties: 'response_nature_of_duties',
    coveredPnbLifeSuraksha: 'response_covered_pnb_life_suraksha',
    postponedPnb: 'response_postponed_pnb',
    postponedPnbDetails: 'response_postponed_pnb_details',
    areYouPregnant: 'response_are_you_pregnant',

    policyDetails: 'policy_details',
    lifePlusOption: 'response_life_plus',
    returnOfPremium: 'response_return_of_premium',
    criticalIllnessCover: 'response_accidental_death',
    policyTenure: 'response_policy_term',
    typeOfPremiumPayment: 'response_premium_type',
    premiumPaymentTerm: 'response_paying_term',
    payoutOption: 'response_payout_option',
    criticalIllnessSumAssured: 'response_critical_illness_sum_assured',
    percentageOfPremium: 'response_percentage_of_premium',
    fiftyPercent: '50%',
    hundredPercent: '100%',
    tenPercent: '10%',
    twentyPercent: '20%',
    thirtyPercent: '30%',
    fortyPercent: '40%',
    singlePremium: 'single_premium',
    limitedPremium: 'limited_premium',
    lumpsumPayout: 'lumpsum_payout',
    lumpsumAndLevelIncome: 'lumpsum_and_level_income',
    lumpsumAndIncreasingIncome: 'lumpsum_and_increasing_income',

    paymentTenure: "response_payment_tenure",
    limitedPayFor: "response_limited_pay_for",
    paymentFrequency:"response_payment_frequency",
    deathBenefitPayout: "response_death_benefit",
}