import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import NotificationAlert from "react-notification-alert";

class InputField extends Component {
  state = {
    input: {
      name: "",
      value: "",
    },
  };
  constructor(props) {
    super(props);
    this.props.onGetInitiatedClaimState();
  }

  notify = (place, title, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    nextProps.input.name &&
      this.setState({
        input: {
          name: nextProps.input.name,
          value: "",
        },
      });
    nextProps.claimState &&
      nextProps.claimState.inputs.hasOwnProperty(nextProps.input.name) &&
      this.setState({
        input: {
          name: nextProps.input.name,
          value: nextProps.claimState.inputs[nextProps.input.name],
        },
      });
  }
  componentWillUpdate(props) {
    // console.log(props.claimState.inputs);
    // console.log(props);
  }
  nat = (n) => {
    // var n1 = Math.abs(n),
    //     n2 = parseInt(n, 10);
    // return !isNaN(n1) && n2 === n1 && n1.toString() === n;
    return /^[1-9]*\d*$/.test(n);
  };
  onChnage = (e) => {
    let val = "";
    if (
      this.props.input.validations.split("|")[1] === "float" ||
      this.props.input.validations.split("|")[1] === "digits"
    ) {
      // console.log(e.target.value.split("")[e.target.value.split("").length - 1]);
      // val = e.target.value;
      // console.log(
      //     this.nat(e.target.value)
      // );
      if (this.nat(e.target.value)) {
        val = e.target.value;
      } else {
        return;
      }
    } else {
      if (e.target.value.length > 300) {
        this.notify("tr", "", "300 characters max", "danger");
        // console.log("Name should not be more than 120 characters");
        return;
      }
      val = e.target.value;
    }
    // console.log(e.target.value);
    this.setState({
      input: {
        name: this.props.input.name,
        value: val,
      },
    });
  };
  submit = () => {
    if (this.state.input.value !== "") {
      this.props.addInput(this.state.input);
      this.setState({
        input: {
          name: this.state.name,
          value: "",
        },
      });
    }
  };
  render() {
    const inputType =
      this.props.input.validations.split("|")[1] === "float"
        ? "number"
        : this.props.input.validations.split("|")[1];
    // console.log(this.props.input.validations.split("|")[1]);

    return (
      <div className="component-container position-relative m-auto">
        <div className="p-4 d-flex align-items-center">
          <img
            className="icon-60 ml-n3"
            src={require("../../../assets/img/icons/details-icon.png")}
            alt=""
          />
          <h2 className=" font-weight-light txt-secondary d-inline m-0">
            {this.props.input.display_name}
          </h2>
        </div>
        <div className="px-4 pb-4">
          <input
            type={inputType}
            className="py-2 px-3 w-100 border-50 txt-secondary font-weight-normal mb-0"
            min="0"
            rows="6"
            value={this.state.input.value}
            onChange={this.onChnage}
            placeholder={this.props.input.placeholder}
          />

          <div className="my-2 mb-3">
            {/* <img className="icon-17 mr-2" src={require("../../../assets/img/icons/question-mark.png")} alt="" />
                        <span className="h6 font-weight-light opacity-50 info-text">Please enter the location and pincode where the accident happened.</span> */}
            {/* <h6 className="text-right mt-1 txt-secondary font-weight-normal  opacity-80">Step 4/5</h6> */}
          </div>

          <div className="my-2 ">
            <img
              className="icon-17 mr-2"
              src={require("../../../assets/img/icons/question-mark.png")}
              alt=""
            />
            <span className="h6 font-weight-light opacity-50 info-text">
              {this.props.input.helper_text}
            </span>
            <h6 className="d-inline float-right mt-1 txt-secondary font-weight-normal  opacity-80">
              Step {this.props.position.current}/{this.props.position.total}
            </h6>
          </div>
        </div>
        <div
          className={
            "btn-primary cursor-pointer bg-shadow-3 px-4  d-flex justify-content-center align-items-center mobile-position-fixed footer-button " +
            (this.state.input.value !== "" ? "" : "opacity-50")
          }
          onClick={(e) => {
            e.preventDefault();
            this.submit();
          }}
        >
          <h2 className="font-weight-normal m-0 txt-white">Next</h2>
        </div>

        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    claimState: state.claim.claimState,
    error: state.claim.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetInitiatedClaimState: () => dispatch(actions.getInitiatedClaims()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InputField);
