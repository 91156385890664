import React, { Component } from "react";

import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import Box from "@material-ui/core/Box";
import ResponsiveContainer from "../../../components/ResponsiveContainer/responsiveContainer";
import CoveredCardItem from "./CoveredCard";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Grid,
  withStyles,
  Divider,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Call from "@material-ui/icons/Call";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CalculatePremium from "../../../components/Forms/CalculatePremium";
import API from "../../../utilities/api";
import FeaturedBacked from "../Landing/featuredBacked";
import LinearProgress from "@material-ui/core/LinearProgress";
import NotificationAlert from "react-notification-alert";
import BackedChip from "./BackedChip";

const useStyles = (theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },

    [theme.breakpoints.up("md")]: {
      paddingLeft: "100px",
      paddingRight: "100px",
      paddingTop: "16px",
      // paddingBottom: "87px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingTop: "16px",
      maxWidth: "1290px",
      margin: "auto",
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingTop: "16px",
      maxWidth: "1440px",
      margin: "auto",
    },
  },
  backContainer: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "-35px",
    },
  },
  backBtn: {
    width: "43px",
    height: "43px",
    marginBottom: "16px",
    marginLeft: "0px",
    [theme.breakpoints.down("md")]: {
      // width: "30px",
      // height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "30px",
      height: "30px",
      marginTop: "15px",
    },
  },
  headerGrid: {
    height: "180px",
    [theme.breakpoints.down("md")]: {
      height: "211px",
    },
  },
  headerCard: {
    borderRadius: "40px",
    boxShadow: "0 24px 50px 0 rgba(192, 192, 192, 0.5)",
    overflow: "hidden",
    marginBottom: "50px",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
      borderRadius: "0",
      boxShadow: "none",
      marginBottom: "30px",
      marginTop: -"24px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },

    [theme.breakpoints.up("lg")]: {
      paddingLeft: "120px",
      paddingRight: "120px",
    },
  },
  appBarContainer: {
    overflowX: "scroll",
  },
  appBar: {
    backgroundColor: "#ffffff !important",
    paddingTop: "28px",
    height: "105px",
    width: "auto",

    [theme.breakpoints.down("md")]: {
      height: "80px",
      paddingTop: "10px",
    },
  },
  tabLabel: {
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "capitalize",
    minWidth: "auto",
    paddingBottom: "0",
    padding: "0",
    [theme.breakpoints.up("md")]: {
      margin: "0 26px",
      fontSize: "22px",
      fontWeight: "700",
    },
    [theme.breakpoints.down("md")]: {
      fontWeight: "600",
      margin: "0 15px",
    },
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      margin: "0 10px",
    },
  },
  logoImg: {
    maxHeight: "90px",
    margin: "0",
    marginRight: "20px",
    [theme.breakpoints.down("md")]: {
      maxHeight: "70px",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "54px",
    },
  },
  calBtn: {
    padding: "17px 25px",
    fontSize: "16px",
    letterSpacing: "2px",
    [theme.breakpoints.down("md")]: {
      padding: "15px 17px",
      fontSize: "14px",
      letterSpacing: "0.2px",
    },
  },
  prmBtn: {
    padding: "17px 25px",
    fontSize: "12px",
    letterSpacing: "2px",
    [theme.breakpoints.down("md")]: {
      padding: "15px 17px",
    },
  },
  tabPannel: {
    paddingLeft: "3px",
    paddingright: "3px",
    overflow: "hidden",
  },
  healthPanel: {},
  healthImage: {
    height: "370px !important",
    position: "relative !important",
    [theme.breakpoints.down("sm")]: {
      height: "160px !important",
    },
  },
  healthBg: {
    [theme.breakpoints.up("md")]: {
      marginTop: "-153px !important",
    },
  },
  lifePanel: {},
  lifeImage: {},
  lifeBg: {},
  homePanel: {},
  homeImage: {},
  homeBg: {},
  claimPanel: {},
  claimImage: {
    [theme.breakpoints.up("md")]: {
      height: "370px",
    },
  },
  claimBg: {},
  topPanel: {
    marginBottom: "90px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "35px",
    },
  },
  topPanelTitle1: {
    fontWeight: "bold",
    color: "rgba(47, 47, 47, 0.8)",
    marginBottom: "6px",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "34px !important",
    },
  },
  topPanelTitle2: {
    fontWeight: "bold",
    color: "rgba(47, 47, 47, 0.8)",
    marginBottom: "13px !important",
    [theme.breakpoints.down("md")]: {
      // fontSize: "14px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginBottom: "13px !important",
    },
  },
  topPanelTitle3: {
    fontWeight: "bold",
    marginBottom: "13px !important",
    color: "rgba(47, 47, 47, 0.8)",
    [theme.breakpoints.down("md")]: {
      // fontSize: "14px !important",
      // fontWeight: "normal",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginBottom: "13px !important",
    },
  },
  claimTitle2: {
    [theme.breakpoints.down("md")]: {
      fontSize: "16px !important",
    },
  },
  claimRightImageCover: {
    alignItems: "flex-end",
    display: "flex",
    marginBottom: "18px",
  },
  titleGroup: {
    paddingTop: "86px !important",
    [theme.breakpoints.down("md")]: {
      paddingTop: "50px !important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0px !important",
    },
  },

  fixedBar: {
    boxShadow: "0 0 50px 0 rgba(192, 192, 192, 0.5)",
    padding: "10px 15px",
    height: "130px",
    backgroundColor: "#ffffff",
    transition: "all 0.5s",

    [theme.breakpoints.up("md")]: {
      paddingLeft: "100px",
      paddingRight: "100px",
      height: "87px",
    },
    [theme.breakpoints.up("lg")]: {
      // paddingLeft: "125px",
      // paddingRight: "125px",
      height: "87px",
    },
  },
  barInnerBox: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "15px",
      paddingRight: "15px",
      maxWidth: "1290px",
      margin: "auto",
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "15px",
      paddingRight: "15px",
      maxWidth: "1440px",
      margin: "auto",
    },
  },

  toffeePlanLogo: {
    maxWidth: "290px",
    height: "55px",
  },
  boldFont: {
    fontWeight: "bold",
  },
  center: {
    textAlign: "center",
  },
  blue: {
    color: "#00c9f3",
  },
  coveredTitle: {
    fontWeight: "bold",
    marginBottom: "50px",
  },
  imageBg: {
    marginTop: "-100px",

    [theme.breakpoints.down("md")]: {
      marginTop: "-50px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-30px",
    },
  },
  rightImage: {
    maxHeight: "390px",
    [theme.breakpoints.down("md")]: {
      marginTop: "35px",
    },
  },
  mobileHidden: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileCenter: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  appBarIcon: {
    backgroundColor: "rgba(255, 88, 93, 0.1)",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "15px",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "50px",
    },
  },
  redBoldText: {
    fontWeight: "bold",
    color: "#ff585d",
  },
  redBoldLink: {
    fontWeight: "bold",
    color: "#ff585d",
    cursor: "pointer",
  },
  lineHeight: "1.6",

  verticalCenter: {
    display: "flex",
    alignItems: "center",
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  bottomBar: {
    height: "87px",
    [theme.breakpoints.down("sm")]: {
      height: "130px",
    },
    [theme.breakpoints.up("md")]: {
      height: "87px",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

class ProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      calOpenDialog: false,
      calculateState: null,
      monthlyPremium: 0,
      yearlyPremium: 0,
      isCalculating: false,
      isCalculated: false,
      isDesktop: true,
      bottomBar: false,
    };
  }
  formData = {
    _token: "DDf8fZhdjiaUK3nZZVIiqbqtCEdRuXZy6CERu1fI",
    DOB: "on",
  };
  handleChange = (event, newValue) => {
    this.setState({ value: newValue }, () => {
      window.scrollTo(0, this.state.isDesktop ? 270 : 250);
    });
  };

  handleDialogOpen = () => {
    this.setState({
      calOpenDialog: true,
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleClose = () => {
    this.setState({
      calOpenDialog: false,
    });
  };
  calculatePremium() {
    console.log(this.formData);

    if (!this.state.calculateState) return;
    console.log("working");

    this.formData["gender"] =
      this.state.calculateState.title === "mr" ? "male" : "female";
    this.formData["name"] = this.state.calculateState.name;
    this.formData["mobile"] = this.state.calculateState.mobile;
    this.formData["email"] = this.state.calculateState.email;
    this.formData["dob"] = this.state.calculateState.dob;
    this.formData["members[spouse][dob]"] = this.state.calculateState.spouseDob;
    this.formData["members[child1][dob]"] = this.state.calculateState.child1Dob;
    this.formData["members[child2][dob]"] = this.state.calculateState.child2Dob;
    this.formData[
      "sum_assured_life"
    ] = this.state.calculateState.sumAssuredLife;
    this.formData[
      "sum_assured_health"
    ] = this.state.calculateState.sumAssuredHealth;
    this.formData[
      "sum_assured_home"
    ] = this.state.calculateState.sumAssuredHouse;
    this.formData["capture_lead"] = "Y";
    this.formData["is_smoker"] = this.state.calculateState.isConsumingTobacco;
    if (this.formData.name === "") {
      this.notify("tc", "", "Please enter name ", "danger");
      return;
    }
    if (this.formData.mobile === "") {
      this.notify("tc", "", "Please enter mobile number ", "danger");
      return;
    }
    if (this.formData.mobile.length !== 10) {
      this.notify("tc", "", "Please enter 10 digit mobile number ", "danger");
      return;
    }
    if (this.formData.email === "") {
      this.notify("tc", "", "Please enter email id ", "danger");
      return;
    }
    if (!this.isEmailValid(this.formData.email)) {
      this.notify("tc", "", "Please enter valid email id ", "danger");
      return;
    }
    if (this.formData.dob === "") {
      this.notify("tc", "", "Please enter self dob ", "danger");
      return;
    }
    if (
      this.state.calculateState.spouse &&
      this.state.calculateState.spouseDob === ""
    ) {
      this.notify("tc", "", "Please enter spouse dob ", "danger");
      return;
    }
    if (
      this.state.calculateState.child1 &&
      this.state.calculateState.child1Dob === ""
    ) {
      this.notify("tc", "", "Please enter child1 dob ", "danger");
      return;
    }
    if (
      this.state.calculateState.child2 &&
      this.state.calculateState.child2Dob === ""
    ) {
      this.notify("tc", "", "Please enter child2 dob ", "danger");
      return;
    }

    this.setState({
      isCalculating: true,
    });
    const data = new FormData();
    Object.keys(this.formData).forEach((key) =>
      data.append(key, this.formData[key])
    );

    API.post("premium/51", data)
      .then((response) => {
        this.setState({
          isCalculating: false,
          isCalculated: true,
        });
        console.log(response.data);
        this.setState({
          monthlyPremium: response.data.data.premiums.monthly,
          yearlyPremium: response.data.data.premiums.annual,
        });
      })
      .catch((err) => {
        this.setState({
          isCalculating: false,
        });
        console.log(err);
      });
  }
  notify = (place, title, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };
  isEmailValid(email) {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  componentDidMount() {
    this.setState({
      isDesktop: window.screen.availWidth > 850,
    });
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    console.log(event.srcElement.scrollingElement.scrollTop);
    this.setState({
      bottomBar: event.srcElement.scrollingElement.scrollTop > 10,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <ResponsiveContainer
            flag={"landing"}
          child={
            <Box className={classes.container}>
              <Box className={classes.backContainer}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => this.props.history.push("/")}
                  aria-label="close"
                  className={classes.backBtn}
                >
                  <ArrowBack />
                </IconButton>
              </Box>
              <Box className={classes.headerCard}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.headerGrid}
                // spacing={2}
                >
                  <Grid item md={6} xs={12}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Box
                        display="flex"
                        flexGrow={1}
                        justifyContent="flex-start"
                        className={classes.mobileCenter}
                      >
                        <img
                          src={require("../../../assets/img/icon-toffeeplan.svg")}
                          alt=""
                          className={classes.logoImg}
                        />
                        <Box display="flex" alignItems="center">
                          <Box>
                            <Typography
                              variant="h1"
                              className={classes.boldFont}
                            >
                              The Toffee Plan
                            </Typography>
                            <Typography variant="h4">
                              Starting from <b>₹600 per Month</b>
                            </Typography>
                            <Typography variant="h6">*Including GST</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      className={classes.mobileCenter}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          // this.handleDialogOpen();
                          this.props.history.push('/the-toffee-plan/calculate-premium')
                        }}
                        className={classes.calBtn}
                      >
                        <b>Calculate Monthly Payment</b>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Divider />
                <Box
                  container
                  display="flex"
                  className={classes.appBarContainer}
                >
                  <AppBar position="static" className={classes.appBar}>
                    <Tabs
                      value={this.state.value}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={this.handleChange}
                    >
                      <Tab className={classes.tabLabel} label="Health" />
                      <Tab className={classes.tabLabel} label="Life" />
                      <Tab className={classes.tabLabel} label="Household" />
                      <Tab className={classes.tabLabel} label="How to Claim?" />
                    </Tabs>
                  </AppBar>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={(e) => {
                        window.open(
                          "https://toffeeinsurance.com/documents/the-toffee-plan.pdf"
                        );
                      }}
                      className={classes.appBarIcon}
                    >
                      <CloudDownload />
                    </IconButton>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={(e) => {
                        window.location.href =
                          "https://toffeeinsurance.com/contact-us";
                      }}
                      className={classes.appBarIcon}
                    >
                      <Call />
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              <TabPanel
                value={this.state.value}
                index={0}
                className={classes.tabPannel + " " + classes.healthPanel}
              >
                <Box className={classes.topPanel} position="relative">
                  <Box>
                    <Grid container direction="row">
                      <Grid
                        item
                        md={7}
                        xs={7}
                        className={classes.titleGroup}
                        spacing={1}
                      >
                        <Typography
                          variant="h1"
                          className={classes.topPanelTitle1}
                        >
                          Health Insurance
                        </Typography>
                        <Typography
                          variant="h2"
                          className={classes.topPanelTitle2}
                        >
                          ₹3/₹4/₹5 Lakhs
                        </Typography>
                        <Typography
                          variant="h3"
                          className={classes.topPanelTitle3}
                        >
                          Everything you need to know
                        </Typography>
                      </Grid>
                      <Grid item md={5} xs={5}>
                        <img
                          src={require("../../../assets/img/product_tab_health.svg")}
                          alt=""
                          className={
                            classes.rightImage + " " + classes.healthImage
                          }
                          zIndex="tooltip"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.imageBg + " " + classes.healthBg}>
                    <img
                      src={require("../../../assets/img/health_bg.svg")}
                      alt=""
                    />
                  </Box>
                </Box>
                <Box>
                  <Grid container direction="row" justify="space-between">
                    <Grid item md={5} xs={12}>
                      <Typography
                        variant="h2"
                        className={[classes.coveredTitle, classes.blue].join(
                          " "
                        )}
                      >
                        What’s Covered?
                      </Typography>

                      <CoveredCardItem
                        isCovered={true}
                        title="Hospitalisation for up to ₹3/₹4/₹5 Lakhs - INCLUDES COVID-19"
                      >
                        <Typography variant={"h4"}>
                          You and your family (for up to 4 members) get full
                          coverage towards hospitalisation charges (for a
                          minimum period of 24 consecutive hours).
                        </Typography>
                        <br />
                        <Typography variant={"h4"}>
                          You can also avail{" "}
                          <span
                            className={classes.redBoldLink}
                            onClick={(e) => {
                              window.open(
                                "https://toffee-cdn.s3.ap-south-1.amazonaws.com/documents/plan_tataaig_day_care_procedures.pdf"
                              );
                            }}
                          >
                            541 daycare treatments
                          </span>{" "}
                          (that may not require hospitalisation) and everything
                          else during your treatment, up to a limit of ₹3/₹4/₹5
                          Lakhs.
                        </Typography>
                        <br />
                        <Typography variant={"h4"}>
                          What does everything else include? Room charges,
                          nursing expenses and intensive care unit charges,
                          surgeon's fee, doctor's fee, anesthesia, blood,
                          oxygen, operation theater charges, ICU charges and so
                          on.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="COVID-19 coverage"
                      >
                        <Typography variant={"h4"}>
                          The good news is that this cashless hospitalisation
                          coverage is applicable even in case of the policy
                          holder testing positive for COVID-19.
                        </Typography>
                        <br />
                        <Typography variant={"h4"}>
                          The plan gives you complete protection and financial
                          security by covering everything from COVID-19 test
                          (only in case of being tested positive) to
                          hospitalisation to pre and post hospitalisation care.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Cashless Facility in over 4000+ hospitals across India"
                      >
                        <Typography variant={"h4"}>
                          Enjoy cashless facility on{" "}
                          <span
                            className={classes.redBoldLink}
                            onClick={(e) => {
                              window.open(
                                "https://toffee-cdn.s3.ap-south-1.amazonaws.com/documents/plan_tataaig_network_hospitals_list.pdf"
                              );
                            }}
                          >
                            4000+ network hospitals
                          </span>{" "}
                          across India for your treatment. Please note that in
                          case of hospitalisation due to COVID-19, this facility
                          may not be available in all hospitals.
                        </Typography>
                        <br />
                        <Typography variant={"h4"}>
                          The plan gives you complete protection and financial
                          security by covering everything from COVID-19 test
                          (only in case of being tested positive) to
                          hospitalisation to pre and post hospitalisation care.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="30 Days Pre-Hospitalisation and 60 Days Post-Hospitalisation - INCLUDES COVID 19"
                      >
                        <Typography variant={"h4"}>
                          Not just the hospitalisation, but the pre and post
                          care for the patient in question can be pretty taxing,
                          emotionally as well as financially. Our health
                          insurance goes a step ahead and covers your medical
                          expenses, doctor consultations, diagnostic tests and
                          routine medication charges up to 30 days before your
                          hospitalisation and 60 days after.
                        </Typography>
                        <br />
                        <Typography variant={"h4"}>
                          This also includes reimbursement for pre and post
                          hospitalisation charges in case the policy holder is
                          tested positive for COVID-19.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Hospitalisation at Home"
                      >
                        <Typography variant={"h4"}>
                          In case of an emergency or a medical condition that
                          does not allow you to get admitted in a hospital, you
                          are covered for medical expenses incurred during your
                          treatment at home (also called domiciliary treatment)
                          for a period exceeding 3 consecutive days up to your
                          sum insured (₹3/₹4/₹5 lakhs).
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Ambulance Coverage for up to ₹1,000"
                      >
                        <Typography variant={"h4"}>
                          You get reimbursed for expenses incurred by you while
                          availing a road ambulance during your hospitalisation.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem isCovered={true} title="Room Rent">
                        <Typography variant={"h4"}>
                          You are eligible for up to ₹2000 as room rent for a
                          shared accommodation during your hospitalisation.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem isCovered={true} title="ICU Charges">
                        <Typography variant={"h4"}>
                          Your ICU charges are covered up to your sum insured
                          (₹3/₹4/₹5 lakhs).
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Annual Health Check-up"
                      >
                        <Typography variant={"h4"}>
                          You get a free Annual Health Check-up for yourself and
                          all family members for up to 1% of your sum insured
                          (₹3000/₹4000/₹5000), only if there were no claims for
                          3 consecutive years.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Restore ₹3/₹4/₹5 Lakhs for the same illness Automatically"
                      >
                        <Typography variant={"h4"}>
                          In special cases where you run out of your health
                          cover due to a claim made, your cover of ₹3/₹4/₹5
                          Lakhs will be restored in your policy for you or your
                          family members. Basically, think of this like
                          recharging your talk time! So you and your family get
                          the same fresh coverage for the same illness, as long
                          as you get hospitalised after 45 days of being
                          discharged.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Organ Donor Cover"
                      >
                        <Typography variant={"h4"}>
                          You will get reimbursed for any medical expenses that
                          are incurred by an organ donor while undergoing the
                          organ transplant surgery up to your sum insured
                          (₹3/₹4/₹5 Lakhs).
                        </Typography>
                      </CoveredCardItem>
                      <br />
                      <BackedChip
                        left="Backed by"
                        right="TATA AIG Medicare Protect"
                      />
                      <br />
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item md={5} xs={12}>
                      <Typography
                        variant="h2"
                        className={classes.coveredTitle}
                        color="primary"
                      >
                        What’s Not Covered?
                      </Typography>

                      <CoveredCardItem
                        isCovered={false}
                        title="30-Day Waiting Period"
                      >
                        <Typography variant={"h4"}>
                          There is a waiting period of 30 days before a claim
                          can be filed for any illness except injury due to an
                          accident. In other words, any diagnosis of disease /
                          undergoing surgery / occurrence of any event, whose
                          signs or symptoms first occur within 30 days of buying
                          this policy period will not be eligible.
                        </Typography>
                      </CoveredCardItem>

                      <CoveredCardItem
                        isCovered={false}
                        title="4 Years of Waiting Period on Pre-existing Illnesses
                        "
                      >
                        <Typography variant={"h4"}>
                          There is a waiting period for 4 years due to
                          pre-existing illnesses like – diabetes, blood
                          pressure, cancer, heart surgeries, etc. (Full List)
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={false}
                        title="2 Years of Waiting Period for Special Ailments
                        "
                      >
                        <Typography variant={"h4"}>
                          Any listed illnesses/treatments will be covered after
                          a waiting period of 24 months. For example,
                          non-infective arthritis, joint replacement, cataract,
                          etc. the coverage kicks in only after two years.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={false}
                        title="Self-Inflicted Injury from attempted Suicide"
                      >
                        <Typography variant={"h4"}>
                          Expenses due to self-inflicted injury (resulting from
                          suicide, attempted suicide) are not covered.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={false}
                        title="Alcohol or Drug Use"
                      >
                        <Typography variant={"h4"}>
                          Expenses arising out of or attributable to alcohol or
                          drug use/misuse/abuse are not covered.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem isCovered={false} title="AIDS">
                        <Typography variant={"h4"}>
                          Medical expenses incurred for treatment of AIDS are
                          not covered.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={false}
                        title="Pregnancy-Related Expenses"
                      >
                        <Typography variant={"h4"}>
                          Treatment arising from or traceable to pregnancy and
                          childbirth, miscarriage, abortion and its consequences
                          are not covered.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={false}
                        title="Congenital disease"
                      >
                        <Typography variant={"h4"}>
                          Any ailment or diagnosis present from the time of
                          birth is not covered under the health plan.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem isCovered={false} title="OPD Expenses">
                        <Typography variant={"h4"}>
                          Medical expenses for OPD and treatments where
                          hospitalisation was not required are not covered.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={false}
                        title="Optical/dental care"
                      >
                        <Typography variant={"h4"}>
                          Expenses for contact lenses, spectacles or a dental
                          treatment are not covered.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={false}
                        title="In-Vitro Fertilisation"
                      >
                        <Typography variant={"h4"}>
                          Tests and treatment relating to infertility and
                          in-vitro fertilisation are not covered.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={false}
                        title="War, Riot, Strike and Nuclear Weapons"
                      >
                        <Typography variant={"h4"}>
                          War, riot, strike, and nuclear weapons induced
                          hospitalisation is not covered.
                        </Typography>
                      </CoveredCardItem>
                      <Divider />
                      <br />
                      <br />
                      <Typography
                        variant="h2"
                        className={[classes.coveredTitle, classes.blue].join(
                          " "
                        )}
                      >
                        What’s Covered?
                      </Typography>

                      <CoveredCardItem
                        isCovered={true}
                        title="Lifelong Renewability"
                      >
                        <Typography variant={"h4"}>
                          Once you buy this plan, you stay covered throughout
                          your life, provided you renew the policy on time every
                          year.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Alternate Treatments"
                      >
                        <Typography variant={"h4"}>
                          Even treatments under Ayurveda, Unani, Sidha or
                          Homeopathy are covered up to your sum insured
                          (₹3/₹4/₹5 Lakhs).
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Compassionate Travel"
                      >
                        <Typography variant={"h4"}>
                          Covers expenses up to ₹20,000 related to a round trip
                          economy class air ticket, or first-class railway
                          ticket, to allow the Immediate Family Member to be at
                          insured person's bedside during his or her stay in the
                          hospital.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Flexible Sum Insured"
                      >
                        <Typography variant={"h4"}>
                          You can increase or decrease your sum insured from ₹3
                          lakhs every year at the time of renewal, depending
                          your needs. For example, if it is recommended to
                          increase your sum insured if you have a child or
                          decrease it when your employer also provides you with
                          health insurance.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem isCovered={true} title="Tax Benefit">
                        <Typography variant={"h4"}>
                          The premium amount paid under this policy qualifies
                          for deduction under 80D of Income Tax (Amendment) Act,
                          1986.
                        </Typography>
                      </CoveredCardItem>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel
                className={classes.tabPannel + " " + classes.lifePanel}
                value={this.state.value}
                index={1}
              >
                <Box className={classes.topPanel} position="relative">
                  <Box>
                    <Grid container direction="row">
                      <Grid
                        item
                        md={7}
                        xs={7}
                        className={classes.titleGroup}
                        spacing={1}
                      >
                        <Typography
                          variant="h1"
                          className={classes.topPanelTitle1}
                        >
                          Life Insurance
                        </Typography>
                        <Typography
                          variant="h2"
                          className={classes.topPanelTitle2}
                        >
                          ₹50/₹75 Lakhs
                        </Typography>
                        <Typography
                          variant="h3"
                          className={classes.topPanelTitle3}
                        >
                          Everything you need to know
                        </Typography>
                      </Grid>
                      <Grid item md={5} xs={5}>
                        <img
                          src={require("../../../assets/img/product_tab_life.svg")}
                          alt=""
                          className={
                            classes.rightImage + " " + classes.lifeImage
                          }
                          zIndex="tooltip"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.imageBg + " " + classes.lifeBg}>
                    <img
                      src={require("../../../assets/img/life_bg.svg")}
                      alt=""
                    />
                  </Box>
                </Box>
                <Box>
                  <Grid container direction="row" justify="space-between">
                    <Grid item md={5} xs={12}>
                      <Typography
                        variant="h2"
                        className={[classes.coveredTitle, classes.blue].join(
                          " "
                        )}
                      >
                        What’s Covered?
                      </Typography>

                      <CoveredCardItem
                        isCovered={true}
                        title="Life Cover for ₹50/₹75 Lakhs"
                      >
                        <Typography variant={"h4"}>
                          A term life insurance that pays your nominee ₹50/₹75
                          lakhs on death.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Renewal up to 40 Years"
                      >
                        <Typography variant={"h4"}>
                          You can renew this insurance for 40 years up to a
                          maximum age of 75 years.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem isCovered={true} title="Tax Benefit">
                        <Typography variant={"h4"}>
                          You get to enjoy tax benefits on the premium you
                          invest as well as the maturity of those, under Section
                          80C and Section 10(10D) as per prevailing income tax
                          laws.
                        </Typography>
                      </CoveredCardItem>
                      <br />
                      <BackedChip
                        left="Backed by"
                        right="IndiaFirst Life Eterm Plan"
                      />
                      <br />
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item md={5} xs={12}>
                      <Typography
                        variant="h2"
                        className={classes.coveredTitle}
                        color="primary"
                      >
                        What’s Not Covered?
                      </Typography>

                      <CoveredCardItem
                        isCovered={false}
                        title="Suicide within 1 year"
                      >
                        <Typography variant={"h4"}>
                          In case of suicide within 1 year of buying the policy,
                          the claim won’t be admissible.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={false}
                        title="No maturity or survival benefit"
                      >
                        <Typography variant={"h4"}>
                          You will not get any money back, if you live beyond 75
                          years of age or 40 years of coverage.
                        </Typography>
                        <br />
                        <Typography variant={"h4"}>
                          Which is fine because by then all your dependents
                          should be earning a livelihood and your savings would
                          be enough to see you through any eventuality.
                        </Typography>
                        <br />
                        <Typography variant={"h4"}>
                          (Tip: Save the money you would have otherwise put into
                          money back and ULIP policies.)
                        </Typography>
                      </CoveredCardItem>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel
                className={classes.tabPannel + " " + classes.homePanel}
                value={this.state.value}
                index={2}
              >
                <Box className={classes.topPanel} position="relative">
                  <Box>
                    <Grid container direction="row">
                      <Grid
                        item
                        md={7}
                        xs={7}
                        className={classes.titleGroup}
                        spacing={1}
                      >
                        <Typography
                          variant="h1"
                          className={classes.topPanelTitle1}
                        >
                          Household Insurance
                        </Typography>
                        <Typography
                          variant="h2"
                          className={classes.topPanelTitle2}
                        >
                          ₹1/₹2/₹5 Lakhs
                        </Typography>
                        <Typography
                          variant="h3"
                          className={classes.topPanelTitle3}
                        >
                          Everything you need to know
                        </Typography>
                      </Grid>
                      <Grid item md={5} xs={5}>
                        <img
                          src={require("../../../assets/img/product_tab_household.svg")}
                          alt=""
                          className={
                            classes.rightImage + " " + classes.homeImage
                          }
                          zIndex="tooltip"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.imageBg + " " + classes.homeBg}>
                    <img
                      src={require("../../../assets/img/household_bg.svg")}
                      alt=""
                    />
                  </Box>
                </Box>
                <Box>
                  <Grid container direction="row" justify="space-between">
                    <Grid item md={5} xs={12}>
                      <Typography
                        variant="h2"
                        className={[classes.coveredTitle, classes.blue].join(
                          " "
                        )}
                      >
                        What’s Covered?
                      </Typography>

                      <CoveredCardItem
                        isCovered={true}
                        title="Theft up to ₹1/₹2/₹5 Lakhs"
                      >
                        <Typography variant={"h4"}>
                          Theft in your home is covered up to ₹1/₹2/₹5 lakhs.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Loss or Damage up to ₹1/₹2/₹5 Lakhs"
                      >
                        <Typography variant={"h4"}>
                          Any kind of loss or damage on account of disasters
                          like fire and others covered under STFI cover (Storm,
                          Cyclone, Typhoon, Tempest, Hurricane, Tornado, Flood
                          or Inundation) owing to loss/damage to your belongings
                          is covered up to ₹1/₹2/₹5 lakhs.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={true}
                        title="Home Utility Appliances"
                      >
                        <Typography variant={"h4"}>
                          Any accidental physical damage caused due to
                          mechanical or electrical breakdown of utility items
                          such as refrigerator, washing machine, air cooler, air
                          conditioner, microwave/oven is covered for up to
                          ₹30,000/₹60,000/₹1,25,000 of repair expenses. There
                          will be a standard 10% annual depreciation applied to
                          the claim amount.
                        </Typography>
                      </CoveredCardItem>
                      <Divider />
                      <br />
                      <br />
                      <Typography
                        variant="h2"
                        className={classes.coveredTitle}
                        color="primary"
                      >
                        What’s Not Covered?
                      </Typography>

                      <CoveredCardItem
                        isCovered={false}
                        title="Belongings without invoice
                        "
                      >
                        <Typography variant={"h4"}>
                          Original invoice of all the appliances will be needed
                          at the time of the claim.
                        </Typography>
                      </CoveredCardItem>
                      <CoveredCardItem
                        isCovered={false}
                        title="Standard Depreciation Is Applicable"
                      >
                        <Typography variant={"h4"}>
                          A 10% annual depreciation will be applied to all the
                          claim amount submitted. So, if your appliance is 2
                          years old, 20% will be deducted from the claim amount
                          of theft or repair.
                        </Typography>
                      </CoveredCardItem>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item md={5} xs={12}>
                      <Typography
                        variant="h2"
                        className={classes.coveredTitle}
                        color="primary"
                      >
                        What’s Not Covered?
                      </Typography>

                      <CoveredCardItem
                        isCovered={false}
                        title="Structure of your Home"
                      >
                        <Typography variant={"h4"}>
                          This policy only covers your belongings inside your
                          home, and not the building structure of your house.
                        </Typography>
                      </CoveredCardItem>

                      <CoveredCardItem
                        isCovered={false}
                        title="Loss or damage of Mobile Phones and Electronic Gadgets
                        "
                      >
                        <Typography variant={"h4"}>
                          Loss or damage to mobile phones and other home
                          electronics such as TV, Computers, Music Systems,
                          Gaming consoles, etc. are not covered.
                        </Typography>
                      </CoveredCardItem>

                      <CoveredCardItem
                        isCovered={false}
                        title="Deductible on the theft/disaster coverage
                        "
                      >
                        <Typography variant={"h4"}>
                          There will be a deductible applicable of 5% on the
                          claim for theft or damage on account of the covered
                          disasters.
                        </Typography>
                      </CoveredCardItem>

                      <CoveredCardItem
                        isCovered={false}
                        title="Jewellery and Cash
                        "
                      >
                        <Typography variant={"h4"}>
                          The home insurance doesn’t cover any loss or damage to
                          your jewellery and cash.
                        </Typography>
                      </CoveredCardItem>

                      <CoveredCardItem
                        isCovered={false}
                        title="Wear &amp; tear
                        "
                      >
                        <Typography variant={"h4"}>
                          Loss or damage caused by depreciation or wear and tear
                          to any appliances or utility item is not covered under
                          the plan.
                        </Typography>
                      </CoveredCardItem>

                      <CoveredCardItem
                        isCovered={false}
                        title="Riots or Terrorism Damage Cover
                        "
                      >
                        <Typography variant={"h4"}>
                          Any kind of loss or theft during any riot, strike or
                          act of terrorism is not covered under the home
                          insurance plan.
                        </Typography>
                      </CoveredCardItem>

                      <CoveredCardItem
                        isCovered={false}
                        title="Bicycles or motor vehicles
                        "
                      >
                        <Typography variant={"h4"}>
                          Theft or damage to any belonging under motor vehicles,
                          or bicycles is not covered.
                        </Typography>
                      </CoveredCardItem>
                      <br />
                      <BackedChip
                        left="Backed by"
                        right="TATA AIG Home Secure"
                      />
                      <br />
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel
                className={classes.tabPannel + " " + classes.claimPanel}
                value={this.state.value}
                index={3}
              >
                <Box className={classes.topPanel}>
                  <Box>
                    <Grid container direction="row">
                      <Grid
                        item
                        md={7}
                        xs={7}
                        className={classes.titleGroup}
                        spacing={1}
                      >
                        <Typography
                          variant="h1"
                          className={classes.topPanelTitle1}
                        >
                          How To Claim?
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.claimTitle2}
                        >
                          Just like our plan, submitting your claims for
                          reimbursements and cover is as{" "}
                          <span className={classes.redBoldText}>
                            easy as a-b-c!
                          </span>{" "}
                        </Typography>
                        <br />
                        <Typography
                          variant="h4"
                          className={classes.claimTitle2}
                        >
                          Unlike other typical insurance providers, we keep our
                          process transparent from the word go.
                        </Typography>
                        <br />
                        <Typography
                          variant="h4"
                          className={classes.claimTitle2}
                        >
                          The result? More successful claims than most. Our{" "}
                          <span className={classes.redBoldText}>
                            98.1% Claim Settlement Ratio
                          </span>{" "}
                          is not for nothing. Here’s how you can file your claim
                          in easy, simple steps.
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={5}
                        xs={5}
                        className={classes.claimRightImageCover}
                      >
                        <img
                          src={require("../../../assets/img/product_tab_claim.svg")}
                          alt=""
                          className={
                            classes.rightImage + " " + classes.claimImage
                          }
                          zIndex="tooltip"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.imageBg + " " + classes.claimBg}>
                    <img
                      src={require("../../../assets/img/how_to_claim.svg")}
                      alt=""
                    />
                  </Box>
                </Box>
                <Box>
                  <ListItem>
                    <ListItemAvatar>
                      <img
                        className="max-height-48 max-width-48 mr-16"
                        src={require("../../../assets/img/icons/a.png")}
                        alt=""
                      />
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <Box m={0} p={0} pr={2}>
                          <Typography
                            variant={"h3"}
                            className={classes.boldFont}
                          >
                            Log in on Toffee
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Box m={0} p={0} pr={2}>
                          <Typography variant={"h4"}>
                            On Toffee website, go to ‘Claims’ section and log
                            into your account using your mobile number.
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <img
                        className="max-height-48 max-width-48 mr-16"
                        src={require("../../../assets/img/icons/b.png")}
                        alt=""
                      />
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <Box m={0} p={0} pr={2}>
                          <Typography
                            variant={"h3"}
                            className={classes.boldFont}
                          >
                            Upload Your Documents
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Box m={0} p={0} pr={2}>
                          <Typography variant={"h4"}>
                            Upload the scanned copies or pictures of required
                            certificates, bills, or documents.
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <img
                        className="max-height-48 max-width-48 mr-16"
                        src={require("../../../assets/img/icons/c.png")}
                        alt=""
                      />
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <Box m={0} p={0} pr={2}>
                          <Typography
                            variant={"h3"}
                            className={classes.boldFont}
                          >
                            Keep it All Online
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Box m={0} p={0} pr={2}>
                          <Typography variant={"h4"}>
                            Once you hit submit on your claim, just sit back and
                            relax. You can track the status of your claim
                            anytime, anywhere by just logging into your account,
                            or simply opt for updates via our app or whatsapp
                            service.
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                </Box>
              </TabPanel>

              <Dialog
                fullWidth={true}
                onClose={(e) => this.handleClose}
                maxWidth={"lg"}
                open={this.state.calOpenDialog}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                className={classes.calDialog}
                PaperProps={{
                  style: {
                    borderRadius: this.state.isDesktop ? "40px" : "20px",
                    paddingBottom: "60px",
                  },
                }}
                TransitionComponent={Transition}
              >
                <DialogTitle id="scroll-dialog-title">
                  <Box display="flex" justifyContent="space-between">
                    <Box></Box>
                    <Box>Calculate Premium</Box>
                    <Box>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => this.handleClose()}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </DialogTitle>
                <DialogContent dividers={false}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    // ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    <CalculatePremium
                      onFormChange={(state) => {
                        this.setState({
                          calculateState: state,
                          isCalculated: false,
                        });
                      }}
                    />

                    <Box className="rna-wrapper" zIndex="tooltip">
                      <NotificationAlert ref="notificationAlert" />
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </Box>
          }
        />
        <FeaturedBacked />
        <Box className={classes.bottomBar}></Box>
        <Box
          container
          className={classes.fixedBar}
          display="flex"
          alignItems="center"
          zIndex="tooltip"
          position="fixed"
          bottom={0}
          right={0}
          left={0}
          hidden={!this.state.calOpenDialog}
          style={{
            height: "87px",
          }}
        >
          <Grid container direction="row">
            <Grid item md={4} lg={4} xs={12} className={classes.mobileHidden}>
              <Box>
                <img
                  src={require("../../../assets/img/toffee_plan_logo.svg")}
                  alt=""
                  className={classes.toffeePlanLogo}
                />
              </Box>
            </Grid>
            <Grid item md={4} lg={4} xs={6} className={classes.verticalCenter}>
              <Box>
                {!this.state.isCalculating &&
                  (!this.state.isCalculated ? (
                    <Box>
                      <Typography variant="h4" className={classes.center}>
                        Starting from <b>₹600 per Month</b>
                      </Typography>
                      <Typography variant="h6" className={classes.center}>
                        *Including GST
                      </Typography>
                    </Box>
                  ) : (
                      <Box>
                        <Typography variant="h4" className={classes.center}>
                          Your premium is
                      </Typography>
                        <Typography variant="h4" className={classes.center}>
                          <b>
                            ₹{this.state.monthlyPremium} per Month or ₹
                          {this.state.yearlyPremium} per Year
                        </b>
                        </Typography>
                        <Typography variant="h6" className={classes.center}>
                          *Including GST
                      </Typography>
                      </Box>
                    ))}
                {this.state.isCalculating && (
                  <Box>
                    <Typography variant="h4" className={classes.center}>
                      Calculating Premium
                    </Typography>
                    <br />
                    <LinearProgress />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              lg={4}
              xs={6}
              className={classes.verticalCenter + " " + classes.justifyEnd}
            >
              <Box>
                {this.state.isCalculated ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      this.setState(
                        {
                          isCalculated: false,
                          calOpenDialog: false,
                        },
                        () => {
                          this.notify(
                            "tc",
                            "Thank You",
                            "Our team will get in touch with you soon",
                            "success"
                          );
                        }
                      );
                    }}
                    className={classes.prmBtn}
                  >
                    <b>CALL ME</b>
                  </Button>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        this.calculatePremium();
                      }}
                      className={classes.prmBtn}
                      hidden={this.state.isCalculating}
                    >
                      {this.state.monthlyPremium ? (
                        <b>RECALCULATE</b>
                      ) : (
                          <b>CALCULATE</b>
                        )}
                    </Button>
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          container
          className={classes.fixedBar}
          display="flex"
          alignItems="center"
          zIndex="tooltip"
          position="fixed"
          bottom={0}
          right={0}
          left={0}
          hidden={!this.state.bottomBar || this.state.calOpenDialog}
        >
          <Box className={classes.barInnerBox}>
            <Grid container direction="row">
              <Grid item md={3} lg={4} xs={12} className={classes.mobileHidden}>
                <Box>
                  <img
                    src={require("../../../assets/img/toffee_plan_logo.svg")}
                    alt=""
                    className={classes.toffeePlanLogo}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={4}
                lg={4}
                xs={12}
                className={classes.verticalCenter + " " + classes.justifyCenter}
              >
                <Box pb={this.state.isDesktop ? 0 : 2}>
                  <Typography variant="h4" className={classes.center}>
                    Starting from <b>₹600 per Month</b>
                  </Typography>
                  <Typography variant="h6" className={classes.center}>
                    *Including GST
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={5} lg={4} xs={12}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  className={classes.mobileCenter}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      // this.handleDialogOpen();
                      this.props.history.push('/the-toffee-plan/calculate-premium')
                    }}
                    className={classes.prmBtn}
                  >
                    <b>CALCULATE MONTHLY PAYMENT</b>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.customer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
  };
};

export default withStyles(useStyles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(ProductPage)
);
