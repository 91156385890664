import {Strings} from "./strings";
import {QuestionType} from "../utilities/questionBuilder/questionTypes";
import {Keys} from "./keys";
import moment from "moment";
import {InputValidator} from "../utilities/questionBuilder/inputValidator";
import {Products} from "./products";

export const Spouse = {
    data: {
        key: Keys.spouseInfo,
        body: Strings.submitSpouseInfo,
        subtitle: Strings.sinceOptedAddFamilyMembers,
        // icon: Icons.person,
        title: Strings.ensureDetailsAreCorrect,
        questions: [
            {
                type: QuestionType.choice,
                key: Keys.spouseTitle,
                placeholder: Strings.title,
                validation: [InputValidator.required],
                inputs: [
                    {value: Keys.mr, label: Strings.mr, products: {
                            52: [true],
                            53: [true],
                            49: [true],
                            48: [true]
                        },},
                    {value: Keys.ms, label: Strings.ms, products: {
                            52: [true],
                            53: [true],
                            49: [true],
                            48: [true]
                        },},
                ],
                products: {
                    52: [true],
                    53: [true],
                    49: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.text,
                placeholder: Strings.spouseFullName,
                key: Keys.spouseName,
                validation: [InputValidator.required, InputValidator.name],
                maxLength: 30,
                products: {
                    52: [true],
                    53: [true],
                    49: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.date,
                placeholder: Strings.dateOfBirth,
                key: Keys.spouseDob,
                validation: [InputValidator.required],
                maxDate: moment().subtract(18, "years"),
                minDate: moment()
                    .subtract(56, "years")
                    .add(1, "days"),
                products: {
                    52: [true],
                    53: [true],
                    49: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.slider,
                validation: [InputValidator.required],
                key: Keys.spouseFeet,
                placeholder: Strings.heightFeet,
                min: 1,
                max: 8,
                step: 1,
                initial: 5,
                products: {
                    52: [true],
                    53: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.slider,
                validation: [InputValidator.required],
                key: Keys.spouseInches,
                placeholder: Strings.heightInches,
                min: 0,
                max: 11,
                step: 1,
                initial: 0,
                products: {
                    52: [true],
                    53: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.slider,
                validation: [InputValidator.required],
                key: Keys.spouseWeight,
                placeholder: Strings.weightKilograms,
                min: 1,
                max: 150,
                step: 0.5,
                initial: 68,
                products: {
                    52: [true],
                    53: [true],
                    48: [true]
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.spouseTobacco,
                placeholder: Strings.currentlySmoke,
                hasSubQuestions: true,
                // readonly: true,
                initial: false,
                products: {
                    53: [true]
                },
            },
            {
                type: QuestionType.checkbox,
                key: Keys.spouseTobaccoAs,
                validation: [InputValidator.required],
                placeholder: Strings.tobaccoProducts,
                expand: {
                    [Keys.spouseTobacco]: [true]
                },
                inputs: [
                    {value: Keys.bidi, label: Strings.bidi},
                    {value: Keys.gutka, label: Strings.gutka},
                    {value: Keys.pan, label: Strings.panMasala},
                    {value: Keys.cigarettes, label: Strings.cigarette},
                    {value: Keys.cigar, label: Strings.cigar},
                ],
                products: {
                    53: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.spouseTobaccoQuantity,
                validation: [InputValidator.required],
                placeholder: Strings.frequencyConsumption,
                expand: {
                    [Keys.spouseTobacco]: [true]
                },
                inputs: [
                    {value: Keys.lessThan5, label: Strings.lessThan5},
                    {value: Keys.between6and10, label: Strings.sixToTen},
                    {value: Keys.moreThan10, label: Strings.moreThan10},
                ],
                products: {
                    53: [true]
                },
            },
            {
                type: QuestionType.toggle,
                key: Keys.spouseAlcohol,
                validation: [InputValidator.required],
                placeholder: Strings.consumeAlcohol,
                hasSubQuestions: true,
                initial: false,
                products: {
                    53: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.spouseAlcoholFrequency,
                validation: [InputValidator.required],
                placeholder: Strings.consumptionFrequency,
                expand: {
                    [Keys.spouseAlcohol]: [true]
                },
                inputs: [
                    {value: Keys.lessThanThirty, label: Strings.lessThanThirty},
                    {value: Keys.thirtyToOneHundredEighty, label: Strings.thirtyToOneHundredEighty},
                    {value: Keys.moreThanOneHundredEighty, label: Strings.moreThanOneHundredEighty},
                ],
                products: {
                    53: [true]
                },
            },
        ],
    },
};
