import React, {Component} from "react";

import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import PolicyCard from "./policyCard";
import TopContent from "./topContent";
import Box from "@material-ui/core/Box";
import MaterialAppBar from "../../../components/Headers/MaterialAppBar";
import ResponsiveContainer from "../../../components/ResponsiveContainer/responsiveContainer";
import Button from "@material-ui/core/Button";
import { getWebService } from "../../../helpers/serverUtils";

class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderDetails: null,
            isLoaded: false
        };
    }

    healthInsuranceProductIds = [49];

    componentDidMount() {
        this.getOrderDetails();
    }

    async getOrderDetails () {
        const response = await getWebService(`orders/${localStorage.getItem('orderId')}`);
        const { data } = response.data;

        this.setState({ orderDetails: data, isLoaded: true });
    }

    firstLetterUpperCase = (str) => {
        let string;
        string = str
            ?.toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))[0];
        return string;
    };

    isHealthInsuranceJourney (productId) {
        return productId && this.healthInsuranceProductIds.indexOf(productId) > -1;
    }

    render() {
        return (
            this.state.isLoaded && 
            <ResponsiveContainer
                flag={"userFlow"}
                child={
                    <>
                        <MaterialAppBar/>
                        <Box>
                            <TopContent
                                title={
                                    "Congratulations " +
                                    this.firstLetterUpperCase(this.state.orderDetails?.name) +
                                    "!"
                                }
                                desc1={this.isHealthInsuranceJourney(this.state.orderDetails?.product_id) 
                                    ? "You have successfully purchased a Health Insurance Policy from Care Health Insurance Limited. Your Welcome Kit and Policy Document from Care Health Insurance will be digitally sent to you shortly. Meanwhile, you may contact us for any queries at +91-938-9-938-938."
                                    : "You have purchased the Insurance Plan successfully. Your Insurance Plan Card and benefits summary document have been sent on your email and Whatsapp. Please go through them carefully."
                                }
                                desc2={this.isHealthInsuranceJourney(this.state.orderDetails?.product_id) 
                                    ? "" 
                                    : "Please note that your Policy documents will be emailed to you in 2-3 working days. Contact us anytime for any issues."
                                }
                            />
                            <Box display="flex" justifyContent="center" pt={1}>
                                <PolicyCard cardData={this.state.orderDetails}/>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="center" pt={20} pb={5}>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                onClick={(e) => {
                                    window.location.href = "https://toffeeinsurance.com/insurance-policies";
                                }}
                            >
                                View Another Toffee
                            </Button>
                        </Box>
                    </>
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.customer.error,
        userDetails: state.customer.userDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
