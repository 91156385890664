import {Strings} from "./strings";
import {QuestionType} from "../utilities/questionBuilder/questionTypes";
import {Keys} from "./keys";
import {TextInputType} from "../utilities/questionBuilder/textInputType";
import {InputValidator} from "../utilities/questionBuilder/inputValidator";
import {Products} from "./products";

export const OtherInsurance = {
    data: {
        key: Keys.lifestyleInfo,
        body: Strings.lifestyleDetails,
        // subtitle: Strings.pleaseAnswerQuickQuestion,
        // icon: Icons.accessibility_new,
        title: Strings.provideHealthHistory,
        questions: [
            // {
            //     type: QuestionType.toggle,
            //     key: Keys.haveOtherLifeInsuranceR,
            //     placeholder: Strings.haveOtherInsurance,
            //     hasSubQuestions: true,
            //     products: {
            //         52: [true]
            //     },
            // },
            {
                type: QuestionType.choice,
                key: Keys.otherLifeInsurancePolicyType,
                validation: [InputValidator.required],
                placeholder: Strings.policyType,
                inputs: [
                    {value: Keys.traditional, label: Strings.traditional},
                    {value: Keys.unitLinked, label: Strings.unitLinked},
                    {value: Keys.pension, label: Strings.pension},
                    {value: Keys.health, label: Strings.health},
                    {value: Keys.termPlan, label: Strings.termPlan},
                ],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {

                type: QuestionType.choice,
                key: Keys.otherLifeInsuranceCommencementYear,
                validation: [InputValidator.required],
                placeholder: Strings.commencementYear,
                inputs: [
                    {value: Strings.twentySeventeen, label: Strings.twentySeventeen},
                    {value: Strings.twentyEighteen, label: Strings.twentyEighteen},
                    {value: Strings.twentyNineteen, label: Strings.twentyNineteen},
                    {value: Strings.twentyTwenty, label: Strings.twentyTwenty},
                ],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.text,
                key: Keys.otherLifeInsuranceSumInsured,
                validation: [InputValidator.required, InputValidator.numeric, InputValidator.indiaFirstMinSumInsured],
                placeholder: Strings.sumInsuredExist,
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.choice,
                key: Keys.otherLifeInsurancePolicyStatus,
                validation: [InputValidator.required],
                placeholder: Strings.policyStatus,
                inputs: [
                    {value: Keys.standard, label: Strings.standard},
                    {value: Keys.ratedUp, label: Strings.ratedUp},
                    {value: Keys.declined, label: Strings.declined},
                    {value: Keys.lapsed, label: Strings.lapsed},
                    {value: Keys.rejected, label: Strings.rejected},
                    {value: Keys.postponed, label: Strings.postponed},
                ],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                readonly: true,
                type: QuestionType.text,
                key: Keys.otherLifeInsurancePersonName,
                placeholder: Strings.nameOfLifeAssured,
                validation: [InputValidator.required],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },
            {
                type: QuestionType.text,
                key: Keys.otherLifeInsuranceCompanyName,
                placeholder: Strings.nameOfCompany,
                validation: [InputValidator.required],
                expand: {
                    [Keys.haveOtherLifeInsuranceR]: [true]
                },
                products: {
                    52: [true]
                },
            },

        ]
    }
}
