import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles, Grid, Chip, TextField, Switch } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ChipSelect from "./ChipSelect";
import ChipDate from "./ChipDate";
import InsuranceCard from "../Cards/InsuranceCard";
import moment from "moment";

const useStyles = (theme) => ({
  leftBox: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  formBox: {
    borderRadius: "4px",
    border: "solid 1px #3f3f3f47",

    // [theme.breakpoints.down("sm")]: {
    //   border: "none",
    // },
  },
  textField: {
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
  },
});

class CalculatePremium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "mr",
      titleOptions: [
        { title: "Mr", value: "mr" },
        { title: "Ms", value: "ms" },
        { title: "Mrs", value: "mrs" },
      ],
      name: "",
      mobile: "",
      email: "",
      self: true,
      dob: "",
      spouse: false,
      spouseDob: "",
      child1: false,
      child1Dob: "",
      child2: false,
      child2Dob: "",
      sumAssuredLife: "7500000",
      sumAssuredLifeOptions: [
        { title: "50 Lakhs", value: "5000000" },
        { title: "75 Lakhs", value: "7500000" },
      ],
      sumAssuredHealth: "300000",
      sumAssuredHealthOptions: [
        { title: "3 Lakhs", value: "300000" },
        { title: "4 Lakhs", value: "400000" },
        { title: "5 Lakhs", value: "500000" },
      ],
      sumAssuredHouse: "100000",
      sumAssuredHouseOptions: [
        { title: "1 Lakhs", value: "100000" },
        { title: "2 Lakhs", value: "200000" },
        { title: "5 Lakhs", value: "500000" },
      ],
      isConsumingTobacco: false,
    };
  }
  componentDidMount() {
    this.updateFormProps();
  }
  selectTitle(title) {
    this.setState(
      {
        title: title,
      },
      () => {
        this.updateFormProps();
      }
    );
  }
  changeInput(type, value) {
    if (type == "mobile" && !this.nat(value)) {
      return;
    }
    this.setState(
      {
        [type]: value,
      },
      () => {
        this.updateFormProps();
      }
    );
  }
  updateFormProps() {
    this.props.onFormChange(this.state);
  }
  changesumAssuredLife(sum) {
    this.setState(
      {
        sumAssuredLife: sum,
      },
      () => {
        this.updateFormProps();
      }
    );
  }
  changesumAssuredHealth(sum) {
    this.setState(
      {
        sumAssuredHealth: sum,
      },
      () => {
        this.updateFormProps();
      }
    );
  }
  changesumAssuredHouse(sum) {
    this.setState(
      {
        sumAssuredHouse: sum,
      },
      () => {
        this.updateFormProps();
      }
    );
  }
  changeDobChip(member, type, value) {
    console.log(member, type, value);

    if (type === "chip") {
      switch (member) {
        case "dob":
          this.setState(
            {
              self: value,
              dob: value ? this.state.dob : "",
            },
            () => {
              this.updateFormProps();
            }
          );
          break;
        case "spouseDob":
          this.setState(
            {
              spouse: value,
              spouseDob: value ? this.state.spouseDob : "",
            },
            () => {
              this.updateFormProps();
            }
          );
          break;
        case "child1Dob":
          this.setState(
            {
              child1: value,
              child1Dob: value ? this.state.child1Dob : "",
            },
            () => {
              this.updateFormProps();
            }
          );
          break;
        case "child2Dob":
          this.setState(
            {
              child2: value,
              child2Dob: value ? this.state.child2Dob : "",
            },
            () => {
              this.updateFormProps();
            }
          );
          break;
      }
    } else {
      this.setState(
        {
          [member]: value,
        },
        () => {
          this.updateFormProps();
        }
      );
    }
  }
  nat = (n) => {
    return /^[1-9]*\d*$/.test(n);
  };
  render() {
    const { classes } = this.props;

    return (
      <Box>
        <Grid container direction="row">
          <Grid item md={6} lg={6} sm={12} xs={12} className={classes.leftBox}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <InsuranceCard toffeeId="2845 1984 3412" name={this.state.name} />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <ChipSelect
              options={this.state.titleOptions}
              selectedItem={this.state.title}
              onChange={(value) => {
                this.selectTitle(value);
              }}
            />
            <Box border={1} m={1} className={classes.formBox}>
              <form className={classes.root} noValidate autoComplete="off">
                <Box p={2}>
                  <TextField
                    id="name"
                    label="Name"
                    fullWidth
                    type="text"
                    value={this.state.name}
                    onChange={(e) => {
                      this.changeInput("name", e.target.value);
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
                <Divider />
                <Box p={2}>
                  <TextField
                    id="mobile"
                    label="Contact No"
                    fullWidth
                    value={this.state.mobile}
                    onChange={(e) => {
                      this.changeInput("mobile", e.target.value);
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
                <Divider />
                <Box p={2}>
                  <TextField
                    id="email"
                    label="Email"
                    fullWidth
                    type="email"
                    value={this.state.email}
                    onChange={(e) => {
                      this.changeInput("email", e.target.value);
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
                <Divider />
                <Box p={2}>
                  <Typography>Family Member</Typography>

                  <Grid container direction="row">
                    <Grid item md={6} xs={12}>
                      <ChipDate
                        isSelected={this.state.self}
                        label="Self"
                        onChange={(type, value) => {
                          this.changeDobChip("dob", type, value);
                        }}
                        maxDate={moment().subtract(18, "years")}
                        minDate={moment().subtract(56, "years").add(1, "days")}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <ChipDate
                        isSelected={this.state.spouse}
                        label="Spouse"
                        onChange={(type, value) => {
                          this.changeDobChip("spouseDob", type, value);
                        }}
                        maxDate={moment().subtract(18, "years")}
                        minDate={moment().subtract(56, "years").add(1, "days")}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <ChipDate
                        isSelected={this.state.child1}
                        label="Child 1"
                        onChange={(type, value) => {
                          this.changeDobChip("child1Dob", type, value);
                        }}
                        maxDate={new Date()}
                        minDate={moment().subtract(26, "years").add(1, "days")}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <ChipDate
                        isSelected={this.state.child2}
                        label="Child 2"
                        onChange={(type, value) => {
                          this.changeDobChip("child2Dob", type, value);
                        }}
                        maxDate={new Date()}
                        minDate={moment().subtract(26, "years").add(1, "days")}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box p={2}>
                  <Typography>Sum assured for Life</Typography>
                  <ChipSelect
                    options={this.state.sumAssuredLifeOptions}
                    selectedItem={this.state.sumAssuredLife}
                    onChange={(value) => {
                      this.changesumAssuredLife(value);
                    }}
                  />
                </Box>
                <Divider />
                <Box p={2}>
                  <Typography>Sum assured for Health</Typography>
                  <ChipSelect
                    options={this.state.sumAssuredHealthOptions}
                    selectedItem={this.state.sumAssuredHealth}
                    onChange={(value) => {
                      this.changesumAssuredHealth(value);
                    }}
                  />
                </Box>
                <Divider />
                <Box p={2}>
                  <Typography>Sum assured for House Hold</Typography>
                  <ChipSelect
                    options={this.state.sumAssuredHouseOptions}
                    selectedItem={this.state.sumAssuredHouse}
                    onChange={(value) => {
                      this.changesumAssuredHouse(value);
                    }}
                  />
                </Box>
              </form>
            </Box>

            <Grid container direction="row">
              <Grid item md={8} xs={9}>
                <Typography>
                  Do you currently smoke or consume tobacco products?
                </Typography>
              </Grid>
              <Grid item md={4} xs={3}>
                <Box display="flex" justifyContent="flex-end">
                  <Switch
                    checked={this.state.isConsumingTobacco}
                    onChange={(event) => {
                      this.setState(
                        {
                          isConsumingTobacco: event.target.checked,
                        },
                        () => {
                          this.updateFormProps();
                        }
                      );
                    }}
                    name="tobacco"
                    // inputProps={{ "aria-label": "secondary checkbox" }}
                    color="primary"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(CalculatePremium);
