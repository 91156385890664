import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function SearchInput(props) {
    const options = props.bankList.map((option) => {
        const firstLetter = option.title[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
        };
    });
    function cardOption(val) {
        if (val === " Other Bank" || val === "State Bank of India" || val === "Paytm Payments Bank") {
            props.otherBank(true)
            return
        }
        else {
            props.otherBank(false)
            return
        }
    }
    return (
        <div style={{width: 300}}>
            <Autocomplete
                id="grouped-demo"
                options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.title}
                style={{width: 300}}
                renderInput={(params) => <TextField {...params} label="Select Your Bank " variant="outlined"/>}
                onKeyPress={(e) => {
                    if (e.which === 13 || e.keyCode === 13) {
                        props.onChangeValueKey(e.target.value)
                    }
                }
                }
                onChange={(e) => {
                    props.onChange(e.target.textContent)
                    cardOption(e.target.textContent)
                }}
                onInputChange={(_, val) => {
                    if (val === options.title || val === '') {
                        props.otherBank(false)
                    } else if (val !== options.title) {
                        props.otherBank(true)
                    }
                }}

            />

        </div>
    );
}