import React from "react";
import FileUpload from "./FileUpload";
import AddPhoto from "./AddPhoto";

import * as actions from "../../../store/actions";
import { connect } from "react-redux";

class Documents extends React.Component {
  runn = true;

  state = {
    action: "doc_list",
    // action: 'add_photo'

    addedDocuments: [],
    position: 0,
  };
  constructor(props) {
    super(props);
    // console.log(this.props.requiredDocuments);
    this.props.onGetInitiatedClaimState();
  }
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    if (nextProps.claimState) {
      // console.log(nextProps.claimState.documents);

      this.runn &&
        this.setState({ addedDocuments: nextProps.claimState.documents });
      this.runn = false;
    }
  }
  addDocument = (data) => {
    this.setState({
      addedDocuments: this.state.addedDocuments.concat([data]),
    });
  };
  removeDocument = (data) => {
    this.setState({
      addedDocuments: this.state.addedDocuments.filter(function (
        value,
        index,
        arr
      ) {
        return value !== data;
      }),
    });
  };
  render() {
    return (
      <>
        {/* <FileUpload  setState={(data) => this.props.setState(data)}></FileUpload> */}

        {this.state.action === "doc_list" && (
          <FileUpload
            removeDoc={(doc) => {
              this.removeDocument(doc);
            }}
            position={{
              current: this.props.requiredInputs.length + 1,
              total: this.props.requiredInputs.length + 1,
            }}
            uploadedDocuments={this.state.addedDocuments}
            claimId={this.props.claimId}
            requiredDocuments={this.props.requiredDocuments}
            switchTo={(data) => {
              this.setState(data);
            }}
            setState={(data) => this.props.setState(data)}
          ></FileUpload>
        )}
        {this.state.action === "add_photo" && (
          <AddPhoto
            addToUploadedDocs={(data) => {
              this.setState({
                addedDocuments: this.state.addedDocuments.concat(data),
              });
            }}
            claimId={this.props.claimId}
            switchTo={(data) => {
              this.setState(data);
            }}
            document={this.props.requiredDocuments[this.state.position]}
            setState={(data) => this.props.setState(data)}
          ></AddPhoto>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // claimTypesLoading: state.claim.claimTypesLoading,

    // claimTypesArray: state.claim.claimTypesArray,
    // claimRequiredInputs: state.claim.claimRequiredInputs,
    // claimRequiredDocuments: state.claim.claimRequiredDocuments,
    // claimId: state.claim.claimId,
    claimState: state.claim.claimState,

    error: state.claim.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
    onGetInitiatedClaimState: () => dispatch(actions.getInitiatedClaims()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Documents);
