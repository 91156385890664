import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Divider, withStyles, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = (theme) => ({
    card: {
        padding: "0px !important",
        borderRadius: "36px",
        backgroundColor: '#0b0ee508',
        // width: '296px',
        height: '159px',
        margin: 'auto',
        overFlow: 'hidden',
        position: 'relative',
        // [theme.breakpoints.down('xs')]: {
        //     width: "308px !important",
        //     height: "398px !important"
        // },
        // [theme.breakpoints.up('sm')]: {
        //     width: "328px !important",
        //     height: "338px !important"
        // },
        [theme.breakpoints.up('md')]: {
            background: 'none',
            height: '325px'
        },

    },
    image: {
        width: '177px',
        height: '119.5px',
        position: 'absolute',
        left: '27px',
        bottom: '0px',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
            height: '275px'
        },
    },
    text: {
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: '0.86px',
        color: '#22334f',
        // width: '160px',
        height: '96px',
        textAlign: 'start',
        position: 'absolute',
        left: '163px',
        top: '23px'
    }
});

class PolicyCard extends Component {
    state = {
        image: [require("../../../assets/img/coverage_img.png"), require("../../../assets/img/details_img.png"), require("../../../assets/img/claims_img.png")],
        imageDesktop: [require("../../../assets/img/coverage_large.png"), require("../../../assets/img/details_large.png"), require("../../../assets/img/claims_img.png")],
        title: ['Your coverage details.', 'Your policy details.', 'What do you want to claim for?'],
    };
    render() {
        const { classes } = this.props;
        const  isDesktop = window.innerWidth > 960;
        return (
            <Box className={classes.card}>
                <img className={classes.image} src={isDesktop ? this.state.imageDesktop[this.props.index] : this.state.image[this.props.index]} />
                {!isDesktop && <Typography className={classes.text}>
                    {this.state.title[this.props.index]}
                </Typography>}
            </Box>
        );
    }
}
export default withStyles(useStyles, { withTheme: true })(PolicyCard);
