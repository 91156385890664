import React, {Component} from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Chip, Grid, withStyles} from "@material-ui/core";

const useStyles = (theme) => ({
  activeChip: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0px",
    color: "#22334f",
    '&:focus,:hover':{
      backgroundColor: theme.palette.primary.main,
      borderRadius: "0px",
      color: "#22334f",
    }
  },
  chip: {
    backgroundColor: "rgba(34, 51, 79, .1)",
    borderRadius: "0px",
    color: "#22334f",
    '&:focus,:hover':{
      backgroundColor: theme.palette.primary.main,
      borderRadius: "0px",
      color: "#22334f",
    }
  },
});

class ChipSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: this.props.selectedItem ?? "",
      options: this.props.options ?? [],
    };
  }
  selectItem(item) {
    this.setState(
      {
        selectedItem: item.value,
      },
      () => {
        this.props.onChange(item.value);
      }
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <Box py={2}>
        <Typography>{this.props.title}</Typography>
        <Grid container spacing={1}>
          {this.state.options.map((item) => {
            return (
              <Grid item>
                <Chip
                  label={item.title}
                  onClick={(e) => {
                    this.selectItem(item);
                  }}
                  className={
                    this.state.selectedItem === item.value
                      ? classes.activeChip
                      : classes.chip
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(ChipSelect);
