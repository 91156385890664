import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./assets/scss/app.scss";
import "./assets/fonts/fonts.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// react library for routing
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "./store/reducers";
import thunk from "redux-thunk";
import theme from "./theme/muiTheme";
import {MuiThemeProvider, responsiveFontSizes, ThemeProvider} from "@material-ui/core/styles";
import TagManager from 'react-gtm-module'
import * as ReactGA from "react-ga";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);
const responsiveTheme = responsiveFontSizes(theme);
const app = (
    <Provider store={store}>
        <BrowserRouter>
            <MuiThemeProvider theme={responsiveTheme}>
                <App/>
            </MuiThemeProvider>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
