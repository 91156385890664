export const InputValidator = {
    required: "required",
    numeric: "numeric",
    pan: "pan",
    name: "name",
    orgName: "orgName",
    email: "email",
    indiaFirstMinSumInsured: "indiaFirstMinSumInsured",
    nonSalaryIncome: "nonSalaryIncome",
    salaryIncome: "salaryIncome"
};
