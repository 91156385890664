import * as actionTypes from "./actionTypes";
import API from "../../utilities/api";

// START
export const getClaimTypesStart = () => {
  return {
    type: actionTypes.GET_CLAIM_TYPES_START,
  };
};
export const getInitiatedClaimStart = () => {
  return {
    type: actionTypes.GET_INITIATED_CLAIM_START,
  };
};

export const getClaimInputsStart = () => {
  return {
    type: actionTypes.GET_CLAIM_INPUTS_START,
  };
};

// SUCCESS
export const getClaimTypesSuccess = (data, message) => {
  return {
    type: actionTypes.GET_CLAIM_TYPES_SUCCESS,
    data: data,
    message: message,
  };
};
export const getInitiatedClaimSuccess = (data) => {
  return {
    type: actionTypes.GET_INITIATED_CLAIM_SUCCESS,
    data: data,
  };
};
export const getClaimInputsSuccess = (inputs, documents, claimId) => {
  return {
    type: actionTypes.GET_CLAIM_INPUTS_SUCCESS,
    inputs: inputs,
    documents: documents,
    claimId: claimId,
  };
};

// FAIL
export const getClaimTypesFail = (error) => {
  // console.log(error);
  return {
    type: actionTypes.GET_CLAIM_TYPES_FAIL,
    error: error,
  };
};

export const getInitiatedClaimFail = (error) => {
  // console.log(error);
  return {
    type: actionTypes.GET_INITIATED_CLAIM_FAIL,
    error: error,
  };
};

export const getClaimInputsFail = (error) => {
  // console.log(error);
  return {
    type: actionTypes.GET_CLAIM_INPUTS_FAIL,
    error: error,
  };
};

// flag
export const showLoader = (flag) => {
  return {
    type: actionTypes.SHOW_LOADER,
    flag: flag,
  };
};

// ACTION
export const getClaimTypesArray = () => {
  return (dispatch) => {
    dispatch(getInitiatedClaimStart());

    const toff_id = window.location.href.split("/")[5];
    // console.log(toff_id);

    dispatch(showLoader(true));
    API.get("web/claim/claimTypes/" + toff_id)
      .then((response) => {
        dispatch(showLoader(false));

        // console.log(response.data);
        dispatch(
          getClaimTypesSuccess(
            response.data.data.claimTypes,
            response.data.data.message
          )
        );
      })
      .catch((err) => {
        dispatch(showLoader(false));

        // console.log(err);
        if (err.response) dispatch(getClaimTypesFail(err.response.data.error));
        else
          dispatch(
            getClaimTypesFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};
export const getInitiatedClaims = () => {
  return (dispatch) => {
    dispatch(getInitiatedClaimStart());

    const toff_id = window.location.href.split("/")[5];
    dispatch(showLoader(true));

    API.get("web/claim/initiate/" + toff_id)
      .then((response) => {
        dispatch(showLoader(false));

        // console.log(response.data.data);
        dispatch(getInitiatedClaimSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(showLoader(false));

        // console.log(err);
        if (err.response)
          dispatch(getInitiatedClaimFail(err.response.data.error));
        else
          dispatch(
            getInitiatedClaimFail({
              code: 502,
              message: err.message,
            })
          );
      });
  };
};
export const updateInputsDocs = (data) => {
  return (dispatch) => {
    dispatch(getClaimInputsStart());
    dispatch(getClaimInputsSuccess(data.inputs, data.documents, data.claimId));
  };
};
export const callShowLoader = (flag) => {
  return (dispatch) => {
    dispatch(showLoader(flag));
  };
};
