import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import Box from "@material-ui/core/Box";
import {
    Grid,
    Typography,
    withStyles,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Keys} from '../../../../data/keys';
import {Strings} from '../../../../data/strings';
import moment from "moment";
import {Personal} from "../../../../data/personal";
import {Address} from "../../../../data/address";
import {Qualification} from "../../../../data/qualification";
import Checkbox from "@material-ui/core/Checkbox";
import {Lifestyle} from "../../../../data/lifeStyle";
import {OtherInsurance} from "../../../../data/otherInsurance";
import {OtherInsurance2} from "../../../../data/otherInsurence2";
import {PolicyDetails} from "../../../../data/policyDetails";
import {Spouse} from "../../../../data/spouse";
import {Child1} from "../../../../data/child1";
import {Child2} from "../../../../data/child2";
import {Nominee} from "../../../../data/nominee";
import {useStyles} from './styles/allDetailsStyles';


class AllDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            policyDetailsList: PolicyDetails.data ? PolicyDetails.data.questions : [],
            personalQuestionList: Personal.data ? Personal.data.questions : [],
            qualificationQuestionList: Qualification.data ? Qualification.data.questions : [],
            nomineeQuestionList: Nominee.data ? Nominee.data.questions : [],
            spouseQuestionList: Spouse.data ? Spouse.data.questions : [],
            child1QuestionList: Child1.data ? Child1.data.questions : [],
            child2QuestionList: Child2.data ? Child2.data.questions : [],
            houseHoldQuestionList: Address.data ? Address.data.questions : [],
            lifeStyleQuestionList: Lifestyle.data ? Lifestyle.data.questions : [],
            otherInsuranceQuestionList: OtherInsurance.data ? OtherInsurance.data.questions : [],
            otherInsurance2QuestionList: OtherInsurance2.data ? OtherInsurance2.data.questions : []
        };
    }

    healthInsuranceProductIds = [49];

    contains(expand, dataToCheck) {
        if (!expand) {
            return false;
        }
        let key = Object.keys(expand)[0];
        let values = Object.values(expand)[0];
        if (!this.props.userData && !dataToCheck) {
            return false;
        } else {
            dataToCheck = dataToCheck ?? this.props.userData;
            if (Object.keys(dataToCheck).includes(key) < 0) {
                return false;
            } else {
                if (values.includes(dataToCheck[key])) {
                    return true;
                } else {
                    return false
                }
            }
        }
    }

    productFilter(products) {
        if (products && Object.keys(products).length === 0 && products.constructor === Object) {
            return false;
        }
        let productId = [];
        products && Object.values(products).map((data, index) => {
            let tempKey = Object.keys(products)[index];
            productId = [...productId, tempKey]
        })
        if (!this.props.userData) {
            return false;
        } else {
            let check = false;
            let idMatch = false;
            let incomingProductId = this.props.userData?.product_id.toString()
            productId.map(id => {
                check = Object.keys(this.props.userData?.products).includes(id) || incomingProductId === id
                if (check) {
                    idMatch = true
                }
            })
            return idMatch
        }
    }

    getQuestionArray(index) {
        if (!this.props.userData) return []
        switch (index) {
            case 0:
                return this.state.personalQuestionList.map((question, index) => {
                    return (question.key && this.handleHeightValue(question.key) && (question.expand == null || this.contains(question.expand)) && (question.products && this.productFilter(question.products))) && {
                        title: (question.key === Keys.title) ? Strings.title : question.placeholder,
                        value: this.handleHeightValue(question.key)
                    }
                });
            case 1:
                return this.state.qualificationQuestionList.map(question => {
                    return (question.key && this.handleHeightValue(question.key) && (question.expand == null || this.contains(question.expand)) && (question.products && this.productFilter(question.products))) &&
                        {
                            title: question.placeholder,
                            value: (question.inputs) ? question.inputs.map(option => {
                                    return this.props.userData[question.key] === option.value && option.label
                                }) :
                                this.handleHeightValue(question.key)
                        }
                })
            case 2:
                return this.state.nomineeQuestionList.map(question => {
                    return (question.key && this.handleHeightValue(question.key) && (question.expand == null || this.contains(question.expand)) && (question.products && this.productFilter(question.products))) &&
                        {
                            title: (question.key === Keys.nomineeTitle) ? Strings.nomineeGender : question.placeholder,
                            value: (question.inputs) ? question.inputs.map(option => {
                                    return this.props.userData[question.key] === option.value && option.label
                                }) :
                                this.handleHeightValue(question.key)
                        }
                })
            case 3:
                let values = [];
                return this.state.spouseQuestionList.map(question => {
                    values = [];
                    return (question.key && this.handleHeightValue(question.key, true, 'spouse') && (question.expand == null || this.contains(question.expand, this.props.userData[Keys.members][Keys.spouse]) && (question.products && this.productFilter(question.products)))) &&
                        {
                            title: question.placeholder,
                            value: (question.type === "checkbox" ?
                                    this.props.userData.response_members[Keys.spouse][question.key].map(arrayItem => {
                                        return arrayItem && values.push(question.inputs.find(el => el.value === arrayItem).label);
                                    }) && values.join(', ')
                                    :
                                    (
                                        (question.inputs) ?
                                            question.inputs.map(option => {
                                                return this.props.userData.response_members['spouse'][question.key] === option.value && option.label
                                            })
                                            :
                                            this.handleHeightValue(question.key, true, 'spouse')
                                    )
                            )
                        }
                })
            case 4:
                let child1Values = [];
                return this.state.child1QuestionList.map(question => {
                    child1Values = [];
                    return (question.key && this.handleHeightValue(question.key, true, 'child1') && (question.expand == null || this.contains(question.expand, this.props.userData[Keys.members][Keys.child1])) && (question.products && this.productFilter(question.products))) &&
                        {
                            title: question.placeholder,
                            value: (question.type === "checkbox" ?
                                    this.props.userData.response_members[Keys.child1][question.key].map(arrayItem => {
                                        return arrayItem && child1Values.push(question.inputs.find(el => el.value === arrayItem).label);
                                    }) && child1Values.join(', ')
                                    :
                                    (
                                        (question.inputs) ?
                                            question.inputs.map(option => {
                                                return this.props.userData.response_members['child1'][question.key] === option.value && option.label
                                            })
                                            :
                                            this.handleHeightValue(question.key, true, 'child1')
                                    )
                            )
                        }
                })
            case 5:
                let child2Values = [];
                return this.state.child2QuestionList.map(question => {
                    child2Values = [];
                    return (question.key && this.handleHeightValue(question.key, true, 'child2') && (question.expand == null || this.contains(question.expand, this.props.userData[Keys.members][Keys.child2])) && (question.products && this.productFilter(question.products))) &&
                        {
                            title: question.placeholder,
                            value: (question.type === "checkbox" ?
                                    this.props.userData.response_members[Keys.child2][question.key].map(arrayItem => {
                                        return arrayItem && child2Values.push(question.inputs.find(el => el.value === arrayItem).label);
                                    }) && child2Values.join(', ')
                                    :
                                    (
                                        (question.inputs) ?
                                            question.inputs.map(option => {
                                                return this.props.userData.response_members['child2'][question.key] === option.value && option.label
                                            })
                                            :
                                            this.handleHeightValue(question.key, true, 'child2')
                                    )
                            )
                        }
                })
            case 6:
                return this.state.houseHoldQuestionList.map(question => {
                    return ((question.key && this.handleHeightValue(question.key) && (question.expand == null || this.contains(question.expand)) && (question.products && this.productFilter(question.products)))) ? {
                        title: question.placeholder,
                        value: this.props.userData[question.key]
                    } : ''
                });
            case 7:
                let lifestyleValues = [];
                return this.state.lifeStyleQuestionList.map(question => {
                    lifestyleValues = [];
                    return (question.key && this.handleHeightValue(question.key) && (question.expand == null || this.contains(question.expand)) && (question.products && this.productFilter(question.products))) &&
                        {
                            title: question.placeholder,
                            value: (question.type !== "checkbox" ?
                                    (
                                        (question.inputs) ?
                                            question.inputs.map(option => {
                                                return this.props.userData[question.key] === option.value && option.label
                                            })
                                            :
                                            this.handleHeightValue(question.key)
                                    )
                                    : <>
                                        {
                                            this.props.userData[question.key].map(arrayItem => {
                                                return arrayItem && lifestyleValues.push(question.inputs.find(el => el.value === arrayItem).label);
                                            }) && lifestyleValues.join(', ')
                                        }</>
                            ),
                            list: "lifestyle"
                        }
                });

            case 8:
                return this.state.otherInsuranceQuestionList.map(question => {
                    return (question.key && (question.expand == null || this.contains(question.expand)) && (question.products && this.productFilter(question.products))) &&
                        {
                            title: question.placeholder,
                            value: ((question.inputs) ?
                                question.inputs.map(option => {
                                    return this.props.userData?.response_other_life_insurance && this.props.userData?.response_other_life_insurance[0][question.key] === option.value && option.label
                                })
                                :
                                this.props.userData?.response_other_life_insurance && this.props.userData?.response_other_life_insurance[0][question.key]),
                        }
                })
            case 9:
                return this.state.otherInsurance2QuestionList.map(question => {
                    return (question.key && (question.expand == null || this.contains(question.expand)) && (question.products && this.productFilter(question.products))) &&
                        {
                            title: question.placeholder,
                            value: ((question.inputs) ?
                                question.inputs.map(option => {
                                    return this.props.userData?.response_other_life_insurance && this.props.userData?.response_other_life_insurance[1][question.key] === option.value && option.label
                                })
                                :
                                this.props.userData?.response_other_life_insurance && this.props.userData?.response_other_life_insurance[1][question.key]),
                        }
                })
            case 10:
                return this.state.policyDetailsList.map(question => {
                    return (question.key && (question.expand == null || this.contains(question.expand)) && (question.products && this.productFilter(question.products))) &&
                        {
                            title: question.placeholder,
                            value: ((question.inputs) ?
                                    question.inputs.map(option => {
                                        return this.props.userData[question.key] === option.value && option.label
                                    })
                                    :
                                    // this.props.userData[question.key]
                                    (question.key === 'response_payment_tenure' ? 'Regular Pay' :
                                        (question.key === 'response_payment_frequency' ?
                                            (this.props.userData.is_monthly ? 'Monthly' : 'Annual') :
                                            (question.key === 'response_death_benefit' ? 'Lump Sum' :
                                                (question.key === 'response_policy_term' ?
                                                    ((this.props.userData[question.key] < 1 ||
                                                        this.props.userData[question.key] === null) ? "40" :
                                                        this.props.userData[question.key]) :
                                                    this.props.userData[question.key]))))
                            ),
                        }
                })
            default:
                return []
                break;
        }
    }

    getExpansionPanel(title, questions) {
        const {classes} = this.props;
        return <ExpansionPanel className={classes.expansionPanel}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1c-content"
                id="panel1c-header"
                className={classes.expansionPanelSummary}
            >
                <Box pl={1}>
                    <Typography variant={"h5"} className={classes.expansionTitle}>{title}</Typography>
                </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={1}>
                    {
                        questions.map((question) => {
                            return this.getQuestionField(question);
                        })
                    }
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>;
    }

    getQuestionField(question) {
        const {classes} = this.props;
        return (question ? ((question.title !== 'Email' && question.title !== 'Name of the Organization / Business' && !question.list) ?
            <Grid item xs={6}>
                <Typography variant={"h6"}
                            className={classes.questionTitle}>{question.title}</Typography>
                <Typography variant={"h5"}
                            className={(question.title !== 'Email' && classes.capitalize + ' ') + ' ' + classes.questionValue}>{this.parseQuestionValue(question.title, question.value)}</Typography>
            </Grid> :
            <Grid item xs={12}>
                <Typography variant={"h6"}
                            className={classes.questionTitle}>{question.title}</Typography>
                <Typography variant={"h5"}
                            className={(question.title !== 'Email' && classes.capitalize + ' ') + ' ' + classes.questionValue}>{(this.parseQuestionValue(question.title, question.value))}</Typography>
            </Grid>)
            : '')
    }

    handleHeightValue(key, isMember, member) {
        let value;
        switch (key) {
            case Keys.feet:
                value = this.props.userData[key] && (this.props.userData[key] + "' " + this.props.userData[Keys.inches] + '"');
                break;
            case Keys.inches:
                value = "";
                break;
            case Keys.heightInch:
                value = "";
                break;
            case Keys.annualIncomeNonSalary:
                value = this.props.userData[Keys.annualIncome] && this.props.userData[Keys.annualIncome];
                break;
            case Keys.annualIncomeSalary:
                value = this.props.userData[Keys.annualIncome] && this.props.userData[Keys.annualIncome];
                break;
            case Keys.spouseFeet:
                value = (this.props.userData?.response_members[member]?.height_feet &&
                    ((this.props.userData?.response_members[member]?.height_feet) + "' " +
                        (this.props.userData?.response_members[member]?.height_inch + '"')));
                break;
            default:
                value = isMember ? (member === 'spouse' ? this.props.userData.response_members['spouse'][key] : (member === 'child1' ? this.props.userData.response_members['child1'][key] : this.props.userData.response_members['child2'][key])) : this.props.userData[key];
        }
        return value;
    }

    parseQuestionValue(title, value) {
        const {classes} = this.props;
        switch (title) {
            case Strings.gender:
                return value?.toLowerCase() === 'mr' ? 'Male' : 'Female';
            case Strings.birthday:
                return moment(value, "DD/MM/YYYY").format('MMM DD, yyyy');
            case Strings.dateOfBirth:
                return moment(value, "DD/MM/YYYY").format('MMM DD, yyyy');
            case Keys.startDate:
                return moment(value, "YYYY/MM/DD").format('MMM DD, yyyy');
            case Keys.endDate:
                return moment(value, "YYYY/MM/DD").format('MMM DD, yyyy');
            default:
                return value === true ? <Checkbox className={classes.checkboxStyle} checked={true}/>
                    : value === false ? <Checkbox className={classes.checkboxStyle} checked={false}/> : value;
        }
        return value;
    }

    isHealthInsuranceJourney (productId) {
        return productId && this.healthInsuranceProductIds.indexOf(productId) > -1;
    }

    render() {
        const {userData} = this.props;
        const qualificationExists = this.getQuestionArray(1).filter(e => e != null && e != false);
        const nomineeExists = userData && userData.nominee_name;
        const houseHoldExists = userData && userData.pincode;
        const child1Exists = userData && userData.response_members && userData.response_members['child1'];
        const child2Exists = userData && userData.response_members && userData.response_members['child2'];
        const spouceExists = userData && userData.response_members && userData.response_members['spouse'];
        const otherInsurance = userData && userData[Keys.haveOtherLifeInsuranceR] === true;
        const otherInsurance2 = userData && userData[Keys.otherLifeInsurancesR] && userData[Keys.otherLifeInsurancesR].length > 2 && userData[Keys.haveOtherLifeInsuranceR] === true
        const lifeStyle = this.getQuestionArray(7).filter(e => e != null && e != false);
        let products = {};
        products = userData?.products;
        const policyDetails = products?.hasOwnProperty("48") || products?.hasOwnProperty("52") || products?.hasOwnProperty("59") ||
            (userData?.product_id === 48 || userData?.product_id === 52 || userData?.product_id === 59);
        return (
            <>
                <Box pb={20} px={3} pt={4} id={"detailsSection"}>
                    {policyDetails && this.getExpansionPanel(Strings.policyDetails, this.getQuestionArray(10))}
                    {this.getExpansionPanel('Personal Information', this.getQuestionArray(0))}
                    {(qualificationExists && !this.isHealthInsuranceJourney(userData?.product_id)) && this.getExpansionPanel('Qualifications', this.getQuestionArray(1))}
                    {nomineeExists && this.getExpansionPanel('Nominee Details', this.getQuestionArray(2))}
                    {spouceExists && this.getExpansionPanel('Spouse Details', this.getQuestionArray(3))}
                    {child1Exists && this.getExpansionPanel('Child 1 Details', this.getQuestionArray(4))}
                    {child2Exists && this.getExpansionPanel('Child 2 Details', this.getQuestionArray(5))}
                    {houseHoldExists && this.getExpansionPanel('Address Details', this.getQuestionArray(6))}
                    {lifeStyle.length > 0 && this.getExpansionPanel('Lifestyle Details', this.getQuestionArray(7))}
                    {otherInsurance && this.getExpansionPanel('Other Insurance Details 1', this.getQuestionArray(8))}
                    {otherInsurance2 && this.getExpansionPanel('Other Insurance Details 2', this.getQuestionArray(9))}

                </Box>

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.customer.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCallShowLoader: (flag) => dispatch(actions.callShowLoader(flag)),
    };
};
export default withStyles(useStyles, {withTheme: true})(
    connect(mapStateToProps, mapDispatchToProps)(AllDetails)
);
