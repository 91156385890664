import {createStyles} from "@material-ui/core";

export const useStyles = (theme) => createStyles({
    title: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        [theme.breakpoints.down("md")]: {
            paddingLeft: "12px",
            paddingRight: "12px",
        },

        [theme.breakpoints.up("md")]: {
            paddingLeft: "100px",
            paddingRight: "100px",
            paddingTop: "16px",
        },
        [theme.breakpoints.up("lg")]: {
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "16px",
            maxWidth: "1290px",
            margin: "auto",
        },
        [theme.breakpoints.up("xl")]: {
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "16px",
            maxWidth: "1440px",
            margin: "auto",
        },
    },
    center: {
        textAlign: "center",
    },
    expansionPanel: {
        marginTop: '20px',
        backgroundColor: '#f1f1f1',
        '&.MuiExpansionPanel-root:before': {
            display: 'none !important',
        },
    },
    expansionPanelSummary: {
        borderRadius: "0px",
        backgroundColor: theme.palette.primary.main,
        minHeight: "64px",
        // boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.11)'
    },
    expansionTitle: {
        fontWeight: '500',
    },
    questionTitle: {
        opacity: '0.5'
    },
    questionValue: {
        fontWeight: '500',
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    checkboxStyle: {
        padding: "5px 0"
    }
});