import familyIllus from '../assets/illustrations/familyIllustration.png'
import  toffeeLogo from '../assets/images/logos/toffeelogo.svg'
import  userIcon from '../assets/img/user-icon.png'
import  credCard from '../assets/img/cred-card.png'
import  docIcon from '../assets/img/doc-icon.png'
import  medicalIcon from '../assets/img/medical-Icon.svg'
import  checkIcon from '../assets/img/green-tick.png'

export const Images = {
    familyIllus: familyIllus,
    toffeeLogo: toffeeLogo,
    userIcon: userIcon,
    credCard: credCard,
    docIcon: docIcon,
    medicalIcon: medicalIcon,
    checkIcon: checkIcon,
}

